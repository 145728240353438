import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ElementSubType, NetworkMapService } from '../../map/network-map.service';
import { Observable, Subscription } from 'rxjs';

import { Floor } from '../../buildings/floor.model';
import { RouteElementGeometryService } from '../../services/route-element-geometry.service';
import { RouteElementType } from '../../map/route-element-details/route-element.model';
import { Venue } from '../../venues/venue.model';

@Component({
    selector: 'add-multiple-doors',
    templateUrl: './add-multiple-doors.component.html',
    styleUrls: ['./add-multiple-doors.component.scss']
})
export class AddMultipleDoorsComponent implements OnInit, OnDestroy {

    @Input() floor: Floor;
    @Input() venue: Venue;

    @Output() cancel = new EventEmitter<void>();

    public hasDoorPoint = false;
    public doorTypes: ElementSubType[] = []; // All door sub types
    public doorTypeValue: string; // View model for door type select field

    private subscriptions = new Subscription();

    constructor(
        private routeElementGeometryService: RouteElementGeometryService,
        private networkMapService: NetworkMapService
    ) { }

    ngOnInit() {
        this.doorTypes = this.networkMapService.getSubtypes(RouteElementType.Door);

        // Preselect the door type dropdown to standard Door
        this.doorTypeValue = this.doorTypes.find(doorType => doorType.value.toLowerCase() === 'door').key.toString();

        this.subscriptions
            .add((this.routeElementGeometryService.isDoorPointSet() as Observable<boolean>)
                .subscribe(value => {
                    this.hasDoorPoint = value;
                })
            );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     * Create and save doors.
     *
     * @memberof AddMultipleDoorsComponent
     */
    public createDoors(): void {
        const doorRouteElements = [];
        this.routeElementGeometryService.doorLineStrings.forEach(doorLineString => {
            const routeElement = this.networkMapService.createDoorRouteElement(doorLineString, this.venue, this.floor.floorIndex);
            routeElement.subtype = parseInt(this.doorTypeValue);
            doorRouteElements.push(routeElement);
        });

        this.networkMapService.createRouteElements(doorRouteElements);
        this.routeElementGeometryService.cancelOperation();
    }

    /**
     * Cancel adding a door.
     *
     * @memberof AddMultipleDoorsComponent
     */
    public cancelOperation(): void {
        if (!confirm('Canceling without saving will discard any changes you have made.')) {
            return;
        }

        this.routeElementGeometryService.cancelOperation();
        this.cancel.emit();
    }

    /**
     * Undo last clicked point.
     *
     * @memberof AddMultipleDoorsComponent
     */
    public undo(): void {
        this.routeElementGeometryService.undoLastDoorPoint();
    }

}
