import { ViewModelProperties } from '../ViewModelProperties/ViewModelProperties';
import { DisplayRule } from '../../app/locations/location.model';
import { Point } from 'geojson';
import { MapViewModel } from '../MapViewModelFactory/MapViewModelFactory';
import { SortKey } from '../MapViewModelFactory/MapViewModelFactory';
import { FeatureClass } from '../FeatureClass';
import { MapsIndoorsData } from '../../app/shared/enums/MapsIndoorsData';

export abstract class Model3DViewModelProperties implements ViewModelProperties {
    featureClass: FeatureClass;
    originalId: string;
    originalType: MapsIndoorsData;
    sortKey: number;
    src: string;
    rotation: [x: number, y: number, z: number];
    scale: number;
    zoomRange: [min: number, max: number];

    /**
     * Factory for creating a Model3DViewModelProperties object.
     *
     * @static
     * @param {string} id
     * @param {number} sortKey
     * @param {DisplayRule} displayRule
     * @param {MapsIndoorsData} originalType
     * @returns {Promise<Model3DViewModelProperties>}
     */
    static create(id: string, sortKey: number, displayRule: DisplayRule, originalType: MapsIndoorsData): Model3DViewModelProperties {
        return {
            featureClass: FeatureClass.MODEL3D,
            originalId: id,
            originalType,
            sortKey: SortKey.MODEL3D + sortKey,
            src: displayRule?.model3D?.model,
            rotation: [displayRule?.model3D?.rotationX, displayRule?.model3D?.rotationY, displayRule?.model3D?.rotationZ],
            scale: displayRule?.model3D?.scale ?? 1,
            zoomRange: [displayRule?.model3D.zoomFrom, displayRule?.model3D.zoomTo]
        };
    }
}

export class Model3DViewModel implements MapViewModel {
    readonly id: string;
    readonly type = 'Feature';
    readonly geometry: Point;
    readonly properties: Model3DViewModelProperties;

    private constructor(id: string, geometry: Point, properties) {
        this.id = `MODEL3D:${id}`;
        this.geometry = geometry;
        this.properties = properties;
    }

    /**
     * Factory function to create a Model3DViewModel from an id, a point, and a DisplayRule.
     *
     * @static
     * @param {string} id
     * @param {Point} geometry
     * @param {DisplayRule} displayRule
     * @param {number} index
     * @param {MapsIndoorsData} originalType
     * @returns {Model3DViewModel}
     */
    static async create(id: string, geometry: Point, displayRule: DisplayRule, index: number, originalType: MapsIndoorsData): Promise<Model3DViewModel> {
        const properties = await Model3DViewModelProperties.create(id, index, displayRule, originalType);
        return new Model3DViewModel(id, geometry, properties);
    }

}
