import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { aboveThresholdValidator, belowThresholdValidator } from '../../shared/threshold.directive';
import { createObjectFromKeypath, mergeObjects, nullifyParentProps, primitiveClone } from '../object-helper';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { DisplayRule } from '../../locations/location.model';
import { DisplayRuleService } from '../../services/DisplayRuleService/DisplayRuleService';
import { LocationService } from '../../locations/location.service';
import { GeoJSONGeometryType, RegexPatterns } from '../enums';
import { SolutionService } from '../../services/solution.service';
import { MediaLibraryService } from '../../media-library/media-library.service';
import { NotificationService } from '../../services/notification.service';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { labelFormats } from '../../display-rule-details/label-format';
import { createDropdownItemElement } from '../mi-dropdown/mi-dropdown';
import { of, Subscription } from 'rxjs';
import { MediaLibraryComponent } from '../../media-library/media-library.component';
import { Router } from '@angular/router';
import { stayAtCurrentUrl } from '../../solution-settings/solution-settings-shared-functions.component';
import { MediaCategory, MediaLibrarySource } from '../../media-library/media.enum';
import { hasMoreDecimalsValidator, isSmallerThanValidator } from '../validators/validators';
import { Box3, Vector3 } from 'three';
import { formattedNumberWithDecimals } from '../number-helper';
import { ImageSize } from '../../display-rule-details/image-size.model';
import { environment } from '../../../environments/environment';
import { MapsIndoorsIcons } from '../../media-library/mapsindoors-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { isNullOrUndefined } from '../../../utilities/Object';
import equal from 'fast-deep-equal';

enum SizeOption {
    Default,
    RealSize,
    CalculateSize,
    RelativeSize
}

enum ModelDataOption {
    Preview,
    Original
}

interface FormControlState {
    keyPath: string;
    value: string;
    disabled: boolean;
}

interface PreviewObject {
    name: string,
    modelData: string
}

@Component({
    selector: 'display-rule-details-editor',
    templateUrl: './display-rule-details-editor.component.html',
    styleUrls: ['./display-rule-details-editor.component.scss'],
    providers: [
        {
            provide: MatDialogRef,
            useValue: {},
        },
    ],
})
export class DisplayRuleDetailsEditorComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('labelNameDropdown', { static: true }) labelNameDropdownElement: ElementRef<HTMLMiDropdownElement>;
    @ViewChild('labelMaxWidthVisibleInput') labelMaxWidthVisibleInput: ElementRef<HTMLInputElement>;

    @ViewChildren('model2d__preview') model2dPreviewContainer: QueryList<ElementRef>;

    private _geometries: [GeoJSON.Geometry, GeoJSON.Point?];
    private _initialGeometries: [GeoJSON.Geometry, GeoJSON.Point?];
    private subscriptions = new Subscription();
    private selectedSolutionSubscription: Subscription;
    private _displayRule: DisplayRule;
    private initialDisplayRule: DisplayRule;
    private _inheritedDisplayRule: DisplayRule;
    private initialFormControlStates: FormControlState[] = [];
    private displayRuleKeyPaths: string[] = [];
    private mediaLibraryModalConfig: MatDialogConfig = {
        width: '90vw',
        minWidth: '1024px', // smallest screen size supported by CMS
        maxWidth: '1700px', // the number is a subject to change after UX has tested properly
        height: '85vh',     // the number is a subject to change after UX has tested properly
        maxHeight: '928px', // the number is a subject to change after UX has tested properly
        minHeight: '550px', // the number is a subject to change after UX has tested properly
        role: 'dialog',
        panelClass: 'details-dialog',
        disableClose: true
    };
    private model2DAspectRatio: number = 1;
    private model3DbaseMeasurements: ImageSize;
    private original2DModelSize: { width: number, height: number };

    public isDisplayRuleFormDirty = false;
    public solutionSubscription: any;
    public model2DModuleEnabled = false;
    public model3DModuleEnabled = false;
    public wallsModuleEnabled = false;
    public extrusionsModuleEnabled = false;
    public maxZoomLevel = 22;
    public labelMaxWidthVisible = false;
    public displayRuleEditorForm = this.formBuilder.group({
        visible: ['', [Validators.required]],
        iconVisible: ['', [Validators.required]],
        model2D: this.formBuilder.group({
            visible: ['', [Validators.required]],
            widthMeters: ['', [Validators.required, Validators.min(0)]],
            heightMeters: ['', [Validators.required, Validators.min(0)]],
            bearing: ['', [Validators.required, Validators.min(0), isSmallerThanValidator(360)]],
            zoomFrom: ['', [Validators.required, Validators.min(1), Validators.max(this.maxZoomLevel)]],
            zoomTo: ['', [Validators.required, Validators.min(1), Validators.max(this.maxZoomLevel)]],
            model: ['']
        }),
        model: [''],
        zoomFrom: ['', [Validators.required, Validators.min(1), Validators.max(this.maxZoomLevel)]],
        zoomTo: ['', [Validators.required, Validators.min(1), Validators.max(this.maxZoomLevel)]],
        icon: ['', [Validators.required]],
        imageSize: this.formBuilder.group({
            width: [''],
            height: [''],
        }),
        imageScale: [''],
        labelVisible: ['', [Validators.required]],
        label: ['', [Validators.required]],
        labelZoomFrom: ['', [Validators.required, Validators.min(1), Validators.max(this.maxZoomLevel)]],
        labelZoomTo: ['', [Validators.required, Validators.min(1), Validators.max(this.maxZoomLevel)]],
        labelMaxWidth: ['', [Validators.required, Validators.min(0), Validators.pattern(RegexPatterns.NumericalNoDecimals)]],
        polygon: this.formBuilder.group({
            visible: ['', [Validators.required]],
            zoomFrom: ['', [Validators.required, Validators.min(1), Validators.max(this.maxZoomLevel)]],
            zoomTo: ['', [Validators.required, Validators.min(1), Validators.max(this.maxZoomLevel)]],
            strokeWidth: ['', [Validators.required, Validators.min(0), Validators.pattern(RegexPatterns.NumericalNoDecimals)]],
            strokeColor: ['#000000', [Validators.required, Validators.pattern(RegexPatterns.HexColor)]],
            strokeOpacity: ['', [Validators.required, Validators.min(0), Validators.max(1)]],
            fillColor: ['#000000', [Validators.required, Validators.pattern(RegexPatterns.HexColor)]],
            fillOpacity: ['', [Validators.required, Validators.min(0), Validators.max(1)]],
        }),
        walls: this.formBuilder.group({
            visible: ['', [Validators.required]],
            zoomFrom: ['', [Validators.required, Validators.min(1), Validators.max(this.maxZoomLevel)]],
            zoomTo: ['', [Validators.required, Validators.min(1), Validators.max(this.maxZoomLevel)]],
            color: ['#000000', [Validators.required]],
            height: ['', [Validators.required, Validators.min(0)]],
        }),
        extrusion: this.formBuilder.group({
            visible: ['', [Validators.required]],
            zoomFrom: ['', [Validators.required, Validators.min(1), Validators.max(this.maxZoomLevel)]],
            zoomTo: ['', [Validators.required, Validators.min(1), Validators.max(this.maxZoomLevel)]],
            color: ['#000000', [Validators.required]],
            height: ['', [Validators.required, Validators.min(0)]],
        }),
        model3D: this.formBuilder.group({
            visible: ['', [Validators.required]],
            zoomFrom: ['', [Validators.required, Validators.min(1), Validators.max(this.maxZoomLevel)]],
            zoomTo: ['', [Validators.required, Validators.min(1), Validators.max(this.maxZoomLevel)]],
            model: [''],
            rotationX: ['', [Validators.required, Validators.min(0), Validators.max(360)]],
            rotationY: ['', [Validators.required, Validators.min(0), Validators.max(360)]],
            rotationZ: ['', [Validators.required, Validators.min(0), Validators.max(360)]],
            scale: ['', [Validators.required, Validators.min(0), Validators.max(1000), hasMoreDecimalsValidator(10)]],
            widthMeters: ['', [Validators.required, Validators.min(0)]],
            heightMeters: ['', [Validators.required, Validators.min(0)]]
        })
    });
    public model2DPreview: PreviewObject = { name: '', modelData: '' };
    public model3DPreview: PreviewObject = { name: '', modelData: '' };
    public readonly fallBackPreview = 'assets/images/image_placeholder.svg';

    /**
     * When we make changes and we want to discard them, we pass discard to Display Rule Details, where dialogRef (modal box) is known.
     */
    @Output() formDiscard = new EventEmitter<any>();

    /**
     * When we make changes and want to save them, we pass changes to Display Rule Details, where dialogRef (modal box) is known.
     */
    @Output() formSubmit = new EventEmitter<any>();

    /**
     * Property that differentiate between Locations, Location Types and Main Display Rule. For first two we close modal, for Main DR we reset the values to original state.
     */
    @Input() discardChangesMainDisplayRule;

    /**
     * Property that defines location's type.
     */
    @Input() is3DWallsSectionVisible: boolean;

    /**
     * Differentiate between Locations/Types and Main Display Rule.
     */
    @Input() isMainDisplayRule: boolean = false;

    /**
     * Geometries setter.
     *
     * @type {[GeoJSON.Geometry, GeoJSON.Point?]}
     */
    @Input() set geometries(geometries: [GeoJSON.Geometry, GeoJSON.Point?]) {
        if (!isNullOrUndefined(this._initialGeometries) && !equal(this._initialGeometries, geometries)) {
            this.isDisplayRuleFormDirty = true;
        } else {
            this._initialGeometries = geometries;
        }
        this._geometries = geometries;
    }

    /**
     * The current display rule that is loaded before form is populated.
     *
     * @type {[DisplayRule, DisplayRule?]}
     * @memberof DisplayRuleDetailsComponent
     */
    @Input()
    set displayRules([displayRule, inheritedDisplayRule]: [DisplayRule, DisplayRule?]) {
        this._inheritedDisplayRule = inheritedDisplayRule;
        this._displayRule = displayRule;
        this.initialDisplayRule = displayRule;

        this.setFormValues(displayRule);
        this.setLabelFormatDropdownItems(
            this._displayRule?.label || this._inheritedDisplayRule?.label
        );

        this.labelMaxWidthVisible =
            this._displayRule?.labelMaxWidth !== 0 ? true : false;

        // Get the formControls initial state for manually controlling the displayRule forms dirty state
        this.initialFormControlStates = this.getFormControlsInitialState();
    }

    /**
     * Toggle visibility of geometry related display rule settings.
     *
     * @type {boolean}
     * @memberof DisplayRuleDetailsComponent
     */
    @Input() isGeometrySettingsVisible: boolean = true;

    /**
     * IsFit2DModelEnabled property.
     *
     * @public
     * @readonly
     * @type {boolean}
     */
    public get isFit2DModelEnabled(): boolean {
        return !this.isMainDisplayRule && this._geometries?.[0].type === GeoJSONGeometryType.Polygon;
    }

    /**
     * Set additional information to be displayed in the panel-header component.
     *
     * @type {string}
     * @memberof DisplayRuleDetailsComponent
     */
    @Input() header: string;

    /**
     * If Display Rule Details Editor form is dirty.
     *
     * @returns {boolean}
     */
    public get dirty(): boolean {
        return this.displayRuleEditorForm.dirty;
    }

    constructor(
        private formBuilder: FormBuilder,
        private solutionService: SolutionService,
        private mediaLibraryService: MediaLibraryService,
        private locationService: LocationService,
        private notificationService: NotificationService,
        private matDialog: MatDialog,
        private router: Router,
        private mapsIndoorsIcons: MapsIndoorsIcons,
        private spinner: NgxSpinnerService,
        private displayRuleService: DisplayRuleService
    ) {
        this.selectedSolutionSubscription = this.solutionService.selectedSolution$.subscribe((solution) => {
            this.extrusionsModuleEnabled = solution?.modules?.includes('3dextrusions');
            this.wallsModuleEnabled = solution?.modules?.includes('3dwalls');
            this.model2DModuleEnabled = solution?.modules?.includes('2dmodels');
            this.model3DModuleEnabled = solution?.modules?.includes('3dmodels');
            this.maxZoomLevel = solution?.modules?.includes('z22') ? 22 : 21;

            const enabledZoomControls: Array<string> = [];
            if (this.extrusionsModuleEnabled) enabledZoomControls.push('extrusion');
            if (this.wallsModuleEnabled) enabledZoomControls.push('walls');
            if (this.model2DModuleEnabled) enabledZoomControls.push('model2D');
            if (this.model3DModuleEnabled) enabledZoomControls.push('model3D');
            this.setFormValidators(enabledZoomControls);
        });

        this.displayRuleKeyPaths = this.getKeyPaths(
            DisplayRuleService.MAIN_DISPLAY_RULE
        ) as string[];

        this.displayRuleEditorForm.valueChanges.subscribe(() => {
            this.isDisplayRuleFormDirty = this.isFormDirty(); // NOTE: Manually marking the displayRuleForm as dirty doesn't trigger the needed change detection
        });
    }

    /**
     * Is called after Angular has initialized all data-bound properties of a directive.
     */
    ngOnInit(): void {
        this.subscriptions
            .add(this.routerEventsSubscription())
            .add(this.labelSubscription())
            .add(this.iconSubscription())
            .add(this.model2DBearingSubscription())
            .add(this.model2DModelSubscription())
            .add(this.model3DModelSubscription());

        // The 2/3D models should be enabled by default if the display rules are opened from the solution settings page.
        const model2DmodelFormControl = this.getFormControl('model2D.model');
        const model3DmodelFormControl = this.getFormControl('model3D.model');
        if (this.isMainDisplayRule) {
            model2DmodelFormControl.enable({ emitEvent: false });
            model3DmodelFormControl.enable({ emitEvent: false });
        }

        const model2DWidthControl = this.getFormControl('model2D.widthMeters');
        const model2DHeightControl = this.getFormControl('model2D.heightMeters');
        this.model2DDimensionSubscription(model2DWidthControl, model2DHeightControl).forEach(subscription => {
            this.subscriptions.add(subscription);
        });

        if ((model2DmodelFormControl.value && model2DmodelFormControl.disabled) || !model2DmodelFormControl.value) {
            model2DWidthControl.disable({ emitEvent: false });
            model2DHeightControl.disable({ emitEvent: false });
        } else {
            model2DWidthControl.enable({ emitEvent: false });
            model2DHeightControl.enable({ emitEvent: false });
        }

        // Setting the width and height of a 2D model is only available for users who have enabled the 2D Models feature.
        if (this.isModel2DInteractable()) {
            if (model2DmodelFormControl.value) {
                this.getModelData(ModelDataOption.Original, model2DmodelFormControl.value)
                    .then(result => this.model2DPreview = result);
            }
        }

        // Subscribing to changes related to the 3D model's measurements.
        this.model3DMeasurementsSubscription().forEach(subscription => {
            this.subscriptions.add(subscription);
        });

        // If the display rule shold a initial value for the 3D model, we need to load the preview from the backend.
        // This also affects the accessibility of the attached width and height form controls.
        const model3DscaleFormControl = this.getFormControl('model3D.scale');
        const model3DwidthFormControl = this.getFormControl('model3D.widthMeters');
        const model3DheightFormControl = this.getFormControl('model3D.heightMeters');
        if (model3DmodelFormControl.value) {
            this.fillMeasurementsControls(model3DmodelFormControl.value);

            this.getModelData(ModelDataOption.Preview, model3DmodelFormControl.value)
                .then(result => this.model3DPreview = result);
        }

        if ((model3DmodelFormControl.value && model3DscaleFormControl.disabled) || !model3DmodelFormControl.value) {
            model3DwidthFormControl.disable({ emitEvent: false });
            model3DheightFormControl.disable({ emitEvent: false });
        } else {
            model3DwidthFormControl.enable({ emitEvent: false });
            model3DheightFormControl.enable({ emitEvent: false });
        }

        this.labelMaxWidthVisible = this.getFormControl('labelMaxWidth').value > 0 ? true : false;
    }

    /**
     * Is called after Angular has fully initialized a component's view.
     */
    ngAfterViewInit(): void {
        this.spinner.show('model2d-spinner');

        let currentContainer: HTMLImageElement;

        if (this.model2dPreviewContainer.first) {
            currentContainer = this.model2dPreviewContainer.first.nativeElement;
            this.listenToLoadedImageAndEvaluateOriginalSize(currentContainer);
        } else {
            this.model2dPreviewContainer.changes.subscribe(() => {
                if (this.model2dPreviewContainer.first && (!currentContainer || currentContainer !== this.model2dPreviewContainer.first.nativeElement)) {
                    currentContainer = this.model2dPreviewContainer.first.nativeElement;
                    this.listenToLoadedImageAndEvaluateOriginalSize(currentContainer);
                } else {
                    currentContainer = null;
                }
            });
        }
    }

    /**
     * Listens to when the image from the provided html image tag is loaded. It hides the spinner, and resets the original2DModelSize property.
     *
     * @param {HTMLImageElement} imageContainer
     */
    private listenToLoadedImageAndEvaluateOriginalSize(imageContainer: HTMLImageElement): void {
        imageContainer.addEventListener('load', () => {
            this.spinner.hide('model2d-spinner');

            const imageSizeInPixels = { width: imageContainer.naturalWidth, height: imageContainer.naturalHeight };
            this.original2DModelSize = this.calculateImageSize(SizeOption.RelativeSize, imageSizeInPixels);
        });
    }

    /**
     * Returns true/false depending on the dimensions of the 2D model and current width and height settings.
     *
     * @returns {boolean}
     */
    public model2DResetSizeDisabled(): boolean {
        return this.getFormControl('model2D.model').disabled ||
            (this.original2DModelSize?.width === this.getFormControl('model2D.widthMeters').value &&
                this.original2DModelSize?.height === this.getFormControl('model2D.heightMeters').value);
    }

    /**
     * NgOnDestroy.
     */
    ngOnDestroy(): void {
        this.selectedSolutionSubscription.unsubscribe();
    }

    /**
     * Dectects changes in URL path. Subscribe to form state. If dirty, triggers onDiscardChanges().
     *
     * @returns {Subscription}
     */
    private routerEventsSubscription(): Subscription {
        return this.router.events
            .subscribe(() => {
                if (this.isDisplayRuleFormDirty) {
                    this.onDiscardChanges();
                }
            });
    }

    /**
     * Subscribing to changes on the label form control.
     * Set main display rule value when label form control is being disabled.
     *
     * @returns {Subscription}
     */
    private labelSubscription(): Subscription {
        const labelFormControl = this.getFormControl('label');
        return labelFormControl.valueChanges
            .pipe(filter(() => labelFormControl.disabled))
            .subscribe(() => this.resetLabelFormatDropdown());
    }

    /**
     * Subscribing to changes on the icon form control.
     * Reset icon related controls when icon control is disabled.
     *
     * @returns {Subscription}
     */
    private iconSubscription(): Subscription {
        const iconFormControl = this.getFormControl('icon');
        return iconFormControl.valueChanges
            .pipe(filter(() => iconFormControl.disabled))
            .subscribe(() => {
                const iconRelatedControls = [
                    'imageSize.width',
                    'imageSize.height',
                    'imageScale',
                ];
                iconRelatedControls.forEach((keyPath) => {
                    const formControl = this.getFormControl(keyPath);
                    formControl.setValue(null);
                    formControl.disable();
                    // TODO: Check if this is needed here:
                    formControl.markAsDirty();
                });
            });
    }

    /**
     * Subscribing to changes on the model2D.bearing form control.
     *
     * @returns {Subscription}
     */
    private model2DBearingSubscription(): Subscription {
        let previousBearingValue: number;
        const model2DBearingFormControl = this.getFormControl('model2D.bearing');

        return model2DBearingFormControl.valueChanges.subscribe(value => {
            if (!value) return;

            if (!Number.isInteger(value)) { // Checks if number has decimals
                const decimals = Number((value - Math.floor(value)).toFixed(8)); // Extracting the round number from the whole number and setting the decimal to 8 places (8 because we have to check if it will be longer than 7 places)
                if (Number(decimals).toString().length - 2 > 7) { //  We have to remove 2 from the converted number for: '0' and '.'
                    if (!previousBearingValue) {
                        previousBearingValue = Number(decimals.toFixed(7)); // Must be rounded to 7 places because the value has more than 7 decimal places
                    }
                    model2DBearingFormControl.patchValue(previousBearingValue, { emitEvent: false }); // If the value is too long, we reset it to the previous value
                } else {
                    previousBearingValue = value;
                }
            }
        });
    }

    /**
     * Subscribing to changes on the model2D.model form control.
     *
     * @returns {Subscription}
     */
    private model2DModelSubscription(): Subscription {
        const model2DmodelFormControl = this.getFormControl('model2D.model');

        return model2DmodelFormControl.valueChanges
            .pipe(switchMap(newModel => this.getModelData(ModelDataOption.Original, newModel)))
            .subscribe(modelPreviewData => this.model2DPreview = modelPreviewData);
    }

    /**
     * Subscribing to changes on the 2D dimension related form controls.
     *
     * @param {FormControl} widthControl
     * @param {FormControl} heightControl
     * @returns {Array<Subscription>}
     */
    private model2DDimensionSubscription(widthControl: FormControl, heightControl: FormControl): Array<Subscription> {
        const subscription: Array<Subscription> = [];

        if (widthControl.value > 0 && heightControl.value > 0) {
            this.model2DAspectRatio = this.calculateAspectRatio(widthControl.value, heightControl.value);
        }

        subscription.push(
            widthControl.valueChanges.subscribe(() => {
                heightControl.patchValue(Math.round((widthControl.value / this.model2DAspectRatio) * 100) / 100, { emitEvent: false });
                heightControl.markAsDirty();
            }),

            heightControl.valueChanges.subscribe(() => {
                widthControl.patchValue(Math.round((heightControl.value * this.model2DAspectRatio) * 100) / 100, { emitEvent: false });
                widthControl.markAsDirty();
            })
        );

        return subscription;
    }

    /**
     * Calculates the ascpect ratio for the 2D model section.
     *
     * @param {number} width
     * @param {number} height
     * @returns {number}
     */
    private calculateAspectRatio(width: number, height: number): number {
        return width / height;
    }

    /**
     * Subscribing to changes on the model3D.model form control.
     *
     * @returns {Subscription}
     */
    private model3DModelSubscription(): Subscription {
        const model3DmodelFormControl = this.getFormControl('model3D.model');

        return model3DmodelFormControl.valueChanges
            .pipe(switchMap(newModel => this.getModelData(ModelDataOption.Preview, newModel)))
            .subscribe((modelPreviewData) => {
                this.fillMeasurementsControls(model3DmodelFormControl.value);
                this.model3DPreview = modelPreviewData;
            });
    }

    /**
     * Subscribing to changes on the 3D measurements related form controls.
     *
     * @returns {Array<Subscription>}
     */
    private model3DMeasurementsSubscription(): Array<Subscription> {
        const subscription: Array<Subscription> = [];
        const model3DscaleFormControl = this.getFormControl('model3D.scale');
        const model3DwidthFormControl = this.getFormControl('model3D.widthMeters');
        const model3DheightFormControl = this.getFormControl('model3D.heightMeters');

        subscription.push(
            model3DscaleFormControl.valueChanges.subscribe((newScale) => {
                if (!this.model3DbaseMeasurements) return;
                this.update3dDimensionControls(newScale, [model3DwidthFormControl, model3DheightFormControl]);
            }),
            model3DwidthFormControl.valueChanges.subscribe((newWidth) => {
                if (!this.model3DbaseMeasurements?.width) return;
                const newScale = newWidth / this.model3DbaseMeasurements.width;
                this.update3dDimensionControls(newScale, [model3DscaleFormControl, model3DheightFormControl]);
            }),
            model3DheightFormControl.valueChanges.subscribe((newHeight) => {
                if (!this.model3DbaseMeasurements?.height) return;
                const newScale = newHeight / this.model3DbaseMeasurements.height;
                this.update3dDimensionControls(newScale, [model3DscaleFormControl, model3DwidthFormControl]);
            })
        );

        return subscription;
    }

    /**
     * Returns whether the 2D model is interactable (is enabled and does exist).
     *
     * @returns {boolean}
     * @memberof DisplayRuleDetailsComponent
     */
    private isModel2DInteractable(): boolean {
        return this.model2DModuleEnabled && this._displayRule?.model2D !== undefined;
    }

    /**
     * Constructs the objects used to display the 2/3D models' names and preview images.
     *
     * @param {ModelDataOption} modelDataType
     * @param {string} modelUrl
     * @returns {Promise<PreviewObject>}
     */
    private getModelData(modelDataType: ModelDataOption, modelUrl: string): Promise<PreviewObject> {
        if (!modelUrl) {
            return Promise.resolve(null);
        }

        if (modelUrl.includes(environment.iconsBaseUrl)) {
            const mapsIndoorsIconName = this.mapsIndoorsIcons.getIcons().find(icon => icon.preview === modelUrl).name;
            return Promise.resolve({
                name: mapsIndoorsIconName,
                modelData: modelUrl
            });
        }

        const nameWithExtension: string = modelUrl?.substring(modelUrl.lastIndexOf('/') + 1);
        const nameWithoutExtension: string = nameWithExtension.substring(0, nameWithExtension.lastIndexOf('.'));
        const decodedNameWithoutExtension: string = decodeURIComponent(nameWithoutExtension);

        if (modelDataType === ModelDataOption.Original) {
            return Promise.resolve({
                name: decodedNameWithoutExtension,
                modelData: modelUrl
            });
        }

        if (modelDataType === ModelDataOption.Preview) {
            return this.mediaLibraryService.getMediaPreviewByName(nameWithExtension)
                .pipe(
                    catchError(() => of(this.fallBackPreview)),
                    map(result => ({
                        name: decodedNameWithoutExtension,
                        modelData: result
                    })))
                .toPromise();
        }
    }

    /**
     * Fills in the measurement related form controls based on the scale's value.
     * The agreed unit-to-meter value is 1:1, this means that 1 meter is 1 vector.
     *
     * @param {string} fileSrc
     */
    private fillMeasurementsControls(fileSrc: string): void {
        const scaleControl = this.getFormControl('model3D.scale');
        const widthControl = this.getFormControl('model3D.widthMeters');
        const heightControl = this.getFormControl('model3D.heightMeters');

        if (!fileSrc) {
            widthControl.setValue('', { emitEvent: false });
            heightControl.setValue('', { emitEvent: false });
            widthControl.disable({ emitEvent: false });
            heightControl.disable({ emitEvent: false });
            return;
        }

        this.mediaLibraryService.load3D(fileSrc)
            .then((gltf) => {
                // 3D model bounding box - created a box with the minimum dimensions needed to fit the 3D model inside.
                const boundingBox = new Box3().setFromObject(gltf?.scene);
                const size = boundingBox?.getSize(new Vector3());

                if (!size || scaleControl.disabled) {
                    widthControl.disable({ emitEvent: false });
                    heightControl.disable({ emitEvent: false });

                    if (!size) return;
                } else {
                    widthControl.enable({ emitEvent: false });
                    heightControl.enable({ emitEvent: false });
                }

                this.model3DbaseMeasurements = {
                    width: size.x,
                    height: size.y
                };

                // Upper limit is the size of the bounding box's dimensions multiplied by the maximum scale level.
                widthControl.setValidators([Validators.max(this.model3DbaseMeasurements.width * 1000)]);
                heightControl.setValidators([Validators.max(this.model3DbaseMeasurements.height * 1000)]);

                const scaledWidth = formattedNumberWithDecimals(size.x * scaleControl.value, 2);
                const scaledHeight = formattedNumberWithDecimals(size.y * scaleControl.value, 2);

                widthControl.setValue(scaledWidth, { emitEvent: false });
                heightControl.setValue(scaledHeight, { emitEvent: false });
            })
            .catch(() => {
                this.notificationService.showError('3D model could not be loaded.');
                widthControl.disable({ emitEvent: false });
                heightControl.disable({ emitEvent: false });
            });
    }

    /**
     * Updates the dimensions formcontrols based on the new scale value.
     *
     * @param {number} newScale
     * @param {Array<FormControl>} formControls
     */
    private update3dDimensionControls(newScale: number, formControls: Array<FormControl>): void {
        const model3DscaleFormControl = this.getFormControl('model3D.scale');
        const model3DwidthFormControl = this.getFormControl('model3D.widthMeters');
        const model3DheightFormControl = this.getFormControl('model3D.heightMeters');

        const scale = formattedNumberWithDecimals(newScale, 10);
        if (scale && formControls.includes(model3DscaleFormControl)) {
            model3DscaleFormControl.patchValue(scale, { emitEvent: false });
        }

        const scaledWidth = formattedNumberWithDecimals(this.model3DbaseMeasurements.width * scale, 2);
        if (scaledWidth && formControls.includes(model3DwidthFormControl)) {
            model3DwidthFormControl.patchValue(scaledWidth, { emitEvent: false });
        }

        const scaledHeight = formattedNumberWithDecimals(this.model3DbaseMeasurements.height * scale, 2);
        if (scaledHeight && formControls.includes(model3DheightFormControl)) {
            model3DheightFormControl.patchValue(scaledHeight, { emitEvent: false });
        }

        if (scale !== this._displayRule?.model3D?.scale) {
            model3DscaleFormControl.markAsDirty();
        }
    }

    /**
     * Get object key paths.
     *
     * @private
     * @param {object} object - Target object.
     * @param {string[]} [keys=[]]
     * @returns {string | string[]} A string array of all object key paths.
     * @memberof DisplayRuleDetailsComponent
     */
    private getKeyPaths = (object: object, keys: string[] = []): string | string[] => {
        return Object(object) === object
            ? Object.entries(object).flatMap(([key, value]) =>
                this.getKeyPaths(value, [...keys, key]))
            : keys.join('.');
    };

    /**
     * Get keyPath value in target object.
     *
     * @private
     * @param {object} target - Target object.
     * @param {string} keyPath - Object key path.
     * @returns {*} - The nested property value.
     * @memberof DisplayRuleDetailsComponent
     */
    private getKeypathValue = (target: object, keyPath: string): any => {
        if (!target) {
            return;
        }

        return keyPath.split('.').reduce((previous, current) => {
            return previous?.[current] ?? null;
        }, target);
    };

    /**
     * Populate form with display rule values.
     * Set values from main display rule for undefined properties.
     *
     * @private
     * @param {DisplayRule} displayRule
     * @memberof DisplayRuleDetailsComponent
     */
    private setFormValues(displayRule: DisplayRule): void {
        for (const keyPath of this.displayRuleKeyPaths) {
            const formControl = this.getFormControl(keyPath);

            // Skip undefined form controls
            if (!formControl) {
                continue;
            }

            const keyValue = this.getKeypathValue(displayRule, keyPath);

            // Set inherited value and disable control when not present in the initial display rule
            if (keyValue === null || keyValue === undefined) {
                const inheritedValue = this.getKeypathValue(
                    this._inheritedDisplayRule,
                    keyPath
                );
                formControl.patchValue(inheritedValue, { emitEvent: false });
                formControl.disable({ emitEvent: false });

                continue;
            }

            if (keyPath === 'model2D.model') {
                this.getModelData(ModelDataOption.Original, keyValue)
                    .then((result) => this.model2DPreview = result);
            }

            if (keyPath === 'model3D.model') {
                this.getModelData(ModelDataOption.Preview, keyValue)
                    .then((result) => this.model3DPreview = result);
            }

            formControl.patchValue(keyValue, { emitEvent: false });
            formControl.enable({ emitEvent: false });
        }

        this.displayRuleEditorForm.updateValueAndValidity();
    }

    /**
     * Set extra form validation.
     *
     * @private
     * @param {Array<string>} enabledZoomControls
     * @memberof DisplayRuleDetailsComponent
     */
    private setFormValidators(enabledZoomControls?: Array<string>): void {
        // Set validators for zoom from controls
        const zoomFromFormControlsKeypaths = [
            { controlKeyPath: 'zoomFrom', thresholdControlKey: 'zoomTo' },
            { controlKeyPath: 'labelZoomFrom', thresholdControlKey: 'labelZoomTo' },
            { controlKeyPath: 'polygon.zoomFrom', thresholdControlKey: 'zoomTo' },
            { controlKeyPath: 'model2D.zoomFrom', thresholdControlKey: 'zoomTo' },
            { controlKeyPath: 'walls.zoomFrom', thresholdControlKey: 'zoomTo' },
            { controlKeyPath: 'extrusion.zoomFrom', thresholdControlKey: 'zoomTo' },
            { controlKeyPath: 'model3D.zoomFrom', thresholdControlKey: 'zoomTo' }
        ];
        if (enabledZoomControls) {
            enabledZoomControls.forEach(zoomControl => {
                zoomFromFormControlsKeypaths.push(
                    { controlKeyPath: `${zoomControl}.zoomFrom`, thresholdControlKey: 'zoomTo' }
                );
            });
        }
        zoomFromFormControlsKeypaths.forEach(keyPaths => {
            const zoomFromFormControlValidators = [Validators.required, Validators.min(1), belowThresholdValidator(keyPaths.thresholdControlKey, (this._inheritedDisplayRule?.zoomTo || this.maxZoomLevel))];
            this.getFormControl(keyPaths.controlKeyPath).setValidators(zoomFromFormControlValidators);
        });

        // Set validators for zoom to controls
        const zoomToFormControlKeypaths = [
            { controlKeyPath: 'zoomTo', thresholdControlKey: 'zoomFrom' },
            { controlKeyPath: 'labelZoomTo', thresholdControlKey: 'labelZoomFrom' },
            { controlKeyPath: 'polygon.zoomTo', thresholdControlKey: 'zoomFrom' },
            { controlKeyPath: 'model2D.zoomTo', thresholdControlKey: 'zoomFrom' },
            { controlKeyPath: 'walls.zoomTo', thresholdControlKey: 'zoomFrom' },
            { controlKeyPath: 'extrusion.zoomTo', thresholdControlKey: 'zoomFrom' },
            { controlKeyPath: 'model3D.zoomTo', thresholdControlKey: 'zoomFrom' }
        ];
        if (enabledZoomControls) {
            enabledZoomControls.forEach(zoomControl => {
                zoomFromFormControlsKeypaths.push(
                    { controlKeyPath: `${zoomControl}.zoomTo`, thresholdControlKey: 'zoomFrom' }
                );
            });
        }
        zoomToFormControlKeypaths.forEach(keyPaths => {
            const zoomToFormControlValidators = [Validators.required, Validators.max(this.maxZoomLevel), aboveThresholdValidator(keyPaths.thresholdControlKey, (this._inheritedDisplayRule?.zoomFrom || this.maxZoomLevel))];
            this.getFormControl(keyPaths.controlKeyPath).setValidators(zoomToFormControlValidators);
        });
    }

    /**
     * Initialize the label format dropdown.
     *
     * @private
     * @param {string} label
     * @memberof DisplayRuleDetailsComponent
     */
    private setLabelFormatDropdownItems(label: string): void {
        this.labelNameDropdownElement.nativeElement.items = labelFormats.map(
            (labelFormat) => {
                const isItemSelected = label === labelFormat.value;
                return createDropdownItemElement(
                    labelFormat.viewValue,
                    labelFormat.value,
                    isItemSelected
                );
            }
        );

        // Add an unknown option when an unknown label format is used or set as inheritance value
        const labelFormatIsUnknown =
            label !== null &&
            labelFormats.findIndex(
                (labelFormat) => labelFormat.value === label
            ) === -1;
        const inheritanceLabelFormatIsUnknown =
            this._inheritedDisplayRule?.label !== null &&
            labelFormats.findIndex(
                (labelFormat) =>
                    labelFormat.value ===
                    this._inheritedDisplayRule?.label
            ) === -1;
        if (labelFormatIsUnknown || inheritanceLabelFormatIsUnknown) {
            const dropdownItemValue = inheritanceLabelFormatIsUnknown
                ? this._inheritedDisplayRule?.label
                : label;
            const isItemSelected = labelFormatIsUnknown;
            const dropdownItem = createDropdownItemElement(
                '-',
                dropdownItemValue,
                isItemSelected
            );
            this.labelNameDropdownElement.nativeElement.items.unshift(
                dropdownItem
            );
        }
    }

    /**
     * Get form controls initial states.
     *
     * @private
     * @returns {FormControlState[]}
     * @memberof DisplayRuleDetailsComponent
     */
    private getFormControlsInitialState(): FormControlState[] {
        const initialStates: FormControlState[] = [];

        for (const keyPath of this.displayRuleKeyPaths) {
            const formControl = this.getFormControl(keyPath);
            if (formControl) {
                initialStates.push({
                    keyPath: keyPath,
                    value: formControl.value,
                    disabled: formControl.disabled
                });
            }
        }

        return initialStates;
    }

    /**
     * Reset label format dropdown to match inherit display rule value.
     *
     * @private
     * @memberof DisplayRuleDetailsComponent
     */
    private resetLabelFormatDropdown(): void {
        const defaultDropdownItem =
            this.labelNameDropdownElement.nativeElement.items.find(
                (item) => item.value === this._inheritedDisplayRule?.label
            );
        this.labelNameDropdownElement.nativeElement.selected = [
            defaultDropdownItem,
        ];
    }

    /**
     * Check if any of the controls value or inheritance state has changed from the initial state.
     *
     * @private
     * @returns {boolean}
     * @memberof DisplayRuleDetailsComponent
     */
    private isFormDirty(): boolean {
        if (!isNullOrUndefined(this._initialGeometries) && !equal(this._initialGeometries, this._geometries)) {
            return true;
        }

        const dirtyControls = this.displayRuleKeyPaths
            .filter((keyPath) => this.getFormControl(keyPath)?.dirty)
            .filter((keyPath) => {
                const formControl = this.getFormControl(keyPath);
                const initialFormControlState = this.initialFormControlStates.find(state => state.keyPath === keyPath);
                return (formControl.value !== initialFormControlState.value || formControl.disabled !== initialFormControlState.disabled);
            });

        return dirtyControls.length > 0;
    }

    /**
     * Confirmation box when are discarding changes for Location Details, Types and Main Display Rule.
     *
     * @returns {boolean}
     */
    private confirmDiscardChanges(): boolean {
        // eslint-disable-next-line no-alert
        return confirm('You will lose your changes if you continue without saving. Do you want to continue?');
    }


    /**
     * Compares the incloming and the original value of a formControl and sets the dirty state of the formControl accordingly.
     *
     * @param {string} formControlName
     * @param {any} value
     */
    private setDirtyState(formControlName: string, value: any): void {
        const formControl = this.getFormControl(formControlName);
        const originalValue = this._displayRule[formControlName];

        originalValue === value
            ? formControl.markAsPristine()
            : formControl.markAsDirty();
        this.labelMaxWidthVisible = value === 0 ? false : true;
    }

    /**
     * Get updated values from dirty controls.
     *
     * @private
     * @returns {Object<string, any>} The updated values.
     * @memberof DisplayRuleDetailsComponent
     */
    private getUpdatedValues(): { [key: string]: any } {
        // The displayRule form value property doesn't include the values for disabled controls
        // therefor we need to loop through all key paths to find the controls marked as dirty and build our own object with updated display rule properties

        let updatedValues: { [key: string]: any } = {};
        this.displayRuleKeyPaths.forEach((keyPath) => {
            // As nested objects can be null when no child properties is set we loop all possible keyPaths
            const formControl = this.getFormControl(keyPath);

            if (!formControl || !formControl.dirty) {
                return;
            }

            // Set value to null when form control is disabled to inherit from main displayRule
            const keyPathValue = formControl.disabled ? null :
                this.getKeypathValue(this.displayRuleEditorForm.value, keyPath);

            const newValueObject = createObjectFromKeypath(keyPath, keyPathValue);

            const updatedValuesClone = primitiveClone(updatedValues);
            updatedValues = mergeObjects(updatedValuesClone, newValueObject);
        });

        return updatedValues;
    }

    /**
     * Opens the Media Library and sets the new values for the formControls.
     *
     * @param {FormControl} urlFormControl
     * @param {MediaLibrarySource} source
     */
    private openMediaLibrary(urlFormControl: FormControl, source: MediaLibrarySource): void {
        const dialog = this.matDialog.open(MediaLibraryComponent, { ...this.mediaLibraryModalConfig, data: { url: urlFormControl.value, disableClose: true, source: source } });

        dialog.afterClosed().subscribe(
            mediaInfo => {
                if (!mediaInfo) return;

                let imageSize: ImageSize;

                // Check if it is a MapsIndoors icon
                if (mediaInfo.selectedMedia.category === MediaCategory.MIIcon) {
                    imageSize = this.calculateImageSize(SizeOption.Default);
                } else if (source === MediaLibrarySource.Icon) {
                    if (mediaInfo.selectedMedia.type.includes('svg')) {
                        imageSize = this.calculateImageSize(SizeOption.RealSize, mediaInfo.selectedMedia);
                    } else {
                        imageSize = this.calculateImageSize(SizeOption.CalculateSize, mediaInfo.selectedMedia);
                    }
                } else if (source === MediaLibrarySource.Model2D) {
                    this.model2DAspectRatio = this.calculateAspectRatio(mediaInfo.selectedMedia.width, mediaInfo.selectedMedia.height);
                    imageSize = this.calculateImageSize(SizeOption.RelativeSize, mediaInfo.selectedMedia);
                    this.original2DModelSize = imageSize;
                }

                this.updateFormControls(source, urlFormControl, mediaInfo.selectedMedia?.url, imageSize);
            }
        );
    }

    /**
     * Calculates the correct image size for the selected media.
     * For MapsIndoors Icons, it is always default values.
     * For Uploads, that are SVGs, it uses their real size.
     * For Uploads, that are not SVGs, it uses the third of their sizes.
     *
     * @param {SizeOption} setTo
     * @param {ImageSize} size
     * @returns {ImageSize}
     */
    private calculateImageSize(setTo: SizeOption, size?: ImageSize): ImageSize {
        switch (setTo) {
            case SizeOption.Default:
                return {
                    width: this.locationService.defaultIconSize,
                    height: this.locationService.defaultIconSize
                };
            case SizeOption.RealSize:
                return {
                    width: +size.width,
                    height: +size.height
                };
            case SizeOption.CalculateSize:
                return {
                    width: Math.floor(+size.width / 3),
                    height: Math.floor(+size.height / 3)
                };
            case SizeOption.RelativeSize:
                return {
                    // Based on the Mapbox documentation, 0.014 is the multiplier for a zoom level of 22 in order to calculate the value in meters.
                    width: Math.round((size.width * 0.014) * 100) / 100,
                    height: Math.round((size.height * 0.014) * 100) / 100
                };
        }
    }

    /**
     * Sets the new values for the affected formControls, enables them and marks them dirty.
     *
     * @param {MediaLibrarySource} source
     * @param {FormControl} urlFormControl
     * @param {string} selectedMediaUrl
     * @param {ImageSize} imageSize
     */
    private updateFormControls(source: MediaLibrarySource, urlFormControl: FormControl, selectedMediaUrl: string, imageSize?: ImageSize): void {
        switch (source) {
            case MediaLibrarySource.Icon:
                selectedMediaUrl === this._displayRule?.icon ? urlFormControl.markAsPristine() : urlFormControl.markAsDirty();
                urlFormControl.setValue(selectedMediaUrl);

                this.setAndEnableFormControl('imageSize.width', imageSize.width);
                this.setAndEnableFormControl('imageSize.height', imageSize.height);
                this.setAndEnableFormControl('imageScale', 1);
                break;
            case MediaLibrarySource.Model2D:
                selectedMediaUrl === this._displayRule?.model2D?.model ? urlFormControl.markAsPristine() : urlFormControl.markAsDirty();
                urlFormControl.setValue(selectedMediaUrl);
                this.spinner.show('model2d-spinner');

                this.setAndEnableFormControl(`${source}.widthMeters`, imageSize.width);
                this.setAndEnableFormControl(`${source}.heightMeters`, imageSize.height);
                break;
            case MediaLibrarySource.Model3D:
                selectedMediaUrl === this._displayRule?.model3D?.model ? urlFormControl.markAsPristine() : urlFormControl.markAsDirty();
                urlFormControl.setValue(selectedMediaUrl);
                break;
        }
    }

    /**
     * Sets a new value, enables and marks the formControls as dirty.
     *
     * @param {string} formControlName
     * @param {string | number} value
     */
    private setAndEnableFormControl(formControlName: string, value: string | number): void {
        const formControl = this.getFormControl(formControlName);
        formControl.patchValue(value, { emitEvent: false });
        formControl.enable();
        formControl.markAsDirty();
    }

    /**
     * Get a form control by its key path.
     *
     * @param {string} keyPath - FormControl key path.
     * @returns {FormControl}
     * @memberof DisplayRuleDetailsComponent
     */
    public getFormControl(keyPath: string): FormControl {
        return this.displayRuleEditorForm.get(keyPath) as FormControl;
    }

    /**
     * Remove and set 2D Model value to null, then make the form dirty to be able to Save/Discard the changes.
     */
    public removeModel2D(): void {
        const model2DFormControl = this.getFormControl('model2D.model');
        model2DFormControl.setValue(null);
        model2DFormControl.markAsDirty();

        this.isDisplayRuleFormDirty = true;
    }

    /**
     * Remove and set 3D Model value to null, then make the form dirty to be able to Save/Discard the changes.
     */
    public removeModel3D(): void {
        const model3DFormControl = this.getFormControl('model3D.model');
        model3DFormControl.setValue(null);
        model3DFormControl.markAsDirty();

        this.isDisplayRuleFormDirty = true;
    }

    /**
     * Unlock the display rule setting.
     *
     * @param {MouseEvent} event
     * @param {string} keyPath
     * @memberof DisplayRuleDetailsComponent
     */
    public unlockSetting(event: MouseEvent, keyPath: string): void {
        const formControl = this.getFormControl(keyPath);

        if (!formControl || formControl.enabled) {
            return;
        }

        this.toggleInheritance(keyPath, event);
    }

    /**
     * Toggle main DisplayRule value inheritance for a single formControl.
     *
     * @param {string} keyPath
     * @param {MouseEvent} event
     * @memberof DisplayRuleDetailsComponent
     */
    public toggleInheritance(keyPath: string, event?: MouseEvent): void {
        event?.stopPropagation();
        const formControl = this.getFormControl(keyPath);
        formControl.markAsDirty();

        if (keyPath === 'model3D.scale' && this.getFormControl('model3D.widthMeters').value && this.getFormControl('model3D.heightMeters').value) {
            if (!this.getFormControl('model3D.model').value) {
                this.getFormControl('model3D.widthMeters').patchValue('', { emitEvent: false });
                this.getFormControl('model3D.heightMeters').patchValue('', { emitEvent: false });
            }
            if (formControl.disabled) {
                this.getFormControl('model3D.widthMeters').enable({ emitEvent: false });
                this.getFormControl('model3D.heightMeters').enable({ emitEvent: false });
            } else if (!formControl.disabled && this.getFormControl('model3D.model').value) {
                this.getFormControl('model3D.widthMeters').disable({ emitEvent: false });
                this.getFormControl('model3D.heightMeters').disable({ emitEvent: false });
            }
        }

        if (formControl.disabled) {
            formControl.enable();
        } else {
            formControl.setValue(this.getKeypathValue(this._inheritedDisplayRule, keyPath));
            formControl.disable();
        }

        if (keyPath === 'model2D.model') {
            if (formControl.value !== this.getKeypathValue(this._inheritedDisplayRule, keyPath)) {
                this.spinner.show('model2d-spinner');
            }

            const widthFormControl = this.getFormControl('model2D.widthMeters');
            const heightFormControl = this.getFormControl('model2D.heightMeters');

            if (formControl.disabled) {
                const inheritedWidth = this.getKeypathValue(this._inheritedDisplayRule, 'model2D.widthMeters');
                const inheritedHeight = this.getKeypathValue(this._inheritedDisplayRule, 'model2D.heightMeters');

                this.model2DAspectRatio = this.calculateAspectRatio(inheritedWidth, inheritedHeight);

                widthFormControl.patchValue(inheritedWidth);
                widthFormControl.disable();

                heightFormControl.patchValue(inheritedHeight);
                heightFormControl.disable();
            } else {
                widthFormControl.enable({ emitEvent: false });
                heightFormControl.enable({ emitEvent: false });
            }
        }
    }

    /**
     * Set value for label form control.
     *
     * @param {CustomEvent} detail
     * @memberof DisplayRuleDetailsComponent
     */
    public onLabelFormatDropdownChange({ detail }: CustomEvent): void {
        const labelFormControl = this.getFormControl('label');
        labelFormControl.markAsDirty();
        labelFormControl.setValue(detail[0].value);
    }

    /**
     * Toggle visibility of labelMaxWidth input.
     * Reset labelMaxWidth formControl when input is hidden.
     *
     * @memberof DisplayRuleDetailsComponent
     */
    public toggleLabelMaxWidthVisible(): void {
        this.labelMaxWidthVisible = !this.labelMaxWidthVisible;
        const labelMaxWidthFormControl = this.getFormControl('labelMaxWidth');

        // Reset labelMaxWidth to 0 (infinity) when toggle is unchecked
        if (!this.labelMaxWidthVisible) {
            this.setDirtyState('labelMaxWidth', 0);
            labelMaxWidthFormControl.setValue(0);
        } else {
            this.setDirtyState('labelMaxWidth', 1);
            labelMaxWidthFormControl.setValue(1);
        }
    }

    /**
     * Toggle main DisplayRule value inheritance for labelMaxWidth formControl.
     * Hide labelMaxWidth input when disabled.
     *
     * @param {MouseEvent} event
     * @memberof DisplayRuleDetailsComponent
     */
    public toggleLabelMaxWidthInheritance(event: MouseEvent): void {
        const keyPath = 'labelMaxWidth';

        this.toggleInheritance(keyPath, event);

        const isLabelMaxWidthFormControlDisabled =
            this.getFormControl(keyPath).disabled;
        if (isLabelMaxWidthFormControlDisabled) {
            this.labelMaxWidthVisible = false;
        }
    }

    /**
     * Changes the labelMaxWidth's formControl's value to user input.
     *
     * @param {any} event
     */
    public setlabelMaxWidthValue(event: any): void {
        this.setDirtyState('labelMaxWidth', parseInt(event.target.value));
        this.getFormControl('labelMaxWidth').setValue(
            parseInt(event.target.value)
        );
    }

    /**
     * Discarding the form and setting values to initial state else staying at the current URL.
     *
     * @returns {boolean}
     * @memberof DisplayRuleDetailsEditorComponent
     */
    public onDiscardChanges(): boolean {
        if (!this.isFormDirty() || this.confirmDiscardChanges()) {
            this.discardChanges();
            return true;
        } else {
            stayAtCurrentUrl(this.router);
            return false;
        }
    }

    /**
     * When discard is triggered for Locations and Types we close the modal. For Main Display Rule we are reverting changes.
     */
    public discardChanges(): void {
        this._geometries = this._initialGeometries;
        this.setFormValues(this.initialDisplayRule);
        this.displayRuleEditorForm.markAsPristine();
        this.initialFormControlStates = this.getFormControlsInitialState();
        this.formDiscard.emit();
    }

    /**
     * Submit display rule changes.
     *
     * @memberof DisplayRuleDetailsComponent
     */
    public onSubmit(): void {
        if (this.displayRuleEditorForm.invalid) {
            return;
        }

        const updatedValues = this.getUpdatedValues();
        let updatedDisplayRule = mergeObjects(
            this.initialDisplayRule ?? {},
            updatedValues
        ) as DisplayRule;
        updatedDisplayRule = nullifyParentProps(updatedDisplayRule);

        this.formSubmit.emit(updatedDisplayRule);

        this.displayRuleEditorForm.markAsPristine();
        this.isDisplayRuleFormDirty = this.displayRuleEditorForm.dirty;
        this.initialFormControlStates = this.getFormControlsInitialState();
        this.initialDisplayRule = updatedDisplayRule;
    }

    /**
     * Open Media Library for Icons.
     */
    public openMediaLibraryForIcon(): void {
        const iconFormControl = this.displayRuleEditorForm.get('icon');
        this.openMediaLibrary(iconFormControl as FormControl, MediaLibrarySource.Icon);
    }

    /**
     * Open Media Library for 2D Model.
     *
     * @memberof DisplayRuleDetailsComponent
     */
    public openMediaLibraryFor2DModel(): void {
        const model2dFromControl = (this.displayRuleEditorForm.controls['model2D'] as FormGroup).controls['model'];
        this.openMediaLibrary(model2dFromControl as FormControl, MediaLibrarySource.Model2D);
    }

    /**
     * Open Media Library for 3D Model.
     *
     * @memberof DisplayRuleDetailsComponent
     */
    public openMediaLibraryFor3DModel(): void {
        const model3dFromControl = (this.displayRuleEditorForm.controls['model3D'] as FormGroup).controls['model'];
        this.openMediaLibrary(model3dFromControl as FormControl, MediaLibrarySource.Model3D);
    }

    /**
     * Resetting the 2D model's width and height controls to the original image size.
     */
    public reset2DModelOriginalSize(): void {
        this.getFormControl('model2D.widthMeters').patchValue(this.original2DModelSize.width);
        this.getFormControl('model2D.heightMeters').patchValue(this.original2DModelSize.height);
    }


    /**
     * Fits the 2D model inside the locations polygon, keeping the anchor point's position.
     *
     * @public
     */
    public fitModel2D(): void {
        this.displayRuleService.fitToPolygon(this.original2DModelSize as ImageSize, this._geometries[0] as GeoJSON.Polygon, this._geometries[1])
            .then(({ width, height, bearing }) => {
                this.setAndEnableFormControl('model2D.widthMeters', width);
                this.setAndEnableFormControl('model2D.heightMeters', height);
                this.setAndEnableFormControl('model2D.bearing', bearing);
            });

    }
}