import midt from '@mapsindoors/midt/tokens/tailwind-colors.json';
import { Feature, Polygon } from 'geojson';
import { GeoJSONSource, MapLayerMouseEvent, MapMouseEvent } from 'mapbox-gl';
import { Observable, merge } from 'rxjs';
import { exhaustMap, filter, finalize, map, skipWhile, switchMap, takeUntil, tap } from 'rxjs/operators';
import { toPointGeometry } from '../../../utilities/Geodata';
import { FeatureClass } from '../../../viewmodels/FeatureClass';
import { DisplayRule } from '../../locations/location.model';
import { LocationService } from '../../locations/location.service';
import ImageCache from '../../map/MapViewState/ImageCache';
import { MapMouseCursor } from '../../MapAdapter/BaseMapAdapter';
import { Mapbox2DLayer } from '../../MapAdapter/Mapbox/Mapbox2DLayer';
import { Mapbox3DLayer } from '../../MapAdapter/Mapbox/Mapbox3DLayer';
import { MapboxAdapter } from '../../MapAdapter/Mapbox/MapboxAdapter';
import { DisplayRuleService } from '../../services/DisplayRuleService/DisplayRuleService';
import { GeodataEditor } from '../GeodataEditor';
import { MouseButton } from '../../shared/enums/MouseButton';

const EDITOR_GEOJSON_SOURCE = 'EDITOR_GEOJSON_SOURCE';
const EDITOR_OUTLINE_LAYER = 'EDITOR_OUTLINE_LAYER';
const EDITOR_POINT_LAYER = 'EDITOR_POINT_LAYER';
const EDITOR_MODEL_2D_LAYER = 'EDITOR_MODEL_2D_LAYER';
const EDITOR_MODEL_3D_LAYER = 'EDITOR_MODEL_3D_LAYER';
const EDITOR_POLYGON_LAYER = 'EDITOR_POLYGON_LAYER';
const EDITOR_EXTRUSION_LAYER = 'EDITOR_EXTRUSION_LAYER';
const EDITOR_WALL_LAYER = 'EDITOR_WALL_LAYER';

const HANDLES_LAYER = 'HANDLES_LAYER';
const HANDLES_GEOJSON_SOURCE = 'HANDLES_GEOJSON_SOURCE';

const MIDPOINTS_LAYER = 'MIDPOINTS_LAYER';
const MIDPOINTS_GEOJSON_SOURCE = 'MIDPOINTS_GEOJSON_SOURCE';

const HIGHLIGHT_GEOJSON_SOURCE = 'HIGHLIGHT_GEOJSON_SOURCE';
const POINT_HIGHLIGHT_LAYER = 'POINT_HIGHLIGHT_LAYER';
const POLYGON_HIGHLIGHT_LAYER = 'POLYGON_HIGHLIGHT_LAYER';

export class MapboxGeodataEditorAdapter extends GeodataEditor {
    private _mainDisplayRule: DisplayRule;
    #map: mapboxgl.Map;
    #mapbox2DLayer: Mapbox2DLayer = new Mapbox2DLayer(EDITOR_MODEL_2D_LAYER);
    #mapbox3DLayer: Mapbox3DLayer = new Mapbox3DLayer(EDITOR_MODEL_3D_LAYER);
    #wallOpacity: number = 1;
    #extrusionOpacity: number = 1;

    constructor(
        public readonly adapter: MapboxAdapter,
        protected displayRuleService: DisplayRuleService,
        protected locationService: LocationService
    ) {
        super(adapter, displayRuleService, locationService);

        adapter.getMap().then(async mapbox => {
            this.#map = mapbox;
            this._mainDisplayRule = await this.displayRuleService.getDisplayRule();

            this.#wallOpacity = this.#map.getPaintProperty('MI_WALL_LAYER', 'fill-extrusion-opacity') ?? 1;
            this.#extrusionOpacity = this.#map.getPaintProperty('MI_EXTRUSION_LAYER', 'fill-extrusion-opacity') ?? 1;

            if (!this.#map.hasImage('defaultMarker.png')) {
                ImageCache.get('assets/images/routelayer/location.defaultMarker.png')
                    .then(image => this.#map.addImage('defaultMarker.png', image));
            }
            if (!this.#map.hasImage('highlight_point.png')) {
                ImageCache.get('assets/images/highlight_point.png')
                    .then(image => this.#map.addImage('highlight_point.png', image));
            }

            this.#map.on('style.load', this.initMapLayers.bind(this));

            this.initMapLayers();
        });
    }

    /**
     * Initializes the layers used by the GeodataEditor.
     *
     * @private
     */
    private initMapLayers(): void {

        this.#map.addSource(EDITOR_GEOJSON_SOURCE, {
            type: 'geojson',
            promoteId: 'originalId',
            data: { type: 'FeatureCollection', features: [] }
        });

        this.#map.addSource(HANDLES_GEOJSON_SOURCE, {
            type: 'geojson',
            generateId: true,
            data: { type: 'FeatureCollection', features: [] }
        });

        this.#map.addSource(MIDPOINTS_GEOJSON_SOURCE, {
            type: 'geojson',
            generateId: true,
            promoteId: 'insertAfter',
            data: { type: 'FeatureCollection', features: [] }
        });

        this.#map.addSource(HIGHLIGHT_GEOJSON_SOURCE, {
            type: 'geojson',
            data: { type: 'FeatureCollection', features: [] }
        });

        //This layer is for Polygon data.
        this.#map.addLayer({
            'id': EDITOR_POLYGON_LAYER,
            'type': 'fill',
            'source': EDITOR_GEOJSON_SOURCE,
            'filter': ['all',
                ['==', '$type', 'Polygon'],
                ['==', 'featureClass', FeatureClass.POLYGON]
            ],
            'layout': {
                'fill-sort-key': ['get', 'sortKey']
            },
            'paint': {
                'fill-color': ['to-color', ['get', 'fillColor'], this._mainDisplayRule?.polygon.fillColor],
                'fill-opacity': ['number', ['get', 'fillOpacity'], this._mainDisplayRule?.polygon.fillOpacity],
            }
        }, 'MI_WALL_LAYER');

        this.#map.addLayer(this.#mapbox2DLayer);

        this.#map.addLayer(this.#mapbox3DLayer);

        //This layer is for walls.
        this.#map.addLayer({
            'filter': ['any',
                ['==', 'featureClass', FeatureClass.WALL]
            ],
            'id': EDITOR_WALL_LAYER,
            'type': 'fill-extrusion',
            'source': EDITOR_GEOJSON_SOURCE,
            'paint': {
                'fill-extrusion-color': ['get', 'color'],
                // Paint property for walls is set inside MapboxAdapter component.
                'fill-extrusion-opacity': this.#wallOpacity,
                'fill-extrusion-height': ['get', 'height']
            }
        });

        //This layer is for extrusions.
        this.#map.addLayer({
            'filter': ['any',
                ['==', 'featureClass', FeatureClass.EXTRUSION]
            ],
            'id': EDITOR_EXTRUSION_LAYER,
            'type': 'fill-extrusion',
            'source': EDITOR_GEOJSON_SOURCE,
            'paint': {
                'fill-extrusion-color': ['get', 'color'],
                // Paint property for extrusion is set inside MapboxAdapter component.
                'fill-extrusion-opacity': this.#extrusionOpacity,
                'fill-extrusion-height': ['get', 'height']
            }
        });

        //This layer is for outlining polygons.
        this.#map.addLayer({
            'id': EDITOR_OUTLINE_LAYER,
            'type': 'line',
            'source': EDITOR_GEOJSON_SOURCE,
            'filter': ['any',
                ['==', 'featureClass', FeatureClass.LINESTRING],
                ['==', 'featureClass', FeatureClass.POLYGON]
            ],
            'layout': {
                'line-sort-key': ['get', 'sortKey']
            },
            'paint': {
                'line-width': ['number', ['get', 'strokeWidth'], this._mainDisplayRule?.polygon.strokeWidth],
                'line-color': ['to-color', ['get', 'strokeColor'], this._mainDisplayRule?.polygon.strokeColor],
                'line-opacity': ['number', ['get', 'strokeOpacity'], this._mainDisplayRule?.polygon.strokeOpacity],
            }
        });



        this.#map.addLayer({
            'id': HANDLES_LAYER,
            'type': 'circle',
            'source': HANDLES_GEOJSON_SOURCE,
            'filter': ['==', '$type', 'Point'],
            'paint': {
                'circle-color': midt['tailwind-colors'].white.value,
                'circle-radius': 4,
                'circle-stroke-color': midt['tailwind-colors'].blue[500].value,
                'circle-stroke-width': 1
            },
        });

        this.#map.addLayer({
            'id': MIDPOINTS_LAYER,
            'type': 'circle',
            'source': MIDPOINTS_GEOJSON_SOURCE,
            'filter': ['==', '$type', 'Point'],
            'paint': {
                'circle-radius': 3,
                'circle-color': midt['tailwind-colors'].white.value,
                'circle-opacity': .6,
                'circle-stroke-width': 1,
                'circle-stroke-color': midt['tailwind-colors'].blue[500].value,
                'circle-stroke-opacity': .6
            },
        });

        this.#map.addLayer({
            'id': EDITOR_POINT_LAYER,
            'type': 'symbol',
            'source': EDITOR_GEOJSON_SOURCE,
            'filter': ['all',
                ['==', '$type', 'Point'],
                ['==', 'featureClass', FeatureClass.POINT]
            ],
            'layout': {
                'icon-allow-overlap': true,
                'icon-ignore-placement': true,
                'icon-image': ['string', ['get', 'src'], 'defaultMarker.png'],
                'icon-size': ['number', ['get', 'scale'], 1],
                'symbol-placement': 'point',
                'symbol-sort-key': ['get', 'sortKey'],
            },
            'paint': {
                'icon-opacity': ['number', ['feature-state', 'icon-opacity'], 1]
            },
        }), EDITOR_POINT_LAYER;

        // Layer for the highlight icon shown when editing Point geometries.
        this.#map.addLayer({
            'id': POINT_HIGHLIGHT_LAYER,
            'type': 'symbol',
            'source': EDITOR_GEOJSON_SOURCE,
            'filter': ['all',
                ['==', '$type', 'Point'],
                ['==', 'featureClass', FeatureClass.HIGHLIGHT]
            ],
            'layout': {
                'icon-allow-overlap': true,
                'icon-image': ['string', 'highlight_point.png']
            }
        });

        // Layer for the highlight polygon shown when editing Polygon geometries.
        this.#map.addLayer({
            'id': POLYGON_HIGHLIGHT_LAYER,
            'type': 'line',
            'source': EDITOR_GEOJSON_SOURCE,
            'filter': ['all',
                ['==', '$type', 'Polygon'],
                ['==', 'featureClass', FeatureClass.HIGHLIGHT]
            ],
            'paint': {
                'line-width': ['number', 2],
                'line-color': ['to-color', '#EF6CCE'],
                'line-opacity': ['number', 1],
            }
        }, HANDLES_LAYER);

        this.editorViewState.refresh();
    }

    /**
     * Set the visibility of the polygon handles.
     *
     * @public
     * @param {boolean} visible
     */
    public showPolygonHandles(visible: boolean): void {
        this.isPolygonHandlesVisible = visible;
        this.#map?.setLayoutProperty(HANDLES_LAYER, 'visibility', visible ? 'visible' : 'none');
        this.#map?.setLayoutProperty(MIDPOINTS_LAYER, 'visibility', visible ? 'visible' : 'none');
    }

    /**
     * Set the visibility of the extrusion layer.
     *
     * @public
     * @param {boolean} visible
     */
    public showExtrusion(visible: boolean): void {
        this.#map?.setLayoutProperty(EDITOR_EXTRUSION_LAYER, 'visibility', visible ? 'visible' : 'none');
    }

    /**
     * Enable or disable pitching of the map (for Mapbox only).
     *
     * @public
     * @param {boolean} disabled
     */
    public async disablePitch(disabled: boolean): Promise<void> {
        const map = await this.adapter.getMap();
        if (disabled) {
            map.setMaxPitch(0);
            map.setMinPitch(0);
            map.setPitch(0);
        } else {
            map.setMaxPitch();
            map.setMinPitch();
        }
    }

    /**
     * For observing mouse move events, until a mouse up event is detected.
     *
     * @readonly
     * @type {Observable<GeoJSON.Point>}
     * @memberof MapboxGeodataEditorAdapter
     */
    public get mouseMoveUntilMouseUp$(): Observable<GeoJSON.Point> {
        return this.mouseMove$.pipe(takeUntil(this.mouseUp$));
    }

    /**
     * For observing mouse move events, until a mouse up event is detected.
     *
     * @readonly
     * @type {Observable<GeoJSON.Point>}
     * @memberof MapboxGeodataEditorAdapter
     */
    public get mouseMove$(): Observable<GeoJSON.Point> {
        return new Observable<GeoJSON.Point>(subscriber => {
            const mouseMoveHandler = (mapMouseEvent: MapMouseEvent): void => {
                mapMouseEvent.preventDefault();
                subscriber.next({ type: 'Point', coordinates: mapMouseEvent.lngLat.toArray() });
            };
            this.adapter.getMap().then(map => {
                subscriber.add(() => map.off('mousemove', mouseMoveHandler));
                map.on('mousemove', mouseMoveHandler);
            });
        });
    }

    /**
     * For observing mouse click events.
     *
     * @readonly
     * @type {Observable<GeoJSON.Point>}
     */
    public get mouseClick$(): Observable<GeoJSON.Point> {
        return new Observable<GeoJSON.Point>(subscriber => {
            const mouseClickHandler = (mapMouseEvent: MapMouseEvent): void => {
                mapMouseEvent.preventDefault();
                subscriber.next({ type: 'Point', coordinates: mapMouseEvent.lngLat.toArray() });
            };
            this.adapter.getMap().then(map => {
                map.on('click', mouseClickHandler);
            });
        });
    }

    /**
     * For observing mouse down events on the Points layer.
     *
     * @readonly
     * @type {Observable<GeoJSON.Point>}
     * @memberof MapboxGeodataEditorAdapter
     */
    public get mouseDownOnPointLayer$(): Observable<GeoJSON.Point> {
        return this.#mouseDown([EDITOR_POINT_LAYER])
            .pipe(
                filter(({ features }) => features.some(feature => feature.layer.id === EDITOR_POINT_LAYER)),
                tap(e => e.preventDefault()),
                map(({ lngLat }) => ({ type: 'Point', coordinates: lngLat.toArray() }))
            );
    }

    /**
     * For observing mouse enter/off events on the Points layer.
     *
     * @param {MapMouseCursor} hoverMouseCursorType
     * @returns {Observable<GeoJSON.Feature | void>}
     */
    public mouseOverPointLayer$(hoverMouseCursorType?: MapMouseCursor): Observable<GeoJSON.Feature | void> {
        return this.mouseOverLayer([EDITOR_POINT_LAYER], hoverMouseCursorType);
    }

    /**
     * For observing mouse enter/off events on the Polygon layer.
     *
     * @param {MapMouseCursor} hoverMouseCursorType
     * @returns {Observable<GeoJSON.Feature | void>}
     */
    public mouseOverPolygonLayer$(hoverMouseCursorType?: MapMouseCursor): Observable<GeoJSON.Feature | void> {
        return this.mouseOverLayer([EDITOR_POLYGON_LAYER], hoverMouseCursorType);
    }

    /**
     * For observing mouse enter/off events on the Point and Polygon layer.
     *
     * @param {MapMouseCursor} hoverMouseCursorType
     * @returns {Observable<GeoJSON.Feature | void>}
     */
    public mouseOverLocation$(hoverMouseCursorType?: MapMouseCursor): Observable<GeoJSON.Feature | void> {
        return this.mouseOverLayer([EDITOR_POINT_LAYER, EDITOR_POLYGON_LAYER], hoverMouseCursorType);
    }

    /**
     * For observing mouse enter/off events on the Handles layer.
     *
     * @param {MapMouseCursor} hoverMouseCursorType
     * @returns {Observable<GeoJSON.Feature | void>}
     */
    public mouseOverHandlesLayer$(hoverMouseCursorType?: MapMouseCursor): Observable<GeoJSON.Feature | void> {
        return this.mouseOverLayer([HANDLES_LAYER], hoverMouseCursorType);
    }

    /**
     * For observing mouse enter/off events on the Midpoints layer.
     *
     * @param {MapMouseCursor} hoverMouseCursorType
     * @returns {Observable<GeoJSON.Feature | void>}
     */
    public mouseOverMidpointsLayer$(hoverMouseCursorType?: MapMouseCursor): Observable<GeoJSON.Feature | void> {
        return this.mouseOverLayer([MIDPOINTS_LAYER], hoverMouseCursorType);
    }

    /**
     * Merging the mouseEnter and mouseLeave observers.
     * If provided, it changes the map cursor when the cursor is on top of a feature.
     *
     * @param {string[]} layers
     * @param {MapMouseCursor} hoverMouseCursorType
     * @returns {Observable<GeoJSON.Feature | void>}
     */
    private mouseOverLayer(layers: string[], hoverMouseCursorType?: MapMouseCursor): Observable<GeoJSON.Feature | void> {
        return merge(this.#mouseEnter(layers), this.#mouseLeave(layers))
            .pipe(tap((feature) => {
                if (!hoverMouseCursorType) {
                    return;
                }

                feature ?
                    this.adapter.setMapMouseCursor(hoverMouseCursorType) :
                    this.adapter.setMapMouseCursor(MapMouseCursor.Default);
            }));
    }

    /**
     * For observing mouse down events on the Linestrings.
     *
     * @returns {Observable}
     */
    public get mouseDownOnLineString$(): Observable<any> {
        return this.#mouseDown([EDITOR_OUTLINE_LAYER])
            .pipe(
                filter(({ features }) => features.some(feature => feature.layer.id === EDITOR_OUTLINE_LAYER)),
                tap(e => e.preventDefault()),
                map(({ lngLat }) => ({ type: 'Linestring', coordinates: lngLat.toArray() }))
            );
    }

    /**
     * For observing mouse down events on the Polygon layer.
     *
     * @readonly
     * @type {Observable<GeoJSON.Point>}
     * @memberof MapboxGeodataEditorAdapter
     */
    public get mouseDownOnPolygonLayer$(): Observable<GeoJSON.Point> {
        // Passed layers that can be edited on the map.
        return this.#mouseDown([MIDPOINTS_LAYER, EDITOR_POLYGON_LAYER, HANDLES_LAYER, EDITOR_POINT_LAYER])
            .pipe(
                filter(({ features }) => features?.[0]?.layer.id === EDITOR_POLYGON_LAYER),
                tap(e => e.preventDefault()),
                map(({ lngLat }) => ({ type: 'Point', coordinates: lngLat.toArray() }))
            );
    }

    /**
     * For observing mouse down events on the Midpoints layer.
     *
     * @readonly
     * @type {Observable<{ position: GeoJSON.Point, insertAfter: number }>}
     * @memberof MapboxGeodataEditorAdapter
     */
    public get mouseDownOnMidpointsLayer$(): Observable<{ position: GeoJSON.Point, insertAfter: number }> {
        return this.#mouseDown([MIDPOINTS_LAYER])
            .pipe(
                filter(({ features }) => features?.[0]?.layer.id === MIDPOINTS_LAYER),
                tap(e => e.preventDefault()),
                map(({ features, lngLat }) => ({
                    position: { type: 'Point', coordinates: lngLat.toArray() },
                    insertAfter: features[0].properties.insertAfter
                }))
            );
    }

    /**
     * For observing mouse down events on the Handles layer.
     *
     * @readonly
     * @type {Observable<{ position: GeoJSON.Point, index: number }>}
     * @memberof MapboxGeodataEditorAdapter
     */
    public get mouseDownOnHandlesLayer$(): Observable<{ position: GeoJSON.Point, index: number }> {
        return this.#mouseDown([HANDLES_LAYER])
            .pipe(
                filter(({ features, originalEvent }) => features?.[0]?.layer.id === HANDLES_LAYER && originalEvent.button === MouseButton.Main),
                tap(e => e.preventDefault()),
                map(({ features, lngLat }) => ({
                    position: { type: 'Point', coordinates: lngLat.toArray() },
                    index: features[0].properties.index
                }))
            );
    }

    /**
     * For observing right/contextmenu click events on the Handles layer.
     *
     * @public
     * @readonly
     * @type {Observable<{ position: GeoJSON.Point; index: number; }>}
     */
    public get mouseContextmenuOnHandlesLayer$(): Observable<{ position: GeoJSON.Point, index: number }> {
        return this.#mouseDown([HANDLES_LAYER])
            .pipe(
                filter(({ features, originalEvent }) => features?.[0]?.layer.id === HANDLES_LAYER && originalEvent.button === MouseButton.Secondary),
                tap(e => e.preventDefault()),
                map(({ features, lngLat }) => ({
                    position: { type: 'Point', coordinates: lngLat.toArray() },
                    index: features[0].properties.index
                }))
            );
    }

    /**
     * For observing mouse down events.
     *
     * @private
     * @param {string[]} [layers] - Only listen to events from the specified layers.
     * @returns {Observable<{ feature: mapboxgl.MapboxGeoJSONFeature, position: GeoJSON.Position }>}
     * @memberof MapboxGeodataEditorAdapter
     */
    #mouseDown(layers?: string[]): Observable<MapLayerMouseEvent> {
        return new Observable((subscriber) => {
            const onMouseDown = (e: MapLayerMouseEvent): void => {
                e.features = this.#map.queryRenderedFeatures(e.point, { layers });
                subscriber.next(e);
            };
            this.adapter.getMap().then(map => {
                subscriber.add(() => map.off('mousedown', onMouseDown));
                map.on('mousedown', onMouseDown);
            });
        });
    }

    /**
     * For observing mouse over events.
     *
     * @private
     * @param {string[]} [layers] - Only listen to events from the specified layers.
     * @returns {Observable<{ feature: mapboxgl.MapboxGeoJSONFeature }>}
     * @memberof MapboxGeodataEditorAdapter
     */
    #mouseEnter(layers?: string[]): Observable<GeoJSON.Feature> {
        return new Observable((subscriber) => {
            const onMouseEnter = (e: MapLayerMouseEvent): void => {
                const features = e.features;
                if (features?.length > 0) {
                    e.preventDefault();
                    subscriber.next(features[0]);
                }
            };
            this.adapter.getMap().then(map => {
                subscriber.add(() => map.off('mouseenter', onMouseEnter));
                map.on('mouseenter', layers, onMouseEnter);
            });
        });
    }

    /**
     * For observing mouse over events.
     *
     * @private
     * @param {string[]} layers - Only listen to events from the specified layers.
     * @returns {Observable<{ feature: mapboxgl.MapboxGeoJSONFeature, position: GeoJSON.Position }>}
     * @memberof MapboxGeodataEditorAdapter
     */
    #mouseLeave(layers?: string[]): Observable<void> {
        return new Observable((subscriber) => {
            const onMouseLeave = (): void => {
                subscriber.next();
            };
            this.adapter.getMap().then(map => {
                subscriber.add(() => map.off('mouseleave', onMouseLeave));
                map.on('mouseleave', layers, onMouseLeave);
            });
        });
    }

    /**
     * For observing mouse up events.
     *
     * @param {string[]} [layers] - Only listen to events from the specified layers.
     * @returns {Observable<MapLayerMouseEvent>}
     * @memberof MapboxGeodataEditorAdapter
     */
    #mouseUp(layers?: string[]): Observable<MapLayerMouseEvent> {
        return new Observable<MapLayerMouseEvent>(subscriber => {
            const onMouseUp = (e: MapLayerMouseEvent): void => {
                const features = this.#map.queryRenderedFeatures(e.point, { layers });
                e.features = features;
                subscriber.next(e);
            };
            this.adapter.getMap().then(map => {
                subscriber.add(() => map?.off('mouseup', onMouseUp));
                map.on('mouseup', onMouseUp);
            });
        });
    }

    /**
     * For observing mouse click events.
     *
     * @private
     * @param {string[]} layers - Only listen to events from the specified layers.
     * @returns {Observable<{ feature: mapboxgl.MapboxGeoJSONFeature, position: GeoJSON.Position }>}
     * @memberof MapboxGeodataEditorAdapter
     */
    #mouseClick(layers?: string[]): Observable<{ feature: mapboxgl.MapboxGeoJSONFeature, position: GeoJSON.Position }> {
        return new Observable((subscriber) => {
            const onClick = (e: mapboxgl.MapLayerMouseEvent): void => {
                e.preventDefault();
                const features = this.#map.queryRenderedFeatures(e.point, { layers });
                subscriber.next({ feature: features?.[0] ?? null, position: e.lngLat.toArray() });
            };
            this.adapter.getMap().then(map => {
                subscriber.add(() => map.off('click', onClick));
                map.on('click', onClick);
            });
        });
    }

    /**
     * For observing mouse move events.
     *
     * @private
     * @returns {Observable<{ feature: mapboxgl.MapboxGeoJSONFeature, position: GeoJSON.Position }>}
     * @memberof MapboxGeodataEditorAdapter
     */
    #mouseMove(): Observable<MapMouseEvent> {
        return new Observable(subscriber => {
            const mouseMoveHandler = (e: MapMouseEvent): void => {
                e.preventDefault();
                subscriber.next(e);
            };
            this.adapter.getMap().then(map => {
                subscriber.add(() => map.off('mousemove', mouseMoveHandler));
                map.on('mousemove', mouseMoveHandler);
            });
        });
    }

    /**
     * Set the data displayed on the map.
     *
     * @private
     * @param {GeoJSON.Feature[]} features
     * @memberof MapboxGeodataEditorAdapter
     */
    protected setViewData(features: GeoJSON.Feature[]): void {
        const source = this.#map.getSource(EDITOR_GEOJSON_SOURCE) as GeoJSONSource;
        const handlesSource = this.#map.getSource(HANDLES_GEOJSON_SOURCE) as GeoJSONSource;
        const midpointSource = this.#map.getSource(MIDPOINTS_GEOJSON_SOURCE) as GeoJSONSource;
        let handles: GeoJSON.Feature[] = [];
        let midpoints: GeoJSON.Feature[] = [];

        for (const feature of features.filter(feature => (feature.properties?.icon instanceof Image))) {
            const icon = feature.properties.icon;
            if (!this.#map.hasImage(icon.src)) {
                this.#map.addImage(icon.src, icon);
            }
        }

        if (this.isPolygonHandlesVisible) {
            const polygon = features.find(feature => feature.properties.featureClass === FeatureClass.POLYGON) as GeoJSON.Feature<GeoJSON.Polygon>;

            handles = GeodataEditor.convertToPoints(polygon?.geometry);
            midpoints = GeodataEditor.calculateMidpoints(polygon?.geometry);
        }

        source?.setData({ type: 'FeatureCollection', features: features });
        this.#mapbox2DLayer.setData(features);
        this.#mapbox3DLayer.setData(features);
        midpointSource?.setData({ type: 'FeatureCollection', features: midpoints });
        handlesSource?.setData({ type: 'FeatureCollection', features: handles });
    }

    /**
     * Returns a GeoJSON.Point when the map is clicked.
     *
     * @returns {Promise<GeoJSON.Point>}
     * @memberof MapboxGeodataEditorAdapter
     */
    drawPoint(): Observable<GeoJSON.Point> {
        return new Observable((subscriber) => {
            this.adapter.isClickable = false;
            const onClick = this.#mouseClick()
                .subscribe(({ position }) => subscriber.next(toPointGeometry(position)));

            subscriber.add(() => {
                onClick;
                this.adapter.isClickable = true;
            });
        });
    }

    /**
     * Initialize freehand drawing mode.
     *
     * @protected
     * @returns {Observable<GeoJSON.Polygon>}
     * @memberof MapboxGeodataEditorAdapter
     */
    protected drawAreaFreehand(): Observable<GeoJSON.Polygon> {
        const source = this.#map.getSource(EDITOR_GEOJSON_SOURCE) as GeoJSONSource;
        const handlesSource = this.#map.getSource(HANDLES_GEOJSON_SOURCE) as GeoJSONSource;
        const lineString: GeoJSON.Feature<GeoJSON.LineString> = { type: 'Feature', geometry: { type: 'LineString', coordinates: [[]] }, properties: { featureClass: FeatureClass.LINESTRING } };
        const linePreview: GeoJSON.Feature<GeoJSON.LineString> = { type: 'Feature', geometry: { type: 'LineString', coordinates: [[]] }, properties: { featureClass: FeatureClass.LINESTRING } };
        const lines: GeoJSON.Feature[] = [lineString, linePreview];

        this.showPolygonHandles(true);

        return new Observable<GeoJSON.Polygon>(subscriber => {
            const path: GeoJSON.Position[] = [];

            //Logic to close the polygon by clicking on the first point in the polygon.
            const onHandlesLayerClick = this.#mouseClick([HANDLES_LAYER]).subscribe(({ feature }) => {
                const index = feature?.properties?.index as number;
                if (index === 0 && path.length > 2) {
                    path.push(path[0]);
                    this.showPolygonHandles(false);
                    subscriber.next({ type: 'Polygon', coordinates: [path] });
                    subscriber.complete();
                }
            });

            //Listen/subscribe to clicks on the map.
            const drawPointSubscription = this.drawPoint()
                .pipe(
                    tap(point => {
                        //Capture the point clicked and pushed on to the path array.
                        path.push(point.coordinates);
                        //Create handles for each joint on the line.
                        const handles: GeoJSON.Feature<GeoJSON.Point>[] = path.map((position, index) => ({ type: 'Feature', geometry: toPointGeometry(position), properties: { index } }));
                        lineString.geometry.coordinates = path;
                        handlesSource?.setData({ type: 'FeatureCollection', features: handles });
                    }),
                    //Switch to listen for mouse movement, to show the "preview" of the next line segment.
                    switchMap(() => this.#mouseMove())
                ).subscribe(({ lngLat }) => {
                    //Update the "preview" geometry.
                    linePreview.geometry.coordinates = [path[path.length - 1], lngLat.toArray()];
                    source?.setData({ type: 'FeatureCollection', features: lines });
                });


            //Set the mouse cursor accordingly.
            const onMouseOver = this.mouseOverLayer([HANDLES_LAYER])
                .subscribe((feature: Feature) => {
                    const index = feature?.properties?.index as number;
                    if (!feature) {
                        this.adapter.setMapMouseCursor(MapMouseCursor.Crosshair);
                    } else if (index === 0 && path.length > 2) {
                        this.adapter.setMapMouseCursor(MapMouseCursor.Pointer);
                    }
                });

            //Teardown logic.
            subscriber.add(() => {
                this.editorViewState.clear();
                drawPointSubscription.unsubscribe();
                onHandlesLayerClick.unsubscribe();
                onMouseOver.unsubscribe();
            });
        });
    }

    /**
     * Initialize rectangular drawing mode.
     *
     * @protected
     * @returns {Observable<GeoJSON.Polygon>}
     * @memberof MapboxGeodataEditorAdapter
     */
    protected drawAreaRectangular(): Observable<Polygon> {
        const source = this.#map.getSource(EDITOR_GEOJSON_SOURCE) as GeoJSONSource;
        const handlesSource = this.#map.getSource(HANDLES_GEOJSON_SOURCE) as GeoJSONSource;
        const preview: GeoJSON.Feature<GeoJSON.Polygon> = { type: 'Feature', geometry: { type: 'Polygon', coordinates: [[]] }, properties: { featureClass: FeatureClass.POLYGON } };

        this.showPolygonHandles(true);

        return new Observable(subscriber => {
            const onMouseDown = this.#mouseDown()
                .pipe(
                    tap(e => e.preventDefault()),
                    exhaustMap(({ lngLat: p0 }) => this.#mouseMove()
                        .pipe(
                            map(({ lngLat: p1 }) => GeodataEditor.createEnvelope(toPointGeometry(p0.toArray()), toPointGeometry(p1.toArray()))),
                            takeUntil(this.#mouseUp([HANDLES_LAYER]).pipe(skipWhile(({ features }) => features.length < 1))),
                            finalize(() => {
                                subscriber.next(preview.geometry);
                                subscriber.complete();
                            })
                        )
                    )
                ).subscribe(envelope => {
                    preview.geometry = envelope;
                    const handles: GeoJSON.Feature<GeoJSON.Point>[] = GeodataEditor.convertToPoints(envelope);
                    source?.setData(preview);
                    handlesSource?.setData({ type: 'FeatureCollection', features: handles });
                });

            //Teardown logic.
            subscriber.add(() => {
                this.editorViewState.clear();
                onMouseDown.unsubscribe();
                this.showPolygonHandles(false);
            });

        });
    }
}
