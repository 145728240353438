<section class="section" [formGroup]="aliasForm">
    <div class="section__row section__row--align-end">
        <label class="mi-label">
            Search aliases
            <input #aliasInput class="mi-input mi-input--medium" formControlName="aliasInput" type="text"
                placeholder="Search aliases (enter to add)" />
        </label>
        <button (click)="addAlias()" class="mi-button mi-button--base mi-button--medium"
            [disabled]="!aliasForm.valid || aliasInput.value.length === 0">
            Add
        </button>
    </div>
    <small role="alert" class="mi-form__error" *ngIf="aliasForm.get('aliasInput').hasError('valueAlreadyExists')">
        Entered alias is already added
    </small>
    <div class="section__column">
        <div class="chips-list" aria-label="Alias">
            <mat-basic-chip *ngFor="let alias of aliases; let index = index" [removable]="true"
                (removed)="removeAlias(index)">
                {{alias}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-basic-chip>
        </div>
    </div>
</section>