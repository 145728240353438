import { BuildingService } from '../../buildings/building.service';
import { Component } from '@angular/core';
import { Floor } from '../../buildings/floor.model';
import { tap } from 'rxjs/operators';
import { LocationService } from '../../locations/location.service';
import { FloorService } from '../../services/floor.service';

@Component({
    selector: 'floor-selector',
    templateUrl: './floor-selector.component.html',
    styleUrls: ['./floor-selector.component.scss'],
})
export class FloorSelectorComponent {
    #floors: Floor[] = [];
    #selectedFloor: Floor = null;
    #disabled: boolean = false;

    /**
     * When true, the floor selector is disabled.
     * The floor selector is disabled when a location is selected.
     *
     * @readonly
     * @type {boolean}
     * @memberof FloorSelectorComponent
     */
    public get disabled(): boolean {
        return this.#disabled;
    }

    constructor(
        private floorService: FloorService,
        private buildingService: BuildingService,
        private locationService: LocationService
    ) {
        this.buildingService
            .selectedBuilding$
            .pipe(
                tap(building => {
                    if (building?.floors?.length > 0) {
                        this.#floors = [...building.floors.sort((a, b) => b.floorIndex - a.floorIndex)];
                    } else {
                        this.#floors = [];
                    }
                })
            )
            .subscribe();

        this.buildingService
            .selectedFloor$
            .pipe(
                tap(floor => this.#selectedFloor = floor),
            )
            .subscribe();

        this.locationService.selectedLocation$.subscribe(location => this.#disabled = !!location);

        this.floorService.isFloorSelectorDisabled$.subscribe((isFloorSelectorDisabled) => {
            this.#disabled = isFloorSelectorDisabled;
        });
    }

    /**
     * Get the floors of the selected buildings.
     *
     * @readonly
     * @type {Floor[]}
     * @memberof FloorSelectorComponent
     */
    public get floors(): Floor[] {
        return this.#floors;
    }

    /**
     * Get the selected floor.
     *
     * @readonly
     * @type {Floor}
     * @memberof FloorSelectorComponent
     */
    public get selectedFloor(): Floor {
        return this.#selectedFloor;
    }

    /**
     * Get the index of the selected floor.
     *
     * @readonly
     * @type {number}
     * @memberof FloorSelectorComponent
     */
    public get selectedFloorIndex(): number {
        return this.#selectedFloor?.floorIndex;
    }

    /**
     * Select the give floor.
     *
     * @param {Floor} floor
     * @memberof FloorSelectorComponent
     */
    selectFloor(floor: Floor): void {
        this.buildingService.setCurrentFloor(floor);
    }
}