import { Component, Input } from '@angular/core';

@Component({
    selector: 'navigatable-section',
    templateUrl: './navigatable-section.component.html',
    styleUrls: ['./navigatable-section.component.scss']
})
export class NavigatableSectionComponent {
    @Input() header: string;
    @Input() documentationLink: string;

    constructor() { }
}
