import { Component } from '@angular/core';

@Component({
    selector: 'app-fallback',
    templateUrl: './fallback.component.html',
    styleUrls: ['./fallback.component.scss']
})
export class FallbackComponent {

    constructor() { }
}
