import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, switchMap, tap } from 'rxjs/operators';

import { AppUserRole } from './app-user-role.model';
import { Injectable } from '@angular/core';
import { Solution } from '../../../solutions/solution.model';
import { SolutionService } from '../../../services/solution.service';
import { environment } from '../../../../environments/environment';

/**
 * A service to manage Customer App-User Roles.
 */
@Injectable({ providedIn: 'root' })
export class AppUserRolesService {
    private api = environment.APIEndpoint;
    private selectedSolution: Solution;

    #roles: BehaviorSubject<AppUserRole[]> = new BehaviorSubject<AppUserRole[]>([]);
    constructor(private http: HttpClient, private solutionService: SolutionService) {
        this.solutionService.selectedSolution$
            .pipe(
                tap(solution => this.selectedSolution = solution),
                switchMap(() => this.fetchAppUserRoles())
            )
            .subscribe();
    }

    /**
     * Get App-User roles as an observable.
     *
     * @readonly
     * @type {Observable<AppUserRole[]>}
     * @memberof AppUserRolesService
     */
    public get appUserRoles$(): Observable<AppUserRole[]> {
        return this.#roles.asObservable();
    }

    /**
     * Creates a new App-User Role for the solution.
     *
     * @param {AppUserRole} appUserRole - The App-User Role to be created.
     * @returns {Observable<any>}
     */
    public createAppUserRole(appUserRole: AppUserRole): Observable<any> {
        appUserRole.solutionId = this.selectedSolution.id;
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(`${this.api}${this.selectedSolution.id}/api/appUserRoles`, appUserRole, { headers })
            .pipe(switchMap(() => this.fetchAppUserRoles()));
    }

    /**
     * Updates a specific App User Role for the solution.
     *
     * @param {AppUserRole} appUserRole - The App-User Role to be updated.
     * @returns {Observable<any>}
     */
    public updateAppUserRole(appUserRole: AppUserRole): Observable<any> {
        const solution = this.solutionService.getStaticSolution();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.put(`${this.api}${solution.id}/api/appUserRoles`, appUserRole, { headers })
            .pipe(switchMap(() => this.fetchAppUserRoles()));
    }

    /**
     * Deletes a specific App-User Role for the solution.
     *
     * @param {AppUserRole} appUserRole - The App-User Role to be deleted.
     * @returns {Observable<any>}
     */
    public deleteAppUserRole(appUserRole: AppUserRole): Observable<any> {
        const solution = this.solutionService.getStaticSolution();
        return this.http.delete(`${this.api}${solution.id}/api/appUserRoles/${appUserRole.id}`)
            .pipe(switchMap(() => this.fetchAppUserRoles()));
    }

    /**
     * Set the app-user-roles' displayName property.
     *
     * @private
     * @param {AppUserRole[]} roles
     * @param {string} language
     * @returns {AppUserRole[]}
     * @memberof AppUserRolesService
     */
    private setAppUserRoleDisplayName(roles: AppUserRole[], language: string): AppUserRole[] {
        return roles.map(role => {
            const roleName = role.names?.find(name => name.language === language);
            role.displayName = roleName?.name || 'n/a';
            return role;
        });
    }

    /**
     * Gets the App-User roles from the backend.
     *
     * @private
     * @returns {Observable<AppUserRole[]>}
     */
    private fetchAppUserRoles(): Observable<AppUserRole[]> {
        return this.http.get<AppUserRole[]>(`${this.api}${this.selectedSolution.id}/api/appUserRoles`)
            .pipe(
                map(roles => this.setAppUserRoleDisplayName(roles, this.selectedSolution?.defaultLanguage)),
                tap(appUserRoles => this.#roles.next(appUserRoles))
            );
    }
}
