/**
 * Returns the decimals from a number as a string.
 *
 * @param {number} number
 * @returns {string}
 */
export function decimalsFromNumber(number: number):  string {
    if (!number || typeof number !== 'number') return;

    const numberAsString = number.toString();

    if (!numberAsString.includes('.') && !numberAsString.includes(',')) {
        return null;
    } else {
        let decimalPlace: number;
        let decimals: string;

        if (numberAsString.includes('.')) {
            decimalPlace = numberAsString.indexOf('.');
            decimals = numberAsString.substring(decimalPlace + 1, numberAsString.length);

        } else if (numberAsString.includes(',')) {
            decimalPlace = numberAsString.indexOf(',');
            decimals = numberAsString.substring(decimalPlace + 1, numberAsString.length);
        }

        return decimals;
    }
}

/**
 * If the incoming number has more decimals than allowed, the function formats it.
 *
 * @param {number} number
 * @param {number} decimals
 * @returns {number}
 */
export function formattedNumberWithDecimals(number: number, decimals: number): number {
    const scaleDecimals = decimalsFromNumber(number);
    if (scaleDecimals && scaleDecimals.length > decimals) {
        return parseFloat(number.toFixed(decimals));
    } else {
        return number;
    }
}