import { Component, forwardRef, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';
import { filter, map, tap } from 'rxjs/operators';

import { SolutionService } from '../../services/solution.service';
import { TypesService } from '../../services/types.service';
import { primitiveClone } from '../../shared/object-helper';
import { Solution } from '../../solutions/solution.model';
import { Translation } from '../location.model';

@Component({
    selector: 'location-custom-properties',
    templateUrl: './location-custom-properties.component.html',
    styleUrls: ['./location-custom-properties.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => LocationCustomPropertiesComponent),
        multi: true,
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => LocationCustomPropertiesComponent),
        multi: true,
    }]
})
export class LocationCustomPropertiesComponent implements ControlValueAccessor, Validator {
    public formGroup: FormGroup = new FormGroup({});
    public languages: Set<string>;
    public translations: Translation[];
    public isDisabled: boolean = false;
    private currentTranslation: Translation[];
    private inheritedKeys: string[] = [];
    private locationKeys: string[];
    protected selectedSolution: Solution;

    public onChange: (value: Translation[] | null) => void = () => { };
    public onTouch: () => void;
    public onValidatorChange: () => void = () => { };

    /**
     * Setting typeName that we can bind to in Location Details.
     */
    @Input()
    public set typeName(typeName: string) {
        this.typesService.types
            .pipe(
                map(types => types.find(type => type.administrativeId === typeName)),
                filter(type => !!type))
            .subscribe((type) => {
                this.inheritedKeys = type.fields?.map(field => field.text) ?? [];
                if (this.translations) {
                    this.buildCustomPropertiesForm(this.translations);
                }
            });
    }

    constructor(
        private solutionService: SolutionService,
        private formBuilder: FormBuilder,
        private typesService: TypesService
    ) {
        this.solutionService.selectedSolution$.subscribe(selectedSolution => this.selectedSolution = selectedSolution);
        this.formGroup.valueChanges.pipe(
            tap((formValues) => {
                const keys: string[] = formValues[this.defaultLanguage].map(field => field.key);
                for (const language of this.languages) {
                    for (let i = 0; i < keys.length; i++) {
                        //Update the key field for all languages.
                        const defaultKey = this.formGroup.get(`${this.defaultLanguage}.${i}.key`).value;
                        if (language !== this.defaultLanguage && this.formGroup.get(`${language}.${i}.key`).value !== defaultKey) {
                            this.formGroup.get(`${language}.${i}.key`).setValue(defaultKey, { emitEvent: false });
                        }

                        if (defaultKey > '') {
                            this.formGroup.get(`${language}.${i}.value`).enable({ emitEvent: false });
                        } else {
                            this.formGroup.get(`${language}.${i}.value`)?.disable({ emitEvent: false });
                        }
                    }
                }
            }))
            .subscribe(() => {
                if (this.formGroup.valid) {
                    const formValues = this.formGroup.getRawValue();
                    const translations = primitiveClone(this.translations);
                    const fieldsArray = Object.values<any>(this.formGroup.getRawValue()).flat();
                    // An empty Array that will consist of keys that are repeating or value is empty. The procedure is necessary to not duplicate Custom Properties between Types and Locations.
                    const keysToExclude = [];

                    this.inheritedKeys.forEach(key => {
                        if (!this.locationKeys.includes(key) && !fieldsArray.some(field => (field?.key?.toLowerCase() === key?.toLowerCase() && field?.value > ''))) {
                            keysToExclude.push(key.toLowerCase());
                        }
                    });

                    for (const translation of translations) {
                        const language = translation.language;
                        const fields = formValues[language].reduce((fields, field) => {
                            if (keysToExclude.includes(field?.key.toLowerCase())) {
                                return fields;
                            }

                            fields[field.key] = { ...field };
                            delete fields[field.key].key;
                            return fields;
                        }, {});

                        translation.fields = fields;
                    }

                    this.onChange(translations);
                    this.currentTranslation = translations;
                }
                this.onValidatorChange();
            });
    }

    /**
     * Get the default language.
     *
     * @readonly
     * @returns {string}
     */
    public get defaultLanguage(): string {
        return this.selectedSolution.defaultLanguage;
    }

    /**
     * Distinguish when to show warning boxes.
     *
     * @param {string} currentLanguage
     * @param {string} key
     * @param {string} value
     * @returns {boolean}
     */
    public isWarningShown(currentLanguage: string, key: string, value: string): boolean {
        if (!value) return false;

        let isShown: boolean = false;
        // If 'currentLanguage' is generic, and other languages have any value in the same key as generic,
        // we will show warning box
        if (currentLanguage === 'generic') {
            this.languages.forEach(language => {
                // if (language === 'generic') return;

                const languageTranslation = this.currentTranslation.find(translation => translation.language === language);
                const languageKeyValue = languageTranslation?.fields[key]?.value;
                if (languageKeyValue) {
                    isShown = true;
                }
            });
            // If we change data in other languages, and there is value for the same keys,
            // we will show warning box
        } else {
            const languageTranslation = this.currentTranslation.find(translation => translation.language === 'generic');
            const languageKeyValue = languageTranslation?.fields[key]?.value;
            if (languageKeyValue) {
                isShown = true;
            }
        }
        return isShown;
    }

    /**
     * Distinguish when to show warning boxes.
     *
     * @param {string} key
     * @param {string} value
     * @returns {boolean}
     */
    public isKeyInheritingValue(key: string, value: string): boolean {
        if (value) return false;

        // If generic 'language' has value for the same Key in languages,
        // we will prompt the user that Key inherits the value from generic 'language'
        let isValueShown: boolean = false;
        const languageTranslation = this.currentTranslation.find(translation => translation.language === 'generic');
        const languageKeyValue = languageTranslation?.fields[key]?.value;
        if (languageKeyValue) {
            isValueShown = true;
        }

        return isValueShown;
    }

    /**
     * Builds the custom properties form.
     *
     * @private
     * @param {Translation[]} translations
     * @memberof LocationCustomPropertiesComponent
     */
    private buildCustomPropertiesForm(translations: Translation[]): void {
        for (const translation of translations) {
            const language = translation.language;
            const locationFields = new Map(Object.entries(translation.fields).map(([key, field]) => ([key.toLowerCase(), field])));
            const inheritedFields = this.inheritedKeys?.map(inheritedKey => {
                const locationField = locationFields.get(inheritedKey?.toLowerCase()) ?? { value: '', type: 'text', text: '' };
                const fieldGroup: FormGroup = this.formBuilder.group({
                    ...locationField,
                    key: [{ value: inheritedKey, disabled: true }],
                });
                return fieldGroup;
            });

            const fields = Object.entries(translation.fields)
                .filter(([key]) => !this.inheritedKeys.some(inheritedKey => inheritedKey?.toLowerCase() === key?.toLowerCase()))
                .reduce((formArray, [key, field]) => {
                    const fieldGroup: FormGroup = this.formBuilder.group({
                        ...field,
                        key: [{ value: key, disabled: language !== this.defaultLanguage && language !== 'generic' }, [Validators.required, unique(formArray, 'key')]]
                    });
                    formArray.push(fieldGroup);
                    return formArray;
                }, new FormArray([...inheritedFields]));

            this.formGroup.setControl(language, fields, { emitEvent: false });
        }
    }

    /**
     * Adds a new translatable custom property.
     *
     * @memberof LocationCustomPropertiesComponent
     */
    public addProperty(): void {
        for (const language of this.languages) {
            const formArray = (this.formGroup.get(language) as FormArray);
            const fieldGroup: FormGroup = this.formBuilder.group({
                // eslint-disable-next-line
                key: [{ value: '', disabled: language !== this.defaultLanguage }, [Validators.required, unique(formArray, 'key')]],
                text: [''],
                type: ['text'],
                value: [{ value: '', disabled: true }]
            });
            formArray.push(fieldGroup, { emitEvent: false });
        }
        this.formGroup.updateValueAndValidity();
    }

    /**
     * Removes the translatable custom property at the given index.
     *
     * @param {number} index
     * @memberof LocationCustomPropertiesComponent
     */
    public removeProperty(index: number): void {
        for (const language of this.languages) {
            (this.formGroup.get(language) as FormArray).removeAt(index);
        }
    }

    /**
     * Adds a new generic custom property.
     *
     * @memberof LocationCustomPropertiesComponent
     */
    public addGenericProperty(): void {
        const formArray = (this.formGroup.get('generic') as FormArray);
        const fieldGroup: FormGroup = this.formBuilder.group({
            // eslint-disable-next-line
            key: [{ value: '', disabled: false }, [Validators.required, unique(formArray, 'key')]],
            text: [''],
            type: ['text'],
            value: [{ value: '', disabled: false }]
        });

        formArray.push(fieldGroup, { emitEvent: false });
        this.formGroup.updateValueAndValidity();
    }

    /**
     * Removes the generic custom property at the given index.
     *
     * @param {number} index
     * @memberof LocationCustomPropertiesComponent
     */
    public removeGenericProperty(index: number): void {
        (this.formGroup.get('generic') as FormArray).removeAt(index);
    }

    /**
     * Writes a new value to the element.
     *
     * This method is called by the forms API to write to the view when programmatic
     * changes from model to view are requested.
     *
     * @param {Translation[]} translations - The new value for the element.
     */
    writeValue(translations: Translation[]): void {
        if (Array.isArray(translations)) {
            this.locationKeys = Object.keys(translations.find(translation => translation.language === this.defaultLanguage)?.fields);
            this.translations = translations;
            this.currentTranslation = translations;
            this.languages = new Set(translations?.map(translation => translation.language));
            // Generic 'language' is not translatable, so it is removed from languages set
            this.languages.delete('generic');

            this.buildCustomPropertiesForm(translations);
        }
    }

    /**
     * Registers a callback function that is called when the control's value
     * changes in the UI.
     *
     * This method is called by the forms API on initialization to update the form
     * model when values propagate from the view to the model.
     *
     * @param {Function} fn - The callback function to register.
     */
    registerOnChange(fn: (value: Translation[] | null) => void): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that is called by the forms API on initialization
     * to update the form model on blur.
     *
     * @param {Function} fn - The callback function to register.
     */
    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    /**
     * Function that is called by the forms API when the control status changes to
     * or from 'DISABLED'. Depending on the status, it enables or disables the
     * appropriate DOM element.
     *
     * @param {boolean} isDisabled - The disabled status to set on the element.
     */
    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    /**
     * Method that performs synchronous validation against the provided control.
     *
     * @returns {ValidationErrors}
     */
    validate(): ValidationErrors {
        return this.formGroup.invalid ? { invalid: true } : null;
    }

    /**
     * Registers a callback function to call when the validator inputs change.
     *
     * @param {Function} fn - The callback function to register.
     */
    registerOnValidatorChange?(fn: () => void): void {
        this.onValidatorChange = fn;
    }
}

/**
 * Validates that the entered value is unique with in the given FormArray.
 *
 * @param {FormArray} formArray
 * @param {string} [formControlName]
 * @returns {ValidatorFn}
 */
function unique(formArray: FormArray, formControlName?: string): ValidatorFn {
    return (source: AbstractControl): ValidationErrors | null => {
        if (!source?.value) {
            return null;
        }
        const isUnique = !formArray?.controls?.some(control => control !== source.parent && control.get(formControlName)?.value?.toLowerCase() === source.value?.toLowerCase());

        return isUnique ? null : { unique: true };
    };
}