import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { AuthService } from '../auth/auth.service';
import { ErrorLoggerService } from './error-logger.service';
import { environment } from '../../environments/environment';

@Injectable()
export class HttpErrorInterceptorService implements HttpInterceptor {
    constructor(private authService: AuthService, private logger: ErrorLoggerService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                let message;
                if (error.error instanceof ErrorEvent) { // client-side error
                    message = `${error.error.message}`;
                } else  if (error.status === 400) {
                    message = 'Invalid request. Please try again.';
                    if (error.error && error.error.error === 'invalid_grant') { //invalid login credentials
                        return throwError(error);
                    }
                    if (error.error && error.error.message) { // error message from the backend
                        message = `${error.error.message}`;
                    }
                } else if (error.status === 401) {
                    // log the user out only when 401 comes from mapsindoors backend and not an external api
                    if (req.url.includes(environment.APIEndpoint)
                        || req.url.includes(environment.AnalyticsEndpoint)
                        || req.url.includes(environment.authApi)) {
                        message = 'Your session has expired. Please log in again.';
                        this.authService.logout();
                    }
                } else if (error.status === 403) {
                    message = 'You do not have the necessary permissions to perform this action.';
                    if (error.error && error.error.message) { // error message from the backend
                        message = `${error.error.message}`;
                    }
                } else if (error.status === 404) {
                    message = 'The requested resource could not be found.';
                    if (error.error && error.error.message) { // error message from the backend
                        message = `${error.error.message}`;
                    }
                } else if (error.status >= 500) {
                    message = 'An error occured on the server. Please try again later.';
                }

                this.logger.logError(error);
                return message ? throwError(message) : throwError(error);
            })
        );
    }
}
