<form #form="ngForm" (ngSubmit)="onSubmit(form)">
	<div mat-dialog-title>
		{{dialogTitle}} role
		<div fxFlex></div>
		<button mat-icon-button mat-dialog-close>
			<mat-icon>close</mat-icon>
		</button>
	</div>
	<mat-divider></mat-divider>
	<div mat-dialog-content>
		<div fxLayout="column" fxFill>
			<mat-form-field *ngFor="let item of data.appUserRole.names | orderByLanguage:data.defaultLanguage; let i=index;">
				<input matInput name="{{item.language}}" placeholder="Name ({{item.language}})" required 			minlength="3" [(ngModel)]="data.appUserRole.names[i].name" />
			</mat-form-field>
		</div>
	</div>

	<div mat-dialog-actions>
		<button mat-button color="warn" *ngIf="dialogTitle === 'Edit'"
			(click)="onDelete()">Delete</button>
		<div fxFlex></div>
		<button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">Save and close</button>
	</div>
</form>