import { Injectable } from '@angular/core';
import { BaseMapAdapter } from '../MapAdapter/BaseMapAdapter';

@Injectable()
export class MapAdapterMediator {
    private _mapAdapter: BaseMapAdapter;

    /**
     * Set the MapAdapter instance.
     *
     * @param {BaseMapAdapter} mapAdapter
     * @memberof MapAdapterMediator
     */
    public setMapAdapter(mapAdapter: BaseMapAdapter): void {
        this._mapAdapter = mapAdapter;
    }

    /**
     * Get the MapAdapter instance.
     *
     * @returns {BaseMapAdapter}
     */
    public getMapAdapter(): BaseMapAdapter {
        return this._mapAdapter;
    }
}