import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';

import { DialogType } from '../../../../shared/enums';

/**
 * Manages the App-User Roles in a dialog.
 */
@Component({
    selector: 'app-user-role-dialog',
    templateUrl: './app-user-role-dialog.component.html',
    styleUrls: ['./app-user-role-dialog.component.scss']
})
export class AppUserRoleDialogComponent implements OnInit {

    /**
     * The title of the dialog.
     */
    dialogTitle: string;

    constructor(
        public dialogRef: MatDialogRef<AppUserRoleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    /**
     * Init lifecycle hook. Called after the constructor.
     */
    ngOnInit(): void {
        switch (this.data.dialogType) {
            case DialogType.create:
                this.dialogTitle = 'Add';
                break;
            case DialogType.edit:
                this.dialogTitle = 'Edit';
                break;
            case DialogType.delete:
                this.dialogTitle = 'Delete';
                break;
            default:
                break;
        }
    }

    /**
     * Submits the form and closes the dialog if the form is valid.
     *
     * @param {NgForm} form - The submitted form.
     * @memberof AppUserRoleDialogComponent
     */
    onSubmit(form: NgForm): void {
        if (form.valid) {
            this.dialogRef.close(this.data);
        }
    }

    /**
     *
     * @memberof AppUserRoleDialogComponent
     */
    onDelete(): void {
        this.data.dialogType = DialogType.delete;
        this.dialogRef.close(this.data);
    }
}
