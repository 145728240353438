import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { RegexPatterns } from '../../shared/enums';

@Component({
    selector: 'location-rotation',
    templateUrl: './location-rotation.component.html',
    styleUrls: ['./location-rotation.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => LocationRotationComponent),
        multi: true,
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => LocationRotationComponent),
        multi: true,
    }]
})
export class LocationRotationComponent implements ControlValueAccessor, Validator {
    public rotationValue: number;
    public isDisabled: boolean = false;
    public onChange: (rotationValue: number) => void = () => { };
    public onTouch: () => void = () => { };
    public onValidatorChange: () => void;
    public rotationForm: FormGroup;

    constructor(private formBuilder: FormBuilder) {}

    /**
     * Keeps track of the changes of the value in the UI.
     *
     * @memberof LocationRotationComponent
     */
    onValueChanged(event): void {
        this.rotationValue = Number.parseInt(event.target.value);
        this.onChange(this.rotationValue);
        this.onValidatorChange();
    }

    /**
     * Writes a new value to the element.
     *
     * @param {string} rotationValue
     * @memberof LocationRotationComponent
     */
    writeValue(rotationValue: number): void {
        this.rotationValue = rotationValue;
        this.rotationForm = this.formBuilder.group({
            angle: [0, [Validators.min(-180), Validators.max(180), Validators.pattern(RegexPatterns.NumericalNoDecimals)]],
        });
    }

    /**
     * Registers a callback function that is called when the control's value changes in the UI.
     *
     * @memberof LocationRotationComponent
     */
    registerOnChange(fn: (rotationValue: number) => void): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that is called by the forms API on initialization to update the form model on blur.
     *
     * @memberof LocationRotationComponent
     */
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    /**
     * Function that is called by the forms API when the control status changes to or from 'DISABLED'. Depending on the status, it enables or disables the appropriate DOM element.
     *
     * @memberof LocationRotationComponent
     */
    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    /**
     * Registers the validate function.
     *
     * @memberof LocationRotationComponent
     * @returns {ValidationErrors}
     */
    validate(): ValidationErrors {
        return this.rotationForm.invalid ? { invalid: true } : null;
    }

    /**
     * Registers a callback function that is called when the validation changes.
     *
     * @memberof LocationRotationComponent
     */
    registerOnValidatorChange?(fn: () => void): void {
        this.onValidatorChange = fn;
    }
}
