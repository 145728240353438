import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Checks for duplicates in the given string list.
 *
 * @param {string[]} list
 * @returns {ValidatorFn}
 */
export function noDuplicateValidator(list: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (!control) {
            return null;
        }

        if (!list || list.length < 1) {
            return null;
        }

        if (list.indexOf(control.value) !== -1) {
            return { valueAlreadyExists: true };
        }

        return null;
    };
}