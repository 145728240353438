import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { Observable, Subscription, forkJoin } from 'rxjs';

import { Building } from './buildings/building.model';
import { BuildingService } from './buildings/building.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Floor } from './buildings/floor.model';
import { Icons } from './shared/enums';
import { Location } from './locations/location.model';
import { LocationService } from './locations/location.service';
import { NotificationService } from './services/notification.service';
import { SolutionService } from './services/solution.service';
import { TypesService } from './services/types.service';
import { Venue } from './venues/venue.model';
import { VenueService } from './venues/venue.service';
import { finalize } from 'rxjs/operators';

declare let google: any;

@Component({
    selector: 'export',
    templateUrl: './export.component.html',
    styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnDestroy, AfterViewInit {
    private googleMap: google.maps.Map;
    private overlay: google.maps.OverlayView;

    @ViewChild('mapContainer')
    private mapContainer: ElementRef;

    maptiler: any;
    bounds: any;

    mapOptions = {
        maxZoom: 21,
        minZoom: 16,
        disableDefaultUI: true,
        zoomControlOptions: {
            position: google.maps.ControlPosition.LEFT_CENTER
        },
        styles: [
            { 'featureType': 'transit', 'stylers': [{ 'visibility': 'off' }] },
            { 'elementType': 'labels', 'stylers': [{ 'visibility': 'off' }] },
            { 'stylers': [{ 'saturation': -75 }, { 'lightness': 50 }] }
        ]
    };

    currentSolution: any;
    types: any;
    markersArray: any = [];

    selectedStyle: string;

    showSettings: boolean = false;

    iconSize: number = 24;

    exportedIconSize: number = 24;
    labelRotation: number = 0;
    labelProperty: string = 'name';

    northEast: any;
    southWest: any;

    selectedTypes = [];

    previewUrl: string;
    downloadUrl: string;

    scale: number = 1;
    exportFrameRotation: number = 0;
    exportFrameWidth: number = 0;
    exportFrameHeight: number = 0;
    exportFrameTransform: any;
    sanitizedTransform: any;

    // slider
    autoTicks = false;
    invert = false;
    max = 2000;
    min = 10;
    showTicks = false;
    step = 2;
    thumbLabel = true;
    vertical = false;
    private subscriptions = new Subscription();
    private locations: Location[] = [];
    public currentFloor: Floor;
    public floors: Floor[] = [];
    public isProgressbarVisible = false;
    public zoomLevel: number;
    public buildings: Building[] = [];
    public venueDetails: Venue;

    constructor(
        private zone: NgZone,
        private venueService: VenueService,
        private solutionService: SolutionService,
        private locationService: LocationService,
        private typesService: TypesService,
        private sanitizer: DomSanitizer,
        private buildingService: BuildingService,
        private notificationService: NotificationService
    ) { }

    /** NgAfterViewInit. */
    ngAfterViewInit(): void {
        this.subscriptions.add(this.venueService.getSelectedVenue()
            .subscribe(venue => {
                if (venue) {
                    this.currentSolution = this.solutionService.getStaticSolution();
                    this.initVenue(venue);
                }
            }));

        this.subscriptions.add(
            (this.buildingService.getCurrentBuilding() as Observable<Building>)
                .subscribe(building => {
                    if (building) {
                        this.floors = building.floors;
                        this.currentFloor = this.buildingService.getInitialFloor(this.venueDetails);
                        this.buildingService.setCurrentFloor(this.currentFloor);
                    }
                }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Initializes the venue.
     *
     * @private
     * @param {Venue} venue - The venue to be initialized as.
     * @memberof ExportComponent
     */
    private initVenue(venue: Venue): void {
        this.venueDetails = venue;
        this.selectedStyle = this.venueDetails.styles[0].folder;
        this.currentFloor = { floorIndex: +this.venueDetails.defaultFloor, geometry: null, pathData: null, floorInfo: null, solutionId: null, id: null };
        this.locations = null;
        this.fetchData(this.venueDetails);
    }

    /**
     * Fetch buildings, locations and location types.
     *
     * @private
     * @param {Venue} venue
     * @memberof ExportComponent
     */
    private fetchData(venue: Venue): void {
        this.isProgressbarVisible = true;
        forkJoin([
            this.buildingService.getBuildings({ venue: venue.name }),
            this.typesService.getTypes(),
            this.locationService.getLocations()])
            .pipe(finalize(() => {
                this.initGoogleMap();
                this.isProgressbarVisible = false;
            }))
            .subscribe(([buildings, types, locations]) => {
                if (buildings) {
                    this.buildings = buildings;
                    let building = this.buildingService.getCurrentBuilding(true) as Building;
                    if (!building || building.pathData.venue.toLowerCase() !== venue.name.toLowerCase()) {
                        building = buildings && buildings.length > 0 ? buildings[0] : building;
                    }
                    this.buildingService.setCurrentBuilding(building);
                }

                if (types) {
                    this.types = types;
                    this.formatTypes();
                }

                this.locations = locations;
                this.drawMarkers();
            }, error => this.notificationService.showError(error));
    }

    formatTypes(): void {
        for (const type of this.types) {
            if (type.translations) {
                for (const lang of type.translations) {
                    if (lang.language === this.currentSolution.defaultLanguage) {
                        type.displayName = lang.name;
                    }
                }
            } else {
                type.displayName = type.administrativeId;
            }
        }
    }

    // can this be moved to a service, considering the service needs to know the current solution
    iconFromType(type): string {
        const currentType = this.types.find(x => x.administrativeId === type);
        if (currentType && currentType.displayRule.icon) {
            return currentType.displayRule.icon;
        } else {
            return Icons.Default;
        }
    }

    /**
     * Initializes the map.
     *
     * @private
     * @memberof VenueComponent
     */
    private initGoogleMap(): void {
        this.mapOptions.maxZoom = this.currentSolution.modules.some(moduleName => moduleName === 'z22') ? 22 : 21;
        this.googleMap = new google.maps.Map(this.mapContainer.nativeElement, this.mapOptions);
        this.loadTiles();
        this.setMapBounds(this.venueDetails.geometry.bbox);

        this.overlay = new google.maps.OverlayView();
        this.overlay.onAdd = () => {
            this.initExportFrameSize(this.overlay.getProjection());
            this.getUrl(true);
        };

        this.overlay.setMap(this.googleMap);

        this.googleMap.addListener('idle', () => {
            this.getUrl(true);
        });

        this.googleMap.addListener('zoom_changed', () => {
            this.zoomLevel = this.googleMap.getZoom();
        });
    }

    loadTiles(): void {
        if (!this.maptiler) {
            const self = this;
            this.maptiler = new google.maps.ImageMapType({
                getTileUrl: (coord, zoom): string => {
                    if (zoom < this.mapOptions.minZoom) {
                        return null;
                    }
                    const style = self.selectedStyle;
                    const floor = self.currentFloor ? self.currentFloor.floorIndex.toString() : '';
                    const tilesUrl = self.venueDetails.tilesUrl
                        .replace('{x}', coord.x)
                        .replace('{y}', coord.y)
                        .replace('{z}', zoom)
                        .replace('{style}', style)
                        .replace('{floor}', floor);

                    return tilesUrl;
                },
                tileSize: new google.maps.Size(256, 256)
            });

            this.googleMap.overlayMapTypes.insertAt(0, this.maptiler);
        } else {
            this.googleMap.overlayMapTypes.removeAt(0);
            this.googleMap.overlayMapTypes.insertAt(0, this.maptiler);
        }
    }

    setCurrentStyle(): void {
        // setTimeout to be sure the new style is set
        setTimeout(() => {
            this.loadTiles();
            this.getUrl(true);
        }, 50);
    }

    drawMarkers(): void {
        if (this.locations) {
            this.clearMarkers();

            for (const location of this.locations) {
                if (location.pathData.building === '_' || location.pathData.floor === this.currentFloor.floorIndex) {

                    if (this.selectedTypes.indexOf(location.type) !== -1) {
                        const icon = location.displayRule && location.displayRule.icon ? location.displayRule.icon : this.iconFromType(location.type);
                        const markerIcon = {
                            url: icon,
                            anchor: new google.maps.Point((this.iconSize / 2), (this.iconSize / 2)),
                            scaledSize: { width: this.iconSize, height: this.iconSize }
                        };

                        const marker = new google.maps.Marker({
                            map: this.googleMap,
                            icon: markerIcon,
                            clickable: false
                        });

                        if (location.geometry.type === 'Point') {
                            marker.setPosition({ lat: location.geometry.coordinates[1], lng: location.geometry.coordinates[0] });
                        } else {
                            marker.setPosition({ lat: location.anchor.coordinates[1], lng: location.anchor.coordinates[0] });
                        }

                        this.markersArray.push(marker);
                    }
                }
            }
        }
    }

    clearMarkers(): void {
        for (const marker of this.markersArray) {
            marker.setMap(null);
        }
        this.markersArray = [];
    }

    updateTypes(): void {
        const typesArray = [];
        for (const type of this.types) {
            if (type.selected) {
                typesArray.push(type.administrativeId);
            }
        }
        this.selectedTypes = typesArray;
        this.drawMarkers();
        this.getUrl(true);
    }

    clearTypes(): void {
        for (const type of this.types) {
            if (type.selected) {
                type.selected = false;
            }
        }
        this.selectedTypes = [];
        this.drawMarkers();
        this.getUrl(true);
    }

    selectAllTypes(): void {
        const typesArray = [];
        for (const type of this.types) {
            type.selected = true;
            typesArray.push(type.administrativeId);
        }
        this.selectedTypes = typesArray;
        this.drawMarkers();
        this.getUrl(true);
    }

    /**
     * Set the export frame's initial size by converting the overlay's bounds to pixels.
     *
     * @private
     * @memberof ExportComponent
     */
    private initExportFrameSize(projection: google.maps.MapCanvasProjection): void {
        if (projection) {
            const pos1 = projection.fromLatLngToContainerPixel(this.northEast);
            const pos2 = projection.fromLatLngToContainerPixel(this.southWest);
            this.exportFrameWidth = Math.floor(pos2.x - pos1.x);
            this.exportFrameHeight = Math.floor(pos1.y - pos2.y);
        }
    }

    exportFrameRotate(): void {
        this.exportFrameTransform = this.sanitizer.bypassSecurityTrustStyle('rotation(' + this.exportFrameRotation + 'deg) translate(-50%, -50%)');
        this.getUrl(true);
    }

    /**
     * Sets the current floor.
     *
     * @param {Floor} floor
     * @memberof ExportComponent
     */
    public setFloor(floor: Floor): void {
        if (floor && floor.floorIndex !== this.currentFloor.floorIndex) {
            this.currentFloor = floor;
            this.buildingService.setCurrentFloor(this.currentFloor);
            this.loadTiles();
            this.drawMarkers();
            this.getUrl(true);
        }
    }

    checkFrameHeight(): void {
        if (!this.exportFrameHeight || this.exportFrameHeight < 10) {
            this.exportFrameHeight = 10;
        } else {
            if (this.exportFrameHeight > 2000) {
                this.exportFrameHeight = 2000;
            }
        }
        this.getUrl(true);
    }

    checkFrameWidth(): void {
        if (!this.exportFrameWidth || this.exportFrameWidth < 10) {
            this.exportFrameWidth = 10;
        } else {
            if (this.exportFrameWidth > 2000) {
                this.exportFrameWidth = 2000;
            }
        }
        this.getUrl(true);
    }

    closeSidebar(): void {
        this.showSettings = false;
    }

    openSidebar(): void {
        this.showSettings = true;
    }

    downloadTile(): void {
        this.getUrl(false);
        window.open(this.downloadUrl);
    }

    zoomIn(): void {
        let zoom = this.googleMap.getZoom();
        zoom++;
        this.googleMap.setZoom(zoom);
    }

    zoomOut(): void {
        let zoom = this.googleMap.getZoom();
        zoom--;
        this.googleMap.setZoom(zoom);
    }

    /**
     * Create the print url.
     *
     * @private
     * @param {boolean} preview
     * @memberof ExportComponent
     */
    private getUrl(preview: boolean): void {
        this.zone.run(() => {
            const solution = this.solutionService.getStaticSolution();
            if (this.googleMap && this.exportFrameHeight > 0 && this.currentFloor) {
                let scale = this.scale;
                let zoom = this.googleMap.getZoom();
                let height = this.exportFrameHeight;
                let width = this.exportFrameWidth;
                let size = width + 'x' + height;
                const rotation = this.exportFrameRotation * -1;

                const iconSize = this.exportedIconSize;
                const labelRotation = this.labelRotation;
                const labelProperty = this.labelProperty;

                const style = this.selectedStyle;

                let types = this.selectedTypes;
                const url = `https://print.mapsindoors.com/${solution.id}?center=${this.googleMap.getCenter().lat()},${this.googleMap.getCenter().lng()}`;
                if (preview) {
                    types = [];
                    while (height > 600 || width > 400) {
                        height = Math.round(height * 0.5);
                        width = Math.round(width * 0.5);
                        zoom--;
                    }
                    size = width + 'x' + height;
                    scale = 1;
                    this.previewUrl = url + '&zoom=' + zoom + '&floor=' + this.currentFloor.floorIndex + '&size=' + size + '&scale=' + scale + '&types=' + types + '&rotation=' + rotation + '&style=' + style;
                } else {
                    this.downloadUrl = url + '&zoom=' + zoom + '&floor=' + this.currentFloor.floorIndex + '&size=' + size + '&scale=' + scale + '&types=' + types + '&rotation=' + rotation + '&style=' + style + '&label_rotation=' + labelRotation + '&label_property=' + labelProperty + '&icon_size=' + iconSize;
                }
            }
        });
    }

    /**
     * Sets map bounds.
     *
     * @private
     * @param {*} bounds
     * @memberof ExportComponent
     */
    private setMapBounds(bounds: number[]): void {
        if (this.googleMap) {
            this.northEast = new google.maps.LatLng(bounds[1], bounds[0]);
            this.southWest = new google.maps.LatLng(bounds[3], bounds[2]);
            const newBounds = new google.maps.LatLngBounds();
            newBounds.extend(this.northEast);
            newBounds.extend(this.southWest);
            this.googleMap.fitBounds(newBounds);
            this.zoomLevel = this.googleMap.getZoom();
        }
    }

    /**
     * Sets the currently selected building.
     * Set the map bounds to the current building.
     *
     * @param {Building} building
     * @memberof ExportComponent
     */
    public setBuilding(building: Building): void {
        if (building) {
            this.setMapBounds(building.geometry.bbox);
            this.buildingService.setCurrentBuilding(building);
        }
    }
}
