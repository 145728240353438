import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '../services/notification.service';

@Component({
    selector: 'app-data-issue',
    templateUrl: './data-issue.component.html',
    styleUrls: ['./data-issue.component.scss']
})
export class DataIssueComponent implements OnInit {
    displayedColumns = ['element', 'id', 'issue'];
    dataSource = [];

    constructor(
        private route: ActivatedRoute,
        private notificationService: NotificationService) { }

    ngOnInit(): void {
        this.route.data.subscribe(
            data => this.init(data['resolver']),
            error => this.notificationService.showError(error)
        );
    }

    private init(data: any): void {
        this.dataSource = data.transformed;
    }
}
