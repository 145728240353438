import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validates that the entered value is smaller than the provided limit.
 *
 * @param {number} limit
 * @returns {ValidatorFn}
 */
export function isSmallerThanValidator(limit: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        if (!value) return null;

        const isSmallerThanLimit = value < limit;

        return !isSmallerThanLimit ? { isBiggerThanLimit: true } : null;
    };
}

/**
 * Validates that the entered value has less decimal places than the provided limit.
 *
 * @param {number} limit
 * @returns {ValidatorFn}
 */
export function hasMoreDecimalsValidator(limit: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = (control.value)?.toString();

        if (!value) return null;

        const decimalIndex = value.indexOf('.');
        const decimals = value.substring(decimalIndex + 1, value.length);

        return decimals.length > limit ? { hasMoreDecimalsThanLimit: true } : null;
    };
}