import { MultiPolygon, Polygon } from 'geojson';

import { ViewModelProperties } from '../ViewModelProperties/ViewModelProperties';
import { DisplayRule } from '../../app/locations/location.model';
import { SortKey } from '../MapViewModelFactory/MapViewModelFactory';
import { MapViewModel } from '../MapViewModelFactory/MapViewModelFactory';
import { FeatureClass } from '../FeatureClass';
import { MapsIndoorsData } from '../../app/shared/enums/MapsIndoorsData';

abstract class WallViewModelProperties implements ViewModelProperties {
    zoomRange: [min: number, max: number];
    featureClass: FeatureClass;
    originalId: string;
    originalType: MapsIndoorsData;
    sortKey: number;
    height: number;
    color: string;

    /**
     * Factory for creating a WallViewModelProperties object.
     *
     * @static
     * @param {string} id
     * @param {number} sortKey
     * @param {DisplayRule} displayRule
     * @returns {WallViewModelProperties}
     * @memberof WallViewModelProperties
     */
    static async create(id: string, sortKey: number, displayRule: DisplayRule, originalType: MapsIndoorsData): Promise<WallViewModelProperties> {
        return await Promise.resolve({
            originalId: id,
            originalType,
            sortKey: SortKey.WALL + sortKey,
            featureClass: FeatureClass.WALL,
            height: displayRule.walls.height,
            color: displayRule.walls.color,
            zoomRange: [displayRule?.walls.zoomFrom, displayRule?.walls.zoomTo]
        });
    }
}


export class WallViewModel implements MapViewModel {
    readonly id: string;
    readonly type = 'Feature';
    readonly geometry: Polygon | MultiPolygon;
    readonly properties: WallViewModelProperties;

    private constructor(id: string, geometry: Polygon | MultiPolygon, properties: WallViewModelProperties) {
        this.id = `WALL:${id}`;
        this.geometry = geometry;
        this.properties = properties;
    }

    /**
     * Factory to create a WallViewModel from an id, a geometry, and a DisplayRule.
     *
     * @static
     * @param {string} id
     * @param {Polygon | MultiPolygon} geometry
     * @param {DisplayRule} displayRule
     * @param {number} index
     * @returns {WallViewModel}
     * @memberof WallViewModel
     */
    static async create(id: string, geometry: Polygon | MultiPolygon, displayRule: DisplayRule, index: number, originalType: MapsIndoorsData): Promise<WallViewModel> {
        const properties = await WallViewModelProperties.create(id, index, displayRule, originalType);
        const viewModel = new WallViewModel(id, geometry as (Polygon | MultiPolygon), properties);

        return viewModel;
    }
}