import { environment } from '../../../environments/environment';
import { MediaCategory } from '../media.enum';

export interface IMediaItem {
    id: string,
    name: string,
    type: string,
    width: number,
    height: number,
    solutionId?: string,
    preview?: any,
    sizeBytes?: number,
    labels?: string[],
    category?: MediaCategory,
    lastModified?: string,
    references?: {
        appConfigCategoryKeys: string[],
        buildingIds: string[],
        categoryIds: string[],
        isUsedBySolutionConfig: boolean,
        locationIds: string[],
        locationTypeIds: string[],
        referencesCount: number,
        venueIds: string[]
    },
    url?: string,
    selected?: boolean
}

export class MediaItem implements IMediaItem {
    readonly id: string;
    readonly name: string;
    readonly type: string;
    readonly width: number;
    readonly height: number;
    readonly solutionId?: string;
    readonly sizeBytes?: number;
    readonly labels?: string[];
    readonly references?: {
        appConfigCategoryKeys: string[],
        buildingIds: string[],
        categoryIds: string[],
        isUsedBySolutionConfig: boolean,
        locationIds: string[],
        locationTypeIds: string[],
        referencesCount: number,
        venueIds: string[]
    };
    readonly url: string;
    category?: MediaCategory;
    preview?: any;
    lastModified?: string;
    selected: boolean = false;

    constructor(mediaItem: IMediaItem) {
        Object.assign(this, mediaItem);
        this.url = this.createUrl(mediaItem.category);
    }

    /**
     * Creates a url for the media item.
     * If the media is and uploaded media, the url is constructed with the media's name and the '.png' extension.
     * All urls must end with '.png' - Backend specification.
     *
     * @returns {string}
     */
    private createUrl(category: MediaCategory): string {
        let url: string;
        switch (category) {
            case MediaCategory.MIIcon:
                url = this.preview;
                break;
            case MediaCategory.Image:
                // 2D model links must end with '.png'
                url = `${environment.MediaEndpoint}${this.solutionId}/media/${encodeURIComponent(this.name)}.png`;
                break;
            case MediaCategory.Model3D:
                // 3D model links end with '.glb' but in the future they may end with '.gltf'
                url = `${environment.MediaEndpoint}${this.solutionId}/media/${encodeURIComponent(this.name)}.${this.type}`;
                break;
        }
        return url;
    }
}