import { BBox } from 'geojson';

import booleanIntersects from '@turf/boolean-intersects';
import { bboxPolygon, feature as toFeature } from '@turf/turf';
import { Location } from '../../locations/location.model';

import { DisplayRuleService } from '../../services/DisplayRuleService/DisplayRuleService';
import { MapViewModel, MapViewModelFactory } from '../../../viewmodels/MapViewModelFactory/MapViewModelFactory';
import { PointViewModel } from '../../../viewmodels/PointViewModel/PointViewModel';
import { PolygonViewModel } from '../../../viewmodels/PolygonViewModel/PolygonViewModel';
import { MapsIndoorsData } from '../../shared/enums/MapsIndoorsData';
import { GeoJSONGeometryType } from '../../shared/enums';
import { generateOffsetPolygon, getMetersPerPixel } from '../../shared/geometry-helper';
import { primitiveClone } from '../../shared/object-helper';
export class HoveredLocationViewModelFactory extends MapViewModelFactory<Location> {

    constructor(private displayRuleService: DisplayRuleService, private mapAdapter: any) {
        super();
    }

    /**
     * Creates MapViewModels for hover effects for a Location.
     *
     * @param {Location} location
     * @returns {Promise<MapViewModel[]>}
     * @memberof HoveredLocationViewModelFactory
     */
    async create(location: Location): Promise<MapViewModel[]> {
        const viewModels: MapViewModel[] = [];
        const displayRule = primitiveClone(await this.displayRuleService.getDisplayRule());

        switch (location.geometry.type) {
            case GeoJSONGeometryType.Point:
                // Circle icon above all
                displayRule.clickable = false;
                displayRule.iconVisible = true;
                displayRule.icon = '/assets/images/highlight_point.png';
                displayRule.imageSize = {
                    width: 34,
                    height: 34
                };

                viewModels.push(await PointViewModel.create('HOVER:' + location.id, location.geometry as GeoJSON.Point, displayRule, 1e8, MapsIndoorsData.Floor));
                break;
            case GeoJSONGeometryType.Polygon: {
                // Outside polygon with fill
                displayRule.clickable = false;
                displayRule.polygon.visible = true;
                displayRule.polygon.strokeWidth = 2;
                displayRule.polygon.strokeColor = '#EF6CCE';
                displayRule.polygon.strokeOpacity = 1;
                displayRule.polygon.fillColor = '#EF6CCE';
                displayRule.polygon.fillOpacity = 0.35;
                const offsetPolygon = generateOffsetPolygon(location.geometry as GeoJSON.Polygon, 2, getMetersPerPixel(location.geometry as GeoJSON.Polygon, this.mapAdapter.getZoom()));
                viewModels.push(await PolygonViewModel.create('HOVER:' + location.id, offsetPolygon.geometry as GeoJSON.Polygon, displayRule, 1e8, MapsIndoorsData.Floor));
                break;
            }
        }

        return Promise.resolve(viewModels);
    }

    /**
     * Checks if the given location intersects with the given bounds.
     *
     * @param {Location} location
     * @param {BBox} bounds
     * @returns {boolean}
     */
    intersectsWithBounds(location: Location, bounds: BBox): boolean {
        const boundsAsPolygon = bboxPolygon(bounds);
        return booleanIntersects(boundsAsPolygon, toFeature(location.geometry));
    }

    /**
     * Checks if the floorIndex for the given location equals the given floorIndex.
     *
     * @returns {boolean}
     */
    floorEquals(): boolean {
        return true;
    }
}