import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { exhaustMap, filter } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ConfigService } from '../../app-settings/config/config.service';
import { Category } from '../../categories/category.model';

@Injectable()
export class SyncManagerService {
    private api = environment.APIEndpoint;
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(private http: HttpClient, private configService: ConfigService) { }

    /**
     * Synchronize the given image from one solution to others.
     *
     * @param {string} imageId
     * @param {string} sourceSolutionId
     * @param {string[]} targetSolutionIds
     * @returns {Observable<any>}
     * @memberof SyncManagerService
     */
    public syncImageToSolutions(imageId: string, sourceSolutionId: string, targetSolutionIds: string[]): Observable<any> {
        return this.http.post(`${this.api}${sourceSolutionId}/api/images/solutionSync`, targetSolutionIds, {
            headers: this.headers,
            params: { imageId }
        });
    }

    /**
     * Synchronize the given location type from one solution to others.
     *
     * @param {string} locationTypeId
     * @param {string} sourceSolutionId
     * @param {string[]} targetSolutionIds
     * @returns {Observable<any>}
     * @memberof SyncManagerService
     */
    public syncLocationTypeToSolutions(locationTypeId: string, sourceSolutionId: string, targetSolutionIds: string[]): Observable<any> {
        return this.http.post(`${this.api}${sourceSolutionId}/api/locationTypes/solutionSync`, targetSolutionIds, {
            headers: this.headers,
            params: { locationTypeId }
        });
    }

    /**
     * Synchronize the given category from one solution to others.
     * If the app-config's mainmenu field contains the category's key, then synchronize that as well.
     *
     * @param {Category} category
     * @param {string} sourceSolutionId
     * @param {string[]} targetSolutionIds
     * @returns {Observable<any>}
     * @memberof SyncManagerService
     */
    public syncCategoryToSolutions(category: Category, sourceSolutionId: string, targetSolutionIds: string[]): Observable<any> {
        return this.http.post(`${this.api}${sourceSolutionId}/api/categories/solutionSync`, targetSolutionIds, {
            headers: this.headers,
            params: { categoryId: category.id }
        }).pipe(
            filter(() => {
                const categoriesInConfig = this.configService.appConfig && this.configService.appConfig.menuInfo['mainmenu'] ?
                    this.configService.appConfig.menuInfo['mainmenu'] : [];
                return categoriesInConfig.some(item => item.categoryKey === category.key);
            }),
            exhaustMap(() => {
                return this.http.post(`${this.api}${sourceSolutionId}/api/appconfig/categorySolutionSync`, targetSolutionIds, {
                    headers: this.headers,
                    params: { categoryId: category.id }
                });
            }));
    }
}
