import { Router } from "@angular/router";

/**
 * Function that based on Ok/Cancel click in confirmation box, it ensures that we stay on current URL (Cancel) or we will be navigated to the next URL (Ok).
 *
 * @param router
 */
export function stayAtCurrentUrl(router: Router): void {
    const currentRoute = router.routerState.snapshot.url;
    router.navigateByUrl(currentRoute, {skipLocationChange: true});
}