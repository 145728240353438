import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { formatToShortDate } from '../../shared/datetime-helper';

/**
 * Determines whether the input date comes after the minimum date.
 *
 * @param {(string | number | Date)} minDate
 * @returns {ValidatorFn}
 */
export function minimumDate(minDate: string | number | Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value || control.value === '') {
            return null;
        }

        if (!minDate || minDate === '') {
            return null;
        }

        const min = formatToShortDate(minDate);

        if (min > control.value) {
            const formatOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
            return { minError: `Must be after ${new Date(min).toLocaleDateString(undefined, formatOptions)}` };
        }

        return null;
    };
}

/**
 * Determines whether the input date comes before the maximum date.
 *
 * @param {(string | number | Date)} maxDate
 * @returns {ValidatorFn}
 */
export function maximumDate(maxDate: string | number | Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value || control.value === '') {
            return null;
        }

        if (!maxDate || maxDate === '') {
            return null;
        }

        const max = formatToShortDate(maxDate);

        if (max < control.value) {
            const formatOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
            return { maxError: `Must be before ${new Date(max).toLocaleDateString(undefined, formatOptions)}` };
        }

        return null;
    };
}