import { ViewModelProperties } from '../ViewModelProperties/ViewModelProperties';
import { DisplayRule } from '../../app/locations/location.model';
import { MapViewModel } from '../MapViewModelFactory/MapViewModelFactory';
import { SortKey } from '../MapViewModelFactory/MapViewModelFactory';
import { FeatureClass } from '../FeatureClass';
import { MapsIndoorsData } from '../../app/shared/enums/MapsIndoorsData';

abstract class HighlightViewModelProperties implements ViewModelProperties {
    featureClass: FeatureClass;
    originalId: string;
    originalType: MapsIndoorsData;
    sortKey: number;
    pixelOffset: number;
    zoomRange: [min: number, max: number];

    /**
     * Factory for creating a HighlightViewModelProperties object.
     *
     * @static
     * @param {string} id
     * @param {number} sortKey
     * @param {DisplayRule} displayRule
     * @returns {Promise<HighlightViewModelProperties>}
     */
    static create(id: string, sortKey: number, displayRule: DisplayRule, originalType: MapsIndoorsData): HighlightViewModelProperties {
        return {
            featureClass: FeatureClass.HIGHLIGHT,
            originalId: id,
            originalType,
            sortKey: SortKey.POINT + sortKey,
            pixelOffset: ((displayRule?.polygon?.strokeWidth ?? 0) + 2) / 2,
            zoomRange: [-Infinity, Infinity]
        };
    }
}

export class HighlightViewModel implements MapViewModel {
    readonly id: string;
    readonly type = 'Feature';
    readonly geometry: GeoJSON.Point | GeoJSON.Polygon | GeoJSON.LineString;
    readonly properties: HighlightViewModelProperties;

    private constructor(id: string, geometry: GeoJSON.Point | GeoJSON.Polygon | GeoJSON.LineString, properties: HighlightViewModelProperties) {
        this.id = `HIGHLIGHT:${id}`;
        this.geometry = geometry;
        this.properties = properties;
    }

    /**
     * Factory function to create a HighlightViewModel from an id, a point, and a DisplayRule.
     *
     * @static
     * @param {string} id
     * @param {GeoJSON.Point} geometry
     * @param {DisplayRule} displayRule
     * @param {number} index
     * @returns {HighlightViewModel}
     */
    static create(id: string, geometry: GeoJSON.Point | GeoJSON.Polygon | GeoJSON.LineString, displayRule: DisplayRule, index: number, originalType: MapsIndoorsData): HighlightViewModel {
        const properties = HighlightViewModelProperties.create(id, index, displayRule, originalType);
        return new HighlightViewModel(id, geometry, properties);
    }
}
