import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { ErrorLoggerService } from './error-logger.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    // Error handling is important and needs to be loaded first.
    // Because of this we should manually inject the services with Injector.
    constructor(private injector: Injector) {}

    handleError(error): void {
        const errorLogger = this.injector.get(ErrorLoggerService);
        errorLogger.logError(error);
        // !IMPORTANT: Throw error to stop change detection from running again and creating an infinite loop.
        // This happens for errors in html templates or in ngOnChange licycle hook.
        if (error instanceof TypeError) {
            throw error;
        }
    }
}
