/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as turf from '@turf/turf';
import { ValidationChain } from '../ValidationChain';
import { closePolygon } from '../utilities';

/**
 * @static
 * @hideconstructor
 * @class PolygonCombine
 */
class PolygonCombine {
    /**
     * Validates that the two polygons can be combined.
     *
     * @static
     * @param {Object} args
     * @param {Feature<Polygon>|Polygon} args.polygon0 - The first polygon.
     * @param {Feature<Polygon>|Polygon} args.polygon1 - The second polygon.
     * @returns {ValidationResult}
     * @memberof PolygonCombine
     */
    static validate({ polygon0, polygon1 }) {
        [polygon0, polygon1] = alignPolygons(polygon0, polygon1).features;
        return new ValidationChain(
            mustResultInASinglePolygon,
            mustShareAnUnbrokenLineSegmentOfAtLeastOneMeter
        ).validate({ polygon0, polygon1 });
    }

    /**
     * Combine two polygons.
     *
     * @static
     * @param {Object} args
     * @param {Feature<Polygon>|Polygon} args.polygon0 - The first polygon.
     * @param {Feature<Polygon>|Polygon} args.polygon1 - The second polygon.
     * @returns {Feature<Polygon>}
     * @memberof PolygonCombine
     */
    static combine({ polygon0, polygon1 }) {
        [polygon0, polygon1] = alignPolygons(polygon0, polygon1).features;
        return turf.union(polygon0, polygon1);
    }
}


/**
 * Validates that the two Polygons have at least one shared line segment with a length of at least one meter.
 *
 * @param {Object} args
 * @param {Feature<Polygon>|Polygon} args.polygon0 - The first polygon.
 * @param {Feature<Polygon>|Polygon} args.polygon1 - The second polygon.
 * @returns {ValidationResult}
 */
function mustShareAnUnbrokenLineSegmentOfAtLeastOneMeter({ polygon0, polygon1 }) {
    const error = 'The polygons must share en unbroken linesegment of at least 1 meter.';
    //The tolerance is set to 5cm. (5e-5 = 0.00005km = 5cm)
    const isValid = turf.lineOverlap(polygon0, polygon1, { tolerance: 5e-5 })
        .features.map(overlap => turf.length(overlap, { units: 'meters' }))
        .some(length => length >= 1);
    return isValid ? { isValid: true } : { isValid: false, error };
}

/**
 * Validates that the two Polygons can be combined to a single Polygon.
 *
 * @param {Object} args
 * @param {Feature<Polygon>|Polygon} args.polygon0 - The first polygon.
 * @param {Feature<Polygon>|Polygon} args.polygon1 - The second polygon.
 * @returns {ValidationResult}
 */
function mustResultInASinglePolygon({ polygon0, polygon1 }) {
    const error = 'The polygons are not close enough.';
    const combined = turf.union(polygon0, polygon1);
    return combined.geometry.type === 'Polygon' ? { isValid: true } : { isValid: false, error };
}

/**
 * Snaps the two polygons together if any point is within tolerance to the other polygon.
 *
 * @param {Feature<Polygon>|Polygon} polygon0 - The first polygon.
 * @param {Feature<Polygon>|Polygon} polygon1 - The second polygon.
 * @param {number} [tolerance=5] - Tolerance distance in centimeters.
 * @returns {ValidationResult}
 */
function alignPolygons(polygon0, polygon1, tolerance = 5) {
    polygon0 = turf.polygon(turf.getGeom(polygon0).coordinates.slice(0), { ...polygon0.properties });
    polygon1 = turf.polygon(turf.getGeom(polygon1).coordinates.slice(0), { ...polygon1.properties });

    /**
     * Simplifying the polygons helps the alignment to be more efficient and precise.
     * Turf doesn't specify a unit for the tolerance parameter.
     * For a more in-depth explanation see: https://en.wikipedia.org/wiki/Ramer%E2%80%93Douglas%E2%80%93Peucker_algorithm.
     */
    turf.simplify(polygon0, { tolerance: 0.000001, highQuality: true, mutate: true });
    turf.simplify(polygon1, { tolerance: 0.000001, highQuality: true, mutate: true });

    const innerRings0 = polygon0.geometry.coordinates.slice(1)
        .map(ring => turf.polygon([ring.reverse()]).geometry);
    const innerRings1 = polygon1.geometry.coordinates.slice(1)
        .map(ring => turf.polygon([ring.reverse()]).geometry);

    const polyline0 = innerRings0.find(ring => turf.booleanIntersects(ring, polygon1))?.coordinates[0] || polygon0.geometry.coordinates[0];
    const polyline1 = innerRings1.find(ring => turf.booleanIntersects(ring, polygon0))?.coordinates[0] || polygon1.geometry.coordinates[0];

    //Check each polygon against the other.
    [polyline0, polyline1].reduce((polyline1, polyline0) => {
        //Loop through each point of the polygon to find the nearest point on the other polygon.
        for (const [p0Index, p0] of polyline0.entries()) {
            const nearestPoint = turf.nearestPointOnLine({ type: 'LineString', coordinates: polyline1 }, p0, { units: 'centimeters' });
            const nearestPointIndex = nearestPoint.properties.index;
            const distance = nearestPoint.properties.dist;
            const p1 = polyline1[nearestPointIndex];
            //Checks that p0 and p1 is not the same point and the distance is within the tolerance.
            if (!turf.booleanEqual(turf.point(p0), turf.point(p1)) && distance <= tolerance) {
                //Checks if nearestPoint is already known or interpolated.
                if (!turf.booleanEqual(nearestPoint, turf.point(p1))) {
                    //If nearestPoint is interpolated a new point is inserted into the polygon.
                    polyline1.splice(nearestPointIndex + 1, 0, p0);
                } else {
                    //If nearestPoint is an already known point then an average between p0 and p1 is calculated.
                    const xMin = Math.min(p0[0], p1[0]);
                    const xMax = Math.max(p0[0], p1[0]);
                    const yMin = Math.min(p0[1], p1[1]);
                    const yMax = Math.max(p0[1], p1[1]);
                    const pAverage = [xMin + ((xMax - xMin) / 2), yMin + ((yMax - yMin) / 2)];

                    //Both p0 and p1 in the Polygons are replaced by the new point.
                    polyline0[p0Index] = pAverage;
                    polyline1[nearestPointIndex] = pAverage;
                }
            }
        }

        return polyline0;
    }, polyline1);

    polygon0.geometry.coordinates = [polygon0.geometry.coordinates[0], ...innerRings0.map(geometry => geometry.coordinates[0].reverse())];
    polygon1.geometry.coordinates = [polygon1.geometry.coordinates[0], ...innerRings1.map(geometry => geometry.coordinates[0].reverse())];

    [polygon0, polygon1].forEach(polygon => {
        turf.truncate(polygon, { precision: 7, mutate: true });
        closePolygon(polygon);
    });

    return { type: 'FeatureCollection', features: [polygon0, polygon1] };
}



export { PolygonCombine, alignPolygons, mustShareAnUnbrokenLineSegmentOfAtLeastOneMeter };
