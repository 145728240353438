/**
 * Creates function that memoizes the results of a given function.
 * Use this to cache results of heavy calculations.
 *
 * Important: The arguments in the passed in function should all be primitives as they are used to generate a cache key.
 *
 * @param {Function} fn - The function to setup memoization for.
 * @returns {Function} - A memoized function.
 */
export default function (fn: Function): any {
    const cache = new Map();

    return (...args: any[]) => {
        const cacheKey = args.join('-');
        if (cache.has(cacheKey)) {
            return cache.get(cacheKey);
        } else {
            const result = fn.apply(this, args);
            cache.set(cacheKey, result);
            return result;
        }
    };
}
