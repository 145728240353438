import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MenuNode, MenuService } from './services/menu.service';
import { NEWS_VERSION, NewsComponent } from './news/news.component';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';

import { AuthService } from './auth/auth.service';
import { ConfigService } from './app-settings/config/config.service';
import { Customer } from './customers/customer.model';
import { CustomerService } from './customers/customer.service';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from './services/notification.service';
import { SharedFunctionsService } from './services/shared-functions.service';
import { Solution } from './solutions/solution.model';
import { SolutionService } from './services/solution.service';
import { User } from './user.model';
import { UserService } from './services/user.service';
import { Venue } from './venues/venue.model';
import { VenueService } from './venues/venue.service';
import { primitiveClone } from './shared/object-helper';
import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('notification') notificationComponent: ElementRef<HTMLMiNotificationElement>;

    private subscriptions = new Subscription();
    private currentUser: User;
    private userIsAdmin = false;
    private selectedVenue: Venue;
    private solutions: Solution[] = [];
    private currentSolution: Solution;
    private config: any;

    public isAuthenticated = false;
    public mainPageLoad = false;
    public venues: Venue[] = [];
    public activeSolutions: Solution[] = [];
    public appLink: string;
    public menuData: MenuNode[] = [];

    constructor(
        private router: Router,
        private authService: AuthService,
        private solutionService: SolutionService,
        private venueService: VenueService,
        private userService: UserService,
        private configService: ConfigService,
        private notificationService: NotificationService,
        private menuService: MenuService,
        private sharedFnService: SharedFunctionsService,
        private customerService: CustomerService,
        private dialog: MatDialog
    ) { }

    /** NgOnInit. */
    ngOnInit(): void {
        this.initGoogleAnalytics();
        // subscribe to authentication
        this.subscriptions
            .add(this.authService.isAuthenticated$
                .pipe(
                    tap(isAuth => this.isAuthenticated = isAuth),
                    filter(isAuth => isAuth)
                )
                .subscribe(() => this.initData()))
            // subscribe to current venue change
            .add(this.venueService.getSelectedVenue()
                .subscribe(venue => {
                    if (venue) {
                        this.selectedVenue = venue;
                        // set the display name of the selected venue
                        const venueInfo = this.selectedVenue.venueInfo.find(_info => _info.language === this.currentSolution.defaultLanguage);
                        this.selectedVenue.displayName = venueInfo ? venueInfo.name : this.selectedVenue.venueInfo[0].name;
                        // update the selected venue in the venues array
                        for (let i = 0; i < this.venues.length; i++) {
                            if (this.venues[i].id === this.selectedVenue.id) {
                                this.venues[i] = this.selectedVenue;
                                break;
                            }
                        }
                    }
                }))
            // subscribe to updates on the current venue
            .add(this.venueService.getVenueEvent().subscribe(() => this.reloadVenues()))
            // Update the solution list when a solution is deleted
            .add(this.solutionService.solutionDeleted
                .subscribe(id => {
                    this.activeSolutions = this.activeSolutions.filter(solution => solution.id !== id);
                }))
            .add(this.solutionService.solutionUpdated
                .subscribe(updatedSolution => {
                    // Create a new array of solutions to update the solutions dropdown
                    this.activeSolutions = this.activeSolutions.map(solution => {
                        if (solution.id === updatedSolution.id) {
                            return updatedSolution;
                        }

                        return { ...solution };
                    });

                    // if it is the current solution, then republish it to the subscribers
                    if (updatedSolution.id === this.currentSolution.id) {
                        this.currentSolution = updatedSolution;
                        this.solutionService.setCurrentSolution(updatedSolution, this.userIsAdmin);
                    }
                }))
            // Update the menu tree when the current customer is updated
            .add((this.customerService.getCurrentCustomer() as Observable<Customer>)
                .subscribe(() => this.setMenuData(this.userIsAdmin)));


        // when there is a router event, save the attemptedUrl
        this.router.events
            .pipe(
                filter((e: RouterEvent): e is RouterEvent => e instanceof RouterEvent),
                tap((e: RouterEvent) => this.authService.attemptedUrl = e.url),
                filter((e: RouterEvent): e is NavigationEnd => e instanceof NavigationEnd && this.isAuthenticated)
            )
            .subscribe(() => this.setMenuData(this.userIsAdmin));
    }

    /** NgOnDestroy. */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /** NgAfterViewInit. */
    ngAfterViewInit(): void {
        this.notificationService.setNotificationReference(this.notificationComponent);
    }

    /**
     * Fetch the user, the solutions and the app config.
     *
     * @memberof AppComponent
     */
    private initData(): void {
        this.mainPageLoad = true;

        this.userService.getUser().pipe(
            switchMap(user => {
                this.currentUser = user;
                if (this.currentUser.roles.includes('owner') || this.currentUser.roles.includes('admin')) {
                    this.userIsAdmin = true;
                } else {
                    this.userIsAdmin = false;
                }

                return this.solutionService.getSolutions();
            }),
            finalize(() => this.mainPageLoad = false)
        ).subscribe(
            (solutions: Solution[]) => {
                this.solutions = solutions;
                this.activeSolutions = this.formatSolutions(this.solutions);
                this.currentSolution = this.activeSolutions[0];
                if (localStorage['currentSolution']) {
                    for (const solution of this.activeSolutions) {
                        if (solution.id === localStorage['currentSolution']) {
                            this.currentSolution = solution;
                            break;
                        }
                    }
                }

                this.solutionService.setCurrentSolution(this.currentSolution, this.userIsAdmin);
                this.setMenuData(this.userIsAdmin);
                // get appconfig
                this.getConfig();
                this.autoOpenNewsDialog();
            }, error => {
                this.notificationService.showError(error);
            });

        this.venueService.venues$.subscribe(venues => {
            this.setVenues(venues);
            this.setMenuData(this.userIsAdmin);
        }, error => this.notificationService.showError(error));
    }

    /**
     * Set solution.
     *
     * @param {Solution} solution
     * @memberof AppComponent
     */
    public setSolution(solution: Solution): void {
        this.currentSolution = solution;
        localStorage.removeItem('MI:VenueId'); // reset the stored venue before changing solution
        this.solutionService.setCurrentSolution(this.currentSolution, this.userIsAdmin);
        this.getConfig();
    }

    /**
     * Returns an array of active solutions.
     *
     * @private
     * @param {*} solutions
     * @returns {Array<any>}
     * @memberof AppComponent
     */
    private formatSolutions(solutions): Array<any> {
        const today = new Date(Date.now());
        const activeSolutions = [];

        for (const solution of solutions) {

            const date = new Date(solution.expirationDate);
            if (solution.expirationDate && date < today) {
                solution.expired = true;
            } else {
                activeSolutions.push(solution);
            }
        }
        this.solutionService.publishActiveSolutions(activeSolutions);
        return activeSolutions;
    }


    /**
     * Format the venues.
     * Set the first venue as the selected one.
     *
     * @private
     * @param {Venue[]} venues
     * @memberof AppComponent
     */
    private setVenues(venues: Venue[]): void {
        if (venues.length > 0) {
            this.venues = venues.sort((a, b) => (a?.displayName || '').localeCompare(b?.displayName));
            const storedVenueId = localStorage ? localStorage.getItem('MI:VenueId') : null;
            this.selectedVenue = this.venues.find(venue => venue.id === storedVenueId) || this.venues[0];
            this.venueService.setSelectedVenue(this.selectedVenue);
        }
    }

    /**
     * Reload venues.
     */
    private reloadVenues(): void {
        if (this.currentSolution) {
            this.subscriptions.add(this.venueService.getVenues()
                .subscribe(venues => this.venues = venues));
        }
    }

    /**
     * Get the app config.
     *
     * @private
     * @memberof AppComponent
     */
    private getConfig(): void {
        this.configService.getConfig()
            .subscribe(
                config => {
                    this.config = config;
                    this.stdAppLink();
                }, error => {
                    if (error !== 'Appconfig not found') {
                        this.notificationService.showError(error);
                    }
                }
            );
    }

    /**
     * Standard App Link.
     */
    private stdAppLink(): void {
        if (this.config && this.config.appId) {
            this.appLink = 'https://clients.mapsindoors.com/' + this.config.appId;
        } else {
            this.appLink = null;
        }
    }

    /**
     * Sets the menu according to the logged in user.
     *
     * @param {boolean} userIsAdmin
     * @memberof AppComponent
     */
    private setMenuData(userIsAdmin: boolean): void {
        const menuTreeCopy = primitiveClone(this.menuService.getMenuTreeData());
        const currentCustomer = this.customerService.getCurrentCustomer(true) as Customer;

        this.menuData = menuTreeCopy.reduce((menu: MenuNode[], parent) => {
            if (parent.id === 'old-map' && !this.currentUser?.roles.includes('owner')) {
                return menu;
            }

            if (userIsAdmin) {
                const children = [];

                parent.children?.forEach((child: MenuNode) => {
                    const segments = child.url.split('/');

                    switch (segments[1]) {
                        case 'export':
                            if (this.sharedFnService.checkModule('export', this.currentSolution))
                                children.push(child);
                            break;
                        case 'logs':
                            if (this.sharedFnService.checkModule('logs', this.currentSolution))
                                children.push(child);
                            break;
                        case 'dashboard':
                            if (currentCustomer && currentCustomer.modules && currentCustomer.modules.includes('dashboard'))
                                children.push(child);
                            break;
                        case 'deployment':
                            if (this.currentUser && this.currentUser.roles.includes('owner')) {
                                children.push(child);
                            }
                            break;
                        case 'solution-settings':
                            if (this.currentUser && this.currentUser.roles.includes('admin') || this.currentUser.roles.includes('owner')) {
                                children.push(child);
                            }
                            break;
                        case 'app-settings':
                            children.push(this.setAppSettingsChildren(child, currentCustomer));
                            break;
                        default:
                            children.push(child);
                            break;
                    }
                });

                parent.children = children;
            } else {
                parent.children = parent.children?.filter((child: MenuNode) => {
                    const segments = child.url.split('/');

                    return segments[1] === 'export' && this.sharedFnService.checkModule('export', this.currentSolution);
                });
            }

            menu.push(parent);

            return menu;
        }, []);
    }

    /**
     * Sets app settings children.
     *
     * @param {MenuNode} menuNode
     * @param {Customer} currentCustomer
     * @returns {MenuNode}
     */
    private setAppSettingsChildren(menuNode: MenuNode, currentCustomer: Customer): MenuNode {
        const children: MenuNode[] = [];

        menuNode.children.forEach(child => {
            const childUrl = child.url.split('/');

            switch (childUrl[2]) {
                case 'booking':
                    currentCustomer?.modules?.includes('booking') && children.push(child);
                    break;
                case 'webex':
                    currentCustomer?.modules?.includes('webex') && children.push(child);
                    break;
                default:
                    children.push(child);
                    break;
            }
        });

        menuNode.children = children;

        return menuNode;
    }

    /**
     * Open what's new dialog.
     *
     * @memberof AppComponent
     */
    public openNewsDialog(): void {
        this.dialog.open(NewsComponent, {
            width: '650px',
            role: 'dialog'
        });
    }

    /**
     * Open what's new dialog if there is a new version.
     *
     * @private
     * @memberof AppComponent
     */
    private autoOpenNewsDialog(): void {
        const newsVersion = localStorage.getItem('newsVersion');

        if (!newsVersion || +newsVersion < NEWS_VERSION) {
            this.openNewsDialog();
        }
    }

    /**
     * Initializes the Google Analytics scripts.
     *
     * @private
     */
    private initGoogleAnalytics(): void {
        if (environment.googleAnalytics > '') {
            const script = document.createElement('script');
            script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalytics}`;
            script.async = true;
            document.getElementsByTagName('head')[0].appendChild(script);

            const gtagScript = document.createElement('script');
            gtagScript.innerText = `
                window.dataLayer = window.dataLayer || [];
                function gtag() { dataLayer.push(arguments); }

                gtag('js', new Date());
                gtag('config', '${environment.googleAnalytics}');
            `;
            document.body.appendChild(gtagScript);

        }
    }
}
