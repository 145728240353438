<div fxFlexFill fxLayout="column">
    <form #form="ngForm" fxFlexFill fxLayout="column">
        <!-- Toolbar -->
        <mat-toolbar class="mat-elevation-z2 page-toolbar" fxLayout="row" fxLayoutGap="16px" >
            <div>
                <button mat-icon-button class="mat-elevation-z2" (click)="goToNewVenue()" matTooltip="Create a new Venue">
                    <mat-icon>add_circle_outline</mat-icon>
                </button>
            </div>
            <div *ngIf="venueDetails" fxLayout="row" fxLayoutAlign="space-evenly center"  fxLayoutGap="8px">
                <button mat-icon-button class="mat-elevation-z2" (click)="openMenu('data')" matTooltip="Edit venue">
                    <mat-icon>edit</mat-icon>
                </button>
                <h2 *ngIf="venueDetails.displayName" class="toolbar-title">{{venueDetails.displayName}}</h2>
            </div>
            <div>
                <button (click)="openTilesManager()" type="button" mat-raised-button>Manage tiles</button>
            </div>
            <div fxFlex></div>
            <div>
                <button color="primary" [disabled]="!form.valid || !venueBoundsValid" (click)="saveVenue()"
                    style="margin-right: 0px;" mat-raised-button>Save venue</button>
            </div>
        </mat-toolbar>

        <mat-progress-bar color="accent" mode="indeterminate" *ngIf="isProgressbarVisible"></mat-progress-bar>

        <!-- Edit venue panel -->
        <div fxLayout="column" fxLayoutAlign="start stretch" class="list-sidebar mat-elevation-z8"
            *ngIf="activeMenu === 'data'">
            <!-- Edit - toolbar -->
            <mat-toolbar>
                <div fxFlex>
                    <h2 *ngIf="venueDetails.displayName">{{venueDetails.displayName}}</h2>
                </div>
                <div fxFlex="<grow>">
                </div>
                <div fxFlex>
                    <button (click)="closeMenu()" mat-icon-button>
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </mat-toolbar>

            <div fxLayout="column" fxLayoutAlign="start stretch" class="page-content column-margin"
                *ngIf="venueDetails">
                <!-- Edit - venue details -->
                <div fxLayout="row" class="column">
                    <div fxFlex="50">
                        <p class="discrete">Administrative ID</p>
                        <p>{{venueDetails.name}}</p>
                    </div>
                    <div fxFlex="50">
                        <p class="discrete">ID</p>
                        <p>{{venueDetails.id}}</p>
                    </div>
                </div>

                <div fxLayout="column" class="column">
                    <div class="sub-section-header">
                        <h3>Graph Setup</h3>
                    </div>
                    <mat-radio-group fxLayout="row" fxLayoutGap="32px" 
                        [(ngModel)]="venueDetails.graphSetup" name="graphSetup"
                        aria-label="Select a graph setup">
                        <mat-radio-button [value]="GraphSetup.Manual" id="graphSetup.Manual">Manual</mat-radio-button>
                        <mat-radio-button [value]="GraphSetup.Automatic">Automatic</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </div>

        <!-- Map -->
        <div class="map-container" fxFlex>
            <div #map fxFlex id="map"></div>
            <div class="map-overlay zoom-indicator mat-elevation-z4" *ngIf="zoomLevel">Zoom:
                <span style="font-weight: bold;">{{zoomLevel}}</span>
            </div>
            <div *ngIf="editVenueOutline" class="bounds-action-bar mat-elevation-z4">
                <button (click)="resetBounds()" matTooltip="New bounds (start over)" type="button" mat-icon-button>
                    <mat-icon style="transform: scale(-1, 1);">refresh</mat-icon>
                </button>
            </div>
            <div *ngIf="editVenueOutline" class="json-panel mat-elevation-z4">
                <div fxLayout="row">
                    <div style="margin-right: 16px;" fxFlex="<grow>">
                        <h3 style="margin-top: 11px;">Venue bounds json</h3>
                    </div>
                    <div fxFlex style="margin: 2px 16px 0 0;">
                        <button *ngIf="displayBoundsCode" (click)="copyBoundsCode()" type="button" mat-raised-button>
                            <mat-icon>content_copy</mat-icon>
                            Copy
                        </button>
                    </div>
                    <div fxFlex>
                        <button (click)="toggleBoundsCode()" type="button" mat-icon-button>
                            <mat-icon *ngIf="!displayBoundsCode">visibility</mat-icon>
                            <mat-icon *ngIf="displayBoundsCode">close</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="inner">
                    <pre *ngIf="displayBoundsCode">
						{{venueBoundsCode}}
					</pre>
                </div>
            </div>
            <!-- Building selector -->
            <app-building-selector *ngIf="buildings && activeMenu !== 'tiles' && activeMenu !== 'data' && !editVenueOutline" 
                class="building-selector map-overlay mat-elevation-z4" 
                [buildings]="buildings | orderBy:'displayName'"
                (selectedBuildingEvent)="setBuilding($event)">
            </app-building-selector>
            <div class="floor-selector-container">
                <div class="floor-selector mat-elevation-z4" *ngIf="floors?.length > 0 && currentFloor">
                    <a [class.active]="currentFloor.floorIndex === floor.floorIndex" (click)="setFloor(floor)"
                        *ngFor="let floor of floors | orderNumBy: 'floorIndex':true">{{floor.displayName}} </a>
                </div>
            </div>
            <div style="position: absolute; right: 32px; bottom: 32px">
                <button *ngIf="!editVenueOutline" matTooltip="Edit graph bounds" mat-fab color="accent"
                    (click)="initVenueOutline();">
                    <mat-icon>timeline</mat-icon>
                </button>
                <button *ngIf="editVenueOutline" matTooltip="End editing graph bounds" mat-fab color="accent"
                    (click)="endVenueOutline();">
                    <mat-icon>cancel</mat-icon>
                </button>
            </div>
        </div>

    </form>
</div>
