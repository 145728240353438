import { Component, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { GeodataEditor } from '../../../../GeodataEditor/GeodataEditor';
import { GeodataEditorFactory } from '../../../../GeodataEditor/GeodataEditor.factory';
import { LocationType } from '../../../../locations/location.model';
import { LocationService } from '../../../../locations/location.service';
import { BaseMapAdapter, MapMouseCursor } from '../../../../MapAdapter/BaseMapAdapter';
import { DisplayRuleService } from '../../../../services/DisplayRuleService/DisplayRuleService';
import { LocationDetailsEditorComponent } from '../../../location-details-editor/location-details-editor.component';
import { MapAdapterMediator } from '../../../map-adapter.mediator';
import { MapSidebarService } from '../../../map-sidebar/map-sidebar.service';
import { MapToolbar } from '../../map-toolbar.interface';

@Component({
    selector: 'add-poi-toolbar',
    templateUrl: './add-poi-toolbar.component.html',
    styleUrls: ['./add-poi-toolbar.component.scss'],
})
export class AddPOIToolbarComponent implements MapToolbar, OnInit, OnDestroy {
    private _destroySubject: Subject<void> = new Subject();
    private _geoDataEditor: GeodataEditor;
    private _mapAdapter: BaseMapAdapter;
    private _subscriptions: Subscription = new Subscription();

    @Output() public readonly destroy = this._destroySubject.asObservable();

    constructor(
        private displayRuleService: DisplayRuleService,
        private mapAdapterMediator: MapAdapterMediator,
        private locationService: LocationService,
        private mapSidebar: MapSidebarService
    ) { }

    /**
     * Angular OnInit lifecycle hook.
     */
    ngOnInit(): void {
        this._mapAdapter = this.mapAdapterMediator?.getMapAdapter();
        this._geoDataEditor = GeodataEditorFactory.create(this._mapAdapter, this.displayRuleService, this.locationService);
        this._mapAdapter.setMapMouseCursor(MapMouseCursor.Crosshair);
        const drawPointSubscription = this._geoDataEditor.drawPoint()
            .pipe(take(1))
            .subscribe((point) => {
                const location = this.locationService.createNewLocation(point);
                location.locationType = LocationType.POI;
                const { componentInstance } = this.mapSidebar.open(LocationDetailsEditorComponent);
                if (componentInstance) {
                    componentInstance.data = location;
                }

                this._destroySubject.next();
            });

        this._subscriptions.add(drawPointSubscription);
    }

    /**
     * Angular OnDestroy lifecycle hook.
     */
    ngOnDestroy(): void {
        this._mapAdapter.setMapMouseCursor(MapMouseCursor.Default);
        this._subscriptions.unsubscribe();
        this._destroySubject.complete();
    }

    /**
     * Resets the cursor to defualt, stops the event-listener on the map and hides the current toolbar..
     */
    public onCancel(): void {
        // eslint-disable-next-line no-alert
        if (confirm('Canceling without saving will discard any changes you have made.')) {
            this._destroySubject.next();
        }
    }

    /**
     * Cancels the add poi operation.
     *
     * @param {KeyboardEvent} event
     */
    @HostListener('document:keydown.escape', ['$event'])
    private onEscapeHandler(event: KeyboardEvent): void {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.onCancel();
    }
}