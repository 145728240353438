import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: 'input[setFocus]'
})
export class SetFocusDirective implements AfterViewInit {
    @Input() setFocus: boolean;

    constructor(private element: ElementRef) {
    }

    /**
     * A callback method that is invoked immediately after
     * Angular has completed initialization of a component's view.
     * It is invoked only once when the view is instantiated.
     */
    ngAfterViewInit(): void {
        if (this.setFocus && !this.element.nativeElement.disabled) {
            this.element.nativeElement.focus();
        }
    }
}