import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { Dimension } from '../dimension-model/dimension.model';

@Directive({
    selector: 'img[responsiveImageStyle]',
})
export class ResponsiveImageStyleDirective implements OnInit {
    private container: HTMLElement;

    constructor(
        private element: ElementRef
    ) {}

    /**
     * Reassessing the style for the image based on the new window size.
     */
    @HostListener('window:resize')
    onResize(): void {
        const containerDimension = this.getContainerSize(this.container);
        const imageDimension = this.getImageNaturalSize(this.element.nativeElement);

        if (imageDimension.isGreaterThan(containerDimension)) {
            this.element.nativeElement.classList.add('image-object-cover');
        } else {
            this.element.nativeElement.classList.remove('image-object-cover');
        }
    }

    /**
     * Angular - ngOnInit.
     */
    ngOnInit(): void {
        this.container = this.element.nativeElement.parentElement;

        //When the image is loaded onResize is called to set the initial state.
        this.element.nativeElement.onload = () => this.onResize();
    }

    /**
     * Gets the container's dimension.
     *
     * @param {HTMLElement} container
     * @returns {Dimension}
     */
    private getContainerSize(container: HTMLElement): Dimension {
        const dimension: { width: number, height: number } = {
            width: container.offsetWidth,
            height: container.offsetHeight
        };

        return new Dimension(dimension);
    }

    /**
     * Gets the image's dimension.
     *
     * @param {HTMLImageElement} image
     * @returns {Dimension}
     */
    private getImageNaturalSize(image: HTMLImageElement): Dimension {
        const dimension: { width: number, height: number } = {
            width: image.naturalWidth,
            height: image.naturalHeight
        };

        return new Dimension(dimension);
    }
}