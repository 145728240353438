import { ElementRef, Injectable } from '@angular/core';
import { NotificationType } from '../shared/enums';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private notification: ElementRef<HTMLMiNotificationElement>;

    /**
     * Pushes a notification to the view.
     *
     * @param {string} message - The message you'd like to send.
     * @param {NotificationType} notificationType - The type of notification you'd like to display.
     * @param {boolean} [isSticky=false] - Whether or not the notification should be sticky.
     * @param {number} [duration=4] - How long the notification will appear when not sticky.
     */
    private notify(message: string, notificationType: NotificationType = NotificationType.None, isSticky: boolean, duration: number): void {
        if (!this.notification) {
            return;
        }

        this.notification.nativeElement.duration = duration;
        this.notification.nativeElement.push(message, notificationType, isSticky);
    }

    /**
     * Sets the reference to an mi-notification element.
     *
     * @param {ElementRef<HTMLMiNotificationElement>} component
     */
    public setNotificationReference(component: ElementRef<HTMLMiNotificationElement>): void {
        this.notification = component;
    }

    /**
     * Pushes an info notification to the view.
     *
     * @param {string} message
     * @param {boolean} [isSticky=false]
     * @param {number} [duration=4]
     * @memberof NotificationService
     */
    public showInfo(message: string, isSticky = false, duration = 4): void {
        this.notify(message, NotificationType.Info, isSticky, duration);
    }

    /**
     * Pushes a success notification to the view.
     *
     * @param {string} message
     * @param {boolean} [isSticky=false]
     * @param {number} [duration=4]
     * @memberof NotificationService
     */
    public showSuccess(message: string, isSticky = false, duration = 4): void {
        this.notify(message, NotificationType.Success, isSticky, duration);
    }

    /**
     * Pushes a warning notification to the view.
     *
     * @param {string} message
     * @param {boolean} [isSticky=false]
     * @param {number} [duration=4]
     * @memberof NotificationService
     */
    public showWarning(message: string, isSticky = false, duration = 4): void {
        this.notify(message, NotificationType.Warning, isSticky, duration);
    }

    /**
     * Pushes an error notification to the view.
     *
     * @param {(string | Error)} error
     * @param {boolean} [isSticky=false]
     * @param {number} [duration=4]
     * @memberof NotificationService
     */
    public showError(error: string | Error, isSticky = false, duration = 4): void {
        let message = 'Unknown error';
        if (typeof error === 'string') {
            message = error;
        } else if (error && error.message) {
            message = error.message;
        }
        this.notify(message, NotificationType.Error, isSticky, duration);
    }

}
