<div fxLayout="column">
	<mat-toolbar class="mat-elevation-z2 page-toolbar">
		<div fxFlex="<grow>">
			<button (click)="newUser()" mat-raised-button [disabled]="creatingNewUser">
				New user
			</button>
		</div>
		<!-- Search query -->
		<div fxFlex class="IE-FlexAuto">
			<mat-form-field class="transparent-btn" style="width: 240px; margin-right: 0px;">
				<input [(ngModel)]="searchQuery" matInput placeholder="Search users (by email)">
				<button mat-button *ngIf="searchQuery.length >= 1" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
					<mat-icon>close</mat-icon>
				</button>
			</mat-form-field>

		</div>
		<!-- Clear all filters  -->
		<div fxFlex class="IE-FlexAuto" style="margin-left: 16px;">
			<!-- If any filter is true, then set disable to false to enabling the button-->
			<button color="accent" [disabled]="searchQuery.length < 1" (click)="clearFilters()" mat-stroked-button>
				Clear
			</button>
		</div>
	</mat-toolbar>

	<mat-progress-bar mode="indeterminate" color="accent" *ngIf="pageLoad"></mat-progress-bar>

	<!-- Edit sidebar -->
	<div fxLayout="column" class="list-sidebar mat-elevation-z8" *ngIf="currentUser">
		<mat-toolbar class="secondary-toolbar">
			<div fxFlex class="IE-FlexAuto" style="position: relative;">
				<button [disabled]="saving || !form.valid || noSolution" (click)="saveUser()" mat-raised-button color="primary">
					<span *ngIf="creatingNewUser">Create</span>
					<span *ngIf="!creatingNewUser">Save and close</span>
				</button>
				<button *ngIf="!creatingNewUser" (click)="openDeleteOverlay()" mat-icon-button>
					<mat-icon>delete</mat-icon>
				</button>
				<div *ngIf="showDeleteOverlay" class="delete-overlay mat-elevation-z2">
					<p style="margin-top: 0px; margin-right: 30px; font-size: 15px;">Delete this user?</p>
					<div (click)="closeDeleteOverlay()" class="close-overlay">
						<mat-icon>close</mat-icon>
					</div>
					<button (click)="deleteUser()" mat-raised-button>
						<mat-icon color="warn">delete</mat-icon>
						<span> Really delete</span>
					</button>
					<button (click)="closeDeleteOverlay()" mat-raised-button>
						<span>Cancel</span>
					</button>
				</div>
			</div>
			<div fxFlex="<grow>">
				<h2 *ngIf="creatingNewUser" style="margin-left: 16px">New user</h2>
			</div>
			<div fxFlex class="IE-FlexAuto">
				<button (click)="closeSidebar()" mat-icon-button>
					<mat-icon>close</mat-icon>
				</button>
			</div>
		</mat-toolbar>
		<div style="overflow: auto;" fxFlex="<grow>" class="page-content">
			<div class="section" fxLayout="column">
				<form #form="ngForm" fxLayout="column">
					<div class="column" fxFlex>
						<div>
							<div *ngIf="!creatingNewUser">
								<h3>{{currentUser.userName}}</h3>
							</div>
							<div *ngIf="creatingNewUser">
								<mat-form-field style="width: 320px;">
									<input name="email" type="email" required="required" [(ngModel)]="currentUser.userName" placeholder="Email(s)" matInput />
								</mat-form-field>
								<p>Separate emails with "," to add multiple users with the same settings.</p>
							</div>
						</div>
						<div>
							<div class="sub-section-header">
								<h3>Role</h3>
							</div>
							<div>
								<mat-radio-group name="role" [(ngModel)]="currentUser.role">
									<div *ngIf="currentUser.role === 'Legacy'">
										<mat-radio-button [disabled]="true" value="Legacy">Legacy CMS user</mat-radio-button>
										<br />
										<p>This user currently does not have access to the CMS, to enable this user select a new role and save.</p>
									</div>
									<mat-radio-button value="Editor">Editor</mat-radio-button>
									<br />
									<br />
									<mat-radio-button value="Administrator">Administrator
										<mat-icon style="vertical-align: middle;">security</mat-icon>
									</mat-radio-button>
								</mat-radio-group>
							</div>
						</div>
						<div>
							<div class="sub-section-header">
								<h3>Solutions</h3>
							</div>
							<div>
								<mat-form-field *ngIf="solutions.length > 10" style="width: 320px;">
									<input matInput placeholder="Filter solutions" name="filter-solution" [(ngModel)]="filterQuery">
								</mat-form-field>
								<div style="padding: 10px 0px;" *ngFor="let solution of solutions | orderBy:'name' | filterSolutions:filterQuery">
									<mat-checkbox name="{{solution.id}}" (change)="solutionsChanged()" [(ngModel)]="solution.selected">{{solution.name}}</mat-checkbox>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>

	<div class="table-container">
		<table *ngIf="users" class="std-table">
			<tr class="sticky">
				<th></th>
				<th></th>
				<th class="sortable" (click)="sortTable('userName')" [class.active]="currentSortBy === 'userName'">
					User
					<span class="table-arrow">
						<mat-icon [ngClass]="{'reverse-180': sortBy === '-userName'}">keyboard_arrow_down</mat-icon>
					</span>
				</th>
				<th class="sortable" (click)="sortTable('solutionString')" [class.active]="currentSortBy === 'solutionString'">
					Solutions
					<span class="table-arrow">
						<mat-icon [ngClass]="{'reverse-180': sortBy === '-solutionString'}">keyboard_arrow_down</mat-icon>
					</span>
				</th>
			</tr>
			<tr *ngFor="let user of users | orderBy:sortBy:sortReverse | filterUsers: searchQuery">
				<td style="width: 40px; padding-right: 0px;">
					<button *ngIf="user.role != 'Owner'" mat-icon-button (click)="editUser(user)">
						<mat-icon>edit</mat-icon>
					</button>
				</td>
				<td style="width: 40px; padding-right: 0px;">
					<mat-icon matTooltip="Administrator" color="accent" *ngIf="user.role == 'Administrator'" style="vertical-align: middle;">security</mat-icon>
					<mat-icon *ngIf="user.role == 'Editor'" style="vertical-align: middle; opacity: 0;">person</mat-icon>
				</td>
				<td>
					<p [class.discrete]="user.role == 'Legacy'">
						{{user.userName}}
						
					</p>
				</td>
				<td>
					<span *ngIf="user.role != 'Owner'">{{user.solutionString}}</span>
				</td>
			</tr>
		</table>

	</div>
</div>