<locations-list [ngClass]="{'expand': isListViewOpen, 'inactive': (currentViewState !== viewStates.Default)}">
    <filters-bar  #filterbar
        [isListViewOpen]="isListViewOpen"
        [canOpenBulkEdit]="canOpenBulkEdit"
        (listViewtoggle)="toggleListView()"
        (openBulkEdit)="onOpenBulkEditor()">
    </filters-bar>

    <mi-data-table
        id="locations-table"
        selectable
        (selectionChanged)="onListViewRowSelectionChanged($event)"
        max-rows="1000"
        [rows]="filteredLocations"
        [selected]="selectedLocations"
        empty-page-header="No results found"
        empty-page-subheader="Adjust your filter or search to find new results."
        [ngClass]="{'hidden': !isListViewOpen}">
        <mi-column (clicked)="setCurrentLocation($event.detail)" align-content="center" width="48px">
            <img src="/assets/images/edit_20px.svg" alt="Edit Location" style="cursor: pointer;" />
        </mi-column>
        <mi-column (clicked)="showLocationOnMapAndHighlightLocation($event)" align-content="center" width="48px">
            <img src="../../../assets/images/show-on-map_20px.svg" title="Show on map" alt="Show on map" style="cursor: pointer;" />
        </mi-column>
        <mi-column label="Name" binding="name" sortable width="25%"></mi-column>
        <mi-column label="Icon" fit-content align-content="center" width="48px"><img src="{icon}" height="24px" /></mi-column>
        <mi-column label="Type" binding="locationTypeName" sortable width="25%"></mi-column>
        <mi-column label="External ID" binding="externalId" sortable monospace width="25%"></mi-column>
        <mi-column label="Floor" binding="floorName" sortable fit-content></mi-column>
        <mi-column label="Building" binding="buildingName" sortable width="25%"></mi-column>
        <mi-column label="Last modified" binding="lastModifiedDate" sortable="date" sort="desc" width="256px"></mi-column>
    </mi-data-table>
</locations-list>

<div class="floor-selector-container" [ngClass]="{'floor-selector-container--inactive': (currentViewState === viewStates.AddDoor || currentViewState === viewStates.AddMultipleDoors || isListViewOpen)}">
    <div class="floor-selector" *ngIf="focusedBuildingFloors?.length > 0">
        <a [class.active]="currentFloor?.floorIndex === floor.floorIndex" (click)="setFloor(floor)"
            *ngFor="let floor of focusedBuildingFloors | orderNumBy: 'floorIndex':true">
            {{floor.displayName}}
        </a>
    </div>
</div>

<map-toolbar
    [ngClass]="{'inactive': (currentViewState === viewStates.Update || isListViewOpen)}"
    [mapZoomLevel]="currentZoomLevel"
    [venue]="currentVenue"
    [buildings]="buildings"
    [floor]="currentFloor"
    [isNetworkVisible]="isNetworkVisible"
    (networkVisibilityChange)="setNetworkElementsVisibility($event)">
</map-toolbar>

<app-location-details
    *ngIf="currentLocation"
    (openSplitLocation)="openSplitLocationDialog()"
    (openCombineLocations)="openCombineLocationsDialog()"
    (startAddDoorFlow)="startAddDoorFlow($event)"
    (startAddMultipleDoorsFlow)="startAddMultipleDoorsFlow($event)"
    (deleteLocation)="onDeleteLocation()"
    [currentSolution]="currentSolution"
    [location]="currentLocation">
</app-location-details>

<app-locations-bulk-edit
    *ngIf="isBulkEditorOpen"
    [locations]="selectedLocations"
    (closeBulkEditor)="closeBulkEditor()"
    (bulkLocationsUpdate)="onBulkLocationsUpdate()">
</app-locations-bulk-edit>

<route-element-details
    *ngIf="currentRouteElement"
    [routeElement]="currentRouteElement">
</route-element-details>

<app-graph-list
    *ngIf="graphIds"
    [graphIds]="graphIds"
    (graphListClose)="onGraphListClose()"
    (activeGraphChange)="onActiveGraphChange($event)">
</app-graph-list>

<div #map id="map"></div>
