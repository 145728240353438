export class DerivedGeometryProperties {
    floorIndex: number;
}

export class DerivedGeometry {
    geodataId: string;
    geodataType: string;
    geometry: GeoJSON.Geometry;
    properties: DerivedGeometryProperties;
    type: DerivedGeometryType;

    constructor() {
        this.properties = new DerivedGeometryProperties();
    }
}

export enum DerivedGeometryType {
    WALL = 'wall',
    EXTRUSION = 'extrusion'
}
