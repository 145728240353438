<div class="container">
    <media-item
        #mediaItemContainer 
        *ngFor="let mediaItem of mediaItems"
        [mediaItem]="mediaItem"
        [showSyncButton]="showSyncButton"
        [ngClass]="{'media-item--highlighted': mediaItem.selected === true}"
        class="media-item"
        (syncButtonClicked)="onOpenSync($event)"
        (mediaItemSelected)="onSelectMediaItem($event)">
    </media-item>
</div>

<div *ngIf="mediaItems?.length === 0" class="empty">
    <p class="empty__main">No results found</p>
    <p class="empty__sub">Adjust your filter or search to find new results</p>
</div>