import { Feature, LineString, Point, Polygon } from 'geojson';


export interface RouteNetwork {
    type: string,
    features: Feature[]
}

export interface RouteElement {
    id?: string;
    administrativeId?: string;
    solutionId: string;
    graphId: string;
    venueId: string;
    geometry?: Point | LineString | Polygon;
    radius?: number;
    type: RouteElementType;
    subtype?: number;
    openingHours?: string;
    floorIndex: number;
    elementValue?: number;
    restrictions?: string[];
    onewayDirection?: number;
    waitTime?: number;
    labels?: RouteElementLabel[];
    fields?: {};
    lastModified?: string;
    lastModifiedBy?: string;
    createdAt?: string;
    createdBy?: string;
    marker?: google.maps.Marker;
    icon?: string;
}

export interface RouteElementLabel {
    name: string;
    language: string;
}

export interface DoorElement extends RouteElement {
    geometry?: LineString;
    width?: number;
    type: RouteElementType.Door
}

/**
 * Type of elements of the route layer.
 * - Barrier 0 - No particular modifier.
 * - Prefer 1 - Prefer to go here. Subtract cost to routes going though.
 * - Avoid 2 - Add an extra cost to routes going through.
 * - Connector 3 - Allows travel between this point and ANY other connector with the same connectorId given via ElementValue.
 * - Door 4 - Allows travel between any room(s) within the area. Which Doors this point is can be seen via EntryPointSubtype.
 * - EntryPoint 5 - Tells if a point is connectable to external (google) maps. Which vehicles this point is for can be seen via EntryPointSubtype.
 *
 * @enum {number}
 */
export enum RouteElementType {
    Barrier,
    Prefer,
    Avoid,
    Connector,
    Door,
    EntryPoint
}

/**
 * Route access modifier.
 *
 * @enum {number}
 */
export enum RouteAccessModifier {
    Open,
    Restricted,
    Closed
}

/**
 * Door subtype can either be of subtype Door = 1 or ElevatorDoor = 2.
 *
 * @enum {number}
 */
export enum DoorType {
    Door = 1,
    ElevatorDoor,
    ExternalDoor,
    InterBuildingDoor,
    Opening,
    Hatchway
}

/**
 * Entrypoint subtype.
 *
 * @enum {number}
 */
export enum EntryPointTypes {
    AnyEntry = 1,
    WalkingEntry = 2,
    DrivingEntry = 4,
    BicyclingEntry = 8,
    TransitEntry = 16
}

/**
 * Specific Entry Points for entry point subtype.
 *
 * @enum {number}
 */
export enum SpecificEntryPoints {
    WalkingEntry = 2,
    DrivingEntry = 4,
    BicyclingEntry = 8,
    TransitEntry = 16
}

/**
 * Connector subtype.
 *
 * @enum {number}
 */
export enum ConnectorType {
    Stairs = 1,
    Elevator = 2,
    Escalator = 3,
    Ramp = 4,
    WheelchairRamp = 5,
    WheelchairLift = 6,
    Ladder = 7
}
