<!-- Edit existing panorama -->
<div class="street-view" *ngIf="staticPanoUrl; else setPanoramaTemplate">
	<p>The image below is the image shown on this location in the web app.<br>
		<br>
		Click the image to change it or click the bin to remove the Street View for this location.
	</p>
	<div class="street-view__image-wrap">
		<img [src]="staticPanoUrl">
		<span class="street-view__edit">
			<button type="button" class="mi-button mi-button--base" (click)="editPanorama()">Change</button>
		</span>
        <button class="mi-button mi-button--delete mi-button--icon street-view__delete" (click)="deletePanorama()">
            <img src="/assets/images/trash-bin_20x20.svg">
		</button>
	</div>
</div>

<!-- Set a panorama -->
<ng-template #setPanoramaTemplate>
    <section class="street-view">
        <p>
            If Google Street View is available at {{building}} it can be enabled in the web app for this location by setting
            a Street View image matching this location:
        </p>
        <ol class="street-view__list">
            <li>
                <section class="street-view__column">
                    Click “Set Street View image” below
                    <details>
                        <summary>Show more</summary>
                        This will open a Google Street View window showing an image closest to this location. In the
                        case an outside image is shown it can be because Google Street View is not available in your
                        building - you need to check that with your organisation or examine using Google maps.
                    </details>
                </section>
            </li>
            <li>Navigate Street View and find an image and viewing angle that is suitable</li>
            <li>Click “Set image”</li>
        </ol>
        <p>The image set will be shown in the web app for the user to access the indoor Street View.</p>
        <button type="button" class="mi-button mi-button--primary" (click)="setPanorama()">
            Set Street View image
        </button>
    </section>
</ng-template>