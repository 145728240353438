import { Injectable } from '@angular/core';


@Injectable()
export class SharedFunctionsService {

    constructor() { }


    /**
     * Generate a random number lower than the given maximum.
     *
     * @param {*} max
     * @returns {number}
     * @memberof SharedFunctionsService
     */
    getRandomInt(max): number {
        return Math.floor(Math.random() * Math.floor(max));
    }

    generateID(): string {
        const chars = 'abcdefghijklmnopqrstuvwxyz1234567890';
        let id = '';

        for (let i = 0; i < 8; i++) {
            const position = this.getRandomInt(chars.length);
            const char = chars.substring(position, (position + 1));
            id = id + char;
        }
        return id;
    }

    checkModule(moduleString, solution): boolean {
        let moduleExists = false;
        if (solution && solution.modules) {
            for (const module of solution.modules) {
                if (module.toLowerCase() === moduleString.toLowerCase()) {
                    moduleExists = true;
                }
            }
        }
        return moduleExists;
    }

    /**
     * Generates a GUID.
     *
     * @returns {string}
     * @memberof SharedFunctionsService
     */
    public generateGuid(): string {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }
}
