import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, catchError } from 'rxjs/operators';
import { SolutionService } from '../services/solution.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class DataIssueResolver implements Resolve<any> {
    constructor(
    private solutionService: SolutionService,
    private spinner: NgxSpinnerService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot
    ): Observable<any> | Promise<any> | any {
        this.spinner.show();
        return this.solutionService.getDataIssuesOf(route.params['solutionId']).pipe(
            map(data => {
                data.transformed = [];

                for (const key in data.issueData) {
                    const value = data.issueData[key] as any[];
                    value.forEach(item => {
                        data.transformed.push({
                            element: key,
                            id: item.id,
                            administrativeId: item.administrativeId,
                            issue: item.issue
                        });
                    });
                }
                this.spinner.hide();
                return data;
            }),
            take(1),
            catchError(err => {
                this.spinner.hide();
                return err;
            })
        );
    }
}
