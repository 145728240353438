import { Component, EventEmitter, Output, Type } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription, of } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { BuildingService } from '../../../../buildings/building.service';
import { RouteElement } from '../../../../map/route-element-details/route-element.model';
import { NetworkService } from '../../../../network-access/network.service';
import { RouteElementMapViewModelFactory } from '../../../../network-access/RouteElementMapViewModelFactory/RouteElementMapViewModelFactory';
import { RouteNetworkMapViewModelFactory } from '../../../../network-access/RouteNetworkMapViewModelFactory/RouteNetworkMapViewModelFactory';
import { DisplayRuleService } from '../../../../services/DisplayRuleService/DisplayRuleService';
import { NotificationService } from '../../../../services/notification.service';
import { UserService } from '../../../../services/user.service';
import { VenueService } from '../../../../venues/venue.service';
import { MapAdapterMediator } from '../../../map-adapter.mediator';
import { MapToolbar } from '../../map-toolbar.interface';
import { AddBarrierComponent } from './add-barrier/add-barrier.component';
import { AddEntryPointComponent } from './add-entry-point/add-entry-point.component';
import { AddFloorConnectorComponent } from './add-floor-connector/add-floor-connector.component';
import { AddDoorComponent } from './add-door/add-door.component';
import { GraphSetup } from '../../../../shared/enums/GraphSetup.enum';
import { Venue } from '../../../../venues/venue.model';
import { Floor } from '../../../../buildings/floor.model';
import { EditGraphBoundsComponent } from './edit-graph-bounds/edit-graph-bounds.component';
import { MapSidebarService } from '../../../map-sidebar/map-sidebar.service';
import { GraphListComponent } from '../../../graph-list/graph-list.component';

@Component({
    selector: 'route-network-tools',
    templateUrl: './route-network-tools.component.html',
    styleUrls: ['./route-network-tools.component.scss']
})
export class RouteNetworkToolsComponent {
    private isRouteNetworkVisible: boolean = false;
    private routeElements$: Observable<RouteElement[]>;
    private routeNetwork$: Observable<GeoJSON.Feature[]>;
    private currentVenue: Venue;
    private currentFloor: Floor;
    private graphIds: string[] = [];
    private readonly GraphSetup = GraphSetup;

    public routeNetworkChanged: boolean = false;
    public isOwner: boolean = false;
    public isAdmin: boolean = false;
    public isAddDoorButtonVisible: boolean = true;

    public readonly Tools = {
        AddBarrierComponent,
        AddFloorConnectorComponent,
        AddEntryPointComponent,
        AddDoorComponent,
        EditGraphBoundsComponent
    };

    @Output() toolSelected = new EventEmitter<Type<MapToolbar>>();

    constructor(
        private venueService: VenueService,
        private buildingService: BuildingService,
        private networkService: NetworkService,
        private mapAdapterMediator: MapAdapterMediator,
        private userService: UserService,
        private displayRuleService: DisplayRuleService,
        private notificationService: NotificationService,
        private spinner: NgxSpinnerService,
        private mapSidebar: MapSidebarService) {

        this.userService.getCurrentUserObservable().subscribe(() => {
            this.isOwner = this.userService.hasOwnerPrivileges();
            this.isAdmin = this.userService.hasAdminPrivileges();
        });

        this.venueService.selectedVenue$.subscribe((venue) => {
            this.currentVenue = venue;
            this.isAddDoorButtonVisible = this.currentVenue.graphSetup === GraphSetup.Automatic && this.isAdmin;
        });

        this.buildingService.selectedFloor$.subscribe((floor) => {
            this.currentFloor = floor;
        });

        this.networkService.routeElementsUpdates$.subscribe(() => {
            this.routeNetworkChanged = true;
        });

        const solutionGraphs = this.networkService.getSolutionGraphs();
        solutionGraphs.subscribe((graphs) => this.graphIds = graphs.map(graph => graph.graphId));
    }

    /**
     * Sets the selected tool active.
     *
     * @param {Type<MapToolbar>} typeOfTool
     * @memberof DefaultMapToolsComponent
     */
    public setToolActive(typeOfTool: Type<MapToolbar>): void {
        this.toolSelected.emit(typeOfTool);
        if (!this.isRouteNetworkVisible) {
            this.toggleRouteNetworkVisibility();
        }
    }

    /**
     * Toggle the visibility of the route network and -elements.
     *
     * @memberof MapToolbarComponent
     */
    public toggleRouteNetworkVisibility(): void {
        const mapAdapter = this.mapAdapterMediator?.getMapAdapter();
        if (mapAdapter) {
            this.isRouteNetworkVisible = !this.isRouteNetworkVisible;
            if (this.isRouteNetworkVisible) {
                this.spinner.show();
                this.routeElements$ = this.networkService.routeElements$;
                this.routeNetwork$ = this.venueService.selectedVenue$
                    .pipe(
                        switchMap(venue => this.buildingService.selectedFloor$
                            .pipe(
                                switchMap(floor => this.networkService.loadRouteNetwork(venue.graphId, floor.floorIndex))
                            )
                        ),
                        tap(() => this.spinner.hide()),
                        catchError((error) => {
                            this.spinner.hide();
                            this.isRouteNetworkVisible = false;
                            this.notificationService.showError(error);
                            return of(error);
                        })
                    );
                mapAdapter.viewState.addDataSource(this.routeNetwork$, new RouteNetworkMapViewModelFactory());
                mapAdapter.viewState.addDataSource(this.routeElements$, new RouteElementMapViewModelFactory(this.displayRuleService));
            } else {
                mapAdapter.viewState.removeDataSource(this.routeElements$);
                mapAdapter.viewState.removeDataSource(this.routeNetwork$);
            }
        }
    }

    /**
     * Refetches and redraws the network graph on the map.
     */
    public reloadRouteNetwork(): void {
        if (this.isRouteNetworkVisible) {
            this.toggleRouteNetworkVisibility();
        }

        this.spinner.show();
        this.networkService.loadGraph(this.currentVenue?.graphId, this.currentFloor?.floorIndex)
            .pipe(finalize(() => this.spinner.hide()))
            .subscribe(() => {
                this.routeNetworkChanged = false;
                this.notificationService.showSuccess('Route Network reloaded!');
                this.toggleRouteNetworkVisibility();
            }, error => this.notificationService.showError(error));
    }

    /**
     * Requests the backend to drop all network graph data and generate new ones.
     *
     * @returns {Subscription}
     */
    public regenerateAllGraphData(): Subscription {
        if (this.isRouteNetworkVisible) {
            this.toggleRouteNetworkVisibility();
        }

        this.spinner.show();
        this.notificationService.showInfo('Removing all generated graph data');
        return this.networkService.regenerateGraphData(this.currentVenue.graphId)
            .pipe(
                finalize(() => {
                    this.notificationService.showSuccess('Old generated data cleared. Creating new data', false, 6);
                    this.toggleRouteNetworkVisibility();
                })
            ).subscribe();
    }

    /**
     * Opens Graph List Editor.
     */
    public openGraphListEditor(): any {
        if (this.graphIds.length > 0) {
            this.mapSidebar.open(GraphListComponent);
        } else {
            this.notificationService.showError('No graphs found in this solution!');
        }
    }
}