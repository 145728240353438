import { Component, OnInit } from '@angular/core';
import { finalize, switchMap } from 'rxjs/operators';

import { NotificationService } from './services/notification.service';
import { SolutionService } from './services/solution.service';
import { UserService } from './services/user.service';
import { forkJoin } from 'rxjs';
import { primitiveClone } from './shared/object-helper';

@Component({
    selector: 'users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
    pageLoad: boolean = false;
    searchQuery: string = '';

    solutions: any;

    creatingNewUser: boolean = false;
    noSolution: boolean = false;

    showDeleteOverlay: boolean = false;

    filterQuery: string = '';

    users = [];
    currentUser: any;
    user: any;
    selectedRow: string = ''; // Highlight row when selected
    sortBy: string = 'userName';
    currentSortBy: string = 'userName';
    sortReverse: boolean = false;

    constructor(
        private userService: UserService,
        private solutionService: SolutionService,
        private notificationService: NotificationService
    ) { }

    ngOnInit(): void {
        this.pageLoad = true;
        forkJoin([this.solutionService.getSolutions(), this.userService.getUsers()])
            .pipe(finalize(() => this.pageLoad = false))
            .subscribe(
                (result: any) => {
                    this.solutions = result[0];
                    this.users = result[1];
                    this.formatUsers();
                }, error => {
                    this.notificationService.showError(error);
                }
            );
    }

    /**
     * Formats the users name.
     *
     * @memberof UsersComponent
     */
    private formatUsers(): void {
        for (const user of this.users) {
            user.display = true;
            if (user.roles.includes('owner')) {
                user.role = 'Owner';
            } else {
                if (user.roles.includes('admin')) {
                    user.role = 'Administrator';
                } else if (user.roles.includes('editor')) {
                    user.role = 'Editor';
                } else {
                    user.role = 'Legacy';
                }
            }

            user.userName = user.userName.toLowerCase();
            this.setSolutionString(user);
        }
    }

    /**
     * Users can belong to solutions this user does not see.
     * Make sure that solution management for users dont overwrite but look up and add/delete.
     *
     * @param {*} user
     * @memberof UsersComponent
     */
    setSolutionString(user): void {
        let solutionString = '';
        for (const solution of user.solutions) {
            let solutionName = '';
            for (const sol of this.solutions) {
                if (sol.id === solution) {
                    solutionName = sol.name;
                }
            }
            if (solutionString.length > 0 && solutionName.length > 0) {
                solutionString += ', ' + solutionName;
            } else {
                if (solutionName.length > 0) {
                    solutionString = solutionName;
                }

            }
        }
        user.solutionString = solutionString;
    }

    setSelectedSolutions(): void {
        for (const solution of this.currentUser.solutions) {
            for (const sol of this.solutions) {
                if (sol.id === solution) {
                    sol.selected = true;
                }
            }
        }
    }

    resetSelectedSolutions(): void {
        for (const solution of this.solutions) {
            solution.selected = false;
        }
    }

    sortTable(sortBy): void {
        if (this.sortBy === sortBy) {
            this.sortBy = '-' + sortBy;
            this.sortReverse = !this.sortReverse;
        } else if (this.sortBy === '-' + sortBy) {
            this.sortBy = sortBy;
            this.sortReverse = !this.sortReverse;
        } else {
            this.sortBy = sortBy;
            this.currentSortBy = sortBy;
            this.sortReverse = false;
        }
    }

    editUser(user): void {
        this.noSolution = false;
        this.resetSelectedSolutions();
        this.creatingNewUser = false;
        this.currentUser = user;
        this.setSelectedSolutions();

        this.selectedRow = user.id; // Highlight selected row
    }

    newUser(): void {
        this.noSolution = true;
        this.creatingNewUser = true;
        this.currentUser = {
            id: null,
            role: 'Editor',
            solutions: [],
            userName: '',
            password: null,
            display: true,
            roles: ['editor']
        };
    }

    formatBeforeSave(): void {
        for (const solution of this.solutions) {
            if (solution.selected === true) {
                if (this.currentUser.solutions.indexOf(solution.id) === -1) {
                    this.currentUser.solutions.push(solution.id);
                }
            } else {
                let index = 0;
                if (this.currentUser.solutions.indexOf(solution.id) !== -1) {
                    index = this.currentUser.solutions.indexOf(solution.id);
                    this.currentUser.solutions.splice(index, 1);
                }
            }
        }
        if (this.currentUser.role === 'Administrator') {
            this.currentUser.roles = ['admin'];
        }

        if (this.currentUser.role === 'Editor') {
            this.currentUser.roles = ['editor'];
        }
    }

    solutionsChanged(): void {
        if (this.creatingNewUser) {
            let selectedSolution = false;
            for (const solution of this.solutions) {
                if (solution.selected) {
                    selectedSolution = true;
                }
            }
            if (selectedSolution) {
                this.noSolution = false;
            } else {
                this.noSolution = true;
            }
        }
    }

    /**
     * If the user has an ID, then update, or else create.
     *
     * @memberof UsersComponent
     */
    public saveUser(): void {
        this.formatBeforeSave();
        if (this.currentUser.id) {
            this.pageLoad = true;
            this.userService.updateUser(this.currentUser)
                .pipe(finalize(() => this.pageLoad = false))
                .subscribe(() => {
                    this.setSolutionString(this.currentUser);
                    this.closeSidebar();
                    this.notificationService.showSuccess('Updated the user');
                }, err => {
                    this.notificationService.showError(err);
                });
        } else {
            const userNames = this.currentUser.userName.split(',');
            const newUsersRequests = [];

            for (let userName of userNames) {
                userName = userName.trim();
                const existingUser = this.users.find(user => user.userName.toLowerCase() === userName.toLowerCase());

                if (existingUser) {
                    this.notificationService.showError(existingUser.userName + ' already exists');
                } else {
                    const userCopy = primitiveClone(this.currentUser);
                    userCopy.userName = userName;
                    newUsersRequests.push(this.userService.newUser(userCopy));
                }
            }

            if (newUsersRequests.length > 0) {
                this.pageLoad = true;
                this.closeSidebar();
                forkJoin(newUsersRequests)
                    .pipe(
                        switchMap(() => this.userService.getUsers()),
                        finalize(() => this.pageLoad = false)
                    ).subscribe((users: any) => {
                        this.users = users;
                        this.formatUsers();
                        this.notificationService.showSuccess('Created the user(s)');
                    }, err => {
                        this.notificationService.showError(err);
                    });
            }
        }
    }

    /**
     * Deletes the selected user.
     *
     * @memberof UsersComponent
     */
    public deleteUser(): void {
        this.pageLoad = true;
        const userCopy = primitiveClone(this.currentUser); //copy user before closing sidebar
        this.userService.deleteUser(userCopy)
            .pipe(finalize(() => this.pageLoad = false))
            .subscribe(() => {
                this.users = this.users.filter(user => user.id !== userCopy.id);
                this.notificationService.showSuccess('User deleted');
            }, err => {
                this.notificationService.showError(err);
            });

        this.closeSidebar();
    }

    clearSearch(): void {
        this.searchQuery = '';
    }

    // Clear all
    clearFilters(): void {
        this.clearSearch();
    }

    closeSidebar(): void {
        this.currentUser = null;
        this.resetSelectedSolutions();
        this.showDeleteOverlay = false;
        this.selectedRow = '';
        this.creatingNewUser = false;
    }

    openDeleteOverlay(): void {
        this.showDeleteOverlay = true;
    }

    closeDeleteOverlay(): void {
        this.showDeleteOverlay = false;
    }
}
