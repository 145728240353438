<ng-container *ngIf="languages" [formGroup]="translationsFormGroup">
    <mi-tabs formArrayName="translations">
        <ng-container *ngFor="let language of languages; index as i;">
            <mi-tab [label]="language === selectedSolution.defaultLanguage ? language + '*' : language"
                [tabFor]="'tab-panel-' + language">
            </mi-tab>
            <mi-tab-panel [id]="'tab-panel-' + language" [formGroupName]="i">
                <section class="section">
                    <label class="mi-label">{{language === selectedSolution.defaultLanguage ? 'Name (Required)' :
                        'Name'}}
                        <textarea class="mi-textarea" formControlName="name" rows="2" placeholder="Name">
                            </textarea>
                        <small role="alert" class="mi-form__error"
                            *ngIf="translationsFormArray.at(i).get('name').hasError('required')">
                            Name is required
                        </small>
                    </label>
                    <label class="mi-label">Description
                        <textarea class="mi-textarea" formControlName="description" rows="2" placeholder="Description">
                            </textarea>
                    </label>
                </section>
            </mi-tab-panel>
        </ng-container>
    </mi-tabs>
</ng-container>