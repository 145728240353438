<button type="button" class="mi-button mi-button--small mi-button--base" (click)="onCancel()">Cancel</button>
<button type="button"
        aria-label="Draw polygon"
        class="map-toolbar__control"
        [ngClass]="{'map-toolbar__control--active': drawingMode === DrawingMode.FreeHand}"
        (click)="setDrawingMode(DrawingMode.FreeHand)">
        <span class="tooltip">Add a freehand shaped Area</span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.9645 10.9389L8.45592 11.5184L9.14593 11.2002L22.9999 4.81241V16.8171L14.4513 22.876L2.81274 18.4585L1.30718 3.08785L7.9645 10.9389Z"
              fill="#DEE3ED" stroke="#1E2025" stroke-width="2" />
    </svg>
</button>
<button type="button"
        aria-label="Draw rectangle>"
        class="map-toolbar__control"
        [ngClass]="{'map-toolbar__control--active': drawingMode === DrawingMode.Rectangular}"
        (click)="setDrawingMode(DrawingMode.Rectangular)">
        <span class="tooltip">Add a rectangular Area</span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.16138 22.1243V2.12427H22.1614V22.1243H2.16138Z" fill="#DEE3ED" stroke="#1E2025" stroke-width="2" />
    </svg>
</button>
<span class="map-toolbar__divider"></span>