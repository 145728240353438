import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { AnalyticsDashboardComponent } from './analytics-dashboard.component';
import { AnalyticsDashboardRoutingModule } from './analytics-dashboard-routing.module';
import { AnalyticsDashboardService } from './analytics-dashboard.service';

@NgModule({
    declarations: [AnalyticsDashboardComponent],
    imports: [
        AnalyticsDashboardRoutingModule,
        SharedModule
    ],
    providers: [
        AnalyticsDashboardService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AnalyticsDashboardModule {}
