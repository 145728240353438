<button type="button" aria-label="Add POI" class="map-toolbar__control" (click)="setToolActive(Tools.AddPOIToolbarComponent)">
    <span class="tooltip">Add POI</span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1054_2175)">
            <path
                d="M12 0C9.48125 0.00264713 7.06643 1.00439 5.28541 2.78541C3.50439 4.56643 2.50265 6.98126 2.5 9.5C2.5 16.05 9 22 11 23.65C11.2687 23.8764 11.6087 24.0005 11.96 24.0005C12.3113 24.0005 12.6513 23.8764 12.92 23.65C15 22 21.5 16.05 21.5 9.5C21.4973 6.98126 20.4956 4.56643 18.7146 2.78541C16.9336 1.00439 14.5187 0.00264713 12 0V0ZM16.75 11.08H13.5C13.3674 11.08 13.2402 11.1327 13.1464 11.2264C13.0527 11.3202 13 11.4474 13 11.58V14.83C13 15.0952 12.8946 15.3496 12.7071 15.5371C12.5196 15.7246 12.2652 15.83 12 15.83C11.7348 15.83 11.4804 15.7246 11.2929 15.5371C11.1054 15.3496 11 15.0952 11 14.83V11.58C11 11.4474 10.9473 11.3202 10.8536 11.2264C10.7598 11.1327 10.6326 11.08 10.5 11.08H7.25C6.98478 11.08 6.73043 10.9746 6.54289 10.7871C6.35536 10.5996 6.25 10.3452 6.25 10.08C6.25 9.81478 6.35536 9.56043 6.54289 9.37289C6.73043 9.18536 6.98478 9.08 7.25 9.08H10.5C10.6326 9.08 10.7598 9.02732 10.8536 8.93355C10.9473 8.83979 11 8.71261 11 8.58V5.33C11 5.06478 11.1054 4.81043 11.2929 4.62289C11.4804 4.43536 11.7348 4.33 12 4.33C12.2652 4.33 12.5196 4.43536 12.7071 4.62289C12.8946 4.81043 13 5.06478 13 5.33V8.58C13 8.71261 13.0527 8.83979 13.1464 8.93355C13.2402 9.02732 13.3674 9.08 13.5 9.08H16.75C17.0152 9.08 17.2696 9.18536 17.4571 9.37289C17.6446 9.56043 17.75 9.81478 17.75 10.08C17.75 10.3452 17.6446 10.5996 17.4571 10.7871C17.2696 10.9746 17.0152 11.08 16.75 11.08Z"
                fill="#1E2025" />
        </g>
        <defs>
            <clipPath id="clip0_1054_2175">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</button>
<button type="button" aria-label="Add Area" class="map-toolbar__control" (click)="setToolActive(Tools.CreateAreaComponent)">
    <span class="tooltip">Add Area</span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1" y1="9" x2="1" y2="15.5" stroke="#1E2025" stroke-width="2" stroke-linecap="round" />
        <line x1="9" y1="23" x2="15.5" y2="23" stroke="#1E2025" stroke-width="2" stroke-linecap="round" />
        <line x1="15.5" y1="1" x2="9" y2="1" stroke="#1E2025" stroke-width="2" stroke-linecap="round" />
        <line x1="1" y1="20" x2="1" y2="23" stroke="#1E2025" stroke-width="2" stroke-linecap="round" />
        <line x1="1" y1="23" x2="4" y2="23" stroke="#1E2025" stroke-width="2" stroke-linecap="round" />
        <line x1="1" y1="1" x2="1" y2="4" stroke="#1E2025" stroke-width="2" stroke-linecap="round" />
        <line x1="4" y1="1" x2="1" y2="1" stroke="#1E2025" stroke-width="2" stroke-linecap="round" />
        <line x1="23" y1="23" x2="23" y2="20" stroke="#1E2025" stroke-width="2" stroke-linecap="round" />
        <line x1="20" y1="23" x2="23" y2="23" stroke="#1E2025" stroke-width="2" stroke-linecap="round" />
        <line x1="23" y1="4" x2="23" y2="1" stroke="#1E2025" stroke-width="2" stroke-linecap="round" />
        <line x1="23" y1="1" x2="20" y2="1" stroke="#1E2025" stroke-width="2" stroke-linecap="round" />
        <line x1="23" y1="15.5" x2="23" y2="9" stroke="#1E2025" stroke-width="2" stroke-linecap="round" />
        <path
            d="M12 18C11.7095 17.9955 11.4319 17.8557 11.2264 17.6106C11.0209 17.3654 10.9038 17.0342 10.9 16.6875V7.3125C10.9038 6.96581 11.0209 6.6346 11.2264 6.38943C11.4319 6.14427 11.7095 6.00453 12 6C12.2906 6.00453 12.5682 6.14427 12.7736 6.38943C12.9791 6.6346 13.0962 6.96581 13.1 7.3125V16.6875C13.0962 17.0342 12.9791 17.3654 12.7736 17.6106C12.5682 17.8557 12.2906 17.9955 12 18Z"
            fill="#1E2025" />
        <path
            d="M6 12C6.00453 11.7095 6.14427 11.4319 6.38943 11.2264C6.6346 11.0209 6.96581 10.9038 7.3125 10.9L16.6875 10.9C17.0342 10.9038 17.3654 11.0209 17.6106 11.2264C17.8557 11.4319 17.9955 11.7095 18 12C17.9955 12.2906 17.8557 12.5682 17.6106 12.7736C17.3654 12.9791 17.0342 13.0962 16.6875 13.1L7.3125 13.1C6.96581 13.0962 6.6346 12.9791 6.38943 12.7736C6.14427 12.5682 6.00453 12.2906 6 12Z"
            fill="#1E2025" />
    </svg>
</button>
<span class="map-toolbar__divider"></span>
    <route-network-tools (toolSelected)="setToolActive($event)"></route-network-tools>
<span class="map-toolbar__divider"></span>