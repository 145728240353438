import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
    selector: 'category-select',
    templateUrl: './category-select.component.html',
})
export class CategorySelectComponent implements OnChanges {
    selectedCategories: any = [];
    notSelectedCategories: any = [];
    categoryQuery: string = '';

    @Input() categories = [];
    @Input() locationCategories = []; // Should not be populated in advance if editing multiple locations
    @Input() isMultipleLocations = false;
    @Output() updatedLocationCategories: EventEmitter<string[]> = new EventEmitter();

    constructor(
    ) { }

    ngOnChanges(): void {
        this.setSelectedCategories();
    }

    /**
     * Update list of selected categories and list of not selected categories.
     *
     * @private
     * @memberof CategorySelectComponent
     */
    private setSelectedCategories(): void {
        if (!this.isMultipleLocations) {
            this.selectedCategories = [];
            this.notSelectedCategories = [];
            for (const category of [...this.categories]) {
                let selectedCat = false;
                for (const cat of this.locationCategories) {
                    if (category.key.toLowerCase() === cat.toLowerCase()) {
                        selectedCat = true;
                    }
                }
                if (selectedCat === true) {
                    this.selectedCategories.push(category);
                } else {
                    this.notSelectedCategories.push(category);
                }
            }
        } else {
            this.notSelectedCategories = [...this.categories];
        }
    }

    addCategory(category) {
        this.selectedCategories.push(category);
        const index = this.notSelectedCategories.indexOf(category);
        this.notSelectedCategories.splice(index, 1);

        this.updateLocationCategories();
    }

    removeCategory(category) {
        this.notSelectedCategories.push(category);
        const index = this.selectedCategories.indexOf(category);
        this.selectedCategories.splice(index, 1);

        this.updateLocationCategories();
    }

    updateLocationCategories() {
        const locationCategoryList = [];
        for (const cat of this.selectedCategories) {
            locationCategoryList.push(cat.key);
        }
        this.updatedLocationCategories.emit(locationCategoryList);
    }
}
