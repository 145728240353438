/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as turf from '@turf/turf';
import { ValidationChain } from '../ValidationChain';

/**
 * @static
 * @hideconstructor
 * @class PolygonSplit
 */
class PolygonSplit {
    /**
     * Validates that the LineString can split the Polygon.
     *
     * @static
     * @param {Object} args
     * @param {Feature|LineString} args.lineString - The LineString that defines where the Polygon should be split.
     * @param {Feature|Polygon} args.polygon - The Polygon to be split.
     * @returns {ValidationResult}
     * @memberof PolygonSplitValidator
     */
    static validate({ lineString, polygon }) {
        return new ValidationChain(
            mustBeAValidGeoJSONLineString,
            mustBeAValidGeoJSONPolygon,
            mustContainExactly2Points,
            mustContainExactly2PointsIntersecting2DifferentEdges,
            mustNotIntersectAnyHoles
        ).validate({ lineString, polygon });
    }

    /**
     * Split Polygon.
     *
     * @static
     * @param {Object} args
     * @param {Feature|LineString} args.lineString - The LineString that defines where the Polygon should be split.
     * @param {Feature|Polygon} args.polygon - The Polygon to be split.
     * @returns {Feature<Polygon>[]}
     * @memberof PolygonSplit
     */
    static split({ lineString, polygon }) {
        //Copy the polygon's exterior ring to a new array.
        const polyline = polygon.geometry.coordinates[0].slice(0);

        //Extract all holes from the polygon.
        const holes = { type: 'MultiPolygon', coordinates: extractHolesFromPolygon(polygon).geometries.map(geometry => geometry.coordinates) };

        const intersections = new Set(lineString.geometry.coordinates);

        const newPolylines = [];
        let previousSplitPoint = [];

        //Looping through all edges of the polyline, looking for where to split it.
        for (let i = 1; i < polyline.length; i++) {
            const p0 = polyline[i - 1], p1 = polyline[i];
            //Looping through the split points to check if they intersect the given edge.
            for (const splitPoint of intersections) {
                //If the split is on the given edge.
                if (isPointOnLine(splitPoint, p0, p1)) {
                    //Create a new polyline by cutting the polyline at the index of p1.
                    const newPolyline = polyline.splice(0, i);
                    //Prepend the previous split point and append the current split point to the new polyline.
                    newPolylines.push(previousSplitPoint.concat(newPolyline, [splitPoint]));
                    //Set the current split point as the previous.
                    previousSplitPoint = [splitPoint];
                    //For performance, we remove the split point from the intersections set when the polyline has been split.
                    intersections.delete(splitPoint);
                    //Reset the index
                    i = 0;
                }
            }
        }

        newPolylines[0] = [].concat(previousSplitPoint, polyline, newPolylines[0]);

        //Reintroduce the holes into the new polygons.
        const features = newPolylines.slice(0, 2).map(coordinates => {
            const exteriorRing = turf.lineToPolygon(turf.lineString(coordinates));
            const interiorRings = turf.intersect(exteriorRing, holes);
            const polygon = interiorRings ? turf.mask(interiorRings, exteriorRing) : exteriorRing;
            return turf.rewind(polygon);
        });

        // Return the features (where duplicates are eliminated)
        return features.map(feature => turf.cleanCoords(feature));
    }
}

/**
 * Checks that the LineString is valid GeoJSON.
 *
 * @param {Object} args
 * @param {Feature|LineString} args.lineString
 * @returns {ValidationResult}
 */
function mustBeAValidGeoJSONLineString({ lineString }) {
    const error = 'The given LineString is not valid GeoJSON';
    try {
        turf.lineString(lineString);
    } catch (err) {
        return { isValid: false, error };
    }
    return { isValid: true };
}

/**
 * Validates that the Polygon is valid GeoJSON.
 *
 * @param {Object} args
 * @param {Feature|Polygon} args.polygon
 * @returns {ValidationResult}
 */
function mustBeAValidGeoJSONPolygon({ polygon }) {
    const error = 'The given Polygon is not valid GeoJSON';
    try {
        turf.polygon(polygon);
    } catch (err) {
        return { isValid: false, error };
    }
    return { isValid: true };
}

/**
 * Validates that the LineString has exactly 2 points.
 *
 * @param {Object} args
 * @param {Feature|LineString} args.lineString
 * @param {Feature|Polygon} args.polygon
 * @returns {ValidationResult}
 */
function mustContainExactly2Points({ lineString }) {
    const error = 'The LineString must consists of exactly 2 points';
    const geometry = turf.getGeom(lineString);
    return geometry.coordinates.length === 2 ? { isValid: true } : { isValid: false, error };
}

/**
 * Validates that the LineStrings endpoints intersect 2 different edges of the outer ring of the Polygon.
 *
 * @param {Object} args
 * @param {Feature|LineString} args.lineString
 * @param {Feature|Polygon} args.polygon
 * @returns {ValidationResult}
 */
function mustContainExactly2PointsIntersecting2DifferentEdges({ lineString, polygon }) {
    const error = 'The ends of the LineString must intersect 2 different edges of the polygon.';
    //The lineString is shortened so the ends don't intersect the polygons exterior ring.
    const splitLine = turf.transformScale(lineString, 0.99);
    //The polygon is converted to a linestring (polyline).
    const polyline = turf.polygonToLine({ type: 'Polygon', coordinates: [polygon.geometry.coordinates[0]] });

    if (turf.lineIntersect(splitLine, polyline).features.length > 0) {
        //If there are any intersections between the outer edge of the polygon and the split line, then the split is not valid.
        return { isValid: false, error, meta: { type: 'FeatureCollection', features: [splitLine] } };
    } else if (turf.lineOverlap(splitLine, polyline, { tolerance: 0.0001 }).features.length > 0) {
        //If there are any overlaps between the outer edge of the polygon and the split line, then the split is not valid.
        return { isValid: false, error, meta: { type: 'FeatureCollection', features: [splitLine] } };
    } else if (!turf.booleanContains(polygon, splitLine)) {
        //If the split line is not fully contained by the polygon, then the split is not valid.
        return { isValid: false, error, meta: { type: 'FeatureCollection', features: [splitLine] } };
    } else {
        return { isValid: true };
    }
}



/**
 * Validates that the LineString doesn't intersect any holes in the Polygon.
 *
 * @param {Object} args
 * @param {Feature|LineString} args.lineString
 * @param {Feature|Polygon} args.polygon
 * @returns {ValidationResult}
 */
function mustNotIntersectAnyHoles({ lineString, polygon }) {
    const error = 'The LineString must not intersect with holes in the polygon.';
    const holes = extractHolesFromPolygon(polygon);
    const intersections = turf.lineIntersect(lineString, holes);
    return intersections.features.length === 0 ? { isValid: true } : { isValid: false, error };
}

/**
 * Validates that the LineString is no closer than one meter to any edge or hole of the given Polygon, except the two edges that the endpoints of the LineString intersect.
 *
 * @param {Object} args
 * @param {Feature|LineString} args.lineString
 * @param {Feature|Polygon} args.polygon
 * @returns {ValidationResult}
 */
function mustNotBeCloserThanOneMeter({ lineString, polygon }) {
    const error = 'The LineString is too close to...?';
    const lineGeometry = turf.rewind(turf.getGeom(lineString));
    const distance = turf.distance(lineGeometry.coordinates[0], lineGeometry.coordinates[1], { units: 'meters' });
    const line = turf.lineSliceAlong(lineGeometry, 1, distance - 1, { units: 'meters' });
    const bufferedLineString = turf.buffer(line, 1, { units: 'meters', steps: 1 });
    const difference = turf.difference(bufferedLineString, polygon);
    if (!difference) {
        return { isValid: true };
    } else if (difference.geometry.type === 'MultiPolygon' && difference.geometry.coordinates.length === 2) {
        let boundaries = line.geometry.coordinates.map(point => turf.circle(point, 1.1, { units: 'meters', steps: 32 }));

        for (const coordinates of difference.geometry.coordinates) {
            boundaries = boundaries.filter(boundary => !turf.booleanWithin({ type: 'Polygon', coordinates }, boundary));
            if (boundaries.length === 2) {
                return { isValid: false, error, meta: { type: 'FeatureCollection', features: [difference, ...line.geometry.coordinates.map(point => turf.circle(point, 1.1, { units: 'meters', steps: 32 }))] } };
            }
        }
        return { isValid: true };
    }
    return { isValid: false, error, meta: { type: 'FeatureCollection', features: [difference] } };
}

/**
 * Extract all holes from a given Polygon and returns them as a GeometryCollection.
 *
 * @param {Feature|Polygon} polygon
 * @returns {GeometryCollection}
 */
function extractHolesFromPolygon(polygon) {
    const geometry = turf.getGeom(polygon);
    const holes = {
        type: 'GeometryCollection',
        geometries: geometry.coordinates.slice(1).map(hole => ({ type: 'Polygon', coordinates: [hole] }))
    };
    return turf.rewind(holes);
}

/**
 * Calculate if a given point (p) is on the line ab.
 *
 * @param {[number,number]} p
 * @param {[number,number]} a
 * @param {[number,number]} b
 * @returns {boolean}
 * @see https://stackoverflow.com/questions/328107/how-can-you-determine-a-point-is-between-two-other-points-on-a-line-segment
 */
function isPointOnLine(p, a, b) {
    const [px, py] = p;
    const [ax, ay] = a;
    const [bx, by] = b;

    if (Math.abs((py - ay) * (bx - ax) - (px - ax) * (by - ay)) > Number.EPSILON) {
        return false;
    }

    const dotProduct = (px - ax) * (bx - ax) + (py - ay) * (by - ay);

    if (dotProduct < 0) {
        return false;
    }

    const squaredLengthBA = (bx - ax) * (bx - ax) + (by - ay) * (by - ay);

    if (dotProduct > squaredLengthBA) {
        return false;
    }

    return true;
}

export { PolygonSplit, mustContainExactly2Points, mustContainExactly2PointsIntersecting2DifferentEdges, mustNotIntersectAnyHoles, mustNotBeCloserThanOneMeter };
