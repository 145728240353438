<div class="combine-locations">
    <header class="combine-locations__header">
        <h2 class="combine-locations__instruction">Select Room to combine with</h2>
        <button type="button" (click)="closeDialog()" class="combine-locations__close-button" [disabled]="isProcessing">&times;</button>
    </header>

    <div #map class="split-combine__map"></div>

    <div class="combine-locations__controls">
        <button
            type="button"
            class="mi-button mi-button--primary combine-locations__button"
            [disabled]="!selectedLocationPolygon || isProcessing"
            (click)="performCombination()">
            Done
        </button>
    </div>
</div>
