import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DisplayRule } from '@mapsindoors/typescript-interfaces';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { NotificationService } from '../../../app/services/notification.service';
import { SolutionService } from '../../../app/services/solution.service';
import { stayAtCurrentUrl } from '../solution-settings-shared-functions.component';
import isEqual from 'fast-deep-equal';
import { SolutionConfig } from '../solution-settings.model';
import { primitiveClone } from '../../shared/object-helper';

@Component({
    selector: 'map-behavior',
    templateUrl: './map-behavior.component.html',
    styleUrls: ['./map-behavior.component.scss']
})

export class MapBehaviorComponent implements OnInit, OnDestroy {

    public clusteringEnabled: boolean;
    public _mainDisplayRule: [DisplayRule, DisplayRule?] = [null];
    public updateData;
    public mapBehaviourForm = this.formBuilder.group({
        enableClustering: ['', [Validators.required]],
        collisionHandling: ['', [Validators.required]]
    });

    private _solutionConfig;
    private _originalFormState;
    private subscriptions = new Subscription();

    constructor(
        private formBuilder: FormBuilder,
        private solutionService: SolutionService,
        private notificationService: NotificationService,
        private spinner: NgxSpinnerService,
        private router: Router
    ) { }

    /**
     * NgOnInit.
     */
    ngOnInit(): void {
        this.spinner.show();
        this.subscriptions
            .add(
                this.solutionService.solutionConfig$
                    .pipe(tap(() => this.spinner.hide()))
                    .subscribe(solutionConfig => {
                        this._solutionConfig = primitiveClone(solutionConfig);
                        this._originalFormState = {
                            enableClustering: this._solutionConfig?.enableClustering,
                            collisionHandling: this._solutionConfig?.collisionHandling
                        };

                        this._mainDisplayRule = [this._solutionConfig?.mainDisplayRule] as [DisplayRule, DisplayRule?];
                        this.setMapBehaviorValues(solutionConfig);
                    }))
            .add(
                this.router.events
                    .subscribe(() => {
                        if (this.mapBehaviourForm.dirty) {
                            this.onDiscard();
                        }
                    }))
            .add(
                this.mapBehaviourForm.valueChanges
                    .pipe(debounceTime(300))
                    .subscribe(formState => {
                        // compare the original state and the current state to see if they are the same.
                        !isEqual(this._originalFormState, formState)
                            ? this.mapBehaviourForm.markAsDirty()
                            : this.mapBehaviourForm.markAsPristine();
                    })
            );
    }

    /**
     * NgOnDestroy.
     */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Get solutionConfig values.
     *
     * @returns {SolutionConfig}
     */
    public get solutionConfig(): SolutionConfig {
        return this._solutionConfig;
    }

    /**
     * Get Main Display Rule values.
     *
     * @returns {[DisplayRule, DisplayRule?]}
     */
    public get displayRules(): [DisplayRule, DisplayRule?] {
        return this._mainDisplayRule;
    }

    /**
     * Patches values to map behavior form.
     */
    private setMapBehaviorValues(solutionConfig: SolutionConfig): void {
        this.mapBehaviourForm.patchValue({
            enableClustering: solutionConfig?.enableClustering,
            collisionHandling: solutionConfig?.collisionHandling
        });
    }

    /**
     * Get a form control by its key path.
     *
     * @param {string} keyPath - FormControl key path.
     * @returns {FormControl}
     * @memberof MapBehaviorComponent
     */
    public getFormControl(keyPath: string): FormControl {
        return this.mapBehaviourForm.get(keyPath) as FormControl;
    }

    /**
     * When submitting accept and save changes made in Map Behaviour section.
     */
    public onSubmit(): void {
        const mapBehaviourValues = {
            ...this._solutionConfig,
            enableClustering: this.mapBehaviourForm.controls['enableClustering'].value,
            collisionHandling: this.mapBehaviourForm.controls['collisionHandling'].value
        };

        this.spinner.show();
        this.solutionService.updateSolutionConfig(mapBehaviourValues)
            .pipe(tap(() => this.spinner.hide()))
            .subscribe(() => {
                this.notificationService.showSuccess('Changes saved successfuly!');
            }, () => {
                this.notificationService.showError('Something went wrong. Please try again.');
            });
    }

    /**
     * When discarding changes in Map Behaviour section we can discard them and revert changes or cancel action and still edit the form.
     */
    public onDiscard(): void {
        // eslint-disable-next-line no-alert
        const dialogResponse = confirm(
            'You will lose your changes if you continue without saving. Do you want to continue?'
        );
        if (!dialogResponse) {
            stayAtCurrentUrl(this.router);
            return;
        }

        this.setMapBehaviorValues(this._solutionConfig);
        this.mapBehaviourForm.markAsPristine();
    }
}
