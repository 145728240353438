import { Anchor, Geometry, LanguageData, PathData } from '../locations/location.model';
import { MICustomProperty } from '../shared/custom-properties/custom-properties.model';
import { Floor } from './floor.model';

export const OUTDOOR_BUILDING: Building = {
    administrativeId: '_',
    anchor: null,
    buildingInfo: null,
    displayName: 'Outside',
    geometry: null,
    id: 'outsideOnVenue',
    pathData: null,
    solutionId: null,
    floors: []
};

export interface Building {
    administrativeId?: string;
    address?: '';
    aliases?: string[];
    anchor: Anchor;
    buildingInfo: BuildingInfo[];
    createdAt?: string;
    createdBy?: string;
    defaultFloor?: number;
    displayName?: string; // Used in the CMS only
    externalId?: string;
    floors?: Floor[]; // Used in the CMS only
    geometry: Geometry;
    graphId?: string;
    id?: string;
    imageUrl?: string;
    lastModified?: string;
    lastModifiedBy?: string;
    path?: string;
    pathData: PathData;
    status?: number;
    solutionId: string;
}

export interface BuildingInfo extends LanguageData {
    customProperties?: MICustomProperty[]
}
