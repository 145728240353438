import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CustomerService } from '../../customers/customer.service';
import { MapService } from '../../services/map.service';
import { NotificationService } from '../../services/notification.service';
import { SolutionService } from '../../services/solution.service';

import { ExtendedLocation } from '../../locations/location.service';
import { Venue } from '../../venues/venue.model';

import midt from '@mapsindoors/midt/tokens/tailwind-colors.json';
import { Polygon } from '@turf/turf';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { Customer } from '../../customers/customer.model';
import { primitiveClone } from '../../shared/object-helper';
import { CombineService } from './combine.service';

@Component({
    selector: 'app-combine-locations',
    templateUrl: './combine-locations.component.html',
    styleUrls: ['./combine-locations.component.scss']
})
export class CombineLocationsComponent implements OnInit {
    @ViewChild('map', { static: true }) mapElement: ElementRef;

    private mapService: MapService;
    private locationPolygon: google.maps.Polygon;

    public isProcessing = false;

    public selectedLocationPolygon: google.maps.Polygon; // Selected location polygon to combine with

    private adjacentLocationPolygons: google.maps.Polygon[] = [];

    constructor(
        private dialogRef: MatDialogRef<CombineLocationsComponent>,
        private combineService: CombineService,
        private customerService: CustomerService,
        private solutionService: SolutionService,
        private notificationService: NotificationService,
        private spinner: NgxSpinnerService,
        @Inject(MAT_DIALOG_DATA) public data: {
            location: ExtendedLocation,
            locations?: ExtendedLocation[],
            venue: Venue
        }
    ) {
        // We will instantiate a separate instance of the MapService to not clash with the existing map.
        this.mapService = new MapService(this.solutionService);
    }

    ngOnInit(): void {
        const customer = this.customerService.getCurrentCustomer(true) as Customer;
        if (customer.modules?.includes('splitandcombine')) {
            this.initMap(this.data.venue)
                .then(this.startCombineMode.bind(this));
        }
    }

    /**
     * Initialize the map.
     * Set map's bounds to the selected location geometry and render a polygon to clarify what's selected.
     *
     * @private
     * @param {Venue} venue
     * @memberof CombineLocationsComponent
     */
    private async initMap(venue: Venue): Promise<void> {
        await this.mapService.createMap(this.mapElement.nativeElement);
        this.mapService.setVenue(venue, this.data.location.floor.floorIndex);

        // Set bounds to fit location while leaving space for the controls in the bottom
        this.mapService.setBounds(this.data.location.geometry.bbox, { bottom: 60, left: 0, right: 0, top: 0 });

        this.drawLocationPolygon();
    }

    /**
     * Close the modal for this component if the user confirms.
     *
     * @memberof CombineLocationsComponent
     */
    public closeDialog(): void {
        const shouldDialogClose = confirm('Closing this window will discard any changes you have made.');
        if (shouldDialogClose) {
            this.dialogRef.close();
        }
    }

    /**
     * Render the location as an extra polygon on the map.
     *
     * @memberof CombineLocationsComponent
     */
    private drawLocationPolygon(): void {
        this.locationPolygon = this.mapService.drawPolygon(this.data.location.geometry);
        this.locationPolygon.setOptions({
            fillOpacity: 0,
            strokeWeight: 2,
            strokeOpacity: 1,
            strokeColor: '#ef6cce',
            zIndex: 1,
            clickable: false // otherwise mousemove event will not register while pointer is on top of polygon
        });
    }

    /**
     * Draw location geometries with listeners and style the selected location with a fill color.
     *
     * @private
     * @memberof CombineLocationsComponent
     */
    private startCombineMode(): void {
        this.locationPolygon.setOptions({
            fillColor: '#ef6cce',
            fillOpacity: .7,
        });

        this.data.locations.forEach(location => {
            const polygon: google.maps.Polygon = this.mapService.drawPolygon(location.geometry);
            polygon.set('location', location);
            polygon.setOptions({
                fillColor: midt['tailwind-colors'].blue[500].value,
                fillOpacity: .32,
                strokeWeight: 1,
                strokeOpacity: 1,
                strokeColor: midt['tailwind-colors'].blue[500].value,
                zIndex: 1,
                clickable: true
            });

            this.addPolygonMouseOverEvent(polygon);
            this.addPolygonMouseOutEvent(polygon);
            this.addPolygonClickEvent(polygon);

            this.adjacentLocationPolygons.push(polygon);
        });
    }

    /**
     * Add and handle click event at polygon.
     *
     * @private
     * @param {google.maps.Polygon} polygon
     * @memberof CombineLocationsComponent
     */
    private addPolygonClickEvent(polygon: google.maps.Polygon): void {
        this.mapService.addEventListener('click', () => {
            // Remove highlight from previous highlighted polygon and add event listeners again
            if (this.selectedLocationPolygon) {
                this.selectedLocationPolygon.setOptions({
                    fillColor: '#3071D9',
                    fillOpacity: .32,
                    strokeColor: '#3071D9',
                });

                this.addPolygonMouseOverEvent(this.selectedLocationPolygon);
                this.addPolygonMouseOutEvent(this.selectedLocationPolygon);
                this.addPolygonClickEvent(this.selectedLocationPolygon);
            }

            polygon.setOptions({
                fillColor: '#ef6cce',
                fillOpacity: .7,
                strokeColor: '#ef6cce',
            });

            this.selectedLocationPolygon = polygon;
            this.mapService.clearInstanceListeners(polygon);
        }, polygon);
    }

    /**
     * Add and handle mouse over event at polygon.
     *
     * @private
     * @param {google.maps.Polygon} polygon
     * @memberof CombineLocationsComponent
     */
    private addPolygonMouseOverEvent(polygon: google.maps.Polygon): void {
        this.mapService.addEventListener('mouseover', () => {
            polygon.setOptions({
                fillOpacity: .7,
            });
        }, polygon);
    }

    /**
     * Add and handle mouse out event at polygon.
     *
     * @private
     * @param {google.maps.Polygon} polygon
     * @memberof CombineLocationsComponent
     */
    private addPolygonMouseOutEvent(polygon: google.maps.Polygon): void {
        this.mapService.addEventListener('mouseout', () => {
            polygon.setOptions({
                fillOpacity: .32,
            });
        }, polygon);
    }

    /**
     * Combine the two selected locations.
     *
     * @memberof CombineLocationsComponent
     */
    public performCombination(): void {
        this.isProcessing = true;
        this.adjacentLocationPolygons.forEach(polygon => {
            this.mapService.clearInstanceListeners(polygon);
        });

        const locationToKeep = primitiveClone(this.data.location);
        const locationToDelete = this.selectedLocationPolygon.get('location');

        const combinedFeature = this.combineService.combine(locationToKeep.geometry as Polygon, locationToDelete.geometry);

        this.spinner.show();
        this.combineService.saveCombination(locationToKeep, locationToDelete, combinedFeature.geometry)
            .pipe(
                finalize(() => {
                    this.isProcessing = false;
                })
            ).subscribe(
                (combinedLocation) => {
                    if (combinedLocation.id !== locationToKeep.id) {
                        this.spinner.hide();
                        // In this case, we know that the returned location is not the combined one, but a recreated after a request failed.
                        this.notificationService.showError('Something went wrong. The combination is not saved. Please try again');
                    } else {
                        return this.dialogRef.close({
                            combinedLocation: combinedLocation,
                            deletedLocation: locationToDelete
                        });
                    }
                },
                () => {
                    this.spinner.hide();
                    this.notificationService.showError('Something went wrong. The combination is not saved. Please try again');
                }
            );
    }

}
