import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import { Building } from '../buildings/building.model';
import { BuildingService } from '../buildings/building.service';
import { Floor } from '../buildings/floor.model';
import { GraphSetup } from '../shared/enums/GraphSetup.enum';
import { MapService } from '../services/map.service';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../services/notification.service';
import { Router } from '@angular/router';
import { SolutionService } from '../services/solution.service';
import { TilesManagerComponent } from '../tiles-manager/tiles-manager.component';
import { Venue } from '../venues/venue.model';
import { VenueService } from '../venues/venue.service';
import { environment } from '../../environments/environment';
import midt from '@mapsindoors/midt/tokens/tailwind-colors.json';
import { primitiveClone } from '../shared/object-helper';

@Component({
    selector: 'app-deployment-venue',
    templateUrl: './deployment-venue.component.html',
    styleUrls: ['./deployment-venue.component.scss']
})
export class DeploymentVenueComponent implements OnInit, OnDestroy {
    @ViewChild('map', { static: true }) mapElement: ElementRef;
    private googleMap: google.maps.Map;
    private eventListeners: google.maps.MapsEventListener[] = [];
    venueOutline: any;
    venueBoundsCode: string = '';
    venueBoundsValid: boolean = true;
    displayBoundsCode: boolean = false;
    GraphSetup = GraphSetup;

    editVenueOutline: boolean = false;

    venueAnchor: any;

    newAlias: string = '';

    showDeleteOverlay: boolean = false;

    currentSolution: any;
    defaultLanguage: string = '';
    geoDatabase: string = '';
    activeMenu: string = '';
    placeQuery: string = '';

    showNewFloorOverlay: boolean = false;

    private subscriptions = new Subscription();
    private venues: Venue[] = [];

    public isProgressbarVisible: boolean = false;
    public zoomLevel: number;
    public venueDetails: Venue;
    public buildings: Building[] = [];
    public floors: Floor[] = [];
    public currentFloor: Floor;

    constructor(
        private zone: NgZone,
        private venueService: VenueService,
        private buildingService: BuildingService,
        private solutionService: SolutionService,
        private notificationService: NotificationService,
        private mapService: MapService,
        private dialog: MatDialog,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.subscriptions.add(this.venueService.getSelectedVenue()
            .subscribe(venue => {
                if (venue) {
                    this.venueDetails = this.removeLastPoint(venue);
                    this.initVenue(this.venueDetails);
                }
            }))

            .add((this.buildingService.getCurrentBuilding() as Observable<Building>)
                .subscribe(building => {
                    if (building) {
                        this.floors = building.floors;
                        this.currentFloor = this.buildingService.getInitialFloor(this.venueDetails);
                        this.buildingService.setCurrentFloor(this.currentFloor);
                    }
                }))

            .add((this.buildingService.getCurrentFloor() as Observable<Floor>)
                .subscribe(floor => {
                    if (floor) {
                        this.mapService.loadTiles(this.venueDetails, floor.floorIndex);
                    }
                }))

            .add(this.venueService.venues$
                .subscribe(venues => this.venues = venues));
    }

    ngOnDestroy(): void {
        this.mapService.deallocate();
        this.subscriptions.unsubscribe();
        this.eventListeners.forEach(e => e.remove());
    }

    /**
     * Initializes the venue.
     *
     * @private
     * @param {Venue} venue
     * @memberof DeploymentVenueComponent
     */
    private initVenue(venue: Venue): void {
        this.currentSolution = this.solutionService.getStaticSolution();
        this.defaultLanguage = this.currentSolution.defaultLanguage;
        this.currentFloor = this.buildingService.getInitialFloor(venue);

        // Setting graphSetup to Manual if stored venue.graphSetup is not Automatic or is missing/null
        if (venue.graphSetup !== GraphSetup.Automatic) {
            venue.graphSetup = GraphSetup.Manual;
        }

        this.getBuildings(venue);
    }

    /**
     * Get buildings and set the current one.
     *
     * @private
     * @param {Venue} venue
     * @memberof DeploymentVenueComponent
     */
    private getBuildings(venue: Venue): void {
        this.isProgressbarVisible = true;
        const subscription = this.buildingService.getBuildings({ venue: venue.name })
            .pipe(finalize(() => {
                this.initGoogleMap(venue);
                this.isProgressbarVisible = false;
            }))
            .subscribe(buildings => {
                this.buildings = buildings;
                let building = this.buildingService.getCurrentBuilding(true) as Building;
                if (!building || building.pathData.venue.toLowerCase() !== venue.name.toLowerCase()) {
                    building = buildings && buildings.length > 0 ? buildings[0] : building;
                }

                this.buildingService.setCurrentBuilding(building);
            }, error => this.notificationService.showError(error));

        this.subscriptions.add(subscription);
    }

    /**
     * Initialize the map.
     *
     * @private
     * @param {Venue} venue
     * @memberof DeploymentVenueComponent
     */
    private async initGoogleMap(venue: Venue): Promise<void> {
        await this.mapService.createMap(this.mapElement.nativeElement);
        this.mapService.setVenue(venue, this.currentFloor.floorIndex);
        this.googleMap = this.mapService.getMap();
        if (this.googleMap) {
            this.eventListeners.push(this.mapService.addEventListener('zoom_changed', () => {
                this.zoomLevel = this.googleMap.getZoom();
            }));

            this.setVenueAnchor();
        }
    }

    removeVertex(vertex): void {
        const path = this.venueOutline.getPath();
        path.removeAt(vertex);
        this.setBoundsCode();
    }

    setVenueAnchor(): void {
        const markerLabel = {
            color: midt['tailwind-colors'].black.value,
            fontSize: '14px',
            fontWeight: 'bold',
            text: this.venueDetails.displayName
        };

        const markerIcon = {
            url: `${environment.iconsBaseUrl}misc/venue.png`,
            anchor: new google.maps.Point(16, 16),
            scaledSize: { width: 32, height: 32 },
            labelOrigin: new google.maps.Point(16, 40)
        };

        this.venueAnchor = new google.maps.Marker({
            map: this.googleMap,
            label: markerLabel,
            clickable: false,
            draggable: true,
            cursor: 'move'
        });
        this.venueAnchor.setIcon(markerIcon);
        this.venueAnchor.setPosition({ lat: this.venueDetails.anchor.coordinates[1], lng: this.venueDetails.anchor.coordinates[0] });

        this.eventListeners.push(this.mapService.addEventListener('dragend', (e) => {
            this.venueDetails.anchor.coordinates = [e.latLng.lng(), e.latLng.lat()];
        }, this.venueAnchor));
    }

    initVenueOutline(): void {
        this.drawVenueOutline();
        this.editVenueOutline = true;
    }

    endVenueOutline(): void {
        this.editVenueOutline = false;
        this.venueBoundsCode = '';
        this.venueOutline.setMap(null);
        this.venueOutline = null;
        google.maps.event.clearListeners(this.googleMap, 'click');
    }

    drawVenueOutline(): void {
        if (this.venueOutline) {
            this.venueOutline.setMap(null);
        }
        const paths = [];
        if (this.venueDetails.geometry.coordinates) {
            const geometry = this.venueDetails.geometry;

            for (const line of geometry.coordinates) {
                const path = [];
                for (const set of line) {
                    path.push(new google.maps.LatLng(set[1], set[0]));
                }
                paths.push(path);
            }
        }
        this.venueOutline = new google.maps.Polygon({
            strokeColor: midt['tailwind-colors'].blue[500].value,
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: midt['tailwind-colors'].blue[500].value,
            fillOpacity: .1,
            map: this.googleMap,
            paths: paths,
            clickable: false,
            editable: true
        });

        const self = this;

        this.googleMap.addListener('click', function (event) {
            self.venueOutline.getPath().push(event.latLng);
            self.setBoundsCode();
        });

        this.venueOutline.getPath().addListener('insert_at', function () {
            self.setBoundsCode();
        });
        this.venueOutline.getPath().addListener('set_at', function () {
            self.setBoundsCode();
        });

        this.venueOutline.addListener('rightclick', function (event) {
            if (!event.vertex) {
                return;
            } else {
                self.removeVertex(event.vertex);
            }
        });

        this.setBoundsCode();
    }

    formatVenueBeforeSave(): void {
        if (this.venueOutline) {
            this.setBoundsCode();
            this.setVenueBoundsCoordinates();
        }
    }

    setVenueBoundsCoordinates(): void {
        const boundsObject = JSON.parse(this.venueBoundsCode);
        this.venueDetails.geometry.coordinates = boundsObject.geometry.coordinates;
    }

    saveVenue(): void {
        this.isProgressbarVisible = true;
        this.formatVenueBeforeSave();
        const venueCopy = primitiveClone(this.venueDetails),
            last = venueCopy.geometry.coordinates[0][venueCopy.geometry.coordinates[0].length - 1],
            first = venueCopy.geometry.coordinates[0][0];

        if (first[0] !== last[0] || first[1] !== last[1]) {
            venueCopy.geometry.coordinates[0].push(first);
        }
        this.venueService.updateVenue(venueCopy)
            .pipe(finalize(() => this.isProgressbarVisible = false))
            .subscribe(
                () => this.notificationService.showSuccess('Venue updated'),
                error => this.notificationService.showError(error)
            );
    }

    toggleBoundsCode(): void {
        this.displayBoundsCode = !this.displayBoundsCode;
    }

    setBoundsCode(): void {
        const coordinates = [];

        this.venueOutline.getPath().forEach(function (item) {
            coordinates.push([item.lng(), item.lat()]);
        });
        coordinates.push(coordinates[0]);
        if (coordinates.length > 2) {
            this.venueBoundsValid = true;
        } else {
            this.venueBoundsValid = false;
        }

        const result = {
            type: 'Feature',
            geometry: { type: 'Polygon', coordinates: [coordinates] },
            properties: {}
        };
        this.zone.run(() => {
            this.venueBoundsCode = JSON.stringify(result, null, '\t');
        });
    }

    copyBoundsCode(): void {
        // Maybe better and less dirty option:
        // https://stackoverflow.com/questions/45768583/angular-4-copy-text-to-clipboard
        const text = this.venueBoundsCode;
        const event = (e: ClipboardEvent): void => {
            e.clipboardData.setData('text/plain', text);
            e.preventDefault();
            document.removeEventListener('copy', event);
        };
        document.addEventListener('copy', event);
        document.execCommand('copy');
        if (document.execCommand('copy')) {
            this.notificationService.showSuccess('Copied to Clipboard');
        } else {
            this.notificationService.showError('Something went wrong');
        }
    }

    closeMenu(): void {
        this.activeMenu = '';
    }

    openMenu(name): void {
        this.activeMenu = name;
    }

    /**
     * Sets the selected floor.
     *
     * @param {Floor} floor
     * @memberof DeploymentVenueComponent
     */
    public setFloor(floor: Floor): void {
        if (floor && floor.floorIndex !== this.currentFloor.floorIndex) {
            this.currentFloor = floor;
            this.buildingService.setCurrentFloor(this.currentFloor);
        }
    }

    resetBounds(): void {
        this.venueOutline.getPath().clear();
        this.setBoundsCode();
    }

    removeLastPoint(venue: Venue): Venue {
        const venueCopy = primitiveClone(venue);
        const last = venueCopy.geometry.coordinates[0][venueCopy.geometry.coordinates[0].length - 1],
            first = venueCopy.geometry.coordinates[0][0];

        if (first[0] === last[0] && first[1] === last[1]) {
            venueCopy.geometry.coordinates[0].pop();
        } else {
            this.notificationService.showError(`This venue's boundary is not valid. The first point: ${first} and  last point: ${last}`);
        }

        return venueCopy;
    }

    /**
     * Navigates to the new-venue page with an absolut url.
     *
     * @memberof DeploymentVenueComponent
     */
    public goToNewVenue(): void {
        this.router.navigateByUrl('/deployment/new-venue');
    }

    /**
     * Sets the currently selected building.
     * Set the map bounds to the current building.
     *
     * @param {Building} building
     * @memberof DeploymentVenueComponent
     */
    public setBuilding(building: Building): void {
        if (building) {
            this.mapService.setBounds(building.geometry.bbox);
            this.buildingService.setCurrentBuilding(building);
        }
    }

    /**
     * Opens Tiles Manager Dialog.
     *
     * @memberof DeploymentVenueComponent
     */
    public openTilesManager(): void {
        const dialogRef = this.dialog.open(TilesManagerComponent, {
            width: '640px',
            height: '580px',
            disableClose: false,
            role: 'dialog',
            autoFocus: false,
            ariaLabel: 'Tiles Manager',
            panelClass: 'manager-dialog',
            closeOnNavigation: true,
            data: { venue: this.venueDetails }
        });

        dialogRef.afterClosed().subscribe((venue: Venue) => {
            if (venue) {
                this.updateAllVenuesTiles(venue);
            }
        });
    }

    /**
     * Updates tiles for all venues in a solution to the given venue tiles.
     *
     * @private
     * @param {Venue} venue
     * @memberof DeploymentVenueComponent
     */
    private updateAllVenuesTiles(venue: Venue): void {
        // take a clean venue and update only the tilesUrl and styles
        const venueCopy = this.venues.find(_venue => _venue.id === venue.id);
        venueCopy.tilesUrl = venue.tilesUrl;
        venueCopy.styles = venue.styles;

        this.isProgressbarVisible = true;
        this.venueService.updateVenue(venueCopy)
            .pipe(
                switchMap(() => {
                    return forkJoin(this.venues.filter(_venue => _venue.id !== venue.id)
                        .map(_venue => {
                            _venue.tilesUrl = venue.tilesUrl;
                            _venue.styles = venue.styles;
                            return this.venueService.updateVenue(_venue);
                        }));
                }),
                finalize(() => this.isProgressbarVisible = false))
            .subscribe(
                () => {
                    this.notificationService.showSuccess('All venues in this solution have been updated');
                    this.venueService.setVenueEvent('reload venues');
                },
                error => this.notificationService.showError(error)
            );
    }
}
