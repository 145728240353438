import { Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filterCustomers',
    pure: true
})
export class FilterCustomersPipe implements PipeTransform {
    transform(value: any[], query: any): any {
        query = query ? query.toLowerCase() : '';
        return query && value ?
            value.filter(customer => {
                if (customer.name.toLowerCase().indexOf(query) !== -1) {
                    return true;
                }
            }
            ) :
            value;
    }
}
