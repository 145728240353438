import { Injectable } 						from '@angular/core';
import { HttpHeaders, HttpClient } 	from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SolutionService }				from '../../services/solution.service';
import { environment } from '../../../environments/environment';

import { FormatService }				from '../../services/format.service';
import { AppConfig } from './config.model';

@Injectable({ providedIn: 'root'})
export class ConfigService {
    private api = environment.APIEndpoint;
    public appConfig: AppConfig = null;

    constructor(
    private formatService: FormatService,
    private solutionService: SolutionService,
    private http: HttpClient
    ) { }

    getConfig(): Observable<any> {
        const solution = this.solutionService.getStaticSolution();
        return this.http.get<any>(this.api + solution.id + '/api/appconfig/')
            .pipe(tap(config => this.appConfig = config));
    }

    createConfig(config): Observable<any> {
        const solution = this.solutionService.getStaticSolution();
        const requestOptions = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(this.api + solution.id + '/api/appconfig/', config, {headers: requestOptions});
    }

    updateConfig(config): Observable<any> {
        const solution = this.solutionService.getStaticSolution();
        const requestOptions = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.put(this.api + solution.id + '/api/appconfig/', config, {headers: requestOptions});
    }
}
