import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';

import EventEmitter from 'events';
import GeoJSON from 'geojson';
import { bbox, circle as createCircle } from '@turf/turf';
import mapboxgl from 'mapbox-gl';
import { MapViewState } from '../map/MapViewState';
import { FeatureClass } from '../../viewmodels/FeatureClass';
import { map } from 'rxjs/operators';
import { MapViewModel } from '../../viewmodels/MapViewModelFactory/MapViewModelFactory';

export enum MapAdapterType {
    GoogleMapsAdapter = 'GoogleMapsAdapter',
    MapboxAdapter = 'MapboxAdapter'
}

export enum MapType {
    ROADMAP,
    SATELLITE,
    HYBRID
}

export enum MapMouseCursor {
    Default = '',
    Pointer = 'pointer',
    Crosshair = 'crosshair',
    Move = 'move',
    Grab = 'grab'
}

export interface MapOptions {
    center?: GeoJSON.Point,
    zoom: number,
    maxZoom: number,
    minZoom: number,
    disableDefaultUI: boolean,
    zoomControl?: boolean,
    zoomControlOptions?,
    styles?
}

export interface MapPadding {
    top: number,
    right: number,
    bottom: number,
    left: number
}

export abstract class BaseMapAdapter extends EventEmitter {
    protected isClickableSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
    protected boundsSubject: ReplaySubject<GeoJSON.BBox> = new ReplaySubject(1);
    protected centerSubject: ReplaySubject<GeoJSON.Point> = new ReplaySubject(1);
    protected clickSubject: Subject<GeoJSON.Feature> = new Subject();
    protected hoverSubject: Subject<{ locationId: string, featureType: string }> = new Subject();
    #element: HTMLElement;
    #viewState: MapViewState;

    #show3D: boolean = true;
    #model3DVisibleSubject: BehaviorSubject<boolean> = new BehaviorSubject(this.#show3D);
    #show2D: boolean = true;
    #model2DVisibleSubject: BehaviorSubject<boolean> = new BehaviorSubject(this.#show2D);

    /**
     * Validates that the given feature is visible at the current zoom level.
     *
     * @param {MapViewModel} feature
     * @returns {boolean}
     */
    #withInZoomRange(feature: MapViewModel): boolean {
        const zoom = this.getZoom();
        const [min, max] = feature.properties.zoomRange;
        return zoom >= min && zoom <= max;
    }

    /**
     * Observable for subscribing to 2D model visibility changes.
     *
     * @readonly
     * @returns {Observable<boolean>}
     * @memberof BaseMapAdapter
     */
    public get model2DVisible$(): Observable<boolean> {
        return this.#model2DVisibleSubject.asObservable();
    }

    /**
     * Observable for subscribing to 3D model visibility changes.
     *
     * @readonly
     * @returns {Observable<boolean>}
     * @memberof BaseMapAdapter
     */
    public get model3DVisible$(): Observable<boolean> {
        return this.#model3DVisibleSubject.asObservable();
    }

    /**
     * Observable for subscribing to bounds changed events.
     *
     * @readonly
     * @returns {Observable<GeoJSON.BBox>}
     * @memberof BaseMapAdapter
     */
    public get bounds$(): Observable<GeoJSON.BBox> {
        return this.boundsSubject.asObservable();
    }

    /**
     * Observable for subscribing to center changed events.
     *
     * @readonly
     * @returns {Observable<GeoJSON.Point>}
     * @memberof BaseMapAdapter
     */
    public get center$(): Observable<GeoJSON.Point> {
        return this.centerSubject.asObservable();
    }

    /**
     * Observable for subscribing to click events.
     *
     * @readonly
     * @returns {Observable<GeoJSON.Feature>}
     * @memberof BaseMapAdapter
     */
    public get click$(): Observable<GeoJSON.Feature> {
        return this.clickSubject.asObservable();
    }

    /**
     * Observable for subscribing to hover events.
     *
     * @readonly
     * @returns {Observable<{ locationId: string, featureType: string }>}
     * @memberof BaseMapAdapter
     */
    public get hover$(): Observable<{ locationId: string, featureType: string }> {
        return this.hoverSubject.asObservable();
    }

    /**
     * Sets/gets if MapsIndoors Locations is clickable.
     */
    public set isClickable(clickable: boolean) {
        if (this.isClickableSubject.getValue() !== clickable) {
            this.isClickableSubject.next(clickable);
        }
    }

    /**
     * Sets/gets if MapsIndoors Locations is clickable.
     *
     * @returns {boolean}
     */
    public get isClickable(): boolean {
        return this.isClickableSubject.getValue();
    }

    /**
     * MapViewState.
     *
     * @property
     * @readonly
     * @type {MapViewState}
     * @memberof BaseMapAdapter
     */
    public get viewState(): MapViewState {
        return this.#viewState;
    }

    constructor(element: HTMLElement) {
        super();
        this.#viewState = new MapViewState(this);
        this.#viewState.asObservable()
            .pipe(map(features => features.filter(feature => {
                if (!this.#withInZoomRange(feature as MapViewModel) ||
                    !this.#show2D && feature.properties.featureClass === FeatureClass.MODEL2D ||
                    !this.#show3D && feature.properties.featureClass === FeatureClass.MODEL3D ||
                    !this.#show3D && feature.properties.featureClass === FeatureClass.EXTRUSION ||
                    !this.#show3D && feature.properties.featureClass === FeatureClass.WALL) {
                    return false;
                }
                return true;
            })))
            .subscribe(this.setViewData.bind(this));
        this.#element = element;
    }

    /**
     * Sets the viewport to contain the given bounds.
     *
     * @param {GeoJSON.BBox} bbox - An array of numbers in [west, south, east, north] order.
     * @param {MapPadding} [padding]
     * @memberof BaseMapAdapter
     */
    abstract fitBounds(bounds: GeoJSON.BBox, padding?: MapPadding): void;

    /**
     * Returns the center of the map.
     *
     * @returns {GeoJSON.Point}
     * @memberof BaseMapAdapter
     */
    abstract getCenter(): GeoJSON.Point;

    /**
     * Sets cursor type.
     *
     * @abstract
     * @memberof BaseMapAdapter
     */
    abstract setMapMouseCursor(type: MapMouseCursor): void;

    /**
     * Returns the map's geographical bounds.
     *
     * @abstract
     * @returns {GeoJSON.BBox}
     * @memberof BaseMapAdapter
     */
    abstract getBounds(): GeoJSON.BBox;

    /**
     * Returns the map instance.
     *
     * @abstract
     * @returns {google.maps.Map | mapboxgl.Map}
     * @memberof BaseMapAdapter
     */
    abstract getMap(): Promise<google.maps.Map | mapboxgl.Map>;

    /**
     * Returns the zoom of the map.
     *
     * @returns {number}
     * @memberof BaseMapAdapter
     */
    abstract getZoom(): number;

    /**
     * Pans the map to the specified location.
     *
     * @param {GeoJSON.Point} position
     * @memberof BaseMapAdapter
     */
    abstract panTo(position: GeoJSON.Point): void;

    /**
     * Returns {x, y} representing pixel coordinates, relative to the map's container, that correspond to the specified GeoJSON Point.
     *
     * @abstract
     * @returns {{ x: number, y: number }}
     * @memberof BaseMapAdapter
     */
    abstract project(position: GeoJSON.Point): { x: number, y: number };

    /**
     * Sets the map's geographical center.
     *
     * @param {GeoJSON.Point} position
     */
    abstract setCenter(position: GeoJSON.Point): void;

    /**
     * Sets the map type to be displayed.
     *
     * @param {MapType} mapType
     * @memberof BaseMapAdapter
     */
    abstract setMapType(mapType: MapType): void;

    /**
     * Gets the current map type.
     *
     * @abstract
     * @returns {MapType}
     */
    abstract getMapType(): MapType;

    /**
     * Sets the maximum zoom level of the map.
     *
     * @param {number} level
     * @memberof BaseMapAdapter
     */
    abstract setMaxZoom(level: number): void;

    /**
     * Sets the overlay tile url.
     *
     * @abstract
     * @param {string} tileUrl
     * @memberof BaseMapAdapter
     */
    abstract setOverlayTileUrl(tileUrl: string): void;

    /**
     * Sets the visibility for 2D or 3D layers.
     *
     * @param {('2D' | '3D')} type
     * @param {boolean} visibility
     */
    setVisibility(type: '2D' | '3D', visibility: boolean): void {
        switch (type) {
            case '2D': {
                if (this.#show2D !== visibility) {
                    this.#show2D = visibility;
                    this.#model2DVisibleSubject.next(visibility);
                    this.viewState.refresh();
                }
                break;
            }
            case '3D': {
                if (this.#show3D !== visibility) {
                    this.#show3D = visibility;
                    this.#model3DVisibleSubject.next(visibility);
                    this.viewState.refresh();
                }
                break;
            }
        }
    }

    /**
     * Get the state of visibility for 2D or 3D layers.
     *
     * @param {('2D' | '3D')} type
     * @returns {boolean}
     */
    getVisibility(type: '2D' | '3D'): boolean {
        switch (type) {
            case '2D':
                return this.#show2D;
            case '3D':
                return this.#show3D;
        }
    }

    /**
     * Sets the zoom of the map.
     *
     * @param {number} level
     * @memberof BaseMapAdapter
     */
    abstract setZoom(level: number): void;

    /**
     * Sets the opacity value for the wall and extrusion layers.
     *
     * @param {{ extrusionOpacity: number, wallOpacity: number }} settings3D
     * @memberof BaseMapAdapter
     */
    abstract setSettings3D(settings3D: { extrusionOpacity: number, wallOpacity: number }): void;

    /**
     * Returns a GeoJSON Point representing geographical coordinates that correspond to the specified pixel coordinates.
     *
     * @abstract
     * @param {{x: number, y: number}} point
     * @returns  {GeoJSON.Point}
     * @memberof BaseMapAdapter
     */
    abstract unproject(point: { x: number, y: number }): Promise<GeoJSON.Point>;

    /**
     * Set the data displayed on the map.
     *
     * @abstract
     * @param {GeoJSON.Feature[]} features
     * @memberof BaseMapAdapter
     */
    protected abstract setViewData(features: GeoJSON.Feature[]): void;

    /**
     * Sets the viewport to contain the given geometry.
     *
     * @param {GeoJSON.Geometry} geometry
     * @param {MapPadding} [padding]
     * @memberof BaseMapAdapter
     */
    public fitGeometry(geometry: GeoJSON.Geometry, padding?: MapPadding): void {
        this.fitBounds(calculateBounds(geometry), padding);
    }

    /**
     * Returns the host element of the map.
     *
     * @returns {HTMLElement}
     * @memberof BaseMapAdapter
     */
    public getDiv(): HTMLElement {
        return this.#element;
    }
}

/**
 * Calculate the bounding box for the given geometry.
 * If the geometry is a point, a 5x5 meter bounds will be used.
 *
 * @param {GeoJSON.Geometry} geometry
 * @returns {GeoJSON.BBox}
 */
function calculateBounds(geometry: GeoJSON.Geometry): GeoJSON.BBox {
    if (geometry?.type === 'Point') {
        return bbox(createCircle(geometry, 5, { units: 'meters', steps: 4 }));
    } else {
        return bbox(geometry);
    }
}