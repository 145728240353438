<panel-header (cancelEvent)="emitCloseEvent()" header="Graphs"></panel-header>

<section>
    <span class="heading">
        View graph on {{venue.graphId}}
    </span>
    <table *ngIf="venue" class="std-table">
        <tr *ngFor="let graphId of graphIds">
            <td>
                {{graphId}}
            </td>
            <td>
                <button
                    class="mi-button mi-button--small mi-button--outline"
                    matTooltip="Show this Graph on the Map"
                    (click)="activeGraphChange.emit(graphId)">
                    Show
                </button>
            </td>
            <td>
                <button
                    class="mi-button mi-button--small mi-button--base"
                    (click)="setVenueGraph(graphId)"
                    [disabled]="venue.graphId === graphId">
                    Set as Venue Graph
                </button>
            </td>
                <td>
                    <button class="mi-button mi-button--delete mi-button--icon mi-button--small" (click)="deleteGraph(graphId)">
                        <img src="/assets/images/trash-bin_20x20.svg#white"/>
                    </button>
                </td>
            </tr>
    </table>
</section>