import { Injectable, OnDestroy } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { DisplayRule, Geometry, LatLng, Location, LocationType, PolygonDisplayRule } from '../../locations/location.model';
import { ExtendedLocation, LocationService } from '../../locations/location.service';
import { DrawingMode, GeoJSONGeometryType } from '../../shared/enums';
import { ViewState, ViewStateService } from '../view-state.service';
import { GoogleMaps2DModel } from './GoogleMaps2DModel';

import midt from '@mapsindoors/midt/tokens/tailwind-colors.json';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import memoize from '../../../utilities/Memoize';
import { Building } from '../../buildings/building.model';
import { BuildingService } from '../../buildings/building.service';
import { Floor } from '../../buildings/floor.model';
import { DisplayRuleService } from '../../services/DisplayRuleService/DisplayRuleService';
import { DrawingService } from '../../services/drawing.service';
import { MapService } from '../../services/map.service';
import { NotificationService } from '../../services/notification.service';
import { generateRectangularPolygonFromPoint, getGeometryFromGooglePolygon } from '../../shared/geometry-helper';
import { primitiveClone } from '../../shared/object-helper';

const memoizedGenerateRectangularPolygonFromPoint = memoize(generateRectangularPolygonFromPoint);

enum MapObjectIdPrefix {
    marker = 'LOCATION-MARKER:',
    polygon = 'LOCATION-POLYGON:',
    model2d = 'LOCATION-MODEL2D:'
}

@Injectable()
export class LocationsMapService implements OnDestroy {
    private readonly POLYGON_ZINDEX = 1000;
    private readonly MODEL2D_ZINDEX_BASE = 3e8;
    private readonly MIN_RENDER_ZOOM = 17;

    private highlightedLocationIds: string[] = [];
    private currentAreaAngle = 0;
    private defaultIconSize = 20;
    private editLocationMarker: google.maps.Marker;
    private editIcon: google.maps.Icon = {
        url: `${environment.iconsBaseUrl}move.png`,
        anchor: new google.maps.Point(16, 16),
        scaledSize: new google.maps.Size(32, 32)
    };
    private newPOIMarker: google.maps.Marker;
    private newPOIMarkerClickListener;
    private currentAreaCenterPoint: LatLng;
    private isLocationDetailsFormDirty = false;
    private isRouteElementDetailsEditorDirty = false;
    private markerDragSubject$ = new Subject<LatLng>();
    private polygonDragEndSubject$ = new Subject<{
        center: LatLng,
        geometry: Geometry,
        buildingAdministrativeId: string
    }>();

    public markerDrag$ = this.markerDragSubject$.asObservable().pipe(filter(latLng => !!latLng));
    public polygonDragEnd$ = this.polygonDragEndSubject$.asObservable().pipe(filter(polygon => !!polygon));

    constructor(
        private buildingService: BuildingService,
        private viewStateService: ViewStateService,
        private mapService: MapService,
        private locationService: LocationService,
        private drawingService: DrawingService,
        private notificationService: NotificationService,
        private displayRuleService: DisplayRuleService
    ) {
        this.locationService.isLocationDetailsFormDirty$.subscribe(isDirty => {
            this.isLocationDetailsFormDirty = isDirty;
        });
        this.locationService.isRouteElementDetailsEditorDirty$.subscribe(isDirty => {
            this.isRouteElementDetailsEditorDirty = isDirty;
        });
    }

    /**
     * Angular lifecycle ngOnDestroy.
     */
    ngOnDestroy(): void {
        this.locationService.isLocationDetailsFormDirty$.unsubscribe();
        this.locationService.isRouteElementDetailsEditorDirty$.unsubscribe();
    }

    /**
     * Deletes all locations from the map.
     *
     * @private
     * @memberof LocationsMapService
     */
    private deleteAllLocationMapObjects(): void {
        this.mapService.mapObjects?.forEach((mapObject, mapObjectId) => {
            if (mapObjectId.includes('LOCATION')) {
                this.mapService.removeFromMap(mapObject, mapObjectId);
            }
        });
    }

    /**
     * Set location id from session storage.
     *
     * @param {string} storageKey
     * @memberof LocationsMapService
     */
    public setLocationHighlightIdsFromStorage(storageKey: string): void {
        this.highlightedLocationIds = JSON.parse(sessionStorage.getItem(storageKey)) || [];
    }

    /**
     * Add location id to highlights array and session storage.
     *
     * @param {string} storageKey
     * @param {string} locationId
     * @memberof LocationsMapService
     */
    public addLocationHighlightId(storageKey: string, locationId: string): void {
        const modifiedLocationIds = JSON.parse(sessionStorage.getItem(storageKey)) || [];
        const updatedModifiedLocationIds = [...new Set([...modifiedLocationIds, locationId])];

        this.highlightedLocationIds = updatedModifiedLocationIds;
        sessionStorage.setItem(storageKey, JSON.stringify(updatedModifiedLocationIds));
    }

    /**
     * Delete location id from highlights array and session storage.
     *
     * @param {string} storageKey
     * @param {string} locationId
     * @memberof LocationsMapService
     */
    public deleteLocationHighlightId(storageKey: string, locationId: string): void {
        const modifiedLocationIds = JSON.parse(sessionStorage.getItem(storageKey)) || [];
        const updatedModifiedLocationIds = modifiedLocationIds.filter(id => id !== locationId);

        this.highlightedLocationIds = updatedModifiedLocationIds;
        sessionStorage.setItem(storageKey, JSON.stringify(updatedModifiedLocationIds));
    }

    /**
     * Draw the given locations.
     *
     * @param {ExtendedLocation[]} locations
     * @param {number} defaultIconSize
     * @param {number} floorIndex - The current floor index.
     * @memberof LocationsMapService
     */
    public drawLocations(locations: ExtendedLocation[], defaultIconSize: number, floorIndex: number): void {
        this.defaultIconSize = defaultIconSize;
        this.deleteAllLocationMapObjects();

        for (const location of locations) {
            this.drawLocation(location as ExtendedLocation, floorIndex);
        }
    }

    /**
     * Draw a location on the map.
     *
     * @param {ExtendedLocation} location
     * @param {number} floorIndex
     * @memberof LocationsMapService
     */
    public drawLocation(location: ExtendedLocation, floorIndex: number): void {
        switch (location.locationType.toLowerCase()) {
            case 'room':
                this.drawRoom(location, floorIndex);
                break;
            case 'area':
                this.drawArea(location, floorIndex);
                break;
        }
        this.drawPOI(location, floorIndex);
        this.draw2DModel(location, floorIndex);
    }

    /**
     * Draw a POI.
     *
     * @private
     * @param {ExtendedLocation} location
     * @param {number} floorIndex
     * @memberof LocationsMapService
     */
    private drawPOI(location: ExtendedLocation, floorIndex: number): void {
        const position: LatLng = location.geometry.type === GeoJSONGeometryType.Point
            ? { lat: location.geometry.coordinates[1], lng: location.geometry.coordinates[0] }
            : { lat: location.anchor.coordinates[1], lng: location.anchor.coordinates[0] };
        const marker = this.mapService.drawMarker(position, this.getIcon(location));

        marker.setTitle(location.name);
        marker.set('location', location);
        marker.setVisible(floorIndex === location.pathData.floor
            && this.mapService.getZoom() > this.MIN_RENDER_ZOOM);
        this.addLocationClickedEvent(marker);
        // add to mapObjects
        this.mapService.mapObjects.set(`${MapObjectIdPrefix.marker}${location.id}`, marker);
    }

    /**
     * Draw a room.
     *
     * @private
     * @param {ExtendedLocation} location
     * @param {number} floorIndex
     * @memberof LocationsMapService
     */
    private drawRoom(location: ExtendedLocation, floorIndex: number): void {
        const room = this.drawPolygon(location, floorIndex);
        this.mapService.mapObjects.set(`${MapObjectIdPrefix.polygon}${location.id}`, room);
        this.onPolygonMouseoverEvent(room, location.locationType);
        this.onPolygonMouseoutEvent(room, location.id);
        this.addLocationClickedEvent(room);
    }

    /**
     * Draw an area.
     *
     * @private
     * @param {ExtendedLocation} location
     * @param {number} floorIndex
     * @memberof LocationsMapService
     */
    private drawArea(location: ExtendedLocation, floorIndex: number): void {
        const area = this.drawPolygon(location, floorIndex);
        this.mapService.mapObjects.set(`${MapObjectIdPrefix.polygon}${location.id}`, area);
        this.onPolygonMouseoverEvent(area, location.locationType);
        this.onPolygonMouseoutEvent(area, location.id);
        this.addLocationClickedEvent(area);
        this.addAreaDragendEvent(area, location);
    }

    /**
     * Draw a polygon, set its display options.
     *
     * @private
     * @param {ExtendedLocation} location
     * @param {number} floorIndex
     * @returns {google.maps.Polygon}
     * @memberof LocationsMapService
     */
    private drawPolygon(location: ExtendedLocation, floorIndex: number): google.maps.Polygon {
        const displayRule = this.getPolygonDisplayRule(location);
        const polygon = this.mapService.drawPolygon(location.geometry);
        polygon.setOptions({
            fillColor: displayRule?.polygon?.fillColor || '#3071D9',
            fillOpacity: displayRule?.polygon?.fillOpacity || 0.2,
            strokeColor: displayRule?.polygon?.strokeColor || '#3071D9',
            strokeWeight: displayRule?.polygon?.strokeWidth || 2,
            strokeOpacity: displayRule?.polygon?.strokeOpacity || 1,
            zIndex: this.calculatezIndex(polygon)
        });

        if (location.locationType.toLowerCase() === 'room') {
            polygon.setOptions({
                fillColor: this.isLocationActive(location) ? '#ef6cce' : midt['tailwind-colors'].gray[400].value,
                fillOpacity: 0,
                strokeColor: this.isLocationActive(location) ? '#ef6cce' : midt['tailwind-colors'].gray[400].value,
                strokeOpacity: 0
            });

            // Highlight room if splitted or combined in current session
            if (this.highlightedLocationIds.includes(location.id)) {
                polygon.setOptions({
                    strokeColor: '#ef6cce',
                    strokeWeight: 2,
                    strokeOpacity: 1
                });
            }
        }

        polygon.set('location', location);
        polygon.setVisible(floorIndex === location.pathData.floor
            && this.mapService.getZoom() > this.MIN_RENDER_ZOOM);
        return polygon;
    }

    /**
     * Draw a 2D model on the map.
     *
     * @param {ExtendedLocation} location
     */
    private async draw2DModel(location: ExtendedLocation, floorIndex): Promise<void> {
        const displayRule = await this.displayRuleService.getDisplayRule(location.id);
        if (displayRule.model2D?.model) {
            const zIndex = this.MODEL2D_ZINDEX_BASE - (Math.floor(Math.sqrt(location?.area * 3)));
            const anchorCoordinates = location.anchor || location.geometry;
            const model2DViewModel = {
                type: 'Feature',
                id: `Model2D.${location.id}`,
                geometry: memoizedGenerateRectangularPolygonFromPoint(anchorCoordinates.coordinates, displayRule.model2D.widthMeters, displayRule.model2D.heightMeters, displayRule.model2D.bearing).geometry, // TODO: maybe cache the calculation somehow
                properties: {
                    widthMeters: displayRule.model2D.widthMeters,
                    heightMeters: displayRule.model2D.heightMeters,
                    bearing: displayRule.model2D.bearing,
                    imageUrl: displayRule.model2D.model,
                    anchor: anchorCoordinates.coordinates,
                    zIndex: zIndex
                }
            };

            const model2D = new GoogleMaps2DModel(model2DViewModel as GeoJSON.Feature, this.mapService.getMap());
            model2D.set('location', location);
            model2D.set('visible', floorIndex === location.pathData.floor
                && this.mapService.getZoom() > this.MIN_RENDER_ZOOM);
            this.mapService.mapObjects.set(`${MapObjectIdPrefix.model2d}${location.id}`, model2D as any); // as any: The model2D is not 100% adhering to the required MVCObject interface, but we don't want to implement missing and unused methods.
        }
    }

    /**
     * Retrieve a Location's icon from the displayRule.
     *
     * @private
     * @param {ExtendedLocation} location
     * @returns {google.maps.Icon}
     * @memberof LocationsMapService
     */
    private getIcon(location: ExtendedLocation): google.maps.Icon {
        const displayRule = location?.displayRule?.icon > '' ? location.displayRule : location.typeOfLocation?.displayRule;
        const icon: google.maps.Icon = {
            url: `${environment.iconsBaseUrl}misc/default-marker.png`,
            anchor: this.calculateAnchorPoint(displayRule),
            scaledSize: this.calculateScaledSize(displayRule)
        };

        icon.url = this.isLocationActive(location)
            ? displayRule?.icon || location.icon
            : `${environment.iconsBaseUrl}misc/inactive-marker.png`;

        return icon;
    }

    /**
     * Checks if the Location is active.
     *
     * @private
     * @param {Location} location
     * @returns {boolean}
     * @memberof LocationsMapService
     */
    private isLocationActive(location: Location): boolean {
        if (location?.activeTo) {
            return new Date() < new Date(location.activeTo);
        }

        if (location?.activeFrom) {
            return new Date() > new Date(location.activeFrom);
        }

        return true;
    }

    /**
     * Calculates the icon's anchor point.
     *
     * @private
     * @param {DisplayRule} displayRule
     * @returns {google.maps.Point}
     * @memberof LocationsMapService
     */
    private calculateAnchorPoint(displayRule: DisplayRule): google.maps.Point {
        const anchor = new google.maps.Point(this.defaultIconSize * 0.5, this.defaultIconSize * 0.5);
        if (displayRule?.imageSize) {
            const scaledSize = this.calculateScaledSize(displayRule);
            anchor.x = scaledSize.width * 0.5;
            anchor.y = scaledSize.height * 0.5;
        }

        return anchor;
    }

    /**
     * Calculates the icon's scaledSize.
     *
     * @private
     * @param {DisplayRule} displayRule
     * @returns {google.maps.Size}
     * @memberof LocationsMapService
     */
    private calculateScaledSize(displayRule: DisplayRule): google.maps.Size {
        const scaledSize = new google.maps.Size(this.defaultIconSize, this.defaultIconSize);
        const width = displayRule?.imageSize?.width || this.defaultIconSize;
        const height = displayRule?.imageSize?.height || this.defaultIconSize;
        const scale = displayRule?.imageScale || 1;

        // if the width or height are less than 2 pixels, then return the defaultIconSize
        if (width < 2 || height < 2) {
            return scaledSize;
        }

        scaledSize.width = Math.floor(width * scale);
        scaledSize.height = Math.floor(height * scale);
        return scaledSize;
    }

    /**
     * Set location visibility at map.
     * Includes special handling of 2D models since they need to be sorted
     * by area so that the larger ones are rendered underneath smaller ones.
     *
     * @param {string[]} locationIds
     * @param {Floor} floor
     * @memberof LocationsMapService
     */
    public setMapObjectsVisibility(locationIds: string[], floor: Floor): void {
        this.mapService.mapObjects.forEach((mapObject) => {
            const location = mapObject.get('location');
            if (location) {
                const isVisible = locationIds?.includes(location.id) &&
                    floor?.floorIndex === location.pathData.floor &&
                    this.mapService.getZoom() > this.MIN_RENDER_ZOOM;
                mapObject?.set('visible', isVisible);
            }
        });

    }

    /**
     * Calculate the polygon's zIndex. The bigger polygon's area, the smaller the zIndex.
     *
     * @private
     * @param {google.maps.Polygon} polygon
     * @returns {number}
     * @memberof LocationsMapService
     */
    private calculatezIndex(polygon: google.maps.Polygon): number {
        const area = google.maps.geometry.spherical.computeArea(polygon?.getPath());
        const zIndex = this.POLYGON_ZINDEX - (Math.floor(Math.sqrt(area)));
        return zIndex > 1 ? zIndex : 1;
    }

    /**
     * Listens for the mouseover event on the polygon.
     *
     * @private
     * @param {google.maps.Polygon} polygon
     * @memberof LocationsMapService
     */
    private onPolygonMouseoverEvent(polygon: google.maps.Polygon, type: string): void {
        this.mapService.addEventListener('mouseover', () => {
            polygon && polygon.setOptions({
                fillOpacity: type.toLowerCase() === 'room' ? 0.35 : type.toLowerCase() === 'area' ? 0.8 : 0,
                strokeWeight: 2,
                strokeOpacity: 1
            });
        }, polygon);
    }

    /**
     * Listens for the mouseout event on the polygon.
     *
     * @private
     * @param {google.maps.Polygon} polygon
     * @param {string} [locationId='']
     * @memberof LocationsMapService
     */
    private onPolygonMouseoutEvent(polygon: google.maps.Polygon, locationId = ''): void {
        const fillOpacity = polygon?.get('fillOpacity');
        const strokeWeight = polygon?.get('strokeWeight');
        const strokeOpacity = polygon?.get('strokeOpacity');

        this.mapService.addEventListener('mouseout', () => {
            const currentLocation = this.locationService.getSelectedLocation() as ExtendedLocation;
            if (locationId !== currentLocation?.id) {
                const isModifiedInSession = this.highlightedLocationIds.includes(locationId);
                const updatedStrokeWeight = isModifiedInSession ? 2 : strokeWeight;
                const updatedStrokeOpacity = isModifiedInSession ? 1 : strokeOpacity;
                polygon?.setOptions({ fillOpacity, strokeWeight: updatedStrokeWeight, strokeOpacity: updatedStrokeOpacity });
            }
        }, polygon);
    }

    /**
     * Add the click event listener.
     *
     * @private
     * @param {google.maps.MVCObject} instance
     * @memberof LocationsMapService
     */
    private addLocationClickedEvent(instance: google.maps.MVCObject): void {
        this.mapService.addEventListener('click', () => {
            if (this.isLocationDetailsFormDirty || this.isRouteElementDetailsEditorDirty) {
                this.notificationService.showWarning('You have unsaved changes!');
            } else {
                const location = instance?.get('location');

                if (this.viewStateService.currentViewState !== ViewState.Create) {
                    this.highlightLocationOnClick(location);
                }
            }
        }, instance);
    }

    /**
     * Adds the dragend event listener.
     *
     * @private
     * @param {google.maps.Polygon} polygon
     * @param {ExtendedLocation} location
     * @memberof LocationsMapService
     */
    private addAreaDragendEvent(polygon: google.maps.Polygon, location: ExtendedLocation): void {
        this.mapService.addEventListener('dragend', () => {
            const geometry = getGeometryFromGooglePolygon(polygon);
            this.currentAreaCenterPoint = this.mapService.getPolygonCenter(geometry);
            const buildings = this.buildingService.getBuildingsFromStore();
            const building = this.mapService.getPolygonBuilding(polygon, buildings, location.pathData.floor);
            const buildingAdministrativeId = building?.administrativeId;
            const marker = this.mapService.mapObjects.get(`${MapObjectIdPrefix.marker}${location.id}`) as google.maps.Marker;
            const model2d = this.mapService.mapObjects.get(`${MapObjectIdPrefix.model2d}${location.id}`) as unknown as GoogleMaps2DModel;

            marker?.setPosition(this.currentAreaCenterPoint);
            this.editLocationMarker?.setPosition(this.currentAreaCenterPoint);
            this.editLocationMarker?.setDraggable(false);
            this.polygonDragEndSubject$.next({
                center: this.currentAreaCenterPoint,
                geometry,
                buildingAdministrativeId
            });

            model2d?.setPosition(new google.maps.LatLng(this.currentAreaCenterPoint));
        }, polygon);
    }

    /**
     * Highlight the selected POI and open Location Details.
     *
     * @param {ExtendedLocation} location
     */
    public highlightLocationOnClick(location: ExtendedLocation): void {
        const previousLocation = this.locationService.getSelectedLocation() as ExtendedLocation;

        this.viewStateService.setViewStateObservable(ViewState.Update);

        // reset the previously selected location when a new one is selected.
        if (previousLocation && previousLocation.id !== location?.id) {
            this.resetCurrentLocation();
        }

        this.createEditLocationMarker(location);
        this.locationService.selectLocation(location);
        this.buildingService.setBuildingBasedOnLocation(location);

        // delete the previously selected area if it wasn't saved to the backend
        previousLocation?.id ?? this.drawingService.deleteSelectedShape();
        this.drawingService.clearSelection();
        previousLocation && this.clearRoomHighlight(previousLocation);
        location.locationType.toLowerCase() === 'area' && this.highlightArea(location);
        location.locationType.toLowerCase() === 'room' && this.highlightRoom(location);
    }

    /**
     * Get the polygon's displayRule.
     *
     * @param {ExtendedLocation} location
     * @returns {DisplayRule}
     * @memberof LocationsMapService
     */
    public getPolygonDisplayRule(location: ExtendedLocation): DisplayRule {
        let polygonDisplayRule: PolygonDisplayRule = null;

        if (location?.locationType?.toLowerCase() === 'area') {
            polygonDisplayRule = {
                fillColor: '#3071D9', //midtColors.color.blue.base.value
                fillOpacity: 0.2,
                strokeColor: '#3071D9', //midtColors.color.blue.base.value
                strokeOpacity: 1,
                strokeWidth: 2,
                visible: true
            };
        }

        const displayRule = primitiveClone(location?.displayRule || location?.typeOfLocation?.displayRule || { polygon: polygonDisplayRule });
        displayRule.polygon = displayRule.polygon || polygonDisplayRule;
        return displayRule;
    }

    /**
     * Update the location's map object.
     *
     * @param {ExtendedLocation} location
     * @param {number} floorIndex
     * @memberof LocationsMapService
     */
    public updateLocationMapObject(location: ExtendedLocation, floorIndex: number): void {
        this.deleteLocationMapObject(location);
        location && this.drawLocation(location, floorIndex);
    }

    /**
     * Delete the Location's map object.
     *
     * @param {ExtendedLocation} location
     * @memberof LocationsMapService
     */
    public deleteLocationMapObject(location: ExtendedLocation): void {
        const deleteFromMap = (keyPrefix: string): void => {
            const mapObjectId = `${keyPrefix}${location?.id}`;
            const mapObject = this.mapService.mapObjects.get(mapObjectId);
            this.mapService.removeFromMap(mapObject, mapObjectId);
        };
        const type = location?.locationType.toLowerCase();
        if (type === 'area' || type === 'room') deleteFromMap(MapObjectIdPrefix.polygon);

        deleteFromMap(MapObjectIdPrefix.marker);
        deleteFromMap(MapObjectIdPrefix.model2d);
    }

    /**
     * Remove new-poi marker.
     * Set map-state to default.
     *
     * @memberof LocationsMapService
     */
    public removeNewPOIMarker(): void {
        this.mapService.removeFromMap(this.newPOIMarker);
    }

    /**
     * Create a new POI marker.
     *
     * @param {ExtendedLocation} location
     * @param {Building[]} buildings
     * @memberof LocationsMapService
     */
    public createNewPOIMarker(location: ExtendedLocation, buildings: Building[]): void {
        this.viewStateService.setViewStateObservable(ViewState.Create);
        this.mapService.setMapObjectsClickability(false);

        this.mapService.getMap()?.setOptions({ draggableCursor: 'crosshair' });

        location.locationType = LocationType.POI;
        location.geometry.type = 'Point';

        this.newPOIMarkerClickListener = this.mapService.addEventListener('click', (event) => {
            this.mapService.getMap()?.setOptions({ draggableCursor: '' });
            this.viewStateService.setViewStateObservable(ViewState.Update);
            this.mapService.setMapObjectsClickability(true);

            const latLng: LatLng = { lat: event.latLng.lat(), lng: event.latLng.lng() };
            location.geometry.coordinates = [latLng.lng, latLng.lat];
            location.anchor = {
                coordinates: location.geometry.coordinates,
                type: location.geometry.type
            };
            this.newPOIMarker = this.mapService.drawMarker(latLng, this.mapService.createSymbol);
            this.newPOIMarker.setOptions({ draggable: true, zIndex: 9999, cursor: 'move' });

            // Open info window
            const content = '<strong>New location</strong>';
            this.mapService.openInfoWindow(content, this.newPOIMarker);

            // attach dragend event
            this.mapService.addEventListener('drag', (_event) => {
                const coordinates: LatLng = { lat: _event.latLng.lat(), lng: _event.latLng.lng() };
                this.newPOIMarker.setPosition(coordinates);
                this.markerDragSubject$.next(coordinates);
            }, this.newPOIMarker);

            location = this.updateNewPOIBuildingPath(location, buildings);
            this.locationService.selectLocation(location);

            this.newPOIMarkerClickListener.remove();
        });
    }

    /**
     * Cancel creation of new POI marker.
     *
     * @memberof LocationsMapService
     */
    public cancelCreateNewPOIMarker(): void {
        this.mapService.getMap()?.setOptions({ draggableCursor: '' });

        this.removeNewPOIMarker();
        this.newPOIMarkerClickListener.remove();
        // set mapObjects to be clickable
        this.mapService.setMapObjectsClickability(true);
    }

    /**
     * Update Building path for the newly created POI.
     *
     * @private
     * @param {ExtendedLocation} location
     * @param {Building[]} buildings
     * @returns {ExtendedLocation}
     * @memberof LocationsMapService
     */
    private updateNewPOIBuildingPath(location: ExtendedLocation, buildings: Building[]): ExtendedLocation {
        const building = this.locationService.getLocationBuilding(location, buildings);
        location.pathData.building = building?.administrativeId || location.pathData.building;
        location.buildingName = building?.displayName || 'OUTSIDE';
        return location;
    }

    /**
     * Remove the edit marker and its event listeners.
     *
     * @memberof LocationsMapService
     */
    public removeEditLocationMarker(): void {
        this.mapService.removeFromMap(this.editLocationMarker);
    }

    /**
     * Reset the current location to its original state.
     */
    public resetCurrentLocation(): void {
        const originalLocation = this.locationService.getSelectedLocation() as ExtendedLocation;

        if (!originalLocation?.id) {
            return;
        }

        this.updateLocationMapObject(originalLocation, originalLocation.pathData.floor);
        this.removeEditLocationMarker();
        this.mapService.closeInfoWindow();
    }

    /**
     * Create the edit marker.
     * Attach a dragend event listener to the marker.
     *
     * @private
     * @param {google.maps.MVCObject} location
     * @memberof LocationsMapService
     */
    private createEditLocationMarker(location: ExtendedLocation): void {
        this.removeEditLocationMarker();
        this.removeNewPOIMarker();

        const position = location?.geometry?.type === GeoJSONGeometryType.Point
            ? { lat: location.geometry.coordinates[1], lng: location.geometry.coordinates[0] }
            : { lat: location.anchor.coordinates[1], lng: location.anchor.coordinates[0] };

        this.editLocationMarker = this.mapService.drawMarker(position, this.editIcon);
        this.editLocationMarker.setOptions({ draggable: true, zIndex: 9999, cursor: 'move', position });

        // Open info window
        const locationName = `<strong> ${location.name} </strong>`;
        const content = location.externalId ? `${location.externalId} - ${locationName}` : locationName;
        const locationMarker = this.mapService.mapObjects.get(`${MapObjectIdPrefix.marker}${location.id}`) as google.maps.Marker;
        const model2d = this.mapService.mapObjects.get(`${MapObjectIdPrefix.model2d}${location.id}`) as unknown as GoogleMaps2DModel;

        this.mapService.openInfoWindow(content, locationMarker);

        this.editLocationMarker.addListener('drag', (event) => {
            // Emit drag event to update the current location in the location details view
            this.markerDragSubject$.next(event.latLng.toJSON());
        });

        this.editLocationMarker.addListener('dragend', (event) => {
            // Set marker's position to be the destination's position
            locationMarker.setPosition(event.latLng);
            model2d?.setPosition(event.latLng);
            // Check if the destination's position is inside the location's polygon.
            // If not, move the marker back to its original position.
            if (location.geometry?.type === GeoJSONGeometryType.Polygon) {
                const polygon = new google.maps.Polygon({
                    paths: location.geometry.coordinates?.map(path => {
                        return path.map(coordinates => new google.maps.LatLng(coordinates[1], coordinates[0]));
                    })
                });

                const polygonContainsPoint = google.maps.geometry.poly.containsLocation(event.latLng, polygon);
                if (!polygonContainsPoint) {
                    locationMarker.setPosition(position);
                    this.editLocationMarker.setPosition(position);
                    model2d?.setPosition(new google.maps.LatLng(position));
                }
            }

            // Emit dragend event to update the current location in the location details view
            this.markerDragSubject$.next(locationMarker.getPosition().toJSON());
        });
    }

    //  #region Areas

    /**
     * Initialize the DrawingManager.
     *
     * @memberof LocationsMapService
     */
    public initDrawingManager(): void {
        this.drawingService.initializeDrawing();
    }

    /**
     * Highlight the given Area.
     *
     * @private
     * @param {ExtendedLocation} location
     * @memberof LocationsMapService
     */
    private highlightArea(location: ExtendedLocation): void {
        const polygon = this.getAreaPolygon(location);
        if (polygon) {
            this.drawingService.setSelection(polygon);
            this.currentAreaAngle = 0;
            if (location.anchor) {
                this.currentAreaCenterPoint = {
                    lat: location.anchor.coordinates[1],
                    lng: location.anchor.coordinates[0]
                };
                return;
            }

            const geometry = getGeometryFromGooglePolygon(polygon);
            this.currentAreaCenterPoint = this.mapService.getPolygonCenter(geometry);
        }
    }

    /**
     * Highlight the given room.
     *
     * @param {ExtendedLocation} location
     * @memberof LocationsMapService
     */
    public highlightRoom(location: ExtendedLocation): void {
        const polygon = this.mapService.mapObjects.get(`${MapObjectIdPrefix.polygon}${location?.id}`) as google.maps.Polygon;
        polygon?.setOptions({ fillOpacity: 0.5, strokeWeight: 2 });
    }

    /**
     * Clear the given room's highlight.
     *
     * @param {ExtendedLocation} location
     * @memberof LocationsMapService
     */
    public clearRoomHighlight(location: ExtendedLocation): void {
        const displayRule = this.getPolygonDisplayRule(location);
        const polygon = this.mapService.mapObjects.get(`${MapObjectIdPrefix.polygon}${location?.id}`) as google.maps.Polygon;
        polygon?.setOptions({
            fillOpacity: displayRule?.polygon?.fillOpacity || 0,
            strokeWeight: displayRule?.polygon?.strokeWidth || 0,
        });
    }

    /**
     * Clear the current Area highlight.
     *
     * @param {ExtendedLocation} location
     * @memberof LocationsMapService
     */
    public clearCurrentAreaHighlight(location: ExtendedLocation): void {
        !location?.id ? this.drawingService.deleteSelectedShape() : this.drawingService.clearSelection();
    }

    /**
     * Initiate the drawing of an Area.
     *
     * @param {ExtendedLocation} location
     * @param {Building[]} buildings
     * @memberof LocationsMapService
     */
    public initiateAreaDrawing(location: ExtendedLocation, buildings: Building[]): void {
        this.viewStateService.setViewStateObservable(ViewState.Create);

        // this.mapService.getMap()?.setOptions({ draggableCursor: 'crosshair' });

        location.locationType = LocationType.Area;
        // make mapObjects unclickable when drawing
        this.mapService.setMapObjectsClickability(false);

        // Initial drawing mode
        this.drawingService.setDrawingMode(DrawingMode.Polygon);

        this.drawingService.drawingComplete$
            .pipe(take(1))
            .subscribe(polygon => {
                this.viewStateService.setViewStateObservable(ViewState.Update);

                this.mapService.setMapObjectsClickability(true);
                this.updateNewAreaData(location, buildings, polygon);
                this.locationService.selectLocation(location);
                const geometry = getGeometryFromGooglePolygon(polygon);
                this.currentAreaCenterPoint = this.mapService.getPolygonCenter(geometry);
            });
    }

    /**
     * Cancel drawing of area.
     *
     * @memberof LocationsMapService
     */
    public cancelAreaDrawing(): void {
        this.mapService.getMap()?.setOptions({ draggableCursor: '' });

        this.mapService.setMapObjectsClickability(true);
        this.drawingService.setDrawingMode(null);
    }

    /**
     * Update the Building path for the newly created Area.
     * Update the DisplayRule for the newly created Area.
     *
     * @private
     * @param {ExtendedLocation} location
     * @param {Building[]} buildings
     * @param {google.maps.Polygon} polygon
     * @memberof LocationsMapService
     */
    private updateNewAreaData(location: ExtendedLocation, buildings: Building[], polygon: google.maps.Polygon): void {
        location.geometry = getGeometryFromGooglePolygon(polygon);
        const building = this.mapService.getPolygonBuilding(polygon, buildings, location.pathData.floor);

        location.pathData.building = building?.administrativeId || location.pathData.building;
        location.displayRule = this.getPolygonDisplayRule(location);
    }

    /**
     * Change the color of the Area.
     *
     * @param {ExtendedLocation} location
     * @param {string} color
     * @memberof LocationsMapService
     */
    public changeAreaColor(location: ExtendedLocation, color: string): void {
        const polygon = this.getAreaPolygon(location);
        polygon.set('fillColor', color);
        polygon.set('strokeColor', color);
    }

    /**
     * Find the polygon object because an area may also have a marker object.
     *
     * @private
     * @param {ExtendedLocation} location
     * @returns {google.maps.Polygon}
     * @memberof LocationsMapService
     */
    private getAreaPolygon(location: ExtendedLocation): google.maps.Polygon {
        return location?.id // if it is a newly created location, then return the selectedShape from the drawingService
            ? this.mapService.mapObjects.get(`${MapObjectIdPrefix.polygon}${location.id}`) as google.maps.Polygon
            : this.drawingService.getSelectedShapeValue();
    }

    /**
     * Rotate an Area.
     *
     * @param {ExtendedLocation} location
     * @param {number} angle
     * @memberof LocationsMapService
     */
    public rotateArea(location: ExtendedLocation, angle: number): void {
        const rotationAngle = angle - this.currentAreaAngle;
        const projection = this.mapService.getMap().getProjection();
        //rotate around center point
        const origin = projection.fromLatLngToPoint(this.currentAreaCenterPoint);
        const polygon = this.getAreaPolygon(location);
        // Update coordinate for each point
        const coords = polygon?.getPath().getArray().map((latLng) => {
            const point = projection.fromLatLngToPoint(latLng);
            const rotatedLatLng = projection.fromPointToLatLng(this.getRotatedPoint(point, origin, rotationAngle));
            return { lat: rotatedLatLng.lat(), lng: rotatedLatLng.lng() };
        });

        polygon.setPath(coords);
        this.currentAreaAngle = this.currentAreaAngle + rotationAngle;
    }

    /**
     * Get rotated point.
     *
     * @private
     * @param {google.maps.Point} point
     * @param {google.maps.Point} origin
     * @param {number} angle
     * @returns {google.maps.Point}
     * @memberof LocationsMapService
     */
    private getRotatedPoint(point: google.maps.Point, origin: google.maps.Point, angle: number): google.maps.Point {
        const angleRad = angle * Math.PI / 180.0; // Angle in Radians
        const newPoint = {
            x: Math.cos(angleRad) * (point.x - origin.x) - Math.sin(angleRad) * (point.y - origin.y) + origin.x,
            y: Math.sin(angleRad) * (point.x - origin.x) + Math.cos(angleRad) * (point.y - origin.y) + origin.y
        } as google.maps.Point;

        return newPoint;
    }
    // #endregion
}
