export enum MediaCategory {
    MIIcon = 'MapsIndoors Icons',
    Image = 'Uploaded image',
    Model3D = 'Uploaded 3D model'
}

export enum MediaSortBy {
    Recent = 'Recently uploaded',
    Oldest = 'Oldest upload',
    AZ = 'File name A-Z',
    ZA = 'File name Z-A'
}

export enum MediaFileType {
    SVG = 'svg',
    PNG = 'png',
    JPG = 'jpg',
    GLB = 'glb'
}

export enum MediaUploadError {
    FileTypeError,
    MaxFileSizeError,
    MinFileSizeError,
    ExistingFileNameError,
    UploadedFileNameError,
    UnsupportedElements,
    NoError
}

export enum MediaAcceptedFileType {
    'image/svg+xml' = MediaFileType.SVG,
    'image/svg' = MediaFileType.SVG,
    'image/png' = MediaFileType.PNG,
    'image/jpg' = MediaFileType.JPG,
    'image/jpeg' = MediaFileType.JPG,
    '.glb' = MediaFileType.GLB
}

export enum MediaLibrarySource {
    Icon = 'icon',
    Model2D = 'model2D',
    Model3D = 'model3D'
}