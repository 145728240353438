import { BBox, Feature, MultiPolygon, Polygon } from 'geojson';

import booleanIntersects from '@turf/boolean-intersects';
import { bboxPolygon, feature as toFeature } from '@turf/turf';

import { MapViewModel, MapViewModelFactory } from '../../../viewmodels/MapViewModelFactory/MapViewModelFactory';
import * as turf from '@turf/turf';
import { DisplayRuleService } from '../../services/DisplayRuleService/DisplayRuleService';
import { Building } from '../building.model';
import { Venue } from '../../venues/venue.model';
import { PolygonViewModel } from '../../../viewmodels/PolygonViewModel/PolygonViewModel';
import { MapsIndoorsData } from '../../shared/enums/MapsIndoorsData';
import { FilterOptions } from '../../map/filters-bar/filter-options.model';

export class OutsideAreaMapViewModelFactory extends MapViewModelFactory<{ venue: Venue, buildings: Building[], activeFilters: FilterOptions }> {

    constructor(private displayRuleService: DisplayRuleService) {
        super();
    }

    /**
     * Creates MapViewModels for the given Outside area.
     *
     * @param {Building} outsideArea
     * @param {number} sortKey
     * @returns {Promise<MapViewModel[]>}
     * @memberof FloorOutlineMapViewModelFacory
     */
    async create({ venue, buildings }: { venue: Venue, buildings: Building[]}, sortKey?: number): Promise<MapViewModel[]> {
        const viewModels: MapViewModel[] = [];
        const displayRule = await this.displayRuleService.getDisplayRule();

        // Logic to exclude building geometries from venue geometries.
        let outdoorAreaPolygon: Feature<(Polygon | MultiPolygon)> = turf.polygon([...venue.geometry.coordinates]);

        buildings.forEach(building => {
            const buildingPolygon = turf.multiPolygon(building.geometry.coordinates);
            outdoorAreaPolygon = turf.difference(outdoorAreaPolygon, buildingPolygon);
        });

        displayRule.clickable = false;
        displayRule.polygon.strokeColor = '#EF6CCE';
        displayRule.polygon.strokeWidth = 5;
        displayRule.polygon.zoomFrom = 15;
        displayRule.polygon.zoomTo = 22;

        viewModels.push(await PolygonViewModel.create(venue.id, outdoorAreaPolygon.geometry, displayRule, sortKey, MapsIndoorsData.Floor));

        return Promise.resolve(viewModels);
    }

    /**
     * Checks if the given location intersects with the given bounds.
     *
     * @param {{ venue: Venue, buildings: Building[] }} venue
     * @param {BBox} bounds
     * @returns {boolean}
     */
    intersectsWithBounds({ venue }: { venue: Venue, buildings: Building[] }, bounds: BBox): boolean {
        const boundsAsPolygon = bboxPolygon(bounds);
        return booleanIntersects(boundsAsPolygon, toFeature(venue.geometry));
    }

    /**
     * Checks if the floorIndex for the given location equals the given floorIndex.
     *
     * @returns {boolean}
     */
    floorEquals(): boolean {
        return true;
    }
}