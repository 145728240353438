import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { MapToolbar } from '../../map-toolbar.interface';

export enum EditorMode {
    Move = 1 << 0,
    Edit = 1 << 1,
    MoveAnchor = 1 << 2
}


@Component({
    selector: 'location-details-toolbar',
    templateUrl: './location-details-toolbar.component.html',
    styleUrls: ['./location-details-toolbar.component.scss'],
})
export class LocationDetailsToolbarComponent implements MapToolbar, OnDestroy {
    #destroySubject: Subject<void> = new Subject();
    #editorModeChanges: Subject<EditorMode> = new Subject();
    #editorMode: EditorMode = EditorMode.Move;
    #supportedModes: EditorMode = EditorMode.Move;
    #isVisible: boolean = false;

    public readonly EditorMode: typeof EditorMode = EditorMode;

    @Output() public readonly destroy = this.#destroySubject.asObservable();
    @Output() public readonly editorModeChanges = this.#editorModeChanges.asObservable();
    @Output() public readonly undoEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() public readonly redoEvent: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Angular OnDestroy lifecycle hook.
     */
    ngOnDestroy(): void {
        this.#editorModeChanges.complete();
        this.#destroySubject.next();
        this.#destroySubject.complete();
    }

    /**
     * Redo.
     *
     * @public
     */
    public redo(): void {
        this.redoEvent.emit();
    }

    /**
     * Undo.
     *
     * @public
     */
    public undo(): void {
        this.undoEvent.emit();
    }

    /**
     * Set the active editor mode.
     *
     * @public
     * @param {EditorMode} mode
     */
    public setEditorMode(mode: EditorMode): void {
        if (this.#editorMode !== mode) {
            this.#editorMode = mode;
            this.#editorModeChanges.next(mode);
        }
    }

    /**
     * Supported editor modes.
     *
     * @public
     * @type {EditorMode}
     */
    public set supportedModes(modes: EditorMode) {
        this.#supportedModes = modes;
        this.#isVisible = (modes & (modes - 1)) > 0;
    }

    /**
     * Supported editor modes.
     *
     * @public
     * @type {EditorMode}
     */
    public get supportedModes(): EditorMode {
        return this.#supportedModes;
    }

    /**
     * Selected editor mode.
     *
     * @public
     * @type {EditorMode}
     */
    public get editorMode(): EditorMode {
        return this.#editorMode;
    }

    /**
     * Toolbar visibility.
     * If only one editor mode is supported no tools should be shown in the toolbar.
     *
     * @public
     * @readonly
     * @type {boolean}
     */
    public get isVisible(): boolean {
        return this.#isVisible;
    }

    /**
     * Check if a specific editor mode is supported.
     *
     * @public
     * @param {EditorMode} mode
     * @returns {boolean}
     */
    public isModeSupported(mode: EditorMode): boolean {
        return (this.#supportedModes & mode) === mode;
    }
}