<panel-header (cancelEvent)="onCloseDialog()" [header]="header"></panel-header>
<navigatable-container>
    <display-rule-details-editor
        [displayRules]="displayRules"
        [isGeometrySettingsVisible]="isGeometrySettingsVisible"
        [is3DWallsSectionVisible]="is3DWallsSectionVisible"
        [geometries]="geometries"
        (formSubmit)="onFormSubmit($event)"
        (formDiscard)="onFormDiscard($event)">
    </display-rule-details-editor>
</navigatable-container>
