<div class="page-content">
  <mat-table [dataSource]="dataSource">
    <!-- element Column -->
    <ng-container matColumnDef="element">
      <mat-header-cell *matHeaderCellDef fxFlex="10"> Element </mat-header-cell>
      <mat-cell *matCellDef="let item" fxFlex="10"> {{item.element}} </mat-cell>
    </ng-container>
  
    <!-- id Column -->
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef fxFlex="20"> Id </mat-header-cell>
      <mat-cell *matCellDef="let item" fxFlex="20"> {{item.id}} </mat-cell>
    </ng-container>
  
    <!-- administrativeId Column -->
    <ng-container matColumnDef="administrativeId">
      <mat-header-cell *matHeaderCellDef> Administrative Id </mat-header-cell>
      <mat-cell *matCellDef="let item"> {{item.administrativeId}} </mat-cell>
    </ng-container>
  
    <!-- issue Column -->
    <ng-container matColumnDef="issue">
      <mat-header-cell *matHeaderCellDef fxFlex> Issue </mat-header-cell>
      <mat-cell *matCellDef="let item" fxFlex> {{item.issue}} </mat-cell>
    </ng-container>
  
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
