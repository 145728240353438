import { Subject, Subscription, merge } from 'rxjs';
import { GeodataEditorOperation } from './GeodataEditorOperation';
import { GeodataEditorViewState } from '../GeodataEditorViewState';
import { GeodataEditor } from '../GeodataEditor.factory';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { GraphData } from '../../map/graph-data.model';
import { MapMouseCursor } from '../../MapAdapter/BaseMapAdapter';

export class GraphBoundsEditorOperation extends GeodataEditorOperation {

    #subscription: Subscription = new Subscription();
    private _graph: any;

    constructor(
        private graph: GraphData,
        protected _changesSubject: Subject<{ geometry: GeoJSON.Geometry, anchor?: GeoJSON.Point }>,
        protected _editorViewState: GeodataEditorViewState,
        protected _geodataEditor: GeodataEditor,
        protected onComplete: () => void
    ) {
        super(_changesSubject, _editorViewState, onComplete);
        this._graph = this.graph;

        _changesSubject.subscribe(({ geometry, anchor }) => {
            this._graph.geometry = geometry;
            this._graph.anchor = anchor;
        });

        this._editorViewState.setGraphBounds(this.graph);

        this.#subscription = this.modify();
    }

    /**
     * Redraw features to refresh the styling.
     */
    public redraw(): void {
        this?._editorViewState.setGraphBounds(this._graph);
    }

    /**
     * End the operation.
     */
    public complete(): void {
        if (!this._changesSubject.isStopped) {
            this._geodataEditor.showPolygonHandles(false);
            this._changesSubject.complete();
            this.#subscription.unsubscribe();
            this?.onComplete();
        }
    }

    /**
     * Reset all changes.
     */
    public reset(): void { }

    /**
     * Initialize the modify operation.
     *
     * @private
     * @returns {Subscription}
     */
    public modify(): Subscription {
        this._geodataEditor.showPolygonHandles(true);
        const mouseDownOnMidpointsLayer = this._geodataEditor.mouseDownOnMidpointsLayer$
            .pipe(
                tap(({ position, insertAfter }) => {
                    const polygon = this._editorViewState.getGeometry(`POLYGON:${this._graph.id}`) as GeoJSON.Polygon;
                    polygon.coordinates[0].splice(insertAfter, 0, position.coordinates);
                    this._editorViewState.setGeometries([
                        [`POLYGON:${this._graph.id}`, polygon],
                        [`HIGHLIGHT:${this._graph.id}`, polygon],
                    ]);
                }),
                switchMap(({ insertAfter: index }) =>
                    this._geodataEditor.mouseMoveUntilMouseUp$.pipe(
                        tap((position) => this.updatePolygonGeometry(this._graph, index, position)),
                        finalize(() => {
                            const geometry = this._editorViewState.getGeometry(`POLYGON:${this._graph.id}`) as GeoJSON.Polygon;
                            this._changesSubject.next({ geometry });
                            this._geodataEditor.adapter.setMapMouseCursor(MapMouseCursor.Default);
                        })
                    ))
            );

        const mouseDownOnHandlesLayer = this._geodataEditor.mouseDownOnHandlesLayer$
            .pipe(switchMap(({ index }) =>
                this._geodataEditor.mouseMoveUntilMouseUp$.pipe(
                    tap((position) => this.updatePolygonGeometry(this._graph, index, position)),
                    finalize(() => {
                        const geometry = this._editorViewState.getGeometry(`POLYGON:${this._graph.id}`) as GeoJSON.Polygon;
                        this._changesSubject.next({ geometry });
                        this._geodataEditor.adapter.setMapMouseCursor(MapMouseCursor.Default);
                    })
                )
            ));

        this.redraw();

        return merge(mouseDownOnHandlesLayer, mouseDownOnMidpointsLayer).subscribe();
    }

    /**
     * Updates the position of the vertex at the given index.
     *
     * @private
     * @param {GraphData} graph
     * @param {number} index
     * @param {GeoJSON.Point} position
     * @memberof GeodataEditor
     */
    private updatePolygonGeometry(graph: GraphData, index: number, position: GeoJSON.Point): void {
        const polygon: GeoJSON.Polygon = this._editorViewState.getGeometry(`POLYGON:${graph.id}`) as GeoJSON.Polygon;

        if (!polygon) {
            return;
        }

        polygon.coordinates[0][index] = position.coordinates;

        // The first and the last point in a polygon is the same, so if the index is 0 we also need to update the last point in the polygon.
        if (index === 0) {
            polygon.coordinates[0].splice(-1, 1, position.coordinates);
        }

        this._editorViewState.setGeometries([
            [`POLYGON:${graph.id}`, polygon]
        ]);
    }
}