import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { BuildingsComponent } from './buildings.component';
import { BuildingsRoutingModule } from './buildings-routing.module';

@NgModule({
    declarations: [
        BuildingsComponent,
    ],
    imports: [
        SharedModule,
        BuildingsRoutingModule
    ],
})
export class BuildingsModule { }
