import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export enum ViewState {
    Default,
    Create,
    Update,
    AddDoor,
    AddMultipleDoors
}
@Injectable({
    providedIn: 'root'
})
export class ViewStateService {
    private viewState$ = new Subject<ViewState>();
    private _currentViewState: ViewState;

    /**
     * Get the current view state.
     *
     * @returns {ViewState}
     */
    public get currentViewState(): ViewState {
        return this._currentViewState;
    }

    /**
     * Set view state observable.
     *
     * @param {ViewState} viewState
     * @memberof ViewStateService
     */
    public setViewStateObservable(viewState: ViewState): void {
        this._currentViewState = viewState;
        this.viewState$.next(viewState);
    }

    /**
     * Get view state observable.
     *
     * @returns {Observable<ViewState>}
     * @memberof ViewStateService
     */
    public getViewStateObservable(): Observable<ViewState> {
        return this.viewState$.asObservable();
    }
}
