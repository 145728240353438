/**
 * Calls the passed function after the specified amount of time has passed.
 *
 * @param {Function} func
 * @param {number} timeout
 * @returns {(...args: any[]) => void}
 */
export function debounce(func: Function, timeout: number = 100): (...args: any[]) => void {
    let timer: ReturnType<typeof setTimeout>;
    return (...args): void => {
        clearTimeout(timer);
        timer = setTimeout(() => func.call(this, ...args), timeout);
    };
}
