<div fxFlexFill fxLayout="column" style="overflow: hidden;">
    <mat-toolbar class="mat-elevation-z2 page-toolbar">
        <div fxFlex>
            <button (click)="openSidebar()" mat-raised-button>
                Display settings
            </button>
            <mat-form-field style="width: 120px; margin-left: 16px; transform: translate(0px, 5px);">
                <mat-select [(ngModel)]="scale" placeholder="Usage (scale)">
                    <mat-option [value]="1">
                        Web (1)
                    </mat-option>
                    <mat-option [value]="2">
                        Retina (2)
                    </mat-option>
                    <mat-option [value]="4">
                        Print (4)
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex></div>
        <div class="tight">
            <div class="control-group">
                <mat-slider style="width: 150px;"
                    [invert]="invert"
                    [max]="max"
                    [min]="min"
                    [step]="step"
                    [thumbLabel]="thumbLabel"
                    [(ngModel)]="exportFrameWidth"
                    (change)="checkFrameWidth()">
                </mat-slider>
                <mat-form-field>
                    <input [(ngModel)]="exportFrameWidth" (blur)="checkFrameWidth()" matInput placeholder="Width">
                </mat-form-field>
            </div>
            <div class="control-group">
                <mat-slider style="width: 150px;"
                    [invert]="invert"
                    [max]="max"
                    [min]="min"
                    [step]="step"
                    [thumbLabel]="thumbLabel"
                    [(ngModel)]="exportFrameHeight"
                    (change)="checkFrameHeight()">
                </mat-slider>
                <mat-form-field>
                    <input [(ngModel)]="exportFrameHeight" (blur)="checkFrameHeight()" matInput placeholder="height">
                </mat-form-field>
            </div>
            <div class="control-group" style="margin-right: 0px;">
                <mat-slider style="width: 90px;"
                    [invert]="invert"
                    [max]="90"
                    [min]="-90"
                    [step]="1"
                    [thumbLabel]="thumbLabel"
                    [(ngModel)]="exportFrameRotation"
                    (change)="exportFrameRotate()">
                </mat-slider>
                <mat-form-field>
                    <input [(ngModel)]="exportFrameRotation" (keyup)="exportFrameRotate()" matInput placeholder="rotation">
                </mat-form-field>
            </div>
        </div>

    </mat-toolbar>

    <mat-progress-bar color="accent" mode="indeterminate" *ngIf="isProgressbarVisible"></mat-progress-bar>

    <div fxLayout="column" class="list-sidebar mat-elevation-z8" style="width: 360px;" *ngIf="showSettings">
        <mat-toolbar class="secondary-toolbar">
            <div fxFlex style="position: relative;">
                <h2>Display Rules</h2>
            </div>
            <div fxFlex="<grow>"></div>
            <div fxFlex class="IE-FlexAuto">
                <button (click)="closeSidebar()" mat-icon-button>
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </mat-toolbar>
        <div style="overflow: auto;" fxFlex="<grow>" class="page-content">
            <div class="column" fxLayout="column">
                <div fxLayout="row">
                    <div fxFlex>
                        <p>Icon size</p>
                        <div>
                            <mat-form-field style="width: 40px; margin-right: 16px;">
                                <input [(ngModel)]="exportedIconSize" matInput placeholder="Size">
                            </mat-form-field>
                            <mat-slider style="width: 140px;"
                                [invert]="invert"
                                [max]="48"
                                [min]="12"
                                [step]="4"
                                [thumbLabel]="thumbLabel"
                                [(ngModel)]="exportedIconSize">
                            </mat-slider>
                            <div class="icon-size-preview" style="margin-left: 16px;" [style.width]="exportedIconSize + 'px'">
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 85.793 85.795" enable-background="new 0 0 85.793 85.795" xml:space="preserve">
                                    <g>
                                        <g>
                                            <g>
                                                <path fill="#4DAF4F" d="M16.296,69.496l17.42-19.035c-2.777-3.436-2.571-8.486,0.623-11.678c3.416-3.417,8.954-3.416,12.371,0
													c0.051,0.051,0.093,0.105,0.143,0.158c1.477-1.475,4.302-4.302,10.558-10.56c-9.8-9.801-25.694-9.801-35.496,0.001
													C10.339,40.375,16.544,61.242,0,85.793C2.048,83.744,8.749,77.044,16.296,69.496z" />
                                            </g>
                                            <path fill="#037D3F" d="M16.3,69.495l19.035-17.418c3.437,2.779,8.485,2.571,11.679-0.624c3.418-3.416,3.416-8.955,0-12.371
												c-0.052-0.051-0.108-0.092-0.159-0.142c1.476-1.477,4.301-4.302,10.558-10.559c9.802,9.8,9.803,25.695,0.001,35.497
												C45.422,75.455,24.553,69.247-0.001,85.795C2.051,83.745,8.752,77.043,16.3,69.495z" />
                                        </g>
                                        <path fill="#037D3F" d="M85.793,29.45c-2.245-6.634-6.003-12.87-11.292-18.158L60.355,25.438c3.778,3.779,6.197,8.397,7.274,13.253
											C73.371,34.223,79.614,31.116,85.793,29.45z" />
                                        <path fill="#4DAF4F" d="M56.344,0c6.634,2.245,12.871,6.004,18.159,11.292L60.355,25.438c-3.778-3.778-8.396-6.198-13.253-7.273
											C51.571,12.423,54.678,6.18,56.344,0z" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row">
                    <div fxFlex>
                        <p>Label content</p>
                        <mat-radio-group [(ngModel)]="labelProperty">
                            <mat-radio-button style="margin-right: 32px;" [value]="'name'">Name</mat-radio-button>
                            <mat-radio-button [value]="'externalId'">External ID</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div fxLayout="row">
                    <div fxFlex>
                        <br />
                        <p>Label rotation</p>
                        <mat-form-field style="width: 40px; margin-right: 16px;">
                            <input [(ngModel)]="labelRotation" matInput placeholder="Angle">
                        </mat-form-field>
                        <mat-slider style="width: 140px;"
                            [invert]="invert"
                            [max]="345"
                            [min]="0"
                            [step]="30"
                            [thumbLabel]="thumbLabel"
                            [(ngModel)]="labelRotation">
                        </mat-slider>
                        <div class="icon-size-preview" style="width: 24px;">
                            <svg opacity=".5" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 85.793 85.795" enable-background="new 0 0 85.793 85.795" xml:space="preserve">
                                <g>
                                    <g>
                                        <g>
                                            <path fill="#4DAF4F" d="M16.296,69.496l17.42-19.035c-2.777-3.436-2.571-8.486,0.623-11.678c3.416-3.417,8.954-3.416,12.371,0
												c0.051,0.051,0.093,0.105,0.143,0.158c1.477-1.475,4.302-4.302,10.558-10.56c-9.8-9.801-25.694-9.801-35.496,0.001
												C10.339,40.375,16.544,61.242,0,85.793C2.048,83.744,8.749,77.044,16.296,69.496z" />
                                        </g>
                                        <path fill="#037D3F" d="M16.3,69.495l19.035-17.418c3.437,2.779,8.485,2.571,11.679-0.624c3.418-3.416,3.416-8.955,0-12.371
											c-0.052-0.051-0.108-0.092-0.159-0.142c1.476-1.477,4.301-4.302,10.558-10.559c9.802,9.8,9.803,25.695,0.001,35.497
											C45.422,75.455,24.553,69.247-0.001,85.795C2.051,83.745,8.752,77.043,16.3,69.495z" />
                                    </g>
                                    <path fill="#037D3F" d="M85.793,29.45c-2.245-6.634-6.003-12.87-11.292-18.158L60.355,25.438c3.778,3.779,6.197,8.397,7.274,13.253
										C73.371,34.223,79.614,31.116,85.793,29.45z" />
                                    <path fill="#4DAF4F" d="M56.344,0c6.634,2.245,12.871,6.004,18.159,11.292L60.355,25.438c-3.778-3.778-8.396-6.198-13.253-7.273
										C51.571,12.423,54.678,6.18,56.344,0z" />
                                </g>
                            </svg>
                            <div class="export-label-preview" [style.transform]="'rotate(' + labelRotation + 'deg)'">
                                <span><span [class.rotate-preview]="labelRotation > 90 && labelRotation <= 270">Label</span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column" fxLayout="column" *ngIf="venueDetails.styles.length > 1">
                <div class="sub-section-header" style="margin-top: 0px;">
                    <div>
                        <h3>Tile set</h3>
                    </div>
                </div>
                <div fxLayout="row">
                    <div>
                        <mat-radio-group [(ngModel)]="selectedStyle">
                            <div style="margin-top: 8px;" *ngFor="let style of venueDetails.styles">
                                <mat-radio-button (click)="setCurrentStyle()" [value]="style.folder">{{style.displayName}}</mat-radio-button>
                            </div>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
            <div class="column">
                <div fxLayout="row" class="sub-section-header" style="margin-top: 0px;">
                    <div fxFlex="<grow>">
                        <h3>Types to display</h3>
                    </div>
                    <div fxFlex class="IE-FlexAuto">
                        <button *ngIf="types" [disabled]="selectedTypes.length < 1" mat-raised-button (click)="clearTypes()">Clear selection</button>
                    </div>
                </div>
                <div fxLayout="row">
                    <div *ngIf="types">
                        <p>Select the types of locations you want to display</p>
                        <button style="margin-bottom: 10px;" [disabled]="selectedTypes.length == types.length" mat-raised-button (click)="selectAllTypes()">Select all</button>
                        <div style="padding: 10px 0px;" *ngFor="let type of types | orderBy:'administrativeId'">
                            <img class="list-image" [src]="type.displayRule.icon" />
                            <mat-checkbox [(ngModel)]="type.selected" (change)="updateTypes()">{{type.administrativeId}}</mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div fxFlex="<grow>" fxLayout="row" style="position: relative; overflow: hidden;" [class.sidebar-open]="showSettings">

        <div fxFlex="<grow>" #mapContainer id="map"></div>
        <!-- Building selector -->
        <app-building-selector *ngIf="buildings"
            class="building-selector map-overlay mat-elevation-z4"
            [buildings]="buildings | orderBy:'displayName'"
            (selectedBuildingEvent)="setBuilding($event)">
        </app-building-selector>
        <!-- Floor selector -->
        <div class="floor-selector-container">
            <div class="floor-selector mat-elevation-z4" *ngIf="floors?.length > 0 && currentFloor">
                <a [class.active]="currentFloor.floorIndex === floor.floorIndex" (click)="setFloor(floor)"
                    *ngFor="let floor of floors | orderNumBy:'floorIndex':true">{{floor.displayName}}</a>
            </div>
        </div>
        <div class="map-overlay zoom-indicator mat-elevation-z4" *ngIf="zoomLevel">Zoom: {{zoomLevel}}</div>
        <!-- Zoom Controls -->
        <div class="map-overlay zoom-controls mat-elevation-z4">
            <button (click)="zoomIn()" mat-icon-button>
                <mat-icon>add</mat-icon>
            </button>
            <br>
            <button (click)="zoomOut()" mat-icon-button>
                <mat-icon>remove</mat-icon>
            </button>
        </div>
        <div class="export-map-frame-outer" [style.transform]="'rotate(' + exportFrameRotation + 'deg)'">
            <div [style.width]="exportFrameWidth + 'px'" [style.height]="exportFrameHeight + 'px'" class="export-map-frame">
                <div class="rotation indicator" *ngIf="exportFrameHeight > 9">
                    <mat-icon style="vertical-align: middle;">explore</mat-icon>
                    {{exportFrameRotation}}
                </div>
                <div class="width indicator" *ngIf="exportFrameHeight > 9"><strong>{{exportFrameWidth * scale}}</strong> ({{exportFrameWidth}} x {{scale}})</div>
                <div class="height indicator" *ngIf="exportFrameHeight > 9"><strong>{{exportFrameHeight * scale}}</strong> ({{exportFrameHeight}} x {{scale}})</div>
            </div>
        </div>
        <div *ngIf="previewUrl && previewUrl.length > 1" class="map-overlay preview-container mat-elevation-z4">
            <h3>Preview</h3>
            <img class="preview" [src]="previewUrl" />
        </div>
        <div class="float-button-container">
            <button color="primary" (click)="downloadTile()" mat-fab>
                <mat-icon>file_download</mat-icon>
            </button>
        </div>
    </div>
</div>
