import { OAuthModuleConfig } from 'angular-oauth2-oidc';
import { environment as env } from '../../environments/environment';

// Calling a Web API with  Access Token is automated by using this
export const authModuleConfig: OAuthModuleConfig = {
    resourceServer: {
        allowedUrls: [
            env.authApi + '/api',
            env.authApi + '/cisco-webex/activation',
            env.APIEndpoint,
            env.AnalyticsEndpoint,
            env.clientApi,
            'https://maps.googleapis.com/maps/api/js/'
        ],
        sendAccessToken: true,
    }
};