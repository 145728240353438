import { BBox, Geometry } from 'geojson';

import { DisplayRule } from '../../app/locations/location.model';
import { Floor } from '../../app/buildings/floor.model';
import { ViewModelProperties } from '../ViewModelProperties/ViewModelProperties';

export enum SortKey {
    POINT = 1e6,
    LABEL = 2e6,
    POLYGON = 3e6,
    NETWORK = 4e6,
    LINESTRING = 4e6,
    MODEL2D = 5e6,
    MODEL3D = 5e6,
    WALL = 6e6,
    EXTRUSION = 6e6
}
export abstract class MapViewModelFactory<T> {
    abstract create(data: T, sortKey?: number): Promise<MapViewModel[]>;
    abstract intersectsWithBounds(data: T, bounds: BBox): boolean;
    abstract floorEquals(data: T, floor: Floor): boolean;

    /**
     * Check if the feature is visible.
     *
     * @param {DisplayRule} displayRule
     * @returns {boolean}
     * @memberof LocationMapViewModelFactory
     */
    protected isVisible(displayRule: DisplayRule): boolean {
        return displayRule.visible === true;
    }
}

export interface MapViewModel extends GeoJSON.Feature<Geometry, ViewModelProperties> { }