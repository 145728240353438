import { Injectable } from '@angular/core';
import { UnitSystem } from '../shared/enums';

@Injectable({
    providedIn: 'root'
})
export class UserAgentService {

    public unitSystem: UnitSystem;

    constructor() {
        this.unitSystem = navigator.language === 'en-US' ? UnitSystem.Imperial : UnitSystem.Metric;
    }

    /**
     * Returns the current unitSystem.
     *
     * @type {UnitSystem}
     */
    public static get UNIT_SYSTEM(): UnitSystem {
        return navigator.language === 'en-US' ? UnitSystem.Imperial : UnitSystem.Metric;
    }
}
