import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { MediaLibraryComponent } from './media-library.component';
import { MediaUploadComponent } from './media-upload/media-upload.component';
import { MediaFilterBarComponent } from './media-filter-bar/media-filter-bar.component';
import { MediaItemsListComponent } from './media-items-list/media-items-list.component';
import { MediaSyncComponent } from './media-sync/media-sync.component';
import { MediaSyncService } from './media-sync/media-sync.service';
import { MediaLibraryService } from './media-library.service';
import { FormatDatePipe } from './media-items-filters/format-date.pipe';
import { MediaItemComponent } from './media-item/media-item.component';
import { MediaUpload3DComponent } from './media-upload-3d/media-upload-3d.component';
import { MediaErrorComponent } from './media-error/media-error.component';

@NgModule({
    declarations: [
        MediaLibraryComponent,
        MediaUploadComponent,
        MediaUpload3DComponent,
        MediaFilterBarComponent,
        MediaItemsListComponent,
        MediaItemComponent,
        MediaSyncComponent,
        MediaErrorComponent,
        FormatDatePipe
    ],
    imports: [
        SharedModule
    ],
    exports: [
        MediaLibraryComponent
    ],
    providers: [
        MediaLibraryService,
        MediaSyncService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MediaLibraryModule {}