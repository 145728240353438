<div class="grid-container">
    <app-header
        [userImageUrl]="userImage"
        [menus]="menuData"
        [solutions]="activeSolutions"
        [venues]="venues"
        [appLink]="appLink"
        (solutionChangedEvent)="setSolution($event)"
        (newsEvent)="openNewsDialog()"
        *ngIf="isAuthenticated">
    </app-header>


    <div class="content-container" *ngIf="isAuthenticated">
        <div class="content-container__progress-bar">
            <mat-progress-bar mode="indeterminate" color="accent" *ngIf="mainPageLoad"></mat-progress-bar>
        </div>
        <router-outlet></router-outlet>
    </div>

</div>
<ngx-spinner type="ball-pulse" bdColor="rgba(51, 51, 51, 0.3)"></ngx-spinner>

<mi-notification #notification position="bottom-center"></mi-notification>
