<panel-header (cancelEvent)="close()" header="Location Details" class="panel__header">
    <button type="button"
        [disabled]="!location.id"
        class="button button--display-rule-editor"
        (click)="openDisplayRuleDetails(location)"
        title="{{location.id ? 'Open the Display Rule editor' : 'Location must be saved to edit Display Rules'}}">
    </button>
    <button type="button"
        class="button button--add-door"
        (click)="addDoor(location)"
        *ngIf="isAddDoorVisible"
        title="Add a door to the room">
    </button>
    <ng-container *ngIf="isLocationSplitCombinable">
        <button type="button"
            (click)="split()"
            class="button button--split"
            title="Split the room">
        </button>
        <button type="button"
            class="button button--combine"
            (click)="combine()"
            title="Combine rooms">
        </button>
    </ng-container>
    <button type="button"
        class="button button--duplicate"
        id="duplicate"
        *ngIf="isLocationDuplicatable"
        (click)="duplicateLocation(location)"
        title="Duplicate the POI or Area">
    </button>
    <button type="button"
        class="button button--delete"
        *ngIf="isLocationDeletable"
        (click)="deleteLocation.emit()"
        title="Delete the POI or Area">
    </button>
</panel-header>

<form [formGroup]="locationForm" class="form" (ngSubmit)="onSubmitForm()" (window:keydown)="handleHotkeys($event)">
    <navigatable-container>
        <navigatable-section header="General">
            <section class="section">
                <location-type-dropdown formControlName="type"></location-type-dropdown>
                <location-basic-info formControlName="translations"></location-basic-info>
                <location-categories formControlName="categories"></location-categories>
                <section class="section__row">
                    <label class="mi-label">
                        <span class="documentation-link__wrapper">
                            External ID
                            <a class="documentation-link" href="https://docs.mapsindoors.com/content/various/external-id" target="_blank">
                                Help
                            </a>
                        </span>
                        <input class="mi-input" type="text" formControlName="externalId" placeholder="1.234-abc" />
                    </label>
                </section>
            </section>
        </navigatable-section>

        <navigatable-section
            header="Area"
            *ngIf="location.locationType === 'area'">
            <section class="section section--area">
                <label>Rotation (angle)</label>
                <div class="section__row">
                    <label class="mi-label mi-label--inline">
                        <input class="mi-input" type="range" formControlName="angle" [value]="locationForm.get('angle').value" min="0" max="359" step="1">
                    </label>
                    <label class="mi-label">
                        <input class="mi-input" type="number" formControlName="angle" [value]="locationForm.get('angle').value" min="0" max="359">
                        <small role="alert" class="mi-form__error"
                            *ngIf="locationForm.get('angle').hasError('min') || locationForm.get('angle').hasError('max')">
                            Enter a number between 0 and 359
                        </small>
                        <small role="alert" class="mi-form__error" *ngIf="locationForm.get('angle').hasError('pattern')">
                            Enter a whole number
                        </small>
                    </label>
                </div>
            </section>
        </navigatable-section>

        <navigatable-section header="Search" documentationLink="https://docs.mapsindoors.com/content/cms/#location">
            <section class="section">
                <toggle-searchable formControlName="status"></toggle-searchable>
                <location-aliases formControlName="aliases"></location-aliases>
            </section>
        </navigatable-section>

        <navigatable-section header="Restrictions" documentationLink="https://docs.mapsindoors.com/content/map/displaying-objects/app-user-roles/#features-affected-by-app-user-roles">
            <section class="section">
                <user-roles-restrictions-selector
                    formControlName="restrictions"
                    isInheritanceControlVisible="true">
                </user-roles-restrictions-selector>
            </section>
        </navigatable-section>

        <navigatable-section header="Visibility" documentationLink="https://docs.mapsindoors.com/content/cms/#editing-data">
            <section class="section">
                <div class="section__row">
                    <label class="mi-label">Active from
                        <input class="mi-input" type="date" formControlName="activeFrom" [min]="MIN_DATE" [max]="MAX_DATE" />
                        <small role="alert" class="mi-form__error" *ngIf="locationForm.get('activeFrom').hasError('minError')">
                            {{locationForm.get('activeFrom').errors.minError}}
                        </small>
                        <small role="alert" class="mi-form__error" *ngIf="locationForm.get('activeFrom').hasError('maxError')">
                            {{locationForm.get('activeFrom').errors.maxError}}
                        </small>
                    </label>
                    <label class="mi-label">Active to
                        <input class="mi-input" type="date" formControlName="activeTo" [min]="MIN_DATE" [max]="MAX_DATE" />
                        <small role="alert" class="mi-form__error" *ngIf="locationForm.get('activeTo').hasError('minError')">
                            {{locationForm.get('activeTo').errors.minError}}
                        </small>
                        <small role="alert" class="mi-form__error" *ngIf="locationForm.get('activeTo').hasError('maxError')">
                            {{locationForm.get('activeTo').errors.maxError}}
                        </small>
                    </label>
                </div>
            </section>
        </navigatable-section>

        <navigatable-section header="Image">
            <section class="section">
                <mi-tabs>
                    <mi-tab tab-for="image" label="Image"></mi-tab>
                    <mi-tab tab-for="google-street-view" label="Google Street View"></mi-tab>
                    <mi-tab-panel id="image">
                        <image-upload #imageUploadComponent
                            [imageSrc]="location.imageURL"
                            [reset]="discardChangesSubject.asObservable()"
                            (imageUrlChanged)="onImageUrlChange($event)">
                        </image-upload>
                    </mi-tab-panel>
                    <mi-tab-panel id="google-street-view">
                        <image-street-view [streetViewConfig]="location.streetViewConfig"
                            [reset]="discardChangesSubject.asObservable()"
                            [location]="location"
                            (updatePanorama)="onUpdatePanorama($event)"
                            (snackBarMessage)="showInfoNotification($event)">
                        </image-street-view>
                    </mi-tab-panel>
                </mi-tabs>
            </section>
        </navigatable-section>

        <navigatable-section header="Custom Properties" documentationLink="https://docs.mapsindoors.com/content/data/customisable-data/custom-properties/">
            <section class="section">
                <location-custom-properties formControlName="customProperties" [typeName]="typeName"></location-custom-properties>
            </section>
        </navigatable-section>

        <navigatable-section header="Details">
            <section class="section">
                <div class="section__row">
                    <label class="mi-label">Building
                        <mi-dropdown #buildingsDropdown filterable (change)="onBuildingsDropdownChange($event)">
                        </mi-dropdown>
                    </label>
                    <label class="mi-label">Floor
                        <mi-dropdown class="right-align" #floorsDropdown filterable (change)="onFloorsDropdownChange($event)">
                        </mi-dropdown>
                    </label>
                </div>

                <div class="mi-label">
                    MapsIndoors Location ID:
                    <code>{{location.id}}</code>
                </div>

                <div class="section__row">
                    <ng-container *ngIf="!location?.anchor && location?.locationType.toLowerCase() === 'area'">
                        <div class="mi-label">Save Area to see latitude and longitude for the anchor point.</div>
                    </ng-container>
                    <ng-container *ngIf="location?.anchor">
                        <div class="mi-label">
                            Latitude
                            <code>{{location.anchor?.coordinates[1]}}</code>
                        </div>
                        <div class="mi-label">
                            Longitude
                            <code>{{location.anchor?.coordinates[0]}}</code>
                        </div>
                    </ng-container>
                </div>
            </section>
        </navigatable-section>
    </navigatable-container>

    <panel-footer *ngIf="locationForm.dirty" text="You have unsaved changes.">
        <button type="button" class="mi-button mi-button--link" (click)="onDiscard()">Discard</button>
        <button type="submit" class="mi-button mi-button--primary" [disabled]="!locationForm.valid">Save and close</button>
    </panel-footer>
</form>