import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { concatMap, finalize } from 'rxjs/operators';

import { ImportService } from '../../services/import.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { Venue } from '../../venues/venue.model';
import { VenueService } from '../../venues/venue.service';

@Component({
    selector: 'app-graph-list',
    templateUrl: './graph-list.component.html',
    styleUrls: ['./graph-list.component.scss']
})
export class GraphListComponent implements OnInit, OnDestroy {
    @Input() graphIds: string[] = [];
    @Output() graphListClose = new EventEmitter<void>();
    @Output() activeGraphChange = new EventEmitter<string>();

    public venue!: Venue;

    private subscriptions = new Subscription();

    constructor(
        private venueService: VenueService,
        private spinner: NgxSpinnerService,
        private importService: ImportService,
        private notificationService: NotificationService) { }

    ngOnInit(): void {
        this.subscriptions.add(this.venueService.getSelectedVenue()
            .subscribe(venue => this.venue = venue));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Emit close event.
     *
     * @memberof GraphListComponent
     */
    public emitCloseEvent(): void {
        this.graphListClose.emit();
    }

    /**
     * Set the venue graph.
     *
     * @param {string} graphId
     * @memberof GraphListComponent
     */
    public setVenueGraph(graphId: string): void {
        this.venue.graphId = graphId;
        this.spinner.show();
        this.venueService.updateVenue(this.venue)
            .pipe(finalize(() => this.spinner.hide()))
            .subscribe(
                () => {
                    this.notificationService.showSuccess('Venue updated');
                    this.venueService.setVenueEvent('updated');
                    this.emitCloseEvent();
                }, err => this.notificationService.showError(err)
            );
    }

    /**
     * Delete graph.
     *
     * @param {string} graphId
     * @memberof GraphListComponent
     */
    public deleteGraph(graphId: string): void {
        if (confirm(`Would you like to delete ${graphId}?`)) {
            this.spinner.show();
            this.importService.deleteGraph(graphId)
                .pipe(
                    concatMap(() => this.importService.getGraphIds()),
                    finalize(() => this.spinner.hide()),
                ).subscribe(graphIds => this.graphIds = graphIds);
        }
    }
}
