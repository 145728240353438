import { Component, ElementRef, Input, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
    selector: 'chips-list',
    templateUrl: './chips-list.component.html',
    styleUrls: ['./chips-list.component.scss']
})
export class ChipsListComponent implements AfterViewInit, OnDestroy {
    @ViewChild('chipsList') chipsListElement: ElementRef<HTMLDivElement>;

    @Input() set dropdownElements(dropdownElements: HTMLMiDropdownElement[]) {
        this._dropdownElements = dropdownElements;

        dropdownElements.forEach(element => {
            element.addEventListener('change', ({ detail: selectedDropdownItems }: CustomEvent) => {
                this.updateChipsList(selectedDropdownItems);
            });
        });
    }

    private _dropdownElements: HTMLMiDropdownElement[] = [];
    private chipsListMutationObserver: MutationObserver;
    public chipsListExpanded = false;
    public isListExpandable = false;
    public activeFilters: HTMLMiDropdownItemElement[] = [];

    constructor() { }

    ngAfterViewInit(): void {
        this.updateChipsList(this.getSelectedDropdownItems(this._dropdownElements));
        this.addChipsListMutationObserver();
    }

    ngOnDestroy(): void {
        this.chipsListMutationObserver.disconnect();
    }

    /**
     * Get selected filters from dropdown elements.
     *
     * @private
     * @param {HTMLMiDropdownElement[]} dropdownElements
     * @returns {HTMLMiDropdownItemElement[]}
     * @memberof ChipsListComponent
     */
    private getSelectedDropdownItems(dropdownElements: HTMLMiDropdownElement[]): HTMLMiDropdownItemElement[] {
        return [].concat(...dropdownElements.map(dropdownElement => dropdownElement.items.filter(item => item.selected)));
    }

    /**
     * Add mutation observer to watch for child list changes.
     * On change see if the chips list element contains more than a single row of chips.
     *
     * @private
     * @memberof ChipsListComponent
     */
    private addChipsListMutationObserver(): void {
        const chipsListElement = this.chipsListElement.nativeElement;
        this.chipsListMutationObserver = new MutationObserver(() => {
            const rowHeight = 35;
            this.isListExpandable = chipsListElement.scrollHeight > rowHeight;
        });
        this.chipsListMutationObserver.observe(chipsListElement, { childList: true });
    }

    /**
     * Trigger change event programmatically for each dropdown element.
     *
     * @private
     * @memberof ChipsListComponent
     */
    private dispatchChangeEvents(): void {
        this._dropdownElements.forEach(element => {
            const event = new CustomEvent('change', { detail: [...element.items.filter(item => item.selected)] });
            element.dispatchEvent(event);
        });
    }

    /**
     * Update chips list to reflect selected filters.
     *
     * @private
     * @param {HTMLMiDropdownElement[]} dropdownElements
     * @memberof ChipsListComponent
     */
    private updateChipsList(selectedDropdownItems: HTMLMiDropdownItemElement[]): void {
        // Add selected filters to chips list
        for (const dropdownItem of selectedDropdownItems as HTMLMiDropdownItemElement[]) {
            // Continue if already present
            if (this.activeFilters.find(element => element.value === dropdownItem.value)) {
                continue;
            }

            this.activeFilters.unshift(dropdownItem);
        }

        // Loop trough all dropdown elements to remove filters from chips list that are not selected anymore.
        const selectedFilterValues = this.getSelectedDropdownItems(this._dropdownElements).map(item => item.value); // The selectedDropDownItems parameter does not necessarily contain the full list of selected dropdown items.
        this.activeFilters = this.activeFilters.filter(item => selectedFilterValues.includes(item.value));
    }

    /**
     * Remove chip and deselect filter in dropdown.
     *
     * @param {string} value
     * @param {number} index
     * @memberof ChipsListComponent
     */
    public onRemoveChip(value: string): void {
        // Deselect filter in dropdown menu
        this.getSelectedDropdownItems(this._dropdownElements).find(item => item.value === value).selected = false;
        this.dispatchChangeEvents();
    }

    /**
     * Clear all chips and disable filters.
     *
     * @memberof ChipsListComponent
     */
    public clearAllChips(): void {
        this.activeFilters = [];

        this.getSelectedDropdownItems(this._dropdownElements).forEach(element => element.selected = false);
        this.dispatchChangeEvents();
    }
}
