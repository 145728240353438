import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SolutionService } from '../services/solution.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

export interface ApiKey {
    active: boolean;
    name: string;
    solutionId?: string;
    id?: string;
    lastModified?: string;
    lastModifiedBy?: string;
    createdAt?: string;
    createdBy?: string;
}

@Injectable()
export class KeyService {
    private api = environment.APIEndpoint;

    constructor(
        private http: HttpClient,
        private solutionService: SolutionService
    ) { }

    public getKeys(): Observable<any> {
        const keyUrl = this.getKeyURL();
        return this.http.get(keyUrl);
    }

    /**
     * Creates an Api Key.
     *
     * @param {ApiKey} key
     * @returns {Observable<string>}
     * @memberof KeyService
     */
    public newKey(key: ApiKey): Observable<string> {
        const keyUrl = this.getKeyURL();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post<string>(keyUrl, key, { headers: headers });
    }

    public updatekey(key): Observable<any> {
        const keyUrl = this.getKeyURL();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.put(keyUrl, key, { headers: headers });
    }

    public deleteKey(keyId): Observable<any> {
        const keyUrl = this.getKeyURL();

        return this.http.delete(keyUrl + keyId);
    }

    private getKeyURL(): string {
        const solution = this.solutionService.getStaticSolution();
        const url = this.api + solution.id + '/api/apikey/';
        return url;
    }
}
