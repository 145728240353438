<div>
    <form [formGroup]="aliasForm" (ngSubmit)="onSubmit()" fxLayout="row" class="IE-FlexAuto">
        <mat-form-field fxFlex="<grow>">
            <input matInput #aliasInput formControlName="alias" type="text" placeholder="Search aliases (enter to add)" />
            <mat-error *ngIf="aliasForm.get('alias').errors?.valueAlreadyExists">
                Entered Alias is already added.
            </mat-error>
        </mat-form-field>
        <div fxFlex class="IE-FlexAuto">
            <button type="submit" mat-raised-button [disabled]="!aliasForm.valid || aliasInput.value.length === 0">
                Add alias
            </button>
        </div>
    </form>

    <mat-chip-list class="select-chips dark-text" aria-label="Alias">
        <mat-chip *ngFor="let alias of aliasList; let index = index" [removable]="true" (removed)="removeAlias(index)">
            {{alias}}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
    </mat-chip-list>
</div>