import { formatDate } from '@angular/common';

/**
 * Formats a date to be displayed as 'yyyy-MM-dd'.
 *
 * @param {(string | number | Date)} date
 * @returns {string}
 */
export function formatToShortDate(date: string | number | Date): string {
    return date ? formatDate(date, 'yyyy-MM-dd', 'en') : '';
}

/**
 * Gets a date that is a number of days before the specified date.
 *
 * @param {Date} date
 * @param {number} [numberOfDaysBefore=1]
 * @returns {Date}
 */
export function getDateBefore(date: Date, numberOfDaysBefore = 1): Date {
    date?.setDate(date?.getDate() - numberOfDaysBefore);
    return date;
}

/**
 * Determines whether the given date is valid.
 *
 * @param {string} date
 * @returns {boolean}
 */
export function isDateValid(date: string): boolean {
    // Convert to a date object
    const dateObject = new Date(date);
    // Considering the Date Object does not tell us “2017–02–30” is an incorrect date,
    // so we need to check if the date it says is the same as the one we said.
    const newDateObject = new Date(dateObject.getFullYear(), (dateObject.getMonth()-1), dateObject.getDate());
    return (Boolean(+newDateObject) && newDateObject.getDate() === dateObject.getDate());
}