import { lowerCaseFirstLetter } from '../../../utilities/string';
import { DisplayRule } from '../../locations/location.model';
import { ConnectorType, DoorType, EntryPointTypes, RouteAccessModifier, RouteElement, RouteElementType } from '../../map/route-element-details/route-element.model';
import midt from '@mapsindoors/midt/tokens/tailwind-colors.json';

export class RouteElementDisplayRuleFactory {
    /**
     * Creates a DisplayRule for the given RouteElement.
     *
     * @private
     * @param {RouteElement} routeElement
     * @returns {DisplayRule}
     * @memberof RouteElementDisplayRuleFactory
     */
    static create(routeElement: RouteElement): DisplayRule {
        return {
            visible: true,
            icon: `assets/images/routelayer/${getIconNameForRouteElement(routeElement)}`,
            imageSize: {
                width: 24,
                height: 24
            },
            polygon: {
                fillColor: midt['tailwind-colors'].red[500].value,
                fillOpacity: 0.2,
                strokeOpacity: 0
            },
            zoomFrom: 0,
            zoomTo: Infinity
        } as DisplayRule;
    }
}

/**
 * Get a icon name for the given RouteElement.
 *
 * @private
 * @param {RouteElement} routeElement
 * @returns {string}
 */
function getIconNameForRouteElement(routeElement: RouteElement): string {
    let iconName = '';
    const type = routeElement.type;
    const subType = routeElement.subtype;
    const accessModifier = getRouteAccessModifier(routeElement);

    switch (type) {
        case RouteElementType.Connector:
            iconName += `floorConnector.${lowerCaseFirstLetter(ConnectorType[subType])}`;
            break;
        case RouteElementType.Door:
            iconName += `door.${lowerCaseFirstLetter(DoorType[subType])}`;
            break;
        case RouteElementType.EntryPoint:
            iconName += `entryPoint.${lowerCaseFirstLetter(EntryPointTypes[subType] ?? EntryPointTypes[EntryPointTypes.AnyEntry])}`;
            break;
        default:
            iconName += 'barrier.routeModifier';
            break;
    }

    switch (accessModifier) {
        case RouteAccessModifier.Restricted:
            iconName += '.restricted';
            break;
        case RouteAccessModifier.Closed:
            iconName += '.closed';
            break;
    }

    return iconName += '.png';
}


/**
 * Get the access modifier for the given RouteElement.
 *
 * @private
 * @param {RouteElement} routeElement
 * @returns {RouteAccessModifier}
 */
function getRouteAccessModifier(routeElement: RouteElement): RouteAccessModifier {
    const restrictions = routeElement.restrictions ?? [];
    if (restrictions.length === 0) {
        return RouteAccessModifier.Open;
    } else if (restrictions.includes('locked')) {
        return RouteAccessModifier.Closed;
    } else {
        return RouteAccessModifier.Restricted;
    }
}
