import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerService } from '../customers/customer.service';
import { Customer } from '../customers/customer.model';

@Injectable()
export class AnalyticsDashboardService {
    private customerId: string;
    private endpoint: string = environment.AnalyticsEndpoint;

    constructor(private http: HttpClient, private customerService: CustomerService) {
    }

    private odata(path, query): Promise<any> {
        return new Promise((resolve, reject) => {
            let queryString = Object.keys(query).map(key => {
                return `${key}=${query[key]}`;
            }).join('&');
            queryString = queryString > '' ? `?${decodeURIComponent(queryString)}` : '';

            this.http.get(`${this.endpoint}/${path}${queryString}`)
                .subscribe(
                    (data: any) => resolve(data.value || data),
                    (error: Error) => reject(error)
                );
        });
    }

    /**
     * Returns a string used for filtering odata by customerId.
     *
     * @private
     * @returns {string}
     * @memberof AnalyticsDashboardService
     */
    private getCustomerFilter(): string {
        const customer = this.customerService.getCurrentCustomer(true) as Customer;
        this.customerId = customer ? customer.id : localStorage['customerId'] as string;
        return this.customerId > '' ? `Customer/InternalId eq '${this.customerId}'` : null;
    }

    /**
     * Returns a Promise that resolves with the current customer's total number of venues.
     *
     * @returns {Promise<number> }
     * @memberof AnalyticsDashboardService
     */
    public getVenuesCount(): Promise<number> {
        const customerFilter = this.getCustomerFilter();
        if (customerFilter) {
            return this.odata('Venues/$count', { $filter: customerFilter });
        } else {
            return Promise.reject();
        }
    }

    /**
     * Returns a Promise that resolves with the current customer's total number of buildings.
     *
     * @returns {Promise<number>}
     * @memberof AnalyticsDashboardService
     */
    public getBuildingsCount(): Promise<Object> {
        const customerFilter = this.getCustomerFilter();
        if (customerFilter) {
            return this.odata('Buildings/$count', { $filter: customerFilter });
        } else {
            return Promise.reject();
        }
    }

    /**
     * Returns a Promise that resolves with the current customer's total number of floors.
     *
     * @returns {Promise<number>}
     * @memberof AnalyticsDashboardService
     */
    public getFloorsCount(): Promise<Object> {
        const customerFilter = this.getCustomerFilter();
        if (customerFilter) {
            return this.odata('Floors/$count', { $filter: customerFilter });
        } else {
            return Promise.reject();
        }
    }

    /**
     * Returns a Promise that resolves with the current customer's total number of rooms.
     *
     * @returns {Promise<number>}
     * @memberof AnalyticsDashboardService
     */
    public getRoomsCount(): Promise<Object> {
        const customerFilter = this.getCustomerFilter();
        if (customerFilter) {
            return this.odata('Rooms/$count', { $filter: customerFilter });
        } else {
            return Promise.reject();
        }
    }

    /**
     * Returns a Promise that resolves with the current customer's total number of rooms with one or more categories.
     *
     * @returns {Promise<number>}
     * @memberof AnalyticsDashboardService
     */
    public getRoomsWithCategoryCount(): Promise<number> {
        const customerFilter = this.getCustomerFilter();
        if (customerFilter) {
            return this.odata('Rooms/$count', { $filter: `${customerFilter} and Categories/any()` });
        } else {
            return Promise.reject();
        }
    }

    /**
     * Returns a Promise that resolves with the current customer's total number of rooms with one or more aliases.
     *
     * @returns {Promise<number>}
     * @memberof AnalyticsDashboardService
     */
    public getRoomsWithAliasCount(): Promise<number> {
        const customerFilter = this.getCustomerFilter();
        if (customerFilter) {
            return this.odata('Rooms/$count', { $filter: `${customerFilter} and Aliases/any()` });
        } else {
            return Promise.reject();
        }
    }

    /**
     * Returns a Promise that resolves with the current customer's total number of unique location types.
     *
     * @returns {Promise<number>}
     * @memberof AnalyticsDashboardService
     */
    public getUniqueLocationTypesCount(): Promise<Object> {
        const customerFilter = this.getCustomerFilter();
        if (customerFilter) {
            return this.odata('LocationTypes/$count',
                {
                    $filter: customerFilter,
                    $apply: 'groupby((AdministrativeId, Customer/InternalId))'
                });
        } else {
            return Promise.reject();
        }
    }

    /**
     * Returns a Promise that resolves with the current customer's total number of unique categories.
     *
     * @returns {Promise<number>}
     * @memberof AnalyticsDashboardService
     */
    public getUniqueCategoriesCount(): Promise<Object> {
        const customerFilter = this.getCustomerFilter();
        if (customerFilter) {
            return this.odata('Categories/$count',
                {
                    $filter: customerFilter,
                    $apply: 'groupby((AdministrativeId, Customer/InternalId))'
                });
        } else {
            return Promise.reject();
        }
    }

    /**
     * Returns a Promise that resolves with the current customer's total number of POI's with one or more categories.
     *
     * @returns {Promise<number>}
     * @memberof AnalyticsDashboardService
     */
    public getPOIsWithCategoryCount(): Promise<number> {
        const customerFilter = this.getCustomerFilter();
        if (customerFilter) {
            return this.odata('POIs/$count', { $filter: `${customerFilter} and Categories/any()` });
        } else {
            return Promise.reject();
        }
    }

    /**
     * Returns a Promise that resolves with the current customer's total number of POI's and rooms with one or more aliases.
     *
     * @returns {Promise<number>}
     * @memberof AnalyticsDashboardService
     */
    public getPOIsWithAliasCount(): Promise<number> {
        const customerFilter = this.getCustomerFilter();
        if (customerFilter) {
            return this.odata('POIs/$count', { $filter: `${customerFilter} and Aliases/any()` });
        } else {
            return Promise.reject();
        }
    }


    /**
     * Returns a Promise that resolves with a list of all floors for the current customer.
     *
     * @returns {Promise<any>}
     * @memberof AnalyticsDashboardService
     */
    public getFloors(): Promise<any> {
        const customerFilter = this.getCustomerFilter();
        if (customerFilter) {
            return this.odata('FloorInfos',
                {
                    $filter: customerFilter,
                    $orderby: 'BuildingName,Level'
                });
        } else {
            return Promise.reject();
        }
    }

    /**
     * Returns a Promise that resolves with a list of all types for the current customer.
     *
     * @returns {Promise<any>}
     * @memberof AnalyticsDashboardService
     */
    public getLocationTypes(): Promise<any> {
        const customerFilter = this.getCustomerFilter();
        if (customerFilter) {
            return this.odata('LocationTypeInfos',
                {
                    $filter: customerFilter,
                    $select: 'InternalId,SolutionName,BuildingName,BuildingId,Address,FloorName,Level,TypeName,POIsOfType,RoomsOfType',
                    $orderby: 'BuildingName,Level'
                });
        } else {
            return Promise.reject();
        }
    }

    /**
     * Returns a Promise that resolves with a list of all categories for the current customer.
     *
     * @returns {Promise<any>}
     * @memberof AnalyticsDashboardService
     */
    public getCategories(): Promise<any> {
        const customerFilter = this.getCustomerFilter();
        if (customerFilter) {
            return this.odata('CategoryInfos',
                {
                    $filter: customerFilter,
                    $select: 'InternalId,SolutionName,BuildingName,BuildingId,Address,FloorName,Level,CategoryName,POIsOfCategories,RoomsOfCategories',
                    $orderby: 'BuildingName,Level'
                });
        } else {
            return Promise.reject();
        }
    }
}

