import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { LocationAliasesComponent } from './location-aliases/location-aliases.component';
import { LocationBasicInfoComponent } from './location-basic-info/location-basic-info.component';
import { LocationCategoriesComponent } from './location-categories/location-categories.component';
import { LocationCustomPropertiesComponent } from './location-custom-properties/location-custom-properties.component';
import { LocationDetailsComponent } from './location-details/location-details.component';
import { LocationRotationComponent } from './location-rotation/location-rotation.component';
import { LocationTypeDropdownComponent } from './location-type-dropdown/location-type-dropdown.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        LocationAliasesComponent,
        LocationBasicInfoComponent,
        LocationCategoriesComponent,
        LocationCustomPropertiesComponent,
        LocationDetailsComponent,
        LocationRotationComponent,
        LocationTypeDropdownComponent,
    ],
    imports: [
        SharedModule
    ],
    exports: [
        LocationAliasesComponent,
        LocationBasicInfoComponent,
        LocationCategoriesComponent,
        LocationCustomPropertiesComponent,
        LocationDetailsComponent,
        LocationRotationComponent,
        LocationTypeDropdownComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LocationsModule { }
