<div style="height: 100%; overflow: hidden;">
	<mat-toolbar class="mat-elevation-z2 page-toolbar">
		<div fxFlex class="IE-FlexAuto">
			<button class="ga-config-save" color="primary" (click)="saveConfig()" style="margin-right: 0px;"
				mat-raised-button>Save configuration</button>
		</div>
		<div fxFlex="<grow>"></div>
	</mat-toolbar>
	<mat-progress-bar color="accent" mode="indeterminate" *ngIf="isProgressBarVisible"></mat-progress-bar>
	<div class="page-content" [hidden]="!appConfig" style="overflow: auto; max-height: 100%;">

			<div class="column" fxLayout="column" fxLayoutGap="16px" fxFlexFill>
				<!-- App Title -->
				<mat-card *ngIf="appConfig">
					<mat-card-header>
						<mat-card-title>App Title</mat-card-title>
					</mat-card-header>
					<mat-card-content>
						<mat-form-field>
							<input [(ngModel)]="appConfig.appSettings.title" matInput placeholder="App Title">
						</mat-form-field>
					</mat-card-content>
				</mat-card>

				<!-- Alias (prev. App ID) -->
				<mat-card *ngIf="appConfig">
					<mat-card-header>
						<mat-card-title>Alias</mat-card-title>
					</mat-card-header>
					<mat-card-content>
						<p>Instead of only using an API key in the MapsIndoors standard apps to load your data, you can set an Alias that is easier to remember.</p>
                        <p>Do not set an Alias if you want to make it more difficult to find and load your MapsIndoors data in a standard app. In that case, you can only load the data with an API key.</p>
                        <h3>Enter an Alias</h3>
						<p [class.error]="!appIDError"><i>Accepted input: a-z, 0-9, no spaces and 1-23 characters length</i></p>
						<mat-form-field style="width: 200px;">
							<input maxlength="23" (blur)="lowerCaseAppId()" [(ngModel)]="appConfig.appId" matInput placeholder="Alias">
						</mat-form-field>
						<div *ngIf="currentAppId">
							<h4>Current Alias</h4>
                            <br>
							<a [href]="'https://clients.mapsindoors.com/' + currentAppId" target="_blank">https://clients.mapsindoors.com/{{currentAppId}}</a>
						</div>
					</mat-card-content>
				</mat-card>

				<!-- App User Role -->
				<app-user-roles (progressBarVisible)="isProgressBarVisible = $event"></app-user-roles> <!--  -->

				<!-- App Categories -->
				<div fxLayout="column" style="padding-bottom: 96px;" *ngIf="appConfig && categories && categories.length > 0">
					<mat-card>
						<mat-card-header>
							<mat-card-title>App Categories</mat-card-title>
							<mat-card-subtitle>Select which categories can be used for browsing locations in the app.
							</mat-card-subtitle>
						</mat-card-header>
						<mat-card-content>
								<table class="std-table mat-elevation-z4">
                                    <tr class="sticky">
                                        <th>Up</th>
                                        <th>Down</th>
                                        <th>Category</th>
                                        <th>Show in App</th>
                                        <th>Menu Icon</th>
                                        <th></th>
                                    </tr>
									<tr *ngFor="let category of displayCategories; let i = index">
										<td>
											<button *ngIf="i > 0" (click)="moveUp(category)" type="button" mat-icon-button>
												<mat-icon>keyboard_arrow_up</mat-icon>
											</button>
										</td>
										<td>
											<button *ngIf="i < (displayCategories.length-1)" (click)="moveDown(category)" type="button"
												mat-icon-button>
												<mat-icon>keyboard_arrow_down</mat-icon>
											</button>
										</td>
										<td>{{category.displayName}}</td>
										<td>
											<mat-checkbox [checked]="true" (click)="setShowInApp(category, false)"></mat-checkbox>
										</td>
										<td>
											<img *ngIf="category.iconUrl" style="min-width: 32px; margin-left: 16px;" class="list-icon"
												src="{{category.iconUrl}}">
										</td>
										<td>
											<button *ngIf="category.iconUrl" style="background-color: #fff;"
												(click)="editMenuIcon(category)" mat-stroked-button color="primary">Set icon</button>
											<button *ngIf="!category.iconUrl" (click)="editMenuIcon(category)" mat-raised-button
												color="primary">Set icon *</button>
										</td>
									</tr>
									<tr *ngFor="let category of inactiveCategories; let i = index">
										<td></td>
										<td></td>
										<td>{{category.displayName}}</td>
										<td>
											<mat-checkbox (click)="setShowInApp(category, true)"></mat-checkbox>
										</td>
										<td>
											<img *ngIf="category.iconUrl" style="min-width: 32px; margin-left: 16px;" class="list-icon"
												src="{{category.iconUrl}}">
										</td>
                                        <td></td>
									</tr>
								</table>
						</mat-card-content>
					</mat-card>
				</div>
			</div>

	</div>
</div>
