import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'lock-button',
    templateUrl: './lock-button.component.html',
    styleUrls: ['./lock-button.component.scss']
})
export class LockButtonComponent {
    @Input() locked = true;
    @Output() toggled = new EventEmitter<MouseEvent>();
}
