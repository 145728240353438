import { NgModule } from '@angular/core';

import { AppUserRolesComponent } from './config/app-user-roles/app-user-roles.component';
import { ConfigComponent } from './config/config.component';
import { AppUserRoleDialogComponent } from './config/app-user-roles/app-user-role-dialog/app-user-role-dialog.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
    declarations: [
        AppUserRolesComponent,
        AppUserRoleDialogComponent,
        ConfigComponent
    ],
    imports: [
        SharedModule
    ],
})
export class AppSettingsModule {}
