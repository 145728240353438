<div class="add-door">
    <label *ngIf="hasDoorPoint === true" class="add-door__input-label">
        Door width (in {{ unitSystem === 'imperial' ? 'inches' : 'cm' }}):
            <input
            class="mi-input add-door__input-field"
            min="0"
            maxlength="8"
            [max]="maxDoorWidthInputValue"
            type="number"
            [(ngModel)]="doorWidthInputValue"
            (input)="updateDoorWidth($event)"
        />
    </label>

    <span *ngIf="doorWidthInputValue > maxDoorWidthInputValue" [title]="'The entered width cannot be larger than ' + maxDoorWidthInputValue">
        ⚠️
    </span>

    <span class="divider"></span>

    <button type="button" class="mi-button mi-button--small mi-button--base"
        (click)="cancelOperation()">
        Cancel
    </button>

    <button
        *ngIf="hasDoorPoint === true"
        [disabled]="doorWidthInputValue > maxDoorWidthInputValue"
        type="button"
        class="mi-button mi-button--small mi-button--primary add-door__submit"
        (click)="createAddedDoor()">
        Done
    </button>
</div>