import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class MediaSyncService {
    private api: string = environment.APIEndpoint;

    constructor(
        private http: HttpClient
    ) {}

    /**
     * Sends POST request to the backend which is syncing a media item to a given solution.
     *
     * @param {string} mediaId
     * @param {string} sourceSolutionId
     * @param {string[]} targetSolutionIds
     * @returns {Observable}
     */
    public syncMedia(mediaId: string, sourceSolutionId: string, targetSolutionIds: string[]): Observable<any> {
        return this.http.post(`${this.api}${sourceSolutionId}/api/media/solutionSync`, targetSolutionIds, {params: {mediaId}});
    }
}
