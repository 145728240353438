import { OperatorFunction, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterOptions } from '../map/filters-bar/filter-options.model';
import { Location } from '../locations/location.model';
import { Building, OUTDOOR_BUILDING } from '../buildings/building.model';
import { Floor } from '../buildings/floor.model';
import { LocationType } from '../location-types/location-type.model';
import { Category } from '../categories/category.model';
import { AppUserRole } from '../app-settings/config/app-user-roles/app-user-role.model';
import { ExtendedLocation } from '../locations/location.service';

/**
 * Transformation operator to filter locations.
 *
 * @param {FilterOptions} filterOptions
 * @returns {OperatorFunction<Location[], Location[]>}
 */
export function filterLocations(filterOptions: FilterOptions): OperatorFunction<Location[], Location[]> {
    filterOptions = filterOptions ?? {};
    return pipe(
        filterLocationsByQuery(filterOptions.searchQuery),
        filterLocationsByBuildings(filterOptions.buildings),
        filterLocationsByFloors(filterOptions.floors),
        filterLocationsByGeometryTypes(filterOptions.geometryTypes),
        filterLocationsByCategories(filterOptions.categories),
        filterLocationsByTypes(filterOptions.locationTypes),
        filterLocationsByAppUserRoles(filterOptions.appUserRoles)
    );
}

/**
 * Transformation operator to filter locations by a query.
 *
 * @param {string} query
 * @returns {OperatorFunction<Location[], Location[]>}
 */
export function filterLocationsByQuery(query: string = ''): OperatorFunction<Location[], Location[]> {
    return map((locations: Location[]) => {
        if (query > '') {
            query = query?.toLowerCase();
            const listOfIds = query.split(';');
            const filteredLocations = listOfIds?.length > 0 && locations.filter((location: Location) => listOfIds.includes(location.id.toLowerCase()));

            if (filteredLocations?.length > 0) {
                return filteredLocations;
            }

            return locations.filter((location: Location) =>
                location.translations.some(translation => translation.name?.toLowerCase().includes(query))
                || location.aliases?.some(alias => alias?.toLowerCase().includes(query))
                || location.externalId?.toLowerCase().includes(query)
                || location.id?.toLowerCase().includes(query));
        }
        return locations;
    });
}

/**
 * Transformation operator to filter locations by buildings.
 *
 * @param {Building[]} buildings
 * @returns {OperatorFunction<Location[], Location[]>}
 */
export function filterLocationsByBuildings(buildings: Building[]): OperatorFunction<Location[], Location[]> {
    const outsideOnVenue = buildings?.some(building => building.id === OUTDOOR_BUILDING.id) ?? false;
    return map((locations: Location[]) => {
        if (buildings?.length > 0) {
            return locations.filter((location: Location) => {
                if (outsideOnVenue && location.pathData.building.toLowerCase() === OUTDOOR_BUILDING.administrativeId) {
                    return true;
                }
                return buildings.some(building => location.pathData.building.toLowerCase() === building?.administrativeId?.toLowerCase());
            });
        }
        return locations;
    });
}

/**
 * Transformation operator to filter locations by floors.
 *
 * @param {Floor[]} floors
 * @returns {OperatorFunction<Location[], Location[]>}
 */
export function filterLocationsByFloors(floors: Floor[]): OperatorFunction<Location[], Location[]> {
    return map((locations: Location[]) => {
        if (floors?.length > 0) {
            return locations.filter((location: Location) =>
                floors.some(floor => location.pathData.floor === floor.floorIndex));
        }
        return locations;
    });
}

/**
 * Transformation operator to filter locations by types.
 *
 * @param {LocationType[]} types
 * @returns {OperatorFunction<Location[], Location[]>}
 */
export function filterLocationsByTypes(types: LocationType[]): OperatorFunction<Location[], Location[]> {
    const administrativeIds = types?.map(type => type.administrativeId.toLowerCase()) ?? [];
    return map((locations: Location[]) => {
        if (types?.length > 0) {
            return locations.filter((location: Location) =>
                administrativeIds.includes(location.type.toLowerCase()));
        }
        return locations;
    });
}

/**
 * Transformation operator to filter locations by categories.
 *
 * @param {Category[]} categories
 * @returns {OperatorFunction<Location[], Location[]>}
 */
export function filterLocationsByCategories(categories: Category[]): OperatorFunction<Location[], Location[]> {
    const categoryKeys = categories?.map(category => category.key.toLowerCase()) ?? [];
    return map((locations: Location[]) => {
        if (categories?.length > 0) {
            return locations.filter((location: Location) => {
                const locationCategories = location.categories.map(category => category.toLowerCase());
                return categoryKeys.some(categoryKey => locationCategories.includes(categoryKey));
            });
        }
        return locations;
    });
}

/**
 * Transformation operator to filter locations by geometry types.
 *
 * @param {string[]} geometryTypes
 * @returns {OperatorFunction<Location[], Location[]>}
 */
export function filterLocationsByGeometryTypes(geometryTypes: string[]): OperatorFunction<Location[], Location[]> {
    return map((locations: Location[]) => {
        if (geometryTypes?.length > 0) {
            return locations.filter((location: Location) => geometryTypes?.includes(location.locationType));
        }
        return locations;
    });
}

/**
 * Transformation operator to filter locations by AppUserRoles.
 *
 * @param {AppUserRole[]} appUserRoles
 * @returns {OperatorFunction<Location[], Location[]>}
 */
export function filterLocationsByAppUserRoles(appUserRoles: AppUserRole[]): OperatorFunction<Location[], Location[]> {
    const userRoleIds = appUserRoles?.map(userRole => userRole.id) ?? [];
    return map((locations: ExtendedLocation[]) => {
        if (userRoleIds?.length > 0) {
            return locations.filter((location: ExtendedLocation) => {
                const restrictions = location?.restrictions?.length > 0 ? location.restrictions : location?.typeOfLocation?.restrictions;
                return restrictions?.some(userRoleId => userRoleIds.includes(userRoleId));
            });
        }
        return locations;
    });
}
