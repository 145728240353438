<section>
    <panel-header header="Sync Manager" (cancelEvent)="closeModal()"></panel-header>

    <div class="top">
        <div class="top__info">
            <div class="top__img">
                <img
                    [src]="mediaItem.preview"
                    alt=""
                    [attr.data-mediaid]="mediaItem.id"
                    [attr.data-mediacategory]="mediaItem.category"
                    mediaItem>
            </div>
            <p>{{ imageName }}</p>
        </div>

        <div class="top__warning" role="note">
            Existing files with the same filename and extension will be overwritten.
        </div>

        <p class="top__instruction">Select the Solutions you want to sync this file to.</p>
    </div>

    <div class="bottom">
        <div class="list">
            <div class="list__select">
                <button class="mi-button mi-button--link" (click)="selectAllSolutions()">{{ 'Select all' }}</button>
                <button class="mi-button mi-button--link" (click)="selectNoneSolutions()">{{ 'Select none' }}</button>
            </div>
            <div class="solution-checkbox" *ngFor="let solution of syncableSolutions">
                <input
                    (click)="$event.stopPropagation()"
                    (change)="$event ? solutionSelectionModel.toggle(solution) : null"
                    [checked]="solutionSelectionModel.isSelected(solution)"
                    type="checkbox"
                    class="mi-input"
                    [name]="solution.id"
                    [id]="solution.id" />
                <label [for]="solution.id">{{solution.name}}</label>
            </div>
        </div>

        <panel-footer class="footer">
            <div class="footer__buttons">
                <button type="button" class="mi-button mi-button--base" (click)="onCancel()">Cancel</button>
                <button
                    type="button"
                    class="mi-button mi-button--primary"
                    (click)="synchronize()"
                    [disabled]="solutionSelectionModel.selected.length === 0">Sync to Solutions</button>
            </div>
        </panel-footer>
    </div>
</section>