<button type="button" class="mi-button mi-button--small mi-button--base" (click)="onCancel()">Cancel</button>
<span class="map-toolbar__divider"></span>
<button type="button" class="mi-button mi-button--small mi-button--delete" aria-label="New Graph Bounds"
    (click)="createNetworkGraphOutline()">
    <span class="tooltip">New Graph Bounds</span>
</button>

<button type="button" [disabled]="isSaveButtonDisabled" class="mi-button mi-button--small mi-button--primary" aria-label="Save Graph Bounds"
    (click)="saveNetworkGraphOutline()">
    <span class="tooltip">Save Graph Bounds</span>
</button>