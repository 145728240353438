import { Injectable } from '@angular/core';
import { CanActivate, Router, CanActivateChild, CanLoad, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(private authService: AuthService, private router: Router) { }

    canLoad(): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivateRoute(this.authService.attemptedUrl)
            .pipe(take(1));
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateRoute(state.url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateRoute(state.url);
    }

    /**
     * Check if the token is set, else redirect to login page.
     *
     * @private
     * @returns {(Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree)}
     * @memberof AuthGuard
     */
    private canActivateRoute(stateUrl: string): Observable<boolean> {
        return this.authService.isDoneLoading$
            .pipe(
                take(1),
                filter(isDone => isDone),
                switchMap(() => this.authService.isAuthenticated$),
                tap(isAuthenticated => isAuthenticated || this.authService.login(stateUrl))
            );
    }
}
