import { Component, OnDestroy, OnInit } from '@angular/core';
import { SolutionService } from '../../services/solution.service';
import { NotificationService } from '../../services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DisplayRule } from '@mapsindoors/typescript-interfaces';
import { debounceTime, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { stayAtCurrentUrl } from '../solution-settings-shared-functions.component';
import isEqual from 'fast-deep-equal';
import { SolutionConfig } from '../solution-settings.model';
import { primitiveClone } from '../../shared/object-helper';

@Component({
    selector: 'settings-3d',
    templateUrl: './settings-3d.component.html',
    styleUrls: ['./settings-3d.component.scss']
})

export class ThreeDSettingsComponent implements OnDestroy, OnInit {

    public settings3DForm = this.formBuilder.group({
        extrusionOpacity: ['', [
            Validators.required,
            Validators.min(0),
            Validators.max(1)
        ]],
        wallOpacity: ['', [
            Validators.required,
            Validators.min(0),
            Validators.max(1)
        ]],
    });

    public _mainDisplayRule: [DisplayRule, DisplayRule?] = [null];

    private _solutionConfig;
    private _originalFormState;
    private subscriptions = new Subscription();

    constructor(
        private formBuilder: FormBuilder,
        private solutionService: SolutionService,
        private notificationService: NotificationService,
        private spinner: NgxSpinnerService,
        private router: Router
    ) { }

    /**
     * NgOnInit.
     */
    ngOnInit(): void {
        this.spinner.show();
        this.subscriptions
            .add(
                this.solutionService.solutionConfig$
                    .pipe(tap(() => this.spinner.hide()))
                    .subscribe(solutionConfig => {
                        // There was a property named updateData which was assigned to solutionConfig. It got removed, but we want to keep it in mind here as a reminder.
                        this._solutionConfig = primitiveClone(solutionConfig);
                        this._originalFormState = {
                            extrusionOpacity: this._solutionConfig?.settings3D.extrusionOpacity,
                            wallOpacity: this._solutionConfig?.settings3D.wallOpacity
                        };

                        this._mainDisplayRule = [this._solutionConfig?.mainDisplayRule] as [DisplayRule, DisplayRule?];
                        this.setSettings3DValues(solutionConfig);

                    }))
            .add(
                this.router.events
                    .subscribe(() => {
                        if (this.settings3DForm.dirty) {
                            this.onDiscard();
                        }
                    }))
            .add(
                this.settings3DForm.valueChanges
                    .pipe(debounceTime(300))
                    .subscribe(formState => {
                        !isEqual(this._originalFormState, formState)
                            ? this.settings3DForm.markAsDirty()
                            : this.settings3DForm.markAsPristine();
                    })
            );
    }

    /**
     * Patches values to settings 3D form.
     */
    private setSettings3DValues(solutionConfig: SolutionConfig): void {
        this.settings3DForm.patchValue({
            extrusionOpacity: solutionConfig?.settings3D?.extrusionOpacity,
            wallOpacity: solutionConfig?.settings3D?.wallOpacity
        });
    }

    /**
     * NgOnDestroy.
     */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Get solutionConfig values.
     *
     * @returns {SolutionConfig}
     */
    public get solutionConfig(): SolutionConfig {
        return this._solutionConfig;
    }

    /**
     * Get Main Display Rule values.
     *
     * @returns {[DisplayRule, DisplayRule?]}
     */
    public get displayRules(): [DisplayRule, DisplayRule?] {
        return this._mainDisplayRule;
    }

    /**
     * Get a form control by its key path.
     *
     * @param {string} keyPath - FormControl key path.
     * @returns {FormControl}
     * @memberof DisplayRuleDetailsComponent
     */
    public getFormControl(keyPath: string): FormControl {
        return this.settings3DForm.get(keyPath) as FormControl;
    }

    /**
     * When discarding changes in 3D Settings section we can discard them and revert changes or cancel action and still edit the form.
     */
    public onDiscard(): void {
        // eslint-disable-next-line no-alert
        const dialogResponse = confirm(
            'You will lose your changes if you continue without saving. Do you want to continue?'
        );
        if (!dialogResponse) {
            stayAtCurrentUrl(this.router);
            return;
        } else {
            this.setSettings3DValues(this._solutionConfig);
            this.settings3DForm.markAsPristine();
        }
    }

    /**
     * When submitting accept and save changes made in 3D Settings Section.
     */
    public onSubmit(): void {
        const settings3DValues = this._solutionConfig;
        settings3DValues.settings3D.extrusionOpacity = this.settings3DForm.controls['extrusionOpacity'].value;
        settings3DValues.settings3D.wallOpacity = this.settings3DForm.controls['wallOpacity'].value;

        this.spinner.show();
        this.solutionService.updateSolutionConfig(settings3DValues)
            .pipe(tap(() => this.spinner.hide()))
            .subscribe(() => {
                this.notificationService.showSuccess('Changes saved successfuly!');
            }, () => {
                this.notificationService.showError('Something went wrong. Please try again.');
            });
    }
}