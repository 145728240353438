import { BehaviorSubject, Observable, OperatorFunction, ReplaySubject } from 'rxjs';
import { AllGeoJSON, area, polygons, transformTranslate } from '@turf/turf';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { Building } from '../buildings/building.model';
import { BuildingService } from '../buildings/building.service';
import { DataService } from '../services/data.service';
import { Floor } from '../buildings/floor.model';
import { Injectable } from '@angular/core';
import { Geometry, Location } from './location.model';
import { LocationType } from '../location-types/location-type.model';
import { OrderByLanguage } from '../pipes/order-by-language.pipe';
import { SolutionService } from '../services/solution.service';
import { GeoJSONGeometryType, Status } from '../shared/enums';
import { Translation } from './location.model';
import { TypesService } from '../services/types.service';
import { VenueService } from '../venues/venue.service';
import { environment } from '../../environments/environment';
import { primitiveClone } from '../shared/object-helper';
import { Venue } from '../venues/venue.model';
import { DerivedGeometry } from '../derived-geometry/DerivedGeometry';

export interface ExtendedLocation extends Location {
    buildingName: string;
    floor: Floor;
    icon: string;
    name: string;
    typeOfLocation: LocationType;
    iconFilename?: string; // TBD when location details is fully implemented
    floorName?: string;
    locationTypeName?: string;
    lastModifiedDate?: string;
    cloneOf?: string;
    wallGeometry?: DerivedGeometry;
}

@Injectable()
export class LocationService {
    #types: LocationType[] = [];
    #buildings: Building[] = [];
    #locationsMap: Map<string, Location> = new Map();
    private readonly DEFAULT_ICON_SIZE = 20;

    /**
     * Default icon size.
     *
     * @readonly
     * @type {number}
     * @memberof LocationService
     */
    public get defaultIconSize(): number {
        return this.DEFAULT_ICON_SIZE;
    }

    private mainDisplayRule;
    private locationsSubject = new ReplaySubject<Location[]>(1);
    private selectedLocationSubject = new BehaviorSubject<Location>(null);
    public isLocationDetailsFormDirty$ = new BehaviorSubject<boolean>(false);
    public isLocationDetailsFormClosable: boolean = true;
    public isRouteElementDetailsEditorDirty$ = new BehaviorSubject<boolean>(false);
    private currentFloor: Floor;
    private currentVenue: Venue;

    private dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit'
    };

    constructor(
        private buildingService: BuildingService,
        private dataService: DataService,
        private orderByLanguagePipe: OrderByLanguage,
        private solutionService: SolutionService,
        private typesService: TypesService,
        private venueService: VenueService
    ) {
        this.buildingService.selectedFloor$
            .subscribe(floor => this.currentFloor = floor);
        this.venueService.selectedVenue$
            .subscribe(venue => this.currentVenue = venue);
        this.solutionService.solutionConfig$
            .subscribe(solutionConfig => this.mainDisplayRule = solutionConfig.mainDisplayRule);
        this.typesService.types
            .subscribe(types => this.#types = types);

        //We subscribe to the buildings$ observable to ensure that the buildings are in sync with the venue before updating the locations.
        this.buildingService.buildings$
            .pipe(
                filter(buildings => buildings?.length > 0),
                tap(buildings => this.#buildings = buildings),
                switchMap(() => this.venueService.getSelectedVenue()),
                switchMap(venue => this.fetchLocations({ venue: venue.name })),
                tap(locations => this.#locationsMap = new Map(locations.map(location => ([location.id, location])))),
                tap(locations => this.locationsSubject.next(locations))
            )
            .subscribe();
    }

    /**
     * Get locations as an observable.
     *
     * @returns {Observable<Location[]>}
     * @memberof TypesService
     */
    get locations$(): Observable<Location[]> {
        return this.locationsSubject.asObservable();
    }

    /**
     * Observable for the selcted location.
     *
     * @readonly
     * @memberof LocationService
     * @returns {Observable<Location>}
     */
    get selectedLocation$(): Observable<Location> {
        return this.selectedLocationSubject.asObservable();
    }

    /**
     * Fetch locations from the store cache.
     *
     * @returns {Observable<Location[]>}
     * @memberof LocationService
     */
    public getLocations(): Observable<Location[]> {
        return this.locationsSubject.asObservable()
            .pipe(take(1));
    }

    /**
     * Get location by id.
     *
     * @param {string} id
     * @returns {Location}
     * @memberof LocationService
     */
    public getLocation(id: string): Location {
        return this.#locationsMap?.get(id);
    }

    /**
     * Fetch locations from the backend given venue as query parameter.
     * Return a filtered array of locations.
     * [params.venue] filter locations to a venue or 'all' venues.
     *
     * @private
     * @param {object} params
     * @param {string} params.venue
     * @returns {Observable<Location[]>}
     * @memberof LocationService
     */
    private fetchLocations(params: { venue: string; }): Observable<Location[]> {
        const options = { params };
        const endpoint = this.getLocationsEndpoint();
        return this.dataService.getItems<Location>(endpoint, options)
            .pipe(
                this.extendLocations(),
                this.calculateAreas()
            );
    }

    /**
     * Calculate the areas for all polygons.
     *
     * @private
     * @returns {ExtendedLocation[]}
     * @memberof LocationService
     */
    private calculateAreas(): OperatorFunction<ExtendedLocation[], ExtendedLocation[]> {
        return map(locations => locations.map(location => {
            location.area = location.geometry.type === 'Polygon'
                ? +area(polygons([location.geometry.coordinates])).toFixed(2)
                : 0;
            return location;
        }));
    }

    /**
     * Create a new location.
     *
     * @param {Location} location
     * @returns {Observable<Location>}
     * @memberof LocationService
     */
    public createLocation(location: Location): Observable<Location> {
        const endpoint = this.getLocationsEndpoint();
        return this.dataService.createItem<Location>(endpoint, location)
            .pipe(
                switchMap(id => this.dataService.getItem<Location>(endpoint + '/details/' + id)),
                this.extendLocation(),
                map(location => {
                    this.#locationsMap.set(location.id, { ...location });
                    this.locationsSubject.next(Array.from(this.#locationsMap.values()));
                    return location;
                })
            );
    }

    /**
     * Update a location.
     *
     * @param {Location} location
     * @returns {Observable<void>}
     * @memberof LocationService
     */
    public updateLocation(location: Location): Observable<void> {
        return this.updateLocations([location]);
    }

    /**
     * Update multiple locations.
     *
     * @param {Location[]} locations
     * @returns {Observable<void>}
     * @memberof LocationService
     */
    public updateLocations(locations: Location[]): Observable<void> {
        const endpoint = this.getLocationsEndpoint() + '/list';
        return this.dataService.updateItems<Location>(endpoint, locations)
            .pipe(
                this.extendLocations(),
                map((updatedLocations: Location[]) => {
                    for (const updatedLocation of updatedLocations) {
                        if (this.#locationsMap.has(updatedLocation.id)) {
                            this.#locationsMap.set(updatedLocation.id, { ...updatedLocation });
                        }
                    }
                    this.locationsSubject.next(Array.from(this.#locationsMap.values()));
                })
            );
    }

    /**
     * Delete a location.
     *
     * @param {Location} location
     * @returns {Observable<void>}
     * @memberof LocationService
     */
    public deleteLocation(location: Location): Observable<void> {
        return this.deleteLocations([location.id]);
    }

    /**
     * Delete multiple locations.
     *
     * @param {string[]} locationIds
     * @returns {Observable<any>}
     * @memberof LocationService
     */
    public deleteLocations(locationIds: string[]): Observable<void> {
        const endpoint = this.getLocationsEndpoint();
        return this.dataService.deleteItems(endpoint, locationIds)
            .pipe(
                tap(() => {
                    for (const id of locationIds) {
                        this.#locationsMap.delete(id);
                    }
                    this.locationsSubject.next(Array.from(this.#locationsMap.values()));
                })
            );
    }

    /**
     * Get the location endpoint.
     *
     * @private
     * @returns {string}
     * @memberof LocationService
     */
    private getLocationsEndpoint(): string {
        const solution = this.solutionService.getStaticSolution();
        return solution?.id + '/api/locations';
    }

    /**
     * Set the current location.
     *
     * @param {Location} location
     * @memberof LocationService
     */
    public selectLocation(location: Location): void {
        this.selectedLocationSubject.next(location);
    }

    /**
     * Get the current location.
     *
     * @returns {Location}
     * @memberof LocationService
     */
    public getSelectedLocation(): Location {
        return this.selectedLocationSubject.value;
    }

    /**
     * Creates translation objects from solution's languages.
     *
     * @param {*} solution
     * @returns {Translation[]}
     * @memberof LocationService
     */
    public createLanguageObjects(solution): Translation[] {
        const translations: Translation[] = [];
        if (solution) {
            for (const lang of solution.availableLanguages) {
                const newLanguage: Translation = {
                    language: lang,
                    name: null,
                    fields: {}
                };

                translations.push(newLanguage);
            }

            const genericLanguage: Translation = {
                language: 'generic',
                name: 'generic',
                fields: {}
            };

            translations.push(genericLanguage);
        }
        return this.orderByLanguagePipe.transform(translations, solution.defaultLanguage);
    }

    /**
     * Build the locations' view-models to be used on the map and list-view.
     *
     * @param {Location[]} locations
     * @param {Building[]} buildings
     * @returns {ExtendedLocation[]}
     * @memberof LocationService
     */
    public buildViewModels(locations: Location[], buildings: Building[]): ExtendedLocation[] {
        const defaultLang = this.solutionService.getStaticSolution()?.defaultLanguage;
        return locations.map((location: ExtendedLocation) => {
            location.typeOfLocation = this.#types?.find(type => type.administrativeId.toLowerCase() === location.type.toLowerCase());
            location.icon = location.displayRule?.icon
                || location.typeOfLocation?.displayRule?.icon
                || this.mainDisplayRule?.icon
                || `${environment.iconsBaseUrl}misc/default-marker.png`;
            location.name = location.translations?.find(tranlation => tranlation.language === defaultLang)?.name || 'n/a';
            location.locationTypeName = location.typeOfLocation?.displayName || 'n/a';
            location.lastModifiedDate = new Date(location.lastModified).toLocaleDateString('us-US', this.dateTimeFormatOptions) || 'n/a';

            const building = buildings.find(
                building => building.administrativeId.toLowerCase() === location.pathData.building.toLowerCase());
            location.buildingName = building ? building.displayName : 'n/a';
            location.floor = building ? building.floors.find(floor => floor.floorIndex === location.pathData.floor) : null;
            if (location.pathData.building === '_') {
                location.buildingName = 'OUTSIDE';
                location.floor = {
                    floorIndex: location.pathData.floor,
                    geometry: null,
                    pathData: null,
                    floorInfo: null,
                    solutionId: location.solutionId,
                    id: null,
                    displayName: location.pathData.floor.toString()
                };
            }
            location.floorName = location.floor?.displayName;
            // Check if generic 'language' exists. If not, we will add it.
            const hasGenericTranslation: boolean = location.translations?.some(translation => translation.language === 'generic');

            if (!hasGenericTranslation) {
                const genericTranslation: Translation = {
                    language: 'generic',
                    name: 'generic',
                    fields: {}
                };

                location.translations?.push(genericTranslation);
            }

            location.translations = this.orderByLanguagePipe.transform(location.translations, defaultLang);

            // Populating anchor property for POI's (old MI location data).
            if (location.geometry.type === GeoJSONGeometryType.Point) {
                location.anchor = {
                    coordinates: location.geometry?.coordinates,
                    type: location.geometry?.type
                };
            }

            return location;
        });
    }

    /**
     * Enhances the locations with additional information about type and building.
     *
     * @private
     * @returns {OperatorFunction<Location[], ExtendedLocation[]>}
     * @memberof LocationService
     */
    private extendLocations(): OperatorFunction<Location[], ExtendedLocation[]> {
        return map(locations => this.buildViewModels(locations, this.#buildings));
    }

    /**
     * Enhances the location with additional information about type and building.
     *
     * @private
     * @returns {OperatorFunction<Location, ExtendedLocation>}
     * @memberof LocationService
     */
    private extendLocation(): OperatorFunction<Location, ExtendedLocation> {
        return map(location => this.buildViewModels([location], this.#buildings)[0]);
    }

    /**
     * Create a new location's object.
     *
     * @returns {ExtendedLocation}
     * @memberof LocationService
     */
    public createEmptyLocation(): ExtendedLocation {
        const solution = this.solutionService.getStaticSolution();
        return {
            aliases: [],
            buildingName: null,
            categories: [],
            icon: `${environment.iconsBaseUrl}misc/default-marker.png`,
            id: '',
            floor: null,
            geometry: {
                bbox: [],
                coordinates: [],
                type: null
            },
            locationType: null,
            name: null,
            pathData: {
                venue: null,
                building: '_',
                floor: null,
                room: null
            },
            solutionId: solution?.id,
            status: Status.All,
            translations: this.createLanguageObjects(solution),
            type: null,
            typeOfLocation: null,
        };
    }

    /**
     * Creates an extended location based on the point's location/geometry.
     *
     * @param {GeoJSON.Point} point
     * @returns {ExtendedLocation}
     */
    private createPathData(point: GeoJSON.Point): ExtendedLocation {
        const floorObject = this.buildingService.getFloorByGeometry(point) ?? this.getOutdoorFloor();

        const pathData = {
            buildingName: floorObject?.pathData?.building ?? null,
            floor: floorObject ?? this.getOutdoorFloor(),
            geometry: point as Geometry,
            pathData: {
                venue: floorObject?.pathData?.venue ?? this.currentVenue?.name,
                building: floorObject?.pathData?.building ?? '_',
                floor: floorObject?.floorIndex ?? this.currentFloor?.floorIndex ?? 0,
                room: floorObject?.pathData?.room ?? null
            },
            anchor: point,
            imageURL: null,
            restrictions: null,
            typeOfLocation: null
        };

        return pathData as ExtendedLocation;
    }

    /**
     * Returns a new location based on a point.
     *
     * @param {GeoJSON.Point} point
     * @returns {ExtendedLocation}
     */
    public createNewLocation(point: GeoJSON.Point): ExtendedLocation {
        const emptyLocation = this.createEmptyLocation();
        const pathData = this.createPathData(point);

        return { ...emptyLocation, ...pathData };
    }

    /**
     * Get the building in which the location is located.
     *
     * @param {ExtendedLocation} location
     * @param {Building[]} buildings
     * @returns {Building} The building that contains the location.
     * @memberof LocationService
     */
    public getLocationBuilding(location: ExtendedLocation, buildings: Building[]): Building {
        if (location?.locationType === 'poi') {
            const point = new google.maps.LatLng(location.geometry.coordinates[1], location.geometry.coordinates[0]);
            return buildings.find(building => {
                const floor = building.floors.find(_floor => _floor.floorIndex === location.pathData.floor);
                const paths = floor?.geometry?.coordinates.map(
                    path => path.map(coordinates => new google.maps.LatLng(coordinates[1], coordinates[0]))
                ) || [];

                const polygon = new google.maps.Polygon({ paths });
                return google.maps.geometry.poly.containsLocation(point, polygon);
            });
        }
        return null;
    }

    /**
     * Make a duplicate of the given location.
     *
     * @param {ExtendedLocation} location
     * @returns {ExtendedLocation}
     * @memberof LocationService
     */
    public duplicateLocation(location: ExtendedLocation): ExtendedLocation {
        let locationCopy: ExtendedLocation;
        if (location) {
            locationCopy = primitiveClone(location);
            delete locationCopy.syncId; // syncId is added in the backend.
            locationCopy.id = null;
            locationCopy.externalId = null;
            locationCopy.path = null;
            locationCopy.geometry.bbox = null;
            locationCopy.cloneOf = location.id;
        }

        return locationCopy;
    }

    /**
     * Offset location by a few meters.
     * Only Areas and POIs are supported.
     *
     * @param {ExtendedLocation} location
     * @returns {ExtendedLocation}
     * @memberof LocationService
     */
    public offsetLocationPosition(location: ExtendedLocation): ExtendedLocation {
        const locationCopy = primitiveClone(location);
        const offsetGeometry = transformTranslate(locationCopy.geometry as AllGeoJSON, 4, 45, { units: 'meters' });
        locationCopy.geometry = offsetGeometry as Geometry;

        if (locationCopy.anchor) {
            const offsetAnchor = transformTranslate(locationCopy.anchor as AllGeoJSON, 4, 45, { units: 'meters' });
            locationCopy.anchor = offsetAnchor as Geometry;
        }

        return locationCopy;
    }

    /**
     * Returns an outdoor floor object with the same solution id as the current floor.
     *
     * @private
     * @returns {Floor}
     */
    private getOutdoorFloor(): Floor {
        const currentFloor = this.buildingService.getCurrentFloor(true) as Floor;

        return {
            floorIndex: 0,
            geometry: null,
            pathData: null,
            floorInfo: null,
            solutionId: currentFloor.solutionId,
            id: null,
            displayName: '0'
        };
    }
}

