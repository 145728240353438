<div class="add-multiple-doors">
    <select class="mi-select" [(ngModel)]="doorTypeValue" name="door-type">
        <option *ngFor="let type of doorTypes" [value]="type.key">{{ type.value }}</option>
    </select>

    <span class="divider"></span>

    <ng-container *ngIf="hasDoorPoint">
        <button
            (click)="undo()"
            type="button"
            class="mi-button mi-button--small mi-button--base">
            Undo last click
        </button>

        <span class="divider"></span>
    </ng-container>

    <button type="button" class="mi-button mi-button--small mi-button--base"
        (click)="cancelOperation()">
        Cancel
    </button>

    <button
        (click)="createDoors()"
        type="button"
        class="mi-button mi-button--small mi-button--primary add-door__submit">
        Save
    </button>
</div>
