
/**
 * A number representing a given button.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
 * @enum {number}
 */
export enum MouseButton {
    /** Usually the left button or the un-initialized state. */
    Main,
    /** Usually the wheel button or the middle button (if present). */
    Auxiliary,
    /** Usually the right button. */
    Secondary,
    /** Typically the Browser Back button. */
    Fourth,
    /** Typically the Browser Forward button. */
    Fifth
}