import { Injectable } from '@angular/core';

import * as Sentry from '@sentry/browser';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ErrorLoggerService {
    constructor() {
        //Only load sentry in production
        if (environment.name === 'prod' || environment.name === 'staging') {
            Sentry.init({
                dsn: 'https://0a4ee5a3ac9d43d3b93a0fa9460d8b16@sentry.io/4156833',
                release: `mi-cms@v${environment.version}`,
                environment: environment.name
            });
        }
    }

    /**
     *Logs the error to sentry.
     *
     * @param {*} error
     * @memberof ErrorLoggerService
     */
    public logError(error): void {
        if (environment.name === 'prod' || environment.name === 'staging') {
            Sentry.captureException(error.originalError || error.stack || error);
        } else {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    }
}
