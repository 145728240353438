import { LocationCountOfType, LocationType } from '../location-types/location-type.model';
import { Observable, of } from 'rxjs';
import { concatMap, filter, map, switchMap } from 'rxjs/operators';

import { DataService } from './data.service';
import { Injectable } from '@angular/core';
import { ObservableStore } from '@codewithdan/observable-store';
import { Solution } from '../solutions/solution.model';
import { SolutionService } from './solution.service';
import { StoreState } from '../store/store-state';

@Injectable({ providedIn: 'root' })
export class TypesService extends ObservableStore<StoreState> {

    constructor(
        private solutionService: SolutionService,
        private dataService: DataService
    ) {
        super({
            stateSliceSelector: state => {
                return { locationTypes: state?.locationTypes };
            }
        });

        this.solutionService.getCurrentSolution()
            .pipe(switchMap(solution => this.fetchTypes(solution)))
            .subscribe();
    }

    /**
     * Get location types as an observable.
     *
     * @returns {Observable<LocationType[]>}
     * @memberof TypesService
     */
    get types(): Observable<LocationType[]> {
        return this.stateChanged
            .pipe(
                filter(store => !!store?.locationTypes),
                map(store => store.locationTypes)
            );
    }

    /**
     * Get location types from the store.
     *
     * @returns {LocationType[]}
     * @memberof TypesService
     */
    public getTypesFromStore(): LocationType[] {
        return this.getStateSliceProperty<LocationType[]>('locationTypes');
    }

    /**
     * Get location type from store.
     *
     * @param {string} administrativeId - Location type administrative id.
     * @returns {LocationType}
     * @memberof TypesService
     */
    public getTypeFromStore(administrativeId: string): LocationType {
        return this.getTypesFromStore().find((type) => type.administrativeId === administrativeId);
    }

    /**
     * Get a list of Location counts grouped by type.
     *
     * @returns {Observable<Map<string, number>>}
     * @memberof TypesService
     */
    public getNumbersOfLocationsGroupedByType(): Observable<Map<string, number>> {
        const endpoint = `${this.getLocationsEndpoint()}/count`;
        return this.dataService.getItems<LocationCountOfType>(endpoint)
            .pipe(
                map(numbersOfLocationsGroupedByType =>
                    new Map(numbersOfLocationsGroupedByType.map(locationCountOfType => [locationCountOfType.id, locationCountOfType.count]))
                )
            );
    }

    /**
     * Get the type's endpoint.
     *
     * @private
     * @param {Solution} [solution]
     * @returns {string}
     * @memberof TypesService
     */
    private getTypesEndpoint(solution?: Solution): string {
        solution = solution ?? this.solutionService.getStaticSolution();
        return solution?.id + '/api/locationTypes';
    }

    /**
     * Get the location endpoint.
     *
     * @private
     * @returns {string}
     * @memberof TypesService
     */
    private getLocationsEndpoint(): string {
        const solution = this.solutionService.getStaticSolution();
        return solution?.id + '/api/locations';
    }

    /**
     * Get the location types from the remote backend.
     *
     * @private
     * @param {Solution} [solution]
     * @returns {Observable<LocationType[]>}
     * @memberof TypesService
     */
    private fetchTypes(solution?: Solution): Observable<LocationType[]> {
        const endpoint = this.getTypesEndpoint(solution);
        return this.dataService.getItems<LocationType>(endpoint)
            .pipe(
                map(locationTypes => {
                    const solution = this.solutionService.getStaticSolution();
                    locationTypes = this.formatTypes(locationTypes, solution?.defaultLanguage);
                    this.setState({ locationTypes }, LocationTypeStoreActions.GetLocationTypes);
                    return locationTypes;
                }));
    }

    /**
     * Get the location types.
     *
     * @param {boolean} [remote=false] - If true, get them from the remote backend.
     * @returns {Observable<LocationType[]>}
     * @memberof TypesService
     */
    public getTypes(remote = false): Observable<LocationType[]> {
        const types = this.getStateSliceProperty<LocationType[]>('locationTypes');

        if (!types || remote) {
            return this.fetchTypes();
        }

        return of(types);
    }

    /**
     * Delete a location type.
     *
     * @param {string} typeId
     * @returns {Observable<any>}
     * @memberof TypesService
     */
    public deleteType(typeId: string): Observable<any> {
        const endpoint = this.getTypesEndpoint() + '/' + typeId;
        return this.dataService.deleteItem(endpoint)
            .pipe(
                map(() => {
                    this.setState({
                        locationTypes: this.getStateSliceProperty<LocationType[]>('locationTypes').filter(type => type.id !== typeId)
                    }, LocationTypeStoreActions.RemoveLocationType);

                    return;
                })
            );
    }

    /**
     * Create a new location type.
     *
     * @param {LocationType} type
     * @returns {Observable<any>}
     * @memberof TypesService
     */
    public createType(type: LocationType): Observable<any> {
        const endpoint = this.getTypesEndpoint();
        return this.dataService.createItem<LocationType>(endpoint, type)
            .pipe(concatMap(() => this.fetchTypes()));
    }

    /**
     * Update a location type.
     *
     * @param {LocationType} type
     * @returns {Observable<any>}
     * @memberof TypesService
     */
    public updateType(type: LocationType): Observable<any> {
        const endpoint = this.getTypesEndpoint();
        return this.dataService.updateItem<LocationType>(endpoint, type)
            .pipe(concatMap(() => this.fetchTypes()));
    }

    /**
     * Update multiple location types.
     *
     * @param {LocationType[]} types
     * @returns {Observable<any>}
     * @memberof TypesService
     */
    public updateTypes(types: LocationType[]): Observable<any> {
        const endpoint = this.getTypesEndpoint() + '/list';
        return this.dataService.updateItems<LocationType>(endpoint, types)
            .pipe(concatMap(() => this.fetchTypes()));
    }

    /**
     * Set the types' displayName property.
     *
     * @private
     * @param {LocationType[]} types
     * @param {string} language
     * @returns {LocationType[]}
     * @memberof TypesService
     */
    private formatTypes(types: LocationType[], language: string): LocationType[] {
        return types?.map(type => {
            // Set the displayName property
            const translation = type.translations?.find(_translation => _translation.language === language);
            type.displayName = translation?.name || 'n/a';
            return type;
        }).sort((typeA, typeB) => typeA.displayName.trimStart().toLowerCase().localeCompare(typeB.displayName.trimStart().toLowerCase()));
    }
}


export enum LocationTypeStoreActions {
    RemoveLocationType = 'REMOVE_LOCATION_TYPE',
    GetLocationTypes = 'GET_LOCATION_TYPES',
    ClearLocationTypes = 'CLEAR_LOCATION_TYPES',
}
