import { Component, OnInit, NgZone } from '@angular/core';

import { VenueService } from '../venues/venue.service';
import { NotificationService } from '../services/notification.service';

import midt from '@mapsindoors/midt/tokens/color.json';

@Component({
    selector: 'new-venue',
    templateUrl: './new-venue.component.html'
})
export class NewVenueComponent implements OnInit {
    constructor(
        private zone: NgZone,
        private venueService: VenueService,
        private notificationService: NotificationService
    ) {
    }

    googleMap: any;
    venueOutline: google.maps.Polygon;
    venueBoundsCode: string = '';
    venueBoundsValid: boolean = false;
    displayBoundsCode: boolean = false;

    autocomplete: any;

    zoomLevel: number;

    newVenueMarker: any;

    venueDetails: any;

    boundsChanged: boolean = false;

    mapLoaded: boolean = false;

    mapOptions: google.maps.MapOptions = {
        center: { lat: 56, lng: 11 },
        zoom: 4,
        minZoom: 3,
        draggableCursor: 'crosshair',
        draggingCursor: 'move',
        mapTypeControl: true,
        mapTypeControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM
        },
        disableDefaultUI: true,
        tilt: 0,
        zoomControl: true,
        zoomControlOptions: {
            position: google.maps.ControlPosition.LEFT_CENTER
        },
        styles: [
            { 'stylers': [] },
            { 'featureType': 'transit', 'stylers': [{ 'visibility': 'off' }] },
            { 'featureType': 'road', 'elementType': 'labels', 'stylers': [{ 'visibility': 'off' }] },
            { 'featureType': 'administrative', 'stylers': [{ 'visibility': 'on' }] },
            { 'featureType': 'poi.medical', 'elementType': 'geometry.fill', 'stylers': [{ 'color': midt.color.white.base.value, }] }
        ]
    };

    ngOnInit(): void {
        this.venueDetails = {
            name: '',
            styles: [
                {
                    displayName: 'all',
                    folder: 'all'
                }
            ],
            tilesUrl: 'https://tiles.mapsindoors.com/tiles/indoor/temp/{style}/l{floor}/z{z}/x{x}/y{y}.png',
            geometry: { type: 'Polygon', coordinates: [] }
        };
        this.loadGoogleMap();
    }

    loadGoogleMap(): void {
        this.googleMap = new google.maps.Map(document.getElementById('venue-map'), this.mapOptions);

        this.autocomplete = new google.maps.places.Autocomplete(<HTMLInputElement>document.getElementById('places-autocomplete-field'));

        const self = this;

        google.maps.event.addListener(this.autocomplete, 'place_changed', function () {
            const place = self.autocomplete.getPlace();

            if (self.newVenueMarker) {
                self.newVenueMarker.setMap(null);
                self.newVenueMarker = null;
            }

            if (place.geometry) {
                if (place.geometry.viewport) {
                    self.googleMap.fitBounds(place.geometry.viewport);
                } else {
                    self.googleMap.setCenter(place.geometry.location);
                    self.googleMap.setZoom(17);
                }

                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();

                self.newVenueMarker = new google.maps.Marker({ map: self.googleMap, draggable: false, zIndex: 9999 });
                self.newVenueMarker.setPosition({ lat: lat, lng: lng });
            } else {
                if (place.name) {
                    const regex = /((-|)\d*\.\d*)/g;
                    const arr = place.name.match(regex);
                    const latLng = new google.maps.LatLng(arr[0], arr[1]);
                    self.googleMap.setZoom(17);
                    self.googleMap.panTo(latLng);
                    self.newVenueMarker = new google.maps.Marker({
                        position: latLng,
                        map: self.googleMap,
                        draggable: false,
                        zIndex: 9999
                    });
                }
            }
        });

        this.googleMap.addListener('zoom_changed', function () {
            self.zone.run(() => {
                self.zoomLevel = self.googleMap.getZoom();
            });
        });

        this.mapLoaded = true;
        this.initVenueOutline();
        this.zoomLevel = this.googleMap.getZoom();

        this.googleMap.addListener('click', function (event) {
            self.venueOutline.getPath().push(event.latLng);
            self.setBoundsCode();
        });

        // dom has not loaded the div size when the map draws.
        setTimeout(() => {
            google.maps.event.trigger(this.googleMap, 'resize');
            this.googleMap.setCenter({ lat: 56, lng: 11 });
        },
        100);
    }

    initVenueOutline(): void {
        this.venueOutline = new google.maps.Polygon({
            strokeColor: midt.color.blue[60].value,
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: midt.color.blue[60].value,
            fillOpacity: .1,
            map: this.googleMap,
            clickable: false,
            editable: true
        });
        this.venueOutline.setPath([]);

        const self = this;

        this.venueOutline.addListener('rightclick', function (event) {
            if (!event.vertex) {
                return;
            } else {
                self.removeVertex(event.vertex);
            }
        });

        this.venueOutline.getPath().addListener('insert_at', function () {
            self.setBoundsCode();
        });
        this.venueOutline.getPath().addListener('set_at', function () {
            self.setBoundsCode();
        });
    }

    removeVertex(vertex): void {
        const path = this.venueOutline.getPath();
        path.removeAt(vertex);
        this.setBoundsCode();
    }

    formatVenueBeforeSave(): void {
        this.setBoundsCode();
        this.setVenueBoundsCoordinates();
    }

    setVenueBoundsCoordinates(): void {
        const boundsObject = JSON.parse(this.venueBoundsCode);
        this.venueDetails.geometry.coordinates = boundsObject.geometry.coordinates;
    }

    saveVenue(): void {
        this.formatVenueBeforeSave();
        this.venueService.createVenue(this.venueDetails)
            .subscribe(
                (id) => {
                    this.venueDetails.id = id;
                    this.notificationService.showSuccess('Venue created');
                    // if created returns an ID use it to set current venue
                    this.venueService.setVenueEvent(this.venueDetails.id);
                },
                (err) => {
                    this.notificationService.showError(err);
                }
            );
    }

    toggleBoundsCode(): void {
        this.displayBoundsCode = !this.displayBoundsCode;
    }

    setBoundsCode(): void {
        const coordinates = [];

        this.venueOutline.getPath().forEach(function (item) {
            coordinates.push([item.lng(), item.lat()]);
        });
        coordinates.push(coordinates[0]);
        if (coordinates.length > 2) {
            this.venueBoundsValid = true;
        } else {
            this.venueBoundsValid = false;
        }

        const result = {
            type: 'Feature',
            geometry: { type: 'Polygon', coordinates: [coordinates] },
            properties: {}
        };
        this.zone.run(() => {
            this.venueBoundsCode = JSON.stringify(result, null, '\t');
        });
    }


    resetBounds(): void {
        this.venueOutline.getPath().clear();
        this.setBoundsCode();
    }

}
