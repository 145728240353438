import { Pipe, PipeTransform } from '@angular/core';
import { OrderBy } from './orderby';

@Pipe({ name: 'orderByLanguage' })
export class OrderByLanguage implements PipeTransform {
    constructor(private orderByPipe: OrderBy) { }
    transform(arr: Array<{
    name: string;
    fields?: {};
    language: string;
  }>, defaultLanguage: string): any {
    //first sort alphabetically by language
        arr = this.orderByPipe.transform(arr, 'language');
        //then move the default language to the first place in the array.
        const defaultInfo = arr.find(info => info.language === defaultLanguage);
        if (defaultInfo) {
            arr.unshift(arr.splice(arr.findIndex(info => info.language === defaultInfo.language), 1)[0]);
        }
        return arr;
    }
}
