import { Component, OnInit, Input, Output, EventEmitter, ElementRef, OnDestroy } from '@angular/core';
import { ViewState, ViewStateService } from '../view-state.service';
import { ExtendedLocation, LocationService } from '../../locations/location.service';
import { NetworkMapService } from '../network-map.service';
import { MapService } from '../../services/map.service';
import { LocationsMapService } from '../locations-map/locations-map.service';
import { Building } from '../../buildings/building.model';
import { Floor } from '../../buildings/floor.model';
import { Venue } from '../../venues/venue.model';
import { GraphDataService } from '../graph-data.service';
import { UserService } from '../../services/user.service';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BuildingService } from '../../buildings/building.service';
import { DrawingService } from '../../services/drawing.service';
import { DrawingMode } from '../../shared/enums';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../services/notification.service';

enum Operation {
    CreatePOI,
    CreateArea,
    CreateRouteElementBarrier,
    CreateRouteElementEntryPoint,
    CreateRouteElementFloorConnector,
    CreateGraphOutline,
    AddDoor,
    AddMultipleDoors
}
@Component({
    selector: 'map-toolbar',
    templateUrl: './map-toolbar.component.html',
    styleUrls: ['./map-toolbar.component.scss']
})
export class MapToolbarComponent implements OnInit, OnDestroy {
    @Input() mapZoomLevel = 0;

    /**
     * Venue setter.
     */
    @Input() set venue(venue: Venue) {
        this._venue = venue;
        if (this.currentOperation === Operation.CreateGraphOutline) {
            this.graphService.setGraphOutlineVisibility(this._venue.graphId, false);
        }

        // Check users privileges.
        this.isOwner = this.userService.hasOwnerPrivileges();
        this.isAdmin = this.userService.hasAdminPrivileges();
    }
    @Input() buildings: Building[] = [];
    @Input() floor: Floor;
    @Input() isNetworkVisible = false;
    @Output() networkVisibilityChange = new EventEmitter<boolean>();

    public viewStates = ViewState;
    public currentViewState = ViewState.Default;
    public operations = Operation;
    public currentOperation: Operation = null;
    public isMapTypeSatellite = false;
    public newNetworkChanges = false;
    public isAdmin = false;
    public isOwner = false;
    public isToolDisabled = false;
    public areaDrawingModes = DrawingMode;
    public areaDrawingMode = null;

    private isLocationDetailsFormDirty = false;
    private subscriptions = new Subscription();
    private _venue: Venue;

    /**
     * Venue getter.
     *
     * @returns {Venue}
     */
    get venue(): Venue {
        return this._venue;
    }

    constructor(
        private hostElement: ElementRef,
        private viewStateService: ViewStateService,
        private mapService: MapService,
        private locationService: LocationService,
        private locationsMapService: LocationsMapService,
        private networkMapService: NetworkMapService,
        private graphService: GraphDataService,
        private userService: UserService,
        private buildingService: BuildingService,
        private drawingService: DrawingService,
        private spinner: NgxSpinnerService,
        private notificationService: NotificationService
    ) { }

    /** NgOnInit. */
    ngOnInit(): void {
        this.subscriptions
            // ViewState observable
            .add(this.viewStateService.getViewStateObservable()
                .subscribe((state: ViewState) => {
                    // Prevent setting the Update state to avoid an empty toolbar when transitioning out
                    if (state === ViewState.Update) return;

                    // Add or remove toolbar sizing class according to view state value
                    if (state === ViewState.Create) {
                        this.hostElement.nativeElement.classList.add('toolbar--large');
                    } else {
                        this.hostElement.nativeElement.classList.remove('toolbar--large');
                    }

                    if (state === ViewState.AddDoor) {
                        this.currentOperation = Operation.AddDoor;
                    }

                    if (state === ViewState.AddMultipleDoors) {
                        this.currentOperation = Operation.AddMultipleDoors;
                    }

                    this.currentViewState = state;
                }))
            // Route elements update observable.
            .add(this.networkMapService.getRouteElementsUpdatedSubject()
                .subscribe(() => this.newNetworkChanges = true))
            // Listen for escape keyup event and cancel current operation when clicked
            .add(fromEvent<KeyboardEvent>(document, 'keyup')
                .pipe(
                    filter((event) => event.key === 'Escape' && this.currentOperation !== undefined),
                    filter(() => !this.isLocationDetailsFormDirty),
                    filter(() => this.locationService.isLocationDetailsFormClosable)
                )
                .subscribe(() => this.cancelOperation())
                // Listen for when building changes and check if it has no floors
            ).add((this.buildingService.getCurrentBuilding() as Observable<Building>)
                .subscribe(({ floors }) => {
                    this.isToolDisabled = floors?.length === 0;
                }))
            .add(this.locationService.isLocationDetailsFormDirty$
                .subscribe(isDirty => {
                    this.isLocationDetailsFormDirty = isDirty;
                }))
            .add(this.drawingService.drawingMode$
                .subscribe(drawingMode => {
                    this.areaDrawingMode = drawingMode;
                }));
    }

    /** NgOnDestroy. */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Get blank location object populated with venue and floor information.
     *
     * @private
     * @returns {ExtendedLocation}
     * @memberof MapToolbarComponent
     */
    private getNewLocationObject(): ExtendedLocation {
        const location = this.locationService.createEmptyLocation();
        location.floor = this.floor;
        location.pathData.venue = this._venue.name;
        location.pathData.floor = this.floor.floorIndex;

        return location;
    }

    /**
     * Initiate add POI mode.
     *
     * @memberof MapToolbarComponent
     */
    public createPointOfInterest(): void {
        this.currentOperation = Operation.CreatePOI;

        const locationObject = this.getNewLocationObject();
        this.locationsMapService.createNewPOIMarker(locationObject, this.buildings);
    }

    /**
     * Initiate add Area mode.
     *
     * @memberof MapToolbarComponent
     */
    public createArea(): void {
        this.currentOperation = Operation.CreateArea;

        const locationObject = this.getNewLocationObject();
        this.locationsMapService.initiateAreaDrawing(locationObject, this.buildings);
    }

    /**
     * Create route element of type Barrier.
     *
     * @memberof MapToolbarComponent
     */
    public createBarrierRouteElement(): void {
        this.currentOperation = Operation.CreateRouteElementBarrier;

        this.isNetworkVisible = true;
        this.networkVisibilityChange.emit(this.isNetworkVisible);

        this.networkMapService.createBarrierRouteElement(this._venue, this.floor.floorIndex);
    }

    /**
     * Create new Entry Point.
     *
     * @memberof MapToolbarComponent
     */
    public createEntryPoint(): void {
        this.currentOperation = Operation.CreateRouteElementEntryPoint;

        this.isNetworkVisible = true;
        this.networkVisibilityChange.emit(this.isNetworkVisible);

        this.networkMapService.createEntryPoint(this._venue, this.floor.floorIndex);
    }

    /**
     * Create new Floor Connector.
     *
     * @memberof MapToolbarComponent
     */
    public createFloorConnector(): void {
        this.currentOperation = Operation.CreateRouteElementFloorConnector;

        this.isNetworkVisible = true;
        this.networkVisibilityChange.emit(this.isNetworkVisible);

        this.networkMapService.createFloorConnector(this._venue, this.floor.floorIndex);
    }

    /**
     * Toggle visibility of network graph and route elements.
     *
     * @memberof MapToolbarComponent
     */
    public setNetworkVisibility(visible: boolean): void {
        this.networkVisibilityChange.emit(visible);
    }

    /**
     * Toggle between Road map and Satellite map types.
     *
     * @memberof MapToolbarComponent
     */
    public toggleMapType(): void {
        const googleMap = this.mapService.getMap();
        if (this.isMapTypeSatellite) {
            googleMap.setMapTypeId(google.maps.MapTypeId.ROADMAP);
        } else {
            googleMap.setMapTypeId(google.maps.MapTypeId.SATELLITE);
            googleMap.setOptions({ tilt: 0 });
        }

        this.isMapTypeSatellite = googleMap.getMapTypeId() === google.maps.MapTypeId.SATELLITE;
    }

    /**
     * Set map zoom level.
     *
     * @param {number} value
     * @memberof MapToolbarComponent
     */
    public setMapZoomLevel(value: number): void {
        const zoom = this.mapZoomLevel + value;
        this.mapService.getMap().setZoom(zoom);
    }


    /**
     * Cancel current operation and set view state to Default.
     *
     * @memberof MapToolbarComponent
     */
    public cancelOperation(): void {
        const setDefaultView = (): void => {
            this.viewStateService.setViewStateObservable(ViewState.Default);
            this.currentOperation = null;
        };

        switch (this.currentOperation) {
            case Operation.CreatePOI:
                // eslint-disable-next-line no-alert
                if (!confirm('Canceling without saving will discard any changes you have made.')) {
                    return;
                }
                this.locationsMapService.cancelCreateNewPOIMarker();
                setDefaultView();
                break;
            case Operation.CreateArea:
                // eslint-disable-next-line no-alert
                if (!confirm('Canceling without saving will discard any changes you have made.')) {
                    return;
                }
                this.locationsMapService.cancelAreaDrawing();
                setDefaultView();
                break;
            case Operation.CreateRouteElementBarrier:
            case Operation.CreateRouteElementEntryPoint:
            case Operation.CreateRouteElementFloorConnector:
                // eslint-disable-next-line no-alert
                if (!confirm('Canceling without saving will discard any changes you have made.')) {
                    return;
                }
                this.networkMapService.cancelCreateNewRouteElement();
                setDefaultView();
                break;
            case Operation.CreateGraphOutline: {
                // eslint-disable-next-line no-alert
                const dialogResponse = confirm('Canceling without saving will discard any changes you have made.');
                if (!dialogResponse) {
                    return;
                }

                this.graphService.setGraphOutlineVisibility(this._venue.graphId, false);
                setDefaultView();
                break;
            }
            default:
                setDefaultView();
                break;
        }
    }

    /**
     * React to a done operation.
     *
     * @memberof MapToolbarComponent
     */
    public operationDone(): void {
        switch (this.currentOperation) {
            case Operation.AddDoor:
                this.viewStateService.setViewStateObservable(ViewState.Update);
                this.currentOperation = null;
                break;
        }
    }

    /**
     * Switch area drawing mode.
     *
     * @memberof MapToolbarComponent
     * @param {DrawingMode} drawingMode
     */
    public setAreaDrawingMode(drawingMode: DrawingMode): void {
        this.drawingService.setDrawingMode(drawingMode);
    }

    /**
     * Refetches and redraws the network graph on the map.
     */
    public reloadNetworkGraph(): void {
        if (this.isNetworkVisible) {
            this.setNetworkVisibility(false);
        }

        this.networkMapService.getNetworkGraph(this._venue?.graphId, this.floor?.floorIndex, true)
            .subscribe(() => {
                this.setNetworkVisibility(true);
            }, error => this.notificationService.showError(error));

        // Reset variable to get default reload icon
        this.newNetworkChanges = false;
    }

    /**
     * Requests the backend to drop all network graph data and generate new ones.
     */
    public regenerateAllGraphData(): void {
        if (this.isNetworkVisible) {
            this.setNetworkVisibility(false);
        }

        this.spinner.show();
        this.networkMapService.regenerateAllGraphData()
            .subscribe(() => {
                this.setNetworkVisibility(true);
            });
    }

    /**
     * Edit venues network graph bounds.
     *
     * @memberof MapToolbarComponent
     */
    public editNetworkGraphOutline(): void {
        this.currentOperation = Operation.CreateGraphOutline;

        this.setNetworkVisibility(true);
        this.graphService.setGraphOutlineVisibility(this._venue.graphId, true);
    }

    /**
     * Create new graph bounds.
     *
     * @memberof MapToolbarComponent
     */
    public createNetworkGraphOutline(): void {
        this.graphService.createGraphOutline();
    }

    /**
     * Save the network graph bounds.
     *
     * @memberof MapToolbarComponent
     */
    public saveNetworkGraphOutline(): void {
        this.graphService.saveGraphOutline(this._venue.graphId);
    }

    /**
     * Open the panel listing the graph IDs in the solution.
     *
     * @memberof MapToolbarComponent
     */
    public getGraphIds(): void {
        this.graphService.publishGraphIds();
    }
}
