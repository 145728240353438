
import memoize from '../../../utilities/Memoize';
import { Model2DViewModel } from '../../../viewmodels/Model2DViewModel/Model2DViewModel';
import { generateRectangularPolygonFromPoint } from '../../shared/geometry-helper';

const memoizedGenerateRectangularPolygonFromPoint = memoize(generateRectangularPolygonFromPoint);

/**
 * GoogleMaps2DModel.
 *
 * @private
 */
export class GoogleMaps2DModel {

    /**
     * The area (in square meters) of the 2D model.
     *
     * @type {number}
     */
    public area: number;

    /**
     * The feature (view model) for the 2D model to show.
     *
     * @type {GeoJSON.Feature}
     */
    #feature: GeoJSON.Feature;

    /**
     * Reference to the Google Map.
     *
     * @type {google.maps.Map}
     */
    #map: google.maps.Map;

    /**
     * Instance of Google Maps OverlayView.
     *
     * @type {google.maps.OverlayView}
     */
    #overlayView: google.maps.OverlayView;

    /**
     * Container div element that holds the image.
     *
     * @type {HTMLDivElement}
     */
    #containerElement: HTMLDivElement;

    /**
     * The image element.
     *
     * @type {HTMLImageElement}
     */
    #imageElement: HTMLImageElement;

    /**
     * Bounds of the 2D model.
     *
     * @type {google.maps.LatLngBounds}
     */
    #bounds: google.maps.LatLngBounds;

    /**
     * The position of the 2D model.
     *
     * @type {google.maps.LatLng}
     */
    #position: google.maps.LatLng;

    /**
     * The zindex/sortkey for the 2D model.
     */
    #zIndex: number;

    /**
     * Creates an instance of GoogleMaps2DModel.
     *
     * Will place an image on the map with it's size is defined in meters instead of pixels,
     * meaning that its size is dependent on the map's zoom level.
     *
     * @param {Model2DViewModel} feature - A geojson feature to generate a 2D model for.
     * @param {google.maps.Map} map - A Google Maps instance.
     */
    constructor(feature: GeoJSON.Feature, map: google.maps.Map) {
        this.#feature = feature;
        this.#zIndex = feature.properties.zIndex;
        this.#map = map;

        //this.area = area(this.#feature.geometry);
        this.#position = new google.maps.LatLng({ lat: this.#feature.properties.anchor[1], lng: this.#feature.properties.anchor[0] });

        // The class itself cannot extend OverlayView since that would require the Google Maps API to be
        // available always. It's not if using other map providers.
        // So we need to create the OverlayView as an instance field and bind the required methods to it.
        this.#overlayView = new google.maps.OverlayView;
        this.#overlayView.onAdd = this.onAdd.bind(this);
        this.#overlayView.draw = this.draw.bind(this);
        this.#overlayView.onRemove = this.onRemove.bind(this);

        this.#overlayView.addListener('visible_changed', () => {
            this.#toggleVisibility(this.get('visible'));
        });
    }

    /**
     * Update the rendered 2D model with the given feature (if changed from the existing one).
     *
     * @param {Model2DViewModel} feature
     */
    update(feature): void {
        if (JSON.stringify(feature) !== JSON.stringify(this.#feature)) {
            this.#feature = feature;
            this.#position = new google.maps.LatLng({ lat: this.#feature.properties.anchor[1], lng: this.#feature.properties.anchor[0] });
            this.setBoundsAndImage();
            this.draw();
        }
    }

    /**
     * Google Maps OverlayView method that is called once when it is added to the map.
     */
    onAdd(): void {
        this.#containerElement = document.createElement('div');
        this.#containerElement.style.position = 'absolute';
        this.#imageElement = document.createElement('img');
        this.#containerElement.appendChild(this.#imageElement);

        this.setBoundsAndImage();

        // Add the element to the overlayLayer pane
        const panes = this.#overlayView.getPanes();
        panes.overlayLayer.appendChild(this.#containerElement);
    }

    /**
     * Calculate bounds used to present the image and
     * set image element styles.
     */
    setBoundsAndImage(): void {
        // Calculate bounds used to present the image.
        const polygon = memoizedGenerateRectangularPolygonFromPoint([this.#position.lng(), this.#position.lat()], this.#feature.properties.widthMeters, this.#feature.properties.heightMeters, 0);
        this.#bounds = new google.maps.LatLngBounds(
            // sw: index 0
            new google.maps.LatLng(polygon.geometry.coordinates[0][0][1], polygon.geometry.coordinates[0][0][0]),
            // ne: index 2
            new google.maps.LatLng(polygon.geometry.coordinates[0][2][1], polygon.geometry.coordinates[0][2][0])
        );

        if (this.#imageElement) {
            this.#imageElement.src = this.#feature.properties.imageUrl;
            this.#imageElement.style.width = '100%';
            this.#imageElement.style.height = '100%';
            this.#imageElement.style.position = 'absolute';
            this.#imageElement.style.transform = `rotate(${this.#feature.properties.bearing}deg)`;
            this.#imageElement.style.zIndex = '30'; // DANGER: Magic number that we don't know the reasoning behind.
        }
    }

    /**
     * Google Maps OverlayView method that is called when added to the map
     * or when zoom or center changes.
     */
    draw(): void {
        const overlayProjection = this.#overlayView.getProjection();
        if (!overlayProjection) {
            return;
        }
        const sw = overlayProjection.fromLatLngToDivPixel(this.#bounds.getSouthWest());
        const ne = overlayProjection.fromLatLngToDivPixel(this.#bounds.getNorthEast());

        // Resize the image container to fit the wanted dimensions
        if (this.#containerElement) {
            const width = ne.x - sw.x;
            const height = sw.y - ne.y;
            this.#containerElement.style.left = Math.round(sw.x) + 'px';
            this.#containerElement.style.top = Math.round(ne.y) + 'px';
            this.#containerElement.style.width = `${Math.round(width)}px`;
            this.#containerElement.style.height = `${Math.round(height)}px`;
            this.#containerElement.style.zIndex = this.#zIndex?.toString();

            // 2D Model should not be shown if smaller than 5 x pixel density.
            const minSize = window.devicePixelRatio * 5;
            this.#containerElement.style.display = (width <= minSize || height <= minSize) ? 'none' : 'block';
        }
    }

    /**
     * Toogle visibility of the 2D model.
     *
     * @param {boolean} visible
     */
    #toggleVisibility(visible: boolean): void {
        if (visible) {
            this.setMap(this.#map);
        } else {
            this.setMap(null);
        }
    }

    /**
     * Deallocate resource.
     */
    teardown(): void {
        this.#overlayView.setMap(null);
    }

    /**
     * Google Maps OverlayView method that is called whenever removed from the map.
     */
    onRemove(): void {
        this.#containerElement.parentNode.removeChild(this.#containerElement);
        google.maps.event.clearInstanceListeners(this);
        this.#containerElement = undefined;
    }

    /**
     * Relays setMap call to the OverlayView.
     *
     * @param {google.maps.Map} map
     */
    setMap(map: google.maps.Map): void {
        if ((map !== this.#overlayView.getMap() && map instanceof google.maps.Map) || map === null) {
            this.#overlayView.setMap(map);
        }
    }

    /**
     * Sets the anchor position for the 2D model.
     *
     * @param {google.maps.LatLng} latLng
     * @returns {void}
     */
    setPosition(latLng: google.maps.LatLng): void {
        if (latLng instanceof google.maps.LatLng) {
            this.#position = latLng;
            this.setBoundsAndImage();
            this.draw();
        }
    }

    /**
     * Sets an observable value.
     *
     * @param {string} key
     * @param {any} value
     */
    set(key: string, value: any): void {
        this.#overlayView.set(key, value);
    }

    /**
     * Gets an observable value.
     *
     * @param {string} key
     * @returns {any}
     */
    get(key: string): any {
        return this.#overlayView.get(key);
    }
}