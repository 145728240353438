import { MercatorCoordinate } from 'mapbox-gl';
import { Camera, Euler, HemisphereLight, Matrix4, Scene, sRGBEncoding, Vector3, WebGLRenderer } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { degToRad } from 'three/src/math/MathUtils';
import { FeatureClass } from '../../../viewmodels/FeatureClass';
import { Model3DViewModelProperties } from '../../../viewmodels/Model3DViewModel/Model3DViewModel';

export class Mapbox3DLayer implements mapboxgl.CustomLayerInterface {
    #modelsOnMap: Map<string, { scene: Scene, projection: Matrix4, src: string }> = new Map();
    #map: mapboxgl.Map;
    #renderer: WebGLRenderer;

    public readonly id: string;
    public readonly renderingMode: '3d' | '2d' = '3d';
    public readonly type: 'custom' = 'custom';

    constructor(layerId: string) {
        this.id = layerId;
    }

    /**
     * Sets the GeoJSON data and re-renders the layer.
     *
     * @public
     * @param {GeoJSON.Feature[]} features
     */
    public setData(features: GeoJSON.Feature[]): void {
        const loader = new GLTFLoader();
        loader.crossOrigin = 'no-cors';
        const hemisphereLight = new HemisphereLight(0xb1b5cd, 0x988f86, 1.1);
        hemisphereLight.position.set(0, 10, 0);

        /**
         * The following reduce function is for updating existing models and creating new ones.
         * On completion, the private property #featuresOnMap is replaced with the new Map.
         */
        this.#modelsOnMap = features
            .filter(feature => (feature?.properties?.featureClass === FeatureClass.MODEL3D) && feature?.properties?.scale)
            .reduce((models, feature: GeoJSON.Feature<GeoJSON.Point, Model3DViewModelProperties>) => {
                const featureId: string = feature?.id.toString();
                const model = this.#modelsOnMap.get(featureId) ?? { scene: new Scene(), projection: new Matrix4(), src: feature.properties.src };
                const { scene, projection, src } = model;

                if (src !== feature.properties.src || !this.#modelsOnMap.has(featureId)) {
                    model.src = feature.properties.src;
                    loader.load(feature.properties.src, (gltf) => {
                        scene.clear();
                        gltf.scene.rotateX(degToRad(90));
                        scene.add(gltf.scene);
                        scene.add(hemisphereLight.clone());
                    });
                }

                const mercatorCoordinates = MercatorCoordinate.fromLngLat(feature.geometry.coordinates as [number, number], 0);
                projection.makeTranslation(mercatorCoordinates.x, mercatorCoordinates.y, mercatorCoordinates.z);
                projection.scale(this.#getScale(mercatorCoordinates));

                scene.setRotationFromEuler(this.#getRotationEuler(feature.properties));
                scene.scale.set(feature.properties.scale, feature.properties.scale, feature.properties.scale);

                return models.set(feature.id, model);
            }, new Map());
    }

    /**
     * This method is called when the layer is added to the Map with Map#addLayer.
     * This gives the layer a chance to initialize gl resources and register event listeners.
     *
     * @param {mapboxgl.Map} map - The Map this custom layer was just added to.
     * @param {WebGLRenderingContext} gl - The gl context for the map.
     */
    public onAdd(map: mapboxgl.Map, gl: WebGLRenderingContext): void {
        this.#map = map;

        this.#renderer = new WebGLRenderer({
            canvas: this.#map.getCanvas(),
            context: gl,
            antialias: true
        });

        this.#renderer.autoClear = false;
    }

    /**
     * Called during a render frame allowing the layer to draw into the GL context.
     *
     * @param {WebGLRenderingContext} gl - The map's gl context.
     * @param {number[]} matrix - The map's camera matrix.
     */
    public render(gl: WebGLRenderingContext, matrix: number[]): void {
        const camera = new Camera();
        for (const model of this.#modelsOnMap.values()) {
            if (model) {
                camera.projectionMatrix = new Matrix4().fromArray(matrix).multiply(model.projection);
                this.#renderer.outputEncoding = sRGBEncoding;
                this.#renderer.resetState();
                this.#renderer.render(model.scene, camera);
            }
        }

        this.#map.triggerRepaint();
    }

    /**
     * Creates an Euler to represent the rotation of the model.
     *
     * @see https://threejs.org/docs/#api/en/math/Euler
     * @param {Model3DViewModelProperties} properties
     * @returns {Euler}
     */
    #getRotationEuler(properties: Model3DViewModelProperties): Euler {
        const x = degToRad(properties.rotation[0]);
        const z = degToRad(properties.rotation[1]);
        const y = degToRad(properties.rotation[2]);

        return new Euler(x, y, z, 'XZY');
    }

    /**
     * Creates a Vector3 to represent the MercatorCoordinate scale.
     *
     * @param {MercatorCoordinate} mercatorCoordinates
     * @returns {Vector3}
     */
    #getScale(mercatorCoordinates: MercatorCoordinate): Vector3 {
        const scale = mercatorCoordinates.meterInMercatorCoordinateUnits();
        return new Vector3(scale, -scale, scale);
    }
}