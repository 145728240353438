<div *ngIf="categories">
    <div>
        <mat-form-field [matMenuTriggerFor]="catMenu">
            <input [(ngModel)]="categoryQuery" name="categories" matInput placeholder="Filter categories">
        </mat-form-field>
        <mat-menu #catMenu="matMenu" [overlapTrigger]="false">
            <div style="padding: 16px; cursor: pointer;" (click)="addCategory(category)" *ngFor="let category of notSelectedCategories | orderBy: ['displayName'] | filterCategories:categoryQuery">
                {{category.displayName}}
            </div>
        </mat-menu>
    </div>
</div>
<div *ngIf="selectedCategories && selectedCategories.length > 0">
    <p *ngIf="!isMultipleLocations" style="margin-top: 0px;">Location currently belongs to these categories:</p>
    <p *ngIf="isMultipleLocations" style="margin-top: 0px;">The selected locations are added to these categories:</p>
    <div>
        <mat-chip-list class="select-chips dark-text" aria-label="Categories">
            <mat-chip *ngFor="let cat of selectedCategories;" [removable]="true" (removed)="removeCategory(cat)">
                {{cat.displayName}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
    </div>
</div>