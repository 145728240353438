/**
 * Check if item is of type object.
 *
 * @param {*} item
 * @returns {boolean}
 */
export function isObject(item: any): boolean {
    return item !== null
        && typeof item === 'object'
        && !Array.isArray(item); // isArray check is needed as arrays is also of type object
}

/**
 * Check if all object properties are null.
 *
 * @example
 * areAllPropertiesNull({ key1: value, key2: null });
 * // returns false
 * areAllPropertiesNull({ key1: null, key2: null });
 * // returns true
 * @param {object} object
 * @returns {boolean}
 */
export function areAllPropertiesNull(object: object): boolean {
    return !Object.values(object).some(entry => entry !== null);
}

/**
 * Set properties to null when all its child properties are null.
 *
 * @example
 * nullifyParentProps({ key1: value, key2: { key21: null } });
 * // returns  { key1: value, key2: null }
 * @param {object} object
 * @returns {object}
 */
export function nullifyParentProps(object: object): object {
    if (!isObject(object)) return;

    for (const key in object) {
        if (!isObject(object[key])) {
            continue;
        }

        if (areAllPropertiesNull(object[key])) {
            object[key] = null;
        } else {
            nullifyParentProps(object[key]);
        }
    }

    return object;
}

/**
 * Recursively merge objects.
 *
 * @example
 * const targetObject = { key1: 'value', key2: { key21: 'value' } };
 * const newObject = { key2: { key21: 'newValue' } }
 * mergeObjects(targetObject, newObject);
 * // returns { key1: 'value', key2: { key21: 'newValue' } }
 * @param {object} target
 * @param {...object[]} sources
 * @returns {object} - A new object with merged properties.
 */
export function mergeObjects(target: object, ...sources: object[]): object {
    const mergeMethod = (targetClone: object, ...sources: object[]): object => {
        if (!sources.length) {
            return targetClone;
        }

        const source = sources.shift();

        if (isObject(targetClone) && isObject(source)) {
            for (const key in source) {
                if (isObject(source[key])) {
                    if (!targetClone[key]) {
                        Object.assign(targetClone, { [key]: {} });
                    }

                    mergeMethod(targetClone[key], source[key]);
                } else {
                    Object.assign(targetClone, { [key]: source[key] });
                }
            }
        }

        return mergeMethod(targetClone, ...sources);
    };

    const clone = primitiveClone(target); // Clone target object
    return mergeMethod(clone, ...sources);
}

/**
 * Create a new nested object based on keypath and set the value.
 *
 * @example
 * createObjectFromKeypath('key1.key2', 'value')
 * // returns { key1: { key2: 'value' } }
 * @param {string} keypath - The keypath eg. "polygon.stroke.width".
 * @param {*} value - The value to be set at the last key.
 * @returns {object} - The new object.
 */
export function createObjectFromKeypath(keypath: string, value: any): { [key: string]: any } {
    const reducer = (previousValue: any, currentValue: string, index: number, array: string[]): { [key: string]: any } => {
        return { [currentValue]: index + 1 < array.length ? previousValue : value };
    };
    const keys = keypath.split('.');
    return keys.reduceRight(reducer, {});
}

/**
 * Recursively remove properties with null values.
 *
 * @example
 * removeEmptyValues({ key1: 'value', key2: { key21: null } });
 * // returns { key1: 'value' }
 * @param {object} object
 * @returns {object}
 */
export function removeEmptyValues(object: object): object {
    if (!isObject(object)) return;

    // Remove null values
    return Object.entries(object)
        .map(([key, value]) => [key, value && isObject(value) ? removeEmptyValues(value) : value])
        .reduce((previousValue, [key, value]: [string, any]) => {
            return (value === null ? previousValue : (previousValue[key] = value, previousValue));
        }, {});
}

/**
 * Recursively remove empty object properties.
 *
 * @example
 * removeEmptyObjects({ key1: 'value', key2: {} });
 * // returns { key1: 'value' }
 * @param {object} object
 * @returns {object}
 */
export function removeEmptyObjects(object: object): object {
    if (!isObject(object)) return;

    for (const key in object) {
        // Continue to next iteration if null or not an object
        if (!isObject(object[key])) {
            continue;
        }

        removeEmptyObjects(object[key]);

        // Delete object property without child properties
        if (Object.keys(object[key]).length === 0) {
            delete object[key];
        }
    }

    return object;
}

/**
 * Clones an object (primitive types only).
 *
 * @template T
 * @param {T} object
 * @returns {T}
 */
export function primitiveClone<T>(object: T): T {
    return JSON.parse(JSON.stringify(object)) as T;
}