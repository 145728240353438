import { Component, Provider, forwardRef, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { createDropdownItemElement } from '../../shared/mi-dropdown/mi-dropdown';
import { CategoryService } from '../../categories/category.service';

const CATEGORY_DROPDOWN_CONTROL_VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LocationCategoriesComponent),
    multi: true,
};

@Component({
    selector: 'location-categories',
    templateUrl: './location-categories.component.html',
    styleUrls: ['./location-categories.component.scss'],
    providers: [CATEGORY_DROPDOWN_CONTROL_VALUE_ACCESSOR]
})
export class LocationCategoriesComponent implements ControlValueAccessor {
    @ViewChild('categoriesDropdown') categoriesDropdownElement: ElementRef<HTMLMiDropdownElement>;
    private onTouched!: Function;
    private onChanged!: Function;
    public categoriesDropdownItems: HTMLMiDropdownItemElement[] = [];
    public selectedCategories: HTMLMiDropdownItemElement[] = [];

    constructor(private categoryService: CategoryService) {}

    /**
     * OnChange handler.
     *
     * @memberof LocationCategoriesComponent
     */
    public onSelectionChange({ detail }: CustomEvent): void {
        this.selectedCategories = detail;
        const categoryKeys = this.selectedCategories?.map(item => item.value);
        this.onChanged(categoryKeys);
        this.onTouched();
    }

    /**
     * Remove from from list.
     *
     * @param {string} value - Index of category to remove.
     * @memberof LocationCategoriesComponent
     */
    public onRemoveChip(value: string): void {
        this.selectedCategories = this.selectedCategories.filter(item => item.value !== value);
        const categoryKeys = this.selectedCategories?.map(item => item.value);
        const item = this.categoriesDropdownItems.find(item => item.value === value);
        if (item) {
            item.selected = false;
        }

        this.onChanged(categoryKeys);
        this.onTouched();
    }

    writeValue(categoryKeys: string[]): void {
        this.selectedCategories = [];
        const categories = this.categoryService.getCategoriesFromStore();
        this.categoriesDropdownItems = categories?.map(
            category => {
                const selected = categoryKeys?.includes(category.key) || false;
                const item = createDropdownItemElement(category.displayName, category.key, selected);
                selected && this.selectedCategories.push(item);
                return item;
            }) || [];
    }

    registerOnChange(fn: any): void {
        this.onChanged = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(): void {
        this.categoriesDropdownElement.nativeElement.disabled = this.categoriesDropdownItems?.length < 1;
    }
}
