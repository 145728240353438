<div fxFlexFill fxLayout="column">

	<mat-toolbar *ngIf="types" fxLayout="row" class="mat-elevation-z2 page-toolbar">
		<div fxFlex class="IE-FlexAuto" style="padding-right: 32px;">
			<button type="button" mat-raised-button (click)="navigateTo('/types')">
				<mat-icon>arrow_back</mat-icon>Back
			</button>
		</div>
		<div fxFlex="<grow>"></div>
	</mat-toolbar>
	
	<mat-progress-bar color="accent" mode="indeterminate" *ngIf="pageLoad"></mat-progress-bar>

	<div fxFlex="<grow>" class="page-content" >
		<div fxLayout="row" style="max-width: 1200px;">
			
			<div *ngIf="currentType" fxFlex="<grow>" class="column IE-FlexAuto">
				<mat-card style="margin-bottom: 32px;">
					<div class="section">
						<h2>New property</h2>
						<div *ngIf="!newField" style="margin-right: -16px;">
							<mat-card matRipple (click)="addNewProperty('text', 'text')" class="new-field-select">
								<h3>Text</h3>
								<div class="preview-input">
									<div>
										<div class="input-label"></div>
									</div>
									<div class="word-area">
										<div class="word" style="width: 110px;"></div>
										<div class="word" style="width: 40px;"></div>
									</div>
								</div>
							</mat-card>
							<mat-card matRipple (click)="addNewProperty('textarea', 'multiline-text')" class="new-field-select">
								<h3>Multiline text</h3>
								<div class="preview-input">
									<div>
										<div class="input-label"></div>
									</div>
									<div class="word-area">
										<div class="word" style="width: 60px;"></div>
										<div class="word" style="width: 20px;"></div>
										<div class="word" style="width: 40px;"></div>
										<div class="word" style="width: 120px;"></div>
									</div>
								</div>
							</mat-card>
						</div>
						<form #form="ngForm">
							<mat-card *ngIf="newField" style="margin-bottom: 16px;">
								<div *ngIf="newField.type == 'text'">
									<h3>Text</h3>
									<div fxLayout="row">
										<div fxFlex class="IE-FlexAuto">
											<mat-form-field>
												<input name="field-name" [(ngModel)]="newField.text" [required]="true" matInput type="text" placeholder="Property name">
											</mat-form-field>
										</div>
										<div fxFlex class="IE-FlexAuto" style="padding: 16px 0px 0px 16px;">
											<mat-checkbox name="translatable" [(ngModel)]="newField.translatable">Translatable</mat-checkbox>
										</div>
										<div fxFlex class="IE-FlexAuto" style="padding-left: 32px;">
											<mat-form-field style="width: 100px;">
												<mat-select name="appType" [(ngModel)]="newField.appType" placeholder="Content">
													<mat-option *ngFor="let contentType of textTypes" [value]="contentType.type">
														{{contentType.name}}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>
										<div fxFlex="<grow>"></div>
									</div>
								</div>

								<div *ngIf="newField.type == 'textarea'">
									<h3>Multiline text</h3>
									<div fxLayout="row">
										<div fxFlex class="IE-FlexAuto">
											<mat-form-field>
												<input name="field-name" [(ngModel)]="newField.text" [required]="true" matInput type="text" placeholder="Property name">
											</mat-form-field>
										</div>
										<div fxFlex class="IE-FlexAuto" style="padding: 16px 0px 0px 16px;">
											<mat-checkbox name="translatable" [(ngModel)]="newField.translatable">Translatable</mat-checkbox>
										</div>
										<div fxFlex="<grow>"></div>
									</div>
								</div>

								<div>
									<button style="margin-right: 16px;" (click)="addPropertyToTemplate(); form.reset()" type="submit" [disabled]="!form.valid" color="primary" mat-raised-button>Add property</button>
									<button mat-raised-button (click)="cancelNewProperty()">Cancel</button>
								</div>
								<p class="discrete">Once a property is added to the template it is no longer possible to change it's name.</p>
							</mat-card>
						</form>
					</div>
					<div class="section">
						<div *ngIf="currentType.fields && currentType.fields.length > 0">
							<h2>Edit properties</h2>
							<div class="section" *ngFor="let field of this.currentType.fields">
								<h3 style="margin-bottom: 8px;" *ngIf="field.type == 'text'">Text</h3>
								<h3 style="margin-bottom: 8px;" *ngIf="field.type == 'textarea'">Multiline text</h3>
								<div fxLayout="row">
									<div fxFlex class="IE-FlexAuto" style="margin-right: 16px; position: relative;">
										<button (click)="openDeleteOverlay(field.text)" mat-icon-button type="button">
											<mat-icon>delete</mat-icon>
										</button>
										<div *ngIf="showDeleteOverlay == field.text" class="field-delete-overlay mat-elevation-z2">
											<p style="margin-top: 0px; font-size: 15px; width: 240px;">Deleting a property will also remove the corresponding data entered on {{currentType.displayName}}-locations!</p>
											<button style="margin-right: 8px;" (click)="deleteTypeFromTemplate(field)" mat-raised-button type="button">
												<mat-icon color="warn">delete</mat-icon>
												<span>Delete</span>
											</button>
											<button type="button" (click)="closeDeleteOverlay()" mat-raised-button>
												<span>Cancel</span>
											</button>
										</div>
									</div>
									<div fxFlex class="IE-FlexAuto">
										<mat-form-field>
											<input [value]="field.text" class="discrete" [readonly]="true" matInput type="text" placeholder="Property name">
										</mat-form-field>
									</div>
									<div fxFlex class="IE-FlexAuto" style="padding: 12px 0px 0px 16px; position: relative;">
										<mat-checkbox name="translatable" (click)="openLanguageOverlay(field.text)" [(ngModel)]="field.translatable">Translatable</mat-checkbox>
										<div style="width: 320px;" *ngIf="showLanguageOverlay == field.text" class="field-translate-overlay mat-elevation-z2">
											<p *ngIf="!field.translatable">Turning translatable off will remove the corresponding location data in all but the default language ({{currentSolution.defaultLanguage}}).
											</p>
											<p *ngIf="field.translatable">
												You should fill in data if any existing locations are using this property.
											</p>
											<button (click)="closeLanguageOverlay()" style="margin-right: 8px;" mat-raised-button type="button">
												<span>Continue</span>
											</button>
											<button type="button" (click)="cancelLanguageOverlay(field)" mat-raised-button>
												<span>Cancel</span>
											</button>
										</div>
									</div>
									<div *ngIf="field.type == 'text'" fxFlex class="IE-FlexAuto" style="padding-left: 32px;">
										<mat-form-field style="width: 100px;">
											<mat-select name="appType" [(ngModel)]="field.appType" placeholder="Content">
												<mat-option *ngFor="let contentType of textTypes" [value]="contentType.type">
													{{contentType.name}}
												</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<div fxFlex="<grow>"></div>
								</div>
							</div>
						</div>
						<button style="margin-top: 16px;" [disabled]="!currentType" (click)="updateType()" type="button" color="primary" mat-raised-button>
							Save template
						</button>
					</div>
				</mat-card>
			</div>

			<div fxFlex class="column IE-FlexAuto">
				<div *ngIf="types" class="mat-elevation-z2" style="width: 500px;">
					<mat-toolbar fxLayout="row">
						<div fxFlex *ngIf="currentType">
							<img class="button-image" [src]="currentType.displayRule.icon">
							<span style="vertical-align: middle;">{{currentType.displayName}}</span>
						</div>
						<div fxFlex="<grow>"></div>
						<div fxFlex>
						</div>
					</mat-toolbar>
					<div *ngIf="currentType">
						<div *ngIf="currentType.fields && currentType.fields.length > 0">
							<div class="langs section">
								<mat-tab-group color="accent">
									<mat-tab *ngFor="let lang of currentSolution.availableLanguages" [label]="lang">
										<div class="column">
											<div *ngFor="let field of currentType.fields">
												<mat-form-field style="display: block;" *ngIf="field.translatable && field.type == 'text'">
													<input [value]="" [readonly]="true" matInput type="text" placeholder="{{field.text}}">
												</mat-form-field>
												<mat-form-field style="display: block;" *ngIf="field.translatable && field.type == 'textarea'">
													<textarea [value]="" rows="3" [readonly]="true" matInput type="text" placeholder="{{field.text}}"></textarea>
												</mat-form-field>
											</div>
										</div>
									</mat-tab>
								</mat-tab-group>
							</div>
							<div class="section column">
								<div *ngFor="let field of currentType.fields">
									<mat-form-field style="display: block;" *ngIf="!field.translatable && field.type == 'text'">
										<input [value]="" [readonly]="true" matInput type="text" placeholder="{{field.text}}">
									</mat-form-field>
									<mat-form-field style="display: block;" *ngIf="!field.translatable && field.type == 'textarea'">
										<textarea [value]="" rows="3" [readonly]="true" matInput type="text" placeholder="{{field.text}}"></textarea>
									</mat-form-field>
								</div>
							</div>
						</div>
						<div *ngIf="!(currentType.fields?.length > 0)">
							<div class="column">
								<p class="empty-state">Build a template by adding properties.</p>
								<p class="empty-state">Finish by saving the template.</p>
							</div>
						</div>
					</div>
					<div *ngIf="!currentType">
						<div class="column">
							<p><strong>Start by selecting a type.</strong></p>
							<p>Location type templates enables you to configure custom fields (properties) for locations of that type.</p>
							<p>Once a property has been added to a type you can start using it on all locations of that type.</p>
							<p>For example you may want to add <i>email</i> as a field on all restaurants.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>