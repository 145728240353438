<div fxFlexFill fxLayout="column">
	<mat-toolbar class="mat-elevation-z2 page-toolbar">
		<div fxFlex>
			<button (click)="newCategory()" mat-raised-button>New category</button>
		</div>
	</mat-toolbar>
	<mat-progress-bar mode="indeterminate" color="accent" *ngIf="pageLoad"></mat-progress-bar>

	<div fxLayout="column" class="list-sidebar mat-elevation-z8" *ngIf="currentCategory">
		<mat-toolbar class="secondary-toolbar">
			<div fxFlex class="IE-FlexAuto" style="position: relative;">
				<button class="ga-categories-save" type="submit" [disabled]="!form.valid" (click)="saveCategory()" color="primary" mat-raised-button>
					<span class="ga-skip" *ngIf="creatingNewCategory">Create</span>
					<span *ngIf="!creatingNewCategory">Save and close</span>
				</button>
				<button *ngIf="!creatingNewCategory" (click)="openDeleteOverlay()" mat-icon-button>
					<mat-icon>delete</mat-icon>
				</button>
				<div *ngIf="showDeleteOverlay" class="delete-overlay mat-elevation-z2">
					<p style="margin-top: 0px; margin-right: 30px; font-size: 15px;">Delete this location?</p>
					<div (click)="closeDeleteOverlay()" class="close-overlay">
						<mat-icon>close</mat-icon>
					</div>
					<button (click)="deleteCategory()" mat-raised-button>
						<mat-icon color="warn">delete</mat-icon>
						<span> Really delete</span>
					</button>
					<button (click)="closeDeleteOverlay()" mat-raised-button>
						<span>Cancel</span>
					</button>
				</div>
			</div>
			<div fxFlex="<grow>">
				<div style="margin-left: 16px;" *ngIf="creatingNewCategory" fxFlex="<grow>">
					<mat-checkbox matTooltip="When adding a new category auto-translate can try to translate other languages based on your first entry." [(ngModel)]="autoTranslate">Suggest translations</mat-checkbox>
				</div>
			</div>
			<div fxFlex class="IE-FlexAuto">
				<button (click)="closeSidebar()" mat-icon-button>
					<mat-icon>close</mat-icon>
				</button>
			</div>
		</mat-toolbar>
		<div style="overflow: auto;" fxFlex="<grow>" class="page-content">
			<div class="section" fxLayout="column">
				<form #form="ngForm" fxLayout="column">
					<div class="column" fxFlex>
						<div>
							<div fxLayout="column">
								<mat-form-field *ngFor="let lang of currentCategory.translations | orderByLanguage:currentSolution.defaultLanguage">
									<input name="lang-{{lang.language}}" placeholder="Category ({{lang.language}})" (keyup.enter)="suggestionsGoogleTranslate(lang.name)" (blur)="suggestionsGoogleTranslate(lang.name)" [required]="true" matInput [(ngModel)]="lang.name" />
								</mat-form-field>
							</div>
						</div>
					</div>
				</form>

                <!-- Sync -->
                <div class="column" *ngIf="syncSolutions.length > 0">
                    <div fxLayout="row" class="sub-section-header">
                        <h3>Sync</h3>
                    </div>
                    <div fxLayout="column">
                        <p class="text-discrete">
                            You can sync this Category to other Solutions.
                            Clicking the button below, you can choose which Solutions to sync to.
                        </p>
                        <div>
                            <button type="button" mat-raised-button (click)="openSyncDialog(currentCategory)">
                                <mat-icon>sync</mat-icon>
                                Sync to other Solutions
                            </button>
                        </div>
                    </div>
                </div>
			</div>
		</div>
	</div>

	<div fxFlex="<grow>" class="page-content" >
			<div *ngIf="categories && currentSolution">
				<table *ngIf="categories.length > 0 && !pageLoad" class="std-table" style="overflow: auto;">
					<tr class="sticky">
						<th></th>
						<th class="sortable" (click)="sortTable('displayName')" [class.active]="currentSortBy === 'displayName'">
							Display Name ({{currentSolution.defaultLanguage}})
							<span class="table-arrow" ><mat-icon [ngClass]="{'reverse-180': sortBy === '-displayName'}">keyboard_arrow_down</mat-icon></span>
						</th>
						<th>Key (Administrative ID)</th>
					</tr>
					<tr *ngFor="let category of categories | orderBy:sortBy:sortReverse" [class.highlight]="selectedRow == category.id">
						<td style="width: 40px; padding-right: 0px;">
							<button class="ga-categories-edit" (click)="editCategory(category)" mat-icon-button>
								<mat-icon>edit</mat-icon>
							</button>
						</td>
						<td>
							{{category.displayName}}
						</td>
						<td>
							{{category.key}}
						</td>
					</tr>
				</table>
				<div *ngIf="categories.length == 0">
					<p class="empty-state">No categories to show.</p>
				</div>
			</div>
			<div *ngIf="!categories">
				<p class="empty-state">No categories to show.</p>
			</div>
	</div>
</div>
