
	<div class="selector-input">
        <mat-icon class="business-icon" aria-label="solution icon" 
            (click)="$event.stopPropagation(); togglePanel(trigger)"
            *ngIf="buildings?.length > 1">
            location_city
        </mat-icon>
		<mat-icon class="carret-icon" aria-label="carret icon" (click)="$event.stopPropagation(); togglePanel(trigger)"
            *ngIf="buildings.length > 1">
			{{trigger.panelOpen ? 'arrow_drop_up' : 'arrow_drop_down'}}
		</mat-icon>
		<input id="building-selector-input" 
            class="autocomplete-input"
			type="text" spellcheck="false" autocomplete="off" autocorrect="off" autocapitalize="off"
			placeholder="Building" aria-label="Select Building"
			[formControl]="buildingInputControl" #trigger="matAutocompleteTrigger"
			[matAutocomplete]="auto" (focus)="togglePanel(trigger);" (click)="$event.target.select();"
            [attr.disabled]="buildings.length <= 1 ? 'disabled' : null">
	</div>
    
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayProperty" (optionSelected)="onOptionSelected($event.option.value)">
      <mat-option *ngFor="let building of buildings 
        | fuzzySearch:buildingSearchQuery:buildingSearchKeys:buildingSearchOptions" 
	  	[value]="building">
        {{building.displayName}}
      </mat-option>
    </mat-autocomplete>

