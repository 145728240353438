<section class="section section--area" [formGroup]="rotationForm">
    <label>Rotation (angle)</label>
    <div class="section__row">
        <label class="mi-label mi-label--inline">
            <input class="mi-input" type="range" formControlName="angle" (input)="onValueChanged($event)" [value]="rotationValue" min="-180" max="180" step="1">
        </label>
        <label class="mi-label">
            <input class="mi-input" type="number" formControlName="angle" (input)="onValueChanged($event)" [value]="rotationValue" min="-180" max="180">
            <small role="alert" class="mi-form__error"
                *ngIf="rotationForm.get('angle')?.hasError('min') || rotationForm.get('angle')?.hasError('max')">
                Enter a number between -180 and 180
            </small>
            <small role="alert" class="mi-form__error" *ngIf="rotationForm.get('angle')?.hasError('pattern')">
                Enter a whole number
            </small>
        </label>
    </div>
</section>