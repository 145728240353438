import { Component, OnInit, OnDestroy, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppUserRole } from '../../app-settings/config/app-user-roles/app-user-role.model';
import { AppUserRolesService } from '../../app-settings/config/app-user-roles/app-user-roles.service';
import { Accessibility } from '../enums';
import { primitiveClone } from '../../shared/object-helper';

@Component({
    selector: 'user-roles-restrictions-selector',
    templateUrl: './user-roles-restrictions-selector.component.html',
    styleUrls: ['./user-roles-restrictions-selector.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => UserRolesRestrictionsSelectorComponent),
        multi: true,
    }]
})
export class UserRolesRestrictionsSelectorComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() isInheritanceControlVisible = false;

    private _restrictions: string[] = [];
    private _appUserRoles: AppUserRole[] = [];
    public subscriptions = new Subscription();
    public originalRestriction;
    public accessibilityTypes = Accessibility;
    public restrictionsFormControl = new FormControl([]);

    public restrictionsForm = this.formBuilder.group({
        accessibilityType: [0, [Validators.required]],
        restrictions: this.formBuilder.array([])
    });

    public onChange: (restrictions: string[]) => void = () => { };
    public onTouch: () => void = () => { };

    constructor(
        private formBuilder: FormBuilder,
        private appUserRolesService: AppUserRolesService,
    ) { }

    /** NgOnInit. */
    ngOnInit(): void {
        this.restrictionsFormControl
            .setValue(this.getRestrictions(this.accessibilityTypeFormControl.value));

        this.appUserRolesService.appUserRoles$
            .subscribe(roles => {
                this._appUserRoles = roles;
                this.setRestrictionsFormArray(roles);
            });

        this.subscriptions
            // Subscribing to formControl's valueChanges - in case of Discarding the parent form (then the change is coming from outside)
            .add(this.restrictionsFormControl.valueChanges
                .subscribe(() => {
                    // Needs to be stringified because the value is an array (the order of items are always the same)
                    // It is executed when the user Discards changes on the parent form
                    if (JSON.stringify(this.restrictionsFormControl.value) !== JSON.stringify(this.getRestrictions(this.accessibilityTypeFormControl.value))) {
                        this.restrictionsForm.setValue(this.originalRestriction);
                    }
                }));
    }

    /** NgOnDestry. */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Returns the list of appUserRoles.
     *
     * @readonly
     * @type {AppUserRole[]}
     * @memberof UserRolesRestrictionsSelectorComponent
     */
    get appUserRoles(): AppUserRole[] {
        return this._appUserRoles;
    }

    /**
     * Get restrictions form array.
     *
     * @readonly
     * @type {FormArray}
     * @memberof UserRolesRestrictionsSelectorComponent
     */
    get restrictionsFormArray(): FormArray {
        return this.restrictionsForm.get('restrictions') as FormArray;
    }

    /**
     * Get accessibilityType form control.
     *
     * @readonly
     * @type {FormControl}
     * @memberof UserRolesRestrictionsSelectorComponent
     */
    get accessibilityTypeFormControl(): FormControl {
        return this.restrictionsForm.controls['accessibilityType'] as FormControl;
    }

    /**
     * Set the restrictions.
     *
     * @private
     * @param {string[]} restrictions
     * @memberof UserRolesRestrictionsSelectorComponent
     */
    private setRestrictions(restrictions: string[]): void {
        this._restrictions = restrictions;
        const accessibilityType = this.getAccessibilityType(restrictions);
        this.accessibilityTypeFormControl.setValue(accessibilityType);
        this.setRestrictionsFormArray(this._appUserRoles);
    }

    /**
     * Get accessibility type.
     *
     * @private
     * @param {string[]} restrictions
     * @returns {Accessibility}
     * @memberof UserRolesRestrictionsSelectorComponent
     */
    private getAccessibilityType(restrictions: string[]): Accessibility {
        if (!restrictions) {
            return this.isInheritanceControlVisible ? Accessibility.InheritFromType : Accessibility.OpenForAll;
        } else if (restrictions.includes('locked')) {
            return Accessibility.Closed;
        } else if (restrictions.length > 0) {
            return Accessibility.OpenForSome;
        }

        return Accessibility.OpenForAll;
    }

    /**
     * Set App User Roles as form controls in restrictions form array.
     *
     * @private
     * @param {AppUserRole[]} appUserRoles
     * @memberof UserRolesRestrictionsSelectorComponent
     */
    private setRestrictionsFormArray(appUserRoles: AppUserRole[]): void {
        this.restrictionsFormArray.clear();

        // Create restriction form controls
        appUserRoles.forEach((appUserRole) => {
            const checked = this._restrictions?.includes(appUserRole.id);
            this.restrictionsFormArray.push(this.formBuilder.control(checked));
        });

        // Fallback to accessibility type of "open for all" if no roles match with the specified restrictions
        if (this.accessibilityTypeFormControl.value === Accessibility.OpenForSome && !this.restrictionsFormArray.value.includes(true)) {
            this.accessibilityTypeFormControl.setValue(Accessibility.OpenForAll);
        }
    }

    /**
     * On change handler that sets the value of the restrictions formcontrol.
     *
     * @memberof UserRolesRestrictionsSelectorComponent
     */
    public onRestrictionsChange(): void {
        this.restrictionsFormControl.setValue(this.getRestrictions(this.accessibilityTypeFormControl.value));
        this.onChange(this.getRestrictions(this.accessibilityTypeFormControl.value));
    }


    /**
     * Returns a string/null depending on what users are provided.
     *
     * @param {Accessibility} accessibility
     * @returns {any}
     */
    private getRestrictions(accessibility: Accessibility): any {
        switch (accessibility) {
            case Accessibility.InheritFromType:
                return null;
            case Accessibility.OpenForAll:
                return [];
            case Accessibility.OpenForSome:
                return this.restrictionsForm.value.restrictions
                    .map((checked, i) => checked ? this._appUserRoles[i].id : null)
                    .filter(appUserRoleId => appUserRoleId !== null);
            case Accessibility.Closed:
                return ['locked'];
            default:
                break;
        }
    }

    /**
     * Writes a new value to the element.
     *
     * @param {string[]} restrictions
     * @memberof UserRolesRestrictionsSelectorComponent
     */
    writeValue(restrictions: string[]): void {
        if (!this.restrictionsForm.controls['accessibilityType']) {
            this.restrictionsFormControl.setValue(restrictions, { onlySelf: true });
        }
        this.setRestrictions(restrictions);
        this.originalRestriction = Object.freeze(primitiveClone(this.restrictionsForm.value));
    }

    /**
     * Registers a callback function that is called when the control's value changes in the UI.
     *
     * @memberof UserRolesRestrictionsSelectorComponent
     */
    registerOnChange(fn: (restrictions: string[]) => void): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that is called by the forms API on initialization to update the form model on blur.
     *
     * @memberof UserRolesRestrictionsSelectorComponent
     */
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }
}


