import { Component, OnInit, forwardRef, ViewChild, ElementRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

import { LocationType } from '../../location-types/location-type.model';
import { createDropdownItemElement, createLocationTypesDropdownItem } from '../../shared/mi-dropdown/mi-dropdown';
import { DisplayRuleService } from '../../services/DisplayRuleService/DisplayRuleService';
import { TypesService } from '../../services/types.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'location-type-dropdown',
    templateUrl: './location-type-dropdown.component.html',
    styleUrls: ['./location-type-dropdown.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => LocationTypeDropdownComponent),
        multi: true,
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => LocationTypeDropdownComponent),
        multi: true,
    }]
})
export class LocationTypeDropdownComponent implements ControlValueAccessor, OnInit, OnDestroy, Validator {
    @ViewChild('locationTypesDropdown', { static: true }) locationTypesDropdownElement: ElementRef<HTMLMiDropdownElement>;
    @Output() public displayRuleChanged: EventEmitter<any> = new EventEmitter();

    private types: LocationType[] = [];
    private selectedType: LocationType;
    public onChange: (administrativeId: string) => void = () => { };
    public onTouch: () => void = () => { };
    public onValidatorChange: () => void = () => { };
    private dropdownItems: HTMLMiDropdownItemElement[] = [];
    private subscriptions = new Subscription();
    public isFormValid = false;

    constructor(
        private typesService: TypesService,
        private displayRuleService: DisplayRuleService,
    ) { }

    /**
     * Angular lifecycle ngOnInit.
     */
    ngOnInit(): void {
        this.subscriptions
            .add(this.typesService.types.subscribe(async types => {
                this.types = types;
                let selectedDropdownItem: HTMLMiDropdownItemElement;
                const selectTypeDropdownItem = createDropdownItemElement('Select Type', null);
                const typeDropdownItems = new Array(this.types.length);

                for (const [index, type] of this.types.entries()) {
                    const displayRule = await this.displayRuleService.getDisplayRule(type.id);
                    typeDropdownItems[index] = createLocationTypesDropdownItem(type, displayRule?.icon);
                    if (this.selectedType === type) {
                        selectedDropdownItem = typeDropdownItems[index];
                        selectedDropdownItem.selected = true;
                    }
                }

                this.dropdownItems = [].concat(selectTypeDropdownItem, typeDropdownItems);
                this.locationTypesDropdownElement.nativeElement.items = this.dropdownItems;

                this.setSelectedDropdownItem(selectedDropdownItem ?? selectTypeDropdownItem, this.selectedType);

                if (selectedDropdownItem) {
                    this.onChange(this.selectedType?.administrativeId);
                    const displayRule = await this.displayRuleService.getDisplayRule(this.selectedType?.id);
                    this.displayRuleChanged.emit(displayRule);
                    this.onValidatorChange();
                }
            }));
    }

    /**
     * Angular lifecycle ngOnDestroy.
     */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Get icon display rule from the type or main display rule.
     *
     * @param {LocationType} locationType
     * @returns {string}
     */
    private async getDisplayIcon(locationType: LocationType): Promise<string> {
        const displayRule = await this.displayRuleService.getDisplayRule(locationType?.id);
        return locationType ? locationType?.displayRule?.icon ?? displayRule?.icon : null;
    }

    /**
     * Set display icon to the dropdown items.
     *
     * @param {LocationType} selectedType
     */
    private async setDisplayIcon(selectedType: LocationType): Promise<void> {
        this.locationTypesDropdownElement.nativeElement.iconSrc = await this.getDisplayIcon(selectedType);
    }

    /**
     * On change handler that sets the value of the type formcontrol.
     *
     * @param {CustomEvent} detail
     * @memberof LocationDetailsComponent
     */
    public async onLocationTypesDropdownChange({ detail }: CustomEvent): Promise<void> {
        const typeId = (detail as HTMLMiDropdownItemElement[])?.map(item => item.value).toString();
        const selectedType = this.types.find(type => type.id === typeId);
        this.setDisplayIcon(selectedType);
        this.onChange(selectedType?.administrativeId);
        this.onValidatorChange();

        const displayRule = await this.displayRuleService.getDisplayRule(typeId);
        this.displayRuleChanged.emit(displayRule);
    }

    /**
     * Set the selected dropdown item and its corresponding icon.
     *
     * @param {string} selectedDropdownItem
     * @param {LocationType} selectedType
     * @memberof LocationDetailsComponent
     */
    public setSelectedDropdownItem(selectedDropdownItem, selectedType): void {
        selectedDropdownItem.selected = true;
        this.locationTypesDropdownElement.nativeElement.selected = [selectedDropdownItem];
        this.setDisplayIcon(selectedType);
    }

    /**
     * Writes a new value to the element.
     *
     * @param {string} administrativeId
     * @memberof LocationTypeDropdownComponent
     */
    writeValue(administrativeId: string): void {
        this.selectedType = this.types.find(type => type.administrativeId === administrativeId);
        if (this.dropdownItems?.length > 0) {
            const selectedDropdownItem = this.dropdownItems?.find(item => item.value === this.selectedType?.id);
            if (selectedDropdownItem) {
                selectedDropdownItem.selected = true;
                this.setSelectedDropdownItem(selectedDropdownItem, this.selectedType);
            } else {
                this.setSelectedDropdownItem(this.dropdownItems?.[0], this.selectedType);
            }
        }
    }

    /**
     * Registers a callback function that is called when the control's value changes in the UI.
     *
     * @memberof LocationTypeDropdownComponent
     */
    registerOnChange(fn: (administrativeId: string) => void): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that is called by the forms API on initialization to update the form model on blur.
     *
     * @memberof LocationTypeDropdownComponent
     */
    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    /**
     * Method that is being called when the control value changes.
     *
     * @memberof LocationAliasesComponent
     * @returns {ValidationErrors}
     */
    validate(): ValidationErrors {
        this.isFormValid = this.locationTypesDropdownElement.nativeElement.selected?.[0] !== this.dropdownItems?.[0];
        return this.isFormValid ? null : { invalid: true };
    }

    /**
     * Registers a callback function that is called when the validation changes.
     *
     * @memberof LocationTypeDropdownComponent
     */
    registerOnValidatorChange?(fn: () => void): void {
        this.onValidatorChange = fn;
    }
}
