import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { NewVenueComponent } from './new-venue.component';

@NgModule({
    declarations: [NewVenueComponent],
    imports: [
        SharedModule
    ],
    providers: []
})
export class NewVenueModule { }
