import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ExternalApiKeys, ExternalApis } from '../../shared/enums';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';

import { ExtendedLocation } from '../../locations/location.service';
import { NotificationService } from '../../services/notification.service';
import { StreetViewConfig } from '../../locations/location.model';
import { StreetViewModalComponent } from './street-view-modal/street-view-modal.component';

@Component({
    selector: 'image-street-view',
    templateUrl: './street-view.component.html',
    styleUrls: ['./street-view.component.scss']
})
export class StreetViewComponent implements OnInit, OnDestroy {
    private _streetViewConfig: StreetViewConfig;
    private subscriptions = new Subscription();
    private streetViewService = new google.maps.StreetViewService();
    public staticPanoUrl: string;

    /**
     * Sets the streetViewConfig and the StaticPanoramaUrl.
     */
    @Input() set streetViewConfig (streetViewConfig: StreetViewConfig) {
        this._streetViewConfig = streetViewConfig;
        this.setStaticPanoramaUrl(streetViewConfig);
    }
    @Input() type: string;
    @Input() location: ExtendedLocation;
    @Input() reset: Subject<any>;

    @Output() updatePanorama: EventEmitter<StreetViewConfig> = new EventEmitter();
    @Output() snackBarMessage: EventEmitter<string> = new EventEmitter();

    /**
     * Returns the StreetViewConfig.
     *
     * @returns {StreetViewConfig}
     */
    get streetViewConfig(): StreetViewConfig {
        return this._streetViewConfig;
    }

    constructor(
        private notificationService: NotificationService,
        public dialog: MatDialog
    ) { }

    /**
     * NgOnInit.
     */
    ngOnInit(): void {
        this.subscriptions.add(
            this.reset.subscribe(formValue => {
                this.setStaticPanoramaUrl(formValue.streetViewConfig);
            }));
    }

    /**
     * NgOnDestroy.
     */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Set panorama url.
     *
     * @private
     * @param {StreetViewConfig} streetViewConfig
     * @memberof StreetViewComponent
     */
    private setStaticPanoramaUrl(streetViewConfig: StreetViewConfig): void {
        this.staticPanoUrl = streetViewConfig?.panoramaId
            ? `${ExternalApis.GoogleStreetView}?size=456x300&pano=${streetViewConfig.panoramaId}&heading=${streetViewConfig.povHeading}&pitch=${streetViewConfig.povPitch}&key=${ExternalApiKeys.GoogleGeneralKey}`
            : null;
    }

    /**
     * Open Street View modal.
     *
     * @param {object} data	- The object should contain panorama id, POV and coordinates – the required parameters to display Street View and mini map.
     * @fires result		The panorama configurations.
     */
    private openModal(data: object): void {
        const dialogConfig: MatDialogConfig = {
            disableClose: true,
            width: '80vw',
            data: data,
            panelClass: 'details-dialog'
        };

        const dialogRef = this.dialog.open(StreetViewModalComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.staticPanoUrl = `${ExternalApis.GoogleStreetView}?size=456x300&pano=${result.id}&heading=${result.pov.heading}&pitch=${result.pov.pitch}&key=AIzaSyAfEnWA1TSDqp1jpzYkgF-r65c4MkA5ZZw`;
                const config: StreetViewConfig = {
                    panoramaId: result.id,
                    povHeading: result.pov.heading,
                    povPitch: result.pov.pitch
                };
                this.updatePanorama.emit(config);
                this.streetViewConfig = config;
            }
        });
    }

    /**
     * Checks if panorama data are available and opens a modal if so otherwise emitting a error.
     *
     * @returns {void}
     * @memberof StreetViewComponent
     */
    public setPanorama(): void {
        if (!this.location?.id && ['Polygon', 'MultiPolygon'].includes(this.location?.geometry?.type)) {
            this.notificationService.showWarning('Save the location before setting Street View image');
            return;
        }
        const coordinates = new google.maps.LatLng(this.location?.anchor?.coordinates[1], this.location?.anchor?.coordinates[0]);
        const radius: number = 10;
        this.streetViewService.getPanorama({ location: coordinates, radius: radius }, (data, status) => {
            if (status === google.maps.StreetViewStatus.OK) {
                this.openModal({
                    streetViewConfig: { panoramaId: data.location.pano },
                    coordinates: new google.maps.LatLng(data.location.latLng.lat(), data.location.latLng.lng())
                });
            } else this.snackBarMessage.emit(`Street View data not found in a radius of ${radius.toString()} meters.`);
        });
    }

    /**
     * Passing data to openModal() function.
     */
    public editPanorama(): void {
        const coordinates = new google.maps.LatLng(this.location?.anchor?.coordinates[1], this.location?.anchor?.coordinates[0]);
        this.openModal({
            streetViewConfig: this.streetViewConfig,
            coordinates: coordinates
        });
    }

    /**
     * Delete the streetview configurations.
     *
     * @memberof StreetViewComponent
     */
    public deletePanorama(): void {
        this.staticPanoUrl = null;
        this.updatePanorama.emit(null);
    }
}
