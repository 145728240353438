import { Injectable } from '@angular/core';
import { CanActivate, Router, CanActivateChild, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { UserService } from '../services/user.service';
import { NotificationService } from '../services/notification.service';
import { User } from '../user.model';
import { environment } from '../../environments/environment';

@Injectable()
export class OwnerGuard implements CanActivate, CanActivateChild {
    constructor(
        private userService: UserService,
        private router: Router,
        private notificationService: NotificationService) { }

    canActivate(): Observable<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivateComponent();
    }

    canActivateChild(): Observable<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivateComponent();
    }

    /**
     * Determine if the current user is allowed to activate the component.
     *
     * @private
     * @returns {(Observable<boolean> | Promise<boolean> | boolean)}
     * @memberof AdminGuard
     */
    private canActivateComponent(): Observable<boolean | UrlTree> | boolean | UrlTree {
        const currentUser = this.userService.getCurrentUser();
        if (currentUser) {
            return this.hasOwnerRole(currentUser);
        } else {
            return this.userService.getUser() // necessary when the page is reloaded
                .pipe(
                    map(user => this.hasOwnerRole(user)),
                    catchError(error => {
                        this.notificationService.showError(error);
                        return of(this.router.createUrlTree([environment.startPage]));
                    })
                );
        }
    }

    /**
     * Check if the user is an admin or owner, else navigate to locations.
     *
     * @private
     * @param {User} user
     * @returns {boolean}
     * @memberof AdminGuard
     */
    private hasOwnerRole(user: User): boolean | UrlTree {
        if (user && user.roles && user.roles.includes('owner')) {
            return true;
        }
        // Redirect with UrlTree to prevent race conditions in some cases where multiple canActivate guards will redirect.
        // With UrlTree, the highest priority guard wins.
        return this.router.createUrlTree([environment.startPage]);
    }
}
