<form [formGroup]="displayRuleEditorForm">
    <navigatable-section header="General">
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('visible').disabled"
            (click)="unlockSetting($event, 'visible')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule" [locked]="getFormControl('visible').disabled"
                (toggled)="toggleInheritance('visible', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="visible">Visibility</label>
                <info-tooltip class="tooltip"
                    text="Controls whether all Locations of this Type are visible on the map or not. If set to Off, the Icon, Label, Polygon, and 3D information are not visible.">
                </info-tooltip>
            </div>
            <div class="setting__control">
                <input id="visible" class="mi-toggle" type="checkbox" formControlName="visible" />
            </div>
        </div>
    </navigatable-section>

    <div class="message message--warning" role="note" *ngIf="!isMainDisplayRule">
        Your app code needs to use Display Rules, not hardcoded overrides, for your style changes here to apply.<br /><a
            href="https://docs.mapsindoors.com/content/map/map-styling/display-rules/" target="_blank">Read more about
            Display Rules in our docs</a>.
    </div>

    <navigatable-section header="Icon"
        documentationLink="https://docs.mapsindoors.com/content/map/map-styling/display-rules/#icon">
        <!-- Icon visibility -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('iconVisible').disabled"
            (click)="unlockSetting($event, 'iconVisible')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('iconVisible').disabled" (toggled)="toggleInheritance('iconVisible', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="icon-visible">Visibility</label>
                <info-tooltip class="tooltip" text="Controls whether the Icon is visible on the map or not.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <input id="icon-visible" class="mi-toggle" type="checkbox" formControlName="iconVisible" />
            </div>
        </div>

        <!-- Icon zoom from -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('zoomFrom').disabled"
            (click)="unlockSetting($event, 'zoomFrom')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule" [locked]="getFormControl('zoomFrom').disabled"
                (toggled)="toggleInheritance('zoomFrom', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="icon-zoom-from">Zoom from</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the Icon is visible."></info-tooltip>
            </div>

            <div class="setting__control">
                <input id="icon-zoom-from" class="mi-input" type="number" formControlName="zoomFrom">
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('zoomFrom').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('zoomFrom').hasError('min')">
                    "Zoom from" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('zoomFrom').hasError('max')">
                    "Zoom from" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('zoomFrom').hasError('aboveThreshold')">
                    "Zoom from" must be lower than "zoom to". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- Icon zoom to -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('zoomTo').disabled"
            (click)="unlockSetting($event, 'zoomTo')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule" [locked]="getFormControl('zoomTo').disabled"
                (toggled)="toggleInheritance('zoomTo', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="icon-zoom-to">Zoom to</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the Icon is visible."></info-tooltip>
            </div>

            <div class="setting__control">
                <input id="icon-zoom-to" class="mi-input" type="number" formControlName="zoomTo">
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('zoomTo').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('zoomTo').hasError('min')">
                    "Zoom to" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('zoomTo').hasError('max')">
                    "Zoom to" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('zoomTo').hasError('belowThreshold')">
                    "Zoom to" must be larger than "zoom from". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- Media Library for Icon selection -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('icon').disabled"
            (click)="unlockSetting($event, 'icon')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule" [locked]="getFormControl('icon').disabled"
                (toggled)="toggleInheritance('icon', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label">Icon</label>
                <info-tooltip class="tooltip" text="Controls which Icon is shown on the map."></info-tooltip>
            </div>

            <div class="setting__control setting__control--icon-manager">
                <img [src]="getFormControl('icon').value" />
                <button type="button" [disabled]="getFormControl('icon').disabled"
                    class="mi-button mi-button--small mi-button--base" (click)="openMediaLibraryForIcon()">Open Media
                    Library</button>
            </div>
        </div>
    </navigatable-section>

    <navigatable-section header="Label"
        documentationLink="https://docs.mapsindoors.com/content/map/map-styling/display-rules/#label">
        <!-- Label visibility -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('labelVisible').disabled"
            (click)="unlockSetting($event, 'labelVisible')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('labelVisible').disabled"
                (toggled)="toggleInheritance('labelVisible', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="label-visible">Visibility</label>
                <info-tooltip class="tooltip" text="Controls whether the Label is visible on the Map or not.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <input id="label-visible" class="mi-toggle" type="checkbox" formControlName="labelVisible" />
            </div>
        </div>

        <!-- Label zoom from -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('labelZoomFrom').disabled"
            (click)="unlockSetting($event, 'labelZoomFrom')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('labelZoomFrom').disabled"
                (toggled)="toggleInheritance('labelZoomFrom', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="label-zoom-from">Zoom from</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the Label is visible."></info-tooltip>
            </div>

            <div class="setting__control">
                <input id="label-zoom-from" class="mi-input" type="number" formControlName="labelZoomFrom">
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('labelZoomFrom').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('labelZoomFrom').hasError('min')">
                    "Zoom from" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('labelZoomFrom').hasError('max')">
                    "Zoom from" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('labelZoomFrom').hasError('aboveThreshold')">
                    "Zoom from" must be lower than "zoom to". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- Label zoom to -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('labelZoomTo').disabled"
            (click)="unlockSetting($event, 'labelZoomTo')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('labelZoomTo').disabled" (toggled)="toggleInheritance('labelZoomTo', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="label-zoom-to">Zoom to</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the Label is visible."></info-tooltip>
            </div>

            <div class="setting__control">
                <input id="label-zoom-to" class="mi-input" type="number" formControlName="labelZoomTo">
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('labelZoomTo').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('labelZoomTo').hasError('min')">
                    "Zoom to" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('labelZoomTo').hasError('max')">
                    "Zoom to" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('labelZoomTo').hasError('belowThreshold')">
                    "Zoom to" must be larger than "zoom from". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- Label name -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('label').disabled"
            (click)="unlockSetting($event, 'label')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule" [locked]="getFormControl('label').disabled"
                (toggled)="toggleInheritance('label', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label">Template</label>
                <info-tooltip class="tooltip" text="Controls the information the Label should contain."></info-tooltip>
            </div>

            <div class="setting__control">
                <!-- TODO: Make sure that disabled is needed here. -->
                <mi-dropdown #labelNameDropdown [disabled]="getFormControl('label').disabled"
                    (change)="onLabelFormatDropdownChange($event)"></mi-dropdown>
            </div>
        </div>

        <!-- Label max width -->
        <div class="setting label-max-width" lockedDisplayRuleHandler
            [isLocked]="getFormControl('labelMaxWidth').disabled" (click)="unlockSetting($event, 'labelMaxWidth')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('labelMaxWidth').disabled" (toggled)="toggleLabelMaxWidthInheritance($event);">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="label-max-width-visible">Max width</label>
                <info-tooltip class="tooltip" text="Controls how wide a Label can be before forcing a line-break.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <div class="toggle-container">
                    <input id="label-max-width-visible" type="checkbox" class="mi-toggle"
                        [disabled]="getFormControl('labelMaxWidth').disabled" [checked]="labelMaxWidthVisible"
                        (change)="toggleLabelMaxWidthVisible()" />
                    <span>Specify maximum Label width.</span>
                </div>

                <input *ngIf="labelMaxWidthVisible" class="mi-input" type="number" min="1"
                    [value]="getFormControl('labelMaxWidth').value" (change)="setlabelMaxWidthValue($event)">
                <input #labelMaxWidthVisibleInput type="hidden" formControlName="labelMaxWidth">
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('labelMaxWidth').hasError('min') ||  getFormControl('labelMaxWidth').hasError('pattern')">
                    Label maximum width must be above 0. No decimal numbers.
                </small>
            </div>
        </div>
    </navigatable-section>

    <navigatable-section header="Polygon" formGroupName="polygon" *ngIf="isGeometrySettingsVisible"
        documentationLink="https://docs.mapsindoors.com/content/map/map-styling/display-rules/#polygon">
        <!-- Polygon visibility -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('polygon.visible').disabled"
            (click)="unlockSetting($event, 'polygon.visible')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('polygon.visible').disabled"
                (toggled)="toggleInheritance('polygon.visible', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="polygon-visible">Visibility</label>
                <info-tooltip class="tooltip"
                    text="Controls whether the Polygon information is visible on the map or not."></info-tooltip>
            </div>

            <div class="setting__control">
                <input id="polygon-visible" class="mi-toggle" type="checkbox" formControlName="visible" />
            </div>
        </div>

        <!-- Polygon zoom from -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('polygon.zoomFrom').disabled"
            (click)="unlockSetting($event, 'polygon.zoomFrom')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('polygon.zoomFrom').disabled"
                (toggled)="toggleInheritance('polygon.zoomFrom', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="polygon-zoom-from">Zoom from</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the Polygon is visible.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <input id="polygon-zoom-from" class="mi-input" type="number" formControlName="zoomFrom">
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('polygon.zoomFrom').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('polygon.zoomFrom').hasError('min')">
                    "Zoom from" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('polygon.zoomFrom').hasError('max')">
                    "Zoom from" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('polygon.zoomFrom').hasError('aboveThreshold')">
                    "Zoom from" must be lower than "zoom to". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- Polygon zoom to -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('polygon.zoomTo').disabled"
            (click)="unlockSetting($event, 'polygon.zoomTo')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('polygon.zoomTo').disabled"
                (toggled)="toggleInheritance('polygon.zoomTo', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="polygon-zoom-to">Zoom to</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the Polygon is visible.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <input id="polygon-zoom-to" class="mi-input" type="number" formControlName="zoomTo">
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('polygon.zoomTo').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('polygon.zoomTo').hasError('min')">
                    "Zoom to" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('polygon.zoomTo').hasError('max')">
                    "Zoom to" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('polygon.zoomTo').hasError('belowThreshold')">
                    "Zoom to" must be larger than "zoom from". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- Polygon stroke color -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('polygon.strokeColor').disabled"
            (click)="unlockSetting($event, 'polygon.strokeColor')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('polygon.strokeColor').disabled"
                (toggled)="toggleInheritance('polygon.strokeColor', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="polygon-stroke-color">Stroke color</label>
                <info-tooltip class="tooltip" text="Controls the stroke color of the Polygon."></info-tooltip>
            </div>

            <div class="color-control">
                <div class="mi-input__wrapper mi-input__wrapper--with-units">
                    <input type="text" formControlName="strokeColor" id="polygon-stroke-color" class="mi-input" maxlength="7" [value]="getFormControl('polygon.strokeColor').value">
                    <input type="color" formControlName="strokeColor" [value]="getFormControl('polygon.strokeColor').value" />
                </div>
            </div>
        </div>

        <!-- Polygon stroke width -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('polygon.strokeWidth').disabled"
            (click)="unlockSetting($event, 'polygon.strokeWidth')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('polygon.strokeWidth').disabled"
                (toggled)="toggleInheritance('polygon.strokeWidth', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="polygon-stroke-width">Stroke width</label>
                <info-tooltip class="tooltip" text="Controls the stroke width of the Polygon."></info-tooltip>
            </div>

            <div class="setting__control">
                <div class="mi-input__wrapper mi-input__wrapper--with-units" data-units="px"
                    [ngClass]="{'disabled' :getFormControl('polygon.strokeWidth').disabled }">
                    <input id="polygon-stroke-width" class="mi-input" type="number" formControlName="strokeWidth">
                </div>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('polygon.strokeWidth').hasError('min') || getFormControl('polygon.strokeWidth').hasError('pattern') || getFormControl('polygon.strokeWidth').hasError('required')">
                    Stroke width must be above 0. No decimal numbers.
                </small>
            </div>
        </div>

        <!-- Polygon stroke opacity -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('polygon.strokeOpacity').disabled"
            (click)="unlockSetting($event, 'polygon.strokeOpacity')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('polygon.strokeOpacity').disabled"
                (toggled)="toggleInheritance('polygon.strokeOpacity', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="polygon-stroke-opacity">Stroke opacity</label>
                <info-tooltip class="tooltip" text="Controls the stroke opacity of the Polygon."></info-tooltip>
            </div>

            <div class="setting__control">
                <input id="polygon-stroke-opacity" class="mi-input" type="number" step="0.1"
                    formControlName="strokeOpacity">
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('polygon.strokeOpacity').hasError('min') || getFormControl('polygon.strokeOpacity').hasError('max') || getFormControl('polygon.strokeOpacity').hasError('required')">
                    Stroke opacity must be a number between 0 and 1.
                </small>
            </div>
        </div>

        <!-- Polygon fill color -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('polygon.fillColor').disabled"
            (click)="unlockSetting($event, 'polygon.fillColor')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('polygon.fillColor').disabled"
                (toggled)="toggleInheritance('polygon.fillColor', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="polygon-fill-color">Fill color</label>
                <info-tooltip class="tooltip" text="Controls fill opacity of the Polygon."></info-tooltip>
            </div>

            <div class="color-control">
                <div class="mi-input__wrapper mi-input__wrapper--with-units">
                    <input type="text" formControlName="fillColor" id="polygon-fill-color" class="mi-input" maxlength="7" [value]="getFormControl('polygon.fillColor').value">
                    <input type="color" formControlName="fillColor" [value]="getFormControl('polygon.fillColor').value" />
                </div>
            </div>
        </div>

        <!-- Polygon fill opacity -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('polygon.fillOpacity').disabled"
            (click)="unlockSetting($event, 'polygon.fillOpacity')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('polygon.fillOpacity').disabled"
                (toggled)="toggleInheritance('polygon.fillOpacity', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="polygon-fill-opacity">Fill opacity</label>
                <info-tooltip class="tooltip" text="Controls the fill opacity of the Polygon."></info-tooltip>
            </div>

            <div class="setting__control">
                <input id="polygon-fill-opacity" class="mi-input" type="number" step="0.1"
                    formControlName="fillOpacity">
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('polygon.fillOpacity').hasError('min') || getFormControl('polygon.fillOpacity').hasError('max') || getFormControl('polygon.fillOpacity').hasError('required')">
                    Fill opacity must be between 0 and 1.
                </small>
            </div>
        </div>
    </navigatable-section>

    <navigatable-section header="2D Model" *ngIf="model2DModuleEnabled" formGroupName="model2D">
        <!-- 2D Model visibility -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('model2D.visible').disabled"
            (click)="unlockSetting($event, 'model2D.visible')">
            <lock-button *ngIf="!isMainDisplayRule" [locked]="getFormControl('model2D.visible').disabled"
                (toggled)="toggleInheritance('model2D.visible', $event)">
            </lock-button>
            <div class="setting__label">
                <label class="mi-label" for="model2D-visible">Visibility</label>
                <info-tooltip class="tooltip" text="Controls whether the 2D Model is visible on the map or not.">
                </info-tooltip>
            </div>
            <div class="setting__control">
                <input id="model2D-visible" class="mi-toggle" type="checkbox" formControlName="visible" />
            </div>
        </div>

        <!-- 2D Model Zoom From -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('model2D.zoomFrom').disabled"
            (click)="unlockSetting($event, 'model2D.zoomFrom')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('model2D.zoomFrom').disabled"
                (toggled)="toggleInheritance('model2D.zoomFrom', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="model2d-zoom-from">Zoom from</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the 2D Model is visible.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <input id="model2d-zoom-from" class="mi-input" type="number" formControlName="zoomFrom">
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('model2D.zoomFrom').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('model2D.zoomFrom').hasError('min')">
                    "Zoom from" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('model2D.zoomTo').hasError('max')">
                    "Zoom from" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('model2D.zoomFrom').hasError('aboveThreshold')">
                    "Zoom from" must be lower than "zoom to". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- 2D Model Zoom To -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('model2D.zoomTo').disabled"
            (click)="unlockSetting($event, 'model2D.zoomTo')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('model2D.zoomTo').disabled"
                (toggled)="toggleInheritance('model2D.zoomTo', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="model2d-zoom-to">Zoom to</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the 2D Model is visible.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <input id="model2d-zoom-to" class="mi-input" type="number" formControlName="zoomTo">
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('model2D.zoomTo').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('model2D.zoomTo').hasError('min')">
                    "Zoom to" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('model2D.zoomTo').hasError('max')">
                    "Zoom to" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('model2D.zoomTo').hasError('belowThreshold')">
                    "Zoom to" must be larger than "zoom from". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- 2D Model Media Library -->
        <div class="setting" lockedDisplayRuleHandler
            [isLocked]="getFormControl('model2D.model').disabled" (click)="unlockSetting($event, 'model2D.model')">
            <lock-button class="setting__lock setting__lock--full-height" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('model2D.model').disabled"
                (toggled)="toggleInheritance('model2D.model', $event)">
            </lock-button>
            <div class="setting__label setting__label--baseline">
                <label class="mi-label">2D Model</label>
                <info-tooltip class="tooltip" text="Controls which image is used as 2D Model on the map.">
                </info-tooltip>
            </div>

            <div *ngIf="getFormControl('model2D.model').value" class="setting__control setting__control--direction-column">
                <div class="model2d">
                    <div class="model2d__preview">
                        <ngx-spinner name="model2d-spinner" [fullScreen]="false" type="ball-pulse"></ngx-spinner>
                        <img responsiveImageStyle class="model2d__preview-image" #model2d__preview [src]="model2DPreview?.modelData">
                        <p class="model2d__name">{{ model2DPreview?.name }}</p>
                    </div>
                    <input type="hidden" formControlName="model">

                    <div class="model2d__buttons">
                        <button [disabled]="getFormControl('model2D.model').disabled"
                            (click)="openMediaLibraryFor2DModel()" class="mi-button mi-button--base">
                            Change
                        </button>
                        <button *ngIf="isMainDisplayRule" class="mi-button mi-button--remove" (click)="removeModel2D()">
                            Remove
                        </button>
                        <button *ngIf="isFit2DModelEnabled" class="mi-button mi-button--base" (click)="fitModel2D()">
                            Fit to Location
                        </button>
                    </div>

                    <div class="setting__group setting__group--top-spacing setting__group--align-items-end">
                        <div class="setting__control setting__control--direction-column">
                            <div class="setting__label setting__label--small-bottom-spacing">
                                <label class="mi-label mi-label--small" for="model2D-width">Width</label>
                                <info-tooltip class="tooltip tooltip--no-margin" text="Controls the width of the 2D model. Editing the width will change the height accordingly as well."></info-tooltip>
                            </div>
                            <div class="mi-input__wrapper mi-input__wrapper--with-units" data-units="m" [ngClass]="{disabled: getFormControl('model2D.widthMeters').disabled}">
                                <input id="model2D-width" class="mi-input" type="number" step="0.01" formControlName="widthMeters" />
                            </div>
                        </div>
                        <img class="setting__icon" src="/assets/images/link.svg" />
                        <div class="setting__control setting__control--direction-column">
                            <div class="setting__label setting__label--small-bottom-spacing">
                                <label class="mi-label mi-label--small" for="model2D-height">Height</label>
                                <info-tooltip class="tooltip tooltip--no-margin" text="Controls the height of the 2D model. Editing the height will change the width accordingly as well."></info-tooltip>
                            </div>
                            <div class="mi-input__wrapper mi-input__wrapper--with-units" data-units="m" [ngClass]="{disabled: getFormControl('model2D.heightMeters').disabled}">
                                <input id="model2D-height" class="mi-input" type="number" step="0.01" formControlName="heightMeters" />
                            </div>
                        </div>

                        <button [disabled]="model2DResetSizeDisabled()" (click)="reset2DModelOriginalSize()" class="mi-button mi-button--base">
                            Reset size
                        </button>
                    </div>
                </div>
            </div>

            <div class="setting__control" *ngIf="!getFormControl('model2D.model').value">
                <button type="button" [disabled]="getFormControl('model2D.model').disabled" (click)="openMediaLibraryFor2DModel()" class="mi-button mi-button--base model2d__button">
                    Add 2D model
                </button>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('model2D.widthMeters').hasError('min') || getFormControl('model2D.widthMeters').hasError('required')">
                    2D Model Width must be above 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('model2D.heightMeters').hasError('min') || getFormControl('model2D.heightMeters').hasError('pattern') || getFormControl('model2D.heightMeters').hasError('required')">
                    2D Model Height must be above 0.
                </small>
            </div>
        </div>

        <!-- 2D Model Bearing (rotation) -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('model2D.bearing').disabled"
            (click)="unlockSetting($event, 'model2D.bearing')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('model2D.bearing').disabled"
                (toggled)="toggleInheritance('model2D.bearing', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="2DModel-bearing">Bearing (rotation)</label>
                <info-tooltip class="tooltip" text="Controls the 2D Model Bearing rotation."></info-tooltip>
            </div>

            <div class="setting__control">
                <div class="mi-input__wrapper mi-input__wrapper--with-units" data-units="°"
                    [ngClass]="{'disabled' :getFormControl('model2D.bearing').disabled }">
                    <input id="2DModel-bearing" class="mi-input" type="number" step="any" min="0" formControlName="bearing">
                </div>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('model2D.bearing').hasError('min') || getFormControl('model2D.bearing').hasError('isBiggerThanLimit') || getFormControl('model2D.bearing').hasError('required')">
                    2D model Bearing (rotation) must be between 0 and 360.
                </small>
            </div>
        </div>
    </navigatable-section>

    <navigatable-section header="3D Walls" formGroupName="walls"
        *ngIf="isGeometrySettingsVisible && wallsModuleEnabled && is3DWallsSectionVisible">
        <!-- Walls visibility -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('walls.visible').disabled"
            (click)="unlockSetting($event, 'walls.visible')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('walls.visible').disabled"
                (toggled)="toggleInheritance('walls.visible', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="walls-visible">Visibility</label>
                <info-tooltip class="tooltip" text="Controls whether the 3D Walls are visible on the map or not.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <input id="walls-visible" class="mi-toggle" type="checkbox" formControlName="visible" />
            </div>
        </div>

        <!-- Walls zoom from -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('walls.zoomFrom').disabled"
            (click)="unlockSetting($event, 'walls.zoomFrom')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('walls.zoomFrom').disabled"
                (toggled)="toggleInheritance('walls.zoomFrom', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="walls-zoom-from">Zoom from</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the Walls is visible."></info-tooltip>
            </div>

            <div class="setting__control">
                <input id="walls-zoom-from" class="mi-input" type="number" formControlName="zoomFrom">
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('walls.zoomFrom').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('walls.zoomFrom').hasError('min')">
                    "Zoom from" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('walls.zoomFrom').hasError('max')">
                    "Zoom from" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('walls.zoomFrom').hasError('aboveThreshold')">
                    "Zoom from" must be lower than "zoom to". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- Walls zoom to -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('walls.zoomTo').disabled"
            (click)="unlockSetting($event, 'walls.zoomTo')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('walls.zoomTo').disabled"
                (toggled)="toggleInheritance('walls.zoomTo', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="walls-zoom-to">Zoom to</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the Walls are visible.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <input id="walls-zoom-to" class="mi-input" type="number" formControlName="zoomTo">
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('walls.zoomTo').hasError('required')">
                    Zoom levels must be between 1 and {{ maxZoomLevel }}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('walls.zoomTo').hasError('max')">
                    "Zoom to" cannot be larger than {{ maxZoomLevel }}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('walls.zoomTo').hasError('belowThreshold')">
                    "Zoom to" must be larger than "zoom from". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- Walls color -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('walls.color').disabled"
            (click)="unlockSetting($event, 'walls.color')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('walls.color').disabled" (toggled)="toggleInheritance('walls.color', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="walls-color">Walls color</label>
                <info-tooltip class="tooltip" text="Controls the color of the 3D Walls."></info-tooltip>
            </div>

            <div class="color-control">
                <div class="mi-input__wrapper mi-input__wrapper--with-units">
                    <input type="text" formControlName="color" id="walls-color" class="mi-input" maxlength="7" [value]="getFormControl('walls.color').value">
                    <input type="color" formControlName="color" [value]="getFormControl('walls.color').value" />
                </div>
            </div>
        </div>

        <!-- Wall height -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('walls.height').disabled"
            (click)="unlockSetting($event, 'walls.height')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('walls.height').disabled"
                (toggled)="toggleInheritance('walls.height', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="walls-height">Wall height</label>
                <info-tooltip class="tooltip" text="Controls the height of the 3D Walls (measured in meters).">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <div class="mi-input__wrapper mi-input__wrapper--with-units" data-units="m"
                    [ngClass]="{'disabled' :getFormControl('walls.height').disabled }">
                    <input id="walls-height" class="setting__control mi-input" type="number" step="0.1"
                        formControlName="height">
                </div>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('walls.height').hasError('min') || getFormControl('walls.height').hasError('required')">
                    Wall height must be 0 or above.
                </small>
            </div>
        </div>
    </navigatable-section>
    <navigatable-section header="3D Room Extrusion" formGroupName="extrusion"
        *ngIf="isGeometrySettingsVisible && extrusionsModuleEnabled">
        <!-- Extrusion visibility -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('extrusion.visible').disabled"
            (click)="unlockSetting($event, 'extrusion.visible')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('extrusion.visible').disabled"
                (toggled)="toggleInheritance('extrusion.visible', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="extrusion-visible">Visibility</label>
                <info-tooltip class="tooltip" text="Controls whether the Room is extruded on the Map or not.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <input id="extrusion-visible" class="mi-toggle" type="checkbox" formControlName="visible" />
            </div>
        </div>

        <!-- Extrusion zoom from -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('extrusion.zoomFrom').disabled"
            (click)="unlockSetting($event, 'extrusion.zoomFrom')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('extrusion.zoomFrom').disabled"
                (toggled)="toggleInheritance('extrusion.zoomFrom', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="extrusion-zoom-from">Zoom from</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the Extrusion is visible.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <input id="extrusion-zoom-from" class="mi-input" type="number" formControlName="zoomFrom">
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('extrusion.zoomFrom').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('extrusion.zoomFrom').hasError('min')">
                    "Zoom from" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('extrusion.zoomFrom').hasError('max')">
                    "Zoom from" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('extrusion.zoomFrom').hasError('aboveThreshold')">
                    "Zoom from" must be lower than "zoom to". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- Extrusion zoom to -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('extrusion.zoomTo').disabled"
            (click)="unlockSetting($event, 'extrusion.zoomTo')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('extrusion.zoomTo').disabled"
                (toggled)="toggleInheritance('extrusion.zoomTo', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="zoom-to">Zoom to</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the Icon is visible."></info-tooltip>
            </div>

            <div class="setting__control">
                <input id="zoom-to" class="mi-input" type="number" formControlName="zoomTo">
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('extrusion.zoomTo').hasError('required')">
                    Zoom levels must be between 1 and {{ maxZoomLevel }}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('extrusion.zoomTo').hasError('max')">
                    "Zoom to" cannot be larger than {{ maxZoomLevel }}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('extrusion.zoomTo').hasError('belowThreshold')">
                    "Zoom to" must be larger than "zoom from". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- Extrusion color -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('extrusion.color').disabled"
            (click)="unlockSetting($event, 'extrusion.color')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('extrusion.color').disabled"
                (toggled)="toggleInheritance('extrusion.color', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="extrusion-color">Extrusion color</label>
                <info-tooltip class="tooltip" text="Controls color of the 3D Room extrusion."></info-tooltip>
            </div>

            <div class="color-control">
                <div class="mi-input__wrapper mi-input__wrapper--with-units">
                    <input type="text" formControlName="color" id="extrusion-color" class="mi-input" maxlength="7" [value]="getFormControl('extrusion.color').value">
                    <input type="color" formControlName="color" [value]="getFormControl('extrusion.color').value" />
                </div>
            </div>
        </div>

        <!-- Extrusion height -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('extrusion.height').disabled"
            (click)="unlockSetting($event, 'extrusion.height')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('extrusion.height').disabled"
                (toggled)="toggleInheritance('extrusion.height', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="extrusion-height">Extrusion height</label>
                <info-tooltip class="tooltip" text="Controls height of the 3D Room extrusion (measured in meters).">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <div class="mi-input__wrapper mi-input__wrapper--with-units" data-units="m"
                    [ngClass]="{'disabled' :getFormControl('extrusion.height').disabled }">
                    <input id="extrusion-height" class="mi-input" type="number" step="0.1" formControlName="height">
                </div>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('extrusion.height').hasError('min') || getFormControl('extrusion.height').hasError('required')">
                    Extrusion height must be 0 or above.
                </small>
            </div>
        </div>
    </navigatable-section>

    <navigatable-section header="3D Model" *ngIf="model3DModuleEnabled" formGroupName="model3D">
        <!-- 3D Model visibility -->
        <div class="setting"
            lockedDisplayRuleHandler
            [isLocked]="getFormControl('model3D.visible').disabled"
            (click)="unlockSetting($event, 'model3D.visible')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('model3D.visible').disabled"
                (toggled)="toggleInheritance('model3D.visible', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="model3D-visibility">Visibility</label>
                <info-tooltip class="tooltip" text="Controls whether the 3D Model is visible on the map or not.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <input id="model3D-visibility" class="mi-toggle" type="checkbox" formControlName="visible" />
            </div>
        </div>

        <!-- 3D Model Zoom From -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('model3D.zoomFrom').disabled"
            (click)="unlockSetting($event, 'model3D.zoomFrom')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('model3D.zoomFrom').disabled"
                (toggled)="toggleInheritance('model3D.zoomFrom', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="model3D-zoom-from">Zoom from</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the 3D model is visible.">
                </info-tooltip>
            </div>

            <div class="setting__control">
                <input id="model3D-zoom-from" class="mi-input" type="number" formControlName="zoomFrom">
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('model3D.zoomFrom').hasError('required')">
                    Zoom levels must be between 1 and {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('model3D.zoomFrom').hasError('min')">
                    "Zoom from" must be larger than  0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('model3D.zoomFrom').hasError('max')">
                    "Zoom from" cannot be larger than {{maxZoomLevel}}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('model3D.zoomFrom').hasError('aboveThreshold')">
                    "Zoom from" must be lower than "zoom to". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- 3D Model Zoom To -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('model3D.zoomTo').disabled"
            (click)="unlockSetting($event, 'model3D.zoomTo')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('model3D.zoomTo').disabled"
                (toggled)="toggleInheritance('model3D.zoomTo', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="model3D-zoom-to">Zoom to</label>
                <info-tooltip class="tooltip" text="Controls at which zoom levels the 3D Model is visible."></info-tooltip>
            </div>

            <div class="setting__control">
                <input id="model3D-zoom-to" class="mi-input" type="number" formControlName="zoomTo">
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('model3D.zoomTo').hasError('required')">
                    Zoom levels must be between 1 and {{ maxZoomLevel }}.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('model3D.zoomTo').hasError('max')">
                    "Zoom to" cannot be larger than {{ maxZoomLevel }}.
                </small>
                <small role="alert" class="mi-form__error"
                    *ngIf="getFormControl('model3D.zoomTo').hasError('belowThreshold')">
                    "Zoom to" must be larger than "zoom from". Equal zoom levels are allowed on the highest zoom level.
                </small>
            </div>
        </div>

        <!-- 3D Model Media Library -->
        <div class="setting" lockedDisplayRuleHandler
            [isLocked]="getFormControl('model3D.model').disabled" (click)="unlockSetting($event, 'model3D.model')">
            <lock-button class="setting__lock setting__lock--full-height" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('model3D.model').disabled"
                (toggled)="toggleInheritance('model3D.model', $event)">
            </lock-button>
            <div class="setting__label setting__label--baseline">
                <label class="mi-label">3D Model</label>
                <info-tooltip class="tooltip" text="Controls which image is used as 3D Model on the map.">
                </info-tooltip>
            </div>

            <div *ngIf="getFormControl('model3D.model').value" class="setting__control">
                <div class="model3d">
                    <div class="model3d__preview">
                        <img [src]="model3DPreview?.modelData" />
                        <p class="model3d__name">{{ model3DPreview?.name }}</p>
                    </div>
                    <input type="hidden" formControlName="model">

                    <div class="model3d__buttons">
                        <button [disabled]="getFormControl('model3D.model').disabled"
                            (click)="openMediaLibraryFor3DModel()" class="mi-button mi-button--base">
                            Change
                        </button>
                        <button *ngIf="isMainDisplayRule" class="mi-button mi-button--remove" (click)="removeModel3D()">
                            Remove
                        </button>
                    </div>
                </div>
            </div>

            <div class="setting__control" *ngIf="!getFormControl('model3D.model').value">
                <button type="button" [disabled]="getFormControl('model3D.model').disabled" (click)="openMediaLibraryFor3DModel()" class="mi-button mi-button--base">
                    Add 3D model
                </button>
            </div>
        </div>

        <!-- 3D Model x rotation -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('model3D.rotationX').disabled"
            (click)="unlockSetting($event, 'model3D.rotationX')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('model3D.rotationX').disabled"
                (toggled)="toggleInheritance('model3D.rotationX', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="model3D-x-rotation">X-axis rotation</label>
                <info-tooltip class="tooltip" text="Controls the rotation of the 3D model on the x-axis."></info-tooltip>
            </div>

            <div class="setting__control">
                <div class="mi-input__wrapper mi-input__wrapper--with-units" data-units="º">
                    <input id="model3D-x-rotation" class="mi-input" type="number" formControlName="rotationX">
                </div>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('model3D.rotationX').hasError('required')">
                    "X-axis rotation" must be between 0 and 360.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('model3D.rotationX').hasError('min')">
                    "X-axis rotation" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('model3D.rotationX').hasError('max')">
                    "X-axis rotation" must be lower than 360.
                </small>
            </div>
        </div>

        <!-- 3D Model y rotation -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('model3D.rotationY').disabled"
            (click)="unlockSetting($event, 'model3D.rotationY')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('model3D.rotationY').disabled"
                (toggled)="toggleInheritance('model3D.rotationY', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="model3D-y-rotation">Y-axis rotation</label>
                <info-tooltip class="tooltip" text="Controls the rotation of the 3D model on the y-axis."></info-tooltip>
            </div>

            <div class="setting__control">
                <div class="mi-input__wrapper mi-input__wrapper--with-units" data-units="º">
                    <input id="model3D-x-rotation" class="mi-input" type="number" formControlName="rotationY">
                </div>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('model3D.rotationY').hasError('required')">
                    "Y-axis rotation" must be between 0 and 360.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('model3D.rotationY').hasError('min')">
                    "Y-axis rotation" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('model3D.rotationY').hasError('max')">
                    "Y-axis rotation" must be lower than 360.
                </small>
            </div>
        </div>

        <!-- 3D Model z rotation -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('model3D.rotationZ').disabled"
            (click)="unlockSetting($event, 'model3D.rotationZ')">
            <lock-button class="setting__lock" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('model3D.rotationZ').disabled"
                (toggled)="toggleInheritance('model3D.rotationZ', $event)">
            </lock-button>

            <div class="setting__label">
                <label class="mi-label" for="model3D-z-rotation">Z-axis rotation</label>
                <info-tooltip class="tooltip" text="Controls the rotation of the 3D model on the z-axis."></info-tooltip>
            </div>

            <div class="setting__control">
                <div class="mi-input__wrapper mi-input__wrapper--with-units" data-units="º">
                    <input id="model3D-z-rotation" class="mi-input" type="number" formControlName="rotationZ">
                </div>
            </div>

            <div class="setting__errors">
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('model3D.rotationZ').hasError('required')">
                    "Z-axis rotation" must be between 0 and 360.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('model3D.rotationZ').hasError('min')">
                    "Z-axis rotation" must be larger than 0.
                </small>
                <small role="alert" class="mi-form__error" *ngIf="getFormControl('model3D.rotationZ').hasError('max')">
                    "Z-axis rotation" must be lower than 360.
                </small>
            </div>
        </div>

        <!-- 3D Model dimensions -->
        <div class="setting" lockedDisplayRuleHandler [isLocked]="getFormControl('model3D.scale').disabled"
            (click)="unlockSetting($event, 'model3D.scale')">
            <lock-button class="setting__lock setting__lock--full-height" *ngIf="!isMainDisplayRule"
                [locked]="getFormControl('model3D.scale').disabled"
                (toggled)="toggleInheritance('model3D.scale', $event)">
            </lock-button>

            <div class="setting__label setting__label--baseline">
                <label class="mi-label">Dimensions</label>
                <info-tooltip class="tooltip" text="Controls the size of the 3D model on the map."></info-tooltip>
            </div>

            <div class="setting__control setting__control--direction-column">
                <div class="setting__group setting__group--bottom-spacing">
                    <div class="setting__control setting__control--direction-column">
                        <div class="setting__label setting__label--small-bottom-spacing">
                            <label class="mi-label mi-label--small" for="model3D-scale">Scale</label>
                            <info-tooltip class="tooltip tooltip--no-margin" text="Controls the scale of the 3D model. Can be used to make the model bigger or smaller on the map."></info-tooltip>
                        </div>
                        <div>
                            <input
                                type="number"
                                class="mi-input"
                                max="1000"
                                min="0"
                                step="0.01"
                                formControlName="scale" >
                        </div>
                    </div>
                </div>

                <div class="setting__errors">
                    <small role="alert" class="mi-form__error" *ngIf="getFormControl('model3D.scale').hasError('required')">
                        "Scale" must be between 0 and 1000.
                    </small>
                    <small role="alert" class="mi-form__error" *ngIf="getFormControl('model3D.scale').hasError('min')">
                        "Scale" must be larger than 0.
                    </small>
                    <small role="alert" class="mi-form__error" *ngIf="getFormControl('model3D.scale').hasError('max')">
                        "Scale" must be lower than 1000.
                    </small>
                    <small role="alert" class="mi-form__error" *ngIf="getFormControl('model3D.scale').hasError('hasMoreDecimalsThanLimit')">
                        "Scale" cannot have more than 10 decimal places.
                    </small>
                </div>

                <div class="setting__group setting__group--bottom-spacing setting__group--align-items-end">
                    <div class="setting__control setting__control--direction-column">
                        <div class="setting__label setting__label--small-bottom-spacing">
                            <label class="mi-label mi-label--small" for="model3D-width">Width</label>
                            <info-tooltip class="tooltip tooltip--no-margin" text="Controls the width of the 3D model. Editing the width will change the height and scale accordingly as well."></info-tooltip>
                        </div>
                        <div
                            data-units="m"
                            class="mi-input__wrapper mi-input__wrapper--with-units"
                            [ngClass]="{'disabled' :getFormControl('model3D.widthMeters').disabled }">
                            <input
                                id="model3D-width"
                                class="mi-input"
                                type="number"
                                step="0.01"
                                formControlName="widthMeters" />
                        </div>
                    </div>

                    <img class="setting__icon" src="/assets/images/link.svg" />

                    <div class="setting__control setting__control--direction-column">
                        <div class="setting__label setting__label--small-bottom-spacing">
                            <label class="mi-label mi-label--small" for="model3D-height">Height</label>
                            <info-tooltip class="tooltip tooltip--no-margin" text="Controls the height of the 3D model. Editing the height will change the width and scale accordingly as well."></info-tooltip>
                        </div>
                        <div
                            data-units="m"
                            class="mi-input__wrapper mi-input__wrapper--with-units"
                            [ngClass]="{'disabled' :getFormControl('model3D.heightMeters').disabled }">
                            <input
                                id="model3D-height"
                                class="mi-input"
                                type="number"
                                step="0.01"
                                formControlName="heightMeters" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </navigatable-section>
</form>
<panel-footer *ngIf="isDisplayRuleFormDirty" text="You have unsaved changes.">
    <button type="button" class="mi-button mi-button--link" (click)="onDiscardChanges()">Discard</button>
    <button type="button" class="mi-button mi-button--primary" [disabled]="displayRuleEditorForm.invalid"
        (click)="onSubmit()">Save and close</button>
</panel-footer>