import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, from } from 'rxjs';
import { SolutionService } from '../services/solution.service';
import { environment } from '../../environments/environment';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { MapsIndoorsIcons } from './mapsindoors-icons';
import { IMediaItem, MediaItem } from './media-item/media-item.model';
import { MediaFilterOptions } from './media-filter-bar/media-filter-options.model';
import { MediaCategory, MediaFileType } from './media.enum';
import { MediaFileUploadRequestBody } from './media-request-upload.model';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

@Injectable()
export class MediaLibraryService {
    private API_ENDPOINT = environment.APIEndpoint;
    private allMediaItemsSubject = new BehaviorSubject<IMediaItem[]>(null!);
    private allMediaItems: IMediaItem[];
    private activeFilters = new Subject<MediaFilterOptions>();
    private isServiceBusy = new BehaviorSubject<boolean>(false);

    constructor(
        private solutionService: SolutionService,
        private http: HttpClient,
        private mapsIndoorsIcons: MapsIndoorsIcons
    ) { }

    /**
     * Returns the list with all the media items as an observable.
     *
     * @returns {Observable<IMediaItem[]>}
     */
    get mediaItems$(): Observable<IMediaItem[]> {
        return this.allMediaItemsSubject.asObservable();
    }

    /**
     * Returns the active filters array as an observable.
     *
     * @returns {Observable<MediaFilterOptions>}
     */
    get activeFilters$(): Observable<MediaFilterOptions> {
        return this.activeFilters.asObservable();
    }

    /**
     * Returns the spinnerState.
     *
     * @returns {Observable<boolean>}
     */
    get isServiceBusy$(): Observable<boolean> {
        return this.isServiceBusy.asObservable();
    }

    /**
     * Get uploaded media by calling HttpGet request to API.
     *
     * @returns {Observable<IMediaItem[]>}
     */
    private getUploadedMedias(): Observable<IMediaItem[]> {
        const solution = this.solutionService.getStaticSolution();
        return this.http.get<IMediaItem[]>(`${this.API_ENDPOINT}${solution.id}/api/media`);
    }

    /**
     * Converts Blob to string.
     *
     * @param {Blob} blob
     * @returns {Promise<string>}
     */
    private blobToDataURL(blob: Blob): Promise<string> {
        return new Promise ((resolve) => {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                resolve(e.target.result as string);
            };
            fileReader.readAsDataURL(blob);
        });
    }

    /**
     * Sets new values to the active filters.
     */
    public setActiveFilter(filters: MediaFilterOptions): void {
        this.activeFilters.next(filters);
    }

    /**
     * Function that compares two arrays: one from API (Uploads) and one from MapsIndoorsIcons (hardcoded Icons) and concat them into one array that can be displayed in Media Library.
     *
     * @returns {Observable<MediaItem[]>}
     */
    getMediaItems(): Observable<MediaItem[]> {
        const solution = this.solutionService.getStaticSolution();
        this.isServiceBusy.next(true);
        return this.getUploadedMedias()
            .pipe(
                map(uploadedMediaItems => {
                    // Decorate uploaded media with a category so we can distinguish between uploads and MapsIndoors icons
                    uploadedMediaItems = uploadedMediaItems.map(mediaItem => {
                        mediaItem.category = mediaItem.type !== MediaFileType.GLB ? MediaCategory.Image : MediaCategory.Model3D;
                        mediaItem.type = mediaItem.type === 'jpeg' ? 'jpg' : mediaItem.type;
                        return mediaItem;
                    });

                    const allMediaItems = [];
                    const mediaItems = uploadedMediaItems.concat(this.mapsIndoorsIcons.getIcons());
                    mediaItems.forEach(media => {
                        media.solutionId = solution.id;
                        allMediaItems.push(new MediaItem(media));
                    });

                    return allMediaItems;
                }),
                tap((allMediaItems) => {
                    this.allMediaItems = allMediaItems;
                    this.allMediaItemsSubject.next(allMediaItems);
                }),
                finalize(() => this.isServiceBusy.next(false))
            );
    }

    /**
     * Get the preview image of a media item based on its id.
     *
     * @param {string} mediaid
     * @returns {Observable<string>}
     */
    getMediaPreviewById(mediaid: string): Observable<string> {
        const solution = this.solutionService.getStaticSolution();
        return this.http.get(`${this.API_ENDPOINT}${solution.id}/media/preview/${mediaid}`, { responseType: 'blob' })
            .pipe(switchMap(result => from(this.blobToDataURL(result))));
    }

    /**
     * Get the preview image of a media item based on its name.
     *
     * @param {string} mediaName
     * @returns {Observable<string>}
     */
    getMediaPreviewByName(mediaName: string): Observable<string> {
        const solution = this.solutionService.getStaticSolution();
        return this.http.get(`${this.API_ENDPOINT}${solution.id}/media/preview/filename/${mediaName}`, { responseType: 'blob' })
            .pipe(switchMap(result => from(this.blobToDataURL(result))));
    }

    /**
     * Get the media item by name.
     *
     * @param {string} mediaName
     * @returns {Observable<Blob>}
     */
    getMediaItem(mediaName: string): Observable<Blob> {
        const solution = this.solutionService.getStaticSolution();
        return this.http.get(`${this.API_ENDPOINT}${solution.id}/media/${encodeURIComponent(mediaName)}`, { responseType: 'blob' });
    }

    /**
     * GET request to Backend to get details about a media item with the media item's id.
     *
     * @param {string} id
     * @returns {Observable<IMediaItem>}
     */
    getMediaItemDetails(id: string): Observable<any> {
        const solution = this.solutionService.getStaticSolution();
        this.isServiceBusy.next(true);
        return this.http.get(`${this.API_ENDPOINT}${solution.id}/api/media/details/${id}?referenceCount=true`)
            .pipe(
                finalize(() => this.isServiceBusy.next(false))
            );
    }

    /**
     * Updates a media item.
     *
     * @param {MediaFileUploadRequestBody} requestBody
     * @returns {Observable<any>}
     */
    updateMediaItem(requestBody: MediaFileUploadRequestBody): Observable<any> {
        const solution = this.solutionService.getStaticSolution();
        this.isServiceBusy.next(true);
        return this.http.put(`${this.API_ENDPOINT}${solution.id}/api/media`, requestBody)
            .pipe(
                finalize(() => this.isServiceBusy.next(false))
            );
    }

    /**
     * POST request to Backend to upload a new media using the request body.
     *
     * @param {MediaFileUploadRequestBody[]} requestBody
     * @returns {Observable<IMediaItem[]>}
     */
    uploadNewMedia(requestBody: MediaFileUploadRequestBody[]): Observable<IMediaItem[]> {
        const solution = this.solutionService.getStaticSolution();
        this.isServiceBusy.next(true);
        return this.http.post<IMediaItem[]>(`${this.API_ENDPOINT}${solution.id}/api/media`, requestBody)
            .pipe(
                tap((medias: IMediaItem[]) => {
                    const newMedias: IMediaItem[] = [];
                    medias.forEach(media => {
                        const newDate = new Date();
                        media.lastModified = newDate.toISOString();
                        media.solutionId = solution.id;
                        media.category = media.type === MediaFileType.GLB ? MediaCategory.Model3D : MediaCategory.Image;

                        const newMedia = new MediaItem(media);
                        newMedias.push(newMedia);
                    });

                    this.allMediaItems = [].concat(newMedias, this.allMediaItems);
                    this.allMediaItemsSubject.next(this.allMediaItems);
                }),
                finalize(() => this.isServiceBusy.next(false))
            );
    }

    /**
     * DELETE request to Backend to delete a media item with the media item's id.
     *
     * @param {string} id
     * @returns {Observable<void>}
     */
    deleteMedia(id: string): Observable<void> {
        const solution = this.solutionService.getStaticSolution();
        return this.http.delete<void>(`${this.API_ENDPOINT}${solution.id}/api/media/${id}`)
            .pipe(
                tap(() => {
                    this.allMediaItems = this.allMediaItems.filter(media => media.id !== id);
                    this.allMediaItemsSubject.next(this.allMediaItems);
                })
            );
    }

    /**
     * Loads a 3D model and returns the gltf file.
     *
     * @param {string} fileSrc
     * @returns {Promise<GLTF>}
     */
    load3D(fileSrc: string): Promise<GLTF> {
        const loader: GLTFLoader = new GLTFLoader();

        return new Promise((resolve, reject) => {
            //.load parameters: (url: String, onLoad: Function, onProgress: Function, onError: Function)
            loader.load(fileSrc,
                (gltf: GLTF) => resolve (gltf),
                () => {},
                (error) => reject(error));
        });
    }
}
