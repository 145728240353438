<div class="container">
    <section class="message">
        <p class="message__headline message__headline--bold">
            <img alt="" src="../../../assets/images/check-circle.svg"> {{ uploadedImages }}&nbsp;
            <span *ngIf="uploadedImages === 1">file was</span>
            <span *ngIf="uploadedImages === 0 || uploadedImages > 1">files were</span>&nbsp;successfully uploaded.
        </p>
    </section>

    <hr class="breakline">

    <section class="message">
        <p class="message__headline">
            <img alt="" src="../../../assets/images/x-circle.svg">
            <span *ngIf="failedImages.length === 1">One</span>
            <span *ngIf="failedImages.length > 1">Some</span>&nbsp;of your files&nbsp;
            <span class="message__headline--bold">could not be uploaded</span>. Please see below for more details.
        </p>

        <div class="errors" *ngFor="let failedImage of failedImages">
            <div class="errors__info">
                <div class="errors__details">
                    <p class="errors__name">{{ failedImage.mediaName }}</p>
                </div>
            </div>

            <ul class="errors__list">
                <li *ngFor="let error of failedImage.errors" [innerHTML]="error"></li>
            </ul>
        </div>
    </section>
</div>
<panel-footer class="footer">
    <button type="button" class="close mi-button mi-button--primary" (click)="onClose()">
        Close
    </button>
</panel-footer>