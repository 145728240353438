import { LocationService } from '../locations/location.service';
import { BaseMapAdapter } from '../MapAdapter/BaseMapAdapter';
import { GoogleMapsAdapter } from '../MapAdapter/Google/GoogleMapsAdapter';
import { MapboxAdapter } from '../MapAdapter/Mapbox/MapboxAdapter';
import { DisplayRuleService } from '../services/DisplayRuleService/DisplayRuleService';
import { GeodataEditor } from './GeodataEditor';
import { GoogleMapsGeodataEditorAdapter } from './GeodataEditorMapAdapter/GoogleMapsGeodataEditorAdapter';
import { MapboxGeodataEditorAdapter } from './GeodataEditorMapAdapter/MapboxGeodataEditorAdapter';

export { GeodataEditor, DrawingMode } from './GeodataEditor';

export class GeodataEditorFactory {
    private static instances = new WeakMap();

    /**
     * Create an instance of the GeodataEditor.
     *
     * @static
     * @param {BaseMapAdapter} mapAdapter
     * @param {DisplayRuleService} displayRuleService
     * @returns {GeodataEditor}
     */
    static create(mapAdapter: BaseMapAdapter, displayRuleService: DisplayRuleService, locationService: LocationService): GeodataEditor {
        if (this.instances.has(mapAdapter)) {
            return this.instances.get(mapAdapter);
        } else {
            let geodataEditor;
            switch (mapAdapter?.constructor) {
                case GoogleMapsAdapter:
                    geodataEditor = new GoogleMapsGeodataEditorAdapter(mapAdapter as GoogleMapsAdapter, displayRuleService, locationService);
                    break;
                case MapboxAdapter:
                    geodataEditor = new MapboxGeodataEditorAdapter(mapAdapter as MapboxAdapter, displayRuleService, locationService);
                    break;
                default:
                    return;
            }

            this.instances.set(mapAdapter, geodataEditor);
            return geodataEditor;
        }
    }
}
