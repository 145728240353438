import { MultiPolygon, Polygon } from 'geojson';

import { ViewModelProperties } from '../ViewModelProperties/ViewModelProperties';
import { DisplayRule } from '../../app/locations/location.model';
import { SortKey } from '../MapViewModelFactory/MapViewModelFactory';
import { MapViewModel } from '../MapViewModelFactory/MapViewModelFactory';
import { FeatureClass } from '../FeatureClass';
import { MapsIndoorsData } from '../../app/shared/enums/MapsIndoorsData';

abstract class PolygonViewModelProperties implements ViewModelProperties {
    originalId: string;
    originalType: MapsIndoorsData;
    clickable: boolean;
    fillColor: string;
    fillOpacity: number;
    strokeColor: string;
    strokeOpacity: number;
    strokeWidth: number;
    sortKey: number;
    featureClass: FeatureClass;
    zoomRange: [min: number, max: number];

    /**
     * Factory for creating a PolygonViewModelProperties object.
     *
     * @static
     * @param {string} id
     * @param {number} sortKey
     * @param {DisplayRule} displayRule
     * @returns {PolygonViewModelProperties}
     * @memberof PolygonViewModelProperties
     */
    static async create(id: string, sortKey: number, displayRule: DisplayRule, originalType: MapsIndoorsData): Promise<PolygonViewModelProperties> {
        return await Promise.resolve({
            originalId: id,
            originalType,
            clickable: displayRule.clickable,
            fillColor: displayRule.polygon.fillColor,
            fillOpacity: displayRule.polygon.visible ? displayRule.polygon.fillOpacity : 0,
            strokeColor: displayRule.polygon.strokeColor,
            strokeOpacity: displayRule.polygon.visible ? displayRule.polygon.strokeOpacity : 0,
            strokeWidth: displayRule.polygon.strokeWidth,
            sortKey: SortKey.POLYGON + sortKey,
            featureClass: FeatureClass.POLYGON,
            zoomRange: [displayRule?.polygon.zoomFrom, displayRule?.polygon.zoomTo]
        });
    }
}


export class PolygonViewModel implements MapViewModel {
    readonly id: string;
    readonly type = 'Feature';
    readonly geometry: Polygon | MultiPolygon;
    readonly properties: PolygonViewModelProperties;

    private constructor(id: string, geometry: Polygon | MultiPolygon, properties: PolygonViewModelProperties) {
        this.id = `POLYGON:${id}`;
        this.geometry = geometry;
        this.properties = properties;
    }

    /**
     * Factory to create a PolygonViewModel from an id, a geometry, and a DisplayRule.
     *
     * @static
     * @param {string} id
     * @param {Polygon | MultiPolygon} geometry
     * @param {DisplayRule} displayRule
     * @param {number} index
     * @returns {PolygonViewModel}
     * @memberof PolygonViewModel
     */
    static async create(id: string, geometry: Polygon | MultiPolygon, displayRule: DisplayRule, index: number, originalType: MapsIndoorsData): Promise<PolygonViewModel> {
        const properties = await PolygonViewModelProperties.create(id, index, displayRule, originalType);
        const viewModel = new PolygonViewModel(id, geometry as (Polygon | MultiPolygon), properties);

        return viewModel;
    }
}