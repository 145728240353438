import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ExtendedLocation, LocationService } from '../locations/location.service';
import { DataService } from '../services/data.service';
import { Venue } from '../venues/venue.model';
import { VenueService } from '../venues/venue.service';
import { DerivedGeometry, DerivedGeometryType } from './DerivedGeometry';

@Injectable({ providedIn: 'root' })
export class DerivedGeometryService {
    private derivedGeometriesSubject = new ReplaySubject<DerivedGeometry[]>(1);

    constructor(
        private locationService: LocationService,
        private venueService: VenueService,
        private dataService: DataService,
    ) {
        combineLatest([this.locationService.locations$, this.venueService.selectedVenue$])
            .pipe(
                catchError(() => of([])),
                switchMap(([locations, venue]: [ExtendedLocation[], Venue]) => this.fetchDerivedGeometries(venue)
                    .pipe(
                        map(derivedGeometries => derivedGeometries.reduce((arr, derivedGeometry) => {
                            const location = locations.find(location => derivedGeometry.geodataId === location.id);
                            if (location) {
                                derivedGeometry.properties.floorIndex = location.pathData.floor;
                                arr.push(derivedGeometry);
                                location.wallGeometry = derivedGeometry.type === DerivedGeometryType.WALL ? derivedGeometry : undefined;
                            }
                            return arr;
                        }, []))
                    )
                )
            )
            .subscribe((derivedGeometries) => this.derivedGeometriesSubject.next(derivedGeometries));
    }

    /**
     * Fetch derived geometries for locations on the given venue.
     *
     * @private
     * @param {Venue} venue
     * @returns {Observable<DerivedGeometry[]>}
     * @memberof DerivedGeometryService
     */
    private fetchDerivedGeometries(venue: Venue): Observable<DerivedGeometry[]> {
        const options = { params: { venueId: venue.id } };
        const endpoint = `${venue.solutionId}/api/derivedgeometry`;
        return this.dataService.getItems<DerivedGeometry>(endpoint, options).pipe(map(derivedGeometries => derivedGeometries.map(derivedGeometry => Object.assign(new DerivedGeometry(), derivedGeometry))));
    }

    /**
     * Get derived geometries as an observable.
     *
     * @returns {Observable<DerivedGeometry[]>}
     * @memberof TypesService
     */
    get derivedGeometries$(): Observable<DerivedGeometry[]> {
        return this.derivedGeometriesSubject.asObservable();
    }
}
