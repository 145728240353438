import { Component, EventEmitter, HostListener, Input, OnDestroy, Optional, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, Subscription } from 'rxjs';
import { MapSidebar, MapSidebarRef } from '../adapter/map-sidebar/map-sidebar.interface';
import { DisplayRule } from '../locations/location.model';
import { ExtendedLocation } from '../locations/location.service';
import { DisplayRuleService } from '../services/DisplayRuleService/DisplayRuleService';
import { DisplayRuleDetailsEditorComponent } from '../shared/display-rule-details-editor/display-rule-details-editor.component';
import { GeoJSONGeometryType } from '../shared/enums';

@Component({
    selector: 'display-rule-details',
    templateUrl: './display-rule-details.component.html',
    styleUrls: ['./display-rule-details.component.scss']
})
export class DisplayRuleDetailsComponent implements MapSidebar, OnDestroy {
    #submitSubject: Subject<DisplayRule> = new Subject();
    #discardSubject: Subject<void> = new Subject();
    #geometries: [GeoJSON.Geometry, GeoJSON.Point?];

    @ViewChild(DisplayRuleDetailsEditorComponent, { static: true })
    private displayRuleEditor!: DisplayRuleDetailsEditorComponent;
    private subscriptions: Subscription = new Subscription();

    constructor(
        @Optional() private dialogRef: MatDialogRef<DisplayRuleDetailsComponent>,
        @Optional() private mapSidebarRef: MapSidebarRef<DisplayRuleDetailsComponent>,
        private displayRuleService: DisplayRuleService
    ) { }

    /**
     * A multicasting observable that emits an event every time a value changes in the UI or programmatically.
     *
     * @public
     * @readonly
     * @type {Observable<any>}
     */
    public get valueChanges(): Observable<any> {
        return this.displayRuleEditor.displayRuleEditorForm.valueChanges;
    }

    /**
     * If the user changes any value in the UI, the editor's status will be dirty.
     *
     * @public
     * @readonly
     * @type {boolean}
     */
    public get dirty(): boolean {
        return this.displayRuleEditor.displayRuleEditorForm.dirty;
    }

    /**
     * If the user has not yet changed any values in the UI, the editor's status will be pristine.
     *
     * @public
     * @readonly
     * @type {boolean}
     */
    public get pristine(): boolean {
        return this.displayRuleEditor.displayRuleEditorForm.pristine;
    }

    /**
     * The editor's status is invalid if any of its values are invalid.
     *
     * @public
     * @readonly
     * @type {boolean}
     */
    public get invalid(): boolean {
        return this.displayRuleEditor.displayRuleEditorForm.invalid;
    }

    /**
     * The editor's status is valid if all of its values are valid.
     *
     * @public
     * @readonly
     * @type {boolean}
     */
    public get valid(): boolean {
        return this.displayRuleEditor.displayRuleEditorForm.valid;
    }

    /**
     * Get the draft display rule.
     *
     * @public
     * @returns {DisplayRule}
     */
    public getDisplayRule(): DisplayRule {
        return this.displayRuleEditor.displayRuleEditorForm.getRawValue();
    }

    /**
     * Emits an event when the Display Rule Editor is submitted.
     *
     * @public
     * @readonly
     * @type {Observable<DisplayRule>}
     */
    public get submit$(): Observable<DisplayRule> {
        return this.#submitSubject.asObservable();
    }

    /**
     * Emits an event when the changes in Display Rule Editor are discarded.
     *
     * @public
     * @readonly
     * @type {Observable<DisplayRule>}
     */
    public get discard$(): Observable<void> {
        return this.#discardSubject.asObservable();
    }

    /**
     * Get the Location geometry and anchor point.
     *
     * @public
     * @type {[GeoJSON.Geometry, GeoJSON.Point?]}
     */
    public get geometries(): [GeoJSON.Geometry, GeoJSON.Point?] {
        return this.#geometries;
    }

    /**
     * Set the Location geometry and anchor point.
     *
     * @public
     * @type {[GeoJSON.Geometry, GeoJSON.Point?]}
     */
    public set geometries(geometries: [GeoJSON.Geometry, GeoJSON.Point?]) {
        this.#geometries = geometries;
    }

    /**
     * Angulars OnDestroy lifecycle hook.
     */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Setter for setting the location for which to edit the display rule.
     */
    @Input()
    set data(location: ExtendedLocation) {
        Promise.all([
            this.displayRuleService.getDisplayRuleForLocation(location.id),
            this.displayRuleService.getDisplayRule(location.type)
        ]).then(displayRules => this.displayRules = displayRules);
        this.header = location.name;
        this.isGeometrySettingsVisible = location.geometry.type === GeoJSONGeometryType.Polygon;
        if (location.locationType !== 'room') {
            this.is3DWallsSectionVisible = false;
        }
    }

    readonly closed: EventEmitter<void> = new EventEmitter();

    /**
     * Closes the location details editor.
     * Returns true or false depending on if the dialog can be closed.
     *
     * @returns {boolean}
     * @memberof DisplayRuleDetailsComponent
     */
    close(): boolean {
        return this.onCloseDialog();
    }

    /**
     * Passed data from Display Rule Details Editor is discarded for specific Location or Location Type.
     *
     * @param {Event} event
     * @returns {void}
     */
    public onFormDiscard(event: Event): void {
        this.#discardSubject.next();
        this.dialogRef?.close(event);
    }

    /**
     * Passed data from Display Rule Details Editor is saved for specific Location or Location Type.
     *
     * @param {Event} event
     * @returns {void}
     */
    public onFormSubmit(event: Event): void {
        if (this.valid) {
            this.#submitSubject.next(event as DisplayRule);
            this.closed.emit();
            this.dialogRef?.close(event);
        }
    }

    /**
     * React on pressing Escape button. If triggered, we are forwarded to onCloseDialog function.
     *
     * @param {KeyboardEvent} event
     * @memberof DisplayRuleDetailsComponent
     */
    @HostListener('keydown.escape', ['$event'])
    public onEscapeButton(event: KeyboardEvent): void {
        this.onCloseDialog();
        event.preventDefault();
        event.stopImmediatePropagation();
    }

    /**
     * Discard changes and close dialog. State of the form is passed via ViewChild and dirty state from there.
     * Returns true or false depending on if the dialog can be closed.
     *
     * @returns {boolean}
     * @memberof DisplayRuleDetailsComponent
     */
    public onCloseDialog(): boolean {
        if (this.displayRuleEditor.onDiscardChanges()) {
            this.dialogRef?.close();
            this.closed.emit();
            return true;
        } else {
            return false;
        }
    }

    /**
     * Property that defines location's type.
     */
    @Input() is3DWallsSectionVisible: boolean = true;

    /**
     * Set additional information to be displayed in the panel-header component.
     *
     * @type {string}
     * @memberof DisplayRuleDetailsComponent
     */
    @Input() header: string;

    /**
     * The current display rule.
     *
     * @type {[DisplayRule, DisplayRule?]}
     * @memberof DisplayRuleDetailsComponent
     */
    @Input() displayRules: [DisplayRule, DisplayRule?];

    /**
     * Toggle visibility of geometry related display rule settings.
     *
     * @type {boolean}
     * @memberof DisplayRuleDetailsComponent
     */
    @Input() isGeometrySettingsVisible: boolean = true;
}
