import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Category } from '../../categories/category.model';
import { ExtendedLocation, LocationService } from '../../locations/location.service';
import { LocationType } from '../../location-types/location-type.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { NotificationService } from '../../services/notification.service';
import { CategoryService } from '../../categories/category.service';
import { Subscription } from 'rxjs';
import { TypesService } from '../../services/types.service';


@Component({
    selector: 'app-locations-bulk-edit',
    templateUrl: './locations-bulk-edit.component.html',
    styleUrls: ['./locations-bulk-edit.component.scss']
})
export class LocationsBulkEditComponent {
    #subscriptions = new Subscription();
    #categories: Category[] = [];
    #locationTypes: LocationType[] = [];

    @Input() locations = new Set<ExtendedLocation>();

    @Output() closeBulkEditor = new EventEmitter<void>();
    @Output() bulkLocationsUpdate = new EventEmitter<void>();

    public bulkChanges = {
        removeAllCategories: false,
        categories: [],
        aliases: [],
        removeAllAliases: false,
        activeFrom: null,
        activeTo: null
    };

    public selectedLocationType: LocationType;

    /**
     * Categories.
     *
     * @readonly
     * @returns {Category[]}
     * @memberof LocationsBulkEditComponent
     */
    public get categories(): Category[] {
        return this.#categories;
    }

    /**
     * Location Types.
     *
     * @readonly
     * @returns {LocationType[]}
     * @memberof LocationsBulkEditComponent
     */
    public get locationTypes(): LocationType[] {
        return this.#locationTypes;
    }

    constructor(
        private spinner: NgxSpinnerService,
        private locationService: LocationService,
        private categoryService: CategoryService,
        private typesService: TypesService,
        private notificationService: NotificationService,
    ) { }




    ngOnInit(): void {
        const categorySubscription = this.categoryService.categories.subscribe(categories => this.#categories = categories);
        const typesSubscription = this.typesService.types.subscribe(types => this.#locationTypes = types);

        this.#subscriptions
            .add(categorySubscription)
            .add(typesSubscription);
    }

    ngOnDestroy(): void {
        this.#subscriptions.unsubscribe();
    }
    /**
     * Reset all bulk edit related variables.
     *
     * @private
     * @memberof LocationsBulkEditComponent
     */
    private resetBulkEdit(): void {
        this.selectedLocationType = null;
        this.bulkChanges.categories = [];
        this.bulkChanges.removeAllCategories = false;
        this.bulkChanges.aliases = [];
        this.bulkChanges.removeAllAliases = false;
        this.bulkChanges.activeFrom = null;
        this.bulkChanges.activeTo = null;
    }

    /**
     * Reset the variables and emit the close event.
     *
     * @memberof LocationsBulkEditComponent
     */
    public closeBulkEdit(): void {
        this.resetBulkEdit();
        this.closeBulkEditor.emit();
    }

    /**
     * Update Locations.
     *
     * @memberof LocationsBulkEditComponent
     */
    public updateLocations(): void {
        for (const location of this.locations) {
            // Update type
            location.type = this.selectedLocationType?.administrativeId || location.type;

            // Remove all previous set categories
            location.categories = (this.bulkChanges.removeAllCategories || !location.categories)
                ? []
                : location.categories;

            // Add new categories
            this.bulkChanges.categories?.forEach(category => {
                !location.categories.includes(category) && location.categories.push(category);
            });

            // Remove all previous set aliases
            location.aliases = (this.bulkChanges.removeAllAliases || !location.aliases)
                ? []
                : location.aliases;

            // Add new aliases
            this.bulkChanges.aliases?.forEach(alias => {
                !location.aliases.includes(alias) && location.aliases.push(alias);
            });

            // Update Active from/to
            location.activeFrom = this.bulkChanges.activeFrom || location.activeFrom;
            location.activeTo = this.bulkChanges.activeTo || location.activeTo;
        }

        this.bulkUpdateLocations();
    }

    /**
     * Bulk send locations to the backend.
     */
    public bulkUpdateLocations(): void {
        this.spinner.show();
        this.locationService.updateLocations([...this.locations])
            .pipe(finalize(() => this.spinner.hide()))
            .subscribe(
                () => {
                    this.bulkLocationsUpdate.emit();
                    this.resetBulkEdit();
                    this.notificationService.showSuccess('Successfully updated locations');
                },
                err => this.notificationService.showError(err)
            );
    }
}
