<div class="container">
    <ul class="nav-menu">
        <li class="nav-menu__title">SDK Settings</li>
        <li class="nav-menu__item" routerLinkActive="nav-menu__item--active">
            <a routerLink="main-display-rule">Main Display Rule</a>
        </li>
        <li class="nav-menu__item" routerLinkActive="nav-menu__item--active">
            <a routerLink="map-behavior">Map Behavior</a>
        </li>
        <li class="nav-menu__item" routerLinkActive="nav-menu__item--active">
            <a routerLink="3d-settings">3D Settings</a>
        </li>
    </ul>

    <div class="container__router">
        <router-outlet></router-outlet>
    </div>
</div>
