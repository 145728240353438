import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { Floor } from '../../buildings/floor.model';
import { NetworkMapService } from '../../map/network-map.service';
import { RouteElementGeometryService } from '../../services/route-element-geometry.service';
import { UnitSystem } from '../../shared/enums';
import { UserAgentService } from '../../services/user-agent.service';
import { Venue } from '../../venues/venue.model';

@Component({
    selector: 'add-door',
    templateUrl: './add-door.component.html',
    styleUrls: ['./add-door.component.scss']
})
export class AddDoorComponent implements OnInit, OnDestroy {

    @Input() floor: Floor;
    @Input() venue: Venue;

    @Output() cancel = new EventEmitter<void>();
    @Output() done = new EventEmitter<void>();

    public unitSystem: UnitSystem;
    public hasDoorPoint = false;
    public doorWidthInputValue: number;
    public maxDoorWidthInputValue: number;

    private subscriptions = new Subscription();

    constructor(
        private routeElementGeometryService: RouteElementGeometryService,
        private networkMapService: NetworkMapService,
        private userAgentService: UserAgentService
    ) { }

    /** NgOnInit. */
    ngOnInit(): void {
        this.unitSystem = this.userAgentService.unitSystem;
        this.doorWidthInputValue = this.routeElementGeometryService.doorWidth;
        this.maxDoorWidthInputValue = this.routeElementGeometryService.maxDoorWidth;

        this.subscriptions
            .add((this.routeElementGeometryService.isDoorPointSet() as Observable<boolean>)
                .subscribe(value => {
                    this.hasDoorPoint = value;
                })
            );
    }

    /** NgOnDestroy. */
    ngOnDestroy(): void {
        this.routeElementGeometryService.cancelOperation();
    }

    /**
     * Cancel adding a door.
     *
     * @memberof AddDoorComponent
     */
    public cancelOperation(): void {
        // eslint-disable-next-line no-alert
        if (!confirm('Canceling without saving will discard any changes you have made.')) {
            return;
        }

        this.routeElementGeometryService.cancelOperation();
        this.cancel.emit();
    }

    /**
     * Update door width when input value changes.
     *
     * @param {KeyboardEvent} event
     * @memberof AddDoorComponent
     */
    public updateDoorWidth(event: KeyboardEvent): void {
        const doorWidth = parseInt((event.target as HTMLInputElement).value, 10);

        if (doorWidth <= this.routeElementGeometryService.maxDoorWidth) {
            this.routeElementGeometryService.updateDoorWidthWhenCreating(doorWidth);
        }
    }

    /**
     * Create the added door.
     *
     * @memberof AddDoorComponent
     */
    public createAddedDoor(): void {
        this.networkMapService.createDoor(this.routeElementGeometryService.doorLineString, this.venue, this.floor.floorIndex);
        this.routeElementGeometryService.cancelOperation();
        this.done.emit();
    }
}
