import * as turf from '@turf/turf';

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Feature, MultiPolygon, Polygon, Position } from 'geojson';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs/operators';
import { ExtendedLocation, LocationService } from '../locations/location.service';
import { ViewState, ViewStateService } from './view-state.service';

import { NgxSpinnerService } from 'ngx-spinner';
import { Building, OUTDOOR_BUILDING } from '../buildings/building.model';
import { BuildingService } from '../buildings/building.service';
import { Floor } from '../buildings/floor.model';
import { Location } from '../locations/location.model';
import { NetworkService } from '../network-access/network.service';
import { filterLocations } from '../operators/filterLocations';
import { MapService } from '../services/map.service';
import { NotificationService } from '../services/notification.service';
import { RouteElementGeometryService } from '../services/route-element-geometry.service';
import { SolutionService } from '../services/solution.service';
import { getCollectedBounds } from '../shared/geometry-helper';
import { Solution } from '../solutions/solution.model';
import { CombineLocationsComponent } from '../subcomponents/combine-locations/combine-locations.component';
import { CombineService } from '../subcomponents/combine-locations/combine.service';
import { SplitLocationComponent } from '../subcomponents/split-location/split-location.component';
import { Venue } from '../venues/venue.model';
import { VenueService } from '../venues/venue.service';
import { FilterOptions } from './filters-bar/filter-options.model';
import { FiltersBarComponent } from './filters-bar/filters-bar.component';
import { GraphDataService } from './graph-data.service';
import { LocationsMapService } from './locations-map/locations-map.service';
import { NetworkMapService } from './network-map.service';
import { RouteElement } from './route-element-details/route-element.model';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    providers: []
})
export class MapComponent implements OnInit, OnDestroy {
    @ViewChild('map', { static: true }) mapElement: ElementRef;
    @ViewChild('filterbar', { static: true }) filterBar: FiltersBarComponent;

    private subscriptions = new Subscription();
    private activeFilters: FilterOptions = {};
    private selectedBuildings: Building[] = [];
    private selectedBuildingsHighlights: google.maps.Polygon[] = [];
    private outdoorAreaHighlightSelected = false;
    private outdoorAreaHighlights: google.maps.Polygon[] = [];
    private focusedBuildingPolygon = new google.maps.Polygon({
        strokeColor: '#ef6cce',
        strokeOpacity: 1,
        strokeWeight: 4,
        fillOpacity: 0,
        clickable: false,
        zIndex: 2
    });
    private routeLayerSubscription: Subscription = new Subscription();
    private routeLayerObservable = this.venueService.selectedVenue$
        .pipe(
            tap(() => this.spinner.show()),
            switchMap(venue => this.networkService.getRouteLayer(venue.graphId)),
            tap(routeElements => {
                this.networkMapService.drawRouteElements(routeElements, this.isNetworkVisible);
            }));

    public viewStates = ViewState;
    public currentViewState = ViewState.Default;
    public currentZoomLevel = 0;
    public currentVenue: Venue;
    public currentFloor: Floor = null;
    public locations: ExtendedLocation[] = [];
    public filteredLocations: ExtendedLocation[] = [];
    public buildings: Building[] = [];
    public focusedBuildingFloors: Floor[] = [];
    public currentLocation: ExtendedLocation;
    public currentSolution: Solution;
    public currentRouteElement: RouteElement;
    public isListViewOpen = false;
    public selectedLocations: Set<ExtendedLocation> = new Set();
    public isBulkEditorOpen = false;
    public canOpenBulkEdit = false;
    public isNetworkVisible = false;
    public graphIds: string[];

    constructor(
        private viewStateService: ViewStateService,
        private solutionService: SolutionService,
        private venueService: VenueService,
        private buildingService: BuildingService,
        private locationService: LocationService,
        private mapService: MapService,
        private locationsMapService: LocationsMapService,
        private networkMapService: NetworkMapService,
        private networkService: NetworkService,
        private routeElementGeometryService: RouteElementGeometryService,
        private notificationService: NotificationService,
        private spinner: NgxSpinnerService,
        private matDialog: MatDialog,
        private combineService: CombineService,
        private graphService: GraphDataService
    ) { }

    /**
     * Highlights the building closest to the map center and updates the floor selector to reflect the building floors.
     *
     * @private
     * @memberof MapComponent
     */
    private highlightBuildingInFocus(): void {
        const selectedBuildings = this.selectedBuildings?.length > 0 ? this.selectedBuildings : this.buildings;

        const mapCenterCoordinate = this.mapService.getMap().getCenter();
        if (mapCenterCoordinate && !this.currentLocation) {
            const mapCenter: GeoJSON.Point = { type: 'Point', coordinates: [mapCenterCoordinate.lng(), mapCenterCoordinate.lat()]};
            const closestBuilding = this.buildingService.getNearestBuilding(selectedBuildings, mapCenter);
            this.buildingService.setCurrentBuilding(closestBuilding);
        }
    }

    /**
     * Angular init function.
     *
     * @memberof MapComponent
     */
    async ngOnInit(): Promise<void> {
        await this.mapService.createMap(this.mapElement.nativeElement);
        this.subscriptions
            // View State subscription
            .add(this.viewStateService.getViewStateObservable()
                .subscribe((state: ViewState) => {
                    this.currentViewState = state;

                    // Remove selections on default state
                    if (state === ViewState.Default) {
                        this.deselectCurrentLocation();
                        this.currentRouteElement = null;
                    }
                }))
            // subscribe to current solution change
            .add(this.solutionService.getCurrentSolution()
                .subscribe((solution => {
                    // Set default view on solution change
                    this.viewStateService.setViewStateObservable(ViewState.Default);

                    this.currentSolution = solution;
                    this.graphIds = null;
                })))
            // subscribe to current venue change
            .add(this.venueService.selectedVenue$
                .pipe(
                    tap(venue => {
                        this.currentFloor = null; // Clear currentFloor on ensure that new tiles, locations and network is loaded correct
                        this.currentVenue = venue;
                        this.setVenue(venue);
                        this.locationsMapService.setLocationHighlightIdsFromStorage(this.getLocationHighlightStorageKey());
                    })
                ).subscribe())
            // Floor change subscription
            .add((this.buildingService.getCurrentFloor() as Observable<Floor>)
                .pipe(
                    tap(floor => {
                        // On first map load or floor index change, load new tiles, locations, network, and route elements corresponding to that new floor
                        if (this.currentFloor === null || this.currentFloor.floorIndex !== floor.floorIndex) {
                            this.currentFloor = floor;
                            this.mapService.loadTiles(this.currentVenue, floor.floorIndex);

                            const locationIds = this.filteredLocations.map(location => location.id);
                            this.locationsMapService.setMapObjectsVisibility(locationIds, floor);
                        }
                        // Focus building floor
                        this.addBuildingFocus(floor);

                        // Remove previous and apply new highlights (This is necessary in case the floor geometry is different).
                        this.removeBuildingHighlights();
                        this.addBuildingHighlights(this.currentVenue, this.selectedBuildings, this.currentFloor);
                        if (this.outdoorAreaHighlightSelected) {
                            this.removeOutdoorAreaHighlight();
                            this.addOutdoorAreaHighlight(this.currentVenue, this.buildings, this.currentFloor);
                        }
                    })
                )
                .subscribe())
            // Currently focused building subscription
            .add((this.buildingService.getCurrentBuilding() as Observable<Building>)
                .subscribe(building => this.focusedBuildingFloors = building.floors))
            // subscribe to current location change
            .add(this.locationService.selectedLocation$
                .subscribe(currentLocation => this.setCurrentLocation(currentLocation as ExtendedLocation)))
            // subscribe to route element selection change
            .add(this.networkMapService.getCurrentRouteElement()
                .pipe(filter(routeElement => !!routeElement))
                .subscribe(routeElement => {
                    this.deselectCurrentLocation();

                    this.currentRouteElement = routeElement;
                }))
            // subscribe to graph IDs
            .add(this.graphService.getGraphIds()
                .subscribe(graphIds => {
                    this.viewStateService.setViewStateObservable(ViewState.Update);
                    this.graphIds = graphIds;
                }))
            .add(this.filterBar.activeFilters$
                .pipe(
                    tap(activeFilters => this.activeFilters = activeFilters),
                    switchMap(activeFilters => this.locationService.locations$
                        .pipe(
                            tap((locations: ExtendedLocation[]) => {
                                this.locations = locations;
                                const selectedIds = new Set(Array.from(this.selectedLocations.values()).map(location => location.id));
                                this.selectedLocations = new Set(this.locations.filter(location => selectedIds.has(location.id)));
                            }),
                            filterLocations(activeFilters),
                        ))
                ).subscribe((filteredLocations: ExtendedLocation[]) => {
                    this.filteredLocations = filteredLocations;
                    this.locationsMapService.drawLocations(filteredLocations, this.locationService.defaultIconSize, this.currentFloor?.floorIndex ?? 0);
                    this.updateMap();
                }))
            .add(this.buildingService.buildings$
                .pipe(
                    tap(buildings => this.buildings = buildings),
                    tap(this.highlightBuildingInFocus.bind(this))
                )
                .subscribe());

        this.locationsMapService.initDrawingManager();

        this.mapService.addEventListener('idle', this.highlightBuildingInFocus.bind(this));

        this.mapService.addEventListener('zoom_changed', () => {
            this.currentZoomLevel = this.mapService.getMap().getZoom();

            const locationIds = this.filteredLocations.map(location => location.id);
            this.locationsMapService.setMapObjectsVisibility(locationIds, this.currentFloor);
            this.buildingService.setBuildingLabelsVisibility();
        });
    }

    /**
     * Angular deconstructor.
     *
     * @memberof MapComponent
     */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.mapService.deallocate();
        this.locationService.selectLocation(null);
        this.networkMapService.setCurrentRouteElement(null);
    }

    /**
     * Reset the graphId list.
     *
     * @memberof MapComponent
     */
    public onGraphListClose(): void {
        this.graphIds = null;
        this.graphService.clearGraphIdsList();
        this.viewStateService.setViewStateObservable(ViewState.Default);
    }

    /**
     * Toggle visibility of list view.
     *
     * @memberof MapComponent
     */
    public toggleListView(): void {
        this.isListViewOpen = !this.isListViewOpen;
        this.mapElement.nativeElement.style.pointerEvents = this.isListViewOpen ? 'none' : 'auto';
        this.viewStateService.setViewStateObservable(this.currentLocation ? ViewState.Update : ViewState.Default);
    }

    /**
     * Show/hide network graph and route elements.
     *
     * @param {boolean} visible
     * @memberof MapComponent
     */
    public setNetworkElementsVisibility(visible: boolean): void {
        this.isNetworkVisible = visible;
        this.routeLayerSubscription.unsubscribe();
        if (visible) {
            this.routeLayerSubscription =
                this.routeLayerObservable.pipe(
                    switchMap(() => this.buildingService.selectedFloor$),
                    distinctUntilChanged((currentFloor, previousFloor) => {
                        return currentFloor.solutionId === previousFloor.solutionId
                            && currentFloor.pathData.venue === previousFloor.pathData.venue
                            && currentFloor.floorIndex === previousFloor.floorIndex;
                    }),
                    switchMap(currentFloor => this.networkMapService.getNetworkGraph(this.currentVenue.graphId, currentFloor.floorIndex, this.isNetworkVisible)),
                ).subscribe(() => {
                    this.spinner.hide();
                    this.networkMapService.setNetworkElementsVisibility(this.isNetworkVisible, this.currentFloor.floorIndex);
                }, error => {
                    this.isNetworkVisible = false;
                    this.currentVenue.hasGraph = false;
                    this.spinner.hide();
                    this.notificationService.showError(error);
                });
        } else {
            this.networkMapService.setNetworkElementsVisibility(this.isNetworkVisible, this.currentFloor.floorIndex);
        }
    }

    /**
     * Set or deselect current location.
     * Set visibility of location details component.
     *
     * @param {Location} location
     * @memberof MapComponent
     */
    public setCurrentLocation(location: ExtendedLocation): void {
        if (location) {
            this.deselectRouteElement();
            this.currentLocation = { ...location };
        } else {
            this.currentLocation = null;
        }
    }

    /**
     * Center and zoom the map to fit the given location.
     *
     * @param {Location} location
     * @memberof MapComponent
     */
    public showLocationOnMap(location: ExtendedLocation): void {
        const bbox = turf.bbox(location.geometry);
        this.deselectRouteElement();
        this.toggleListView();
        this.buildingService.setBuildingBasedOnLocation(location);
        this.setFloor(location.floor);
        this.mapService?.setBounds(bbox);
    }

    /**
     * Center and zoom the map to fit the given location, and open Location Details.
     *
     * @param {ExtendedLocation} location
     */
    public showLocationOnMapAndHighlightLocation({ detail: location }: CustomEvent): void {
        // The 736px is the combined value of the width of the Location details editor (720) and its left margin (16).
        const LOCATION_DETAILS_EDITOR_OFFSET = 736;
        const mapPadding = {
            top: 0,
            bottom: 0,
            left: LOCATION_DETAILS_EDITOR_OFFSET,
            right: 0
        };

        this.showLocationOnMap(location);
        this.locationsMapService.highlightLocationOnClick(location);

        // The padding is applied to ensure that the selected Location is visible on the map.
        const bounds = this.mapService.getMap().getBounds().toJSON();
        this.mapService.getMap().fitBounds(bounds, mapPadding);
    }

    /**
     * Deselect currently selected location.
     *
     * @private
     * @memberof MapComponent
     */
    private deselectCurrentLocation(): void {
        this.locationsMapService.clearCurrentAreaHighlight(this.currentLocation);
        this.locationsMapService.clearRoomHighlight(this.currentLocation);
        this.currentLocation = null;
        this.locationService.selectLocation(null);
        this.locationsMapService.removeNewPOIMarker();
        this.locationsMapService.removeEditLocationMarker();
    }

    /**
     * Deselect current route element.
     *
     * @private
     * @memberof MapComponent
     */
    private deselectRouteElement(): void {
        this.currentRouteElement = null;
        this.networkMapService.cancelRouteElementEditMode();
        this.networkMapService.removeNewMarker();
    }

    /**
     * Initialize the map.
     *
     * @private
     * @param {Venue} venue
     * @memberof MapComponent
     */
    private setVenue(venue: Venue): void {
        const floorIndex = +(this.currentFloor?.floorIndex ?? venue.defaultFloor);
        this.mapService.setVenue(venue, floorIndex);

    }

    /**
     * Set the current floor observable.
     *
     * @param {Floor} floor
     * @memberof MapComponent
     */
    public setFloor(floor: Floor): void {
        this.buildingService.setCurrentFloor(floor);
    }

    /**
     * Refresh the content on the map.
     *
     * @memberof MapComponent
     */
    public updateMap(): void {
        let selectedBuildings = this.activeFilters?.buildings?.length > 0 ? [...this.activeFilters.buildings] : [];
        this.outdoorAreaHighlightSelected = selectedBuildings.some(building => building.id === OUTDOOR_BUILDING.id);

        this.removeBuildingHighlights();
        this.removeOutdoorAreaHighlight();

        if (this.outdoorAreaHighlightSelected) {
            // Remove outside option from buildings array to prevent highlight and fit building functionality to execute
            selectedBuildings = selectedBuildings.filter(buildigns => buildigns.id !== OUTDOOR_BUILDING.id);

            // Highlight outdoor area (venue geometry)
            this.addOutdoorAreaHighlight(this.currentVenue, this.buildings, this.currentFloor);
        }

        if (selectedBuildings.length > 0) {
            this.selectedBuildings = selectedBuildings;

            // Highlight selected buildings
            this.addBuildingHighlights(this.currentVenue, selectedBuildings, this.currentFloor);

            // Fit buildings in view
            const buildingsBounds = getCollectedBounds(selectedBuildings.map(building => building.geometry.bbox));
            this.mapService.getMap().fitBounds(buildingsBounds);
        } else {
            this.selectedBuildings = [];
        }

        const locationIds = this.filteredLocations.map(location => location.id);
        this.locationsMapService.setMapObjectsVisibility(locationIds, this.currentFloor);
    }

    /**
     * Get session storage key for location highlights.
     *
     * @private
     * @returns {string}
     * @memberof MapComponent
     */
    private getLocationHighlightStorageKey(): string {
        return `${this.currentSolution.id}-${this.currentVenue.id}-splittedAndCombinedLocations`;
    }

    /**
     * Start the add door flow.
     *
     * @param {Location} location
     */
    public startAddDoorFlow(location: Location): void {
        if (this.isListViewOpen) {
            this.toggleListView();
        }

        this.viewStateService.setViewStateObservable(ViewState.AddDoor);
        this.routeElementGeometryService.addDoor(location);

        // Make the details editor disappear while adding the door
        this.deselectCurrentLocation();
    }

    /**
     * Start flow for adding multiple doors.
     *
     * @param {Location} location
     */
    public startAddMultipleDoorsFlow(location: Location): void {
        if (this.isListViewOpen) {
            this.toggleListView();
        }

        this.viewStateService.setViewStateObservable(ViewState.AddMultipleDoors);
        this.routeElementGeometryService.addDoors(location);

        // Make the details editor disappear while adding doors
        this.deselectCurrentLocation();
    }

    /**
     * Opens a dialog for splitting the location into two.
     *
     * @memberof MapComponent
     */
    public openSplitLocationDialog(): void {
        const dialogConfig: MatDialogConfig = {
            data: {
                venue: this.currentVenue,
                location: this.currentLocation
            },
            width: '90%',
            height: '90%',
            disableClose: true,
            hasBackdrop: true,
            role: 'dialog',
            ariaLabel: 'Split / Combine Room',
            closeOnNavigation: false
        };

        const dialogRef = this.matDialog.open(SplitLocationComponent, dialogConfig);

        // Make the details editor disappear while working on splitting
        this.deselectCurrentLocation();

        dialogRef.afterClosed()
            .pipe(finalize(() => this.spinner.hide()))
            .subscribe((payload) => {
                if (!payload?.newLocation) {
                    this.viewStateService.setViewStateObservable(ViewState.Default);
                    return;
                }

                this.locationsMapService.addLocationHighlightId(this.getLocationHighlightStorageKey(), payload.newLocation.id);
                this.locationsMapService.addLocationHighlightId(this.getLocationHighlightStorageKey(), payload.originalLocation.id);

                // Add the new location to the locations and re-render them all
                this.locations = [...this.locations, payload.newLocation];
                this.filteredLocations = this.locations.slice();
                this.locationsMapService.drawLocations(this.filteredLocations, this.locationService.defaultIconSize, this.currentFloor?.floorIndex);

                // Set the new location as current location in order to trigger the location details editor.
                this.locationService.selectLocation(payload.newLocation);
                this.locationsMapService.highlightRoom(payload.newLocation);
            });
    }

    /**
     * Opens a dialog for combining the location with another one.
     *
     * @memberof MapComponent
     */
    public openCombineLocationsDialog(): void {
        const dialogConfig: MatDialogConfig = {
            data: {
                venue: this.currentVenue,
                location: this.currentLocation,
                locations: this.combineService.getAdjacentRooms(this.currentLocation, this.locations)
            },
            width: '90%',
            height: '90%',
            disableClose: true,
            hasBackdrop: true,
            role: 'dialog',
            ariaLabel: 'Split / Combine Room',
            closeOnNavigation: false
        };

        const dialogRef = this.matDialog.open(CombineLocationsComponent, dialogConfig);

        // Make the details editor disappear while working on combining
        this.deselectCurrentLocation();

        dialogRef.afterClosed()
            .pipe(finalize(() => this.spinner.hide()))
            .subscribe((payload) => {
                if (!payload?.deletedLocation || !payload?.combinedLocation) {
                    this.viewStateService.setViewStateObservable(ViewState.Default);
                    return;
                }

                this.locationsMapService.deleteLocationHighlightId(this.getLocationHighlightStorageKey(), payload.deletedLocation.id);
                this.locationsMapService.addLocationHighlightId(this.getLocationHighlightStorageKey(), payload.combinedLocation.id);

                // Remove the deleted from the locations and re-render them all
                this.locations = this.locations.filter(location => location.id !== payload.deletedLocation.id);
                this.filteredLocations = this.locations.slice();
                this.locationsMapService.drawLocations(this.filteredLocations, this.locationService.defaultIconSize, this.currentFloor?.floorIndex);

                // Set the combined location as current location in order to trigger the location details editor.
                this.locationService.selectLocation(payload.combinedLocation);
                this.locationsMapService.highlightRoom(payload.combinedLocation);
            });
    }

    /**
     * Delete a Location and remove it from the map and the List View.
     *
     * @returns {void}
     * @memberof MapComponent
     */
    public onDeleteLocation(): void {
        if (this.currentLocation.locationType.toLowerCase() === 'room') {
            this.notificationService.showError('You cannot delete a Room. Only a POI or an Area can be deleted');
            return;
        }

        // eslint-disable-next-line no-alert
        if (this.currentLocation?.id && confirm('Do you really want to delete this Location?')) {
            this.spinner.show();
            const locationClone = { ...this.currentLocation };
            this.locationService.deleteLocation(locationClone)
                .pipe(finalize(() => this.spinner.hide()))
                .subscribe(
                    () => {
                        this.viewStateService.setViewStateObservable(ViewState.Default);
                        this.locationsMapService.deleteLocationMapObject(locationClone);
                        this.locations = this.locations.filter(location => location.id !== locationClone.id);
                        this.filteredLocations = this.filteredLocations.filter(location => location.id !== locationClone.id);
                        this.notificationService.showSuccess('Location deleted');
                    },
                    error => this.notificationService.showError(error)
                );
        }
    }

    /**
     * Check if the bulk-edit mode can be enabled.
     *
     * @private
     * @memberof MapComponent
     */
    private setEditButtonState(): void {
        this.canOpenBulkEdit = this.selectedLocations?.size > 0 && !this.isBulkEditorOpen;
    }

    /**
     * Get the selected rows from the table.
     *
     * @param {CustomEvent} Object - With detail property.
     * @memberof MapComponent
     */
    public onListViewRowSelectionChanged({ detail: locations }: CustomEvent): void {
        this.selectedLocations = locations as Set<ExtendedLocation>;
        this.setEditButtonState();
    }

    /**
     * Close the bulk editor and clear the selected rows in the list view.
     *
     * @memberof MapComponent
     */
    public closeBulkEditor(): void {
        this.isBulkEditorOpen = false;
        this.setEditButtonState();
    }

    /**
     * Open the bulk editor.
     *
     * @memberof MapComponent
     */
    public onOpenBulkEditor(): void {
        this.isBulkEditorOpen = true;
        this.setEditButtonState();
    }

    /**
     * Update locations and close the bulk editor.
     *
     * @memberof MapComponent
     */
    public onBulkLocationsUpdate(): void {
        this.closeBulkEditor();
    }

    /**
     * Get and show a graph by its ID.
     *
     * @param {string} graphId
     * @memberof MapComponent
     */
    public onActiveGraphChange(graphId: string): void {
        this.isNetworkVisible = true;
        const subscription = this.networkMapService.getNetworkGraph(graphId, this.currentFloor.floorIndex, this.isNetworkVisible).subscribe(() => {
            subscription.unsubscribe();
        });
    }

    /**
     * Get floor corresponding to provided floor index.
     *
     * @private
     * @param {Building} building
     * @param {number} floorIndex
     * @returns {Floor}
     * @memberof MapComponent
     */
    private getCorrespondingFloor(building: Building, floorIndex: Number): Floor {
        return building.floors.find((floor) => floor.floorIndex === floorIndex);
    }

    /**
     * Remove highlight of buildings.
     *
     * @private
     * @memberof MapComponent
     */
    private removeBuildingHighlights(): void {
        this.selectedBuildingsHighlights.forEach(polygon => polygon.setMap(null));
        this.selectedBuildingsHighlights = [];
    }

    /**
     * Add highlight style to buildings.
     *
     * @private
     * @param {Venue} venue
     * @param {Building[]} buildings
     * @param {Floor} currentFloor
     * @memberof MapComponent
     */
    private addBuildingHighlights(venue: Venue, buildings: Building[], currentFloor: Floor): void {
        if (this.currentFloor && this.currentFloor.floorIndex) {
            buildings.forEach((building) => {
                const floor = this.getCorrespondingFloor(building, currentFloor.floorIndex) || this.buildingService.getDefaultFloor(venue, building);
                const floorCoordinates = floor.geometry.coordinates.map(polygon => polygon.map(coord => new google.maps.LatLng(coord[1], coord[0])));
                const polygon = new google.maps.Polygon({
                    paths: floorCoordinates,
                    strokeColor: '#ef6cce',
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillOpacity: 0,
                    clickable: false,
                    map: this.mapService.getMap(),
                    zIndex: 1
                });
                this.selectedBuildingsHighlights.push(polygon);
            });
        }
    }


    /**
     * Remove outdoor area highlights from venue.
     *
     * @private
     * @memberof MapComponent
     */
    private removeOutdoorAreaHighlight(): void {
        this.outdoorAreaHighlights.forEach(polygon => polygon.setMap(null));
        this.outdoorAreaHighlights = [];
    }

    /**
     * Add outdoor area highlights on venue.
     *
     * @private
     * @param {Venue} venue
     * @param {Building[]} buildings
     * @param {Floor} currentFloor
     * @memberof MapComponent
     */
    private addOutdoorAreaHighlight(venue: Venue, buildings: Building[], currentFloor: Floor): void {
        // Extract each building geometry from venues geometry
        let outdoorAreaPolygon: Feature<(Polygon | MultiPolygon)> = turf.polygon([...venue.geometry.coordinates]);
        buildings.forEach(building => {
            const floor = this.getCorrespondingFloor(building, currentFloor.floorIndex) || this.buildingService.getDefaultFloor(venue, building);
            const buildingPolygon = turf.multiPolygon(floor.geometry.coordinates);
            outdoorAreaPolygon = turf.difference(outdoorAreaPolygon, buildingPolygon);
        });

        // Map coordinates as google maps coordinates
        let paths: google.maps.LatLng[][] | google.maps.LatLng[][][] = [];
        if (outdoorAreaPolygon.geometry.type === 'Polygon') {
            paths = [(outdoorAreaPolygon.geometry.coordinates as Position[][]).map(polygon => {
                return polygon.map(coordinate => new google.maps.LatLng(coordinate[1], coordinate[0]));
            })];
        } else {
            paths = (outdoorAreaPolygon.geometry.coordinates as Position[][][]).map(polygons => {
                return polygons.map(polygon => polygon.map(coordinate => new google.maps.LatLng(coordinate[1], coordinate[0])));
            });
        }

        paths.forEach(path => {
            const polygon = new google.maps.Polygon({
                paths: path,
                fillColor: '#ef6cce',
                strokeColor: '#ef6cce',
                strokeOpacity: 1,
                strokeWeight: 1,
                fillOpacity: .1,
                clickable: false,
                zIndex: 1,
                map: this.mapService.getMap()
            });
            this.outdoorAreaHighlights.push(polygon);
        });
    }

    /**
     * Add focus style to building.
     *
     * @private
     * @param {Floor} currentFloor
     * @memberof MapComponent
     */
    private addBuildingFocus(currentFloor: Floor): void {
        const floorCoordinates = currentFloor.geometry.coordinates.map(polygon => polygon.map(coord => new google.maps.LatLng(coord[1], coord[0])));
        this.focusedBuildingPolygon.setOptions({
            paths: floorCoordinates,
            map: this.mapService.getMap()
        });
    }
}
