import * as turf from '@turf/turf';

import { ExtendedLocation, LocationService } from '../../locations/location.service';
import { Feature, FeatureCollection, Geometry, LineString, Point, Polygon, Position } from 'geojson';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Location } from '../../locations/location.model';
import { PolygonSplit } from '../../shared/polygon-split-validation/PolygonSplit';
import { primitiveClone } from '../../shared/object-helper';

@Injectable({
    providedIn: 'root'
})
export class SplitService {

    constructor(
        private locationService: LocationService
    ) { }

    private locationPolygon: Feature<Polygon>;             // The polygon for the selected location.
    private originalGeometry: Geometry;                    // Original polygon data for the location used in case of revert

    public splitPoint1: Feature<Point>;                    // The start point on the split line.
    public splitPoint1EdgeIndex: number;                   // On which edge on the polygon is the split point

    public splitPoint2: Feature<Point>;                    // The end point on the split line.

    public splitLocationFeatures: Feature<Polygon>[];      // The two locations after split

    public originalSplitLocationFeature: Feature<Polygon>; // The split location selected as the original one.
    public newSplitLocationFeature: Feature<Polygon>;      // The split location selected as the new one.

    public locationData: ExtendedLocation;                 // Location data of selected location

    /**
     * Initialize split.
     *
     * @param {ExtendedLocation} location - To split on.
     * @memberof SplitService
     */
    public initialize(location: ExtendedLocation): void {
        this.locationData = location;
        this.originalGeometry = location.geometry as Geometry;
        this.locationPolygon = turf.polygon(location.geometry.coordinates);
    }

    /**
     * Check if the current split line will result in a valid split.
     *
     * @returns {boolean}
     * @memberof SplitService
     */
    public isSplitValid(splitPoint2?): boolean {
        const splitLineString: Feature<LineString> = turf.lineString([
            this.splitPoint1.geometry.coordinates,
            splitPoint2 ? splitPoint2.geometry.coordinates : this.splitPoint2.geometry.coordinates
        ]);

        const validationResult = PolygonSplit.validate({
            lineString: splitLineString,
            polygon: this.locationPolygon
        });

        if (validationResult.isValid === false) {
            console.warn(validationResult.error); /* eslint-disable-line no-console */
        }

        return validationResult.isValid;
    }

    /**
     * Get the point on the location polygon that is closest to a given point.
     *
     * @param {Position} position - The point to get the nearest point on location polygon for.
     * @memberof SplitService
     * @returns {Feature<Point>}
     */
    public getNearestPointOnLocationPolygon(position: Position): Feature<Point> {
        return turf.nearestPointOnLine(turf.lineString(this.locationPolygon.geometry.coordinates[0]), position); // TODO: Does not support MultiPolygon (MICMS3-1364)
    }

    /**
     * Calculate the second point on the split line:
     * 1. The point is on the location polygon.
     * 2. The split line is perpendicular to the polygon edge from the first point on the split line.
     *
     * @memberof SplitService
     * @returns {Point}
     */
    public getPerpendicularPoint(): Feature<Point> {
        // Calculate bearing of the polygon edge where the split point 1 is.
        // TODO: Does not support MultiPolygon (MICMS3-1364)
        const marker1EdgeBearing: number = turf.bearing(this.locationPolygon.geometry.coordinates[0][this.splitPoint1EdgeIndex], this.locationPolygon.geometry.coordinates[0][this.splitPoint1EdgeIndex + 1]);

        // Calculate point that is perpendicular to the polygon edge, and far away (1km)
        const pointPerpendicularToMarker1Edge: Feature<Point> = turf.destination(this.splitPoint1, 1, marker1EdgeBearing + 90);

        // Generate line from split point 1 to the perpendicular point.
        // The line is scaled up a little to make sure there will be intersections on the location polygon.
        const perpendicularLine: Feature<LineString> = turf.transformScale(
            turf.lineString([this.splitPoint1.geometry.coordinates, pointPerpendicularToMarker1Edge.geometry.coordinates]),
            1.1
        );

        // To find the perpendicular point on the location polygon, locate intersections between the rotated lineString and the location polygon.
        const intersections: FeatureCollection<Point> = turf.lineIntersect(perpendicularLine, turf.lineString(this.locationPolygon.geometry.coordinates[0])) as FeatureCollection<Point>;
        let polygonIntersections: Feature<Point>[];
        if (intersections) {
            // Filter out points on the original edge
            polygonIntersections = intersections.features.filter((feature: Feature<Point>) => {
                const intersectionPoint = turf.truncate(turf.point(feature.geometry.coordinates));
                const splitPoint = turf.truncate(this.splitPoint1);
                return turf.booleanEqual(intersectionPoint, splitPoint) === false;
            });

            return polygonIntersections[0]; // We don't allow splitting on mulitple intersections anyway, so it's safe to assume to take the first one.
        }
    }

    /**
     * Generate two new location polygons from the selected location spliting by the line generated by the two split points.
     *
     * @memberof SplitService
     */
    public generateTwoLocationsFromSplitLine(): void {
        const splitLineString: Feature<LineString> = turf.lineString([
            this.splitPoint1.geometry.coordinates,
            this.splitPoint2.geometry.coordinates
        ]);

        const locationPolygon = turf.rewind(this.locationPolygon);
        this.splitLocationFeatures = PolygonSplit.split({ lineString: splitLineString, polygon: locationPolygon });
    }

    /**
     * Save the split by updating existing location and creating a new one.
     *
     * @memberof SplitService
     * @returns {Observable<string>} The id of the new created location.
     */
    public saveSplit(): Observable<Location | void> {
        return this.updateAndSaveSelectedLocationGeometry()
            .pipe(
                catchError(error => {
                    return throwError(error);
                }),
                mergeMap(() => this.createClonedLocation()),
                catchError(() => {
                    // Creation of new location failed. Try to update the original location with the original geometry.
                    this.originalSplitLocationFeature.geometry = this.originalGeometry as any;
                    return this.updateAndSaveSelectedLocationGeometry();
                })
            );
    }

    /**
     * Updates the original location with the new geometry.
     *
     * @memberof SplitService
     * @returns {Observable<void>}
     */
    private updateAndSaveSelectedLocationGeometry(): Observable<void> {
        this.originalSplitLocationFeature.geometry.bbox = turf.bbox(this.originalSplitLocationFeature.geometry);
        this.locationData.geometry = this.originalSplitLocationFeature.geometry as any;

        return this.locationService.updateLocation(this.locationData);
    }

    /**
     * Create and save a new location cloned from the original location data.
     *
     * @memberof SplitService
     * @returns {Observable<Location>}
     */
    private createClonedLocation(): Observable<Location> {
        const locationClone: ExtendedLocation = primitiveClone(this.locationData);

        this.newSplitLocationFeature.geometry.bbox = turf.bbox(this.newSplitLocationFeature.geometry);
        locationClone.geometry = this.newSplitLocationFeature.geometry as any;

        // These properties should not be transferred to the new location, but be generated in the backend.
        delete locationClone.id;
        delete locationClone.syncId;
        delete locationClone.createdBy;
        delete locationClone.createdAt;
        delete locationClone.anchor;

        return this.locationService.createLocation(locationClone);
    }
}
