import { LineString, MultiLineString } from 'geojson';

import { ViewModelProperties } from '../ViewModelProperties/ViewModelProperties';
import { DisplayRule } from '../../app/locations/location.model';
import { MapViewModel, SortKey } from '../MapViewModelFactory/MapViewModelFactory';
import { FeatureClass } from '../FeatureClass';
import { MapsIndoorsData } from '../../app/shared/enums/MapsIndoorsData';

export interface LineStringViewModelProperties extends ViewModelProperties  {
    clickable: boolean;
    strokeColor: string;
    strokeOpacity: number;
    strokeWidth: number;
}

export class LineStringViewModel implements MapViewModel {
    readonly id: string;
    readonly type = 'Feature';
    readonly geometry: LineString | MultiLineString;
    readonly properties: LineStringViewModelProperties;

    protected constructor(id: string, geometry: LineString | MultiLineString, properties: LineStringViewModelProperties) {
        this.id = `LINE:${id}`;
        this.geometry = geometry;
        this.properties = properties;
    }

    /**
     * Factory function to create a LineStringViewModel from a Location and a DisplayRule.
     *
     * @static
     * @param {string} id
     * @param {GeoJSON.LineString | GeoJSON.MultiLineString} geometry
     * @param {DisplayRule} displayRule
     * @returns {LineStringViewModel}
     * @memberof LineStringViewModel
     */
    static create(id: string, geometry: GeoJSON.LineString | GeoJSON.MultiLineString, displayRule: DisplayRule, sortKey: number, originalType: MapsIndoorsData): LineStringViewModel {
        const properties: LineStringViewModelProperties = {
            originalId: id,
            originalType,
            clickable: displayRule.clickable,
            strokeColor: displayRule.polygon?.strokeColor,
            strokeOpacity: displayRule.polygon?.strokeOpacity,
            strokeWidth: displayRule.polygon?.strokeWidth,
            featureClass: FeatureClass.LINESTRING,
            sortKey: SortKey.LINESTRING + sortKey,
            zoomRange: [displayRule?.polygon.zoomFrom, displayRule?.polygon.zoomTo]
        };

        return new LineStringViewModel(id, geometry, properties);
    }
}