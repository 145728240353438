import { AfterContentInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GeoJSONGeometryType, RegexPatterns, UnitSystem } from '../../shared/enums';
import { RouteElement, RouteElementType } from './route-element.model';
import { ViewState, ViewStateService } from '../view-state.service';
import { getCenterPosition, getDoorWidth } from '../../shared/geometry-helper';

import { AppUserRole } from '../../app-settings/config/app-user-roles/app-user-role.model';
import { AppUserRolesService } from '../../app-settings/config/app-user-roles/app-user-roles.service';
import { GeometryObject } from '@turf/turf';
import { LocationService } from '../../locations/location.service';
import { NetworkMapService } from '../network-map.service';
import { Position } from 'geojson';
import { RouteElementGeometryService } from '../../services/route-element-geometry.service';
import { Subscription } from 'rxjs';
import { UserAgentService } from '../../services/user-agent.service';
import isEqual from 'fast-deep-equal';
import { primitiveClone } from '../../shared/object-helper';

@Component({
    selector: 'route-element-details',
    templateUrl: './route-element-details.component.html',
    styleUrls: ['./route-element-details.component.scss']
})
export class RouteElementDetailsComponent implements OnInit, OnDestroy, AfterContentInit {
    #appUserRoles: AppUserRole[] = [];
    private originalFormState;

    /**
     * The selected route element.
     *
     * @property {RouteElement} routeElement
     * @memberof RouteElementDetailsComponent
     */
    @Input() set routeElement(routeElement: RouteElement) {
        this._routeElement = routeElement;

        const routeElementOriginalState: any = {
            elementValue: routeElement.elementValue === undefined ? null : routeElement.elementValue,
            floorIndex: routeElement.floorIndex,
            onewayDirection: routeElement.onewayDirection === undefined ? null : routeElement.onewayDirection,
            radius: routeElement.radius,
            subtype: routeElement.subtype === undefined ? null : routeElement.subtype,
            restrictions: routeElement.restrictions ?? [],
            waitTime: routeElement.waitTime,
            width: getDoorWidth(routeElement, this.unitSystem)
        };

        this.originalFormState = Object.freeze(primitiveClone(routeElementOriginalState));

        if (this.form) {
            this.setFormValidators(this.routeElement);
            this.form.patchValue(this.originalFormState);
        }

        this.unitSystem = this.userAgentService.unitSystem;
    }

    /**
     * The selected route element.
     *
     * @returns {RouteElement}
     * @memberof RouteElementDetailsComponent
     */
    get routeElement(): RouteElement {
        return this._routeElement;
    }

    /**
     * App UserRoles.
     *
     * @readonly
     * @type {AppUserRole[]}
     * @memberof RouteElementDetailsComponent
     */
    get appUserRoles(): AppUserRole[] {
        return this.#appUserRoles;
    }

    private _routeElement: RouteElement;
    private subscriptions = new Subscription();

    public unitSystem: UnitSystem;
    public geoJSONGeometryType = GeoJSONGeometryType;
    public lineStringLengthInputValue: number; // in browser unit system

    public form: FormGroup;
    public routeElementTypes = RouteElementType;

    /**
     * RouteElementType enum.
     *
     * @readonly
     * @type {typeof RouteElementType}
     * @memberof RouteElementDetailsComponent
     */
    public get routeElementType(): typeof RouteElementType {
        return RouteElementType;
    }

    constructor(
        private viewStateService: ViewStateService,
        private formBuilder: FormBuilder,
        private networkMapService: NetworkMapService,
        private userAgentService: UserAgentService,
        private routeElementGeometryService: RouteElementGeometryService,
        private appUserRolesService: AppUserRolesService,
        private locationService: LocationService
    ) { }

    /**
     * Called after Angular has initialized all data-bound properties of a directive.
     */
    ngOnInit(): void {
        this.createForm(this.originalFormState);
        this.setFormValidators(this.routeElement);

        this.subscriptions
            .add(this.routeElementGeometryService.getGeometryIsValid()
                .subscribe(geometryIsValid => {
                    this.form.controls['width'].setErrors(geometryIsValid ? null : { invalidGeometry: true });
                }))
            .add(this.routeElementGeometryService.getUpdatedGeometry()
                .subscribe(updatedGeometry => {
                    if (updatedGeometry) {
                        (this.routeElement.geometry as GeometryObject) = updatedGeometry;
                    }
                }))
            .add(this.appUserRolesService.appUserRoles$
                .subscribe(roles => this.#appUserRoles = roles))
            .add(this.form.controls['width'].valueChanges
                .subscribe((width) => {
                    if (!width || width < 0) return;

                    const lengthInMeters = this.unitSystem === UnitSystem.Imperial ? width / 39.370 : width / 100;
                    this.routeElementGeometryService.updateDoorWidthWhenEditing(lengthInMeters);
                }));
    }

    /**
     * Called after Angular has fully initialized a component's view - we need to make sure that the content is loaded before subscribing to value changes.
     */
    ngAfterContentInit(): void {
        if (!this._routeElement.id) {
            this.form.markAsDirty({ onlySelf: true });
        } else {
            this.subscriptions
                .add(this.form.valueChanges
                    .subscribe(formState => {
                        // compare the original state and the current state to see if they are the same.
                        !isEqual(this.originalFormState, formState)
                            ? this.form.markAsDirty({ onlySelf: true })
                            : this.form.markAsPristine({ onlySelf: true });
                    }));
        }

        // notify observers of the location details form's status
        this.locationService.isRouteElementDetailsEditorDirty$.next(this.form.dirty);
    }

    /**
     * Called when a directive, pipe, or service is destroyed.
     */
    ngOnDestroy(): void {
        this.locationService.isRouteElementDetailsEditorDirty$.next(false);
        this.subscriptions.unsubscribe();
    }

    /**
     * Creating the main form goup.
     */
    private createForm(initialValues): void {
        this.form = this.formBuilder.group({
            elementValue: [initialValues.elementValue],
            width: [initialValues.width, [Validators.min(0)]],
            waitTime: [initialValues.waitTime, [Validators.min(0)]],
            radius: [initialValues.radius, [Validators.min(0), Validators.required]],
            floorIndex: [initialValues.floorIndex, [Validators.required, Validators.pattern(RegexPatterns.NumericalNoDecimals)]],
            onewayDirection: [initialValues.onewayDirection, [Validators.min(0), Validators.max(360)]],
            subtype: [initialValues.subtype],
            restrictions: [initialValues.restrictions]
        });
    }

    /**
     * Set extra form validators depending on route element type.
     *
     * @private
     * @param {RouteElement} Object - With type property.
     * @memberof RouteElementDetailsComponent
     */
    private setFormValidators({ type }: RouteElement): void {
        // Required when type of connector, door or entry point
        if (type === RouteElementType.Connector) {
            this.form.controls['elementValue'].setValidators(Validators.required);
        } else {
            this.form.controls['elementValue'].setValidators([]);
        }
    }

    /**
     * Get anchor point for the route element.
     *
     * @memberof RouteElementDetailsComponent
     * @returns {Position}
     */
    public getAnchorPoint(): Position {
        return getCenterPosition(this.routeElement.geometry);
    }

    /**
     * Save route element.
     *
     * @memberof RouteElementDetailsComponent
     */
    public onSave(): void {
        if (this.form.invalid) {
            return;
        }

        const lastModified = new Date().toISOString(); // TODO: this should be changed when https://mapspeople.atlassian.net/browse/MIBAPI-3391 is done
        const routeElement: RouteElement = { ...this.routeElement, ...this.form.value }; //creates one object of the two, overwriting the first objects values with the second one (if relevant)

        routeElement.lastModified = lastModified;
        this.networkMapService.saveRouteElement(routeElement);
    }

    /**
     * Cancel editing of route element and close details panel.
     *
     * @memberof RouteElementDetailsComponent
     */
    public onCancel(): void {
        // eslint-disable-next-line no-alert
        if ((this.form.dirty && confirm('Your unsaved changes will be lost! Would you like to continue?')) || !this.form.dirty) {
            this.viewStateService.setViewStateObservable(ViewState.Default);
            this.locationService.isRouteElementDetailsEditorDirty$.next(false);
            this.networkMapService.cancelRouteElementEditMode();
            this.networkMapService.setCurrentRouteElement(null);
            this.networkMapService.removeNewMarker();
            this.form.reset(this.originalFormState);
        }
    }

    /**
     * Delete route element.
     *
     * @memberof RouteElementDetailsComponent
     */
    public onDelete(): void {
        // eslint-disable-next-line no-alert
        if (confirm('Do you want to delete this Route Element?')) {
            if (!this.routeElement.id) {
                this.onCancel();
                return;
            }

            this.networkMapService.deleteRouteElement(this.routeElement);
        }
    }

    /**
     * React on keyboard presses.
     *
     * @param {KeyboardEvent} event
     * @memberof RouteElementDetailsComponent
     */
    public handleHotkeys(event: KeyboardEvent): void {
        if (event.key.toLowerCase() === 'escape') {
            this.onCancel();
        }
    }
}
