import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { LanguageService } from '../../services/language.service';

@Component({
    selector: 'app-custom-properties',
    templateUrl: './custom-properties.component.html',
    styleUrls: ['./custom-properties.component.scss']
})
export class CustomPropertiesComponent implements OnInit, OnDestroy {
    @Input() defaultLanguageCode: string;
    @Input() translations;

    private regex = new RegExp(/[^a-zA-Z0-9_-]/g);
    private keyInputChanged: Subject<{ key: string, index: number }> = new Subject<{ key: string, index: number }>();
    public subs = new Subscription();
    public customError: { message: string, index: number };

    constructor(private languageService: LanguageService) { }

    ngOnInit(): void {
        this.customError = null;
        this.subs.add(this.keyInputChanged
            .pipe(
                debounceTime(200),
                distinctUntilChanged(),
                // check if key has special characters
                filter(input => {
                    if (input.key.match(this.regex)) {
                        this.customError = { message: 'No special characters', index: input.index };
                        return false;
                    }
                    return true;
                }),
                // check if key already exists
                filter(input => {
                    if (this.isKeyInLocationTypeTemplate(input.key) || this.isKeyInCustomProperties(input.key)) {
                        this.customError = { message: 'Key already exists', index: input.index };
                        return false;
                    }
                    return true;
                }))
            .subscribe(input => {
                this.customError = null;
                //Copy key to all available languages
                this.translations.forEach(translation => {
                    if (translation.customProperties[input.index].key !== input.key) {
                        translation.customProperties[input.index].key = input.key;
                    }
                });
            }));
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    /**
     * Get the language name given the code.
     *
     * @param {string} code
     * @returns {string}
     * @memberof CustomPropertiesComponent
     */
    public getLanguageNameWithCode(code: string): string {
        return this.languageService.getLanguageList().find(language => code === language.code).name;
    }

    /**
     * Check if the key already exists as a location type template key.
     *
     * @private
     * @param {string} key
     * @returns {boolean}
     * @memberof CustomPropertiesComponent
     */
    private isKeyInLocationTypeTemplate(key: string): boolean {
        return this.translations[0].customFields
            ? this.translations[0].customFields.some(field => field.name.toLowerCase() === key.toLowerCase())
            : false;
    }

    /**
     * Check if the key already exists as a custom property key.
     *
     * @private
     * @param {string} key
     * @returns {boolean}
     * @memberof CustomPropertiesComponent
     */
    private isKeyInCustomProperties(key: string): boolean {
        return this.translations[0].customProperties.some(customProp => customProp.key.toLowerCase() === key.toLowerCase());
    }

    /**
     * Add a custom property in each language.
     *
     * @memberof CustomPropertiesComponent
     */
    public addCustomProperty(): void {
        this.translations.forEach(translation => {
            translation.customProperties.push({ key: '', value: '', type: 'text' });
        });
    }

    /**
     * Emit the newly typed key.
     *
     * @param {string} newKey
     * @param {number} index
     * @memberof CustomPropertiesComponent
     */
    public onKeyChanged(newKey: string, index: number): void {
        this.keyInputChanged.next({ key: newKey, index });
    }

    /**
     * Remove custom property from array in all languages.
     *
     * @param {number} index
     * @memberof CustomPropertiesComponent
     */
    public deleteCustomProperty(index: number): void {
        this.translations.forEach(translation => {
            translation.customProperties.splice(index, 1);
        });
    }
}
