import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DoorType, EntryPointTypes, RouteElement, RouteElementType } from '../../../map/route-element-details/route-element.model';
import { ElementSubType, NetworkService } from '../../../network-access/network.service';

interface SpecificEntryPoint {
    key: number;
    title: string;
}

@Component({
    selector: 'route-element-subtype',
    templateUrl: './route-element-subtype.component.html',
    styleUrls: ['./route-element-subtype.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => RouteElementSubtypeComponent),
        multi: true,
    }]
})
export class RouteElementSubtypeComponent implements ControlValueAccessor, OnInit, OnDestroy {
    /**
     * Every time a new route element is selected.
     */
    @Input() set routeElement(routeElement: RouteElement) {
        this._routeElement = routeElement;
        this.subtypesArray = this.networkService.getSubtypes(this._routeElement.type);
        this.setSubtypeFormControlValue();
        this.setSpecificEntryPointsFormArray(this.specificEntryPoints, routeElement.subtype);
        this.originalForm = this.form.value;
    }

    private _routeElement: RouteElement;
    private subscriptions = new Subscription();
    private originalForm: {specificSubtype: number, specificEntryPoints: []};

    public subtypesArray: ElementSubType[] = [];
    public entryPointTypes = EntryPointTypes;
    public subtypeFormControl = new FormControl([]);
    public form = this.formBuilder.group({
        specificSubtype: [0, [Validators.required]],
        specificEntryPoints: this.formBuilder.array([])
    });
    public specificEntryPoints: SpecificEntryPoint[] = [
        { key: EntryPointTypes.WalkingEntry, title: 'Walking Entry' },
        { key: EntryPointTypes.DrivingEntry, title: 'Driving Entry' },
        { key: EntryPointTypes.BicyclingEntry, title: 'Bicycling Entry' },
        { key: EntryPointTypes.TransitEntry, title: 'Transit Entry', }
    ];

    public onChange: (subtype: number) => void = () => { };
    public onTouch: () => void = () => { };

    constructor(
        private formBuilder: FormBuilder,
        private networkService: NetworkService
    ) { }

    /**
     * Initialize the directive or component.
     */
    ngOnInit(): void {
        this.subscriptions
            .add(this.subtypeFormControl.valueChanges
                .subscribe(() => {
                    if (this.subtypeFormControl.value !== this.specificSubtypeFormControl.value) {
                        this.form.setValue(this.originalForm);
                    }
                }));
    }

    /**
     * Cleanup just before Angular destroys the directive or component.
     */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Get subtype form control.
     *
     * @readonly
     * @type {FormControl}
     * @memberof RouteElementSubtypeComponent
     */
    get subtypeTypeFormControl(): FormControl {
        return this.form.controls['subtype'] as FormControl;
    }

    /**
     * Get subtype form control from the form.
     *
     * @readonly
     * @type {FormArray}
     * @memberof RouteElementSubtypeComponent
     */
    get specificSubtypeFormControl(): FormArray {
        return this.form.get('specificSubtype') as FormArray;
    }

    /**
     * Get specificEntryPoints form array.
     *
     * @readonly
     * @type {FormArray}
     * @memberof RouteElementSubtypeComponent
     */
    get specificEntryPointsFormArray(): FormArray {
        return this.form.get('specificEntryPoints') as FormArray;
    }

    /**
     * Set value of specificSubtype form control.
     *
     * @private
     * @memberof RouteElementSubtypeComponent
     */
    private setSubtypeFormControlValue(): void {
        // First, checking if the route element is an entry point, and if so, setting it to AnyEntry, otherwise 0 (representing Specific Entry Point).
        // Second, checking/finding what type of route element it is from the available options in the subtypesArray list.
        const selectedSubtypeValue = this.isEntryPointType() ?
            this._routeElement.subtype === EntryPointTypes.AnyEntry || !this._routeElement.subtype ?
                EntryPointTypes.AnyEntry :
                0 : // EntryPoint type
            this.subtypesArray?.find(subtype => subtype.key === this._routeElement.subtype)?.key; // Connector, Door and other types

        this.form.controls['specificSubtype'].setValue(selectedSubtypeValue ?? 1, { emitEvent: false });
    }

    /**
     * Push Specific Entry Points to form array.
     *
     * @private
     * @param {SpecificEntryPoint[]} specificEntryPoints
     * @param {number} subtype
     * @memberof RouteElementSubtypeComponent
     */
    private setSpecificEntryPointsFormArray(specificEntryPoints: SpecificEntryPoint[], subtype: number): void {
        this.specificEntryPointsFormArray.clear({ emitEvent: false });

        if (!this.isEntryPointType()) {
            return;
        }

        // Create specificEntryPoints form controls
        specificEntryPoints.forEach((specificEntryPoint) => {
            // Check Entry Points bitwise
            const checked = (subtype & specificEntryPoint.key) > 0;
            this.specificEntryPointsFormArray.push(this.formBuilder.control(checked), { emitEvent: false });
        });
    }

    /**
     * Check if route element is of type EntryPoint.
     *
     * @private
     * @returns {boolean}
     * @memberof RouteElementSubtypeComponent
     */
    private isEntryPointType(): boolean {
        return this._routeElement.type === RouteElementType.EntryPoint;
    }

    /**
     * Get subtype value.
     *
     * @private
     * @returns {number}
     * @memberof RouteElementSubtypeComponent
     */
    private getSubtypeValue(): number {
        // EntryPoint Route Layer Element Type
        if (this.isEntryPointType()) {
            // Any Entry Point
            if (this.form.value.specificSubtype === EntryPointTypes.AnyEntry) {
                return this.form.value.specificSubtype;
            }

            // Specific Entry Points
            // Creating a list of numbers with the values of the EntryPointTypes enum
            const checkedEntryPointKeys: number[] = this.form.value.specificEntryPoints
                .map((checked, i) => checked ? this.specificEntryPoints[i].key : null)
                .filter(specificEntryPointKey => specificEntryPointKey !== null);

            let combinedSubtypeKeys = 0;
            checkedEntryPointKeys.forEach(key => combinedSubtypeKeys += key);

            // If all Specific Entry Points are checked then fallback to AnyEntry
            if (combinedSubtypeKeys === 30) {
                combinedSubtypeKeys = EntryPointTypes.AnyEntry;
            }

            return combinedSubtypeKeys;
        }

        // Connector, Door and other Route Layer Element Types
        return this.specificSubtypeFormControl.value;
    }

    /**
     * Check if the selected subtype is an interbuilding door.
     *
     * @param {number} selectedSubtype
     * @returns {boolean}
     * @memberof RouteElementSubtypeComponent
     */
    public isInterBuildingDoor(selectedSubtype: number): boolean {
        return this._routeElement.type === RouteElementType.Door
            && this.form?.value?.subtype === DoorType.InterBuildingDoor
            && selectedSubtype === DoorType.InterBuildingDoor;
    }

    /**
     * On change handler that sets the value of the subtype formcontrol.
     *
     * @memberof RouteElementSubtypeComponent
     */
    public onSubtypeChange(): void {
        this.subtypeFormControl.setValue(this.specificSubtypeFormControl.value);
        this.onChange(this.getSubtypeValue());
    }

    /**
     * Writes a new value to the element.
     *
     * @param {number} subtype
     * @memberof RouteElementSubtypeComponent
     */
    writeValue(subtype: number): void {
        this.subtypeFormControl.setValue(subtype ?? 1, { onlySelf: true });
    }

    /**
     * Registers a callback function that is called when the control's value changes in the UI.
     *
     * @memberof RouteElementSubtypeComponent
     */
    registerOnChange(fn: (subtype: number) => void): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that is called by the forms API on initialization to update the form model on blur.
     *
     * @memberof RouteElementSubtypeComponent
     */
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }
}