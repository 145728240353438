import { Component, ViewChild, ElementRef, forwardRef } from '@angular/core';
import { Validators, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, ControlValueAccessor, NG_VALIDATORS, Validator, ValidationErrors } from '@angular/forms';
import { noDuplicateValidator } from '../../shared/directives/no-duplicate.directive';

@Component({
    selector: 'location-aliases',
    templateUrl: './location-aliases.component.html',
    styleUrls: ['./location-aliases.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => LocationAliasesComponent),
        multi: true,
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => LocationAliasesComponent),
        multi: true,
    }]
})
export class LocationAliasesComponent implements ControlValueAccessor, Validator {
    @ViewChild('aliasInput') aliasInputElement: ElementRef;
    public onChange: (aliases: string[]) => void = () => { };
    public onTouch: () => void = () => { };
    public onValidatorChange: () => void = () => { };
    public isDisabled: boolean = false;

    public aliases: string[] = [];
    public aliasForm: FormGroup = this.formBuilder.group({
        aliasInput: ['']
    });

    constructor(private formBuilder: FormBuilder) {}

    /**
     * Submit aliasForm and add alias.
     *
     * @memberof LocationAliasesComponent
     */
    public addAlias(): void {
        if (!this.aliasForm.valid) {
            return;
        }

        this.aliases.push(this.aliasForm.get('aliasInput').value);
        this.aliasForm.reset();
        this.aliasInputElement.nativeElement.focus();

        this.onChange(this.aliases);
        this.onValidatorChange();
    }

    /**
     * Remove alias from list.
     *
     * @param {number} index - Index of alias to remove.
     * @memberof LocationAliasesComponent
     */
    public removeAlias(index: number): void {
        this.aliases.splice(index, 1);
        this.onChange(this.aliases);
    }

    /**
     * Writes a new value to the element.
     *
     * @param {string[]} aliases
     * @memberof LocationAliasesComponent
     */
    writeValue(aliases: string[]): void {
        this.aliases = aliases;
        this.aliasForm.controls.aliasInput.setValidators([Validators.minLength(1), noDuplicateValidator(this.aliases)]);
    }

    /**
     * Registers a callback function that is called when the control's value changes in the UI.
     *
     * @memberof LocationAliasesComponent
     */
    registerOnChange(fn: (aliases: string[]) => void): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that is called by the forms API on initialization to update the form model on blur.
     *
     * @memberof LocationAliasesComponent
     */
    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    /**
     * Method that is being called when the control value changes.
     *
     * @memberof LocationAliasesComponent
     * @returns {ValidationErrors}
     */
    validate(): ValidationErrors {
        return this.aliasForm.invalid ? { invalid: true } : null;
    }

    /**
     * Registers a callback function that is called when the validation changes.
     *
     * @memberof LocationAliasesComponent
     */
    registerOnValidatorChange?(fn: () => void): void {
        this.onValidatorChange = fn;
    }
}

