import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, UrlTree, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Customer } from '../customers/customer.model';
import { CustomerService } from '../customers/customer.service';

@Injectable()
export class CustomerLevelModulesGuard implements CanActivate {

    constructor(private customerService: CustomerService, private router: Router) {}

    /**
     * Determines whether a customer-level-module's route can be activated.
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {(Observable<boolean | UrlTree> | boolean | UrlTree)}
     * @memberof CustomerLevelModulesGuard
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
        return (this.customerService.getCurrentCustomer() as Observable<Customer>)
            .pipe(
                take(1),
                map(customer => {
                    if (customer?.modules?.includes(state.url.split('/').pop())) {
                        return true;
                    }
                    /* Redirect with UrlTree to prevent race conditions in some cases where multiple guards redirect.
                    With UrlTree, the highest priority guard wins.*/
                    return this.router.createUrlTree([environment.startPage]);
                })
            );
    }
}
