import { UnitSystem } from '../enums';

/**
 * Convert meters to feet.
 *
 * @param {number} meters
 * @param {UnitSystem} unitSystem
 * @returns {number}
 */
export function convertMetersToFeet(meters: number, unitSystem: UnitSystem): number {
    return Math.round(100 * (unitSystem === UnitSystem.Imperial ? meters * 39.370 : meters * 100)) / 100;
}

/**
 * Converts centimeters to meters.
 *
 * @param {number} centimeters
 * @returns {number}
 */
export function convertCentimetersToMeters(centimeters: number): number {
    return centimeters / 100;
}

/**
 * Converts inches to meters.
 *
 * @param {number} inches
 * @returns {number}
 */
export function convertInchesToMeters(inches: number): number {
    return inches * 0.0254;
}