import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { DeploymentVenueComponent } from './deployment-venue.component';

@NgModule({
    declarations: [DeploymentVenueComponent],
    imports: [
        SharedModule
    ],
    providers: []
})
export class DeploymentVenueModule { }
