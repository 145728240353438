import { ComponentRef } from '@angular/core';
import { Observable } from 'rxjs';

export interface MapToolbar {
    destroy: Observable<void>
}

export class MapToolbarRef {
    #componentRef: ComponentRef<MapToolbar>;

    /**
     * Get the instance of the component that is shown in the toolbar.
     *
     * @public
     * @template T
     * @type {T}
     */
    public get componentRef(): ComponentRef<MapToolbar> {
        return this.#componentRef;
    }

    /**
     * Set the instance of the component that is shown in the toolbar.
     * The instance can only be set once.
     */
    public set componentRef(componentRef: ComponentRef<MapToolbar>) {
        if (!this.#componentRef) {
            this.#componentRef = componentRef;
        }
    }
}