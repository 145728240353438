import { AfterViewInit, Component, ElementRef, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
    selector: 'navigatable-container',
    templateUrl: './navigatable-container.component.html',
    styleUrls: ['./navigatable-container.component.scss']
})
export class NavigatableContainerComponent implements AfterViewInit {
    @ViewChild('content', { static: true }) content: ElementRef<HTMLElement>;
    @ViewChild('navMenu', { static: true }) navMenu: ElementRef<HTMLElement>;

    private hasSideMenuLinkBeenClicked = false;
    private scrollPaddingTop: number;

    constructor(private readonly viewRef: ViewContainerRef) {}

    ngAfterViewInit(): void {
        // Indicates the threshold for when the menu should change state, and is used to align a navigatable-section with the top menu item.
        this.scrollPaddingTop = parseInt(getComputedStyle(this.viewRef.element.nativeElement, null).getPropertyValue('padding-top'));

        this.generateNavigationMenu();
        this.enableLinkActivationInMenuOnScroll(this.scrollPaddingTop);
    }

    /**
     * Generate side menu links based on the header attribute of the navigatable-section component.
     */
    private generateNavigationMenu(): void {
        this.navMenu.nativeElement.innerHTML = '';
        const listElements: Element[] = [];

        Array.from(this.content.nativeElement.querySelectorAll('navigatable-section')).forEach((sectionElement: HTMLElement) => {
            const listElement = document.createElement('li');
            const anchorElement = document.createElement('a');

            // Remove click event listener from anchor element.
            anchorElement.onclick = null;

            anchorElement.textContent = sectionElement.getAttribute('header');
            anchorElement.addEventListener('click', () => {
                this.hasSideMenuLinkBeenClicked = true;
                this.viewRef.element.nativeElement.scrollTop = sectionElement.offsetTop - this.viewRef.element.nativeElement.offsetTop - this.scrollPaddingTop;

                this.changeActiveLinkInMenu(anchorElement.textContent);
            });

            listElement.classList.add('nav-menu__item');
            listElement.setAttribute('header', anchorElement.textContent);
            listElement.appendChild(anchorElement);
            listElements.push(listElement);
        });

        // Set active state on the first element in the side menu.
        listElements?.[0]?.classList.add('nav-menu__item--active');
        listElements.forEach(value => {
            this.navMenu.nativeElement.appendChild(value);
        });
    }

    /**
     * Set the active state on a link in the side menu when scrolling through the sections.
     *
     * @param {number} thresholdOffset - Determine when a link should be activated by setting a threshold offset that a section must pass before becoming active.
     */
    private enableLinkActivationInMenuOnScroll(thresholdOffset: number): void {
        this.viewRef.element.nativeElement.addEventListener('scroll', (event) => {
            const targetElement = event.target as HTMLElement;
            const sections = Array.from(targetElement.querySelectorAll('navigatable-section'));
            let sectionHeaderAtThreshold: string;

            // Prevent scroll logic for each section to run when clicking on a link in the side menu.
            if (this.hasSideMenuLinkBeenClicked === true) {
                this.hasSideMenuLinkBeenClicked = false;
                return;
            }

            sections.forEach((section: HTMLElement) => {
                if (targetElement.scrollTop >= (section.offsetTop - targetElement.offsetTop - thresholdOffset)) {
                    sectionHeaderAtThreshold = section.getAttribute('header');
                }

                // When scrolling to the bottom of the page, activate the bottom link in the menu.
                if ((targetElement.scrollHeight - targetElement.scrollTop) <= targetElement.clientHeight) {
                    sectionHeaderAtThreshold = sections[sections.length - 1].getAttribute('header');
                }

                this.changeActiveLinkInMenu(sectionHeaderAtThreshold);
            });
        });
    }

    /**
     * Helper function to find the currently active link in the side menu, removing it and setting a new active link when clicking or scrolling.
     *
     * @param {string} header - The `header` attribute of an `li` element.
     */
    private changeActiveLinkInMenu(header: string): void {
        let currentlyActiveLinkInMenu = this.viewRef.element.nativeElement.querySelector('.nav-menu__item--active');

        currentlyActiveLinkInMenu.classList.remove('nav-menu__item--active');
        currentlyActiveLinkInMenu = this.viewRef.element.nativeElement.querySelector(`li[header="${header}"]`);
        currentlyActiveLinkInMenu.classList.add('nav-menu__item--active');
    }

    /**
     * Regenerate the side menu links if anything in the main element changes.
     */
    public onContentChange(): void {
        this.generateNavigationMenu();
    }
}
