import { Component, OnDestroy, OnInit } from '@angular/core';
import { Geometry, LanguageData } from '../locations/location.model';
import { Subscription, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { Building } from './building.model';
import { BuildingService } from './building.service';
import { CustomerService } from '../customers/customer.service';
import { MICustomProperty } from '../shared/custom-properties/custom-properties.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../services/notification.service';
import { SolutionService } from '../services/solution.service';

@Component({
    selector: 'buildings',
    templateUrl: './buildings.component.html',
    styleUrls: ['./buildings.component.scss']
})
export class BuildingsComponent implements OnDestroy, OnInit {
    public isProgressBarVisible = false;
    public isSideBarOpen = false;

    constructor(
        private solutionService: SolutionService,
        private notificationService: NotificationService,
        private customerService: CustomerService,
        private spinner: NgxSpinnerService,
        private buildingService: BuildingService
    ) { }

    buildings: Building[];
    currentBuilding: Building;

    selectedRow: string = ''; // Highlight row when selected

    currentSolution: any;
    defaultLanguage: string = '';

    sortBy: string = 'displayName';
    currentSortBy: string = 'displayName';
    sortReverse: boolean = false;

    subscriptions = new Subscription();
    showAdvancedSettings = false;

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnInit(): void {
        const selectedSolutionSubscription = this.solutionService.selectedSolution$
            .subscribe(solution => {
                this.currentSolution = solution;
                this.defaultLanguage = solution.defaultLanguage;
            });

        const buildingsSubscription = this.buildingService.buildings$
            .pipe(
                map((buildings) => {
                    buildings.forEach(building => building.floors.sort((currentFloor, nextFloor) => currentFloor.floorIndex - nextFloor.floorIndex));
                    return buildings;
                }),
                catchError(err => {
                    this.notificationService.showError(err);
                    return of(undefined);
                }),
                finalize(() => this.isProgressBarVisible = false))
            .subscribe(buildings => this.buildings = buildings);

        this.subscriptions
            .add(selectedSolutionSubscription)
            .add(buildingsSubscription);
    }

    /**
     * Sets the default floor.
     *
     * @param {*} index
     * @memberof BuildingsComponent
     */
    public setDefaultFloor(index): void {
        this.currentBuilding.defaultFloor = Number.isInteger(index) ? index : null;
    }

    /**
     * Sets the value of the imageURL when the image changes in the image upload component.
     *
     * @param {string} imageUrl
     */
    public onImageUrlChange(imageUrl: string): void {
        this.currentBuilding.imageUrl = imageUrl;
    }

    /**
     * Formats the building name before saving.
     * Formats the floor names before saving.
     *
     * @private
     * @memberof BuildingsComponent
     */
    private formatBuildingBeforeSave(): void {
        const buildingInfo = this.currentBuilding.buildingInfo.find(info => info.language === this.defaultLanguage);
        this.currentBuilding.displayName = buildingInfo ? buildingInfo.name : 'n/a';
        // if the building name is not set in other languages, then set it to the name in the default language
        this.currentBuilding.buildingInfo
            .filter(info => info.language !== this.defaultLanguage)
            .forEach(lang => {
                if (lang.name === undefined || lang.name === null || lang.name === '') {
                    lang.name = buildingInfo ? buildingInfo.name : 'n/a';
                }
            });

        this.currentBuilding.floors.forEach(floor => {
            const floorInfo = floor.floorInfo.find(info => info.language === this.defaultLanguage);
            floor.displayName = floorInfo ? floorInfo.name : 'n/a';
            // if the floor name is not set in other languages, then set it to the name in the default language
            floor.floorInfo
                .filter(info => info.language !== this.defaultLanguage)
                .forEach(lang => {
                    if (lang.name === undefined || lang.name === null || lang.name === '') {
                        lang.name = floorInfo ? floorInfo.name : 'n/a';
                    }
                });
        });

        this.formatCustomPropertiesBeforeSave();
    }

    /**
     * Save the building.
     *
     * @memberof BuildingsComponent
     */
    public saveBuilding(): void {
        this.isSideBarOpen = false;
        this.formatBuildingBeforeSave();
        this.isProgressBarVisible = true;
        this.buildingService.updateBuilding(this.currentBuilding)
            .pipe(finalize(() => this.isProgressBarVisible = false))
            .subscribe(() => {
                this.notificationService.showSuccess('Building updated');
            }, err => this.notificationService.showError(err));
        this.selectedRow = '';
    }

    /**
     * Sorts the table.
     *
     * @param {*} sortBy
     * @memberof BuildingsComponent
     */
    public sortTable(sortBy): void {
        if (this.sortBy === sortBy) {
            this.sortBy = '-' + sortBy;
            this.sortReverse = !this.sortReverse;
        } else if (this.sortBy === '-' + sortBy) {
            this.sortBy = sortBy;
            this.sortReverse = !this.sortReverse;
        } else {
            this.sortBy = sortBy;
            this.currentSortBy = sortBy;
            this.sortReverse = false;
        }
    }

    /**
     * Highlights the selected building and sets the default floor.
     *
     * @param {Building} building
     * @memberof BuildingsComponent
     */
    public editBuilding(building: Building): void {
        this.currentBuilding = building;
        this.selectedRow = building.id; // Highlight selected row
        this.addCustomPropertiesToBuildingInfo();
        this.isSideBarOpen = true;
    }

    /**
     * Closes the sidebar.
     *
     * @memberof BuildingsComponent
     */
    public closeSidebar(): void {
        this.isSideBarOpen = false;
        this.selectedRow = '';
    }

    /**
     * Toggles the advanced settings module.
     *
     * @memberof BuildingsComponent
     */
    public toggleAdvancedSettings(): void {
        this.showAdvancedSettings = !this.showAdvancedSettings;
    }

    /**
     * Creates LanguageData in all the available languages with a default name.
     *
     * @private
     * @param {string} name
     * @returns {LanguageData[]}
     * @memberof BuildingsComponent
     */
    private createDefaultLanguageData(name: string): LanguageData[] {
        const languages = this.currentSolution.availableLanguages.slice();
        return languages.map(lang => {
            return {
                fields: null,
                language: lang,
                name
            };
        });
    }

    /**
     * Creates a default building's geometry.
     *
     * @private
     * @param {google.maps.LatLng[]} bbox
     * @returns {Geometry}
     * @memberof BuildingsComponent
     */
    private createBuildingGeometry(bbox: google.maps.LatLng[]): Geometry {
        return {
            type: 'Polygon',
            bbox: [+bbox[0].lng().toFixed(8), +bbox[0].lat().toFixed(8), +bbox[3].lng().toFixed(8), +bbox[3].lat().toFixed(8)],
            coordinates: [[
                [+bbox[0].lng().toFixed(8), +bbox[0].lat().toFixed(8)],
                [+bbox[1].lng().toFixed(8), +bbox[1].lat().toFixed(8)],
                [+bbox[2].lng().toFixed(8), +bbox[2].lat().toFixed(8)],
                [+bbox[3].lng().toFixed(8), +bbox[3].lat().toFixed(8)],
                [+bbox[0].lng().toFixed(8), +bbox[0].lat().toFixed(8)]
            ]]
        };
    }

    /**
     * Requests the service to create a building.
     *
     * @private
     * @param {Building} building
     * @memberof BuildingsComponent
     */
    private createBuilding(building: Building): void {
        this.spinner.show('building-spinner');
        this.buildingService.createBuilding(building)
            .pipe(finalize(() => this.spinner.hide('building-spinner')))
            .subscribe(() => {
                this.notificationService.showSuccess('Building created');
            }, err => this.notificationService.showError(err));
    }

    /**
     * Create array of existing custom properties and add it to the buildingInfo property.
     *
     * @private
     * @memberof LocationsComponent
     */
    private addCustomPropertiesToBuildingInfo(): void {
        for (const translation of this.currentBuilding.buildingInfo) {
            const customProperties: MICustomProperty[] = [];
            for (const key in translation.fields) {
                customProperties.push({ key: key, value: translation.fields[key].value, type: 'text' });
            }
            translation.customProperties = customProperties;
        }
    }

    /**
     * Transfer the custom properties array to the buildingInfo's fields property.
     *
     * @private
     * @memberof LocationsComponent
     */
    private formatCustomPropertiesBeforeSave(): void {
        for (const translation of this.currentBuilding.buildingInfo) {
            translation.fields = {};
            if (translation && translation.customProperties) {
                for (const customProperty of translation.customProperties) {
                    if (customProperty.key && customProperty.key.length > 0) {
                        translation.fields[customProperty.key] = {
                            text: customProperty.key,
                            type: customProperty.type,
                            value: customProperty.value
                        };
                    }
                }
            }
        }
    }
}
