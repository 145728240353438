import { LocationType } from '../../location-types/location-type.model';

/**
 * Create and get a mi-dropdown-item element.
 *
 * @param {string} label
 * @param {string} value
 * @param {boolean} [selected=false]
 * @param {string} title
 * @returns {HTMLMiDropdownItemElement}
 */
export function createDropdownItemElement(label: string, value: string, selected: boolean = false, title?: string): HTMLMiDropdownItemElement {
    const miDropdownItemElement = document.createElement('mi-dropdown-item') as HTMLMiDropdownItemElement;
    miDropdownItemElement.text = label;
    miDropdownItemElement.value = value;
    miDropdownItemElement.selected = selected;

    if (title) {
        miDropdownItemElement.setAttribute('title', title);
    }

    return miDropdownItemElement;
}

/**
 * Creates mi-dropdown-items from an array of LocationTypes.
 *
 * @param {LocationType} locationType
 * @param  {string} displayIcon
 * @returns {HTMLMiDropdownItemElement}
 */
export function createLocationTypesDropdownItem(locationType: LocationType, displayIcon?: string): HTMLMiDropdownItemElement {
    const miDropDownItemElement = createDropdownItemElement(locationType.displayName, locationType.id);
    miDropDownItemElement.innerHTML = displayIcon ? `<img src="${displayIcon}" height="24" />${locationType.displayName}` : locationType.displayName;
    return miDropDownItemElement;
}
