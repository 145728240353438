<div fxFlexFill fxLayout="column">
	<mat-toolbar class="mat-elevation-z2 page-toolbar">
		<div fxFlex="20">
			<button (click)="createCustomer()" mat-raised-button>
				New customer
			</button>
		</div>
		<div fxFlex></div>
		<div fxFlex="30" fxLayout="column">
			<mat-form-field class="transparent-btn">
				<input [(ngModel)]="searchQuery" matInput placeholder="Search customers">
				<button mat-button *ngIf="searchQuery.length >= 1" matSuffix mat-icon-button aria-label="Clear" (click)="searchQuery = ''">
					<mat-icon>close</mat-icon>
				</button>
			</mat-form-field>
		</div>
	</mat-toolbar>

	<ngx-spinner name="customer-spinner" 
		[fullScreen]="false" 
		type="ball-pulse" 
		bdColor="rgba(51,51,51,0.3)">
	</ngx-spinner>

	<div fxFlex fxLayout="column" class="list-sidebar mat-elevation-z8" *ngIf="currentCustomer">
		<mat-toolbar>
			<button [disabled]="!customerName.valid" mat-raised-button color="primary" (click)="saveCustomer(currentCustomer)">Save and close</button>
			<div fxFlex></div>
			<button (click)="closeSidebar()" mat-icon-button>
				<mat-icon>close</mat-icon>
			</button>
		</mat-toolbar>
			<div class="page-content">
				<div class="section">
					<div class="column">
						<div fxLayout="column">
							<mat-form-field>
								<input placeholder="Customer name" matInput [formControl]="customerName" type="text" required/>
								<mat-error *ngIf="customerName.hasError('required')">Customer name is required</mat-error>
								<mat-error *ngIf="customerName.hasError('minlength')">Min 3 characters</mat-error>
							</mat-form-field>
						</div>
						<div>
							<div class="sub-section-header">
								<h3>Modules</h3>
							</div>
							<div class="input-container float" *ngFor="let module of customerModules | orderBy:'name'">
								<mat-slide-toggle color="primary" matTooltip="{{module.description}}" [(ngModel)]="module.selected">
									{{module.name}}
								</mat-slide-toggle>
							</div>
                            <div class="input-container float">
                                <mat-slide-toggle color="primary" matTooltip="Enables SDK logs" 
                                    [checked]="currentCustomer.logConfig?.eventLoggingEnabled"
                                    (change)="onSdkLogsToggle($event)">
                                    SDK Event Logs
                                </mat-slide-toggle>
                            </div>
						</div>
						<div>
							<div class="sub-section-header">
								<h3>Delete customer</h3>
							</div>
							<div>
								<p>Type "DELETE" to enable the delete button. Proceed at your own peril</p>
								<mat-form-field>
									<input placeholder="Enable delete" [(ngModel)]="enableDelete" matInput />
								</mat-form-field>
								<br />
								<button [disabled]="enableDelete !== 'DELETE'" (click)="deleteCustomer(currentCustomer)" color="warn" mat-raised-button>
									<mat-icon>delete</mat-icon>
									Really delete {{currentCustomer.name}}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	<div class="page-content">
			<table *ngIf="customers" class="std-table">
				<tr class="sticky">
					<th></th>
					<th class="sortable" (click)="sortTable('name')" [class.active]="currentSortBy === 'name'">
						Customer name
						<span class="table-arrow">
							<mat-icon [ngClass]="{'reverse-180': sortBy === '-name'}">keyboard_arrow_down</mat-icon>
						</span>
					</th>
					<th>Customer id</th>
				</tr>
				<tr [class.selected]="currentCustomer && customer.id === currentCustomer.id" *ngFor="let customer of customers 
					| orderBy:sortBy:sortReverse
					| filterCustomers:searchQuery" [class.highlight]="selectedRow == customer.id">
					<td style="width: 40px; padding-right: 0px;">
						<button (click)="getCustomer(customer)" mat-icon-button>
							<mat-icon>edit</mat-icon>
						</button>
					</td>
					<td>
						{{customer.name}}
					</td>
					<td>
						{{customer.id}}
					</td>
				</tr>
			</table>
	</div>
</div>