
<panel-header header="Google Street View" class="panel__header" (cancelEvent)="dialogRef.close()"></panel-header>
<div class="street-view-dialog">
	<div #StreetViewPanorama class="street-view-dialog__panorama">
		<div #StreetViewMinimap class="treet-view-dialog__minimap"></div>
	</div>

	<div class="street-view-dialog__footer">
		<div class="street-view-dialog__paragraph">
			<p class="street-view-dialog__paragraph street-view-dialog__paragraph--bold">
				Adjust the angle of the image to your preference. Press “Set image” to save the current Street View image for the Location.
			</p>
			<p class="street-view-dialog__paragraph street-view-dialog__paragraph--italic">
				If the Room shown is not the expected one navigate until reaching the correct Room. If you see an outside Location this might be due to Google Street View not being available at this Building - you need to check with your organization or examine using Google Maps.
			</p>
		</div>
		<button class="mi-button mi-button--primary" (click)="setImage()">
			Set image
		</button>
	</div>
</div>