import { Component, ComponentRef, Injector, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MapAdapterType } from '../../MapAdapter/BaseMapAdapter';
import { SolutionService } from '../../services/solution.service';
import { MapAdapterComponent } from '../map-adapter/map-adapter.component';


@Component({
    selector: 'map-container',
    templateUrl: './map-container.component.html'
})
export class MapContainerComponent implements OnInit, OnDestroy {
    @ViewChild('container', { read: ViewContainerRef, static: true })
    private container!: ViewContainerRef;
    private componentRef!: ComponentRef<MapAdapterComponent>;
    private mapAdapterType: MapAdapterType;
    private subscriptions: Subscription = new Subscription();

    constructor(
        private parentInjector: Injector,
        private solutionService: SolutionService,
    ) { }

    /**
     * Angular OnInit lifecycle hook.
     */
    ngOnInit(): void {
        const selectedSolutionSubscription = this.solutionService.selectedSolution$
            .pipe(map(solution => solution?.modules?.some(module => module.toLowerCase() === 'mapbox') ? MapAdapterType.MapboxAdapter : MapAdapterType.GoogleMapsAdapter))
            .subscribe(nextMapAdapterType => {
                this.mapAdapterType = nextMapAdapterType;
                this.componentRef?.destroy();
                this.componentRef = this.initMapAdapter(nextMapAdapterType);
            });
        this.subscriptions.add(selectedSolutionSubscription);
    }

    /**
     * Angular OnDestroy lifecycle hook.
     */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }


    /**
     * Creates a new MapAdapterComponent.
     *
     * @private
     * @param {MapAdapterType} mapAdapterType
     * @returns {ComponentRef<MapAdapterComponent>}
     */
    private initMapAdapter(mapAdapterType: MapAdapterType): ComponentRef<MapAdapterComponent> {
        const injector = Injector.create({ providers: [{ provide: MapAdapterType, useValue: mapAdapterType }], parent: this.parentInjector });
        return this.container.createComponent<MapAdapterComponent>(MapAdapterComponent, { injector });
    }
}