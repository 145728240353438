<div class="section">
    <div class="section__column">
        <label class="mi-label">Categories
            <mi-dropdown label="Select" 
                #categoriesDropdown 
                name="categories-dropdown" 
                [items]="categoriesDropdownItems" 
                (change)="onSelectionChange($event)" 
                filterable 
                multiple>
            </mi-dropdown>
        </label>
        <div class="chips-list">
            <mat-basic-chip matChipRemove disableRipple
                *ngFor="let category of selectedCategories"
                (removed)="onRemoveChip(category.value)">
                {{category.text || category.innerText}}
                <mat-icon>cancel</mat-icon>
            </mat-basic-chip>
        </div>
    </div>
</div>