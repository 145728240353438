import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Status } from '../../shared/enums';

@Component({
    selector: 'app-status-bit',
    templateUrl: './status-bit.component.html'
})
export class StatusBitComponent implements OnChanges {
    /** An array of geodata objects with a status property. */
    @Input() geoObjects: any[];
    @Input() simpleView: boolean;

    public searchable = true;
    public haveDifferentSearchableBits = false;

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        if (typeof changes['geoObjects'] !== 'undefined') {
            const geoObjects: any[] = changes['geoObjects'].currentValue || [];

            if (geoObjects.length > 0 && typeof geoObjects[0].status !== 'undefined') {
                this.searchable = this.isSearchable(geoObjects[0].status);
                this.haveDifferentSearchableBits = geoObjects.some(geoObject => this.searchable !== this.isSearchable(geoObject.status));

                if (this.haveDifferentSearchableBits) {
                    this.setSearchableBit(this.searchable);
                }
            }
        }
    }

    /**
     * Sets the searchable bit of the geodata object.
     *
     * @param {boolean} isSearchable
     * @memberof StatusBitComponent
     */
    public setSearchableBit(isSearchable: boolean): void {
        this.geoObjects.forEach(geoObject => {
            if (typeof geoObject.status === 'undefined') {// For newly created geoObject
                geoObject.status = Status.All;
            }

            if (isSearchable) {
                geoObject.status |= Status.Searchable; // keeps the existing bits in status intact
            } else {
                geoObject.status &= ~Status.Searchable; // removes the searchable bit from status
            }
        });
    }

    /**
     * Checks whether the given status has the searchable bit set.
     *
     * @param {number} status
     * @returns {boolean}
     * @memberof StatusBitComponent
     */
    private isSearchable(status: number): boolean {
        // Bitwise AND (a & b) returns a 1 in each bit position for which the corresponding bits of both operands are 1s.
        return Status.Searchable === (status & Status.Searchable);
    }
}
