import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'media-error',
    templateUrl: './media-error.component.html',
    styleUrls: ['./media-error.component.scss']
})

export class MediaErrorComponent {
    public uploadedImages = this.data.uploaded.length;
    public failedImages = this.data.failed;

    constructor(
        private dialogRef: MatDialogRef<MediaErrorComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    /**
     * Closes the dialog box displaying the error messages.
     */
    public onClose(): void {
        this.dialogRef.close();
    }
}