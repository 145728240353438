import { Point } from 'geojson';

/**
 * Creates a Point geometry from an array of numbers - [lng, lat].
 *
 * @param {number[]} coordinates
 * @returns {Point}
 */
export function toPointGeometry(coordinates: number[]): Point {
    return { type: 'Point', coordinates };
}