import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { pointOnFeature } from '@turf/turf';
import { Observable, Subject, Subscription } from 'rxjs';
import { DrawingMode, GeodataEditor, GeodataEditorFactory } from '../../../../GeodataEditor/GeodataEditor.factory';
import { Geometry, LocationType } from '../../../../locations/location.model';
import { LocationService } from '../../../../locations/location.service';
import { DisplayRuleService } from '../../../../services/DisplayRuleService/DisplayRuleService';
import { LocationDetailsEditorComponent } from '../../../location-details-editor/location-details-editor.component';
import { MapAdapterMediator } from '../../../map-adapter.mediator';
import { MapSidebarService } from '../../../map-sidebar/map-sidebar.service';
import { MapToolbar } from '../../map-toolbar.interface';

@Component({
    selector: 'create-area',
    templateUrl: './create-area.component.html',
    styleUrls: ['./create-area.component.scss'],
})
export class CreateAreaComponent implements MapToolbar, OnInit, OnDestroy {
    private _destroySubject: Subject<void> = new Subject();
    private _subscription: Subscription;
    private _drawingMode: DrawingMode;
    private _geodataEditor: GeodataEditor;

    public readonly DrawingMode: typeof DrawingMode = DrawingMode;
    public readonly destroy: Observable<void> = this._destroySubject.asObservable();

    /**
     * Active drawing mode.
     *
     * @readonly
     * @type {DrawingMode}
     */
    public get drawingMode(): DrawingMode {
        return this._drawingMode;
    }

    constructor(
        private mapAdapterMediator: MapAdapterMediator,
        private displayRuleService: DisplayRuleService,
        private locationService: LocationService,
        private mapSidebar: MapSidebarService
    ) {
        const mapAdapter = this.mapAdapterMediator.getMapAdapter();
        this._geodataEditor = GeodataEditorFactory.create(mapAdapter, this.displayRuleService, this.locationService);
    }

    /**
     * Set the drawing-mode for creating an area.
     *
     * @param {DrawingMode} drawingMode
     */
    setDrawingMode(drawingMode: DrawingMode): void {
        if (this._drawingMode !== drawingMode) {
            this._drawingMode = drawingMode;
            this._subscription?.unsubscribe();
            this._subscription = this._geodataEditor.drawArea(drawingMode)
                .subscribe(polygon => {
                    this._subscription.unsubscribe();
                    const anchor = pointOnFeature(polygon)?.geometry;
                    const location = this.locationService.createNewLocation(anchor);
                    location.geometry = polygon as Geometry;
                    location.locationType = LocationType.Area;

                    const { componentInstance } = this.mapSidebar.open(LocationDetailsEditorComponent);
                    if (componentInstance) {
                        componentInstance.data = location;
                    }

                    this._destroySubject.next();
                });
        }
    }
    /**
     * Angular OnInit lifecycle hook.
     */
    ngOnInit(): void {
        this.setDrawingMode(DrawingMode.FreeHand);
    }

    /**
     * Angular OnDestroy lifecycle hook.
     */
    ngOnDestroy(): void {
        this._subscription.unsubscribe();
        this._destroySubject.complete();
    }

    /**
     * Cancels the create area operation.
     */
    public onCancel(): void {
        // eslint-disable-next-line no-alert
        if (confirm('Canceling without saving will discard any changes you have made.')) {
            this._destroySubject.next();
        }
    }

    /**
     * Cancels the create area operation.
     *
     * @param {KeyboardEvent} event
     */
    @HostListener('document:keydown.escape', ['$event'])
    private onEscapeHandler(event: KeyboardEvent): void {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.onCancel();
    }
}