import { ComponentType } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MapToolbar, MapToolbarRef } from './map-toolbar.interface';

@Injectable()
export class MapToolbarService {
    private subject: Subject<{ component: ComponentType<MapToolbar>,  mapToolbarRef: MapToolbarRef }> = new Subject();

    /**
     * Notifies the observer to create a new instance of the given component and returns a ComponentRef.
     *
     * @template T
     * @param {ComponentType<T>} component
     * @returns {MapToolbarRef<T>}
     */
    public show<T extends MapToolbar>(component: ComponentType<T>): ComponentRef<T> {
        const mapToolbarRef = new MapToolbarRef();
        this.subject.next({ component, mapToolbarRef });
        return mapToolbarRef.componentRef as ComponentRef<T>;
    }

    /**
     * Returns an observable for observing "show" requests.
     *
     * @template T
     * @returns {Observable<{component: ComponentType<MapToolbar>}>}
     */
    public asObservable(): Observable<{ component: ComponentType<MapToolbar>, mapToolbarRef: MapToolbarRef }> {
        return this.subject.asObservable();
    }
}