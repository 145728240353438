<div class="header">
    <header>Main Display Rule</header>
    <hr />
    <div class="description">
        The Main Display Rule is the default styling options for all geodata on
        the map. You can override these for each individual Type, or single
        Location. Changing an option here will change it for all those that
        inherit from the Main Display Rule.
    </div>
</div>
<div class="message message--warning">
    Your app code needs to use Display Rules, not hardcoded overrides, for your
    style changes here to apply.
</div>
<a class="documentation"
    href="https://docs.mapsindoors.com/content/map/map-styling/display-rules"
    target="_blank"
    >Read more about Display Rules in our documentation.</a
>
<div class="body">
    <display-rule-details-editor
        [displayRules]="displayRules"
        [isGeometrySettingsVisible]="isGeometrySettingsVisible"
        [isMainDisplayRule]="true"
        [is3DWallsSectionVisible]="is3DWallsSectionVisible"
        (formSubmit)="onSubmit($event)"
        [discardChangesMainDisplayRule]="true">
    </display-rule-details-editor>
</div>

