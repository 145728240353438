<div fxFlexFill fxLayout="column" class="page-content">
    <mat-progress-bar mode="indeterminate" color="accent" *ngIf="isProgressbarVisible"></mat-progress-bar>

    <div class="grid-content">
        <mi-card *ngFor="let dataOpt of dataOptions">
            <div class="card-content" DragDrop 
                (filesChangeEmiter)="chooseFile($event, dataOpt)" 
                [allowed_extensions]="['csv']">
                <h1>{{dataOpt.title}}</h1>
                
                <mat-form-field *ngIf="dataOpt.path === 'graphs'">
                    <mat-label>Select graph</mat-label>
                    <mat-select [(value)]="selectedGraphId" [disabled]="dataOpt.showSpinner">
                        <mat-option *ngFor="let graphId of graphIds" [value]="graphId">
                            {{graphId}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mi-spinner *ngIf="dataOpt.showSpinner"></mi-spinner>

                <div class="file-upload">
                    <button type="button" class="mi-button mi-button--base" 
                        [disabled]="dataOpt.showSpinner || dataOpt.title === 'Graphs(.osm)'"
                        (click)="fileInput.click()">
                        Choose file to upload
                        <input type="file" accept=".csv" #fileInput [id]="dataOpt.path+'-input'" (change)="chooseFile($event, dataOpt)">
                    </button>
                    <span class="filename" *ngIf="dataOpt.file">
                        <span>{{dataOpt.file.name}}</span>
                        <i>({{dataOpt.file.size}} bytes)</i>
                    </span>         
                </div>

                <div class="actions">
                    <button type="button" class="mi-button mi-button--primary mi-button--icon-left"
                        [disabled]="!dataOpt.file || dataOpt.showSpinner || dataOpt.title === 'Graphs(.osm)'"
                        (click)="upload(dataOpt, true)">
                        <!-- <object data="/assets/images/upload.svg" type="image/svg+xml"></object> -->
                        Upload
                    </button>              
                    <button type="button" class="mi-button mi-button--primary mi-button--icon-left" 
                        [disabled]="dataOpt.showSpinner"
                        (click)="download(dataOpt)">
                        <!-- <object data="/assets/images/download.svg" type="image/svg+xml"></object> -->
                        Download
                    </button>
                </div>
            </div>
        </mi-card>
    </div>
</div>
