/* eslint-disable @typescript-eslint/explicit-function-return-type */
const $rules = Symbol();

/**
 * @typedef {Object} ValidationResult
 * @property {boolean} isValid - True if the input passes validation; otherwise false.
 * @property {string} [error] - Error message. *Only present if the validation has failed.
 * @property {Object} [meta]
 */

/**
 * @class ValidationChain
 */
class ValidationChain {
    constructor(...rules) {
        this[$rules] = new Set(rules);
    }

    /**
     * Validate the input against the defined ruleset.
     *
     * @param {*} input
     * @returns {ValidationResult}
     * @memberof ValidationChain
     */
    validate(input) {
        for (const rule of this[$rules]) {
            const result = rule(input);
            if (!result.isValid) { return result; }
        }
        return { isValid: true, error: null };
    }
}

export { ValidationChain };
