import { Component, OnDestroy } from '@angular/core';
import { SolutionService } from '../../services/solution.service';
import { TypesService } from '../../services/types.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';

@Component({
    selector: 'location-templates',
    templateUrl: './location-templates.component.html'
})
export class LocationTemplatesComponent implements OnDestroy {
    currentSolution: any;
    types: any;

    currentType: any;
    sortBy: string = 'displayName';
    currentSortBy: string = 'displayName';
    sortReverse: boolean = false;

    locationTemplate: any;
    //tempFields: any = [];

    newField: any;

    field: any = {
        text: '',
        type: 'text',
        appType: 'text',
        value: null,
        translatable: false
    };

    textTypes: any = [
        {
            name: 'Text',
            type: 'text'
        },
        {
            name: 'URL',
            type: 'url'
        },
        {
            name: 'Email',
            type: 'email'
        }
    ];

    pageLoad: boolean = false;

    solutionSubscription: any;
    routeSubscription: any;

    showDeleteOverlay: string = '';
    showLanguageOverlay: string = '';

    constructor(
        private solutionService: SolutionService,
        private typesService: TypesService,
        private route: ActivatedRoute,
        private notificationService: NotificationService,
        private router: Router,
    ) {
        this.solutionSubscription = this.solutionService.getCurrentSolution().subscribe(solution => {
            this.currentSolution = solution;
            if (this.currentSolution) {
                this.getTypes();
            }
        });
    }

    ngOnDestroy(): void {
        this.solutionSubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
    }

    setType(type): void {
        this.currentType = type;
    }

    addNewProperty(type, appType): void {
        const tempField = JSON.stringify(this.field);
        this.newField = JSON.parse(tempField);
        this.newField.type = type;
        this.newField.appType = appType;
    }

    cancelNewProperty(): void {
        this.newField = null;
    }

    getTypes(): void {
        this.typesService.getTypes().subscribe(
            types => {
                this.types = types;
                this.formatTypes();
                this.routeSubscription = this.route.params.subscribe(params => {
                    for (const type of this.types) {
                        if (type.administrativeId === params['type']) {
                            this.setType(type);
                        }
                    }
                });
                if (!this.currentType) {
                    // currently runs before sorting
                    this.setType(this.types[0]);
                }
            }
        );
    }

    formatTypes(): void {
        for (const type of this.types) {
            if (type.translations) {
                for (const lang of type.translations) {
                    if (lang.language === this.currentSolution.defaultLanguage) {
                        type.displayName = lang.name;
                    }
                }
            }
        }
    }

    addPropertyToTemplate(): void {
        if (!this.currentType.fields) {
            this.currentType.fields = [];
        }

        this.newField.text = this.newField.text.trim();
        if (this.newField.text.toLowerCase() === 'name') {
            this.notificationService.showError('A property name must not be called "name". It is already used internally');
            return;
        }

        for (const field of this.currentType.fields) {
            if (this.newField.text.toLowerCase() === field.text.toLowerCase()) {
                this.notificationService.showError('A property of that name already exists. Select another name');
                return;
            }
        }
        const tempField = JSON.stringify(this.newField);
        this.currentType.fields.splice(0, 0, JSON.parse(tempField));
        setTimeout(() => {
            this.cancelNewProperty();
        }, 50);

    }

    deleteTypeFromTemplate(field): void {
        const index = this.currentType.fields.indexOf(field);
        this.currentType.fields.splice(index, 1);
        this.closeDeleteOverlay();
    }

    public updateType(): void {
        this.pageLoad = true;
        this.typesService.updateType(this.currentType)
            .subscribe(
                () => {
                    //this.getTypes();
                    this.notificationService.showSuccess('Updated the location template');
                    this.navigateTo('/types');
                    setTimeout(() => {
                        this.pageLoad = false;
                        this.currentType.lastModified = Date.now();
                    }, 500);
                },
                err => {
                    this.notificationService.showError(err);
                    setTimeout(() => { this.pageLoad = false; }, 500);
                }
            );

    }

    openDeleteOverlay(text): void {
        this.showDeleteOverlay = text;
    }

    closeDeleteOverlay(): void {
        this.showDeleteOverlay = '';
    }

    openLanguageOverlay(text): void {
        this.showLanguageOverlay = text;
    }

    closeLanguageOverlay(): void {
        this.showLanguageOverlay = '';
    }

    cancelLanguageOverlay(field): void {
        field.translatable = !field.translatable;
        this.closeLanguageOverlay();
    }

    /**
     * Navigates to a page.
     *
     * @param {string} page
     * @memberof LocationTemplatesComponent
     */
    navigateTo(page: string): void {
        this.router.navigate([page]);
    }
}

