<div [formGroup]="form">
    <ng-container *ngFor="let type of subtypesArray">
        <label class="mi-label mi-label--radio">
            <input 
                type="radio" 
                class="mi-input" 
                formControlName="specificSubtype" 
                [value]="type.key"
                (change)="onSubtypeChange()">
            {{type.value}}
        </label>
        <p class="type__description" *ngIf="isInterBuildingDoor(type.key)">
            Interbuilding Doors are a way of connecting two buildings, therefore Interbuilding Doors must be placed where two Floors meet to connect them to each other.
        </p>
    </ng-container>

    <!-- Specific Entry Points -->
    <div formArrayName="specificEntryPoints" *ngIf="form.get('specificSubtype').value !== entryPointTypes.AnyEntry">
        <label *ngFor="let specificEntryPoint of specificEntryPointsFormArray.controls; index as i"
            class="mi-label mi-label--checkbox role">
            <input 
                type="checkbox" 
                class="mi-input" 
                [formControl]="specificEntryPointsFormArray.controls[i]"
                (change)="onSubtypeChange()">
            {{specificEntryPoints[i].title}}
        </label>
    </div>
</div>