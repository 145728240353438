<h1 mat-dialog-title>
    <span> Total {{data.entriesTotal}} |</span>
    <span> Added {{data.entriesAdded}} |</span>
    <span> Updated {{data.entriesUpdated}} |</span>
    <span> Deleted {{data.entriesDeleted}} |</span>
    <span class="error-text"> Failed {{data.entriesFailed}}</span>
</h1>

<div mat-dialog-content>
    <mat-accordion multi>
        <!-- Errors -->
        <mat-expansion-panel *ngIf="data.errorList?.length > 0">
            <mat-expansion-panel-header>
                <h3>
                    <strong class="error-text">{{data.entriesFailed}}</strong> entries contain errors and will not be imported:
                </h3>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <p class="error-list-item" *ngFor="let entry of data.errorList">
                    <span>Line no. {{entry.lineNumber}}:</span>
                    <span class="error-text">{{entry.text}}</span>
                </p>
            </ng-template>
        </mat-expansion-panel>

        <!-- Warning -->
        <mat-expansion-panel *ngIf="data.warningList?.length > 0">
            <mat-expansion-panel-header>
                <h3><strong>{{data.warningList.length}}</strong> Warning<span *ngIf="data.warningList?.length > 1">s</span>:</h3>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <p class="error-list-item" *ngFor="let entry of data.warningList">
                    <span>Line no. {{entry.lineNumber}}:</span>
                    <span class="error-text">{{entry.text}}</span>
                </p>
            </ng-template>
        </mat-expansion-panel>

        <!-- Venues -->
        <mat-expansion-panel *ngIf="data.venuesCreated?.length > 0">
            <mat-expansion-panel-header>
                <h3><strong>{{data.venuesCreated.length}}</strong> Venues will be created:</h3>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <span>{{data.venuesCreated.join()}}</span>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="data.venuesUpdated?.length > 0">
            <mat-expansion-panel-header>
                <h3><strong>{{data.venuesUpdated.length}}</strong> Venues will be updated:</h3>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <span>{{data.venuesUpdated.join()}}</span>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="data.venuesDeleted?.length > 0">
            <mat-expansion-panel-header>
                <h3><strong>{{data.venuesDeleted.length}}</strong> Venues will be deleted:</h3>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <span>{{data.venuesDeleted.join()}}</span>
            </ng-template>
        </mat-expansion-panel>

        <!-- Buildings -->
        <mat-expansion-panel *ngIf="data.buildingsCreated?.length > 0">
            <mat-expansion-panel-header>
                <h3><strong>{{data.buildingsCreated.length}}</strong> Buildings will be created:</h3>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <span>{{data.buildingsCreated.join()}}</span>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="data.buildingsUpdated?.length > 0">
            <mat-expansion-panel-header>
                <h3><strong>{{data.buildingsUpdated.length}}</strong> Buildings will be updated:</h3>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <span>{{data.buildingsUpdated.join()}}</span>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="data.buildingsDeleted?.length > 0">
            <mat-expansion-panel-header>
                <h3><strong>{{data.buildingsDeleted.length}}</strong> Buildings will be deleted:</h3>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <span>{{data.buildingsDeleted.join()}}</span>
            </ng-template>
        </mat-expansion-panel>

        <!-- Floors -->
        <mat-expansion-panel *ngIf="data.floorsCreated?.length > 0">
            <mat-expansion-panel-header>
                <h3><strong>{{data.floorsCreated.length}}</strong> Floors will be created:</h3>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <span>{{data.floorsCreated.join()}}</span>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="data.floorsUpdated?.length > 0">
            <mat-expansion-panel-header>
                <h3><strong>{{data.floorsUpdated.length}}</strong> Floors will be updated:</h3>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <span>{{data.floorsUpdated.join()}}</span>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="data.floorsDeleted?.length > 0">
            <mat-expansion-panel-header>
                <h3><strong>{{data.floorsDeleted.length}}</strong> Floors will be deleted:</h3>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <span>{{data.floorsDeleted.join()}}</span>
            </ng-template>
        </mat-expansion-panel>

        <!-- Locations -->
        <mat-expansion-panel *ngIf="data.locationsCreated?.length > 0">
            <mat-expansion-panel-header>
                <h3><strong>{{data.locationsCreated.length}}</strong> Locations will be created:</h3>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <span>{{data.locationsCreated.join()}}</span>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="data.locationsUpdated?.length > 0">
            <mat-expansion-panel-header>
                <h3><strong>{{data.locationsUpdated.length}}</strong> Locations will be updated:</h3>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <span>{{data.locationsUpdated.join()}}</span>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="data.locationsDeleted?.length > 0">
            <mat-expansion-panel-header>
                <h3><strong>{{data.locationsDeleted.length}}</strong> Locations will be deleted:</h3>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <span>{{data.locationsDeleted.join()}}</span>
            </ng-template>
        </mat-expansion-panel>

    </mat-accordion>

</div>

<div mat-dialog-actions style="padding-bottom: 0px; margin-bottom: 0px;">
    <button mat-button type="button" *ngIf="anyEntriesChanged" (click)="dialogRef.close(false)"
        class="btn-secondary">Cancel</button>
    <button mat-raised-button color="primary" type="button" [disabled]="data.entriesFailed > 0" *ngIf="anyEntriesChanged" (click)="dialogRef.close(true)"
        class="btn-primary">Import</button>
    <button mat-raised-button type="button" *ngIf="!anyEntriesChanged" (click)="dialogRef.close(false)" color="primary"
        class="btn-primary">Ok</button>
</div>