import { Pipe, PipeTransform } from '@angular/core';
import FuzzySearch from 'fuzzy-search';
import { normalizeText } from 'normalize-text';

@Pipe({name: 'fuzzySearch'})
export class FuzzySearchPipe implements PipeTransform {
    constructor() {}

    /**
     * Filters the given list based on the input parameters.
     *
     * @param {Array<object>} elements
     * @param {string} searchQuery
     * @param {string[]} keys
     * @param  {object} options
     * @returns {Array<any>}
     */
    transform(elements: Array<Object>, searchQuery: string, keys?: string[], options?: Object): Array<any> {
        if (!searchQuery) return elements;

        const searcher = new FuzzySearch(elements, keys, options);
        const normalizedQuery = normalizeText(searchQuery);
        return searcher.search(normalizedQuery);
    }
}
