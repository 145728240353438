import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { filterMediaItems } from '../../operators/filterMediaItems';
import { MediaFilterOptions } from '../media-filter-bar/media-filter-options.model';
import { IMediaItem, MediaItem } from '../media-item/media-item.model';
import { MediaLibraryService } from '../media-library.service';
import { MediaSyncComponent } from '../media-sync/media-sync.component';
import { SolutionService } from '../../services/solution.service';

@Component({
    selector: 'media-items-list',
    templateUrl: './media-items-list.component.html',
    styleUrls: ['./media-items-list.component.scss']
})
export class MediaItemsListComponent implements OnInit {
    public showSyncButton: boolean = true;
    private _selectedItem: IMediaItem;

    /**
     * Setting the new used media item.
     */
    @Input()
    public set selectedItem(item: IMediaItem) {
        this._selectedItem = item;
    }
    @Input() defaultFilter: MediaFilterOptions;

    @Output() chosenMediaItem = new EventEmitter<IMediaItem>();

    public filteredMediaItems$: Observable<IMediaItem[]>;
    public mediaItems: IMediaItem[];

    constructor(
        private mediaLibraryService: MediaLibraryService,
        private solutionService: SolutionService,
        private dialog: MatDialog
    ) { }

    /**
     * Creates and updates the list of media items for rendering each media item component.
     */
    ngOnInit(): void {
        this.filteredMediaItems$ = this.mediaLibraryService.activeFilters$
            .pipe(
                switchMap(
                    (filter) => this.mediaLibraryService.mediaItems$
                        .pipe(filterMediaItems(filter))
                )
            );

        this.filteredMediaItems$.subscribe(
            (filteredMediaItems) => {
                this.mediaItems = filteredMediaItems;
                if (this._selectedItem) {
                    this._selectedItem.selected = true;
                    this.sendSelectedMedia();
                }
            }
        );

        this.mediaLibraryService.setActiveFilter(this.defaultFilter);

        this.showSyncButton = this.solutionService.getSyncableSolutions().length > 0;
    }

    /**
     * Emits an event and sends the selected item along - the media-library component is listening to it in order so send the media item along when the media libarry modal box is closed.
     */
    private sendSelectedMedia(): void {
        this.chosenMediaItem.emit(this._selectedItem);
    }

    /**
     * Opens sync manager.
     *
     * @param {MediaItem} mediaItem
     */
    public onOpenSync(mediaItem: MediaItem): void {
        this.dialog.open(MediaSyncComponent, {
            disableClose: false,
            role: 'dialog',
            autoFocus: false,
            ariaLabel: 'Sync Manager',
            panelClass: 'media-dialog',
            closeOnNavigation: true,
            data: { mediaItem }
        });
    }

    /**
     * When clicking on a media item, emit an event with the clicked media item.
     *
     * @param {IMediaItem} mediaItem
     */
    public onSelectMediaItem(mediaItem: IMediaItem): void {
        if (this._selectedItem) this._selectedItem.selected = false;
        if (this._selectedItem === mediaItem) {
            this._selectedItem = null;
        } else {
            this._selectedItem = mediaItem;
            this._selectedItem.selected = true;
        }
        this.sendSelectedMedia();
    }
}