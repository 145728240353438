import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DoorType, EntryPointTypes, RouteElement, RouteElementType } from '../route-element.model';
import { ElementSubType, NetworkMapService } from '../../network-map.service';
import { FormArray, FormBuilder, FormControl, FormGroupDirective, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';
import { primitiveClone } from '../../../shared/object-helper';

interface SpecificEntryPoint {
    key: number;
    title: string;
}

@Component({
    selector: 'route-element-subtype',
    templateUrl: './route-element-subtype.component.html',
    styleUrls: ['./route-element-subtype.component.scss']
})
export class RouteElementSubtypeComponent implements OnInit, OnDestroy {
    @Input() set routeElement(routeElement: RouteElement) {
        this._routeElement = routeElement;

        this.subtypes = this.networkMapService.getSubtypes(routeElement.type);

        this.setSubtypeFormControlValue();
        this.setSpecificEntryPointsFormArray(this.specificEntryPoints, routeElement.subtype);

        this.subtypeFormControl.setValue(this.getSubtypeValue(), { emitEvent: false });
        this.originalSubTypeValues = primitiveClone(this.form.value);
    }

    /**
     * Returns routeElement.
     *
     * @returns {RouteElement}
     */
    get routeElement(): RouteElement {
        return this._routeElement;
    }

    @Output() subtypeChanged = new EventEmitter<number>();

    private subscriptions = new Subscription();
    private _routeElement: RouteElement;
    private originalSubTypeValues;

    public routeElementType = RouteElementType;
    public entryPointTypes = EntryPointTypes;
    public subtypes: ElementSubType[] = [];
    public specificEntryPoints: SpecificEntryPoint[] = [
        { key: EntryPointTypes.WalkingEntry, title: 'Walking Entry' },
        { key: EntryPointTypes.DrivingEntry, title: 'Driving Entry' },
        { key: EntryPointTypes.BicyclingEntry, title: 'Bicycling Entry' },
        { key: EntryPointTypes.TransitEntry, title: 'Transit Entry', }
    ];
    public form = this.fb.group({
        subtype: [0, [Validators.required]],
        specificEntryPoints: this.fb.array([])
    });
    public subtypeFormControl = new FormControl([]);

    constructor(
        private fb: FormBuilder,
        private networkMapService: NetworkMapService,
        private parent: FormGroupDirective
    ) { }

    /**
     * Called after Angular has initialized all data-bound properties of a directive.
     */
    ngOnInit(): void {
        this.parent.form.setControl(
            'subtype', this.subtypeFormControl, { emitEvent: false}
        );

        this.subscriptions
            .add(this.form.valueChanges
                .subscribe(() => {
                    const subtypeValue = this.getSubtypeValue();
                    this.subtypeChanged.emit(subtypeValue);
                    this.subtypeFormControl.setValue(this.getSubtypeValue());
                }))
            .add(this.subtypeFormControl.valueChanges
                .subscribe(() => {
                    if (this.getSubtypeValue() !== this.subtypeFormControl.value) {
                        this.form.setValue(this.originalSubTypeValues, { emitEvent: false, onlySelf: true });
                    }
                }));
    }
    
    /**
     * Called when a directive, pipe, or service is destroyed.
     */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Get specificEntryPoints form array.
     *
     * @readonly
     * @type {FormArray}
     * @memberof RouteElementSubtypeComponent
     */
    get specificEntryPointsFormArray(): FormArray {
        return this.form.get('specificEntryPoints') as FormArray;
    }

    /**
     * Check if the selected subtype is an interbuilding door.
     *
     * @param {number} selectedSubtype
     * @returns {boolean}
     * @memberof RouteElementSubtypeComponent
     */
    public isInterBuildingDoor(selectedSubtype: number): boolean {
        return this._routeElement.type === RouteElementType.Door
            && this.form?.value?.subtype === DoorType.InterBuildingDoor
            && selectedSubtype === DoorType.InterBuildingDoor;
    }

    /**
     * Check if route element is of type EntryPoint.
     *
     * @private
     * @returns {boolean}
     * @memberof RouteElementSubtypeComponent
     */
    private isEntryPointType(): boolean {
        return this._routeElement.type === RouteElementType.EntryPoint;
    }

    /**
     * Set value of subtype form control.
     *
     * @private
     * @memberof RouteElementSubtypeComponent
     */
    private setSubtypeFormControlValue(): void {
        const selectedSubtypeValue = this.isEntryPointType() ?
            this._routeElement.subtype === EntryPointTypes.AnyEntry ? EntryPointTypes.AnyEntry : 0 : // EntryPoint type
            this.subtypes?.find(subtype => subtype.key === this._routeElement.subtype)?.key; // Connector, Door and other types

        this.form.controls['subtype'].setValue(selectedSubtypeValue ?? 1, { emitEvent: false });
    }

    /**
     * Push Specific Entry Points to form array.
     *
     * @private
     * @param {SpecificEntryPoint[]} specificEntryPoints
     * @param {number} subtype
     * @memberof RouteElementSubtypeComponent
     */
    private setSpecificEntryPointsFormArray(specificEntryPoints: SpecificEntryPoint[], subtype: number): void {
        this.specificEntryPointsFormArray.clear({ emitEvent: false });

        if (!this.isEntryPointType()) {
            return;
        }
        // Create specificEntryPoints form controls
        specificEntryPoints.forEach((specificEntryPoint) => {
            // Check Entry Points bitwise
            const checked = (subtype & specificEntryPoint.key) > 0;
            this.specificEntryPointsFormArray.push(this.fb.control(checked), { emitEvent: false });
        });
    }

    /**
     * Get subtype value.
     *
     * @private
     * @returns {number}
     * @memberof RouteElementSubtypeComponent
     */
    private getSubtypeValue(): number {
        // EntryPoint Route Layer Element Type
        if (this.isEntryPointType()) {
            // Any Entry Point
            if (this.form.value.subtype === EntryPointTypes.AnyEntry) {
                return this.form.value.subtype;
            }

            // Specific Entry Points
            const checkedEntryPointKeys: number[] = this.form.value.specificEntryPoints
                .map((checked, i) => checked ? this.specificEntryPoints[i].key : null)
                .filter(specificEntryPointKey => specificEntryPointKey !== null);

            let combinedSubtypeKeys = 0;
            checkedEntryPointKeys.forEach(key => combinedSubtypeKeys += key);

            // If all Specific Entry Points are checked then fallback to AnyEntry
            if (combinedSubtypeKeys === 30) {
                combinedSubtypeKeys = EntryPointTypes.AnyEntry;
            }

            return combinedSubtypeKeys;
        }

        // Connector, Door and other Route Layer Element Types
        return this.form.value.subtype;
    }
}
