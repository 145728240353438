import { Component, OnDestroy, OnInit } from '@angular/core';

import { CategoryService } from '../../categories/category.service';
import { ConfigService } from './config.service';
import { MatDialog } from '@angular/material/dialog';
import { MediaLibraryComponent } from '../../../app/media-library/media-library.component';
import { NotificationService } from '../../services/notification.service';
import { SharedFunctionsService } from '../../services/shared-functions.service';
import { SolutionService } from '../../services/solution.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-config',
    templateUrl: './config.component.html',
    styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnDestroy, OnInit {
    isProgressBarVisible: boolean = false;
    appConfig: any;
    appColors: any = {
        primaryColor: '#3071D9',
        accentColor: '#2A844E',
        primaryText: '#FCFCFC',
        accentText: '#FCFCFC'
    };

    categories: any;
    appConfigCategories: any = [];
    displayCategories: any = [];
    inactiveCategories: any = [];

    securityModule: boolean = true;
    solutionSubscription: any;

    currentAppId: any;
    appIDError: boolean = true;

    currentSolution: any;
    defaultLanguage: string = '';

    constructor(
        private configService: ConfigService,
        private solutionService: SolutionService,
        private categoryService: CategoryService,
        private notificationService: NotificationService,
        private dialog: MatDialog,
        private sharedFunctionsService: SharedFunctionsService
    ) {

    }

    ngOnInit(): void {
        this.solutionSubscription = this.solutionService.getCurrentSolution()
            .subscribe(solution => {
                this.currentSolution = solution;
                this.defaultLanguage = this.currentSolution.defaultLanguage;
                this.getConfig();
            });
    }

    ngOnDestroy(): void {
        this.solutionSubscription.unsubscribe();
    }

    /**
     * Gets the AppConfig.
     *
     * @private
     * @memberof ConfigComponent
     */
    private getConfig(): void {
        this.appConfigCategories = [];
        this.displayCategories = [];
        this.inactiveCategories = [];
        this.isProgressBarVisible = true;
        this.configService.getConfig()
            .pipe(finalize(() => this.isProgressBarVisible = false))
            .subscribe(
                config => {
                    this.appConfig = config;
                    if (this.appConfig.appSettings.primaryColor) {
                        this.appColors.primaryColor = this.appConfig.appSettings.primaryColor;
                    }
                    if (this.appConfig.appSettings.accentColor) {
                        this.appColors.accentColor = this.appConfig.appSettings.accentColor;
                    }
                    if (this.appConfig.appSettings.primaryText) {
                        this.appColors.primaryText = this.appConfig.appSettings.primaryText;
                    }
                    if (this.appConfig.appSettings.accentText) {
                        this.appColors.accentText = this.appConfig.appSettings.accentText;
                    }

                    if (this.appConfig.appId && this.appConfig.appId.length > 0) {
                        this.currentAppId = this.appConfig.appId;
                    } else {
                        this.currentAppId = null;
                    }
                    this.getAppConfigCategories();
                }, error => {
                    error === 'Appconfig not found' ? this.createConfig() : this.notificationService.showError(error);
                }
            );
    }

    /**
     * Gets categories from the AppConfig.
     *
     * @private
     * @memberof ConfigComponent
     */
    private getAppConfigCategories(): void {
        if (this.appConfig.menuInfo && this.appConfig.menuInfo.mainmenu) {
            for (const category of this.appConfig.menuInfo.mainmenu) {
                this.appConfigCategories.push(category);
            }
        }

        this.categoryService.getCategories()
            .subscribe(
                categories => {
                    this.categories = categories;
                    this.formatCategories();
                }
            );
    }

    /**
     * Formats the categories to be added to the AppConfig.
     *
     * @private
     * @memberof ConfigComponent
     */
    private formatCategories(): void {
        for (const category of this.categories) {

            let appCategory = false;

            for (const appCat of this.appConfigCategories) {
                if (appCat.categoryKey.toLowerCase() === category.key.toLowerCase()) {
                    appCategory = true;
                }
            }

            if (appCategory === false) {
                const displayCategory = {
                    categoryKey: category.key,
                    iconUrl: null,
                    displayName: category.displayName
                };
                this.inactiveCategories.push(displayCategory);
            }
        }
        // To preserve the order in appConfig this is done in a new loop
        for (const appCat of this.appConfigCategories) {
            for (const category of this.categories) {
                if (appCat.categoryKey.toLowerCase() === category.key.toLowerCase()) {
                    const displayCategory = {
                        categoryKey: category.key,
                        iconUrl: null,
                        displayName: category.displayName
                    };
                    displayCategory.iconUrl = appCat.iconUrl;
                    this.displayCategories.push(displayCategory);
                }
            }
        }
    }

    /**
     * Moves the given category up in the array.
     *
     * @param {*} category
     * @memberof ConfigComponent
     */
    public moveUp(category): void {
        const index = this.displayCategories.indexOf(category);
        const cat = this.displayCategories.splice(index, 1);
        this.displayCategories.splice((index - 1), 0, cat[0]);
    }

    /**
     * Moves the given category down in the array.
     *
     * @param {*} category
     * @memberof ConfigComponent
     */
    public moveDown(category): void {
        const index = this.displayCategories.indexOf(category);
        const cat = this.displayCategories.splice(index, 1);
        this.displayCategories.splice((index + 1), 0, cat[0]);
    }

    /**
     * Adds/Removes the given category to/from the array of categories to be saved in the AppConfig.
     *
     * @param {*} category
     * @param {*} show
     * @memberof ConfigComponent
     */
    public setShowInApp(category, show): void {
        if (show) {
            const index = this.inactiveCategories.indexOf(category);
            this.inactiveCategories.splice(index, 1);
            this.displayCategories.push(category);
        } else {
            const index = this.displayCategories.indexOf(category);
            this.displayCategories.splice(index, 1);
            this.inactiveCategories.push(category);
        }
    }

    /**
     * Opens the Icon Manager.
     *
     * @param {*} category
     * @memberof ConfigComponent
     */
    public editMenuIcon(category): void {
        const dialogRef = this.dialog.open(MediaLibraryComponent, {
            width: '90vw',
            minWidth: '1024px',     // smallest screen size supported by CMS
            maxWidth: '1700px',     // the number is a subject to change after UX has tested properly
            height: '85vh',         // the number is a subject to change after UX has tested properly
            maxHeight: '928px',     // the number is a subject to change after UX has tested properly
            minHeight: '550px',     // the number is a subject to change after UX has tested properly
            role: 'dialog',
            panelClass: 'details-dialog'
        });

        dialogRef.afterClosed().subscribe((mediaItem) => {
            if (mediaItem?.selectedMedia?.url > '') {
                category.iconUrl = mediaItem?.selectedMedia?.url;
            }
        });
    }

    /**
     * Converts the AppConfig.appId to lowercase and removes spaces.
     *
     * @memberof ConfigComponent
     */
    public lowerCaseAppId(): void {
        if (this.appConfig && this.appConfig.appId && this.appConfig.appId.length > 0) {
            this.appConfig.appId = this.appConfig.appId.toLowerCase();
            this.appConfig.appId = this.appConfig.appId.replace(/\s/g, '');

            const regex = RegExp(/^[a-z0-9]+$/i);

            this.appIDError = regex.test(this.appConfig.appId);
        } else {
            this.appIDError = true;
        }
    }

    /**
     * Creates a default AppConfig.
     *
     * @private
     * @memberof ConfigComponent
     */
    private createConfig(): void {
        const solution = this.solutionService.getStaticSolution();
        const newConfig = {
            solutionId: solution.id,
            primaryURL: 'https://api.mapsindoors.com',
            secondaryURL: 'https://api-us.mapsindoors.com',
            appSettings: {},
            menuInfo: {},
            translations: {},
            venueImages: {}
        };
        this.configService.createConfig(newConfig)
            .subscribe(
                () => this.getConfig(),
                error => this.notificationService.showError(error)
            );
    }

    /**
     * Adds a formatted version of the selected categories to the AppConfig's main menu section.
     *
     * @private
     * @memberof ConfigComponent
     */
    private formatConfigBeforeSave(): void {
        this.appConfig.appSettings.primaryColor = this.appColors.primaryColor;
        this.appConfig.appSettings.accentColor = this.appColors.accentColor;
        this.appConfig.appSettings.primaryText = this.appColors.primaryText;
        this.appConfig.appSettings.accentText = this.appColors.accentText;

        const saveCategories = [];
        for (const cat of this.displayCategories) {
            const saveCat = {
                categoryKey: cat.categoryKey,
                iconUrl: cat.iconUrl
            };
            saveCategories.push(saveCat);
        }
        if (saveCategories.length > 0) {
            this.appConfig.menuInfo.mainmenu = saveCategories;
        } else {
            delete this.appConfig.menuInfo.mainmenu;
        }
    }

    /**
     * Updates the AppConfig.
     *
     * @memberof ConfigComponent
     */
    public saveConfig(): void {
        this.formatConfigBeforeSave();
        this.isProgressBarVisible = true;
        this.configService.updateConfig(this.appConfig)
            .pipe(finalize(() => this.isProgressBarVisible = false))
            .subscribe(() => {
                this.notificationService.showSuccess('Saved app configuration');
                this.getConfig();
            }, error => this.notificationService.showError(error));
    }

}
