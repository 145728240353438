import { Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filterCategories',
    pure: true
})
export class FilterCategoriesPipe implements PipeTransform {
    transform(value: any[], query: any): any {
        query = query ? query.toLowerCase() : '';
        return query && value ?
            value.filter(category => {
                if (category.displayName && category.displayName.toLowerCase().indexOf(query) !== -1) {
                    return true;
                }
            }
            ) :
            value;
    }
}


@Pipe({
    name: 'filterCategoriesBySelected',
    pure: false
})
export class FilterCategoriesBySelectedPipe implements PipeTransform {
    transform(value: any[], selected: boolean): any {
        return value && selected ?
            value.filter(category => {
                if (category.active === selected) {
                    return true;
                }
            }
            ) :
            value;
    }
}

