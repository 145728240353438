import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { SolutionService } from './solution.service';
import { FormatService } from './format.service';
import { environment } from '../../environments/environment';
import { GraphData } from '../map/graph-data.model';

@Injectable({
    providedIn: 'root'
})
export class ImportService {
    private api = environment.APIEndpoint;

    constructor(
        private solutionService: SolutionService,
        private formatService: FormatService,
        private http: HttpClient
    ) { }

    /**
     * Download a file from the backend.
     *
     * @param {string} path
     * @param {string} [graphId='']
     * @param {string} [fileType='csv']
     * @returns {Observable<any>}
     * @memberof ImportService
     */
    public download(path: string, graphId = '', fileType = 'csv'): Observable<any> {
        const solution = this.solutionService.getStaticSolution();
        return this.http.get(`${this.api}${solution.id}/api/${path}/${fileType}/${graphId}`, { responseType: 'arraybuffer' });
    }

    /**
     * Upload a file to the backend.
     *
     * @param {string} path
     * @param {boolean} preview
     * @param {*} file
     * @param {string} [fileType='csv']
     * @param {string} [graphId='']
     * @returns {Observable<any>}
     * @memberof ImportService
     */
    public upload(path: string, preview: boolean, file: any, fileType = 'csv', graphId = ''): Observable<any> {
        const solution = this.solutionService.getStaticSolution();
        const headers = new HttpHeaders({ 'Content-Type': fileType === 'osm' ? 'application/osm' : 'text/csv' });
        return this.http.post(`${this.api}${solution.id}/api/${path}/${fileType}${fileType === 'osm' ? '/' + graphId : ''}?preview=${preview}`, file, { headers });
    }

    /**
     * Get an array of graph IDs.
     *
     * @returns {Observable<string[]>}
     * @memberof ImportService
     */
    public getGraphIds(): Observable<string[]> {
        const solution = this.solutionService.getStaticSolution();
        return this.http.get<string[]>(`${this.api}${solution.id}/api/graphs?graphIdOnly=true`);
    }

    createApiKey(solutionId) {
        const headers = new HttpHeaders({ 'Content-Type': 'text/json' });
        return this.http.post(environment.APIEndpoint + solutionId + '/api/apikey/createsolutionkey/', true, { headers: headers });
    }

    cloneSolution(solutionId, newSolutionName) {
        const headers = new HttpHeaders({ 'Content-Type': 'text/json' });
        return this.http.post(environment.APIEndpoint + 'api/solutions/clone/' + solutionId + '?clonedSolutionName=' + newSolutionName, true, { headers: headers });
    }

    updateLocationCache(solutionId) {

        return this.http.get(environment.clientApi + 'api/locations/updatecache?solutionid=' + solutionId);
    }

    deleteGraph(graphId) {
        const lmDate = new Date('December 31, 2031, 23:15:30 GMT+11:00');
        const lastModified = this.formatService.utcDate(lmDate);
        const graphsUrl = this.getGraphsURL();
        const headers = new HttpHeaders({ 'If-Modified-Since': lastModified });
        return this.http.delete(graphsUrl + graphId, { headers: headers });
    }

    /**
     * Get graphs data for the current solution.
     *
     * @returns {Observable<GraphData[]>}
     * @memberof ImportService
     */
    public getSolutionGraphs(): Observable<GraphData[]> {
        const solution = this.solutionService.getStaticSolution();
        return this.http.get<GraphData[]>(environment.APIEndpoint + solution.id + '/api/graphs');
    }

    /**
     * Save the graph data for the current solution.
     *
     * @param {*} graph
     * @returns {Observable<any>}
     * @memberof ImportService
     */
    public saveSolutionGraph(graph): Observable<any> {
        const graphsUrl = this.getGraphsURL();
        const requestOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.put(graphsUrl, graph, { headers: requestOptions });
    }

    getNode(venue, id) {
        const graphUrl = this.getGraphsURL();

        return this.http.get(graphUrl + 'node?graphId=' + venue + '&nodeId=' + id);
    }

    saveNode(venue, node) {
        const graphUrl = this.getGraphsURL();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.put(graphUrl + 'node?graphId=' + venue, node, { headers: headers });
    }


    getGraphsURL() {
        const solution = this.solutionService.getStaticSolution();
        const url = environment.APIEndpoint + solution.id + '/api/graphs/';
        return url;
    }
}

