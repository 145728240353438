import { Component, OnInit } 		from '@angular/core';

import { DateAdapter } 				from '@angular/material/core';

import { LogService }					from './services/log.service';
import { SolutionService }				from './services/solution.service';

import { saveAs } from 'file-saver/dist/FileSaver';
import { NotificationService } from './services/notification.service';

@Component({
    selector: 'log',
    templateUrl: './log.component.html'
})
export class LogComponent implements OnInit {
    pageLoad: boolean = false;

    fileLoad: boolean = false;

    logFrom: any;
    logTo: any;

    logTypes: any;
    selectedLogTypes: string = '';
    logUsers: string = '';
    logIds: string = '';

    constructor(
        private logService: LogService,
        private solutionService: SolutionService,
        private notificationService: NotificationService,
        private adapter: DateAdapter<any>
    ) { }

    ngOnInit(): void {
        const lang = navigator.language;
        if (lang) {
            this.adapter.setLocale(lang);
        }

        this.getLogTypes();
    }

    public getLog(): void {
        this.fileLoad = true;

        for (const type of this.logTypes) {
            if (type.selected) {
                if (this.selectedLogTypes.length > 0) {
                    this.selectedLogTypes = this.selectedLogTypes + ',';
                }
                this.selectedLogTypes = this.selectedLogTypes + type.id;
            }
        }

        // remove all whitespace
        this.logUsers = this.logUsers.replace(/\s/g,'');
        this.logIds = this.logIds.replace(/\s/g,'');

        // convert from/to to the format the service expects
        this.logService.getLogCSV(this.logFrom, this.logTo, this.logIds, this.logUsers, this.selectedLogTypes)
            .subscribe(
                (response) => {
                    this.saveToFileSystem(response);
                    this.selectedLogTypes = '';
                    this.fileLoad = false;
                },
                (err) => {
                    if (err.status > 199 && err.status < 300) {
                        this.saveToFileSystemErr(err);
                        this.selectedLogTypes = '';
                    } else {
                        this.notificationService.showError(err);
                        this.selectedLogTypes = '';
                    }
                    this.fileLoad = false;
                }
            );
    }

    public getLogTypes(): void {
        this.logService.getLogTypes()
            .subscribe(
                logTypes => {
                    this.logTypes = logTypes;
                }
            );
    }

    private saveToFileSystem(response): void {
        const solution = this.solutionService.getStaticSolution();
        const filename = 'log_' + solution.name + '.csv';
        if (response && response._body) {
            const blob = new Blob([response._body], { type: 'text/plain' });
            saveAs(blob, filename);
        } else {
            this.notificationService.showInfo('No data to show');
        }
    }

    private saveToFileSystemErr(response): void {
        const solution = this.solutionService.getStaticSolution();
        const filename = 'log_' + solution.name + '.csv';
        if (response && response.error) {
            const blob = new Blob([response.error.text], { type: 'text/plain' });
            saveAs(blob, filename);
        } else {
            this.notificationService.showInfo('No data to show');
        }
    }
}

