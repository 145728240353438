import { BehaviorSubject, Subject } from 'rxjs';

import { DrawingMode } from '../shared/enums';
import { Injectable } from '@angular/core';
import { MapService } from './map.service';
import { filter } from 'rxjs/operators';
import midt from '@mapsindoors/midt/tokens/tailwind-colors.json';

@Injectable()
export class DrawingService {
    private selectedShape: google.maps.Polygon;
    private drawingManager: google.maps.drawing.DrawingManager;
    private drawingOptions = {
        strokeColor: midt['tailwind-colors'].blue[500].value,
        strokeWeight: 2,
        strokeOpacity: 1,
        fillOpacity: 0.2,
        fillColor: midt['tailwind-colors'].blue[500].value,
        editable: true,
        draggable: true
    };
    private drawingManagerOptions: google.maps.drawing.DrawingManagerOptions = {
        drawingControl: false,
        polygonOptions: this.drawingOptions,
        rectangleOptions: this.drawingOptions
    };

    private drawingCompleteSubject$ = new Subject<google.maps.Polygon>();
    public drawingComplete$ = this.drawingCompleteSubject$.asObservable().pipe(filter(polygon => !!polygon));
    public drawingMode$ = new BehaviorSubject<DrawingMode>(null);

    constructor(private mapService: MapService) { }

    /**
     * Initializes the drawing manager.
     * Registers to overlaycomplete event.
     *
     * @returns {google.maps.drawing.DrawingManager}
     * @memberof DrawingService
     */
    public initializeDrawing(): google.maps.drawing.DrawingManager {
        // Creates a drawing manager attached to the map that allows the user to draw
        // markers, lines, and shapes.
        this.drawingManager = new google.maps.drawing.DrawingManager(this.drawingManagerOptions);
        this.drawingManager.setMap(this.mapService.getMap());

        google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (e) => {
            let newShape = e.overlay;
            newShape.type = e.type;

            // In case of the type being a rectangle, convert it to a polygon
            if (newShape.type === DrawingMode.Rectangle) {
                newShape = this.convertGoogleRectangleToGooglePolygon(newShape);

                // Show the Polygon and remove the Rectangle from the map.
                newShape.setMap(this.mapService.getMap());
                e.overlay.setMap(null);
            }

            // Switch back to non-drawing mode after drawing a shape.
            this.drawingManager.setDrawingMode(null);
            this.drawingCompleteSubject$.next(newShape);
            this.setSelection(newShape);
        });

        // Clear the current selection when the drawing mode is changed, or when the
        // map is clicked.
        google.maps.event.addListener(this.drawingManager, 'drawingmode_changed', this.clearSelection);
        google.maps.event.addListener(this.mapService.getMap(), 'click', this.clearSelection);


        this.selectColor(midt['tailwind-colors'].blue[500].value);
        return this.drawingManager;
    }

    /**
     * Convert a Google Rectangle to a Google Polygon.
     *
     * @param {google.maps.Rectangle} rectangle
     * @returns {google.maps.Polygon}
     */
    private convertGoogleRectangleToGooglePolygon(rectangle: google.maps.Rectangle): google.maps.Polygon {
        const polygon = new google.maps.Polygon(this.drawingOptions);

        // Calculate five points to set as the path.
        const rectangleBounds = rectangle.getBounds().toJSON();
        const sw = { lat: rectangleBounds.south, lng: rectangleBounds.west };
        const nw = { lat: rectangleBounds.north, lng: rectangleBounds.west };
        const ne = { lat: rectangleBounds.north, lng: rectangleBounds.east };
        const se = { lat: rectangleBounds.south, lng: rectangleBounds.east };

        polygon.setPath([sw, nw, ne, se, sw]);

        return polygon;
    }

    /**
     * Get selected shape value.
     *
     * @returns {google.maps.Polygon}
     * @memberof DrawingService
     */
    public getSelectedShapeValue(): google.maps.Polygon {
        return this.selectedShape;
    }

    /**
     * Get center for selected shape.
     *
     * @returns {google.maps.LatLng}
     * @memberof DrawingService
     */
    public getCenterForSelectedShape(): google.maps.LatLng {
        const bounds = new google.maps.LatLngBounds();
        for (const coordinate of this.selectedShape.getPath().getArray()) {
            bounds.extend(coordinate);
        }
        return bounds.getCenter();
    }

    /**
     * Highlight the selected shape and make it edit- and draggable.
     *
     * @param {google.maps.Polygon} shape
     * @memberof DrawingService
     */
    public setSelection(shape: google.maps.Polygon): void {
        this.clearSelection();
        shape.setEditable(true);
        shape.setDraggable(true);
        this.selectColor(shape.get('fillColor') || shape.get('strokeColor'));
        this.selectedShape = shape;
    }

    /**
     * Clears the selection.
     *
     * @memberof DrawingService
     */
    public clearSelection(): void {
        if (this.selectedShape) {
            this.selectedShape.setEditable(false);
            this.selectedShape.setDraggable(false);
            this.selectedShape = null;
        }
    }

    /**
     * Retrieves the current options from the drawing manager and replaces the stroke or fill color as appropriate.
     *
     * @private
     * @param {*} color
     * @memberof DrawingService
     */
    private selectColor(color): void {
        const polygonOptions = this.drawingManager.get('polygonOptions');
        polygonOptions.fillColor = color;
        polygonOptions.strokeColor = color;
        this.drawingManager.set('polygonOptions', polygonOptions);
    }

    /**
     * Delete the selected shape.
     *
     * @memberof DrawingService
     */
    public deleteSelectedShape(): void {
        if (this.selectedShape) {
            this.selectedShape = this.mapService.removeFromMap(this.selectedShape) as google.maps.Polygon;
        }
    }

    /**
     * Set the drawing mode.
     *
     * @param {DrawingMode} drawingMode
     * @memberof DrawingService
     */
    public setDrawingMode(drawingMode: DrawingMode): void {
        this.drawingManager.set('drawingMode', drawingMode);
        this.drawingMode$.next(drawingMode);
        this.selectColor(midt['tailwind-colors'].blue[500].value,);
    }
}
