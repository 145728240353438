import { LineString, Point } from 'geojson';
import { Observable, Subject } from 'rxjs';
import { FeatureClass } from '../../viewmodels/FeatureClass';
import { HighlightViewModel } from '../../viewmodels/HighlightViewModel/HighlightViewModel';
import { LineStringViewModel } from '../../viewmodels/LineStringViewModel/LineStringViewModel';
import { SortKey } from '../../viewmodels/MapViewModelFactory/MapViewModelFactory';
import { Model2DViewModel } from '../../viewmodels/Model2DViewModel/Model2DViewModel';
import { Model3DViewModel } from '../../viewmodels/Model3DViewModel/Model3DViewModel';
import { PointViewModel } from '../../viewmodels/PointViewModel/PointViewModel';
import { PolygonViewModel } from '../../viewmodels/PolygonViewModel/PolygonViewModel';
import { DisplayRule } from '../locations/location.model';
import { RouteElement } from '../map/route-element-details/route-element.model';
import { BaseMapAdapter } from '../MapAdapter/BaseMapAdapter';
import { DisplayRuleService, isLocationActive } from '../services/DisplayRuleService/DisplayRuleService';
import { MapsIndoorsData } from '../shared/enums/MapsIndoorsData';
import { closePolygon, generateOffsetPolygon, getMetersPerPixel, offsetPolygonEdges } from '../shared/geometry-helper';
import { ExtendedLocation } from '../locations/location.service';
import { GraphData } from '../map/graph-data.model';
import midt from '@mapsindoors/midt/tokens/tailwind-colors.json';
import { primitiveClone } from '../shared/object-helper';
import { ExtrusionViewModel } from '../../viewmodels/ExtrusionViewModel/ExtrusionViewModel';
import { WallViewModel } from '../../viewmodels/WallViewModel/WallViewModel';
import { environment } from '../../environments/environment';

export class GeodataEditorViewState {
    private _viewModelsSubject: Subject<GeoJSON.Feature[]> = new Subject();
    private _viewModels: GeoJSON.Feature[] = [];

    constructor(private mapAdapter: BaseMapAdapter, private displayRuleService: DisplayRuleService) { }

    /**
     * Set the route element to be editable.
     *
     * @param {RouteElement} routeElement
     * @returns {Promise<void>}
     */
    async setRouteElement(routeElement: RouteElement, displayRule?: DisplayRule): Promise<void> {
        displayRule ??= await this.displayRuleService.getRouteElementDisplayRule(routeElement);
        const viewModels: GeoJSON.Feature[] = [];

        if (routeElement?.geometry?.type === 'Point') {
            const geometry = routeElement.geometry as Point;
            viewModels.push(await PointViewModel.create(routeElement.id, geometry, displayRule, SortKey.POINT + 1e7, MapsIndoorsData.RouteElement));
            viewModels.push(HighlightViewModel.create(routeElement.id, geometry, displayRule, SortKey.POINT + 1e7, MapsIndoorsData.RouteElement));
        }

        if (routeElement?.geometry?.type === 'LineString') {
            const geometry = routeElement.geometry as LineString;
            displayRule.polygon = { strokeColor: '#EF6CCE', strokeWidth: 10 };
            viewModels.push(await LineStringViewModel.create(routeElement.id, geometry, displayRule, SortKey.LINESTRING + 1e7, MapsIndoorsData.RouteElement));
        }

        this._viewModels = viewModels;

        this.next();
    }

    /**
     * Set the location to be editable.
     *
     * @param {ExtendedLocation} location
     * @returns {Promise<void>}
     */
    async setLocation(location: ExtendedLocation, displayRule?: DisplayRule): Promise<void> {
        displayRule ??= await this.displayRuleService.getDisplayRule(location.id ?? location.cloneOf);
        const viewModels: GeoJSON.Feature[] = [];

        if (location?.geometry?.type === 'Polygon' || location?.geometry?.type === 'MultiPolygon') {
            const polygon = location.geometry as GeoJSON.Polygon;
            if (!displayRule?.polygon?.visible) {
                displayRule.polygon = { ...displayRule.polygon, fillOpacity: 0, strokeOpacity: 0 };
            }
            viewModels.push(await PolygonViewModel.create(location.id, polygon, displayRule, SortKey.POLYGON + 1e7, MapsIndoorsData.Location));

            if (displayRule?.extrusion?.visible) {
                const extrusion = offsetPolygonEdges(polygon, -0.05);
                viewModels.push(await ExtrusionViewModel.create(location.id, extrusion, displayRule, SortKey.EXTRUSION + 1e7, MapsIndoorsData.Location));
            }

            if (displayRule?.walls?.visible && location.wallGeometry) {
                const geometry = location.wallGeometry?.geometry as GeoJSON.MultiPolygon | GeoJSON.Polygon;
                viewModels.push(await WallViewModel.create(location.id, geometry, displayRule, SortKey.WALL + 1e7, MapsIndoorsData.Location));
            }
        }

        if (location?.geometry?.type === 'Point' || location?.anchor?.type === 'Point') {
            const geometry = location.anchor as Point ?? location.geometry as Point;
            displayRule.icon = isLocationActive(location) ? displayRule.icon : `${environment.iconsBaseUrl}misc/inactive-marker.png`;
            viewModels.push(await PointViewModel.create(location.id, geometry, displayRule, SortKey.POINT + 1e7, MapsIndoorsData.Location));
        }

        if (displayRule?.model2D?.visible && displayRule?.model2D?.model) {
            const geometry = location.anchor as Point ?? location.geometry as Point;
            viewModels.push(await Model2DViewModel.create(location.id, geometry, displayRule, SortKey.MODEL2D + 1e7));
        }

        if (displayRule?.model3D?.visible && displayRule?.model3D?.model) {
            const geometry = location.anchor as Point ?? location.geometry as Point;
            viewModels.push(await Model3DViewModel.create(location.id, geometry, displayRule, SortKey.MODEL3D + 1e7, MapsIndoorsData.Location));
        }

        const highlightGeometry = location?.geometry?.type === 'Polygon' ? generateOffsetPolygon(location.geometry as GeoJSON.Polygon, (displayRule?.polygon?.strokeWidth ?? 0) / 2, getMetersPerPixel(location.geometry as GeoJSON.Polygon, this.mapAdapter.getZoom())).geometry : location.geometry;
        viewModels.push(HighlightViewModel.create(location.id, highlightGeometry as GeoJSON.Point | GeoJSON.Polygon, displayRule, SortKey.POLYGON + 1e7, MapsIndoorsData.Location));

        this._viewModels = viewModels;

        this.next();
    }

    /**
     * Set Graph Bounds on the map.
     *
     * @param {GraphData} graphData
     */
    async setGraphBounds(graphData: GraphData): Promise<void> {
        const viewModels: GeoJSON.Feature[] = [];
        const graphGeometry = graphData.graphArea as GeoJSON.Polygon;

        // In case the Polygon has a hole or the first and last coordinates are not the same.
        const closedPolygon = closePolygon(graphGeometry);

        if (graphData.id) {
            viewModels.push(await PolygonViewModel.create(graphData.id, closedPolygon, this.getGraphDisplayRules(), SortKey.POLYGON + 1e7, MapsIndoorsData.Unknown));
        }

        this._viewModels = viewModels;

        this.next();
    }

    /**
     * Update the geometry of the feature with the given id.
     *
     * @param {string} featureId
     * @param {GeoJSON.Geometry} geometry
     */
    public setGeometry(featureId: string, geometry: GeoJSON.Geometry): void {
        this.setGeometries([[featureId, geometry]]);
    }

    /**
     * Update the geometries of multiple ViewModels at once.
     *
     * @param {[string, GeoJSON.Geometry][]} geometries
     * @memberof GeodataEditorViewState
     */
    public setGeometries(geometries: [string, GeoJSON.Geometry][]): void {
        for (const [featureId, geometry] of geometries) {
            const viewModel = this.getViewModel(featureId);
            if (viewModel) {
                if (viewModel.properties.featureClass === FeatureClass.HIGHLIGHT && viewModel.geometry.type === 'Polygon') {
                    const highlightGeometry = generateOffsetPolygon(geometry as GeoJSON.Polygon, viewModel.properties.pixelOffset, getMetersPerPixel(geometry as GeoJSON.Polygon, this.mapAdapter.getZoom())).geometry;
                    viewModel.geometry = highlightGeometry;
                } else if (viewModel.properties.featureClass === FeatureClass.EXTRUSION && viewModel.geometry.type === 'Polygon') {
                    const extrusion = offsetPolygonEdges(geometry as GeoJSON.Polygon, -0.05);
                    viewModel.geometry = extrusion;
                } else {
                    viewModel.geometry = geometry;
                }
            }
        }
        this.next();
    }

    /**
     * Get a clone of the feature with the given id's geometry.
     *
     * @param {string} featureId
     * @returns {GeoJSON.Geometry}
     */
    public getGeometry(featureId: string): GeoJSON.Point | GeoJSON.Polygon | GeoJSON.MultiPolygon | GeoJSON.MultiPoint | GeoJSON.LineString {
        const viewModel = this.getViewModel(featureId);
        return (viewModel ? primitiveClone(viewModel?.geometry) : null) as GeoJSON.Point | GeoJSON.Polygon | GeoJSON.MultiPolygon | GeoJSON.MultiPoint | GeoJSON.LineString;
    }

    /**
     * Get the feature for with the given id.
     *
     * @private
     * @param {string} featureId
     * @returns {GeoJSON.Feature}
     */
    private getViewModel(featureId: string): GeoJSON.Feature {
        return this._viewModels.find(viewModel => viewModel.id === featureId);
    }

    /**
     * Display Rules for Graph outline.
     *
     * @returns {DisplayRule}
     */
    private getGraphDisplayRules(): DisplayRule {
        return {
            clickable: false,
            polygon: {
                visible: true,
                fillOpacity: 0,
                strokeColor: midt['tailwind-colors'].blue[400].value,
                strokeWidth: 2,
            }
        } as DisplayRule;
    }

    /**
     * Remove all map features.
     */
    public clear(): void {
        this._viewModels = [];
        this.next();
    }

    /**
     * Nofify subscribers.
     *
     * @private
     */
    private next(): void {
        this._viewModelsSubject.next([...this._viewModels]);
    }

    /**
     * Notifies subscribers to update.
     *
     * @public
     */
    public refresh(): void {
        this.next();
    }

    /**
     * Observable for subscribing to changes.
     *
     * @readonly
     * @type {Observable<GeoJSON.Feature[]>}
     */
    public get changes(): Observable<GeoJSON.Feature[]> {
        return this._viewModelsSubject.asObservable();
    }
}