import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

/**
 * A generic service that works for any resource.
 *
 * @export
 * @class DataService
 * @template T
 */
@Injectable({
    providedIn: 'root'
})
export class DataService {
    private api = environment.APIEndpoint;
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(private http: HttpClient) { }

    /**
     * Get item.
     *
     * @param {string} endpoint
     * @param {*} [options={}]
     * @returns {Observable<T>}
     * @memberof DataService
     */
    public getItem<T>(endpoint: string, options = {}): Observable<T> {
        return this.http.get<T>(`${this.api}${endpoint}`, options);
    }

    /**
     * Get items.
     *
     * @param {string} endpoint
     * @param {*} [options={}]
     * @returns {Observable<T[]>}
     * @memberof DataService
     */
    public getItems<T>(endpoint: string, options = {}): Observable<T[]> {
        return this.http.get<T[]>(`${this.api}${endpoint}`, options);
    }

    /**
     * Create item.
     *
     * @param {string} endpoint
     * @param {T} item
     * @param {*} [options={}]
     * @returns {Observable<string>}
     * @memberof DataService
     */
    public createItem<T>(endpoint: string, item: T, options = {}): Observable<string> {
        options = { ...options, headers: this.headers };
        return this.http.post<string>(`${this.api}${endpoint}`, item, options);
    }

    /**
     * Update item.
     *
     * @param {string} endpoint
     * @param {T} item
     * @param {*} [options={}]
     * @returns {Observable<T>}
     * @memberof DataService
     */
    public updateItem<T>(endpoint: string, item: T, options = {}): Observable<T> {
        options = { ...options, headers: this.headers };
        return this.http.put<T>(`${this.api}${endpoint}`, item, options);
    }

    /**
     * Update items.
     *
     * @param {string} endpoint
     * @param {Array<T>} items
     * @param {*} [options={}]
     * @returns {Observable<T>}
     * @memberof DataService
     */
    public updateItems<T>(endpoint: string, items: Array<T>, options = {}): Observable<T[]> {
        options = { ...options, headers: this.headers };
        return this.http.put<T[]>(`${this.api}${endpoint}`, items, options);
    }

    /**
     * Delete item.
     *
     * @param {string} endpoint
     * @param {*} [options={}]
     * @returns {Observable<void>}
     * @memberof DataService
     */
    public deleteItem(endpoint: string, options = {}): Observable<void> {
        return this.http.delete<void>(`${this.api}${endpoint}`, options);
    }

    /**
     * Delete items by their id.
     *
     * @param {string} endpoint
     * @param {Array<string>} itemsId
     * @param {*} [options={}]
     * @returns {Observable<void>}
     * @memberof DataService
     */
    public deleteItems(endpoint: string, itemsId: Array<string>, options = {}): Observable<void> {
        options = { ...options, headers: this.headers, body: itemsId };
        return this.http.delete<void>(`${this.api}${endpoint}`, options);
    }
}
