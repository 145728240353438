import { Injectable } from '@angular/core';

import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';
import { concatMap, filter, finalize } from 'rxjs/operators';

import { getGeometryFromGooglePolygon } from '../shared/geometry-helper';
import { ViewState, ViewStateService } from './view-state.service';
import { ImportService } from '../services/import.service';
import { MapService } from '../services/map.service';
import { NotificationService } from '../services/notification.service';
import { GraphData } from './graph-data.model';

import midt from '@mapsindoors/midt/tokens/tailwind-colors.json';

@Injectable()
export class GraphDataService {
    private graphData: GraphData;
    private graphOutline: google.maps.Polygon;
    private eventListeners: google.maps.MapsEventListener[] = [];
    private getSolutionGraphsData$: Observable<GraphData[]>;
    private graphIDsSubject$ = new BehaviorSubject<string[]>(null);

    constructor(
        private viewStateService: ViewStateService,
        private importService: ImportService,
        private mapService: MapService,
        private spinner: NgxSpinnerService,
        private notificationService: NotificationService,
    ) { }

    /**
     * Get graph IDs.
     *
     * @returns {Observable<string[]>}
     * @memberof GraphDataService
     */
    public getGraphIds(): Observable<string[]> {
        return this.graphIDsSubject$.asObservable().pipe(filter(graphIds => !!graphIds));
    }

    /**
     * Clear graphIds subject.
     *
     * @memberof GraphDataService
     */
    public clearGraphIdsList(): void {
        this.graphIDsSubject$.next(null);
    }

    /**
     * Initiate the creation flow a new graph bounds.
     *
     * @memberof GraphDataService
     */
    public createGraphOutline(): void {
        this.mapService.getMap()?.setOptions({ draggableCursor: 'crosshair' });

        this.graphOutline?.getPath()?.clear();
        this.eventListeners.push(this.mapService.addEventListener('click', (event: google.maps.MapMouseEvent) => {
            this.graphOutline?.getPath()?.push(event.latLng);
        }));
    }

    /**
     * Show/hide graph bounds.
     *
     * @param {string} graphId
     * @param {boolean} visible
     * @memberof GraphDataService
     */
    public setGraphOutlineVisibility(graphId: string, visible: boolean): void {
        if (!visible) {
            this.mapService.getMap()?.setOptions({ draggableCursor: '' });

            this.cancelGraphOutlineEdit();
            return;
        }

        this.viewStateService.setViewStateObservable(ViewState.Create);
        this.getGraphs(graphId);
    }

    /**
     * Cancel graph bounds edit and remove it from the map.
     *
     * @private
     * @memberof GraphDataService
     */
    private cancelGraphOutlineEdit(): void {
        this.mapService.removeFromMap(this.graphOutline);
        this.graphOutline = null;
        this.eventListeners?.forEach(listener => listener?.remove());
        this.eventListeners = [];
    }

    /**
     * Save the graph bounds if it has more than 2 vertices.
     *
     * @memberof GraphDataService
     */
    public saveGraphOutline(graphId): void {
        if (this.graphOutline?.getPath()?.getLength() > 2) {
            this.graphData.graphArea = getGeometryFromGooglePolygon(this.graphOutline);

            this.spinner.show();
            this.importService.saveSolutionGraph(this.graphData)
                .pipe(
                    concatMap(() => this.getSolutionGraphsData$),
                    finalize(() => this.spinner.hide())
                )
                .subscribe({
                    complete: () => {
                        this.viewStateService.setViewStateObservable(ViewState.Default);
                        this.setGraphOutlineVisibility(graphId, false);
                    },
                    error: error => this.notificationService.showError(error)
                });
        }
    }

    /**
     * Fetch the solutions graphs data.
     *
     * @private
     * @param {string} graphId
     * @memberof GraphDataService
     */
    private getGraphs(graphId: string): void {
        this.spinner.show();
        this.getSolutionGraphsData$ = this.importService.getSolutionGraphs()
            .pipe(finalize(() => {
                this.spinner.hide();
            }));

        this.getSolutionGraphsData$.subscribe(
            graphs => {
                this.drawGraphOutline(graphId, graphs);
            },
            error => {
                this.notificationService.showError(error);
                this.cancelGraphOutlineEdit();
            });
    }

    /**
     * Draw the graph bounds given a graphId.
     *
     * @private
     * @param {string} graphId
     * @param {GraphData[]} graphs
     * @memberof GraphDataService
     */
    private drawGraphOutline(graphId: string, graphs: GraphData[]): void {
        this.cancelGraphOutlineEdit();
        this.graphData = graphs.find(_graph => _graph.graphId.toLowerCase() === graphId.toLowerCase());
        if (this.graphData?.graphArea) {
            this.graphOutline = this.mapService.drawPolygon(this.graphData?.graphArea);
            this.graphOutline.set('strokeColor', midt['tailwind-colors'].blue[400].value);
            this.graphOutline.set('strokeWeight', 2);
            this.graphOutline.set('fillOpacity', 0);
            this.graphOutline.setEditable(true);
            this.graphOutline.set('clickable', false);
            this.addGraphOutlineRightClickEvent(this.graphOutline);
        }
    }

    /**
     * Add a right click event to remove a vertex.
     *
     * @private
     * @param {google.maps.Polygon} polygon
     * @memberof GraphDataService
     */
    private addGraphOutlineRightClickEvent(polygon: google.maps.Polygon): void {
        this.mapService.addEventListener('rightclick', (event: google.maps.PolyMouseEvent) => {
            if (!event?.vertex) {
                return;
            }

            const path = polygon?.getPath();
            path?.removeAt(event.vertex);
        }, polygon);
    }

    /**
     * Publish graph IDs in the solution.
     *
     * @memberof GraphDataService
     */
    public publishGraphIds(): void {
        this.spinner.show();
        this.importService.getGraphIds()
            .pipe(finalize(() => this.spinner.hide()))
            .subscribe(
                graphIds => this.graphIDsSubject$.next(graphIds),
                err => this.notificationService.showError(err)
            );
    }
}
