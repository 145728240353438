import pointInPolygon from 'point-in-polygon-hao';
import { Geometry } from '../../locations/location.model';
import { GeoJSONGeometryType } from '../enums';

/**
 * Converts a supported geometry to a GeoJSON MultiLineString.
 * Supported types: Polygon, MultiLineString, MultiPolygon, LineString.
 *
 * @param {Geometry} geometry - The input geometry.
 * @returns {GeoJSON.MultiLineString | null} The converted MultiLineString geometry, or null if not supported.
 */
export function toMultiLineString(geometry: Geometry): GeoJSON.MultiLineString | null {
    const { type, coordinates } = geometry || {};
    if (!coordinates) {
        return null;
    }
    switch (type) {
        case GeoJSONGeometryType.Polygon:
        case GeoJSONGeometryType.MultiLineString:
            return { type: GeoJSONGeometryType.MultiLineString, coordinates };
        case GeoJSONGeometryType.MultiPolygon:
            return { type: GeoJSONGeometryType.MultiLineString, coordinates: coordinates.flat(1) };
        case GeoJSONGeometryType.LineString:
            return { type: GeoJSONGeometryType.MultiLineString, coordinates: [coordinates] };
        default:
            return null;
    }
}

/**
 * Determines if the point is inside the given Polygon or MultiPolygon.
 *
 * @param {GeoJSON.Position} point
 * @param {(GeoJSON.Polygon | GeoJSON.MultiPolygon)} polygon
 * @returns {boolean}
 */
export function isPointInPolygon(point: GeoJSON.Position, polygon: GeoJSON.Polygon | GeoJSON.MultiPolygon): boolean {
    if (polygon.type === GeoJSONGeometryType.MultiPolygon) {
        return (polygon as GeoJSON.MultiPolygon)?.coordinates.some(polygon => isPointInPolygon(point, { type: 'Polygon', coordinates: polygon }));
    } else {
        return !!pointInPolygon(point, polygon.coordinates);
    }
}