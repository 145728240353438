import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FilterCategoriesBySelectedPipe, FilterCategoriesPipe } from '../pipes/filter-categories';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NumericalSortPipe, OrderBy } from '../pipes/orderby';

import { AliasesComponent } from '../aliases/aliases.component';
import { BuildingSelectorComponent } from './building-selector/building-selector.component';
import { CategorySelectComponent } from '../category-select/category-select.component';
import { CommonModule } from '@angular/common';
import { CustomPropertiesComponent } from './custom-properties/custom-properties.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { FilterCustomersPipe } from '../customers/filter-customers';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuzzySearchPipe } from './fuzzy-search/fuzzy-search.pipe';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { InfoTooltipComponent } from './info-tooltip/info-tooltip.component';
import { LazyLoadDirective } from './directives/lazy-load.directive';
import { MaterialElevationDirective } from './directives/material-elevation.directive';
import { MaterialModule } from '../material.module';
import { NavigatableContainerComponent } from './navigatable-container/navigatable-container.component';
import { NavigatableSectionComponent } from './navigatable-container/navigatable-section/navigatable-section.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OrderByLanguage } from '../pipes/order-by-language.pipe';
import { PanelFooterComponent } from './panel-footer/panel-footer.component';
import { PanelHeaderComponent } from './panel-header/panel-header.component';
import { RouterModule } from '@angular/router';
import { StatusBitComponent } from './status-bit/status-bit.component';
import { StreetViewComponent } from '../subcomponents/street-view/street-view.component';
import { SyncManagerComponent } from './sync-manager/sync-manager.component';
import { SyncManagerService } from './sync-manager/sync-manager.service';
import { TilesManagerComponent } from '../tiles-manager/tiles-manager.component';
import { ToggleSearchableComponent } from './toggle-searchable/toggle-searchable.component';
import { UserRolesAccessibilityComponent } from './user-roles-accessibility/user-roles-accessibility.component';
import { UserRolesRestrictionsSelectorComponent } from './user-roles-restrictions-selector/user-roles-restrictions-selector.component';
import { SetFocusDirective } from './directives/setFocus.directive';
import { DisplayRuleDetailsEditorComponent } from './display-rule-details-editor/display-rule-details-editor.component';
import { LockedDisplayRuleDirective } from '../display-rule-details/locked-display-rule-handler.directive';
import { LockButtonComponent } from '../display-rule-details/lock-button/lock-button.component';
import { ResponsiveImageStyleDirective } from './directives/responsive-image-style.directive';

/**
 * A module that is shared across several modules.
 *
 * @export
 * @class SharedModule
 */
@NgModule({
    declarations: [
        AliasesComponent,
        BuildingSelectorComponent,
        CategorySelectComponent,
        CustomPropertiesComponent,
        CustomPropertiesComponent,
        DisplayRuleDetailsEditorComponent,
        DragDropDirective,
        FilterCategoriesBySelectedPipe,
        FilterCategoriesPipe,
        FilterCustomersPipe,
        FuzzySearchPipe,
        FuzzySearchPipe,
        ImageUploadComponent,
        ImageUploadComponent,
        InfoTooltipComponent,
        LazyLoadDirective,
        LazyLoadDirective,
        LockButtonComponent,
        LockedDisplayRuleDirective,
        MaterialElevationDirective,
        MaterialElevationDirective,
        NavigatableContainerComponent,
        NavigatableSectionComponent,
        NumericalSortPipe,
        OrderBy,
        OrderByLanguage,
        PanelFooterComponent,
        PanelHeaderComponent,
        SetFocusDirective,
        StatusBitComponent,
        StreetViewComponent,
        SyncManagerComponent,
        TilesManagerComponent,
        ToggleSearchableComponent,
        UserRolesAccessibilityComponent,
        UserRolesRestrictionsSelectorComponent,
        ResponsiveImageStyleDirective
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        MaterialModule,
        NgxSpinnerModule,
        ReactiveFormsModule,
        RouterModule,
    ],
    exports: [
        AliasesComponent,
        BuildingSelectorComponent,
        CategorySelectComponent,
        CommonModule,
        CustomPropertiesComponent,
        CustomPropertiesComponent,
        DisplayRuleDetailsEditorComponent,
        DragDropDirective,
        FilterCategoriesBySelectedPipe,
        FilterCategoriesPipe,
        FilterCustomersPipe,
        FlexLayoutModule,
        FormsModule,
        FuzzySearchPipe,
        FuzzySearchPipe,
        ImageUploadComponent,
        ImageUploadComponent,
        InfoTooltipComponent,
        LockButtonComponent,
        LockedDisplayRuleDirective,
        MaterialModule,
        NavigatableContainerComponent,
        NavigatableSectionComponent,
        NgxSpinnerModule,
        NgxSpinnerModule,
        NumericalSortPipe,
        OrderBy,
        OrderByLanguage,
        PanelFooterComponent,
        PanelHeaderComponent,
        ReactiveFormsModule,
        SetFocusDirective,
        StatusBitComponent,
        StreetViewComponent,
        SyncManagerComponent,
        TilesManagerComponent,
        ToggleSearchableComponent,
        UserRolesAccessibilityComponent,
        UserRolesRestrictionsSelectorComponent,
        ResponsiveImageStyleDirective
    ],
    providers: [
        OrderBy,
        OrderByLanguage,
        SyncManagerService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
