import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Check if the value of the form control is below the threshold comparison controls value.
 *
 * @param {string} thresholdControlKey - Control key to be used as threshold.
 * @param {number} [maxZoomLevel=22] - Solutions max zoom level.
 * @returns {ValidatorFn}
 */
export function belowThresholdValidator(thresholdControlKey: string, maxZoomLevel = 22): ValidatorFn {
    return (formControl: FormControl): ValidationErrors | null => {
        const thresholdFormControl: FormControl = formControl.parent?.controls[thresholdControlKey];
        const threshold = thresholdFormControl?.value || maxZoomLevel;
        const isValid = formControl.value < threshold || (formControl.value === maxZoomLevel && thresholdFormControl.value === maxZoomLevel);

        // Delete threshold error from threshold control and update validity
        if (isValid && thresholdFormControl?.hasError('belowThreshold')) {
            delete thresholdFormControl.errors['belowThreshold'];
            thresholdFormControl.updateValueAndValidity();
        } else if (!isValid) {
            thresholdFormControl.setErrors({ belowThreshold: true });
        }
        return isValid ? null : { aboveThreshold: 'Value is above the threshold' };
    };
}

/**
 * Check if the value of the form controls is above the threshold comparison controls value.
 *
 * @param {string} thresholdControlKey - Control key to be used as threshold.
 * @param {number} [maxZoomLevel=22] - Solutions max zoom level.
 * @returns {ValidatorFn}
 */
export function aboveThresholdValidator(thresholdControlKey: string, maxZoomLevel = 22): ValidatorFn {
    return (formControl: FormControl): ValidationErrors | null => {
        const thresholdFormControl = formControl.parent?.controls[thresholdControlKey];
        const threshold = thresholdFormControl?.value || 0;
        const isValid = formControl.value > threshold || (formControl.value === maxZoomLevel && thresholdFormControl.value === maxZoomLevel);

        // Delete threshold error from threshold control and update validity
        if (isValid && thresholdFormControl?.hasError('aboveThreshold')) {
            delete thresholdFormControl.errors['aboveThreshold'];
            thresholdFormControl.updateValueAndValidity();
        } else if (!isValid) {
            thresholdFormControl.setErrors({ aboveThreshold: true });
        }
        return isValid ? null : { belowThreshold: 'Value is below the threshold' };

    };
}