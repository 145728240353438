import { Injectable } 	from '@angular/core';


@Injectable()
export class FormatService {

    constructor(
    ) { }

    // Dates

    utcDate(date) {
        if (!date) date = new Date();
        /*
        var _utc = date;
        if(date instanceof Date) {
            _utc = date.toISOString();
        }
        */

        const month = this.convertMonth(date.getUTCMonth());
        const _utc = date.getUTCDate() + ' ' + month + ' ' + date.getUTCFullYear() + ' ' + date.getUTCHours() + ':' + date.getUTCMinutes() + ':' + date.getUTCSeconds();
        return _utc;
    }

    convertMonth(month: number) {
        switch (month) {
            case 0:
                return 'jan';
            case 1:
                return 'feb';
            case 2:
                return 'mar';
            case 3:
                return 'apr';
            case 4:
                return 'may';
            case 5:
                return 'jun';
            case 6:
                return 'jul';
            case 7:
                return 'aug';
            case 8:
                return 'sep';
            case 9:
                return 'oct';
            case 10:
                return 'nov';
            default:
                return 'dec';
        }
    }

    // String operations

    stringDateToDate(str) {
        if (str !== null && str !== '') {
            const date = new Date(str);
            return date;
        }
        return null;
    }

}
