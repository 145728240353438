import { Component, OnInit } from '@angular/core';

import { Subscription, Observable } from 'rxjs';

import { saveAs } from 'file-saver';
import { json2csvAsync } from 'json-2-csv';
import { AnalyticsDashboardService } from './analytics-dashboard.service';
import { CustomerService } from '../customers/customer.service';
import { Customer } from '../customers/customer.model';

@Component({
    selector: 'app-analytics-dashboard',
    templateUrl: './analytics-dashboard.component.html',
    styleUrls: ['./analytics-dashboard.component.scss']
})
export class AnalyticsDashboardComponent implements OnInit {
    private subscriptions = new Subscription();

    constructor(
        private customerService: CustomerService,
        private dashboardService: AnalyticsDashboardService
    ) { }

    public venues: number;
    public venuesErr: string;

    public buildings: Array<any> = [];
    public buildingsErr: string;
    public buildingsCount: number;
    public buildingsViewData: Array<any> = [];

    public floors: Array<any>;
    public floorsErr: string;
    public floorsCount: number;

    public categories: Array<any>;
    public categoriesErr: string;
    public categoriesCount: number;
    public categoriesViewData: Array<any> = [];

    public locationTypes: Array<any> = [];
    public locationTypesErr: string;
    public locationTypesCount: number;
    public locationTypesViewData: Array<any> = [];

    public POIsWithCategory: number;
    public POIsWithCategoryErr: string;
    public POIsWithAlias: number;
    public POIsWithAliasErr: string;

    public roomsWithAlias: number;
    public roomsWithAliasErr: string;
    public roomsWithCategory: number;
    public roomsWithCategoryErr: string;

    public buildings_filters_buildings: Array<any> = [];

    public types_filters_buildings: Array<any> = [];
    public types_filters_types: Array<any> = [];

    public categories_filters_buildings: Array<any> = [];
    public categories_filters_categories: Array<any> = [];

    ngOnInit(): void {
        this.subscriptions.add(
            (this.customerService.getCurrentCustomer() as Observable<Customer>)
                .subscribe(() => this.init()));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private init(): void {
        const ERROR_MESSAGE = 'Data not available';

        this.dashboardService.getFloors()
            .then(data => {
                this.floors = Array.from(data);
                this.buildingsViewData = this.floors;
                this.floorsCount = this.floors.length;
                return this.floors;
            }).then(floors => floors.reduce((items, floor) => {
                items.set(floor.BuildingId, { text: floor.Address ?? floor.BuildingName, value: floor.BuildingId });
                return items;
            }, new Map())
            ).then(buildings => {
                buildings = Array.from(buildings.values())
                    .sort((a: any, b: any) => (a.text > b.text && 1) || a.text === b.text ? 0 : -1);

                this.buildingsCount = buildings.length;
                this.buildings_filters_buildings = [...buildings.map(this.createDropDownItem)];
                this.types_filters_buildings = [...buildings.map(this.createDropDownItem)];
                this.categories_filters_buildings = [...buildings.map(this.createDropDownItem)];

            }).catch(() => {
                this.buildingsErr = ERROR_MESSAGE;
                this.floorsErr = ERROR_MESSAGE;
            });

        this.dashboardService.getLocationTypes()
            .then(data => {
                this.locationTypes = Array.from(data);
                this.locationTypesViewData = this.locationTypes;
                return this.locationTypesViewData;
            })
            .then(locationTypesViewData => locationTypesViewData.reduce((types, type) => {
                types.set(type.TypeName, { text: type.TypeName, value: type.TypeName });
                return types;
            }, new Map()))
            .then(locationTypes => {
                locationTypes = Array.from(locationTypes.values())
                    .sort((a: any, b: any) => (a.text > b.text && 1) || a.text === b.text ? 0 : -1);
                this.locationTypesCount = locationTypes.length;
                this.types_filters_types = [...locationTypes.map(this.createDropDownItem)];
            })
            .catch(() => this.locationTypesErr = ERROR_MESSAGE);

        this.dashboardService.getCategories()
            .then(data => {
                this.categories = Array.from(data);
                this.categoriesViewData = this.categories;
                return this.categoriesViewData;
            })
            .then(categoriesViewData => categoriesViewData.reduce((categories, category) => {
                categories.set(category.CategoryName, { text: category.CategoryName, value: category.CategoryName });
                return categories;
            }, new Map()))
            .then(categories => {
                categories = Array.from(categories.values())
                    .sort((a: any, b: any) => (a.text > b.text && 1) || a.text === b.text ? 0 : -1);
                this.categoriesCount = categories.length;
                this.categories_filters_categories = [...categories.map(this.createDropDownItem)];
            })
            .catch(() => this.categoriesErr = ERROR_MESSAGE);

        this.dashboardService.getVenuesCount()
            .then((count: number) => this.venues = count)
            .catch(() => this.venuesErr = ERROR_MESSAGE);
        this.dashboardService.getPOIsWithAliasCount()
            .then((count: number) => this.POIsWithAlias = count)
            .catch(() => this.POIsWithAliasErr = ERROR_MESSAGE);
        this.dashboardService.getPOIsWithCategoryCount()
            .then((count: number) => this.POIsWithCategory = count)
            .catch(() => this.POIsWithCategoryErr = ERROR_MESSAGE);
        this.dashboardService.getRoomsWithAliasCount()
            .then((count: number) => this.roomsWithAlias = count)
            .catch(() => this.roomsWithAliasErr = ERROR_MESSAGE);
        this.dashboardService.getRoomsWithCategoryCount()
            .then((count: number) => this.roomsWithCategory = count)
            .catch(() => this.roomsWithCategoryErr = ERROR_MESSAGE);
    }

    /**
     * Creates an HTMLMiDropdownItemElement from a { text, value } object.
     *
     * @private
     * @param {*} item
     * @returns {HTMLMiDropdownItemElement}
     * @memberof AnalyticsDashboardComponent
     */
    private createDropDownItem(item: any): HTMLMiDropdownItemElement {
        const dropdownItem = document.createElement('mi-dropdown-item') as HTMLMiDropdownItemElement;
        dropdownItem.text = item.text || '';
        dropdownItem.value = item.value;
        return dropdownItem;
    }


    /**
     * Used for reducing an array of HTMLMiDropdownItemElement to only the selected values.
     *
     * @private
     * @param {Array<string>} arr
     * @param {HTMLMiDropdownItemElement} item
     * @returns {Array<string>}
     * @memberof AnalyticsDashboardComponent
     */
    private getSelecedValues(arr, item): Array<string> {
        if (item.selected) { arr.push(item.value); }
        return arr;
    }

    /**
     * For filtering the buildings table.
     *
     * @returns {void}
     * @memberof AnalyticsDashboardComponent
     */
    public filterBuildingsTable(): void {
        const buildingIds = this.buildings_filters_buildings.reduce(this.getSelecedValues, []);

        if (buildingIds.length === 0) {
            this.buildingsViewData = [...this.floors];
            return;
        }

        this.buildingsViewData = [...this.floors.filter(item => {
            return buildingIds.indexOf(item.BuildingId.toString()) > -1;
        })];
    }


    /**
     * For filtering the types table.
     *
     * @returns {void}
     * @memberof AnalyticsDashboardComponent
     */
    public filterTypesTable(): void {
        const typeNames = this.types_filters_types.reduce(this.getSelecedValues, []);
        const buildingIds = this.types_filters_buildings.reduce(this.getSelecedValues, []);

        if (typeNames.length === 0 && buildingIds.length === 0) {
            this.locationTypesViewData = [...this.locationTypes];
            return;
        }

        this.locationTypesViewData = [...this.locationTypes.filter(item => {
            if (buildingIds.length > 0 && buildingIds.indexOf(item.BuildingId.toString()) < 0) {
                return false;
            }

            if (typeNames.length > 0 && typeNames.indexOf(item.TypeName) < 0) {
                return false;
            }

            return true;
        })];
    }


    /**
     * For filtering the categories table.
     *
     * @returns {void}
     * @memberof AnalyticsDashboardComponent
     */
    public filterCategoriesTable(): void {
        const categories = this.categories_filters_categories.reduce(this.getSelecedValues, []);
        const buildingIds = this.categories_filters_buildings.reduce(this.getSelecedValues, []);

        if (categories.length === 0 && buildingIds.length === 0) {
            this.categoriesViewData = [...this.categories];
            return;
        }

        this.categoriesViewData = [...this.categories.filter(item => {
            if (buildingIds.length > 0 && buildingIds.indexOf(item.BuildingId.toString()) < 0) {
                return false;
            }

            if (categories.length > 0 && categories.indexOf(item.CategoryName) < 0) {
                return false;
            }

            return true;
        })];
    }


    /**
     * Export the input data as CSV and save it as a file.
     *
     * @private
     * @param {Array<any>} data
     * @memberof AnalyticsDashboardComponent
     */
    private exportCSV(data: Array<any>): void {
        json2csvAsync(data).then((output: string) => {
            const blob: Blob = new Blob([output], { type: 'text/csv' });
            saveAs(blob, 'export.csv');
        });
    }

    /**
     * Export building data as CSV.
     *
     * @memberof AnalyticsDashboardComponent
     */
    public exportBuildingsToCSV(): void {
        this.exportCSV(this.buildingsViewData);
    }

    /**
     * Export types data as CSV.
     *
     * @memberof AnalyticsDashboardComponent
     */
    public exportTypesToCSV(): void {
        this.exportCSV(this.locationTypesViewData);
    }

    /**
     * Export categories data as CSV.
     *
     * @memberof AnalyticsDashboardComponent
     */
    public exportCategoriesToCSV(): void {
        this.exportCSV(this.categoriesViewData);
    }
}
