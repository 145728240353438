import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';

import { finalize } from 'rxjs/operators';

import { SolutionService } from '../../services/solution.service';
import { MediaSyncService } from './media-sync.service';
import { NotificationService } from '../../services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IMediaItem } from '../media-item/media-item.model';
import { Solution } from '../../solutions/solution.model';

@Component({
    selector: 'media-sync',
    templateUrl: './media-sync.component.html',
    styleUrls: ['./media-sync.component.scss']
})
export class MediaSyncComponent implements OnInit {
    public isSynced: boolean = false;
    public mediaItem: IMediaItem = this.data.mediaItem;
    public solutionSelectionModel = new SelectionModel<any>(true, []);
    public imageName: string = `${this.data.mediaItem.name}.${this.data.mediaItem.type}`;
    public syncableSolutions: Solution[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<MediaSyncComponent>,
        private solutionService: SolutionService,
        private mediaSyncService: MediaSyncService,
        private notificationService: NotificationService,
        private spinner: NgxSpinnerService
    ) { }

    /**
     * NgOnInit.
     */
    ngOnInit(): void {
        this.syncableSolutions = this.solutionService.getSyncableSolutions();
    }

    /**
     * Synchronize an icon from one solution to other solutions.
     *
     * @param {string} mediaId
     * @param {string} sourceSolutionId
     * @param {string[]} targetSolutionIds
     */
    private syncMedia(mediaId: string, sourceSolutionId: string, targetSolutionIds: string[]): void {
        this.spinner.show();
        this.mediaSyncService.syncMedia(mediaId, sourceSolutionId, targetSolutionIds)
            .pipe(finalize(() => this.spinner.hide()))
            .subscribe(
                () => {
                    this.isSynced = true;
                    this.solutionSelectionModel.clear();
                    this.closeModal();
                    this.notificationService.showSuccess('Sync was successful');
                },
                error => this.notificationService.showError(error)
            );
    }

    /**
     * Selects all solutions if they are not all selected or clear all selection.
     */
    public toggleSolutions(): void {
        if (this.isAllSolutionsSelected()) {
            this.solutionSelectionModel.clear();
        } else {
            this.solutionSelectionModel.select(...this.syncableSolutions);
        }
    }

    /**
     * Does the number of selected solutions match the total number of solutions.
     *
     * @returns {boolean}
     */
    public isAllSolutionsSelected(): boolean {
        return this.solutionSelectionModel.selected.length === this.syncableSolutions.length;
    }

    /**
     * Synchronize icon, type or category.
     */
    public synchronize(): void {
        const sourceId = this.solutionService.getStaticSolution().id;
        const targetIds = this.solutionSelectionModel.selected.map(solution => solution.id);

        if (this.data.mediaItem as IMediaItem) {
            this.syncMedia(this.data.mediaItem.id, sourceId, targetIds);
            return;
        }
    }

    /**
     * Deselects all solutions.
     */
    public selectNoneSolutions(): void {
        this.solutionSelectionModel.clear();
    }

    /**
     * Selects all solutions.
     */
    public selectAllSolutions(): void {
        this.solutionSelectionModel.select(...this.syncableSolutions);
    }

    /**
     * When clicking on the Cancel button, confirm box appears.
     */
    public onCancel(): void {
        if (this.solutionSelectionModel.selected.length > 0) {
            // eslint-disable-next-line no-alert
            if (!confirm('You have not finished syncing yet. Are you sure you want to close it?')) {
                return;
            }
        }
        this.solutionSelectionModel.clear();
        this.closeModal();
    }

    /**
     * Closes modal, if previously there was any solution selected, it will run the onCancel function.
     */
    public closeModal(): void {
        if (this.solutionSelectionModel.selected.length > 0) this.onCancel();
        this.dialogRef.close();
    }
}
