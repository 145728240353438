<div class="margin-horizontal-medium" fxLayout="column" fxLayoutGap="16px"
    *ngIf="!simpleView; else simpleViewTemplate">
  <div class="sub-section-header margin-top-none">
    <h3>Searchable</h3>
  </div>
  <span>Turn this off to hide in searches.</span>
  <mat-slide-toggle [(ngModel)]="searchable" (change)="setSearchableBit($event.checked)">Show in searches</mat-slide-toggle>
  <p *ngIf="haveDifferentSearchableBits"><i>The selected items have different statuses. Saving will set the same status for all selected items.</i></p>
</div>

<ng-template #simpleViewTemplate>
    <div class="margin-horizontal-medium margin-top-medium">
        <mat-slide-toggle [(ngModel)]="searchable" (change)="setSearchableBit($event.checked)">
        </mat-slide-toggle>
    </div>
</ng-template>
