import { Directive, HostListener, HostBinding, EventEmitter, Output, Input } from '@angular/core';
import { NotificationService } from '../../services/notification.service';

@Directive({
    selector: '[DragDrop]'
})
export class DragDropDirective {

    @Input() private allowed_extensions: Array<string> = [];
    @Output() private filesChangeEmiter: EventEmitter<File[]> = new EventEmitter();
    // @Output() private filesInvalidEmiter: EventEmitter<File[]> = new EventEmitter();

    @HostBinding('style.background') private background = 'rgba(0, 0, 0, 0)';

    constructor(
        private notificationService: NotificationService,
    ) { }

    @HostListener('dragover', ['$event']) public onDragOver(evt): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = 'rgba(0, 0, 0, 0.12)';
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(evt): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = 'rgba(0, 0, 0, 0)';
    }

    @HostListener('drop', ['$event']) public onDrop(evt): void {
        evt.preventDefault();
        evt.stopPropagation();

        this.background = 'rgba(0, 0, 0, 0)';

        const files = evt.dataTransfer.files;
        const valid_files: Array<File> = [];
        // let invalid_files: Array<File> = [];

        if (files.length > 0) {
            for (const file of files) {
                const ext = file.name.split('.')[file.name.split('.').length - 1];
                if (this.allowed_extensions.lastIndexOf(ext) !== -1) {
                    valid_files.push(file);
                } else {
                    const alertString: string =  ext + ' files is not allowed';
                    this.notificationService.showError(alertString);
                    // invalid_files.push(file);
                }
            }
            this.filesChangeEmiter.emit(valid_files);
            // this.filesInvalidEmiter.emit(invalid_files);
        }
    }

}
