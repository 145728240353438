/**
 * Returns the file name from a url.
 *
 * @param {string} url
 * @returns {string}
 */
export function getNameFromUrl(url: string): string {
    if (!url) return;
    return url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.'));
}

/**
 * Returns the data without the base64 prefix.
 *
 * @param {string} data
 * @returns {string}
 */
export function removeBase64Prefix(data: string): string {
    return data?.replace(/^.+?;base64,/, '');
}
