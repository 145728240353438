import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { tap, filter } from 'rxjs/operators';

import { FormatService } from '../services/format.service';
import { environment } from '../../environments/environment';
import { Customer } from './customer.model';

@Injectable({ providedIn: 'root' })
export class CustomerService {
    private currentCustomer = new BehaviorSubject<Customer>(null);
    private api = environment.APIEndpoint;
    private customerUrl = this.api + 'api/customers/';

    constructor(
        private http: HttpClient,
        private formatService: FormatService
    ) { }

    /**
     * Set the current customer.
     *
     * @param {Customer} customer
     * @memberof CustomerService
     */
    public setCurrentCustomer(customer: Customer): void {
        this.currentCustomer.next(customer);
    }

    /**
     * Get the current customer.
     *
     * @param {boolean} [asValue] - Should it be returned as a value or observable.
     * @returns {(Customer | Observable<Customer>)}
     * @memberof CustomerService
     */
    public getCurrentCustomer(asValue?: boolean): Customer | Observable<Customer> {
        return asValue
            ? this.currentCustomer.getValue()
            : this.currentCustomer.asObservable().pipe(filter(customer => !!customer));
    }

    /**
     * Fetch a single customer given its ID.
     *
     * @param {string} customerId
     * @returns {Observable<Customer>}
     * @memberof CustomerService
     */
    public getCustomer(customerId: string): Observable<Customer> {
        return this.http.get<Customer>(`${this.customerUrl}details/${customerId}`);
    }

    /**
     * Update a customer.
     *
     * @param {Customer} customer
     * @returns {Observable<any>}
     * @memberof CustomerService
     */
    public updateCustomer(customer: Customer): Observable<any> {
        const requestOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.put(this.customerUrl, customer, { headers: requestOptions })
            .pipe(tap(() => {
                if (customer.id === this.currentCustomer.getValue().id) {
                    this.currentCustomer.next(customer);
                }
            }));
    }

    /**
     * Gets all customers.
     *
     * @returns {Observable<Customer[]>}
     * @memberof CustomerService
     */
    getCustomers(): Observable<Customer[]> {
        return this.http.get<Customer[]>(this.customerUrl);
    }

    /**
     * Creates a new customer.
     *
     * @param {Customer} customer
     * @returns {Observable<string>}
     * @memberof CustomerService
     */
    newCustomer(customer: Customer): Observable<string> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post<string>(this.customerUrl, customer, { headers });
    }

    deleteCustomer(customer: Customer): Observable<any> {
        const lmDate = new Date(customer.lastModified);
        const lastModified = this.formatService.utcDate(lmDate);
        const headers = new HttpHeaders({ 'If-Modified-Since': lastModified });
        return this.http.delete(this.customerUrl + customer.id, { headers });
    }
}
