import { Subject, Subscription } from 'rxjs';

import {
    lineString as toLineString, multiLineString as toMultiLineString, nearestPointOnLine, pointOnFeature, polygon as toPolygon,
    transformScale, buffer, length, along, LineString
} from '@turf/turf';

import { GeoJSONGeometryType } from '../../shared/enums';
import { MultiLineString } from 'geojson';
import { shiftPolygonStartEndAwayFromDoorPoint } from '../../shared/geometry-helper';
import booleanIntersects from '@turf/boolean-intersects';
import { isPointInPolygon } from '../../shared/geometry-helper/geojson';

class SnapTool {
    #restrictionPolygon: GeoJSON.Polygon;
    #restrictionLineString: GeoJSON.Feature<GeoJSON.LineString>;
    #geometryChangesSubscription: Subscription;

    /**
     * Set a polygon geometry that a point can be snapped to be inside.
     *
     * @param {GeoJSON.Polygon} polygonGeometry
     * @param {Subject} geometryChangesSubject
     */
    restrictToPolygon(polygonGeometry: GeoJSON.Polygon, geometryChangesSubject: Subject<{ geometry: GeoJSON.Geometry, anchor?: GeoJSON.Point }>): void {
        this.#setup(polygonGeometry);

        this.#geometryChangesSubscription = geometryChangesSubject.subscribe(({ geometry }) => {
            if (geometry.type === GeoJSONGeometryType.Polygon) {
                this.#setup(geometry as GeoJSON.Polygon);
            }
        });
    }

    /**
     * Returns the lineSegment of the multiLineString that intersects with the provided point.
     *
     * @param {MultiLineString} multiLineString
     * @param {GeoJSON.Point} point
     * @returns {Array<Array<number>>}
     */
    getIntersectingLineSegment(multiLineString: MultiLineString, point: GeoJSON.Point): GeoJSON.Feature<LineString> {
        const bufferPoint = buffer(point, 1, { units: 'millimeters', steps: 4 });
        const lineSegment = multiLineString.coordinates.find(lineSegment => booleanIntersects(bufferPoint, toLineString(lineSegment)));

        return lineSegment ? toLineString(lineSegment) : null;
    }

    /**
     * Returns the polygon closest to the given point.
     *
     * @param {GeoJSON.Polygon[]} polygons
     * @param {GeoJSON.Point} point
     * @returns {GeoJSON.Polygon}
     */
    getNearestPolygon(polygons: GeoJSON.Polygon[], point: GeoJSON.Point): GeoJSON.Polygon {
        let polygon = polygons.find(polygon => isPointInPolygon(point.coordinates, polygon));

        if (!polygon) {
            const multiLineString = polygons.reduce((multiLineString, polygon) => {
                multiLineString.geometry.coordinates = multiLineString.geometry.coordinates.concat(polygon.coordinates);
                return multiLineString;
            }, toMultiLineString([]));

            const pointOnLine = nearestPointOnLine(multiLineString, point);
            const box = buffer(pointOnLine, 1, { units: 'millimeters', steps: 4 });
            polygon = polygons.find(polygon => booleanIntersects(box, polygon));
        }

        return polygon;
    }

    /**
     * Calculates the midpoint on the edge of the polygon closest to the given point.
     *
     * @param {GeoJSON.Point} point
     * @param {MultiLineString} polygon
     * @returns {GeoJSON.Point}
     */
    calculateNearestMidpoint(point: GeoJSON.Point, polygon: GeoJSON.Polygon): GeoJSON.Point {
        const polygonAsMultiLineString = toMultiLineString(polygon.coordinates);
        const nearestPoint = nearestPointOnLine(polygonAsMultiLineString, point);

        const intersectingLineSegment = this.getIntersectingLineSegment(polygonAsMultiLineString.geometry, nearestPoint.geometry);

        const shiftedLineString = shiftPolygonStartEndAwayFromDoorPoint(intersectingLineSegment, nearestPoint);

        // Find the closest point on the line segment.
        const nearestPointOnCleanPolygonLineString = nearestPointOnLine(shiftedLineString, point.coordinates);

        // Get the closest wall that mouse pointer is. Calculates the length of it and find a middle point.
        const edgeLineString = toLineString([shiftedLineString.geometry.coordinates[nearestPointOnCleanPolygonLineString.properties.index], shiftedLineString.geometry.coordinates[nearestPointOnCleanPolygonLineString.properties.index + 1]]);

        const midpoint = this.calculateMidpointOfLineString(edgeLineString.geometry);

        return midpoint;
    }

    /**
     * Returns the point on the polygon that is the closest to the provided point.
     *
     * @param {GeoJSON.Polygon} polygon
     * @param {GeoJSON.Point} point
     * @param {boolean} snapToMidpoints - Will find the closest midpoint if true.
     * @returns {GeoJSON.Point}
     */
    getPointOnPolygon(polygon: GeoJSON.Polygon, point: GeoJSON.Point, snapToMidpoints: boolean): GeoJSON.Point {
        let pointOnPolygon: GeoJSON.Point;

        if (snapToMidpoints) {
            // If the new lineString should be snapped to a midpoint, it finds the closest midpoint to the current point on the polygon.
            pointOnPolygon = this.calculateNearestMidpoint(point, polygon);
        } else {
            const polygonAsMultiLineString = toMultiLineString(polygon.coordinates);
            // The closest point of the new lineString on the closest line from the current point.
            pointOnPolygon = nearestPointOnLine(polygonAsMultiLineString.geometry, point.coordinates)?.geometry;
        }

        return pointOnPolygon;
    }

    /**
     * Snap the given point to be inside the set polygon.
     *
     * @param {GeoJSON.Point} point
     * @returns {GeoJSON.Point}
     */
    snap(point: GeoJSON.Point): GeoJSON.Point {
        if (!this.#restrictionPolygon || isPointInPolygon(point.coordinates, this.#restrictionPolygon)) {
            return point;
        } else {
            const nearestPoint = nearestPointOnLine(this.#restrictionLineString, point);
            return nearestPoint.geometry;
        }
    }

    /**
     * Reset and clean up.
     */
    clear(): void {
        this.#geometryChangesSubscription?.unsubscribe();
        this.#restrictionPolygon = null;
        this.#restrictionLineString = null;
    }

    /**
     * Generate necessary values for snapping.
     *
     * @param {GeoJSON.Polygon} polygonGeometry
     */
    #setup(polygonGeometry: GeoJSON.Polygon): void {
        this.#restrictionPolygon = transformScale(toPolygon(polygonGeometry.coordinates), 0.9999).geometry; // We scale a little down to avoid rounding errors when placing anchor on edge.
        this.#restrictionLineString = toLineString(this.#restrictionPolygon.coordinates[0]);
    }

    /**
     * Ensures that the anchor is moved inside the polygon if it is outside.
     *
     * @param {GeoJSON.Point} anchor
     * @param {GeoJSON.Polygon} polygon
     * @returns {GeoJSON.Point}
     */
    ensureAnchorIsInsidePolygon(anchor: GeoJSON.Point, polygon?: GeoJSON.Polygon): GeoJSON.Point {
        if (polygon && !isPointInPolygon(anchor.coordinates, polygon)) {
            return pointOnFeature(polygon)?.geometry ?? anchor;
        }
        return anchor;
    }

    /**
     * If the anchor is not inside the polygon, it returns the point on the polygon that is closest to the anchor. Or else it returns the anchor.
     *
     * @param {GeoJSON.Point} anchor
     * @param {GeoJSON.Polygon} [polygon]
     * @returns {GeoJSON.Point}
     * @memberof SnapTool
     */
    contain(anchor: GeoJSON.Point, polygon?: GeoJSON.Polygon): GeoJSON.Point {
        if (!polygon || polygon.type !== 'Polygon' || isPointInPolygon(anchor.coordinates, polygon)) {
            return anchor;
        } else {
            const scaledPolygon = transformScale(toPolygon(polygon.coordinates), 0.9999).geometry;
            return nearestPointOnLine(toLineString(scaledPolygon.coordinates[0]), anchor)?.geometry;
        }
    }

    /**
     * Returns the point on the line string halfway from start to end.
     *
     * @param {GeoJSON.LineString} lineString
     * @returns {GeoJSON.Point}
     */
    calculateMidpointOfLineString(lineString: LineString): GeoJSON.Point {
        const lineStringLength = length(toLineString(lineString.coordinates), { units: 'meters' });
        const centerPoint = along(lineString, lineStringLength / 2, { units: 'meters' });
        return centerPoint.geometry;
    }
}

export default new SnapTool;