<ng-container *ngIf="languages" [formGroup]="formGroup">
    <ng-container>
        <section class="section">
            <p>Language independent Custom Properties are not shown in the app, but can be used to set up logic in your
                solution.</p>
            <section class="section__column" [formArrayName]="'generic'">
                <section class="section__row" *ngFor="let fields of formGroup.get('generic').controls; let i = index"
                    [formGroup]="fields">
                    <label class="mi-label">Key
                        <input class="mi-input" type="text" formControlName="key"
                            [setFocus]="formGroup.get('generic').controls.length - 1 === i && fields.get('key').value === ''" />
                        <ng-container *ngIf="formGroup.get('generic').invalid && formGroup.get('generic').touched">
                            <small role="alert" class="mi-form__error"
                                *ngIf="formGroup.get('generic' + '.' + i + '.key').errors?.required">
                                The key is required.
                            </small>
                            <small role="alert" class="mi-form__error"
                                *ngIf="formGroup.get('generic' + '.' + i + '.key').errors?.unique">
                                The key already exists.
                            </small>
                        </ng-container>
                    </label>
                    <label class="mi-label mi-label__value">Value
                        <info-tooltip class="tooltip" *ngIf="inheritedKeys?.includes(fields.get('key').value)"
                            text="Custom Property template from Type.">
                        </info-tooltip>
                        <textarea class="mi-textarea" rows="2" placeholder="Add Value"
                            formControlName="value"></textarea>
                        <small role="alert" class="mi-form__warning"
                            *ngIf="isWarningShown('generic', formGroup.get('generic' + '.' + i + '.key').value, formGroup.get('generic').value[i].value)">
                            This Value is overridden by a Value in one of the languages with the same Key. <a
                                href="https://docs.mapsindoors.com/custom-properties/" target="_blank">Learn more.</a>
                        </small>
                    </label>
                    <button *ngIf="!inheritedKeys?.includes(fields.get('key').value)"
                        class="mi-button mi-button--icon-only mi-button--small trash" type="button"
                        (click)="removeGenericProperty(i)">
                    </button>
                </section>
                <section class="section__row section__row--justify-center">
                    <button type="button" class="mi-button mi-button--base mi-button--icon-left"
                        (click)="addGenericProperty()">
                        <img src="/assets/images/add-circle-black.svg">
                        Add Custom Property
                    </button>
                </section>
            </section>
        </section>
    </ng-container>
    <br>
    <br>
    <mi-tabs>
        <ng-container *ngFor="let language of languages">
            <mi-tab [label]="language === defaultLanguage ? language + '*' : language"
                [tabFor]="'tab-panel-' + language">
            </mi-tab>
            <mi-tab-panel [id]="'tab-panel-' + language">
                <section class="section">
                    <section class="section__column" [formArrayName]="language">
                        <section class="section__row"
                            *ngFor="let fields of formGroup.get(language).controls; let i = index" [formGroup]="fields">
                            <label class="mi-label">Key
                                <input class="mi-input" type="text" formControlName="key"
                                    [setFocus]="formGroup.get(language).controls.length - 1 === i && fields.get('key').value === ''" />
                                <ng-container
                                    *ngIf="formGroup.get(language + '.' + i + '.key').invalid && formGroup.get(language + '.' + i + '.key').touched">
                                    <small role="alert" class="mi-form__error"
                                        *ngIf="formGroup.get(language + '.' + i + '.key').errors?.required">
                                        The key is required.
                                    </small>
                                    <small role="alert" class="mi-form__error"
                                        *ngIf="formGroup.get(language + '.' + i + '.key').errors?.unique">
                                        The key already exists.
                                    </small>
                                </ng-container>
                            </label>
                            <label class="mi-label mi-label__value">Value
                                <info-tooltip class="tooltip" *ngIf="inheritedKeys?.includes(fields.get('key').value)"
                                    text="Custom Property template from Type.">
                                </info-tooltip>
                                <textarea class="mi-textarea" rows="2" placeholder="Add Value" formControlName="value">
                            </textarea>
                                <small role="alert" class="mi-form__warning"
                                    *ngIf="isWarningShown(language, formGroup.get(language + '.' + i + '.key')?.value, formGroup.get(language)?.value[i].value )">
                                    This Value overrides the Value in “Generic” with the same Key. <a
                                        href="https://docs.mapsindoors.com/custom-properties/" target="_blank">Learn
                                        more.</a>
                                </small>
                                <small role="alert" class="mi-form__warning"
                                    *ngIf="isKeyInheritingValue(formGroup.get(language + '.' + i + '.key')?.value, formGroup.get(language)?.value[i].value)">
                                    Key “inherits” Value from Generic. <a
                                        href="https://docs.mapsindoors.com/custom-properties/" target="_blank">Learn
                                        more.</a>
                                </small>
                            </label>
                            <button
                                *ngIf="!inheritedKeys?.includes(fields.get('key').value) && language === defaultLanguage"
                                class="mi-button mi-button--icon-only mi-button--small trash" type="button"
                                (click)="removeProperty(i)">
                            </button>
                        </section>
                        <section class="section__row section__row--justify-center" *ngIf="language === defaultLanguage">
                            <button type="button" class="mi-button mi-button--base mi-button--icon-left"
                                (click)="addProperty()">
                                <img src="/assets/images/add-circle-black.svg">
                                Add Custom Property
                            </button>
                        </section>
                    </section>
                </section>
            </mi-tab-panel>
        </ng-container>
    </mi-tabs>
</ng-container>