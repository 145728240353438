import { Component, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { GeodataEditor, GeodataEditorFactory } from '../../../../../GeodataEditor/GeodataEditor.factory';
import { BaseMapAdapter, MapMouseCursor } from '../../../../../MapAdapter/BaseMapAdapter';
import { Floor } from '../../../../../buildings/floor.model';
import { DisplayRuleService } from '../../../../../services/DisplayRuleService/DisplayRuleService';
import { MapAdapterMediator } from '../../../../map-adapter.mediator';
import { NetworkService } from '../../../../../network-access/network.service';
import { BuildingService } from '../../../../../buildings/building.service';
import { MapSidebarService } from '../../../../map-sidebar/map-sidebar.service';
import { LocationService } from '../../../../../locations/location.service';
import { take } from 'rxjs/operators';
import { RouteElementType } from '../../../../../map/route-element-details/route-element.model';
import { RouteElementDetailsEditorComponent } from '../../../../route-element-details-editor/route-element-details-editor.component';
import { RouteElementEditorOperation } from '../../../../../GeodataEditor/GeodataEditorOperation/RouteElementEditorOperation';
import { FloorService } from '../../../../../services/floor.service';

@Component({
    selector: 'add-door-component',
    templateUrl: './add-door.component.html',
    styleUrls: ['./add-door.component.scss']
})

export class AddDoorComponent implements OnInit, OnDestroy {
    private _destroySubject: Subject<void> = new Subject();
    private _geoDataEditor: GeodataEditor;
    private _mapAdapter: BaseMapAdapter;
    private _subscriptions: Subscription = new Subscription();
    private _editorOperation: RouteElementEditorOperation;
    private floor: Floor;

    @Output() public readonly destroy = this._destroySubject.asObservable();

    constructor(
        private displayRuleService: DisplayRuleService,
        private mapAdapterMediator: MapAdapterMediator,
        private networkService: NetworkService,
        private buildingService: BuildingService,
        private mapSidebar: MapSidebarService,
        private locationService: LocationService,
        private floorService: FloorService
    ) { }

    /** NgOnInit. */
    ngOnInit(): void {
        const floorSubscription = this.buildingService.selectedFloor$.subscribe((floor) => {
            this.floor = floor;
        });

        this.floorService.disableFloorSelector(true);

        this._mapAdapter = this.mapAdapterMediator?.getMapAdapter();
        this._geoDataEditor = GeodataEditorFactory.create(this._mapAdapter, this.displayRuleService, this.locationService);
        this._mapAdapter.setMapMouseCursor(MapMouseCursor.Crosshair);

        const DEFAULT_RADIUS_IN_METERS = 0.5;
        const DEFAULT_DOOR_WIDTH_IN_METERS = 0.9;

        const door = this.networkService.createNewRouteElement(this.floor.floorIndex, { type: 'Point', coordinates: [0, 0] }, RouteElementType.Door);
        door.radius = DEFAULT_RADIUS_IN_METERS;

        this._editorOperation = this._geoDataEditor?.placeDoor(door, DEFAULT_DOOR_WIDTH_IN_METERS);
        this._editorOperation.changes
            .pipe(take(1))
            .subscribe(() => {
                this._mapAdapter.isClickable = true;
                const { componentInstance } = this.mapSidebar.open(RouteElementDetailsEditorComponent);

                if (componentInstance) {
                    componentInstance.data = door;
                }

                this._destroySubject.next();
            });

        this._subscriptions
            .add(floorSubscription);
    }

    /** NgOnDestroy. */
    ngOnDestroy(): void {
        this.floorService.disableFloorSelector(false);
        this._editorOperation?.complete();
        this._mapAdapter.setMapMouseCursor(MapMouseCursor.Default);
        this._subscriptions.unsubscribe();
        this._destroySubject.complete();
    }

    /**
     * Resets the cursor to defualt, stops the event-listener on the map and hides the current toolbar..
     */
    public onCancel(): void {
        // eslint-disable-next-line no-alert
        if (confirm('Canceling without saving will discard any changes you have made.')) {
            this._destroySubject.next();
        }
    }

    /**
     * Cancels the add door operation.
     *
     * @param {KeyboardEvent} event
     */
    @HostListener('document:keydown.escape', ['$event'])
    private onEscapeHandler(event: KeyboardEvent): void {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.onCancel();
    }
}