import { Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'filterSolutionsCustomers',
    pure: true
})
export class FilterSolutionsCustomersPipe implements PipeTransform {
    transform(value: any[], query: any): any {
        query = query ? query.toLowerCase() : '';
        return query && value ?
            value.filter(solution => {
                if (solution.name.toLowerCase().indexOf(query) !== -1 || solution.id.indexOf(query) !== -1) {
                    return true;
                }
                if ((solution.customerName && solution.customerName.toLowerCase().indexOf(query) !== -1) || solution.id.indexOf(query) !== -1) {
                    return true;
                }
                return false;
            }
            ) :
            value;
    }
}

@Pipe({
    name: 'filterSolutions',
    pure: true
})
export class FilterSolutionsPipe implements PipeTransform {
    transform(value: any[], query: any): any {
        query = query ? query.toLowerCase() : '';
        return query && value ?
            value.filter(solution => {
                if (solution.name.toLowerCase().indexOf(query) !== -1) {
                    return true;
                }
            }
            ) :
            value;
    }
}

@Pipe({
    name: 'filterVenues',
    pure: true
})
export class FilterVenuesPipe implements PipeTransform {
    transform(value: any[], query: any): any {
        query = query ? query.toLowerCase() : '';
        return query && value ?
            value.filter(venue => {
                if (venue.displayName.toLowerCase().indexOf(query) !== -1) {
                    return true;
                }
            }
            ) :
            value;
    }
}

@Pipe({
    name: 'filterSolutionsByType'
})
export class FilterSolutionsByTypePipe implements PipeTransform {
    transform(value: any[], settings: any[]): any {
        return settings && value ?
            value.filter(solution => {
                if (settings !== null && settings.length > 0 && solution.implementationType !== null) {
                    for (const setting of settings) {
                        if (solution.implementationType.toLowerCase() === setting.toLowerCase()) {
                            return true;
                        }
                    }
                    return false;
                } else {
                    return true;
                }
            })
            :
            value;
    }
}
