import { Subject } from 'rxjs';
import { DisplayRule } from '../../locations/location.model';
import { RouteElement, RouteElementType } from '../../map/route-element-details/route-element.model';
import { primitiveClone } from '../../shared/object-helper';
import { GeodataEditorViewState } from '../GeodataEditorViewState';
import { GeodataEditorOperation } from './GeodataEditorOperation';
import { MultiLineString } from 'geojson';
import { along, buffer, lineString, length } from '@turf/turf';
import { getSubLineStringFromPointOnLineString } from '../../shared/geometry-helper';
import booleanIntersects from '@turf/boolean-intersects';

export class RouteElementEditorOperation extends GeodataEditorOperation {
    #doorWidth: number;

    private _routeElement: RouteElement;

    constructor(
        private _originalRouteElement: RouteElement,
        protected _changesSubject: Subject<{ geometry: GeoJSON.Geometry, anchor?: GeoJSON.Point }>,
        protected _editorViewState: GeodataEditorViewState,
        private roomsMultiLineString: MultiLineString,
        protected onComplete: () => void,
        private onRotate: (degrees: number) => void
    ) {
        super(_changesSubject, _editorViewState, onComplete);
        this._routeElement = primitiveClone(this._originalRouteElement);
        _changesSubject.subscribe(({ geometry }) => {
            this._routeElement.geometry = geometry as GeoJSON.Point;
        });
    }

    /**
     * Door width getter.
     *
     * @returns {number}
     */
    public get doorWidth(): number {
        return this.#doorWidth;
    }

    /**
     * Door width setter.
     */
    public set doorWidth(value: number) {
        this.#doorWidth = value;
    }

    private _currentRotation: number = 0;

    /**
     * Redraw features to refresh the styling.
     */
    public redraw(displayRule?: DisplayRule): void {
        this?._editorViewState.setRouteElement(this._routeElement, displayRule);
    }

    /**
     * Reset all changes.
     */
    public reset(): void {
        this._routeElement = primitiveClone(this._originalRouteElement);
        this?._editorViewState.setRouteElement(this._routeElement);

    }

    /**
     * Set the door width - when icreasing the width, the door should snap.
     *
     * @param {number} width
     */
    public setDoorWidth(width: number): void {
        if (this._routeElement?.type === RouteElementType.Door) {
            this.doorWidth = width;

            // Logic to get current door as a lineString and it's center point.
            const doorLineString: GeoJSON.LineString = this._editorViewState.getGeometry(`LINE:${this._routeElement.id}`) as GeoJSON.LineString;
            const centerPoint = along(doorLineString, length(lineString(doorLineString.coordinates), { units: 'meters' }) / 2, { units: 'meters' });

            // Buffer by 1 centemeter to expand boundary of geoemetry.
            const bufferDoor = buffer(centerPoint, 1, { units: 'millimeters', steps: 4 });

            // Find the lineSegment that the door intersects with.
            const lineSegment = this.roomsMultiLineString?.coordinates.find(lineSegment => booleanIntersects(bufferDoor, lineString(lineSegment).geometry));

            const result = getSubLineStringFromPointOnLineString(lineString(lineSegment), centerPoint, this.doorWidth);

            this._editorViewState.setGeometry(`LINE:${this._routeElement.id}`, result.geometry);

            this._changesSubject.next({ geometry: result.geometry, anchor: centerPoint.geometry });
        }
    }

    /**
     * End the operation.
     */
    public complete(): void {
        this._changesSubject.complete();
        this?.onComplete();
    }

    /**
     * Rotate location.
     *
     * @param {number} degrees
     */
    public rotate(degrees: number): void {
        const _degrees = degrees - this._currentRotation;
        this._currentRotation = degrees;
        this?.onRotate(_degrees);
    }
}



