/**
 * Convert the first letter of the given string to lower case.
 *
 * @param {string} str
 * @returns {string}
 */
export function lowerCaseFirstLetter(str: string): string {
    return str.charAt(0).toLowerCase() + str.slice(1);
}

/**
 * Convert the given buffer to a hex string.
 *
 * @param {ArrayBuffer} buffer
 * @returns {string}
 */
export function toHexString(buffer: ArrayBuffer): string {
    const uint32Array = new Uint32Array(buffer);
    const hexArr = ['0x'];
    for (const byte of uint32Array) {
        hexArr.push(byte.toString(16));
    }

    return hexArr.join('');
}

/**
 * Extends the string with the given number in parentheses.
 *
 * @param {string} str
 * @param {number} num
 * @returns {string}
 */
export function  extendStringWithNumberInParentheses(str: string, num: number): string {
    return num > 0 ? `${str} (${num})` : str;
}