import * as turf from '@turf/turf';

import { Feature, GeometryObject, LineString, MultiLineString, Point, Position } from 'geojson';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { GeoJSONGeometryType, UnitSystem } from '../shared/enums';
import { getSubLineStringFromPointOnLineString, pickPolygonBasedOnClosestPointAndMousePointer, shiftPolygonStartEndAwayFromDoorPoint } from '../shared/geometry-helper';

import { Injectable } from '@angular/core';
import midt from '@mapsindoors/midt/tokens/tailwind-colors.json';
import { Location } from '../locations/location.model';
import { LocationService } from '../locations/location.service';
import { MapService } from './map.service';
import { UserAgentService } from './user-agent.service';

enum SnapMode {
    Nearest = 'nearest',          // snap to the nearest point on the room polygon from the mouse pointer
    EdgeMidpoint = 'edgemidpoint' // snaps to the midpoint of the polygon edge closest to the mouse pointer.
}

@Injectable({
    providedIn: 'root'
})
/**
 * This service contains helper methods to support:
 *
 * - Adding a single door by clicking on a point of a room polygon. Door width is set from an input field elsewhere.
 * - Adding multiple doors by clicking to define the sides of the doors.
 * - Editing a door by dragging the linestring on the map. Door width is edited from an input field elsewhere.
 */
export class RouteElementGeometryService {

    private location: Location;

    private doorPointSet = new BehaviorSubject(null);

    // Google Map properties and events
    private locationPolygonOnMap: google.maps.Polygon; // TODO: Move away from Google dependencies
    private snapMarker: google.maps.Marker; // Map marker for snap point TODO: Move away from Google dependencies
    private mouseMoveListener: google.maps.MapsEventListener; // TODO: Move away from Google dependencies
    private mouseClickListener: google.maps.MapsEventListener;  // TODO: Move away from Google dependencies

    private snapToleranceMeters = 5; // When mouse cursor is closer than this to the location, snap to the location outline

    private selectDoorPointKeyPressedHandler: EventListener;
    private selectDoorPointKeyReleasedHandler: EventListener;

    private snapMarkerIconOptions: google.maps.Icon = {
        url: null,
        scaledSize: new google.maps.Size(12, 12),
        anchor: new google.maps.Point(6, 6)
    };

    /*
     * Properties specific to adding or editing a single door
     */
    private doorCenterPoint: Feature<Point>;
    public doorLineString: Feature<LineString>;
    private doorLineStringOnMap: google.maps.Polyline;

    private userAgentUnitSystem: UnitSystem;
    private positionIsValidSubject = new Subject<boolean>();
    private geometrySubject = new Subject<GeometryObject>();

    public doorWidth: number;
    public maxDoorWidth: number;
    private defaultDoorWidth = {
        [UnitSystem.Imperial]: 36, // inches
        [UnitSystem.Metric]: 90    // cm
    };

    private polygonRingsOnFloor: Position[][] = [];
    private multiLineStringForPolygonRings: Feature<MultiLineString>;

    private addDoorSnapMode = SnapMode.EdgeMidpoint;

    /*
     * Properties specific to adding multiple doors
     */
    private doorLineStringsOnMap: google.maps.Polyline[] = [];
    public doorLineStrings: Feature<LineString>[] = [];
    private doorPoints: Feature<Point>[] = []; // Points on the polygon that are clicked to generate doors.
    private doorFirstPointMarker: google.maps.Marker; // Marker for first point on door shown while selecting second door point.

    constructor(
        private mapService: MapService,
        private locationService: LocationService,
        private userAgentService: UserAgentService
    ) {
        this.doorWidth = this.defaultDoorWidth[this.userAgentService.unitSystem];

        // Bind keyboard event listeners used for changing snap mode.
        this.selectDoorPointKeyPressedHandler = this.selectDoorPointKeyPressed.bind(this);
        this.selectDoorPointKeyReleasedHandler = this.selectDoorPointKeyReleased.bind(this);
    }

    /**
     * Get validity state of route element geometry.
     *
     * @returns {Observable<boolean>}
     * @memberof RouteElementGeometryService
     */
    public getGeometryIsValid(): Observable<boolean> {
        return this.positionIsValidSubject.asObservable();
    }

    /**
     * Get notified whenever geometry updates.
     *
     * @returns {Observable<GeometryObject>}
     * @memberof RouteElementGeometryService
     */
    public getUpdatedGeometry(): Observable<GeometryObject> {
        return this.geometrySubject.asObservable();
    }

    /**
     * Initiates the process of adding a door to a location.
     * When creating a door, you can drag it to snap to the edges of the current location.
     *
     * @param {Location} location - The location to set a door for.
     * @memberof RouteElementGeometryService
     */
    public addDoor(location: Location): void {
        this.initializeForAdding(location);

        this.userAgentUnitSystem = this.userAgentService.unitSystem;
        const locationPolygonCircumference = turf.length(turf.lineString(location.geometry.coordinates[0]), { units: 'meters' });
        const maxDoorWidthInCm = locationPolygonCircumference / 2 * 100;
        this.maxDoorWidth = Math.ceil(this.userAgentUnitSystem === UnitSystem.Imperial ? maxDoorWidthInCm / 2.54 : maxDoorWidthInCm);

        this.setDoorPoint();
    }

    /**
     * Initiates the process of adding door(s) to a location by clicking to define the sides of the door.
     *
     * @param {Location} location - The location to set a doors on.
     * @memberof RouteElementGeometryService
     */
    public addDoors(location): void {
        this.initializeForAdding(location);
        this.setDoorPoints();
    }

    /**
     * Set up variables and map bounds for the process of adding one or more doors.
     *
     * @param {Location} location - The location to set a doors on.
     * @memberof RouteElementGeometryService
     */
    private initializeForAdding(location): void {
        this.location = location;

        // Set bounds to fit rooom while leaving space for the controls in the bottom
        this.mapService.setBounds(location.geometry.bbox, { bottom: 60, left: 0, right: 0, top: 0 });

        this.drawLocationPolygon();

        this.mapService.setMapObjectsClickability(false);
    }

    /**
     * Initiates the process of editing the geometry of a LineString route element.
     * When editing a door, you can drag it to snap to the edge of any room on the current floor.
     *
     * @param {GeometryObject} geometry
     * @param {string} venueId
     * @param {number} floorIndex
     * @memberof RouteElementGeometryService
     */
    public editDoor(geometry: GeometryObject, venueId: string, floorIndex: number): void {
        this.doorLineString = turf.lineString((geometry as LineString).coordinates);
        this.doorWidth = turf.length(this.doorLineString, { units: 'meters' });

        if (this.doorLineStringOnMap) {
            this.mapService.removeFromMap(this.doorLineStringOnMap);
        }
        this.renderDoorLineStringOnMap();

        // Find all rooms on the floor in order to make it possible to snap to their polygons
        this.locationService.getLocations()
            .subscribe(locations => {
                // Generate array of all polygon rings based on rooms on the floor of the route element.
                locations.filter(
                    location => location.pathData.floor === floorIndex && location.locationType.toLowerCase() === 'room' && location.geometry.type === GeoJSONGeometryType.Polygon
                ).forEach(location => {
                    location.geometry.coordinates.forEach(coordinates => this.polygonRingsOnFloor.push(coordinates));
                });

                // A MultiLineString is created with all the polygon rings that we can later use to snap the Linestring to.
                this.multiLineStringForPolygonRings = turf.multiLineString(this.polygonRingsOnFloor);

                // When dragging the LineString (google maps polygon) set new middle point and update.
                this.doorLineStringOnMap.addListener('drag', (mouseEvent: google.maps.MapMouseEvent) => {
                    this.doorCenterPoint = turf.point([mouseEvent.latLng.lng(), mouseEvent.latLng.lat()]);

                    this.updateLineStringWhenEditing();
                });
            });
    }

    /**
     * Update width of door.
     *
     * @param {number} width - In meters.
     * @memberof RouteElementGeometryService
     */
    public updateDoorWidthWhenEditing(width: number,): void {
        const currentLineStringLength = turf.length(this.doorLineString, { units: 'meters' });
        this.doorCenterPoint = turf.along(this.doorLineString, currentLineStringLength / 2, { units: 'meters' });
        this.doorWidth = width;

        this.updateLineStringWhenEditing();
    }

    /**
     * Calculate and draw LineString based on point and current width.
     *
     * @memberof RouteElementGeometryService
     */
    private updateLineStringWhenEditing(): void {
        // Figure out which room to snap to
        const closestPointOnPolygonRings = turf.nearestPointOnLine(this.multiLineStringForPolygonRings, this.doorCenterPoint);
        const polygonRingGeometries = this.polygonRingsOnFloor.map(polygonRing => turf.polygon([polygonRing]));
        const closestPolygonRingIndex = pickPolygonBasedOnClosestPointAndMousePointer(polygonRingGeometries.map(p => p.geometry), closestPointOnPolygonRings, this.doorCenterPoint);

        // The lineString should not be longer than half of the available circumference
        let maxLength;
        if (closestPolygonRingIndex) {
            const locationPolygonCircumference = turf.length(turf.lineString(this.polygonRingsOnFloor[closestPolygonRingIndex]), { units: 'meters' });
            maxLength = locationPolygonCircumference / 2;
        }

        // Calculate the new geometry: Convert polygon ring to lineString and then make the route element lineString follow that.
        const closestLineString = turf.lineString(this.polygonRingsOnFloor[closestPolygonRingIndex]);
        const updatedLineString = getSubLineStringFromPointOnLineString(closestLineString, closestPointOnPolygonRings, this.doorWidth);

        // Set validity of geometry (color of LineString, push subject)
        if (updatedLineString === undefined || closestPolygonRingIndex === undefined || (maxLength && this.doorWidth > maxLength)) {
            this.doorLineStringOnMap.setOptions({
                strokeColor: midt['tailwind-colors'].red[500].value
            });
            this.positionIsValidSubject.next(false);
        } else {
            this.doorLineStringOnMap.setOptions({
                strokeColor: '#ef6cce'
            });
            this.positionIsValidSubject.next(true);

            this.doorLineString = updatedLineString;

            this.doorLineStringOnMap.setPath((updatedLineString.geometry as LineString).coordinates.map(position => ({ lat: position[1], lng: position[0] })));
            this.geometrySubject.next(updatedLineString.geometry);
        }
    }

    /**
     * Cancel adding a door. Will clean up markers and event listeners.
     *
     * @memberof RouteElementGeometryService
     */
    public cancelOperation(): void {
        this.doorWidth = this.defaultDoorWidth[this.userAgentService.unitSystem];

        this.doorPointSet.next(false);
        this.mapService.setMapObjectsClickability(true);

        if (this.snapMarker) {
            this.snapMarker.setPosition(null);
        }

        this.mapService.removeFromMap(this.snapMarker);
        this.mapService.removeFromMap(this.locationPolygonOnMap);
        this.mapService.removeFromMap(this.doorLineStringOnMap);
        this.doorLineStringsOnMap.forEach(doorLineStringOnMap => this.mapService.removeFromMap(doorLineStringOnMap));

        this.doorLineStringsOnMap = [];
        this.doorLineStrings = [];
        this.doorPoints = [];

        this.mapService.removeEventListener(this.mouseMoveListener);
        this.mapService.removeEventListener(this.mouseClickListener);
        document.removeEventListener('keydown', this.selectDoorPointKeyPressedHandler);
        document.removeEventListener('keyup', this.selectDoorPointKeyReleasedHandler);
    }

    /**
     * Get if a door point is set.
     *
     * @returns {Observable<boolean>}
     * @memberof RouteElementGeometryService
     */
    public isDoorPointSet(): Observable<boolean> {
        return this.doorPointSet.asObservable();
    }

    /**
     * Update the door width.
     *
     * @param {number} doorWidth
     * @memberof RouteElementGeometryService
     */
    public updateDoorWidthWhenCreating(doorWidth: number): void {
        this.doorWidth = doorWidth;

        this.calculateDoorLineString();
        this.updateDoorLineStringOnMap();
    }

    /**
     * Render the location as an extra polygon on the map.
     *
     * @memberof RouteElementGeometryService
     */
    private drawLocationPolygon(): void {
        this.locationPolygonOnMap = this.mapService.drawPolygon(this.location.geometry);
        this.locationPolygonOnMap.setOptions({
            fillOpacity: 0.5,
            fillColor: '#ef6cce',
            strokeOpacity: 0,
            zIndex: 1,
            clickable: false // otherwise mousemove event will not register while pointer is on top of polygon
        });
    }

    /**
     * Set up listeners and marker so the user can click on a point on the location polygon.
     *
     * @memberof RouteElementGeometryService
     */
    private setDoorPoint(): void {
        // Marker that snaps to the location when selecting door point
        this.snapMarker = this.mapService.drawMarker(null, {
            ...this.snapMarkerIconOptions,
            url: '/assets/images/line-marker-blue.svg'
        });
        this.snapMarker.setClickable(false);

        // Listen for keyboard events to change snap mode
        document.addEventListener('keydown', this.selectDoorPointKeyPressedHandler);
        document.addEventListener('keyup', this.selectDoorPointKeyReleasedHandler);

        this.mouseMoveListener = this.mapService.addEventListener('mousemove', this.selectDoorPointMouseMoved.bind(this));
        this.mouseClickListener = this.mapService.addEventListener('click', this.selectDoorPointMouseClicked.bind(this));
    }

    /**
     * When holding key down, change snap mode, so the door point snaps to the nearest point on the room polygon from the mouse pointer.
     *
     * @param {KeyboardEvent} keyboardEvent
     * @memberof RouteElementGeometryService
     */
    private selectDoorPointKeyPressed(keyboardEvent: KeyboardEvent): void {
        if (['ShiftLeft', 'ShiftRight'].includes(keyboardEvent.code)) {
            this.addDoorSnapMode = SnapMode.Nearest;
        }
    }

    /**
     * When releasing shift key, change snap mode, so the door point snaps to the midpoint of the polygon edge closest to the mouse pointer.
     *
     * @param {KeyboardEvent} keyboardEvent
     * @memberof RouteElementGeometryService
     */
    private selectDoorPointKeyReleased(keyboardEvent: KeyboardEvent): void {
        if (['ShiftLeft', 'ShiftRight'].includes(keyboardEvent.code)) {
            this.addDoorSnapMode = SnapMode.EdgeMidpoint;
        }
    }

    /**
     * Place a marker on the edge of the location if mouse pointer is close enough.
     * If snap mode is "halfway", always place point on halfway on the edge.
     *
     * @param {google.maps.MapMouseEvent} mouseEvent
     * @memberof RouteElementGeometryService
     */
    private selectDoorPointMouseMoved(mouseEvent: google.maps.MapMouseEvent): void {
        const nearestPointOnLocationPolygon = turf.nearestPointOnLine(turf.lineString(this.location.geometry.coordinates[0]), [mouseEvent.latLng.lng(), mouseEvent.latLng.lat()]);

        // If the mouse pointer is not close enough to the location polygon, remove the snap marker.
        if (nearestPointOnLocationPolygon.properties.dist >= this.snapToleranceMeters / 1000) {
            this.snapMarker.setPosition(null);
            return;
        }

        switch (this.addDoorSnapMode) {
            case SnapMode.Nearest: {

                // Snap the door point to the nearest point on the polygon:

                this.snapMarker.setPosition({ lat: nearestPointOnLocationPolygon.geometry.coordinates[1], lng: nearestPointOnLocationPolygon.geometry.coordinates[0] });
                break;
            }

            case SnapMode.EdgeMidpoint: {

                // Snap the door point to the mid point of the nearest edge of the polygon:

                const roomLineString = turf.lineString(this.location.geometry.coordinates[0]);
                // Make sure linestring start/end is never near the door point.
                const shiftedLocationLineString = shiftPolygonStartEndAwayFromDoorPoint(roomLineString, nearestPointOnLocationPolygon);
                // Remove redundant points.
                const cleanLocationLineString = turf.simplify(shiftedLocationLineString, { tolerance: 0.000001, highQuality: true, mutate: true });

                // Find the edge and its length nearest to the point
                const nearestPointOnCleanLocationLineString = turf.nearestPointOnLine(cleanLocationLineString, [mouseEvent.latLng.lng(), mouseEvent.latLng.lat()]);
                const edgeLineString = turf.lineString([cleanLocationLineString.geometry.coordinates[nearestPointOnCleanLocationLineString.properties.index], cleanLocationLineString.geometry.coordinates[nearestPointOnCleanLocationLineString.properties.index + 1]]);
                const edgeLength = turf.length(edgeLineString, { units: 'meters' });

                // Calculate and set snap point at the middle of the edge
                const midPoint = turf.along(edgeLineString, edgeLength / 2, { units: 'meters' });
                this.snapMarker.setPosition({ lat: midPoint.geometry.coordinates[1], lng: midPoint.geometry.coordinates[0] });
            }
        }
    }

    /**
     * Register door point and place marker on the map for it.
     *
     * @memberof RouteElementGeometryService
     */
    private selectDoorPointMouseClicked(): void {
        if (!this.snapMarker.getPosition()) {
            return; // There is nothing snapped
        }

        this.doorCenterPoint = turf.point([this.snapMarker.getPosition().lng(), this.snapMarker.getPosition().lat()]);
        this.doorPointSet.next(true);

        this.mapService.removeEventListener(this.mouseClickListener);
        this.mapService.removeEventListener(this.mouseMoveListener);

        this.snapMarker.setPosition(null);

        this.calculateDoorLineString();

        this.renderDoorLineStringOnMap();

        this.makeDoorDraggable();
    }

    /**
     * Calculate door linestring based on door point and door length.
     * The door linestring will follow the location polygon.
     *
     * @memberof RouteElementGeometryService
     */
    private calculateDoorLineString(): void {
        // Transform the location polygon to a linestring and split on the chosen door point.
        const locationLineString: Feature<LineString> = turf.lineString(this.location.geometry.coordinates[0]); // TODO: This limits the functionality to outer rings only.

        this.doorLineString = getSubLineStringFromPointOnLineString(locationLineString, this.doorCenterPoint, this.doorWidthInMeters());
    }

    /**
     * Get the door linestring width in meters.
     *
     * @memberof RouteElementGeometryService
     * @returns {number}
     */
    private doorWidthInMeters(): number {
        return this.userAgentUnitSystem === UnitSystem.Imperial ? this.doorWidth / 39.37 : this.doorWidth / 100;
    }

    /**
     * Render a door (polyline) on the map around the selected door point.
     *
     * @memberof RouteElementGeometryService
     */
    private renderDoorLineStringOnMap(): void {
        this.doorLineStringOnMap = this.mapService.drawPolyline(
            this.doorLineString.geometry.coordinates,
            {
                fillColor: '#ef6cce',
                strokeOpacity: 1,
                strokeWeight: 8,
                zIndex: 1000, // always above polygons
                draggable: true,
                cursor: 'crosshair'
            } as any // cast to any because cursor is not on the type, but it still works
        );
    }

    /**
     * Make the door draggable along the edges of the location polygon.
     *
     * @memberof RouteElementGeometryService
     */
    private makeDoorDraggable(): void {
        const locationLineString = turf.lineString(this.location.geometry.coordinates[0]);

        this.doorLineStringOnMap.addListener('drag', (mouseEvent: google.maps.MapMouseEvent) => {
            // The door polyline is freely draggable. Make sure to snap it to the location polygon.
            const point = [mouseEvent.latLng.lng(), mouseEvent.latLng.lat()];
            const closestPointOnLocationPolygonEdge = turf.nearestPointOnLine(locationLineString, point);

            this.doorCenterPoint = turf.point(closestPointOnLocationPolygonEdge.geometry.coordinates);

            this.calculateDoorLineString();
            this.updateDoorLineStringOnMap();
        });
    }

    /**
     * Update the geometry of the rendered door on the map.
     *
     * @memberof RouteElementGeometryService
     */
    private updateDoorLineStringOnMap(): void {
        this.doorLineStringOnMap.setPath(this.doorLineString.geometry.coordinates.map(position => ({ lat: position[1], lng: position[0] })));
    }

    /**
     * Will remove last added door point.
     *
     * @memberof RouteElementGeometryService
     */
    public undoLastDoorPoint(): void {
        this.doorPoints.pop();
        this.doorPointSet.next(this.doorPoints.length > 0);
        this.calculateDoorLineStrings();
        this.updateDoorLineStringsOnMap();
        this.updateGuidePoints();
    }

    /**
     * Set up listeners and marker so the user can click on a point on the location polygon.
     *
     * @memberof RouteElementGeometryService
     */
    private setDoorPoints(): void {
        // Marker that snaps to the location when selecting door point
        this.snapMarker = this.mapService.drawMarker(null, {
            ...this.snapMarkerIconOptions,
            url: '/assets/images/line-marker-blue.svg'
        });
        this.snapMarker.setClickable(false);

        this.doorFirstPointMarker = this.mapService.drawMarker(null, {
            ...this.snapMarkerIconOptions,
            url: '/assets/images/line-marker-blue.svg'
        });
        this.doorFirstPointMarker.setClickable(false);

        this.mouseMoveListener = this.mapService.addEventListener('mousemove', this.selectMultipleDoorsPointMouseMoved.bind(this));
        this.mouseClickListener = this.mapService.addEventListener('click', this.selectMultipleDoorsPointMouseClicked.bind(this));
    }

    /**
     * Place a marker on the edge of the location if mouse pointer is close enough.
     *
     * @param {google.maps.MapMouseEvent} mouseEvent
     * @memberof RouteElementGeometryService
     */
    private selectMultipleDoorsPointMouseMoved(mouseEvent: google.maps.MapMouseEvent): void {
        const nearestPointOnLocationPolygon = turf.nearestPointOnLine(turf.lineString(this.location.geometry.coordinates[0]), [mouseEvent.latLng.lng(), mouseEvent.latLng.lat()]);

        // If the mouse pointer is not close enough to the location polygon, remove the snap marker.
        if (nearestPointOnLocationPolygon.properties.dist >= this.snapToleranceMeters / 1000) {
            this.snapMarker.setPosition(null);
            return;
        }

        // Snap the door point to the nearest point on the polygon:
        this.snapMarker.setPosition({ lat: nearestPointOnLocationPolygon.geometry.coordinates[1], lng: nearestPointOnLocationPolygon.geometry.coordinates[0] });
    }

    /**
     * Register a door point (either start point or end point for a door linestring).
     *
     * @memberof RouteElementGeometryService
     */
    private selectMultipleDoorsPointMouseClicked(): void {
        if (!this.snapMarker.getPosition()) {
            return; // There is nothing snapped
        }

        const point = turf.point([this.snapMarker.getPosition().lng(), this.snapMarker.getPosition().lat()]);
        this.doorPoints.push(point); // Instead of points, we should calculate a proper linestring that follows the polygon

        this.doorPointSet.next(true);
        this.updateGuidePoints();
        this.calculateDoorLineStrings();
        this.updateDoorLineStringsOnMap();

        this.snapMarker.setPosition(null);
    }

    /**
     * Update snap marker and first door point.
     *
     * @memberof RouteElementGeometryService
     */
    private updateGuidePoints(): void {
        if (this.doorPoints.length % 2 === 1) {
            const lastDoorPoint = this.doorPoints[this.doorPoints.length - 1];
            this.doorFirstPointMarker.setPosition({ lat: lastDoorPoint.geometry.coordinates[1], lng: lastDoorPoint.geometry.coordinates[0] });
        } else {
            this.doorFirstPointMarker.setPosition(null);
        }

        this.snapMarker.setIcon({
            ...this.snapMarkerIconOptions,
            url: this.doorPoints.length % 2 === 0 ? '/assets/images/line-marker-blue.svg' : 'assets/images/line-marker-white.svg'
        });
    }

    /**
     * Calculate door line strings based on pairs of clicked points.
     *
     * @memberof RouteElementGeometryService
     */
    private calculateDoorLineStrings(): void {
        // Make door points into pairs
        const doorStartAndEnds = this.doorPoints.reduce((result, val, index, array) => {
            if (index % 2 === 0) result.push(array.slice(index, index + 2));
            return result;
        }, []);

        this.doorLineStrings = [];

        doorStartAndEnds.forEach((doorDefinition: Feature<Point>[]) => {
            if (doorDefinition.length !== 2) return;

            // Transform the location polygon to a linestring
            const locationLineString: Feature<LineString> = turf.lineString(this.location.geometry.coordinates[0]); // TODO: This limits the functionality to outer rings only.

            // Make sure linestring start/end is never near the door point.
            const shiftedLocationLineString = shiftPolygonStartEndAwayFromDoorPoint(locationLineString, doorDefinition[0]);
            const doorLineString = turf.lineSlice(doorDefinition[0].geometry.coordinates, doorDefinition[1].geometry.coordinates, shiftedLocationLineString);

            this.doorLineStrings.push(doorLineString);
        });
    }

    /**
     * Render doors (polylines) on the map based on the clicked door points.
     *
     * @memberof RouteElementGeometryService
     */
    private updateDoorLineStringsOnMap(): void {
        // Remove existing ones on map in order to redraw.
        this.doorLineStringsOnMap.forEach(doorLinestringOnMap => this.mapService.removeFromMap(doorLinestringOnMap));
        this.doorLineStringsOnMap = [];

        this.doorLineStrings.forEach(doorLineString => {
            this.doorLineStringsOnMap.push(this.mapService.drawPolyline(
                doorLineString.geometry.coordinates,
                {
                    fillColor: '#ef6cce',
                    strokeOpacity: 1,
                    strokeWeight: 8,
                    zIndex: 2,
                    draggable: true,
                    cursor: 'crosshair'
                } as any // cast to any because cursor is not on the type, but it still works
            ));
        });
    }

}
