<panel-header header="Media Library" (cancelEvent)="closeModal()"></panel-header>

<div class="media">
    <ngx-spinner name="media-spinner" [fullScreen]="false" type="ball-pulse"></ngx-spinner>

    <div class="media-navbar">
        <div class="media-navbar__upload">
            <media-upload [isUploadEnabled]="isUploadEnabled"></media-upload>
        </div>
        <div class="media-navbar__menu">
            <a
                class="documentation-link documentation-link--right"
                href="https://docs.mapsindoors.com/content/cms/media-library/"
                target="_blank">
                Help
            </a>
            <media-filter-bar [defaultFilter]="defaultFilter"></media-filter-bar>
        </div>
    </div>

    <div class="media__items">
        <media-items-list
            *ngIf="allMediaItems"
            [selectedItem]="usedItem"
            [defaultFilter]="defaultFilter"
            (chosenMediaItem)="setSelectedMedia($event)">
        </media-items-list>
    </div>
</div>

<panel-footer *ngIf="showFooter" class="footer">
    <div class="footer__buttons">
        <button
            type="button"
            class="mi-button mi-button--base"
            (click)="onDiscardChanges()">
            Cancel
        </button>
        <p class="warning" *ngIf="showWarningMessage">
            {{ warningMessage }}
            <input
                *ngIf="showWarningToAccept"
                class="warning__checkbox"
                (change)="onWarningAccepted($event)"
                id="checkWarning"
                type="checkbox">
        </p>
        <button
            type="button"
            class="mi-button mi-button--primary"
            (click)="onSelect()"
            [disabled]="disableSelect">
            Select
        </button>
    </div>
</panel-footer>