import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { CustomersComponent } from './customers.component';

@NgModule({
    declarations: [
        CustomersComponent],
    imports: [
        SharedModule
    ],
    providers: []
})
export class CustomersModule { }
