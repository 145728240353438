<div class="split-location">
    <header class="split-location__header">
        <h2 class="split-location__instruction" *ngIf="isSelectingNewLocation || isNewLocationSelected">Select new Room</h2>
        <button type="button" (click)="closeDialog()" class="split-location__close-button" [disabled]="isProcessing">&times;</button>
    </header>

    <div #map class="split-location__map"></div>

    <div class="split-location__controls">
        <button
            type="button"
            class="mi-button mi-button--base split-location__button split-location__button--undo"
            [disabled]="isProcessing"
            (click)="reset()">
            <img src="/assets/images/close.svg" />
            Undo
        </button>

        <button
            *ngIf="isNewLocationSelected"
            type="button"
            class="mi-button mi-button--primary split-location__button split-location__button--confirm"
            [disabled]="isProcessing"
            (click)="performSplit()">
            Done
        </button>
    </div>
</div>
