<div mat-dialog-title>
    What's new
    <div fxFlex></div>
    <mat-icon mat-dialog-close class="close-icon">close</mat-icon>
</div>
<mat-divider class="top-divider"></mat-divider>

<div mat-dialog-content fxLayout="column">
    <div>
        <h3>New CMS updates to ease your map management</h3>
        <a href="https://www.mapspeople.com/resources/product-news/cms-display-rules" target="_blank"><img
                 src="/images/news/faster_and_easier_map_management_with_display_rules.png" /></a>
        <p>
            A range of improvements to the MapsIndoors CMS is now available to make it faster and easier to make changes
            to your map.
            The changes include:
        </p>
        <ul>
            <li>New display rule editor that allows you to make changes by the inheritance principle across your entire
                solution and review the changes as you make them.</li>
            <li>Upload 2D images to your map and scale according to zoom level or fit to polygon.</li>
            <li>2D/3D button in the bottom work bar to turn on/off your 2D and 3D objects.</li>
        </ul>
        <p>
            <b>
                To read more about the new CMS updates, <a
                   href="https://www.mapspeople.com/resources/product-news/cms-display-rules"
                   target="_blank">click here!</a>.
            </b>
        </p>
    </div>
</div>