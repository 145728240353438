
import { BBox, Feature } from 'geojson';
import { MapViewModel, MapViewModelFactory } from '../../../viewmodels/MapViewModelFactory/MapViewModelFactory';
import { DisplayRule } from '../../locations/location.model';
import midt from '@mapsindoors/midt/tokens/tailwind-colors.json';
import { PointViewModel } from '../../../viewmodels/PointViewModel/PointViewModel';
import { Floor } from '../../buildings/floor.model';
import { MapsIndoorsData } from '../../shared/enums/MapsIndoorsData';
import { RouteNetworkViewModel } from '../../../viewmodels/RouteNetworkViewModel/RouteNetworkViewModel';
import { GeoJSONGeometryType } from '../../shared/enums';

export class RouteNetworkMapViewModelFactory extends MapViewModelFactory<Feature> {

    /**
     * Creates MapViewModels for the given RouteNetworkFeature.
     *
     * @param {Feature} feature
     * @param {number} sortKey
     * @returns {Feature[]}
     * @memberof RouteNetworkMapViewModelFactory
     */
    async create(feature: Feature, sortKey?: number): Promise<MapViewModel[]> {
        const viewModels: MapViewModel[] = [];
        const displayRule = await this.getDisplayRule(feature);
        const id = `${feature.id}_${feature.properties.floorIndex}`;
        if (feature?.geometry?.type === GeoJSONGeometryType.LineString && displayRule.visible) {
            viewModels.push(await RouteNetworkViewModel.create(id, feature.geometry, displayRule, sortKey, MapsIndoorsData.Unknown));
        }

        if (feature?.geometry?.type === GeoJSONGeometryType.Point && displayRule.visible) {
            displayRule.clickable = false;
            viewModels.push(await PointViewModel.create(id, feature.geometry, displayRule, sortKey, MapsIndoorsData.Unknown));
        }

        return Promise.resolve(viewModels);
    }

    /**
     * Returns true for all RouteNetworkFeature.
     *
     * @returns {boolean}
     * @memberof RouteNetworkMapViewModelFactory
     */
    intersectsWithBounds(feature: Feature, bounds: BBox): boolean {
        if (feature.geometry?.type === GeoJSONGeometryType.Point) {
            const [lng, lat] = feature.geometry.coordinates;
            return lng >= bounds[0] && lng <= bounds[2] && lat >= bounds[1] && lat <= bounds[3];
        } else if (feature.geometry?.type === GeoJSONGeometryType.LineString) {
            const [start, end] = feature.geometry.coordinates;
            return start[0] >= bounds[0] && start[0] <= bounds[2] && start[1] >= bounds[1] && start[1] <= bounds[3] ||
            end[0] >= bounds[0] && end[0] <= bounds[2] && end[1] >= bounds[1] && end[1] <= bounds[3];
        }
    }

    /**
     * Checks if the floorIndex for the given RouteNetworkFeature equals the given floorIndex.
     *
     * @param {GeoJSON.Feature} feature
     * @param {Floor} floor
     * @returns {boolean}
     * @memberof RouteNetworkMapViewModelFactory
     */
    floorEquals(feature: GeoJSON.Feature, floor: Floor): boolean {
        return feature?.properties?.floorIndex === floor?.floorIndex;
    }

    /**
     * Get a DisplayRule for the given RouteElement.
     *
     * @private
     * @returns {DisplayRule}
     * @memberof RouteNetworkMapViewModelFactory
     */
    private getDisplayRule(feature): DisplayRule {
        let strokeColor: string = midt['tailwind-colors'].blue[500].value;
        const strokeWidth: number = 3;
        let strokeOpacity: number = 0.8;
        const visible: boolean = true;

        if (feature.properties.unreachable) {
            strokeOpacity = 0.2;
        }

        if (feature.properties.modified) {
            strokeColor = midt['tailwind-colors'].yellow[500].value;
        }

        if (feature.properties.locked) {
            strokeColor = midt['tailwind-colors'].red[500].value;
            strokeOpacity = 0.95;
        }

        return {
            clickable: false,
            visible,
            polygon: {
                strokeWidth,
                strokeColor,
                strokeOpacity,
                zoomFrom: 0,
                zoomTo: Infinity
            },
            icon: 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/isolatednode.png',
            imageSize: {
                width: 10,
                height: 10
            },
            zoomFrom: 0,
            zoomTo: Infinity
        } as DisplayRule;
    }
}