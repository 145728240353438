import { AuthConfig } from 'angular-oauth2-oidc';
import { environment as env } from '../../environments/environment';

export const authConfig: AuthConfig = {
    issuer: env.authApi, // Url of the Identity Provider,
    clientId: env.clientId, // The "Auth Code + PKCE" client
    responseType: 'code',
    redirectUri: window.location.origin + '/index.html', // URL of the SPA to redirect the user to after login
    scope: 'openid profile account manager', // Ask offline_access to support refresh token refreshes
    useSilentRefresh: false, // Needed for Code Flow to suggest using iframe-based refreshes
    sessionChecksEnabled: false,
    showDebugInformation: !env.production, // Also requires enabling "Verbose" level in devtools
    clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
    nonceStateSeparator: 'semicolon', // Real semicolon gets mangled by IdentityServer's URI encoding
    customQueryParams: {}
};