import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { CustomEncoder } from '../shared/toolbox/custom-encoder';
import { TwoFactorSettings, User } from '../user.model';

@Injectable()
export class UserService {
    private userUrl = environment.authApi + '/api/account';
    private currentUser = new BehaviorSubject<User>(null);

    constructor(
        private http: HttpClient
    ) { }

    /**
     * Fetch the user from the token.
     * Emit the fetched user as the currently logged in user.
     *
     * @returns {Observable<User>}
     * @memberof UserService
     */
    public getUser(): Observable<User> {
        return this.http.get<User>(this.userUrl + '/details/')
            .pipe(tap(user => this.currentUser.next(user)));
    }

    public updateUser(user): Observable<any> {
        const requestOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.put(this.userUrl, user, { headers: requestOptions });
    }

    public newUser(user): Observable<any> {
        const requestOptions = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(this.userUrl + '/invitenewuser', user, { headers: requestOptions });
    }

    public deleteUser(user): Observable<any> {

        return this.http.delete(this.userUrl + '/' + user.id);
    }

    public getUsers(): Observable<any> {
        return this.http.get(this.userUrl);
    }

    /**
     * Reset password.
     *
     * @param {string} email
     * @returns {Observable<any>}
     * @memberof UserService
     */
    public resetPassword(email: string): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const params = new HttpParams({ encoder: new CustomEncoder() })
            .set('userEmail', email);
        return this.http.post(this.userUrl + '/resetpassword', null, { headers, params });
    }

    /**
     * Get the currently logged in user.
     *
     * @memberof UserService
     */
    public getCurrentUser(): User {
        return this.currentUser.value;
    }

    /**
     * Get current user as observable.
     *
     * @returns {Observable<User>}
     * @memberof UserService
     */
    public getCurrentUserObservable(): Observable<User> {
        return this.currentUser.asObservable().pipe(filter(user => !!user));
    }

    /**
     * Get user's two factor settings.
     *
     * @returns {Observable<TwoFactorSettings>}
     * @memberof UserService
     */
    public getTwoFactorSettings(): Observable<TwoFactorSettings> {
        return this.http.get<TwoFactorSettings>(`${this.userUrl}/twofactor/`);
    }

    /**
     * Enable two factor verification on user.
     *
     * @param {string} authenticatorCode - Code generated by an authenticator.
     * @returns {Observable<boolean>}
     * @memberof UserService
     */
    public enableTwoFactorVerification(authenticatorCode: string): Observable<boolean> {
        return this.http.put<boolean>(`${this.userUrl}/twofactor/verification`, null, { params: { code: authenticatorCode } });
    }

    /**
     * Disable two factor verification on user.
     *
     * @param {string} authenticatorCode - Code generated by an authenticator.
     * @returns {Observable<void>}
     * @memberof UserService
     */
    public disableTwoFactorVerification(authenticatorCode: string): Observable<void> {
        return this.http.delete<void>(`${this.userUrl}/twofactor`, { params: { code: authenticatorCode } });
    }


    /**
     * Check if the user has owner privileges.
     *
     * @returns {boolean}
     * @memberOf UserService
     */
    public hasOwnerPrivileges(): boolean {
        return this.currentUser.value?.roles?.includes('owner') || false;
    }

    /**
     * Check if the user has admin privileges.
     *
     * @returns {boolean}
     * @memberOf UserService
     */
    public hasAdminPrivileges(): boolean {
        return this.currentUser.value?.roles?.includes('admin') || this.hasOwnerPrivileges();
    }
}
