import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderBy', pure: true })
export class OrderBy implements PipeTransform {
    transform(obj: any, orderFields: string): any {
        // normally you would pass a string array to the pipe directly (line above), but since departmentname is always the
        // last param and the select only provides a string we build the array here
        const orderBy: [string] = [''];

        if (orderFields !== '') {
            orderBy.push(orderFields);
        }
        orderBy.forEach(function (currentField) {
            let orderType = 'ASC';

            if (currentField[0] === '-') {
                currentField = currentField.substring(1);
                orderType = 'DESC';
            }

            obj.sort(function (a, b) {
                if (a[currentField] !== undefined && b[currentField] !== undefined) {
                    if (orderType === 'ASC') {
                        if (a[currentField].toLowerCase() < b[currentField].toLowerCase()) return -1;
                        if (a[currentField].toLowerCase() > b[currentField].toLowerCase()) return 1;
                        return 0;
                    } else {
                        if (a[currentField].toLowerCase() < b[currentField].toLowerCase()) return 1;
                        if (a[currentField].toLowerCase() > b[currentField].toLowerCase()) return -1;
                        return 0;
                    }
                } else {
                    return 0;
                }
            });

        });
        return obj;
    }
}

@Pipe({ name: 'orderNumBy' })
export class NumericalSortPipe implements PipeTransform {
    transform(array: Array<any>, orderField: string, orderType: boolean) {
        array.sort((a: any, b: any) => {
            let ae = a[orderField];
            let be = b[orderField];
            if (ae === undefined && be === undefined) return 0;
            if (ae === undefined && be !== undefined) return orderType ? 1 : -1;
            if (ae !== undefined && be === undefined) return orderType ? -1 : 1;
            if (ae === be) return 0;
            ae = parseFloat(ae);
            be = parseFloat(be);
            return orderType ? (ae > be ? -1 : 1) : (be > ae ? -1 : 1);
        });
        return array;
    }
}
