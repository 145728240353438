import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';

@Component({
    selector: 'app-tiles-manager',
    templateUrl: './tiles-manager.component.html',
    styleUrls: ['./tiles-manager.component.scss']
})
export class TilesManagerComponent implements OnInit {
    public maxLength = 36;
    public readonly tilesUrlStart = 'https://tiles.mapsindoors.com/tiles/indoor/';
    public readonly tilesUrlEnd = '/{style}/l{floor}/z{z}/x{x}/y{y}.png';
    public tilesForm = this.formBuilder.group({
        urlPath: ['', Validators.required],
        styles: this.formBuilder.array([])
    });
    /* Gets urlPath's Form Control */
    get urlPath(): FormControl {
        return this.tilesForm.get('urlPath') as FormControl;
    }
    /* Gets Styles Form Array */
    get styles(): FormArray {
        return this.tilesForm.get('styles') as FormArray;
    }

    constructor(
        public dialogRef: MatDialogRef<TilesManagerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
    ) { }

    ngOnInit(): void {
        if (this.data.venue) {
            // populate the urlPath form control
            this.urlPath.setValue(this.data.venue.tilesUrl
                .split(this.tilesUrlStart)
                .pop()
                .split(this.tilesUrlEnd)
                .shift()
            );
            // populate the styles form array
            this.data.venue.styles.forEach(style => this.addStyle(style.displayName, style.folder));
        }
    }

    /**
     * Adds a new form group to the form array with the given style controls.
     *
     * @param {string} [displayName='']
     * @param {string} [folder='']
     * @memberof TilesManagerComponent
     */
    public addStyle(displayName = '', folder = ''): void {
        this.styles.push(
            this.formBuilder.group({
                displayName: [displayName, Validators.required],
                folder: [folder, Validators.required]
            })
        );
    }

    /**
     * Handles the tilesForm submit event.
     * If the form is valid, set the venue's tilesUrl and styles.
     *
     * @memberof TilesManagerComponent
     */
    public onSubmit(): void {
        if (this.tilesForm.valid) {
            this.data.venue.tilesUrl = `${this.tilesUrlStart}${this.urlPath.value}${this.tilesUrlEnd}`;
            this.data.venue.styles = this.styles.controls.map(style => {
                return {
                    displayName: style.get('displayName').value,
                    folder: style.get('folder').value
                };
            });

            this.dialogRef.close(this.data.venue);
        }
    }
}
