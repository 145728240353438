export enum FeatureClass {
    EXTRUSION,
    HIGHLIGHT,
    LABEL,
    LINESTRING,
    NETWORK,
    MODEL2D,
    MODEL3D,
    POINT,
    POLYGON,
    WALL,
}
