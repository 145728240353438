import { Component, Input } from '@angular/core';

@Component({
    selector: 'panel-footer',
    templateUrl: './panel-footer.component.html',
    styleUrls: ['./panel-footer.component.scss']
})
export class PanelFooterComponent {
    @Input() text = '';
}


