import { BBox } from 'geojson';
import { RouteElement } from '../../map/route-element-details/route-element.model';
import { bboxPolygon, feature } from '@turf/turf';
import * as turf from '@turf/turf';

import { Floor } from '../../buildings/floor.model';
import { MapViewModel, MapViewModelFactory } from '../../../viewmodels/MapViewModelFactory/MapViewModelFactory';
import { PointViewModel } from '../../../viewmodels/PointViewModel/PointViewModel';
import { PolygonViewModel } from '../../../viewmodels/PolygonViewModel/PolygonViewModel';
import booleanIntersects from '@turf/boolean-intersects';
import { MapsIndoorsData } from '../../shared/enums/MapsIndoorsData';
import { DisplayRuleService } from '../../services/DisplayRuleService/DisplayRuleService';
import { GeoJSONGeometryType } from '../../shared/enums';

export class RouteElementMapViewModelFactory extends MapViewModelFactory<RouteElement> {
    constructor(private displayRuleService: DisplayRuleService) {
        super();
    }

    /**
     * Creates MapViewModels for the given RouteElement.
     *
     * @param {Location} routeElement
     * @param {number} zIndex
     * @returns {Promise<MapViewModel[]>}
     * @memberof RouteElementMapViewModelFactory
     */
    async create(routeElement: RouteElement, zIndex?: number): Promise<MapViewModel[]> {
        const viewModels: MapViewModel[] = [];
        const displayRule = await this.displayRuleService.getRouteElementDisplayRule(routeElement);

        if (!this.isVisible(displayRule))
            return viewModels;

        if (routeElement?.geometry?.type === GeoJSONGeometryType.LineString) {
            const doorLineString = turf.lineString(routeElement.geometry.coordinates);
            const currentLineStringLength = turf.length(doorLineString);
            const lineStringCenterPoint = turf.along(doorLineString, currentLineStringLength / 2);

            viewModels.push(await PointViewModel.create(routeElement.id, lineStringCenterPoint.geometry, displayRule, zIndex, MapsIndoorsData.RouteElement));
        }

        if (routeElement?.geometry?.type === GeoJSONGeometryType.Polygon) {
            viewModels.push(await PolygonViewModel.create(routeElement.id, routeElement.geometry, { ...displayRule, clickable: false }, zIndex, MapsIndoorsData.RouteElement));
        }

        if (routeElement?.geometry?.type === GeoJSONGeometryType.Point) {
            viewModels.push(await PointViewModel.create(routeElement.id, routeElement.geometry, displayRule, zIndex, MapsIndoorsData.RouteElement));
        }

        return viewModels;
    }

    /**
     * Checks if the given RouteElement intersects with the given bounds.
     *
     * @param {RouteElement} routeElement
     * @param {BBox} bounds
     * @returns {boolean}
     * @memberof RouteElementMapViewModelFactory
     */
    intersectsWithBounds(routeElement: RouteElement, bounds: BBox): boolean {
        if (routeElement.geometry?.type === GeoJSONGeometryType.Point) {
            const [lng, lat] = routeElement.geometry.coordinates;
            return lng >= bounds[0] && lng <= bounds[2] && lat >= bounds[1] && lat <= bounds[3];
        } else if (routeElement.geometry?.type === GeoJSONGeometryType.LineString) {
            const [start, end] = routeElement.geometry.coordinates;
            return start[0] >= bounds[0] && start[0] <= bounds[2] && start[1] >= bounds[1] && start[1] <= bounds[3] ||
                end[0] >= bounds[0] && end[0] <= bounds[2] && end[1] >= bounds[1] && end[1] <= bounds[3];
        } else if (routeElement.geometry?.type === GeoJSONGeometryType.Polygon) {
            const boundsAsPolygon = bboxPolygon(bounds);
            return booleanIntersects(boundsAsPolygon, feature(routeElement.geometry));
        }
    }

    /**
     * Checks if the floorIndex for the given RouteElement equals the given floorIndex.
     *
     * @param {RouteElement} routeElement
     * @param {floor} floor
     * @returns {boolean}
     * @memberof RouteElementMapViewModelFactory
     */
    floorEquals(routeElement: RouteElement, floor: Floor): boolean {
        return routeElement?.floorIndex === floor?.floorIndex;
    }
}