import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { DataIssueResolver } from './data-issue-resolver.service';
import { DataIssueComponent } from './data-issue.component';

@NgModule({
    declarations: [DataIssueComponent],
    imports: [
        SharedModule
    ],
    providers: [
        DataIssueResolver
    ]
})
export class DataIssueModule { }
