import { MultiPolygon, Polygon } from 'geojson';

import { ViewModelProperties } from '../ViewModelProperties/ViewModelProperties';
import { DisplayRule } from '../../app/locations/location.model';
import { SortKey } from '../MapViewModelFactory/MapViewModelFactory';
import { MapViewModel } from '../MapViewModelFactory/MapViewModelFactory';
import { FeatureClass } from '../FeatureClass';
import { MapsIndoorsData } from '../../app/shared/enums/MapsIndoorsData';

abstract class ExtrusionViewModelProperties implements ViewModelProperties {
    featureClass: FeatureClass;
    originalId: string;
    originalType: MapsIndoorsData;
    sortKey: number;
    height: number;
    color: string;
    zoomRange: [min: number, max: number];

    /**
     * Factory for creating a ExtrusionViewModelProperties object.
     *
     * @static
     * @param {string} id
     * @param {number} sortKey
     * @param {DisplayRule} displayRule
     * @returns {ExtrusionViewModelProperties}
     * @memberof ExtrusionViewModelProperties
     */
    static async create(id: string, sortKey: number, displayRule: DisplayRule, originalType: MapsIndoorsData): Promise<ExtrusionViewModelProperties> {
        return await Promise.resolve({
            originalId: id,
            originalType,
            sortKey: SortKey.EXTRUSION + sortKey,
            featureClass: FeatureClass.EXTRUSION,
            height: displayRule?.extrusion.height,
            color: displayRule?.extrusion.color,
            zoomRange: [displayRule?.extrusion.zoomFrom, displayRule?.extrusion.zoomTo]
        });
    }
}


export class ExtrusionViewModel implements MapViewModel {
    readonly id: string;
    readonly type = 'Feature';
    readonly geometry: Polygon | MultiPolygon;
    readonly properties: ExtrusionViewModelProperties;

    private constructor(id: string, geometry: Polygon | MultiPolygon, properties: ExtrusionViewModelProperties) {
        this.id = `EXTRUSION:${id}`;
        this.geometry = geometry;
        this.properties = properties;
    }

    /**
     * Factory to create a ExtrusionViewModel from an id, a geometry, and a DisplayRule.
     *
     * @static
     * @param {string} id
     * @param {Polygon | MultiPolygon} geometry
     * @param {DisplayRule} displayRule
     * @param {number} index
     * @returns {ExtrusionViewModel}
     * @memberof ExtrusionViewModel
     */
    static async create(id: string, geometry: Polygon | MultiPolygon, displayRule: DisplayRule, index: number, originalType: MapsIndoorsData): Promise<ExtrusionViewModel> {
        const properties = await ExtrusionViewModelProperties.create(id, index, displayRule, originalType);
        const viewModel = new ExtrusionViewModel(id, geometry as (Polygon | MultiPolygon), properties);

        return viewModel;
    }
}