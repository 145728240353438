import { BBox } from 'geojson';

import booleanIntersects from '@turf/boolean-intersects';
import { bboxPolygon, feature as toFeature } from '@turf/turf';

import { MapViewModel, MapViewModelFactory } from '../../../viewmodels/MapViewModelFactory/MapViewModelFactory';
import { Floor } from '../floor.model';
import { DisplayRuleService } from '../../services/DisplayRuleService/DisplayRuleService';
import { MapsIndoorsData } from '../../shared/enums/MapsIndoorsData';
import { LineStringViewModel } from '../../../viewmodels/LineStringViewModel/LineStringViewModel';
import { toMultiLineString } from '../../shared/geometry-helper/geojson';

export class FloorOutlineMapViewModelFacory extends MapViewModelFactory<Floor> {

    constructor(private displayRuleService: DisplayRuleService) {
        super();
    }

    /**
     * Creates MapViewModels for the given Floor.
     *
     * @param {Floor} floor
     * @param {number} sortKey
     * @returns {Promise<MapViewModel[]>}
     * @memberof FloorOutlineMapViewModelFacory
     */
    async create(floor: Floor, sortKey?: number): Promise<MapViewModel[]> {
        const viewModels: MapViewModel[] = [];
        const displayRule = await this.displayRuleService.getDisplayRule();

        const multiLineString = toMultiLineString(floor?.geometry);

        // LineString geometry exists and the DisplayRule's visibility is set to true.
        if (floor?.id && multiLineString && displayRule.visible) {
            displayRule.clickable = false;
            displayRule.polygon.strokeColor = '#EF6CCE';
            displayRule.polygon.strokeWidth = 5;
            displayRule.polygon.zoomFrom = 15;
            displayRule.polygon.zoomTo = 22;

            viewModels.push(await LineStringViewModel.create(floor.id, multiLineString, displayRule, sortKey, MapsIndoorsData.Floor));
        }

        return Promise.resolve(viewModels);
    }

    /**
     * Checks if the given location intersects with the given bounds.
     *
     * @param {Floor} floor
     * @param {BBox} bounds
     * @returns {boolean}
     * @memberof LocationMapViewModelFactory
     */
    intersectsWithBounds(floor: Floor, bounds: BBox): boolean {
        const boundsAsPolygon = bboxPolygon(bounds);
        return booleanIntersects(boundsAsPolygon, toFeature(floor.geometry));
    }

    /**
     * Checks if the floorIndex for the given location equals the given floorIndex.
     *
     * @param {Floor} data
     * @param {Floor} floor
     * @returns {boolean}
     * @memberof FloorOutlineMapViewModelFacory
     */
    floorEquals(data: Floor, floor: Floor): boolean {
        return data === floor;
    }

}