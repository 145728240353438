const { version } = require('../../package.json');

export const environment = {
    production: false,
    name: 'kindergarten',
    clientId: 'cms',
    googleAnalytics: '',
    version: version,
    startPage: '/map',
    AnalyticsEndpoint: 'https://analytics.kg.mapsindoors.com',
    APIEndpoint: 'https://v2.kg.mapsindoors.com/',
    MediaEndpoint: 'https://media.kg.mapsindoors.com/',
    authApi: 'https://auth.kg.mapsindoors.com',
    clientApi: 'https://api.dev.mapsindoors.com/',
    iconsBaseUrl: 'https://app.mapsindoors.com/mapsindoors/cms/assets/icons/'
};
