import { LineString, MultiLineString } from 'geojson';

import { DisplayRule } from '../../app/locations/location.model';
import { SortKey } from '../MapViewModelFactory/MapViewModelFactory';
import { FeatureClass } from '../FeatureClass';
import { MapsIndoorsData } from '../../app/shared/enums/MapsIndoorsData';
import { LineStringViewModel, LineStringViewModelProperties } from '../LineStringViewModel/LineStringViewModel';

interface RouteNetworkViewModelProperties extends LineStringViewModelProperties { }

export class RouteNetworkViewModel extends LineStringViewModel {
    override readonly id: string;
    protected constructor(id: string, geometry: LineString | MultiLineString, properties: RouteNetworkViewModelProperties) {
        super(id, geometry, properties);
        this.id = `NETWORK:${id}`;
    }

    /**
     * Factory function to create a RouteNetworkViewModel from a Route Network and DisplayRule.
     *
     * @static
     * @param {string} id
     * @param {GeoJSON.LineString | GeoJSON.MultiLineString} geometry
     * @param {DisplayRule} displayRule
     * @returns {RouteNetworkViewModel}
     * @memberof RouteNetworkViewModel
     */
    static create(id: string, geometry: GeoJSON.LineString | GeoJSON.MultiLineString, displayRule: DisplayRule, sortKey: number, originalType: MapsIndoorsData): RouteNetworkViewModel {
        const properties: RouteNetworkViewModelProperties = {
            originalId: id,
            originalType,
            clickable: displayRule.clickable,
            strokeColor: displayRule.polygon?.strokeColor,
            strokeOpacity: displayRule.polygon?.strokeOpacity,
            strokeWidth: displayRule.polygon?.strokeWidth,
            featureClass: FeatureClass.NETWORK,
            sortKey: SortKey.NETWORK + sortKey,
            zoomRange: [displayRule?.polygon.zoomFrom, displayRule?.polygon.zoomTo]
        };

        return new RouteNetworkViewModel(id, geometry, properties);
    }
}