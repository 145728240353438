import * as turf from '@turf/turf';

import { Feature, Geometry, Polygon } from 'geojson';
import { Location, Geometry as LocationGeometry } from '../../locations/location.model';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ExtendedLocation } from '../../locations/location.service';
import { Injectable } from '@angular/core';
import { LocationService } from '../../locations/location.service';
import { PolygonCombine } from '../../shared/polygon-split-validation/PolygonCombine'; // TODO: Move
import { primitiveClone } from '../../shared/object-helper';

@Injectable({
    providedIn: 'root'
})
export class CombineService {

    constructor(
        private locationService: LocationService
    ) { }

    /**
     * Get all adjacent room geometries for the given location.
     *
     * @param {ExtendedLocation} selectedLocation
     * @param {ExtendedLocation[]} locations
     * @param {Floor} floor
     * @returns {ExtendedLocation[]}
     * @memberof CombineService
     */
    public getAdjacentRooms(selectedLocation: ExtendedLocation, locations: ExtendedLocation[]): ExtendedLocation[] {
        const selectedPolygon = turf.polygon(selectedLocation.geometry.coordinates);
        const bufferedPolygon = turf.buffer(selectedPolygon, 1, { units: 'meters' });

        return locations.filter(location => {
            if (location.geometry.type === 'Polygon') {
                const locationPolygon = turf.polygon(location.geometry.coordinates);
                return location.id !== selectedLocation.id &&
                    location.type.toLowerCase() !== 'corridor' &&
                    location.locationType.toLowerCase() === 'room' &&
                    location.floor.id === selectedLocation.floor.id &&
                    turf.intersect(locationPolygon, bufferedPolygon) &&
                    PolygonCombine.validate({ polygon0: selectedPolygon, polygon1: locationPolygon }).isValid;
            }
            return false;
        });
    }


    /**
     * Combines the two polygons.
     *
     * @param {(Feature<Polygon> | Polygon)} polygon0
     * @param {(Feature<Polygon> | Polygon)} polygon1
     * @returns {Feature<Polygon>}
     * @memberof CombineService
     */
    public combine(polygon0: Feature<Polygon> | Polygon, polygon1: Feature<Polygon> | Polygon): Feature<Polygon> {
        return PolygonCombine.combine({ polygon0, polygon1 });
    }

    /**
     * Save a combination of two locations by deleting one location and updating
     * the geometry on the one to keep.
     *
     * @param {ExtendedLocation} locationToKeep
     * @param {ExtendedLocation} locationToDelete
     * @param {Geometry} geometry
     * @returns {Observable<ExtendedLocation>}
     * @memberof CombineService
     */
    public saveCombination(locationToKeep: ExtendedLocation, locationToDelete: ExtendedLocation, geometry: Geometry): Observable<ExtendedLocation | Location> {
        // delete the location that was selected last
        return this.locationService.deleteLocation(locationToDelete)
            .pipe(
                catchError(error => {
                    return throwError(error);
                }),
                mergeMap(() => this.updateAndSaveSelectedLocation(locationToKeep, geometry)),
                catchError(() => {
                    // Updating the location failed. Try to recreate the already deleted location
                    return this.locationService.createLocation(locationToDelete);
                })
            );
    }

    /**
     * Save a change in location geometry.
     *
     * @param {ExtendedLocation} location
     * @param {Geometry} newGeometry
     * @returns {Observable<void>}
     * @memberof CombineService
     */
    private updateAndSaveSelectedLocation(location: ExtendedLocation, newGeometry: Geometry): Observable<ExtendedLocation> {
        newGeometry.bbox = turf.bbox(newGeometry);
        location.geometry = newGeometry as LocationGeometry;

        // Remove this property in order to make the backend recalculate it
        const clonedLocation = primitiveClone(location);
        delete clonedLocation.anchor;

        return this.locationService.updateLocation(clonedLocation)
            .pipe(
                map(() => {
                    return location;
                })
            );
    }
}
