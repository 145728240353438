/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as turf from '@turf/turf';

/**
 * Ensures that the first and last positions are identical.
 *
 * @param {Feature<Polygon>|Polygon} polygon
 * @returns {Feature<Polygon>|Polygon}
 */
export function closePolygon(polygon) {
    const coordinates = turf.getGeom(polygon).coordinates;
    const firstPosition = coordinates[0][0];
    const lastPosition = coordinates[0][coordinates[0].length - 1];
    if (!turf.booleanEqual(turf.point(firstPosition), turf.point(lastPosition))) {
        coordinates[0].push([...firstPosition]);
    }

    return polygon;
}