import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MapSidebar, MapSidebarRef } from './map-sidebar.interface';

@Injectable()
export class MapSidebarService {
    private subject: Subject<{ component: ComponentType<MapSidebar>, stack: boolean, componentRef: MapSidebarRef<any> }> = new Subject();

    /**
     * Notifies the observer to create a new instance of the given component and returns a MapSidebarRef object.
     *
     * @template T
     * @param {ComponentType<T>} component
     * @param {boolean} stack
     * @returns {MapSidebarRef<T>}
     */
    public open<T extends MapSidebar>(component: ComponentType<T>, stack: boolean = false): MapSidebarRef<T> {
        const componentRef = new MapSidebarRef<T>();
        this.subject.next({ component, stack, componentRef });
        return componentRef;
    }

    /**
     * Returns an observable for observing "open" requests.
     *
     * @template T
     * @returns {Observable<{component: ComponentType<MapSidebar>}>}
     */
    public asObservable<T extends MapSidebar>(): Observable<{ component: ComponentType<MapSidebar>, stack: boolean, componentRef: MapSidebarRef<T> }> {
        return this.subject.asObservable();
    }
}