import { Component, OnInit, OnDestroy } 			from '@angular/core';

import { ConfigService } from '../app-settings/config/config.service';
import { SolutionService } from '../services/solution.service';
import { NotificationService } from '../services/notification.service';
import { finalize } from 'rxjs/operators';



@Component({
    selector: 'app-deployment-config',
    templateUrl: './deployment-config.component.html'
})
export class DeploymentConfigComponent implements OnInit, OnDestroy {
    selectedConfig: string = 'Android configuration';
    pageLoad: boolean = false;
    appConfig: any;

    appConfigCategories: any = [];
    displayCategories: any = [];

    solutionSubscription: any;

    currentSolution: any;

    appConfigStringified: any;

    constructor (
        private configService: ConfigService,
        private solutionService: SolutionService,
        private notificationService: NotificationService,
    ) {
        this.solutionSubscription = this.solutionService.getCurrentSolution().subscribe(solution => {
            this.currentSolution = solution;
            this.getConfig();
        });
    }

    ngOnInit(): void {
        this.pageLoad = true;
    }

    ngOnDestroy(): void {
        this.solutionSubscription.unsubscribe();
    }

    getConfig(): void {
        this.appConfigCategories = [];
        this.displayCategories = [];
        this.configService.getConfig()
            .pipe(finalize(() => this.pageLoad = false))
            .subscribe(config => {
                this.appConfig = config;
                this.appConfigStringified = JSON.stringify(this.appConfig, undefined, 4);
            }, error => {
                error === 'Appconfig not found' ? this.createConfig() : this.notificationService.showError(error);
            });
    }

    createConfig(): void {
        const solution = this.solutionService.getStaticSolution();
        const newConfig = {
            solutionId: solution.id,
            primaryURL: 'https://api.mapsindoors.com',
            secondaryURL: 'https://api-us.mapsindoors.com',
            appSettings: {

            },
            menuInfo: {

            },
            translations: {

            },
            venueImages: {

            }

        };
        this.configService.createConfig(newConfig)
            .subscribe(
                () => {
                    this.getConfig();
                }, error => {
                    this.notificationService.showError(error);
                }
            );
    }

    saveStringifiedConfig(): void {
        this.appConfig = JSON.parse(this.appConfigStringified);
        this.configService.updateConfig(this.appConfig)
            .subscribe(
                () => {
                    this.notificationService.showSuccess('Saved app configuration');
                    this.getConfig();
                }, error => {
                    this.notificationService.showError(error);
                }
            );
    }
}
