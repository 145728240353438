import { Component, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';

import { Solution } from '../../solutions/solution.model';
import { SolutionService } from '../../services/solution.service';
import { Translation } from '../location.model';

@Component({
    selector: 'location-basic-info',
    templateUrl: './location-basic-info.component.html',
    styleUrls: ['./location-basic-info.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => LocationBasicInfoComponent),
        multi: true,
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => LocationBasicInfoComponent),
        multi: true,
    }]
})
export class LocationBasicInfoComponent implements ControlValueAccessor, Validator, OnInit {
    public selectedSolution: Solution;
    public translations: Translation[] = [];
    public languages: Set<string>;

    public onChange: (value: Translation[] | null) => void = () => { };
    public onTouch: () => void = () => { };
    public onValidatorChange: () => void;
    public isDisabled: boolean = false;

    public translationsFormGroup: FormGroup = new FormGroup({ 'translations': new FormArray([]) });
    public translationsFormArray: FormArray = new FormArray([]);

    constructor(
        private formBuilder: FormBuilder,
        private solutionService: SolutionService
    ) {
        this.solutionService.selectedSolution$.subscribe(solution => this.selectedSolution = solution);

    }

    /**
     * Angluar lifecycle hook.
     */
    ngOnInit(): void {
        this.translationsFormGroup.valueChanges.subscribe((formValues) => {
            const translations = this.translations.map((translation, index) => ({ ...translation, ...formValues.translations[index] }));
            this.onChange(translations);
            this.onValidatorChange();
        });
    }

    /**
     * Create translation's form-group.
     *
     * @private
     * @param {Translation[]} translations
     * @returns {FormGroup}
     * @memberof LocationBasicInfoComponent
     */
    private createTranslationsFormArray(translations: Translation[]): FormArray {
        return translations.reduce((formArray, translation) => {
            const required = translation.language === this.selectedSolution.defaultLanguage ? Validators.required : null;
            const group = this.formBuilder.group({
                name: [translation.name, required],
                description: [translation.description || '']
            });
            formArray.push(group);
            return formArray;
        }, new FormArray([]));
    }

    /**
     * Writes a new value to the element.
     *
     * This method is called by the forms API to write to the view when programmatic
     * changes from model to view are requested.
     *
     * @param {Translation[]} translations - The new value for the element.
     */
    writeValue(translations: Translation[]): void {
        if (Array.isArray(translations)) {
            this.languages = new Set(translations.map(translation => translation.language));
            this.languages.delete('generic');
            this.translationsFormArray = this.createTranslationsFormArray(translations);
            this.translationsFormGroup.setControl('translations', this.translationsFormArray, { emitEvent: false });
            this.translations = translations;
        }
    }

    /**
     * Registers a callback function that is called when the control's value
     * changes in the UI.
     *
     * This method is called by the forms API on initialization to update the form
     * model when values propagate from the view to the model.
     *
     * @param {Function} fn - The callback function to register.
     */
    registerOnChange(fn: (value: Translation[] | null) => void): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that is called by the forms API on initialization
     * to update the form model on blur.
     *
     * @param {Function} fn - The callback function to register.
     */
    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }

    /**
     * Function that is called by the forms API when the control status changes to
     * or from 'DISABLED'. Depending on the status, it enables or disables the
     * appropriate DOM element.
     *
     * @param {boolean} isDisabled - The disabled status to set on the element.
     */
    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
    /**
     * Method that performs synchronous validation against the provided control.
     *
     * @returns {ValidationErrors}
     */
    validate(): ValidationErrors {
        return this.translationsFormGroup.invalid ? { invalid: true } : null;
    }

    /**
     * Registers a callback function to call when the validator inputs change.
     *
     * @param {Function} fn - The callback function to register.
     */
    registerOnValidatorChange?(fn: () => void): void {
        this.onValidatorChange = fn;
    }
}