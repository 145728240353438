import { Component } from '@angular/core';

@Component({
    selector: 'locations-list',
    templateUrl: './locations-list.component.html',
    styleUrls: ['./locations-list.component.scss']
})
export class LocationsListComponent {

    constructor() { }

}

