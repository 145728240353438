import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'datePipe'
})
export class FormatDatePipe implements PipeTransform {

    /**
     * Transform the given date based on the given parameters.
     *
     * @param {string} date
     * @returns {string}
     */
    transform(date: string): string {
        const options: any = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        };

        date = new Date(date).toLocaleString(navigator.language, options);
        return date;
    }
}