import { EventEmitter } from '@angular/core';

export interface MapSidebar {
    closed: EventEmitter<void>;
    close(): boolean;
}

export class MapSidebarRef<T> {
    #componentInstance: T;

    /**
     * Get the instance of the component that is shown in the sidebar.
     *
     * @public
     * @template T
     * @type {T}
     */
    public get componentInstance(): T {
        return this.#componentInstance;
    }

    /**
     * Set the instance of the component that is shown in the sidebar.
     * The instance can only be set once.
     */
    public set componentInstance(componentInstance: T) {
        if (!this.#componentInstance) {
            this.#componentInstance = componentInstance;
        }
    }
}