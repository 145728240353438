<mat-toolbar *ngIf="appConfigStringified" class="mat-elevation-z2 page-toolbar">
	<div fxFlex>
		<button color="primary" (click)="saveStringifiedConfig()" style="margin-right: 0px;" mat-raised-button>Save config JSON</button>
	</div>
	<div fxFlex></div>
</mat-toolbar>
<div class="page-content" [hidden]="!appConfigStringified">
	<div class="section" *ngIf="appConfigStringified">
		<div fxLayout="row">
			<div class="column" fxFlex>
				<h2>Edit App Config directly</h2>
				<p>Make sure you know what you are doing.</p>
				<br>
				<div style="background-color: #fff; box-shadow: inset 0px 2px 5px rgba(0,0,0,.4); padding: 16px; padding-bottom: 0px;">
					<mat-form-field style="width: 100%;">
						<textarea rows="30" [(ngModel)]="appConfigStringified" matInput placeholder="Edit app config directly"></textarea>
					</mat-form-field>
				</div>
			</div>
			<div class="column" fxFlex>
			</div>
		</div>
	</div>
</div>