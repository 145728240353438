import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { OAuthService, UserInfo } from 'angular-oauth2-oidc';
import { BehaviorSubject, defer, from, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, distinctUntilChanged } from 'rxjs/operators';
import { authConfig } from './auth-config';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public static readonly USER_IMAGE_KEY = 'userImage';

    private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
    public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

    private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
    public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable().pipe(distinctUntilChanged());

    /** Used this in the canLoad guard because the guard does not have access to the RouterStateSnapshot. */
    public attemptedUrl: string;

    constructor(private router: Router, private oauthService: OAuthService) {
        // Useful for debugging:
        /* this.oauthService.events.subscribe(event => {
            if (event instanceof OAuthErrorEvent) {
                console.error(event);
            } else {
                console.warn(event);
            }
        }); */

        this.oauthService.events
            .pipe(catchError(error => throwError(error)))
            .subscribe(() => this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken()));
    }

    /**
     * Gets the user profile from the received token.
     *
     * @returns {Observable<UserInfo>}
     * @memberof AuthService
     */
    public getUserProfile(): Observable<any> {
        return defer(() => from(this.oauthService.loadUserProfile()));
    }


    /**
     * Get user's identity claim.
     *
     * @returns {*} {*}.
     * @memberof AuthService
     */
    public getIdentityClaims(): any {
        return this.oauthService.getIdentityClaims();
    }

    /**
     * Log out.
     *
     * @memberof LoginService
     */
    public logout(): void {
        this.oauthService.logOut();
    }

    /**
     * Manual login using code flow.
     *
     * @param {string} [targetUrl] - Optional url to redirect to after login.
     * @memberof AuthService
     */
    public login(targetUrl?: string): void {
        this.oauthService.initCodeFlow(targetUrl || this.router.url);
    }

    /**
     * Loads the discovery document and try to log in.
     * Check if the received token is valid.
     *
     * @returns {Promise<void>}
     * @memberof AuthService
     */
    public runInitialLoginSequence(): Promise<void> {
        this.setCustomQueryParams();

        return this.oauthService.loadDiscoveryDocumentAndLogin()
            .then(() => {
                if (this.oauthService.hasValidAccessToken()) {
                    return Promise.resolve();
                }
                return Promise.reject(null);
            })
            .then(() => this.isDoneLoadingSubject$.next(true))
            .catch(() => this.isDoneLoadingSubject$.next(true));
    }

    /**
     * Checks if organizationName is present as a query parameter,
     * then set it in the customQueryParams of AuthConfig.
     *
     * @private
     * @memberof AuthService
     */
    private setCustomQueryParams(): void {
        const queryString = window.location.search;
        if (queryString) {
            const urlParams = new URLSearchParams(queryString);
            const organizationName = urlParams.get('organizationName');
            if (organizationName) {
                authConfig.customQueryParams['organizationName'] = organizationName;
            }
        }
    }
}
