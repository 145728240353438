import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[lockedDisplayRuleHandler]'
})
export class LockedDisplayRuleDirective {
    /**
     * Set or remove disabled class and title.
     *
     * @memberof LockedDisplayRuleDirective
     */
    @Input() set isLocked(disabled: boolean) {
        if (disabled) {
            this.hostElement.nativeElement.classList.add('setting--disabled');
            this.hostElement.nativeElement.setAttribute('title', 'Click to unlock setting and stop inheriting');
        } else {
            this.hostElement.nativeElement.classList.remove('setting--disabled');
            this.hostElement.nativeElement.removeAttribute('title');
        }
    }

    constructor(
        private hostElement: ElementRef
    ) { }
}
