import { Component, ElementRef, OnDestroy, OnInit, Output, ViewChild, EventEmitter, Optional, Input, HostListener } from '@angular/core';
import { ExtendedLocation, LocationService } from '../../locations/location.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomProperty, DisplayRule, Location, LocationType, StreetViewConfig, Translation } from '../../locations/location.model';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { maximumDate, minimumDate } from '../../shared/directives/date-interval.directive';
import { mergeObjects, primitiveClone } from '../../shared/object-helper';

import { AppUserRole } from '../../app-settings/config/app-user-roles/app-user-role.model';
import { AppUserRolesService } from '../../app-settings/config/app-user-roles/app-user-roles.service';
import { Building, OUTDOOR_BUILDING } from '../../buildings/building.model';
import { BuildingService } from '../../buildings/building.service';
import { Floor } from '../../buildings/floor.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../../services/notification.service';
import { Solution } from '../../solutions/solution.model';
import { SolutionService } from '../../services/solution.service';
import { createDropdownItemElement } from '../../shared/mi-dropdown/mi-dropdown';
import { formatToShortDate } from '../../shared/datetime-helper';
import isEqual from 'fast-deep-equal';
import { MapAdapterMediator } from '../map-adapter.mediator';
import { DisplayRuleService } from '../../services/DisplayRuleService/DisplayRuleService';
import { GeodataEditorFactory, GeodataEditor } from '../../GeodataEditor/GeodataEditor.factory';
import { DisplayRuleDetailsComponent } from '../../display-rule-details/display-rule-details.component';
import { BaseMapAdapter } from '../../MapAdapter/BaseMapAdapter';
import { LocationEditorOperation } from '../../GeodataEditor/GeodataEditorOperation/LocationEditorOperation';
import { MapSidebar } from '../map-sidebar/map-sidebar.interface';
import { MapSidebarService } from '../map-sidebar/map-sidebar.service';
import { MapUIComponents, MapUIService } from '../map-ui-service/map-ui.service';
import { FloorService } from '../../services/floor.service';
import { intersectingFeaturePoint } from '../../shared/geometry-helper';
import { Router } from '@angular/router';
import { stayAtCurrentUrl } from '../../solution-settings/solution-settings-shared-functions.component';

@Component({
    selector: 'location-details-editor',
    templateUrl: './location-details-editor.component.html',
    styleUrls: ['./location-details-editor.component.scss'],
})
export class LocationDetailsEditorComponent implements OnInit, OnDestroy, MapSidebar {
    @ViewChild('buildingsDropdown', { static: true })
    private buildingsDropdownElement: ElementRef<HTMLMiDropdownElement>;
    @ViewChild('floorsDropdown', { static: true })
    private floorsDropdownElement: ElementRef<HTMLMiDropdownElement>;

    private _appUserRoles: AppUserRole[] = [];
    private _buildings: Building[];
    private _location: ExtendedLocation;
    private _selectedSolution: Solution;
    private _subscriptions: Subscription = new Subscription();
    private _originalFormState;
    private _geoDataEditor: GeodataEditor;
    private _editLocationOperation: LocationEditorOperation;
    private _mapAdapter: BaseMapAdapter;
    private _duplicationInProgress: boolean = false;

    private readonly _outsideBuilding: Building = {
        ...OUTDOOR_BUILDING,
        floors: [{
            floorIndex: 0,
            geometry: null,
            pathData: null,
            floorInfo: null,
            solutionId: null,
            id: null,
            displayName: ''
        }]
    };

    @Output()
    public closed: EventEmitter<void> = new EventEmitter();

    /**
     * AppUserRoles getter.
     *
     * @readonly
     * @type {AppUserRole[]}
     * @memberof LocationDetailsComponent
     */
    public get appUserRoles(): AppUserRole[] {
        return this._appUserRoles;
    }

    /**
     * Location getter.
     *
     * @readonly
     * @type {ExtendedLocation}
     * @memberof LocationDetailsComponent
     */
    public get location(): ExtendedLocation {
        return this._location;
    }

    /**
     * Selected Solution getter.
     *
     * @readonly
     * @type {Solution}
     * @memberof LocationDetailsComponent
     */
    public get selectedSolution(): Solution {
        return this._selectedSolution;
    }

    /**
     * Location editor operation instance.
     *
     * @public
     * @readonly
     * @type {LocationEditorOperation}
     */
    public get editorOperation(): LocationEditorOperation {
        return this._editLocationOperation;
    }

    public typeName: string;
    public readonly MIN_DATE = '1999-01-01';
    public readonly MAX_DATE = '2099-12-31';

    public locationForm = this.formBuilder.group({
        activeFrom: ['', [minimumDate(this.MIN_DATE), maximumDate(this.MAX_DATE)]],
        activeTo: ['', [minimumDate(this.MIN_DATE), maximumDate(this.MAX_DATE)]],
        aliases: [[]],
        anchor: [],
        categories: [[]],
        customProperties: [],
        imageURL: [],
        externalId: [''],
        pathData: this.formBuilder.group({
            building: [],
            floor: []
        }),
        restrictions: [[]],
        status: [1],
        streetViewConfig: this.formBuilder.group({
            panoramaId: [],
            povHeading: [],
            povPitch: []
        }),
        type: ['', [Validators.required]],
        translations: [],
        angle: [0],
        geometry: [{}, [Validators.required]]
    });

    public discardChangesSubject: Subject<any> = new Subject<any>();

    /**
     * Property that reflects if the location can be deleted.
     *
     * @readonly
     * @type {boolean}
     * @memberof LocationDetailsEditorComponent
     */
    public get canLocationBeDeleted(): boolean {
        return (this?._location?.id > '' && this?._location?.locationType !== LocationType.Room);
    }

    /**
     * Property that reflects if the location can be duplicated.
     *
     * @readonly
     * @type {boolean}
     * @memberof LocationDetailsEditorComponent
     */
    public get canLocationBeDuplicated(): boolean {
        return (this?._location?.id > '' && this?._location?.locationType !== LocationType.Room) && !this._duplicationInProgress;
    }

    constructor(
        @Optional() private mapAdapterMediator: MapAdapterMediator,
        private appUserRolesService: AppUserRolesService,
        private buildingService: BuildingService,
        private formBuilder: FormBuilder,
        private locationService: LocationService,
        private notificationService: NotificationService,
        private solutionService: SolutionService,
        private spinner: NgxSpinnerService,
        private displayRuleService: DisplayRuleService,
        private mapSidebar: MapSidebarService,
        private mapUIService: MapUIService,
        private floorService: FloorService,
        private router: Router
    ) {
        this._mapAdapter = this.mapAdapterMediator?.getMapAdapter();
        if (this._mapAdapter) {
            this._geoDataEditor = GeodataEditorFactory.create(this._mapAdapter, this.displayRuleService, this.locationService);
        }

        const buildingsSubscription = this.buildingService.buildings$
            .subscribe(buildings => {
                this._buildings = [this._outsideBuilding, ...buildings];
            });

        this._subscriptions.add(buildingsSubscription);
    }

    /**
     * Setter for setting the location to be edited.
     */
    @Input()
    set data(input: Location) {
        this._location = primitiveClone(input) as ExtendedLocation;
        this.typeName = this._location?.type;
        this.buildingService.setCurrentFloor(this._location?.floor);
        this.setLocationFormValues(this._location);
        this.makeLocationDraggable(this._location);
        //Centers the location on the map between the location details editor and the floor selector.
        this._mapAdapter.fitGeometry(input.geometry as GeoJSON.Geometry, { left: 736, right: 66, top: 0, bottom: 0 });
        this._mapAdapter.viewState.refresh();
    }

    /**
     * The Location's anchor point.
     *
     * @readonly
     * @type {number[]}
     */
    public get anchorPoint(): number[] {
        return this._location.geometry.type === 'Point' ? this.locationForm.get('geometry').value : this.locationForm.get('anchor').value;
    }

    /**
     * Closes the location details editor.
     *
     * @param {boolean} [skipConfirm=false]
     * @returns {boolean}
     * @memberof LocationDetailsEditorComponent
     */
    public close(skipConfirm: boolean = false): boolean {
        if (skipConfirm || this.confirmDiscard()) {
            this.floorService.disableFloorSelector(false);
            this._editLocationOperation?.complete();
            this.locationForm.reset({}, { emitEvent: false });
            this._location = null;
            this._mapAdapter.viewState.refresh();
            this.closed.emit();
            return true;
        }
        return false;
    }

    /**
     * Resets the Location Details form.
     *
     * @private
     * @memberof LocationDetailsEditorComponent
     */
    private _resetForm(): void {
        if (this._location) {
            const originalFormValue = primitiveClone(this._originalFormState);
            this.discardChangesSubject.next(originalFormValue);
            this.locationForm.reset(originalFormValue);
            this.locationForm.markAsPristine();
            this._editLocationOperation.reset();

            // reset Buildings & Floors dropdown control
            const building = this.buildingService.getBuildingByAdministrativeId(this._originalFormState, this._buildings);
            this.populateBuildingsDropdown(this._buildings, this._originalFormState.pathData.building);
            this.populateFloorsDropdown(building?.floors, this._originalFormState.pathData.floor);
        }
    }

    /**
     * Makes the location on the map draggable.
     *
     * @private
     * @param {ExtendedLocation} location
     * @memberof LocationDetailsEditorComponent
     */
    private makeLocationDraggable(location: ExtendedLocation): void {
        this._editLocationOperation?.complete();
        this._editLocationOperation = this._geoDataEditor?.editLocation(location);
        this._editLocationOperation.changes.subscribe((e) => {
            this.locationForm.patchValue({
                anchor: e.anchor,
                geometry: e.geometry
            });
        });
    }

    /**
     * Populates the buildings dropdown with items.
     *
     * @private
     * @param {Building[]} buildings
     * @param {string} selectedBuildingId
     * @memberof LocationDetailsComponent
     */
    private populateBuildingsDropdown(buildings: Building[], selectedBuildingId: string): void {
        const buildingDropdownItems = buildings?.map(building => {
            const selected = (selectedBuildingId.toLowerCase() === building.administrativeId.toLowerCase());

            return createDropdownItemElement(building.displayName, building.administrativeId, selected);
        });
        this.buildingsDropdownElement.nativeElement.items = buildingDropdownItems;
    }

    /**
     * Populates the floors dropdown with items.
     *
     * @private
     * @param {Floor[]} floors
     * @memberof LocationDetailsComponent
     */
    private populateFloorsDropdown(floors: Floor[], selectedFloor?: number): void {
        const sortedFloorsList = floors?.sort((a, b) => a.floorIndex < b.floorIndex ? -1 : 1);
        const currentFloor = selectedFloor ?? sortedFloorsList[0]?.floorIndex;

        const floorsDropdownItems = sortedFloorsList.map(floor => {
            const selected = floor.floorIndex === currentFloor;
            return createDropdownItemElement(floor.displayName, floor.floorIndex.toString(), selected);
        });

        this.floorsDropdownElement.nativeElement.items = floorsDropdownItems ?? [];
    }

    /**
     * Set the location form's values.
     *
     * @private
     * @param {ExtendedLocation} location
     * @memberof LocationDetailsComponent
     */
    private setLocationFormValues(location: ExtendedLocation): void {
        const building = this.buildingService.getBuildingByAdministrativeId(location, this._buildings);
        const customProperties = location.translations.map(({ language, fields }) => ({ language, fields }));
        const floorIndex = location.pathData?.floor ?? 0;

        this.locationForm.patchValue({
            activeFrom: formatToShortDate(location?.activeFrom),
            activeTo: formatToShortDate(location?.activeTo),
            aliases: location?.aliases,
            anchor: location?.anchor,
            angle: 0,
            categories: location?.categories,
            customProperties,
            externalId: location?.externalId || '',
            imageURL: location?.imageURL,
            pathData: {
                building: location?.pathData?.building,
                floor: location?.pathData?.floor
            },
            restrictions: location?.restrictions,
            status: location?.status,
            streetViewConfig: location?.streetViewConfig ? location.streetViewConfig : {
                panoramaId: null,
                povHeading: null,
                povPitch: null
            },
            translations: location.translations,
            type: location?.type || null,
            geometry: location?.geometry
        });

        this._outsideBuilding.floors[0].floorIndex = floorIndex;
        this.populateBuildingsDropdown(this._buildings, location?.pathData?.building);
        this.populateFloorsDropdown(building?.floors, location?.pathData?.floor);

        this._originalFormState = primitiveClone(this.locationForm.value);
    }

    /**
     * Angular OnInit lifecycle hook.
     */
    ngOnInit(): void {
        this.mapUIService.show(MapUIComponents.LocationDetailsEditor);
        this.floorService.disableFloorSelector(true);

        const locationType = this.locationForm.get('type').valueChanges.subscribe(value => {
            this.typeName = value;
        });

        const solutionSubscription = this.solutionService.selectedSolution$
            .subscribe(solution => {
                if (this._selectedSolution) {
                    this.closed.emit();
                }

                this._selectedSolution = solution;
            });

        const appUserRolesSubscription = this.appUserRolesService.appUserRoles$
            .subscribe(roles => this._appUserRoles = roles);

        const rotationSubscription = this.locationForm.controls['angle'].valueChanges
            .subscribe(value => this._editLocationOperation?.rotate(value));

        const locationFormsSubscription = this.locationForm.valueChanges
            .subscribe(formState => {
                if (formState.geometry?.type === 'Polygon' || formState.geometry?.type === 'MultiPolygon') {
                    // Checks if any of Polygon's edges are intersecting with each other.
                    const intersectingPoints = intersectingFeaturePoint(formState.geometry);

                    if (intersectingPoints.features.length > 0) {
                        this.notificationService.showError('Polygon edges cannot intersect!');
                        this.locationForm.setErrors({ invalid: true });
                    }
                }

                // compare the original state and the current state to see if they are the same.
                !this._location.id || !isEqual(this._originalFormState, formState)
                    ? this.locationForm.markAsDirty()
                    : this.locationForm.markAsPristine();
            });

        const selectedLocationSubscription = this.locationService.selectedLocation$.subscribe(location => {
            if (location && this.confirmDiscard()) {
                this._location = primitiveClone(location) as ExtendedLocation;
                this.typeName = location?.type;
                this.setLocationFormValues(this._location);
                this.makeLocationDraggable(this._location);

                this._mapAdapter.viewState.refresh();
            }
        });

        const urlSubscription = this.router.events.subscribe(() => {
            if (this.locationForm.dirty) {
                if (!this.confirmDiscard()) {
                    stayAtCurrentUrl(this.router);
                    return;
                } else {
                    this.close(true);
                }
            }
        });

        this._subscriptions
            .add(appUserRolesSubscription)
            .add(locationFormsSubscription)
            .add(locationType)
            .add(rotationSubscription)
            .add(selectedLocationSubscription)
            .add(solutionSubscription)
            .add(urlSubscription);
    }

    /**
     * Angular OnDestroy lifecycle hook.
     */
    ngOnDestroy(): void {
        this._editLocationOperation?.complete();
        this._subscriptions.unsubscribe();

        const buildings = this._buildings?.filter(building => building.administrativeId !== OUTDOOR_BUILDING.administrativeId);
        const nearestBuilding = this.buildingService.getNearestBuilding(buildings, this._mapAdapter.getCenter());
        this.buildingService.setCurrentBuilding(nearestBuilding);
    }

    /**
     * Redraws the location with the new displayRules.
     *
     * @param {DisplayRule} displayRule
     */
    public updateDisplayRule(displayRule: DisplayRule): void {
        this._editLocationOperation.redraw(displayRule);
    }

    /**
     * On change handler that sets the value ot the building formcontrol.
     *
     * @param {CustomEvent} detail
     * @memberof LocationDetailsComponent
     */
    public onBuildingsDropdownChange({ detail }: CustomEvent): void {
        const administrativeId = (detail as HTMLMiDropdownItemElement[])?.map(item => item.value).toString();
        const buildingControl = this.locationForm.get('pathData.building');
        if (buildingControl.value?.toLowerCase() !== administrativeId.toLowerCase()) {
            buildingControl?.setValue(administrativeId);

            const building = this._buildings.find(building => building.administrativeId.toLowerCase() === administrativeId.toLowerCase());
            if (building !== this._outsideBuilding) {
                this.floorsDropdownElement.nativeElement.disabled = false;
                this.populateFloorsDropdown(building?.floors);
            } else {
                this.floorsDropdownElement.nativeElement.items = [];
                this.floorsDropdownElement.nativeElement.disabled = true;
            }
        }
    }

    /**
     * On change handler that sets the value of the floor formcontrol.
     *
     * @param {CustomEvent} detail
     * @memberof LocationDetailsComponent
     */
    public onFloorsDropdownChange({ detail }: CustomEvent): void {
        const floorIndex = (detail as HTMLMiDropdownItemElement[])?.map(item => item.value).toString();
        const floorControl = this.locationForm.get('pathData.floor');
        floorControl?.setValue(+ floorIndex);

        const building = this.buildingService.getBuildingByAdministrativeId(this._originalFormState, this._buildings);
        const newFloor = building?.floors.find(floor => floor.floorIndex === parseInt(floorIndex));

        if (newFloor) {
            this.buildingService.setCurrentFloor(newFloor);
        }
    }

    /**
     * Helper function to Save or SaveAndClose location editor.
     *
     * @returns {Observable}
     */
    private saveLocation(): Observable<Location> {
        if (!this.locationForm.valid) {
            return;
        }

        const translations = this.locationForm.value.translations as Translation[];
        const customProperties = this.locationForm.value.customProperties as [{ language: string, fields: { [key: string]: CustomProperty } }];

        for (const translation of translations) {
            const language = translation.language;
            translation.fields = customProperties.find(fields => fields.language === language)?.fields;
        }

        let location = mergeObjects(this._location, this.locationForm.value) as ExtendedLocation;
        location = this.formatBeforeSave(location);

        if (location.id) {
            return this.updateLocation(location);
        }

        return this.createLocation(location);
    }

    /**
     * On submit form handler that formats location properties before sending it over the wire. Then it closes the location details editor.
     *
     * @memberof LocationDetailsComponent
     */
    public onSaveAndClose(): void {
        this.saveLocation().subscribe(() => {
            this.locationService.selectLocation(null);
            this.close(true);
        });
    }

    /**
     * On submit form that just saves the form without closing the location details editor.
     *
     * @memberof LocationDetailsComponent
     */
    public onSave(): void {
        this.saveLocation().subscribe((location: ExtendedLocation) => {
            this._location = location;
            // To keep editing mode after editing Rotation of the Polygon, we set it before and assign it after calling makeLocationDraggable().
            const currentEditingMode = this._editLocationOperation.mode;
            this.makeLocationDraggable(location);
            this._editLocationOperation.mode = currentEditingMode;
            this.setLocationFormValues(location);
            this.locationForm.markAsPristine();
        });
    }

    /**
     * Resets the form and set the values.
     *
     * @memberof LocationDetailsComponent
     */
    public onDiscard(): void {
        this._location.id > '' ? this.confirmDiscard() && this._resetForm() : this.close();
    }

    /**
     * Delete the location.
     *
     * @memberof LocationDetailsEditorComponent
     */
    public onDelete(): void {
        // eslint-disable-next-line no-alert
        if (this.confirmDelete()) {
            this.spinner.show();
            this.locationService.deleteLocation(this._location)
                .pipe(finalize(() => this.spinner.hide()))
                .subscribe(
                    () => {
                        this.notificationService.showSuccess('Location deleted');
                        this.close(true);
                    },
                    error => this.notificationService.showError(error)
                );
        }
    }

    /**
     * Duplicates location the location.
     *
     * @param {KeyboardEvent} [event]
     * @memberof LocationDetailsEditorComponent
     */
    @HostListener('window:keydown.control.d', ['$event'])
    public onDuplicate(event?: KeyboardEvent): void {
        event?.preventDefault();
        // eslint-disable-next-line no-alert
        if (this.canLocationBeDuplicated && this.confirmDiscard()) {
            this._duplicationInProgress = true;
            this._editLocationOperation.complete();
            let duplicate = this.locationService.duplicateLocation(this._location);
            duplicate = this.locationService.offsetLocationPosition(duplicate);
            this.createLocation(duplicate)
                .pipe(finalize(() => this._duplicationInProgress = false))
                .subscribe(location => this.data = location);
        }
    }

    /**
     * Updates the location.
     *
     * @private
     * @param {ExtendedLocation} location
     * @returns {Observable<Location>}
     * @memberof LocationDetailsComponent
     */
    private updateLocation(location: ExtendedLocation): Observable<Location> {
        this.spinner.show();
        return this.locationService.updateLocation(location)
            .pipe(
                tap(() => this.notificationService.showSuccess('Location updated successfully!')),
                map(() => location),
                finalize(() => this.spinner.hide())
            );
    }

    /**
     * Creates a location.
     *
     * @private
     * @param {ExtendedLocation} location
     * @returns {Observable<void>}
     * @memberof LocationDetailsComponent
     */
    private createLocation(location: ExtendedLocation): Observable<Location> {
        this.spinner.show();
        return this.locationService.createLocation(location)
            .pipe(
                tap(() => this.notificationService.showSuccess('Location created successfully!')),
                finalize(() => this.spinner.hide())
            );
    }

    /**
     * On change handler that updates the value of streetViewConfig formcontrol.
     *
     * @param {StreetViewConfig} panorama
     * @memberof LocationDetailsComponent
     */
    public onUpdatePanorama(panorama: StreetViewConfig): void {
        const streetViewConfigControl = this.locationForm.get('streetViewConfig');
        streetViewConfigControl?.markAsDirty();
        streetViewConfigControl?.setValue(panorama || {
            panoramaId: null,
            povHeading: null,
            povPitch: null
        });
    }

    /**
     * Show a info notification.
     *
     * @param {string} message
     * @memberof LocationDetailsComponent
     */
    public showInfoNotification(message: string): void {
        this.notificationService.showInfo(message, false);
    }

    /**
     * On change handler that sets the value of the imageURL formcontrol.
     *
     * @param {string} imageUrl
     * @memberof LocationDetailsComponent
     */
    public onImageUrlChange(imageUrl: string): void {
        const imageUrlControl = this.locationForm.get('imageURL');
        imageUrlControl?.setValue(imageUrl);
    }

    /**
     * Format the Location's properties before saving it.
     *
     * @private
     * @param {ExtendedLocation} location
     * @returns {ExtendedLocation}
     * @memberof LocationsComponent
     */
    private formatBeforeSave(location: ExtendedLocation): ExtendedLocation {
        // Format activeFrom and activeTo dates back to ISO format with Date.toISOString();
        if (location?.activeFrom > '') {
            location.activeFrom = new Date(location.activeFrom)?.toISOString() || location.activeFrom;
        }

        if (location?.activeTo > '') {
            location.activeTo = new Date(location.activeTo)?.toISOString() || location.activeTo;
        }

        // Nullify the streetViewConfig if no panoramaId is set.
        if (!location.streetViewConfig?.panoramaId) {
            location.streetViewConfig = null;
        }

        // The backend expects the deprecated path string to be null
        location.path = null;

        delete location.cloneOf;

        return location;
    }

    /**
     * Open the DisplayRuleDetails component in the MapSidebar.
     * Updates the display rule for the location on save.
     *
     * @param {ExtendedLocation} location
     * @memberof LocationDetailsComponent
     */
    public openDisplayRuleDetails(location: ExtendedLocation): void {
        const { geometry, anchor } = this.locationForm.getRawValue();
        const { componentInstance } = this.mapSidebar.open(DisplayRuleDetailsComponent, true);

        if (componentInstance) {
            componentInstance.data = location;
            componentInstance.geometries = [geometry, anchor];
            componentInstance.valueChanges.subscribe(() => {
                const displayRuleDraft = componentInstance.getDisplayRule();
                this._editLocationOperation.redraw(displayRuleDraft);
            });

            const geometryChanges = this._editLocationOperation.changes.subscribe(({ geometry, anchor }) => {
                componentInstance.geometries = [geometry, anchor];
            });

            componentInstance.submit$.pipe(
                filter((displayRule: DisplayRule) => !!displayRule),
                map((displayRule) => {
                    this.spinner.show();
                    const { geometry, anchor } = this.locationForm.getRawValue();
                    return { ...this._location, geometry, anchor, displayRule } as ExtendedLocation;
                }),
                switchMap((location) => this.locationService.updateLocation(location)
                    .pipe(
                        map(() => location),
                        finalize(() => this.spinner.hide()))
                )
            ).subscribe((location) => {
                this._location = location;
                this.setLocationFormValues(this._location);
                this.makeLocationDraggable(this._location);
                this.notificationService.showSuccess('Location\'s Display Rule updated successfully.');
                geometryChanges.unsubscribe();
            }, error => this.notificationService.showError(error));

            componentInstance.discard$.subscribe(() => {
                this._editLocationOperation.reset();
            });
        }
    }

    /**
     * Prompt the user to confirm before discarding.
     *
     * @private
     * @returns {boolean}
     * @memberof LocationDetailsComponent
     */
    private confirmDiscard(): boolean {
        // eslint-disable-next-line no-alert
        return (this._location?.id !== '' && !this.locationForm.dirty) || confirm('Your unsaved changes will be lost! Would you like to continue?');
    }

    /**
     * Prompt the user to confirm before discarding.
     *
     * @private
     * @returns {boolean}
     * @memberof LocationDetailsComponent
     */
    private confirmDelete(): boolean {
        // eslint-disable-next-line no-alert
        return confirm('Delete this Location?');
    }
}