<panel-header header="Set Preview image - {{ fileName }}" (cancelEvent)="onClose()"></panel-header>

<div class="info">
    <p>
        Edit the preview for the 3D model by rotating, moving, and zooming using the mouse or touchpad.
        <info-tooltip class="position-bottom" text="On touchpad: Rotate by clicking with 1 finger + drag. Move by clicking with 2 fingers + drag. Zoom by making a pinching gesture."></info-tooltip>
    </p>
</div>

<section class="model3d">
    <div #canvas3DContainer class="model3d__preview">
        <div class="model3d__button model3d__zoom-buttons">
            <button (click)="onScaleChange(true)">
                <img src="../../../assets/images/plus.svg" alt="Plus icon">
            </button>
            <button (click)="onScaleChange(false)">
                <img src="../../../assets/images/minus.svg" alt="Minus icon">
            </button>
        </div>

        <button class="model3d__button model3d__reset-button mi-button mi-button--base" type="button"
            (click)="onResetAll()">
            Reset All
        </button>

        <canvas #canvas3D class="model3d__canvas"></canvas>
    </div>
</section>

<panel-footer class="footer" text="You have unsaved changes.">
    <button type="button" class="mi-button mi-button--link" (click)="onDiscard()" *ngIf="isFormDirty">Discard</button>
    <button class="mi-button mi-button--primary" (click)="onSave()">Save</button>
</panel-footer>