/**
 * Merge objects into one.
 *
 * @param {...object} objects
 * @returns {object}
 */
export function merge(...objects: Object[]): any {
    return objects.reduce((result, obj) => {
        if (result === obj) {
            return result;
        } else if (obj && obj?.constructor?.prototype === Object.prototype) {
            for (const key of Object.keys(obj)) {
                result = result ?? {};
                result[key] = merge(result[key], obj[key]);
            }
            return result;
        } else {
            return result === undefined || (obj !== null && obj !== undefined) ? obj : result;
        }
    }, objects[0]);
}

/**
 * Checks if the passed value is null or undefined.
 *
 * @param {any} value
 * @returns {boolean}
 */
export function isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
}
