import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterUsers',
    pure: false
})
export class FilterUsersPipe implements PipeTransform {
    transform(value: any[], query: any): any {
        query = query ? query.toLowerCase() : '';
        return value ?
            value.filter(user => {
                if (user.display !== true) {
                    return false;
                }
                if (user.userName && user.userName.toLowerCase().indexOf(query) !== -1) {
                    return true;
                }
                /*
                if(location.type.toLowerCase().indexOf(query) !== -1 || location.path.toLowerCase().indexOf(query) !== -1) {
                    return true;
                }
                */
            }
            ) :
            value;
    }
}

@Pipe({
    name: 'filterUsersBySolution'
})
export class FilterUsersBySolutionPipe implements PipeTransform {

    transform(value: any[], settings: any[]) {
        return settings && value ?
            value.filter(user => {
                if (settings !== null && settings.length > 0) {
                    if (user.solutions !== undefined && user.solutions !== null) {

                        for (const setting of settings) {
                            for (const solution of user.solutions) {

                                if (solution === setting) {
                                    return true;
                                }
                            }
                        }
                    }
                    return false;
                } else {
                    return true;
                }
            })
            :
            value;
    }
}

@Pipe({
    name: 'filterUserRole'
})
export class FilterUserRolePipe implements PipeTransform {
    transform(value: any[], query: any): any {
        query = query ? query.toLowerCase() : '';
        return value ?
            value.filter(user => {
                if (user.roles.indexOf(query) !== -1) {
                    return true;
                }
                return false;
            }
            ) :
            value;
    }
}

@Pipe({
    name: 'filterNonUserRole'
})
export class FilterNonUserRolePipe implements PipeTransform {
    transform(value: any[], query: any): any {
        query = query ? query.toLowerCase() : '';
        return value ?
            value.filter(user => {
                if (user.roles.indexOf(query) !== -1) {
                    return false;
                }
                return true;
            }
            ) :
            value;
    }
}
