<panel-header (cancelEvent)="close()" header="Graphs"></panel-header>
<section class="section">
    <span class="heading">
        View graph on {{ venue.graphId }}
    </span>
        <div class="container" *ngFor="let graphId of graphIds">
            <div class="container__graphId">
                {{ graphId }}
            </div>
            <div>
                <button class="mi-button mi-button--small mi-button--outline" (click)="loadCurrentVenueGraph(graphId)">
                    <span class="tooltip">Show this Graph on the Map</span>
                    Show
                </button>
            </div>
            <div>
                <button class="mi-button mi-button--small mi-button--base" (click)="setVenueGraph(graphId)"
                [disabled]="venue.graphId === graphId">
                Set as Venue Graph
                </button>
            </div>
            <div>
                <button class="mi-button mi-button--delete mi-button--icon mi-button--small"
                        (click)="deleteGraph(graphId)">
                        <img src="/assets/images/trash-bin_20x20.svg#white" />
                </button>
            </div>
        </div>
</section>