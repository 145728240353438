import { MercatorCoordinate } from 'mapbox-gl';
import { Camera, Euler, Matrix4, Mesh, MeshBasicMaterial, PlaneGeometry, Scene, Texture, TextureLoader, Vector3, WebGLRenderer } from 'three';
import { degToRad } from 'three/src/math/MathUtils';
import { FeatureClass } from '../../../viewmodels/FeatureClass';
import { Model2DViewModelProperties } from '../../../viewmodels/Model2DViewModel/Model2DViewModel';

export class Mapbox2DLayer implements mapboxgl.CustomLayerInterface {
    #modelsOnMap: Map<string, { transformation: Matrix4, scene: Scene }> = new Map();
    #textureCache: Map<string, Texture> = new Map();
    #map: mapboxgl.Map;
    #renderer: WebGLRenderer;
    #textureLoader: TextureLoader = new TextureLoader();

    public readonly id: string;
    public readonly renderingMode: '3d' | '2d' = '3d';
    public readonly type: 'custom' = 'custom';

    constructor(layerId: string) {
        this.id = layerId;
    }

    /**
     * Sets the GeoJSON data and re-renders the layer.
     *
     * @public
     * @param {GeoJSON.Feature[]} features
     */
    public setData(features: GeoJSON.Feature[]): void {
        this.#modelsOnMap = features
            .filter(feature => feature?.properties?.featureClass === FeatureClass.MODEL2D)
            .reduce((models, feature: GeoJSON.Feature<GeoJSON.Point, Model2DViewModelProperties>) => {
                const featureId: string = feature?.id.toString();
                const model = { scene: new Scene(), transformation: new Matrix4() };
                const mercatorCoordinates = MercatorCoordinate.fromLngLat(feature.geometry.coordinates as [number, number], 0);
                const planeGeometry = new PlaneGeometry(feature.properties.widthMeters, feature.properties.heightMeters);
                const scale = this.#getScale(mercatorCoordinates);
                const rotationEuler = this.#getRotationEuler(feature.properties);
                const { scene, transformation } = model;

                const material = new MeshBasicMaterial({
                    map: this.#getTexture(feature.properties.src),
                    transparent: true,
                    depthTest: false
                });

                scene.add(new Mesh(planeGeometry, material));
                scene.setRotationFromEuler(rotationEuler);

                transformation.makeTranslation(mercatorCoordinates.x, mercatorCoordinates.y, mercatorCoordinates.z);
                transformation.scale(scale);

                return models.set(featureId, model);
            }, new Map());
    }

    /**
     * This method is called when the layer is added to the Map with Map#addLayer.
     * This gives the layer a chance to initialize gl resources and register event listeners.
     *
     * @param {mapboxgl.Map} map - The Map this custom layer was just added to.
     * @param {WebGLRenderingContext} gl - The gl context for the map.
     */
    public onAdd(map: mapboxgl.Map, gl: WebGLRenderingContext): void {
        this.#map = map;

        this.#renderer = new WebGLRenderer({
            canvas: this.#map.getCanvas(),
            context: gl,
            antialias: true
        });

        this.#renderer.autoClear = false;
    }

    /**
     * Called during a render frame allowing the layer to draw into the GL context.
     *
     * @param {WebGLRenderingContext} gl - The map's gl context.
     * @param {number[]} matrix - The map's camera matrix.
     */
    render(gl: WebGLRenderingContext, matrix: number[]): void {
        const camera = new Camera();
        for (const model of this.#modelsOnMap.values()) {

            const scene = model.scene;
            if (scene) {
                const transformation = model.transformation;
                camera.projectionMatrix = new Matrix4().fromArray(matrix).multiply(transformation);
                this.#renderer.resetState();
                this.#renderer.render(scene, camera);
            }
        }

        this.#map.triggerRepaint();
    }

    /**
     * Creates an Euler to represent the rotation of the model.
     *
     * @see https://threejs.org/docs/#api/en/math/Euler
     * @param {Model2DViewModelProperties} properties
     * @returns {Euler}
     */
    #getRotationEuler(properties: Model2DViewModelProperties): Euler {
        const x = degToRad(0);
        const y = degToRad(0);
        const z = degToRad(-(properties.bearing % 360 + 360) % 360);

        return new Euler(x, y, z, 'XYZ');
    }

    /**
     * Creates a Vector3 to represent the MercatorCoordinate scale.
     *
     * @param {MercatorCoordinate} mercatorCoordinates
     * @returns {Vector3}
     */
    #getScale(mercatorCoordinates: MercatorCoordinate): Vector3 {
        const scale = mercatorCoordinates.meterInMercatorCoordinateUnits();
        return new Vector3(scale, -scale, scale);
    }

    /**
     * Gets the texture.
     * If the texture isn't in the cache, it will be fetched and cached for future use.
     *
     * @param {string} src
     * @returns {Texture}
     */
    #getTexture(src: string): Texture {
        if (!this.#textureCache.has(src)) {
            this.#textureCache.set(src, this.#textureLoader.load(src));
        }

        return this.#textureCache.get(src).clone();
    }

}