/**
 * Create and get combo-box-item element.
 *
 * @param {string} label
 * @param {string} value
 * @param {boolean} selected
 * @param {string} title
 * @returns {HTMLMiDropdownItemElement}
 */
export function createComboBoxItemElement(label: string, value: string, selected: boolean = false, title?: string): HTMLMiDropdownItemElement {
    const miDropdownItemElement = document.createElement('combo-box-item') as HTMLMiDropdownItemElement;
    miDropdownItemElement.text = label;
    miDropdownItemElement.value = value;
    miDropdownItemElement.selected = selected;

    if (title) {
        miDropdownItemElement.setAttribute('title', title);
    }

    return miDropdownItemElement;
}