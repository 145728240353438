export enum LabelFormat {
    LocationName = '{{name}}',
    ExternalId = '{{externalId}}',
    ExternalIdAndLocName = '{{externalId}} {{name}}',
    LocationNameAndExternalId = '{{name}} {{externalId}}',
}

export const labelFormats: { value: LabelFormat; viewValue: string; }[] = [
    {
        value: LabelFormat.LocationName,
        viewValue: 'Location name'
    },
    {
        value: LabelFormat.ExternalId,
        viewValue: 'External Id'
    },
    {
        value: LabelFormat.ExternalIdAndLocName,
        viewValue: 'External Id & Location Name'
    },
    {
        value: LabelFormat.LocationNameAndExternalId,
        viewValue: 'Location name & External Id'
    }
];