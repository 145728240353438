<div #chipsList class="chips-list" [ngClass]="{'chips-list--expanded': chipsListExpanded}">
    <mat-basic-chip matChipRemove disableRipple
        *ngFor="let filter of activeFilters;"
        (removed)="onRemoveChip(filter.value)">
        {{filter.text || filter.innerText}}
        <mat-icon>cancel</mat-icon>
    </mat-basic-chip>
</div>

<div class="controls">
    <button type="button" class="mi-button mi-button--small mi-button--icon-right mi-button--base btn-more"
        [disabled]="!isListExpandable"
        (click)="chipsListExpanded = !chipsListExpanded">
        {{chipsListExpanded ? "Show less" : "Show more"}}
        <svg [ngClass]="{'rotate': chipsListExpanded}" width="12" height="8" viewBox="0 0 12 8" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.7497 0.917208C11.5885 0.75678 11.37 0.666656 11.1421 0.666656C10.9142 0.666656 10.6957 0.75678 10.5345 0.917208L6.13791 5.29264C6.11795 5.31256 6.09425 5.32837 6.06814 5.33916C6.04204 5.34994 6.01406 5.35549 5.9858 5.35549C5.95754 5.35549 5.92956 5.34994 5.90346 5.33916C5.87736 5.32837 5.85365 5.31256 5.83369 5.29264L1.4371 0.917208C1.27378 0.769536 1.05969 0.690059 0.839146 0.695231C0.618605 0.700403 0.408508 0.789829 0.252353 0.944994C0.0961981 1.10016 0.00594625 1.30918 0.000283478 1.52877C-0.00537929 1.74837 0.0739807 1.96173 0.221932 2.12467L5.074 6.95707C5.19371 7.07635 5.33585 7.17098 5.4923 7.23554C5.64876 7.30009 5.81645 7.33332 5.9858 7.33332C6.15515 7.33332 6.32285 7.30009 6.4793 7.23554C6.63575 7.17098 6.77789 7.07635 6.8976 6.95707L11.7497 2.12467C11.91 1.9643 12 1.74722 12 1.52094C12 1.29465 11.91 1.07758 11.7497 0.917208Z"
                fill="#1E2025" />
        </svg>
    </button>

    <button type="button" class="mi-button mi-button--small mi-button--icon-right mi-button--outline btn-clear"
        (click)="clearAllChips()">
        Clear
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.66659 1.27334L8.72659 0.333344L4.99992 4.06001L1.27325 0.333344L0.333252 1.27334L4.05992 5.00001L0.333252 8.72668L1.27325 9.66668L4.99992 5.94001L8.72659 9.66668L9.66659 8.72668L5.93992 5.00001L9.66659 1.27334Z"
                fill="#444B55" />
        </svg>
    </button>
</div>