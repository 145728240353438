<div fxLayout="column" fxLayoutGap="16px">
  <div>
    <p>When adding a new key-value pair, create them for each language in your solution. Keys can only be edited in the solution's default language.</p>
    <p>Your solution supports these languages:</p>
    <ul>
      <li *ngFor="let translation of translations">- {{getLanguageNameWithCode(translation.language)}}</li>
    </ul>
  </div>
  <mat-tab-group color="accent" class="langs" *ngIf="translations[0].customProperties?.length > 0">
    <mat-tab fxLayout="column" fxLayoutGap="16px"
      *ngFor="let translation of translations | orderByLanguage:defaultLanguageCode" [label]="translation.language">
      <div class="column" *ngFor="let customProperty of translation.customProperties; index as i">
        <div fxLayout="row" fxLayoutGap="16px">
          <mat-form-field fxFlex="35">
            <input matInput placeholder="Key" [ngModel]="customProperty.key" [disabled]="translation.language !== defaultLanguageCode"
              (ngModelChange)="onKeyChanged($event, i)">
            <mat-hint *ngIf="customError?.index === i" class="error-hint">{{customError.message}}</mat-hint>
          </mat-form-field>
          <mat-form-field fxFlex="55">
            <textarea matInput placeholder="Value" [(ngModel)]="customProperty.value"></textarea>
          </mat-form-field>
          <div fxFlex="10">
            <button mat-icon-button type="button" (click)="deleteCustomProperty(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div>
    <button mat-raised-button (click)="addCustomProperty()">
      <mat-icon class="add-icon">add</mat-icon>Add Custom Property
    </button>
  </div>
</div>