import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BuildingsComponent } from './buildings.component';
import { AdminGuard } from '../guards/admin-guard.service';

const routes: Routes = [
    { path: 'buildings', component: BuildingsComponent, canActivate: [AdminGuard] }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BuildingsRoutingModule {}
