import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AlertDialogData {
    title: string;
    content?: string;
    options?: any;
    deleteText?: string;
    placeholderText?: string;
}

@Component({
    selector: 'alert-dialog',
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {
    public deleteText: string = null;
    public isDisabled = false;

    constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogData) {}

    ngOnInit(): void {
        if (this.data.deleteText) {
            this.isDisabled = true;
        }
    }

    onInputChanged(value: string): void {
        if (value === this.data.deleteText) {
            this.isDisabled = false;
        }
    }
}
