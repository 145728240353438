<div class="header">
    <header>Map Behavior</header>
    <hr />
    <div class="description">
        Control how overlapping markers should behave.
    </div>
</div>

<form [formGroup]="mapBehaviourForm" class="body">
        <label class="body__label mi-label">
            <input class="mi-toggle" type="checkbox" [checked]="clusteringEnabled" formControlName="enableClustering"/>
            <span>Enable clustering</span>
        </label>
        <div class="body__title">Collision Handling</div>
        <div class="body__form">
            <label class="mi-label mi-label--radio">
                <input type="radio" class="mi-input" formControlName="collisionHandling" name="collisionHandling" [value]="0"/>
                Do nothing
            </label>
            <label class="mi-label mi-label--radio">
                <input type="radio" class="mi-input" formControlName="collisionHandling" name="collisionHandling" [value]="1">
                Remove labels first
            </label>
            <label class="mi-label mi-label--radio">
                <input type="radio" class="mi-input" formControlName="collisionHandling" name="collisionHandling" [value]="2"/>
                Remove icons first
            </label>
        </div>
</form>

<panel-footer *ngIf="mapBehaviourForm.dirty" text="You have unsaved changes." class="footer">
    <button type="button" class="mi-button mi-button--link" (click)="onDiscard()">Discard</button>
    <button type="button" class="mi-button mi-button--primary" (click)="onSubmit()">Save and close</button>
</panel-footer>
