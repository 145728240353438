<div fxFlexFill fxLayout="column">
	<form #form="ngForm" fxFlexFill fxLayout="column">
		<mat-toolbar class="mat-elevation-z2 page-toolbar">
			<div fxFlex>
				<mat-form-field style="width: 280px;">
					<input name="place" placeholder="Search for places or lat,lng" id="places-autocomplete-field" matInput />
				</mat-form-field>
			</div>
			<div fxFlex="<grow>"></div>
			<div fxFlex *ngIf="venueDetails">
				<div>
					<mat-form-field>
						<input name="name" placeholder="Venue name" required="required" matInput [(ngModel)]="venueDetails.name" />
					</mat-form-field>
				</div>
			</div>
			<div fxFlex>
				<button color="primary" [disabled]="!form.valid || !venueBoundsValid" (click)="saveVenue()" style="margin-right: 0px; margin-left: 16px;" mat-raised-button>Create venue</button>
			</div>
		</mat-toolbar>

		<div style="position: relative;" fxFlex="<grow>" fxLayout="row">
			<div fxFlex="<grow>" id="venue-map"></div>
			<div class="map-overlay zoom-indicator mat-elevation-z4" *ngIf="zoomLevel">Zoom: <span style="font-weight: bold;">{{zoomLevel}}</span></div>
			<div class="bounds-action-bar mat-elevation-z4">
				<button (click)="resetBounds()" matTooltip="New bounds (start over)" type="button" mat-icon-button>
					<mat-icon style="transform: scale(-1, 1);">refresh</mat-icon>
				</button>
			</div>
			<div class="json-panel mat-elevation-z4">
				<div fxLayout="row">
					<div style="margin-right: 16px;" fxFlex="<grow>">
						<h3 style="margin-top: 11px;">Venue bounds json</h3>
					</div>
					<!--
					<div  style="margin-right: 8px;" fxFlex>
						<button (click)="copyBoundsCode()" type="button" mat-icon-button>
							<mat-icon>content_copy</mat-icon>
						</button>
					</div>
					-->
					<div fxFlex>
						<button (click)="toggleBoundsCode()" type="button" mat-icon-button>
							<mat-icon *ngIf="!displayBoundsCode">visibility</mat-icon>
							<mat-icon *ngIf="displayBoundsCode">close</mat-icon>
						</button>
					</div>
				</div>
				<div class="inner">
					<pre *ngIf="displayBoundsCode">
						{{venueBoundsCode}}
					</pre>
				</div>
			</div>
		</div>
	</form>
</div>