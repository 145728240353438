import { defineCustomElements } from '@mapsindoors/components/dist/esm/loader';

import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Loader } from '@googlemaps/js-api-loader';

if (environment.production) {
    enableProdMode();
}

//Load the Google Maps API.
new Loader({
    apiKey: null,
    client: 'gme-mapspeopleas',
    channel: 'MapsIndoorsCMS3',
    version: 'weekly',
    libraries: ['places', 'drawing', 'geometry']
}).load().then(() => {
    platformBrowserDynamic().bootstrapModule(AppModule);
});

defineCustomElements(window);
