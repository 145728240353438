import { Component, forwardRef, Provider } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Status } from '../../shared/enums';

const TOGGLE_SEARCHABLE_CONTROL_VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ToggleSearchableComponent),
    multi: true,
};

@Component({
    selector: 'toggle-searchable',
    templateUrl: './toggle-searchable.component.html',
    styleUrls: [],
    providers: [TOGGLE_SEARCHABLE_CONTROL_VALUE_ACCESSOR]
})
export class ToggleSearchableComponent implements ControlValueAccessor {

    private onTouched!: Function;
    private onChanged!: Function;
    private status: number;

    public checked: boolean;

    writeValue(status: number): void {
        this.status = status;
        this.checked = this.isSearchable(status);
    }

    registerOnChange(fn: any): void {
        this.onChanged = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    /**
     * Checks whether the given status has the searchable bit set.
     *
     * @private
     * @param {number} status
     * @returns {boolean}
     * @memberof ToggleSearchableComponent
     */
    private isSearchable(status: number): boolean {
        // Bitwise AND (a & b) returns a 1 in each bit position for which the corresponding bits of both operands are 1s.
        return Status.Searchable === (status & Status.Searchable);
    }

    /**
     * Sets the searchable bit on the status.
     *
     * @param {boolean} checked
     * @memberof ToggleSearchableComponent
     */
    public onToggleChange(checked: boolean): void {
        this.checked = checked;
        if (checked) {
            this.status |= Status.Searchable; // keeps the existing bits in status intact
        } else {
            this.status &= ~Status.Searchable; // removes the searchable bit from status
        }

        this.onChanged(this.status);
        this.onTouched();
    }
}
