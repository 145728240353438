import { BBox, MultiPolygon, Point } from 'geojson';

import { DisplayRuleService, isLocationActive } from '../../services/DisplayRuleService/DisplayRuleService';
import { Floor } from '../../buildings/floor.model';
import { Location } from '../location.model';
import { MapViewModel, MapViewModelFactory } from '../../../viewmodels/MapViewModelFactory/MapViewModelFactory';
import { PointViewModel } from '../../../viewmodels/PointViewModel/PointViewModel';
import { bboxPolygon, feature as toFeature, Polygon } from '@turf/turf';
import { PolygonViewModel } from '../../../viewmodels/PolygonViewModel/PolygonViewModel';
import booleanIntersects from '@turf/boolean-intersects';
import { Model2DViewModel } from '../../../viewmodels/Model2DViewModel/Model2DViewModel';
import { MapsIndoorsData } from '../../shared/enums/MapsIndoorsData';
import { Model3DViewModel } from '../../../viewmodels/Model3DViewModel/Model3DViewModel';
import { environment } from '../../../environments/environment';
import { GeoJSONGeometryType } from '../../shared/enums';

export class LocationMapViewModelFactory extends MapViewModelFactory<Location> {
    constructor(private displayRuleService: DisplayRuleService) {
        super();
    }

    /**
     * Creates MapViewModels for the given location.
     *
     * @param {Location} location
     * @param {number} sortKey
     * @returns {Promise<MapViewModel[]>}
     * @memberof LocationMapViewModelFactory
     */
    async create(location: Location, sortKey: number): Promise<MapViewModel[]> {
        const viewModels: Promise<MapViewModel>[] = [];
        const displayRule = await this.displayRuleService.getDisplayRule(location.id);

        if (!this.isVisible(displayRule))
            return [];

        if ((location?.geometry?.type === GeoJSONGeometryType.Polygon || location?.geometry?.type === GeoJSONGeometryType.MultiPolygon)) {
            viewModels.push(PolygonViewModel.create(location.id, location.geometry as (Polygon | MultiPolygon), displayRule, sortKey, MapsIndoorsData.Location));
        }

        if (location?.geometry?.type === GeoJSONGeometryType.Point || location?.anchor?.type === GeoJSONGeometryType.Point) {
            const geometry = location.anchor as Point ?? location.geometry as Point;
            displayRule.icon = isLocationActive(location) ? displayRule.icon : `${environment.iconsBaseUrl}misc/inactive-marker.png`;
            viewModels.push(PointViewModel.create(location.id, geometry, displayRule, sortKey, MapsIndoorsData.Location));
        }

        if (displayRule?.model2D?.visible && displayRule?.model2D?.model) {
            const geometry = location.anchor as Point ?? location.geometry as Point;
            viewModels.push(Model2DViewModel.create(location.id, geometry, displayRule, sortKey));
        }

        if (displayRule?.model3D?.visible && displayRule?.model3D?.model) {
            const geometry = location.anchor as Point ?? location.geometry as Point;
            viewModels.push(Model3DViewModel.create(location.id, geometry, displayRule, sortKey, MapsIndoorsData.Location));
        }

        return Promise.all(viewModels);
    }

    /**
     * Checks if the given location intersects with the given bounds.
     *
     * @param {Location} location
     * @param {BBox} bounds
     * @returns {boolean}
     * @memberof LocationMapViewModelFactory
     */
    intersectsWithBounds(location: Location, bounds: BBox): boolean {
        const boundsAsPolygon = bboxPolygon(bounds);
        return booleanIntersects(boundsAsPolygon, toFeature(location.geometry));
    }

    /**
     * Checks if the floorIndex for the given location equals the given floorIndex.
     *
     * @param {Location} location
     * @param {floor} floor
     * @returns {boolean}
     * @memberof LocationMapViewModelFactory
     */
    floorEquals(location: Location, floor: Floor): boolean {
        return location.pathData.floor === floor?.floorIndex;
    }
}