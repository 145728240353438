import memoize from '../../../utilities/Memoize';
import { Model2DViewModelProperties } from '../../../viewmodels/Model2DViewModel/Model2DViewModel';
import { generateRectangularPolygonFromPoint } from '../../shared/geometry-helper';

const memoizedGenerateRectangularPolygonFromPoint = memoize(generateRectangularPolygonFromPoint);

/**
 * GoogleMaps2DModel.
 *
 * @private
 */
export class GoogleMaps2DModel {

    /**
     * The feature (view model) for the 2D model to show.
     *
     * @type {GeoJSON.Feature<GeoJSON.Point>}
     */
    #feature: GeoJSON.Feature<GeoJSON.Point>;

    /**
     * Instance of Google Maps OverlayView.
     *
     * @type {google.maps.OverlayView}
     */
    #overlayView: google.maps.OverlayView;

    /**
     * The image element.
     *
     * @type {HTMLImageElement}
     */
    #imageElement: HTMLImageElement;

    /**
     * The minumum pixel size at which a 2D model should be displayed.
     *
     * @type {number}
     */
    #minSize: number = window.devicePixelRatio * 5;

    /**
     * Bounds of the 2D model.
     *
     * @type {google.maps.LatLngBounds}
     */
    #bounds: google.maps.LatLngBounds;


    /**
     * Styles for the 2D model.
     *
     * @type {Model2DViewModelProperties}
     */
    #style: Model2DViewModelProperties;

    /**
     * Creates an instance of GoogleMaps2DModel.
     *
     * Will place an image on the map with it's size is defined in meters instead of pixels,
     * meaning that its size is dependent on the map's zoom level.
     *
     * @param {GeoJSON.Feature} feature - A geojson feature to generate a 2D model for.
     * @param {google.maps.Map} map - A Google Maps instance.
     */
    constructor(feature: GeoJSON.Feature<GeoJSON.Point>, map: google.maps.Map) {
        this.#feature = feature;
        this.#style = feature.properties as Model2DViewModelProperties;

        this.#bounds = this.calculateBounds(this.#feature.geometry);


        // The class itself cannot extend OverlayView since that would require the Google Maps API to be
        // available always. It's not if using other map providers.
        // So we need to create the OverlayView as an instance field and bind the required methods to it.
        this.#overlayView = new google.maps.OverlayView;
        this.#overlayView.onAdd = this.onAdd.bind(this);
        this.#overlayView.draw = this.draw.bind(this);
        this.#overlayView.onRemove = this.onRemove.bind(this);
        this.#overlayView.setMap(map);
    }

    /**
     * Update the 2D model style.
     *
     * @param {Model2DViewModelProperties} style
     */
    setStyle(style: Model2DViewModelProperties): void {
        this.#style = style;
        const position = this.#bounds.getCenter();
        this.#bounds = this.calculateBounds({ type: 'Point', coordinates: [position.lng(), position.lat()] });
        this.setImage();
        this.draw();
    }

    /**
     * Google Maps OverlayView method that is called once when it is added to the map.
     */
    onAdd(): void {
        this.#imageElement = document.createElement('img');
        this.setImage();

        // Add the element to the overlayLayer pane
        const panes = this.#overlayView.getPanes();
        panes.overlayLayer.appendChild(this.#imageElement);
    }

    /**
     * Calculate bounds used to present the image.
     *
     * @param {GeoJSON.Point} position
     * @returns {google.maps.LatLngBounds}
     */
    private calculateBounds(position: GeoJSON.Point): google.maps.LatLngBounds {
        // Calculate bounds used to present the image.
        const polygon = memoizedGenerateRectangularPolygonFromPoint(position.coordinates, this.#style.widthMeters, this.#style.heightMeters, 0);

        return new google.maps.LatLngBounds(
            // sw: index 0
            new google.maps.LatLng(polygon.geometry.coordinates[0][0][1], polygon.geometry.coordinates[0][0][0]),
            // ne: index 2
            new google.maps.LatLng(polygon.geometry.coordinates[0][2][1], polygon.geometry.coordinates[0][2][0])
        );
    }

    /**
     * Set the image element styles.
     */
    setImage(): void {
        if (this.#imageElement) {
            this.#imageElement.src = this.#style?.src;
            this.#imageElement.style.position = 'absolute';
            this.#imageElement.style.zIndex = '30'; // DANGER: Magic number that we don't know the reasoning behind.
        }
    }

    /**
     * Google Maps OverlayView method that is called when added to the map
     * or when zoom or center changes.
     */
    draw(): void {
        const overlayProjection = this.#overlayView.getProjection();
        if (!overlayProjection) {
            return;
        }

        const sw = overlayProjection.fromLatLngToDivPixel(this.#bounds.getSouthWest());
        const ne = overlayProjection.fromLatLngToDivPixel(this.#bounds.getNorthEast());

        // Resize the image container to fit the wanted dimensions
        if (this.#imageElement) {
            const width = ne.x - sw.x;
            const height = sw.y - ne.y;
            // 2D Model should not be shown if smaller than 5 x pixel density.
            const display = (width <= this.#minSize || height <= this.#minSize) ? 'none' : 'block';

            this.#imageElement.style.display = display;
            this.#imageElement.style.left = `${Math.round(sw.x)}px`;
            this.#imageElement.style.top = `${Math.round(ne.y)}px`;
            this.#imageElement.style.width = `${Math.round(width)}px`;
            this.#imageElement.style.height = `${Math.round(height)}px`;
            this.#imageElement.style.zIndex = this.#style?.sortKey.toString();
            this.#imageElement.style.transform = `rotate(${this.#style?.bearing}deg)`;
        }
    }

    /**
     * Deallocate resource.
     */
    teardown(): void {
        this.#overlayView.setMap(null);
    }

    /**
     * Google Maps OverlayView method that is called whenever removed from the map.
     */
    onRemove(): void {
        this.#imageElement.parentNode.removeChild(this.#imageElement);
        google.maps.event.clearInstanceListeners(this);
        this.#imageElement = undefined;
    }

    /**
     * Relays setMap call to the OverlayView.
     *
     * @param {google.maps.Map} map
     */
    setMap(map: google.maps.Map): void {
        if ((map !== this.#overlayView.getMap() && map instanceof google.maps.Map) || map === null) {
            this.#overlayView.setMap(map);
        }
    }

    /**
     * Sets the anchor position for the 2D model.
     *
     * @param {GeoJSON.Point} position
     * @returns {void}
     */
    setPosition(position: GeoJSON.Point): void {
        this.#bounds = this.calculateBounds(position);
        this.draw();
    }
}