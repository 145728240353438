import { Geometry, LatLng } from '../locations/location.model';
import { centerOfMass, polygons } from '@turf/turf';

import { BaseMapAdapter } from '../MapAdapter/BaseMapAdapter';
import { Building } from '../buildings/building.model';
import { Floor } from '../buildings/floor.model';
import { Injectable } from '@angular/core';
import { SolutionService } from './solution.service';
import { Venue } from '../venues/venue.model';
import { getLatLngFromPosition } from '../shared/geometry-helper';
import midt from '@mapsindoors/midt/tokens/tailwind-colors.json';
import { tap } from 'rxjs/operators';
import { primitiveClone } from '../shared/object-helper';
import { getTileStyleFolderName } from '../map/MapViewState';

@Injectable({ providedIn: 'root' })
export class MapService {
    private googleMap: google.maps.Map;
    #mapAdapter: BaseMapAdapter;
    private mapOptions: google.maps.MapOptions = {
        zoom: 17,
        maxZoom: 22,
        minZoom: 15,
        disableDefaultUI: true,
        zoomControl: false,
        zoomControlOptions: { position: google.maps.ControlPosition.LEFT_CENTER },
        styles: [
            { 'featureType': 'poi', 'elementType': 'labels', 'stylers': [{ 'visibility': 'off' }] },
            { 'featureType': 'transit', 'stylers': [{ 'visibility': 'off' }] }
        ]
    };

    private infoWindow = new google.maps.InfoWindow();
    private _mapObjects = new Map<string, google.maps.MVCObject>(); // TODO: https://mapspeople.atlassian.net/browse/MICMS-1565
    get mapObjects(): Map<string, google.maps.MVCObject> {
        return this._mapObjects;
    }

    private _createSymbol: google.maps.Symbol = {
        path: google.maps.SymbolPath.CIRCLE,
        strokeWeight: 3,
        fillColor: midt['tailwind-colors'].white.value,
        strokeColor: midt['tailwind-colors'].green[500].value,
        fillOpacity: 0.7,
        strokeOpacity: 0.8,
        scale: 12
    };
    get createSymbol(): google.maps.Symbol {
        return this._createSymbol;
    }

    constructor(private solutionService: SolutionService) {
        this.solutionService.getCurrentSolution()
            .pipe(tap(solution => {
                this.mapOptions.maxZoom = solution?.modules?.includes('z22') ? 22 : 21;
                if (this.googleMap) {
                    this.googleMap.set('max-zoom', this.mapOptions.maxZoom);
                }

            }))
            .subscribe();
    }

    /**
     * Create a new map.
     *
     * @param {HTMLDivElement} [mapElement=document.getElementById('map') as HTMLDivElement]
     * @memberof MapService
     */
    public async createMap(mapElement: HTMLDivElement = document.getElementById('map') as HTMLDivElement): Promise<void> {
        if (!this.googleMap) {
            this.googleMap = new google.maps.Map(mapElement, this.mapOptions);
        }
    }

    /**
     * Set the venue.
     *
     * @param {Venue} venue
     * @param {number} [floorIndex=0]
     * @returns {google.maps.Map}
     * @memberof MapService
     */
    public setVenue(venue: Venue, floorIndex = 0): google.maps.Map {
        this.deleteAllMapObjects();
        this.loadTiles(venue, floorIndex);
        this.setBounds(venue?.geometry.bbox);
        return this.googleMap;
    }

    /**
     * Get the map instance.
     *
     * @returns {google.maps.Map}
     * @memberof MapService
     */
    public getMap(): google.maps.Map {
        return this.googleMap;
    }

    /**
     * Get a copy of the default map options.
     *
     * @returns {google.maps.MapOptions}
     * @memberof MapService
     */
    public getMapOptions(): google.maps.MapOptions {
        return primitiveClone(this.mapOptions);
    }

    /**
     * Load map tiles.
     *
     * @param {Venue} venue
     * @param {number} [floorIndex=0]
     * @memberof MapService
     */
    public loadTiles(venue: Venue, floorIndex = 0): void {
        if (this.googleMap && venue) {
            this.googleMap.overlayMapTypes.removeAt(0);
            const tileStyleFolderName = getTileStyleFolderName(venue);
            const mapTiler = new google.maps.ImageMapType({
                getTileUrl: (coord, zoom): string => {
                    if (zoom < this.mapOptions.minZoom) {
                        return null;
                    }

                    const tilesUrl = venue.tilesUrl
                        .replace('{x}', coord.x.toString())
                        .replace('{y}', coord.y.toString())
                        .replace('{z}', zoom.toString())
                        .replace('{style}', tileStyleFolderName || '')
                        .replace('{floor}', floorIndex.toString());

                    return tilesUrl;
                },
                tileSize: new google.maps.Size(256, 256)
            });

            this.googleMap.overlayMapTypes.insertAt(0, mapTiler);
        }
    }

    /**
     * Removes an item and its reference from the map.
     *
     * @param {google.maps.MVCObject} item
     * @param {string} [mapObjectId]
     * @returns {google.maps.MVCObject}
     * @memberof MapService
     */
    public removeFromMap(item: google.maps.MVCObject, mapObjectId?: string): google.maps.MVCObject {
        if (item) {
            this.clearInstanceListeners(item);
            item.set('map', null);
            item = null;
            // delete from the MapObjects collection
            mapObjectId && this._mapObjects.delete(mapObjectId);
        }
        return item;
    }

    /**
     * Fits the map's bounds to the given bounding box.
     *
     * @param {number[]} bbox
     * @param {google.maps.Padding} [padding]
     * @memberof MapService
     */
    public setBounds(bbox: number[], padding?: google.maps.Padding): void {
        if (this.googleMap && bbox) {
            const bounds = new google.maps.LatLngBounds(),
                northEast = new google.maps.LatLng(bbox[1], bbox[0]),
                southWest = new google.maps.LatLng(bbox[3], bbox[2]);
            bounds.extend(northEast);
            bounds.extend(southWest);
            this.googleMap.fitBounds(bounds, padding);
        }
    }

    /**
     * Determines whether a polygon contains another polygon.
     *
     * @private
     * @param {google.maps.Polygon} innerPolygon
     * @param {google.maps.Polygon} outerPolygon
     * @returns {boolean}
     * @memberof MapService
     */
    private isPolygonInsidePolygon(innerPolygon: google.maps.Polygon, outerPolygon: google.maps.Polygon): boolean {
        const points = innerPolygon.getPath().getArray();
        for (let i = 0; i < points.length; i++) {
            if (!google.maps.geometry.poly.containsLocation(points[i], outerPolygon)) {
                return false;
            }
        }
        return true;
    }

    /**
     * Gets the building that contains the given polygon.
     *
     * @param {google.maps.Polygon} polygon
     * @param {Building[]} buildings
     * @param {number} floorIndex
     * @returns {Building}
     * @memberof MapService
     */
    public getPolygonBuilding(polygon: google.maps.Polygon, buildings: Building[], floorIndex: number): Building {
        return buildings.find(building => {
            const floor: Floor = building.floors.find(_floor => _floor.floorIndex === floorIndex);
            const geometry = floor ? floor.geometry : building.geometry;
            const coords = geometry.coordinates.map(poly => poly.map(coord => new google.maps.LatLng(coord[1], coord[0])));
            const floorPolygon = new google.maps.Polygon({ paths: coords });
            return this.isPolygonInsidePolygon(polygon, floorPolygon);
        });
    }

    /**
     * Add an event listener to a specific instance.
     *
     * @param {string} event - Name of the event to listen to.
     * @param {Function} callback - Handler to be executed when the event fires.
     * @param {object} [instance=this.googleMap]
     * @returns {google.maps.MapsEventListener}
     * @memberof MapService
     */
    public addEventListener(event: string, callback: Function, instance: object = this.googleMap): google.maps.MapsEventListener {
        if (instance && typeof callback === 'function') {
            return google.maps.event.addListener(instance, event, (e) => callback(e));
        }
    }

    /**
     * Remove all listeners from a particular instance.
     *
     * @param {object} instance
     * @memberof MapService
     */
    public clearInstanceListeners(instance: object = this.googleMap): void {
        if (instance) {
            google.maps.event.clearInstanceListeners(instance);
        }
    }

    /**
     * Remove an event listener.
     *
     * @param {google.maps.MapsEventListener} listener
     * @memberof MapService
     */
    public removeEventListener(listener: google.maps.MapsEventListener): void {
        if (listener) {
            google.maps.event.removeListener(listener);
        }
    }

    /**
     * Draw marker.
     *
     * @param {(google.maps.LatLng | google.maps.LatLngLiteral)} position
     * @param {(string | google.maps.Icon | google.maps.Symbol)} icon
     * @returns {google.maps.Marker}
     * @memberof MapService
     */
    public drawMarker(
        position: google.maps.LatLng | google.maps.LatLngLiteral,
        icon: string | google.maps.Icon | google.maps.Symbol
    ): google.maps.Marker {
        return new google.maps.Marker({
            map: this.googleMap,
            position,
            icon
        });
    }

    /**
     * Draw a polygon.
     *
     * @param {Geometry} geometry
     * @returns {google.maps.Polygon}
     * @memberof MapService
     */
    public drawPolygon(geometry: Geometry): google.maps.Polygon {
        const paths = geometry?.coordinates?.map(
            path => path.map(coordinates => new google.maps.LatLng(coordinates[1], coordinates[0]))
        );

        return paths ? new google.maps.Polygon({
            map: this.googleMap,
            paths
        }) : null;
    }

    /**
     * Draw a polyline.
     *
     * @param {object[]} coordinates - Array of coordinates to draw.
     * @param {google.maps.PolylineOptions} [options] - Options for the polyline.
     * @returns {google.maps.Polyline}
     */
    public drawPolyline(coordinates: object[], options?: google.maps.PolylineOptions): google.maps.Polyline {
        return new google.maps.Polyline({
            path: coordinates.map(coordinates => new google.maps.LatLng({ lat: coordinates[1], lng: coordinates[0] })),
            map: this.googleMap,
            ...options
        });
    }

    /**
     * Draw infowindow.
     *
     * @param {string} content
     * @param {google.maps.InfoWindowOptions} options
     * @returns {google.maps.InfoWindow}
     * @memberof MapService
     */
    public drawInfoWindow(content: string, options: google.maps.InfoWindowOptions): google.maps.InfoWindow {
        return new google.maps.InfoWindow({
            content,
            ...options
        });
    }

    /**
     * Get zoom.
     *
     * @returns {number}
     */
    public getZoom(): number {
        return this.googleMap?.getZoom() || -1;
    }

    /**
     * Add a map control.
     *
     * @param {HTMLDivElement} control
     * @param {google.maps.ControlPosition} position
     * @memberof MapService
     */
    public addMapControl(control: HTMLDivElement, position: google.maps.ControlPosition): void {
        this.googleMap?.controls[position]?.push(control);
    }

    /**
     * Open the info window.
     *
     * @param {string} content
     * @param {google.maps.MVCObject} anchor
     * @memberof MapService
     */
    public openInfoWindow(content: string, anchor: google.maps.MVCObject): void {
        this.infoWindow.setContent(content);
        this.infoWindow.open(this.googleMap, anchor);
    }

    /**
     * Close the info window.
     *
     * @memberof MapService
     */
    public closeInfoWindow(): void {
        this.infoWindow.close();
    }

    /**
     * Get center of a polygon.
     *
     * @param {Geometry} geometry
     * @returns {google.maps.LatLng}
     * @memberof MapService
     */
    public getPolygonCenter(geometry: Geometry): LatLng {
        const point = centerOfMass(polygons([geometry.coordinates]));
        return getLatLngFromPosition(point.geometry.coordinates);
    }

    /**
     * Delete all objects.
     *
     * @private
     * @memberof MapService
     */
    private deleteAllMapObjects(): void {
        this._mapObjects?.forEach((mapObject, key) => this.removeFromMap(mapObject, key));
        this._mapObjects?.clear();
    }

    /**
     * Set map objects clickability.
     *
     * @param {boolean} value
     * @memberof MapService
     */
    public setMapObjectsClickability(value: boolean): void {
        this._mapObjects.forEach(mapObject => mapObject.set('clickable', value));
    }

    /**
     * Deallocates the map.
     *
     * @memberof MapService
     */
    public deallocate(): void {
        this.clearInstanceListeners();
        this.mapObjects.clear();
        this.googleMap = null;
        this.#mapAdapter = null;
    }
}
