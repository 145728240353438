import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
    selector: 'app-street-view-modal',
    templateUrl: './street-view-modal.component.html',
    styleUrls: ['./street-view-modal.component.scss']
})
export class StreetViewModalComponent implements OnInit {
    panorama: google.maps.StreetViewPanorama;

    @ViewChild('StreetViewPanorama', { static: true }) streetViewPanoramaElement: ElementRef<HTMLElement>;
    @ViewChild('StreetViewMinimap', { static: true }) streetViewMinimapElement: ElementRef<HTMLElement>;

    constructor(
        public dialogRef: MatDialogRef<StreetViewModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    /**
     * NgOnInit.
     */
    ngOnInit(): void {
        this.loadStreetView();

        this.dialogRef.keydownEvents().subscribe(event => this.onEscapeHandler(event));
    }

    /**
     * Load Street View and mini map.
     */
    private loadStreetView(): void {
        const streetViewConfig = this.data.streetViewConfig;
        const config: google.maps.StreetViewPanoramaOptions = {
            pano: streetViewConfig?.panoramaId,
            zoom: 0,
            addressControl: false,
            clickToGo: true,
            disableDoubleClickZoom: false,
            enableCloseButton: false,
            fullscreenControl: false,
            imageDateControl: false,
            motionTracking: false,
            motionTrackingControl: false,
            panControl: false,
            scrollwheel: false,
            zoomControl: false,
        };

        if (streetViewConfig?.povHeading) {
            config.pov = {
                heading: streetViewConfig.povHeading,
                pitch: streetViewConfig.povPitch
            };
        }

        // Minimap
        const minimap = new google.maps.Map(this.streetViewMinimapElement.nativeElement,
            {
                center: this.data.coordinates,
                zoom: 14,
                draggable: false,
                mapTypeControl: false,
                zoomControl: false,
                rotateControl: false,
                scaleControl: false,
                panControl: false,
                fullscreenControl: false
            });
        this.panorama = new google.maps.StreetViewPanorama(this.streetViewPanoramaElement.nativeElement, config);
        this.panorama.controls[google.maps.ControlPosition.RIGHT_TOP].push(this.streetViewMinimapElement.nativeElement);
        // Set pegMan on top of minimap
        minimap.setStreetView(this.panorama);
    }

    /**
     * Set selected Street View panorama image.
     * Passing a object to parent containing panorama id and point of view when modal closes.
     */
    public setImage(): void {
        const pov: google.maps.StreetViewPov = this.panorama.getPov();
        const id = this.panorama.getPano();
        const pano = {
            id: id,
            pov: pov,
        };
        this.dialogRef.close(pano);
    }

    /**
     * Handle the escape key press.
     *
     * @param {KeyboardEvent} event
     * @memberof StreetViewModalComponent
     */
    public onEscapeHandler(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            event.preventDefault();
            event.stopImmediatePropagation();
            this.dialogRef.close();
        }
    }
}