import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Floor } from '../buildings/floor.model';
import { SolutionService } from './solution.service';

@Injectable({
    providedIn: 'root'
})
export class FloorService {
    private api = environment.APIEndpoint;
    private isFloorSelectorDisabledSubject = new BehaviorSubject<boolean>(false);

    constructor(
        private solutionService: SolutionService,
        private http: HttpClient
    ) { }

    /**
     * Returns the spinnerState.
     *
     * @returns {Observable<boolean>}
     */
    public get isFloorSelectorDisabled$(): Observable<boolean> {
        return this.isFloorSelectorDisabledSubject.asObservable();
    }

    /**
     * Calling next on the isFloorSelectorDisabled Subject with the new value.
     *
     * @param {boolean} disableFloorSelector
     */
    public disableFloorSelector(disableFloorSelector: boolean): void {
        this.isFloorSelectorDisabledSubject.next(disableFloorSelector);
    }

    /**
     * Fetch floors from a given solution.
     * Set the diplayName property on all floors.
     *
     * @param {string} [buildingAdministrativeId] - An optional building administrative ID to filter by.
     * @returns {Observable<Floor[]>}
     * @memberof FloorService
     */
    public getFloors(buildingAdministrativeId?: string): Observable<Floor[]> {
        const solution = this.solutionService.getStaticSolution();
        const options = buildingAdministrativeId ? { params: { building: buildingAdministrativeId } } : {};
        return this.http.get<Floor[]>(`${this.api}${solution.id}/api/floors`, options)
            .pipe(map(floors => this.setFloorDisplayName(floors, solution.defaultLanguage)));
    }

    /**
     * Fetch a floor by its ID.
     * Set the displayName property on that floor.
     *
     * @param {string} floorId
     * @returns {Observable<Floor>}
     * @memberof FloorService
     */
    public getFloorById(floorId: string): Observable<Floor> {
        const solution = this.solutionService.getStaticSolution();
        return this.http.get<Floor>(`${this.api}${solution.id}/api/floors/details/${floorId}`)
            .pipe(map(floor => this.setFloorDisplayName([floor], solution.defaultLanguage).pop()));
    }

    /**
     * Create a floor.
     *
     * @param {Floor} floor
     * @returns {Observable<any>}
     * @memberof FloorService
     */
    public createFloor(floor: Floor): Observable<any> {
        const solution = this.solutionService.getStaticSolution();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.post(`${this.api}${solution.id}/api/floors`, floor, { headers });
    }

    /**
     * Update a floor.
     *
     * @param {Floor} floor
     * @returns {Observable<any>}
     * @memberof FloorService
     */
    public updateFloor(floor: Floor): Observable<any> {
        const solution = this.solutionService.getStaticSolution();
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.http.put(`${this.api}${solution.id}/api/floors`, floor, { headers });
    }

    /**
     * Delete a floor.
     *
     * @param {string} floorId
     * @returns {Observable<any>}
     * @memberof FloorService
     */
    public deleteFloor(floorId: string): Observable<any> {
        const solution = this.solutionService.getStaticSolution();
        return this.http.delete(`${this.api}${solution.id}/api/floors/${floorId}`);
    }

    /**
     * Set the floors' displayName property.
     *
     * @private
     * @param {Floor[]} floors
     * @param {string} language
     * @returns {Floor[]}
     * @memberof FloorService
     */
    private setFloorDisplayName(floors: Floor[], language: string): Floor[] {
        return floors.map(floor => {
            const info = floor.floorInfo.find(translation => translation.language === language);
            floor.displayName = info ? info.name : 'n/a';
            return floor;
        });
    }
}
