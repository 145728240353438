<div class="item" (click)="onSelect(mediaItem)">
    <div class="item-actions">
        <button 
            *ngIf="showSyncButton" 
            class="item__button item__button--sync" 
            type="button" 
            aria-label="Sync to other solutions." 
            (click)="onSyncDialog($event, mediaItem)">
            <span class="tooltip tooltip--left">Sync to other Solutions</span>
        </button>

        <button 
            *ngIf="mediaItem.category === categories.Model3D"
            class="item__button item__button--preview" 
            type="button" 
            (click)="onChangePreview($event)"
            [disabled]="changePreviewButtonDisabled">
            Change preview
        </button>

        <button 
            *ngIf="mediaItem.category === categories.Image || mediaItem.category === categories.Model3D"
            class="item__button item__button--delete" 
            type="button" 
            aria-label="Delete" 
            (click)="onDelete($event)"
            [disabled]="deleteButtonDisabled">
            <span class="tooltip tooltip--right">Delete</span>
        </button>
    </div>
    
    <div class="item__image-container">
        <div class="item-info">
            <div class="item-info__main">
                <p>{{ mediaItem.name }}</p>
                <span>.{{ mediaItem.type }}</span>
            </div>
            <p *ngIf="mediaItem.type !== 'glb'" class="item-info__sub">{{ mediaItem.width }}x{{ mediaItem.height }}px</p>
            <p class="item-info__sub">{{ mediaItem.lastModified | datePipe }}</p>
        </div>
        
        <img
            src="{{ mediaItem.preview }}"
            #mediaItemImage
            class="item__image"
            [ngClass]="{'item__image--icon': mediaItem.category === categories.MIIcon}"
            responsiveImageStyle>
    </div>
</div>