<h3 mat-dialog-title>{{data.title}}</h3>
<div mat-dialog-content fxLayout="column">
  <p *ngIf="data.content && data.content.length > 0">{{data.content}}</p>
  <div *ngIf="data.deleteText">
    <mat-form-field>
      <input matInput type="text" [(ngModel)]="deleteText" placeholder="{{data.placeholderText}}"
      (ngModelChange)="isDisabled = ($event !== this.data.deleteText)">
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <div fxFlex></div>
  <button mat-raised-button [mat-dialog-close]="data.options" color="warn" *ngIf="data.options"
    [disabled]="isDisabled">{{data.deleteText ? 'Delete' : 'YES'}}
  </button>
</div>