import { Observable, Subject } from 'rxjs';
import { DisplayRule } from '../../locations/location.model';
import { GeodataEditorViewState } from '../GeodataEditorViewState';

export abstract class GeodataEditorOperation {
    constructor(
        protected _changesSubject: Subject<{ geometry: GeoJSON.Geometry, anchor?: GeoJSON.Point }>,
        protected _editorViewState: GeodataEditorViewState,
        protected onComplete: () => void,
    ) { }

    /**
     * Observable for subscribing to changes.
     *
     * @readonly
     * @type {Observable<GeoJSON.Feature[]>}
     */
    public get changes(): Observable<{ geometry: GeoJSON.Geometry, anchor?: GeoJSON.Point }> {
        return this._changesSubject.asObservable();
    }

    /**
     * Redraw features to refresh the styling.
     */
    public abstract redraw(displayRule: DisplayRule): void;

    /**
     * Reset all changes.
     */
    public abstract reset(): void;

    /**
     * End the operation.
     */
    public complete(): void {
        this._changesSubject.complete();
        this?.onComplete();
    }
}



