import { BBox, MultiPolygon, Polygon } from 'geojson';

import booleanIntersects from '@turf/boolean-intersects';
import { bboxPolygon, feature as toFeature } from '@turf/turf';

import { MapViewModel, MapViewModelFactory } from '../../viewmodels/MapViewModelFactory/MapViewModelFactory';
import { DisplayRuleService } from '../services/DisplayRuleService/DisplayRuleService';
import { MapsIndoorsData } from '../shared/enums/MapsIndoorsData';
import { Floor } from '../buildings/floor.model';
import { DerivedGeometry, DerivedGeometryType } from './DerivedGeometry';
import { WallViewModel } from '../../viewmodels/WallViewModel/WallViewModel';
import { ExtrusionViewModel } from '../../viewmodels/ExtrusionViewModel/ExtrusionViewModel';

export class DerivedGeometryViewModelFactory extends MapViewModelFactory<DerivedGeometry> {
    constructor(private displayRuleService: DisplayRuleService) {
        super();
    }

    /**
     * Creates MapViewModels for the given location.
     *
     * @param {DerivedGeometry} derivedGeometry
     * @param {number} sortKey
     * @returns {MapViewModel[]}
     * @memberof DerivedGeometryViewModelFactory
     */
    async create(derivedGeometry: DerivedGeometry, sortKey: number): Promise<MapViewModel[]> {
        const viewModels: Promise<MapViewModel>[] = [];
        const displayRule = await this.displayRuleService.getDisplayRule(derivedGeometry.geodataId);

        if (!this.isVisible(displayRule))
            return Promise.resolve([]);

        if (derivedGeometry?.geometry?.type.match(/(Multi)?Polygon/) && derivedGeometry.type === DerivedGeometryType.WALL && displayRule?.walls?.visible) {
            viewModels.push(WallViewModel.create(derivedGeometry.geodataId, derivedGeometry.geometry as (Polygon | MultiPolygon), displayRule, sortKey, MapsIndoorsData.Location));
        }

        if (derivedGeometry?.geometry?.type === 'Polygon' && derivedGeometry.type === DerivedGeometryType.EXTRUSION && displayRule?.extrusion?.visible) {
            viewModels.push(ExtrusionViewModel.create(derivedGeometry.geodataId, derivedGeometry.geometry as (Polygon | MultiPolygon), displayRule, sortKey, MapsIndoorsData.Location));
        }

        return Promise.all(viewModels);
    }

    /**
     * Checks if the given DerivedGeometry intersects with the given bounds.
     *
     * @param {DerivedGeometry} derivedGeometry
     * @param {BBox} bounds
     * @returns {boolean}
     * @memberof DerivedGeometryViewModelFactory
     */
    intersectsWithBounds(derivedGeometry: DerivedGeometry, bounds: BBox): boolean {
        const boundsAsPolygon = bboxPolygon(bounds);
        return booleanIntersects(boundsAsPolygon, toFeature(derivedGeometry.geometry));
    }

    /**
     * Checks if the floorIndex for the given DerivedGeometry equals the given floorIndex.
     *
     * @param {DerivedGeometry} derivedGeometry
     * @param {floor} floor
     * @returns {boolean}
     * @memberof DerivedGeometryViewModelFactory
     */
    floorEquals(derivedGeometry: DerivedGeometry, floor: Floor): boolean {
        return derivedGeometry.properties.floorIndex === floor?.floorIndex;
    }
}