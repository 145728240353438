import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { DeploymentConfigComponent } from './deployment-config.component';

@NgModule({
    declarations: [DeploymentConfigComponent],
    imports: [ SharedModule ],
    exports: [],
    providers: [],
})
export class DeploymentConfigModule {}